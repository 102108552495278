import { Field } from "formik";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import Select from "react-select";
import CustomSelectMulti from "../components/CustomSelectMulti";
import DeclareDeath from "../components/DeclareDeath";
import SingleSelectInput from "../components/SingleSelectInput";
// import { answererRelation, bloodgroup, casteList, marriage, mothertongue, occupationList, religionList } from '../data/advanceSearch';
import {
    abroadCountry,
    abroadCountryQuestion,
    abroadReason,
    abroadReasonQuestion,
    abroadYearQuestion,
    ageQuestion,
    answererRelation,
    answererRelationQuestion,
    bloodgroup,
    bloodgroupQuestion,
    casteList,
    casteQuestion,
    contactNo,
    dateQuestion,
    disabilitytype,
    disabilitytypeQuestion,
    education,
    educationQuestion,
    firstMarriageAgeQn,
    gender,
    genderQuestion,
    healthcondition,
    healthconditionQuesiton,
    ifOtherValueQuestion,
    insuranceListQuestion,
    insuranceLists,
    insuranceQuestion,
    insuranceYesNo,
    isGoingToSchool,
    isGoingToSchoolQn,
    livingStatus,
    livingStatusQuestion,
    livingStatusOtherReason,
    livingStatusOtherPlace,
    marriage,
    marriageQuestion,
    memberName,
    mentalCardQuestion,
    mentalCardtype,
    mentalCardTypeQuestion,
    mentalCardYesNo,
    mentaltype,
    mentaltypeQuestion,
    mentalyesno,
    mentalYesNoQuestion,
    mothertongue,
    mothertongueQuestion,
    notGoingSchool,
    notGoingSchoolQn,
    occupation,
    occupationQuestion,
    agriWorkField,
    timeSpanForAgri,
    other,
    primary,
    primaryQuestion,
    religionList,
    religionQuestion,
    secondary,
    secondaryQuestion,
    skillDetail,
    skillDetailQuestion,
    skillJobNoList,
    skillJobNoQn,
    skillJobQn,
    skillJobYesNo,
    skillTraining,
    skillTrainingDurationQn,
    skillTrainingQuestion,
    skillYesNo,
    socialSecurityAllowanceList,
    socialSecurityAllowanceQn,
    studyField,
    studyFieldQn,
    totalMember,
    unhealthy,
    unhealthyQuestion,
    votercard,
    votercardQuestion,
    dateList,
    monthList,
    covidVaccineName,
    doseName,
    noBirthCertificate,
    reasonForNoAllowance,
    job,
    outsideDistrictSchooling,
    ifSchoolGoing,
    schooLeftInMiddleReason,
    ifNoNationalCard,
} from "../data/familyKoboValue";
import { selectedLanguage, ward, wardQuestion } from "../data/institute";

const PariwarikBibaran = (props) => {
    const [memberCount, setMemberCount] = useState(0);
    const [repeaterForm, setRepeaterForm] = useState([]);
    const [showDeclareDeath, setShowDeclareDeath] = useState(false);
    const [familyId, setFamilyId] = useState("");
    const { formik } = props;
    const { familyDetail } = formik.values;
    const handleFamilyCountChange = (e) => {
        formik.handleChange(e);
        if (parseInt(e.target.value)) {
            setMemberCount(parseInt(e.target.value));
        } else {
            setMemberCount(0);
        }
    };
    useEffect(() => {
        console.log(formik.values.totalMember);
        const formMemberCount = formik.values.totalMember || 0;
        const repeaterForms = [...Array(formMemberCount).keys()];
        setRepeaterForm(repeaterForms);
    }, [formik.values.totalMember]);
    useEffect(() => {
        if (familyDetail.totalMember > 0) {
            setMemberCount(familyDetail.totalMember);
            formik.setValues({
                ...formik.values,
                totalMember: familyDetail.totalMember,
            });
        }
    }, []);
    const showDeathForm = (familyId) => {
        setShowDeclareDeath(true);
        setFamilyId(familyId);
    };
    const removeRepeaterForm = (index) => {
        var array = [...repeaterForm]; // make a separate copy of the array
        if (index !== -1) {
            array.splice(index, 1);
            setRepeaterForm(array);
            const newMemberCount = memberCount - 1;
            setMemberCount(newMemberCount);
        }
    };
    const getSingleFamilyMember = (ind) => (
        <>
            <div className="row">
                <div className="col-12 d-flex justify-content-end mb-4 align-items-center">
                    <i className="flaticon2-trash mr-3"></i>
                    <Button
                        variant="light-danger"
                        onClick={() =>
                            showDeathForm(familyDetail[`_familyId${ind}`])
                        }
                    >
                        Declare death
                    </Button>
                </div>
            </div>
            <div className="row mb-7">
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{memberName[selectedLanguage]}</label>
                        <input
                            type="text"
                            class="form-control"
                            name={`familyDetail.fullName${ind}`}
                            placeholder={memberName[selectedLanguage]}
                            onChange={formik.handleChange}
                            value={familyDetail[`fullName${ind}`]}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>Full Name in Block Letter (ENGLISH )</label>
                        <input
                            type="text"
                            class="form-control"
                            name={`familyDetail.fullNameInEng${ind}`}
                            placeholder="Full Name in Block Letter (ENGLISH )"
                            onChange={formik.handleChange}
                            value={familyDetail[`fullNameInEng${ind}`]}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>
                            {familyDetail[`fullName${ind}`]}को घरमुली संगको नाता
                            के हो ?
                        </label>
                        <SingleSelectInput
                            id="answererRelation"
                            name={`familyDetail.answererRelation${ind}`}
                            value={familyDetail[`answererRelation${ind}`]}
                            formik={formik}
                            selectList={answererRelation}
                        />
                    </div>
                </div>
                {familyDetail[`answererRelation${ind}`] === "other" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>अन्य भए उल्लेख गर्नुहोस |</label>
                            <input
                                type="text"
                                class="form-control"
                                name={`familyDetail.answererRelationOther${ind}`}
                                onChange={formik.handleChange}
                                placeholder=""
                                value={
                                    familyDetail[`answererRelationOther${ind}`]
                                }
                            />
                        </div>
                    </div>
                )}
                <div className="col-lg-6">
                    <div class="form-group">
                        <label className="form-control-label">साल</label>
                        <input
                            type="text"
                            className="form-control"
                            id="example-date-input"
                            placeholder={`साल(YYYY)`}
                            name={`familyDetail.dateYear${ind}`}
                            onChange={formik.handleChange}
                            value={familyDetail[`dateYear${ind}`]}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label className="form-control-label">महिना</label>
                        <SingleSelectInput
                            id="dateYear"
                            name={`familyDetail.dateMonth${ind}`}
                            value={familyDetail[`dateMonth${ind}`]}
                            formik={formik}
                            selectList={monthList}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label className="form-control-label">गते</label>
                        <SingleSelectInput
                            id="dateYear"
                            name={`familyDetail.dateDay${ind}`}
                            value={familyDetail[`dateDay${ind}`]}
                            formik={formik}
                            selectList={dateList}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{ageQuestion[selectedLanguage]}</label>
                        <input
                            type="number"
                            class="form-control"
                            name={`familyDetail.age${ind}`}
                            placeholder=""
                            onChange={formik.handleChange}
                            value={familyDetail[`age${ind}`]}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>को इमेल ठेगाना:</label>
                        <input
                            type="email"
                            class="form-control"
                            name={`familyDetail.email${ind}`}
                            placeholder=""
                            onChange={formik.handleChange}
                            value={familyDetail[`email${ind}`]}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{contactNo[selectedLanguage]}</label>
                        <input
                            type="number"
                            class="form-control"
                            name={`familyDetail.contactNumber${ind}`}
                            placeholder=""
                            onChange={formik.handleChange}
                            value={familyDetail[`contactNumber${ind}`]}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{genderQuestion[selectedLanguage]}</label>
                        <SingleSelectInput
                            id="gender"
                            name={`familyDetail.gender${ind}`}
                            value={familyDetail[`gender${ind}`]}
                            formik={formik}
                            selectList={gender}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{livingStatusQuestion[selectedLanguage]}</label>
                        <SingleSelectInput
                            id="livingStatus"
                            name={`familyDetail.livingStatus${ind}`}
                            value={familyDetail[`livingStatus${ind}`]}
                            formik={formik}
                            selectList={livingStatus}
                        />
                    </div>
                </div>

                {familyDetail[`livingStatus${ind}`] == "other_place" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>स्वदेश अन्यत्र कहाँ</label>
                            <SingleSelectInput
                                id="livingStatusOtherPlace"
                                name={`familyDetail.livingStatusOtherPlace${ind}`}
                                value={
                                    familyDetail[`livingStatusOtherPlace${ind}`]
                                }
                                formik={formik}
                                selectList={livingStatusOtherPlace}
                            />
                        </div>
                    </div>
                )}
                {familyDetail[`livingStatusOtherPlace${ind}`] === "others" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>अन्य भए उल्लेख गर्नुहोस |</label>
                            <input
                                type="text"
                                class="form-control"
                                name={`familyDetail.livingStatusOtherPlaceOther${ind}`}
                                placeholder=""
                                onChange={formik.handleChange}
                                value={
                                    familyDetail[
                                        `livingStatusOtherPlaceOther${ind}`
                                    ]
                                }
                            />
                        </div>
                    </div>
                )}

                {familyDetail[`livingStatus${ind}`] == "other_place" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label> स्वदेश अन्य बस्ने भए के कारण हो ?</label>
                            <SingleSelectInput
                                id="livingStatusOtherReason"
                                name={`familyDetail.livingStatusOtherReason${ind}`}
                                value={
                                    familyDetail[
                                        `livingStatusOtherReason${ind}`
                                    ]
                                }
                                formik={formik}
                                selectList={livingStatusOtherReason}
                            />
                        </div>
                    </div>
                )}
                {familyDetail[`livingStatusOtherReason${ind}`] ===
                    "others_work" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>अन्य भए उल्लेख गर्नुहोस |</label>
                            <input
                                type="text"
                                class="form-control"
                                name={`familyDetail.livingStatusOtherReasonOther${ind}`}
                                placeholder=""
                                onChange={formik.handleChange}
                                value={
                                    familyDetail[
                                        `livingStatusOtherReasonOther${ind}`
                                    ]
                                }
                            />
                        </div>
                    </div>
                )}

                {familyDetail[`livingStatus${ind}`] === "foreign" && (
                    <>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {abroadReasonQuestion[selectedLanguage]}
                                </label>
                                <select
                                    className="form-control"
                                    id="abroadReason"
                                    name={`familyDetail.abroadReason${ind}`}
                                    onChange={formik.handleChange}
                                    value={familyDetail[`abroadReason${ind}`]}
                                >
                                    {abroadReason.map((value) => (
                                        <option value={value.value}>
                                            {value[selectedLanguage]}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {familyDetail[`abroadReason${ind}`] ===
                            "other_reason" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>{other[selectedLanguage]}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name={`familyDetail.abroadReasonOther${ind}`}
                                        placeholder=""
                                        onChange={formik.handleChange}
                                        value={
                                            familyDetail[
                                                `abroadReasonOther${ind}`
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {abroadYearQuestion[selectedLanguage]}
                                </label>
                                <input
                                    type="number"
                                    class="form-control"
                                    name={`familyDetail.bideshiyekoYear${ind}`}
                                    placeholder=""
                                    onChange={formik.handleChange}
                                    value={
                                        familyDetail[`bideshiyekoYear${ind}`]
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {abroadCountryQuestion[selectedLanguage]}
                                </label>
                                <select
                                    className="form-control"
                                    id="abroadCountry"
                                    name={`familyDetail.abroadCountry${ind}`}
                                    onChange={formik.handleChange}
                                    value={familyDetail[`abroadCountry${ind}`]}
                                >
                                    {abroadCountry.map((value) => (
                                        <option value={value.value}>
                                            {value[selectedLanguage]}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {familyDetail[`abroadCountry${ind}`] === "other" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>अन्य भए उल्लेख गर्नुहोस |</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name={`familyDetail.abroadCountryOther${ind}`}
                                        placeholder=""
                                        onChange={formik.handleChange}
                                        value={
                                            familyDetail[
                                                `abroadCountryOther${ind}`
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{casteQuestion[selectedLanguage]}</label>
                        <SingleSelectInput
                            id="casteList"
                            name={`familyDetail.caste${ind}`}
                            value={familyDetail[`caste${ind}`]}
                            formik={formik}
                            selectList={casteList}
                        />
                    </div>
                </div>
                {familyDetail[`caste${ind}`] === "other" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>अन्य भए उल्लेख गर्नुहोस |</label>
                            <input
                                type="text"
                                class="form-control"
                                name={`familyDetail.casteOther${ind}`}
                                placeholder=""
                                onChange={formik.handleChange}
                                value={familyDetail[`casteOther${ind}`]}
                            />
                        </div>
                    </div>
                )}
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{religionQuestion[selectedLanguage]}</label>
                        <SingleSelectInput
                            id="casteList"
                            name={`familyDetail.religionList${ind}`}
                            value={familyDetail[`religionList${ind}`]}
                            formik={formik}
                            selectList={religionList}
                        />
                    </div>
                </div>
                {familyDetail[`religionList${ind}`] === "other_religion" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>अन्य भए उल्लेख गर्नुहोस |</label>
                            <input
                                type="text"
                                class="form-control"
                                name={`familyDetail.religionListOther${ind}`}
                                placeholder=""
                                onChange={formik.handleChange}
                                value={familyDetail[`religionListOther${ind}`]}
                            />
                        </div>
                    </div>
                )}
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{mothertongueQuestion[selectedLanguage]}</label>
                        <SingleSelectInput
                            id="mothertongue"
                            name={`familyDetail.mothertongue${ind}`}
                            value={familyDetail[`mothertongue${ind}`]}
                            formik={formik}
                            selectList={mothertongue}
                        />
                    </div>
                </div>
                {familyDetail[`mothertongue${ind}`] === "other_language" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>अन्य भए उल्लेख गर्नुहोस |</label>
                            <input
                                type="text"
                                class="form-control"
                                name={`familyDetail.mothertongueOther${ind}`}
                                placeholder=""
                                onChange={formik.handleChange}
                                value={familyDetail[`mothertongueOther${ind}`]}
                            />
                        </div>
                    </div>
                )}
                {/* <div className="col-lg-6">
                    <div class="form-group">
                        <label>{marriageQuestion[selectedLanguage]}</label>
                        <SingleSelectInput
                            id="casteList"
                            name={`familyDetail.marriage${ind}`}
                            value={familyDetail[`marriage${ind}`]}
                            formik={formik}
                            selectList={marriage}
                        />
                    </div>
                </div> */}

                <div className="col-lg-6">
                    <div class="form-group">
                        <label>को शिक्षा</label>
                        <div class="radio-inline">
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.education${ind}`}
                                    value="literate"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`education${ind}`] ==
                                        "literate"
                                    }
                                />
                                <span></span>
                                साक्षर
                            </label>
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.education${ind}`}
                                    value="illitrate"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`education${ind}`] ==
                                        "illitrate"
                                    }
                                />
                                <span></span>
                                निराक्षर
                            </label>
                        </div>
                    </div>
                </div>

                {familyDetail[`education${ind}`] == "literate" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>{educationQuestion[selectedLanguage]}</label>
                            <SingleSelectInput
                                id="educationLevel"
                                name={`familyDetail.educationLevel${ind}`}
                                value={familyDetail[`educationLevel${ind}`]}
                                formik={formik}
                                selectList={education}
                            />
                        </div>
                    </div>
                )}
                {familyDetail[`educationLevel${ind}`] == "one_to_eight" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>{primaryQuestion[selectedLanguage]}</label>
                            <select
                                className="form-control"
                                id="primary"
                                name={`familyDetail.primary${ind}`}
                                onChange={formik.handleChange}
                                value={familyDetail[`primary${ind}`]}
                            >
                                {primary.map((value) => (
                                    <option value={value.value}>
                                        {value[selectedLanguage]}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
                {familyDetail[`educationLevel${ind}`] == "nine_to_twelve" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>{secondaryQuestion[selectedLanguage]}</label>
                            <select
                                className="form-control"
                                id="secondary"
                                name={`familyDetail.secondary${ind}`}
                                onChange={formik.handleChange}
                                value={familyDetail[`secondary${ind}`]}
                            >
                                {secondary.map((value) => (
                                    <option value={value.value}>
                                        {value[selectedLanguage]}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
                {["bachelors", "masters", "m_phil", "phd"].includes(
                    familyDetail[`educationLevel${ind}`]
                ) && (
                    <>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>{studyFieldQn[selectedLanguage]}</label>
                                <CustomSelectMulti
                                    formik={formik}
                                    value={familyDetail[`studyField${ind}`]}
                                    isMulti={true}
                                    list={studyField}
                                    field={`familyDetail.studyField${ind}`}
                                />
                            </div>
                        </div>
                        {familyDetail[`studyField${ind}`]?.includes(
                            "other"
                        ) && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {ifOtherValueQuestion[selectedLanguage]}
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name={`familyDetail.studyFieldOther${ind}`}
                                        onChange={formik.handleChange}
                                        value={
                                            familyDetail[
                                                `studyFieldOther${ind}`
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}

                {familyDetail[`age${ind}`] >= 4 &&
                    familyDetail[`age${ind}`] <= 18 && (
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {isGoingToSchoolQn[selectedLanguage]}
                                </label>
                                <div class="radio-inline">
                                    {isGoingToSchool.map((value, i) => (
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`familyDetail.isSchoolGoing${ind}`}
                                                value={value.value}
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    familyDetail[
                                                        `isSchoolGoing${ind}`
                                                    ] == value.value
                                                }
                                            />
                                            <span></span>
                                            {value[selectedLanguage]}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                {familyDetail[`isSchoolGoing${ind}`] == "yes_going" && (
                    <>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    विद्यालय जानु हुन्छ भने कहाँ जानुहुन्छ ?
                                </label>
                                <SingleSelectInput
                                    id="ifSchoolGoing"
                                    name={`familyDetail.ifSchoolGoing${ind}`}
                                    value={familyDetail[`ifSchoolGoing${ind}`]}
                                    formik={formik}
                                    selectList={ifSchoolGoing}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>सामुदायिक वा निजि विद्यालय ?</label>
                                <SingleSelectInput
                                    id="outsideDistrictSchooling"
                                    name={`familyDetail.outsideDistrictSchooling${ind}`}
                                    value={
                                        familyDetail[
                                            `outsideDistrictSchooling${ind}`
                                        ]
                                    }
                                    formik={formik}
                                    selectList={outsideDistrictSchooling}
                                />
                            </div>
                        </div>
                    </>
                )}
                {familyDetail[`isSchoolGoing${ind}`] == "not_going" && (
                    <>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {notGoingSchoolQn[selectedLanguage]}
                                </label>
                                <select
                                    className="form-control"
                                    id="whyNoSchool"
                                    name={`familyDetail.whyNoSchool${ind}`}
                                    onChange={formik.handleChange}
                                    value={familyDetail[`whyNoSchool${ind}`]}
                                >
                                    {notGoingSchool.map((value) => (
                                        <option value={value.value}>
                                            {value[selectedLanguage]}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {familyDetail[`whyNoSchool${ind}`] === "others" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {ifOtherValueQuestion[selectedLanguage]}
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name={`familyDetail.whyNoSchoolOther${ind}`}
                                        onChange={formik.handleChange}
                                        value={
                                            familyDetail[
                                                `whyNoSchoolOther${ind}`
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>विद्यालय बिचैमा छोडेको हो/होइन ?</label>
                                <div class="radio-inline">
                                    <label class="radio radio-rounded">
                                        <input
                                            type="radio"
                                            name={`familyDetail.schooLeftInMiddle${ind}`}
                                            value="yes"
                                            onChange={formik.handleChange}
                                            defaultChecked={
                                                familyDetail[
                                                    `schooLeftInMiddle${ind}`
                                                ] == "yes"
                                            }
                                        />
                                        <span></span>हो
                                    </label>
                                    <label class="radio radio-rounded">
                                        <input
                                            type="radio"
                                            name={`familyDetail.schooLeftInMiddle${ind}`}
                                            value="no"
                                            onChange={formik.handleChange}
                                            defaultChecked={
                                                familyDetail[
                                                    `schooLeftInMiddle${ind}`
                                                ] == "no"
                                            }
                                        />
                                        <span></span>
                                        हैन
                                    </label>
                                </div>
                            </div>
                        </div>

                        {familyDetail[`schooLeftInMiddle${ind}`] == "yes" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        विद्यालय बिचैमा छोडेको हो भने कारण के हो
                                        ?
                                    </label>
                                    <SingleSelectInput
                                        id="schooLeftInMiddleReason"
                                        name={`familyDetail.schooLeftInMiddleReason${ind}`}
                                        value={
                                            familyDetail[
                                                `schooLeftInMiddleReason${ind}`
                                            ]
                                        }
                                        formik={formik}
                                        selectList={schooLeftInMiddleReason}
                                    />
                                </div>
                            </div>
                        )}
                        {familyDetail[`schooLeftInMiddleReason${ind}`] ===
                            "others" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>अन्य भए उल्लेख गर्नुहोस |</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name={`familyDetail.schooLeftInMiddleReasonOther${ind}`}
                                        onChange={formik.handleChange}
                                        value={
                                            familyDetail[
                                                `schooLeftInMiddleReasonOther${ind}`
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}

                {/* <div className="col-lg-6">
                    <div class="form-group">
                        <label>{firstMarriageAgeQn[selectedLanguage]}</label>
                        <input
                            type="number"
                            class="form-control"
                            name={`familyDetail.firstMarriageAge${ind}`}
                            onChange={formik.handleChange}
                            value={familyDetail[`firstMarriageAge${ind}`]}
                        />
                    </div>
                </div> */}
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{occupationQuestion[selectedLanguage]}</label>
                        <SingleSelectInput
                            id="occupation"
                            name={`familyDetail.occupation${ind}`}
                            value={familyDetail[`occupation${ind}`]}
                            formik={formik}
                            selectList={occupation}
                        />
                    </div>
                </div>
                {familyDetail[`occupation${ind}`] == "other_job" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>अन्य भए उल्लेख गर्नुहोस |</label>
                            <input
                                type="text"
                                class="form-control"
                                name={`familyDetail.occupationOther${ind}`}
                                placeholder=""
                                onChange={formik.handleChange}
                                value={familyDetail[`occupationOther${ind}`]}
                            />
                        </div>
                    </div>
                )}

                {familyDetail[`occupation${ind}`] == "labor" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>ज्याला/ मजदुरी हो भने के :</label>
                            <SingleSelectInput
                                id="labor"
                                name={`familyDetail.labor${ind}`}
                                value={familyDetail[`labor${ind}`]}
                                formik={formik}
                                selectList={job}
                            />
                        </div>
                    </div>
                )}
                {familyDetail[`labor${ind}`] == "other_labor" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>अन्य भए उल्लेख गर्नुहोस |</label>
                            <input
                                type="text"
                                class="form-control"
                                name={`familyDetail.laborOther${ind}`}
                                placeholder=""
                                onChange={formik.handleChange}
                                value={familyDetail[`laborOther${ind}`]}
                            />
                        </div>
                    </div>
                )}

                {familyDetail[`occupation${ind}`] == "agriculture" && (
                    <div className="col-lg-12">
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>कृषि कार्यको क्षेत्र</label>
                                <SingleSelectInput
                                    id="agriWorkField"
                                    name={`familyDetail.agriWorkField${ind}`}
                                    value={familyDetail[`agriWorkField${ind}`]}
                                    formik={formik}
                                    selectList={agriWorkField}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>व्यावसायिक कृषिमा सगलग्न हो ?</label>
                                <div class="radio-inline">
                                    <label class="radio radio-rounded">
                                        <input
                                            type="radio"
                                            name={`familyDetail.isAgriBusiness${ind}`}
                                            value="yes"
                                            onChange={formik.handleChange}
                                            defaultChecked={
                                                familyDetail[
                                                    `isAgriBusiness${ind}`
                                                ] == "yes"
                                            }
                                        />
                                        <span></span>छ
                                    </label>
                                    <label class="radio radio-rounded">
                                        <input
                                            type="radio"
                                            name={`familyDetail.isAgriBusiness${ind}`}
                                            value="no"
                                            onChange={formik.handleChange}
                                            defaultChecked={
                                                familyDetail[
                                                    `isAgriBusiness${ind}`
                                                ] == "no"
                                            }
                                        />
                                        <span></span>
                                        छैन
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>कृषि कार्य गरेको ठेगाना</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name={`familyDetail.workAddress${ind}`}
                                    placeholder=""
                                    onChange={formik.handleChange}
                                    value={familyDetail[`workAddress${ind}`]}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    कृषि कार्यमा बार्षिक औसत कति समय संलग्न
                                    हुनुहुन्छ ?
                                </label>
                                <SingleSelectInput
                                    id="timeSpanForAgri"
                                    name={`familyDetail.timeSpanForAgri${ind}`}
                                    value={
                                        familyDetail[`timeSpanForAgri${ind}`]
                                    }
                                    formik={formik}
                                    selectList={timeSpanForAgri}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    कृषि पेशा वा कृषि कार्यमा संलग्न वर्ष (कति
                                    वर्ष देखि कृषि गर्दै हुनुहुन्छ ) ?
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name={`familyDetail.workTimePeriod${ind}`}
                                    placeholder=""
                                    onChange={formik.handleChange}
                                    value={familyDetail[`workTimePeriod${ind}`]}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    कुनै समूह वा सहकारीमा आवद्द हुनुहुन्छ ?
                                </label>
                                <div class="radio-inline">
                                    <label class="radio radio-rounded">
                                        <input
                                            type="radio"
                                            name={`familyDetail.isInvolveInGroup${ind}`}
                                            value="yes"
                                            onChange={formik.handleChange}
                                            defaultChecked={
                                                familyDetail[
                                                    `isInvolveInGroup${ind}`
                                                ] == "yes"
                                            }
                                        />
                                        <span></span>छ
                                    </label>
                                    <label class="radio radio-rounded">
                                        <input
                                            type="radio"
                                            name={`familyDetail.isInvolveInGroup${ind}`}
                                            value="no"
                                            onChange={formik.handleChange}
                                            defaultChecked={
                                                familyDetail[
                                                    `isInvolveInGroup${ind}`
                                                ] == "no"
                                            }
                                        />
                                        <span></span>
                                        छैन
                                    </label>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        समूह वा सहकारीमा आवद्द हुनुहुन्छ भने
                                        कुनै एकको नाम उल्लेख गर्नुहोस्
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name={`familyDetail.invloveGroupName${ind}`}
                                        placeholder=""
                                        onChange={formik.handleChange}
                                        value={
                                            familyDetail[
                                                `invloveGroupName${ind}`
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {familyDetail[`age${ind}`] >= 10 && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>विवाहको अवस्था के हो ?</label>
                            <SingleSelectInput
                                id="martialStatus"
                                name={`familyDetail.martialStatus${ind}`}
                                value={familyDetail[`martialStatus${ind}`]}
                                formik={formik}
                                selectList={marriage}
                            />
                        </div>
                    </div>
                )}
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>
                            {healthconditionQuesiton[selectedLanguage]}
                        </label>
                        <div class="radio-inline">
                            {healthcondition.map((value, i) => (
                                <label class="radio radio-rounded">
                                    <input
                                        type="radio"
                                        name={`familyDetail.healthcondition${ind}`}
                                        value={value.value}
                                        onChange={formik.handleChange}
                                        defaultChecked={
                                            familyDetail[
                                                `healthcondition${ind}`
                                            ] == value.value
                                        }
                                    />
                                    <span></span>
                                    {value[selectedLanguage]}
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
                {familyDetail[`healthcondition${ind}`] === "unhelathy" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>{unhealthyQuestion[selectedLanguage]}</label>

                            <CustomSelectMulti
                                formik={formik}
                                value={familyDetail[`unhealthy${ind}`]}
                                isMulti={true}
                                list={unhealthy}
                                field={`familyDetail.unhealthy${ind}`}
                            />
                            {/* <Select
								onChange={(e) =>
									formik.setFieldValue(`familyDetail[unhealthy${ind}`, e.value)
								}
								value={familyDetail[`unhealthy${ind}`]}
								isMulti={true}
								options={unhealthy.map((child, i) => {
									return {
										value: child.value,
										label: child.nepali,
									};
								})}
							/> */}
                        </div>
                    </div>
                )}
                {familyDetail[`unhealthy${ind}`] === "other_disease" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>अन्य भए उल्लेख गर्नुहोस |</label>
                            <input
                                type="text"
                                class="form-control"
                                name={`familyDetail.unhealthyOther${ind}`}
                                placeholder=""
                                onChange={formik.handleChange}
                                value={familyDetail[`unhealthyOther${ind}`]}
                            />
                        </div>
                    </div>
                )}

                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{mentalYesNoQuestion[selectedLanguage]}</label>
                        <div class="radio-inline">
                            {mentalyesno.map((value, i) => (
                                <label class="radio radio-rounded">
                                    <input
                                        type="radio"
                                        name={`familyDetail.mentalyesno${ind}`}
                                        value={value.value}
                                        onChange={formik.handleChange}
                                        defaultChecked={
                                            familyDetail[`mentalyesno${ind}`] ==
                                            value.value
                                        }
                                    />
                                    <span></span>
                                    {value[selectedLanguage]}
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
                {familyDetail[`mentalyesno${ind}`] === "disability" && (
                    <>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {mentaltypeQuestion[selectedLanguage]}
                                </label>
                                <SingleSelectInput
                                    id="mentaltype"
                                    name={`familyDetail.mentaltype${ind}`}
                                    value={familyDetail[`mentaltype${ind}`]}
                                    formik={formik}
                                    selectList={mentaltype}
                                />
                            </div>
                        </div>
                        {familyDetail[`mentaltype${ind}`] ===
                            "other_disable" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>अन्य भए उल्लेख गर्नुहोस |</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name={`familyDetail.mentaltypeOther${ind}`}
                                        placeholder=""
                                        onChange={formik.handleChange}
                                        value={
                                            familyDetail[
                                                `mentaltypeOther${ind}`
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {disabilitytypeQuestion[selectedLanguage]}
                                </label>
                                <SingleSelectInput
                                    id="mentalcondition"
                                    name={`familyDetail.mentalcondition${ind}`}
                                    value={
                                        familyDetail[`mentalcondition${ind}`]
                                    }
                                    formik={formik}
                                    selectList={disabilitytype}
                                />
                            </div>
                        </div>

                        {familyDetail[`mentalcondition${ind}`] === "others" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>अन्य भए उल्लेख गर्नुहोस |</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name={`familyDetail.mentalconditionOther${ind}`}
                                        placeholder=""
                                        onChange={formik.handleChange}
                                        value={
                                            familyDetail[
                                                `mentalconditionOther${ind}`
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {mentalCardQuestion[selectedLanguage]}
                                </label>
                                <div class="radio-inline">
                                    {mentalCardYesNo.map((value, i) => (
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`familyDetail.mentalCardYesNo${ind}`}
                                                value={value.value}
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    familyDetail[
                                                        `mentalCardYesNo${ind}`
                                                    ] == value.value
                                                }
                                            />
                                            <span></span>
                                            {value[selectedLanguage]}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {familyDetail[`mentalCardYesNo${ind}`] ===
                            "card_taken" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {
                                            mentalCardTypeQuestion[
                                                selectedLanguage
                                            ]
                                        }
                                    </label>
                                    <SingleSelectInput
                                        id="mentalCardtype"
                                        name={`familyDetail.mentalCardtype${ind}`}
                                        value={
                                            familyDetail[`mentalCardtype${ind}`]
                                        }
                                        formik={formik}
                                        selectList={mentalCardtype}
                                    />
                                </div>
                            </div>
                        )}
                        {["red_card", "blue_card"].includes(
                            familyDetail[`mentalCardtype${ind}`]
                        ) && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        सामाजिक सुरक्षा भत्ता पाउँनु भएको छ कि
                                        छैन ?
                                    </label>
                                    <div class="radio-inline">
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`familyDetail.hasAllowance${ind}`}
                                                value="yes"
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    familyDetail[
                                                        `hasAllowance${ind}`
                                                    ] == "yes"
                                                }
                                            />
                                            <span></span>छ
                                        </label>
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`familyDetail.hasAllowance${ind}`}
                                                value="no"
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    familyDetail[
                                                        `hasAllowance${ind}`
                                                    ] == "no"
                                                }
                                            />
                                            <span></span>
                                            छैन
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                        {familyDetail[`hasAllowance${ind}`] == "no" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>छैन भने किन ?</label>
                                    <CustomSelectMulti
                                        formik={formik}
                                        value={
                                            familyDetail[
                                                `reasonForNoAllowance${ind}`
                                            ]
                                        }
                                        isMulti={true}
                                        list={reasonForNoAllowance}
                                        field={`familyDetail.reasonForNoAllowance${ind}`}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{insuranceQuestion[selectedLanguage]}</label>
                        <div class="radio-inline">
                            {insuranceYesNo.map((value, i) => (
                                <label class="radio radio-rounded">
                                    <input
                                        type="radio"
                                        name={`familyDetail.hasInsurance${ind}`}
                                        value={value.value}
                                        onChange={formik.handleChange}
                                        defaultChecked={
                                            familyDetail[
                                                `hasInsurance${ind}`
                                            ] == value.value
                                        }
                                    />
                                    <span></span>
                                    {value[selectedLanguage]}
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
                {familyDetail[`hasInsurance${ind}`] === "yes" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>
                                {insuranceListQuestion[selectedLanguage]}
                            </label>

                            <CustomSelectMulti
                                formik={formik}
                                value={familyDetail[`insuranceType${ind}`]}
                                isMulti={true}
                                list={insuranceLists}
                                field={`familyDetail.insuranceType${ind}`}
                            />
                            {/* <Select
								onChange={(e) =>
									formik.setFieldValue(
										`familyDetail.insuranceLists${ind}`,
										e.value
									)
								}
								value={familyDetail[`insuranceLists${ind}`]}
								isMulti={true}
								options={insuranceLists.map((child, i) => {
									return {
										value: child.value,
										label: child.nepali,
									};
								})}
							/> */}
                        </div>
                    </div>
                )}

                <div className="col-lg-6">
                    <div class="form-group">
                        <label>तपाइँलाई आफ्नो रगत समूह थाह छ कि छैन ?</label>
                        <div class="radio-inline">
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.knowBloodGroup${ind}`}
                                    value="yes"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`knowBloodGroup${ind}`] ==
                                        "yes"
                                    }
                                />
                                <span></span>छ
                            </label>
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.knowBloodGroup${ind}`}
                                    value="no"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`knowBloodGroup${ind}`] ==
                                        "no"
                                    }
                                />
                                <span></span>
                                छैन
                            </label>
                        </div>
                    </div>
                </div>

                {familyDetail[`knowBloodGroup${ind}`] == "yes" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>
                                {bloodgroupQuestion[selectedLanguage]}
                            </label>
                            <SingleSelectInput
                                id="bloodgroup"
                                name={`familyDetail.bloodgroup${ind}`}
                                value={familyDetail[`bloodgroup${ind}`]}
                                formik={formik}
                                selectList={bloodgroup}
                            />
                        </div>
                    </div>
                )}
                {/* <div className="col-lg-6">
                    <div class="form-group">
                        <label>{votercardQuestion[selectedLanguage]}</label>
                        <div class="radio-inline">
                            {
                                votercard.map((value, i) => (
                                    <label class="radio radio-rounded">
                                        <input type="radio" name={`familyDetail.votercard${ind}`} value={value.value} onChange={formik.handleChange} defaultChecked={familyDetail[`votercard${ind}`] == value.value} />
                                        <span></span>
                                        {value[selectedLanguage]}
                                    </label>
                                ))
                            }
                        </div>
                    </div>
                </div> */}
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{skillTrainingQuestion[selectedLanguage]}</label>
                        <div class="radio-inline">
                            {skillTraining.map((value, i) => (
                                <label class="radio radio-rounded">
                                    <input
                                        type="radio"
                                        name={`familyDetail.skillTraining${ind}`}
                                        value={value.value}
                                        onChange={formik.handleChange}
                                        defaultChecked={
                                            familyDetail[
                                                `skillTraining${ind}`
                                            ] == value.value
                                        }
                                    />
                                    <span></span>
                                    {value[selectedLanguage]}
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
                {familyDetail[`skillTraining${ind}`] === "skill_yes" && (
                    <>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {skillDetailQuestion[selectedLanguage]}
                                </label>

                                <CustomSelectMulti
                                    formik={formik}
                                    value={familyDetail[`skillDetail${ind}`]}
                                    isMulti={true}
                                    list={skillDetail}
                                    field={`familyDetail.skillDetail${ind}`}
                                />
                                {/* <Select
									onChange={(e) =>
										formik.setFieldValue(
											`familyDetail.skillDetail${ind}`,
											e.value
										)
									}
									value={familyDetail[`skillDetail${ind}`]}
									isMulti={true}
									options={skillDetail.map((child, i) => {
										return {
											value: child.value,
											label: child.nepali,
										};
									})}
								/> */}
                            </div>
                        </div>
                        {familyDetail[`skillDetail${ind}`]?.includes(
                            "other"
                        ) && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {ifOtherValueQuestion[selectedLanguage]}
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name={`familyDetail.skillTrainingOther${ind}`}
                                        onChange={formik.handleChange}
                                        value={
                                            familyDetail[
                                                `skillTrainingOther${ind}`
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {skillTrainingDurationQn[selectedLanguage]}
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name={`familyDetail.trainingDuration${ind}`}
                                    onChange={formik.handleChange}
                                    value={
                                        familyDetail[`trainingDuration${ind}`]
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>{skillJobQn[selectedLanguage]}</label>
                                <div class="radio-inline">
                                    {skillJobYesNo.map((value, i) => (
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`familyDetail.isSameJobTraining${ind}`}
                                                value={value.value}
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    familyDetail[
                                                        `isSameJobTraining${ind}`
                                                    ] == value.value
                                                }
                                            />
                                            <span></span>
                                            {value[selectedLanguage]}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div className="col-lg-6">
                    <div class="form-group">
                        <label>
                            तपाइँको सामाजिक सुरक्षा कोष परिचय पत्र छ वा छैन ?
                        </label>
                        <div class="radio-inline">
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.hasSocialSecurityFund${ind}`}
                                    value="yes"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[
                                            `hasSocialSecurityFund${ind}`
                                        ] == "yes"
                                    }
                                />
                                <span></span>छ
                            </label>
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.hasSocialSecurityFund${ind}`}
                                    value="no"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[
                                            `hasSocialSecurityFund${ind}`
                                        ] == "no"
                                    }
                                />
                                <span></span>
                                छैन
                            </label>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div class="form-group">
                        <label>तपाइको नागरिकता छ वा छैन ?</label>
                        <div class="radio-inline">
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.hasCitizenship${ind}`}
                                    value="yes"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`hasCitizenship${ind}`] ==
                                        "yes"
                                    }
                                />
                                <span></span>छ
                            </label>
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.hasCitizenship${ind}`}
                                    value="no"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`hasCitizenship${ind}`] ==
                                        "no"
                                    }
                                />
                                <span></span>
                                छैन
                            </label>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div class="form-group">
                        <label>
                            तपाइले मतदाता परिचय पत्र लिनु भएको छ वा छैन ?
                        </label>
                        <div class="radio-inline">
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.voterId${ind}`}
                                    value="yes"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`voterId${ind}`] == "yes"
                                    }
                                />
                                <span></span>छ
                            </label>
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.voterId${ind}`}
                                    value="no"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`voterId${ind}`] == "no"
                                    }
                                />
                                <span></span>
                                छैन
                            </label>
                        </div>
                    </div>
                </div>
                {familyDetail[`voterId${ind}`] == "yes" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>मतदाता परिचय पत्र नम्बर</label>
                            <input
                                type="text"
                                class="form-control"
                                name={`familyDetail.voterCardNumber${ind}`}
                                onChange={formik.handleChange}
                                value={familyDetail[`voterCardNumber${ind}`]}
                            />
                        </div>
                    </div>
                )}

                <div className="col-lg-6">
                    <div class="form-group">
                        <label>तपाइँको राष्ट्रिय परिचय पत्र छ कि छैन ?</label>
                        <div class="radio-inline">
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.nationalIdCard${ind}`}
                                    value="yes"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`nationalIdCard${ind}`] ==
                                        "yes"
                                    }
                                />
                                <span></span>छ
                            </label>
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.nationalIdCard${ind}`}
                                    value="no"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`nationalIdCard${ind}`] ==
                                        "no"
                                    }
                                />
                                <span></span>
                                छैन
                            </label>
                        </div>
                    </div>
                </div>

                {familyDetail[`nationalIdCard${ind}`] == "no" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>छैन भने किन ?</label>

                            <CustomSelectMulti
                                formik={formik}
                                value={familyDetail[`ifNoNationalCard${ind}`]}
                                isMulti={true}
                                list={ifNoNationalCard}
                                field={`familyDetail.ifNoNationalCard${ind}`}
                            />
                        </div>
                    </div>
                )}

                <div className="col-lg-6">
                    <div class="form-group">
                        <label>स्थाई लेखा नं. (प्यान नम्बर ) छ कि छैन ?</label>
                        <div class="radio-inline">
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.hasPanNumber${ind}`}
                                    value="yes"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`hasPanNumber${ind}`] ==
                                        "yes"
                                    }
                                />
                                <span></span>छ
                            </label>
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.hasPanNumber${ind}`}
                                    value="no"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`hasPanNumber${ind}`] ==
                                        "no"
                                    }
                                />
                                <span></span>
                                छैन
                            </label>
                        </div>
                    </div>
                </div>

                {familyDetail[`hasPanNumber${ind}`] == "yes" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>को प्यान नम्बर</label>
                            <input
                                type="text"
                                class="form-control"
                                name={`familyDetail.panNumber${ind}`}
                                onChange={formik.handleChange}
                                value={familyDetail[`panNumber${ind}`]}
                            />
                        </div>
                    </div>
                )}

                <div className="col-lg-6">
                    <div class="form-group">
                        <label>सवारी चालक अनुमति प्रमाण पत्र छ ?</label>
                        <div class="radio-inline">
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.hasDrivingLicense${ind}`}
                                    value="yes"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[
                                            `hasDrivingLicense${ind}`
                                        ] == "yes"
                                    }
                                />
                                <span></span>छ
                            </label>
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.hasDrivingLicense${ind}`}
                                    value="no"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[
                                            `hasDrivingLicense${ind}`
                                        ] == "no"
                                    }
                                />
                                <span></span>
                                छैन
                            </label>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div class="form-group">
                        <label>कोभिड १९ बिरुद्द खोप लिएको छ कि छैन ?</label>
                        <div class="radio-inline">
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.hasCovidVaccine${ind}`}
                                    value="yes"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`hasCovidVaccine${ind}`] ==
                                        "yes"
                                    }
                                />
                                <span></span>छ
                            </label>
                            <label class="radio radio-rounded">
                                <input
                                    type="radio"
                                    name={`familyDetail.hasCovidVaccine${ind}`}
                                    value="no"
                                    onChange={formik.handleChange}
                                    defaultChecked={
                                        familyDetail[`hasCovidVaccine${ind}`] ==
                                        "no"
                                    }
                                />
                                <span></span>
                                छैन
                            </label>
                        </div>
                    </div>
                </div>

                {familyDetail[`hasCovidVaccine${ind}`] == "yes" && (
                    <>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>छ भने कुन डोज लिएको छ ?</label>
                                <SingleSelectInput
                                    id="covidDose"
                                    name={`familyDetail.covidDose${ind}`}
                                    value={familyDetail[`covidDose${ind}`]}
                                    formik={formik}
                                    selectList={doseName}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    खोपको नाम (कुन खोप लगाउनु भएको छ ? )
                                </label>

                                <CustomSelectMulti
                                    formik={formik}
                                    value={
                                        familyDetail[`covidVaccineName${ind}`]
                                    }
                                    isMulti={true}
                                    list={covidVaccineName}
                                    field={`familyDetail.covidVaccineName${ind}`}
                                />
                            </div>
                        </div>
                    </>
                )}

                {familyDetail[`age${ind}`] < 10 && (
                    <>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    जन्म दर्ता गर्नु भएको छ ? (जन्म दर्ता छ कि
                                    छैन )?
                                </label>
                                <div class="radio-inline">
                                    <label class="radio radio-rounded">
                                        <input
                                            type="radio"
                                            name={`familyDetail.isBirthCertificate${ind}`}
                                            value="yes"
                                            onChange={formik.handleChange}
                                            defaultChecked={
                                                familyDetail[
                                                    `isBirthCertificate${ind}`
                                                ] == true
                                            }
                                        />
                                        <span></span>छ
                                    </label>
                                    <label class="radio radio-rounded">
                                        <input
                                            type="radio"
                                            name={`familyDetail.isBirthCertificate${ind}`}
                                            value="no"
                                            onChange={formik.handleChange}
                                            defaultChecked={
                                                familyDetail[
                                                    `isBirthCertificate${ind}`
                                                ] == false
                                            }
                                        />
                                        <span></span>
                                        छैन
                                    </label>
                                </div>
                            </div>
                        </div>
                        {familyDetail[`isBirthCertificate${ind}`] === "no" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        जन्म दर्ता गर्नु भएको छैन भनी किन नगर्नु
                                        भएको ?
                                    </label>
                                    <SingleSelectInput
                                        id="noBirthCertificate"
                                        name={`familyDetail.noBirthCertificate${ind}`}
                                        value={
                                            familyDetail[
                                                `noBirthCertificate${ind}`
                                            ]
                                        }
                                        formik={formik}
                                        selectList={noBirthCertificate}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                )}

                {familyDetail[`noBirthCertificate${ind}`] === "others" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>अन्य भए उल्लेख गर्नुहोस |</label>
                            <input
                                type="text"
                                class="form-control"
                                name={`familyDetail.noBirthCertificateOther${ind}`}
                                placeholder=""
                                onChange={formik.handleChange}
                                value={
                                    familyDetail[
                                        `noBirthCertificateOther${ind}`
                                    ]
                                }
                            />
                        </div>
                    </div>
                )}
            </div>
            <hr />
        </>
    );
    return (
        <>
            <DeclareDeath
                familyId={familyId}
                show={showDeclareDeath}
                setShow={setShowDeclareDeath}
            />
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                <div class="col-xl-12 col-xxl-8">
                    <div
                        class="pb-5"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                    >
                        <h3 class="mb-10 font-weight-bold text-dark">
                            परिवार विवरण
                        </h3>
                        <div className="row">
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {totalMember[selectedLanguage]}
                                    </label>
                                    <input
                                        min="0"
                                        type="number"
                                        class="form-control"
                                        name="totalMember"
                                        onChange={(e) =>
                                            handleFamilyCountChange(e)
                                        }
                                        placeholder={
                                            totalMember[selectedLanguage]
                                        }
                                        value={formik.values.totalMember}
                                    />
                                </div>
                            </div>
                        </div>
                        {repeaterForm.map((ind) => (
                            <Accordion defaultActiveKey={0}>
                                <Card className="mb-2">
                                    <Card.Header>
                                        <Accordion.Toggle
                                            className="w-100 d-flex justify-content-between"
                                            as={Button}
                                            variant="light-primary"
                                            eventKey={ind}
                                            title="Click to expand/collapse"
                                        >
                                            <h5 className="mb-0">
                                                {familyDetail[`fullName${ind}`]}
                                            </h5>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={ind}>
                                        <Card.Body>
                                            {getSingleFamilyMember(ind)}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PariwarikBibaran;
