import React from "react";
import { Card } from "react-bootstrap";
import {
    agriCropType,
    agriProductionType,
    foodForMonth,
    agricultureProductionOrNot,
    allAgriType,
} from "../../data/houseSurvey";
import { selectedLanguage } from "../../data/institute";

const AgricultureDetail = ({ agriculture }) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>कृषि बाली</Card.Title>
                <h6>
                    कृषि बाली उत्पादन तथा उपभोग:{" "}
                    {agricultureProductionOrNot.find(
                        (i) =>
                            i.value === agriculture.agricultureProductionOrNot
                    )
                        ? agricultureProductionOrNot.find(
                              (i) =>
                                  i.value ===
                                  agriculture.agricultureProductionOrNot
                          )[selectedLanguage]
                        : agriculture.agricultureProductionOrNot}
                </h6>
                {agriculture.agricultureProductSales && (
                    <>
                        <h6>
                            बार्षिक बाली उत्पादन तथा बिक्रि :{" "}
                            {agriculture.agricultureProductSalesType.map(
                                (agri) => {
                                    return agriProductionType.find(
                                        (i) => i.value === agri
                                    )
                                        ? agriProductionType.find(
                                              (i) => i.value === agri
                                          )[selectedLanguage] + ", "
                                        : "";
                                }
                            )}
                        </h6>
                        {agriculture.agricultureDetail?.map((agro) => {
                            return (
                                <>
                                    <h6>
                                        वालिको नाम :
                                        {allAgriType.find(
                                            (i) => i.value === agro.title
                                        ) !== undefined
                                            ? allAgriType.find(
                                                  (i) => i.value === agro.title
                                              )[selectedLanguage]
                                            : ""}
                                    </h6>
                                    {agro.production.plantCount !== 0 && (
                                        <h6>
                                            वालिको उत्पादन परिमाण :
                                            {agro?.production?.plantCount}
                                        </h6>
                                    )}
                                    <h6>
                                        वालिको उत्पादन परिमाण :
                                        {agro?.production?.kg !== 0 &&
                                            `${agro?.production?.kg} kg`}
                                    </h6>
                                    {agro.sale.plantCount !== 0 && (
                                        <h6>
                                            वालिको विक्री परिमाण :
                                            {agro?.sale?.plantCount}
                                        </h6>
                                    )}
                                    <h6>
                                        वालिको विक्री परिमाण :
                                        {agro?.sale?.kg !== 0 &&
                                            `${agro?.sale?.kg} kg`}
                                    </h6>
                                    <hr />
                                </>
                            );
                        })}

                        <h6>
                            आम्दानी वा उत्पादनले परिवारलाई कति महिना खान पुग्छ ?
                            :{" "}
                            {foodForMonth.find(
                                (i) => i.value === agriculture.foodForMonth
                            )
                                ? foodForMonth.find(
                                      (i) =>
                                          i.value === agriculture.foodForMonth
                                  )[selectedLanguage]
                                : ""}
                        </h6>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};

export default AgricultureDetail;
