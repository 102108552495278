import React, { useEffect, useMemo } from "react";
import { Accordion, Button, Card, Tab, Tabs } from "react-bootstrap";
import { useHtmlClassService } from "../../_metronic/layout";
import objectPath from "object-path";
import { agricultureList } from "../data/agricultureReport";
import { selectedLanguage } from "../data/institute";
import AgricultureBarGraphCard from "../detailTypes/overview-sections/AgricultureBarGraphCard";
const AgricultureDataOverview = () => {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray200: objectPath.get(
                uiService.config,
                "js.colors.gray.gray200"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseDanger: objectPath.get(
                uiService.config,
                "js.colors.theme.base.danger"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
        };
    }, [uiService]);

    useEffect(() => {
        const element1 = document.getElementById("school_chart");
        // renderChart(element1, layoutProps)
    }, [layoutProps]);
    return (
        <>
            <Card>
                <Card.Body>
                    <Tabs
                        size="sm"
                        variant="pills"
                        defaultActiveKey={"agricultureReport"}
                        id="uncontrolled-tab-example"
                    >
                        {agricultureList.map((report, parentIndex) => {
                            return (
                                <Tab
                                    key={report.value}
                                    eventKey={report.value}
                                    title={report[selectedLanguage]}
                                >
                                    <Card className="mb-3" key={parentIndex}>
                                        <Card.Header>
                                            <h2>{report[selectedLanguage]}</h2>
                                        </Card.Header>
                                        <Card.Body>
                                            <Accordion defaultActiveKey={0}>
                                                {report.child.map(
                                                    (eachChild, index) => (
                                                        <AgricultureBarGraphCard
                                                            key={index}
                                                            child={eachChild}
                                                            parentIndex={
                                                                parentIndex
                                                            }
                                                            childIndex={index}
                                                        />
                                                    )
                                                )}
                                            </Accordion>
                                        </Card.Body>
                                    </Card>
                                </Tab>
                            );
                        })}
                    </Tabs>
                </Card.Body>
            </Card>
        </>
        // <Card>
        //     <Card.Header>
        //         <h2>
        //             शिक्षा सम्बन्धी बिबरण
        //         </h2>
        //     </Card.Header>
        //     <Card.Body>

        //         <Accordion defaultActiveKey="0">

        //         </Accordion>
        //     </Card.Body>
        // </Card>
    );
};

export default AgricultureDataOverview;
