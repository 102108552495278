import Table from "rc-table";
import React from "react";
import { Card } from "react-bootstrap";
import {
    genderFemale,
    genderMale,
    genderThird,
    totalHouseCount,
    wardQuestion,
} from "../../data/houseSurvey";
import { selectedLanguage } from "../../data/institute";

const PopulationTable = ({ wardData, population }) => {
    const columnsWard = [
        {
            title: wardQuestion[selectedLanguage],
            dataIndex: "ward",
            key: "ward",
            width: undefined,
        },
        {
            title: totalHouseCount[selectedLanguage],
            dataIndex: "house",
            key: "house",
            width: undefined,
        },
        {
            title: genderMale[selectedLanguage],
            dataIndex: "male",
            key: "male",
            width: undefined,
        },
        {
            title: genderFemale[selectedLanguage],
            dataIndex: "female",
            key: "female",
            width: undefined,
        },
        {
            title: genderThird[selectedLanguage],
            dataIndex: "third",
            key: "third",
            width: undefined,
        },
        {
            title: "जनसंख्या",
            dataIndex: "total",
            key: "total",
            width: undefined,
        },
    ];
    const wardKeys = Object.keys(wardData).map((ward) => {
        return ward.replace("ward", "");
    });
    const unsortedDataWard = Object.keys(wardData).map((wardKey, i) => {
        return {
            index: wardKeys[i],
            ward: `वडा ${wardKeys[i]}`,
            house: wardData[wardKey].house,
            male: wardData[wardKey].male,
            female: wardData[wardKey].female,
            third: wardData[wardKey].third,
            total: wardData[wardKey].total,
        };
    });
    console.log(population);
    const dataWardWithoutTotal = unsortedDataWard.sort(function(a, b) {
        return a.index - b.index;
    });
    const dataWard = [
        ...dataWardWithoutTotal,
        {
            index: 100,
            ward: `सुन्दरहरैँचा`,
            house: dataWardWithoutTotal.reduce((a, b) => a + b.house, 0),
            male: dataWardWithoutTotal.reduce((a, b) => a + b.male, 0),
            female: dataWardWithoutTotal.reduce((a, b) => a + b.female, 0),
            third: dataWardWithoutTotal.reduce((a, b) => a + b.third, 0),
            total: dataWardWithoutTotal.reduce((a, b) => a + b.total, 0),
        },
    ];
    const add = (accumulator, a) => {
        return accumulator + a;
    };
    // const wardValues = [...Array(pages).keys()].map()
    return (
        <>
            <Card className="mb-4">
                <Card.Header>
                    <h3>समस्त विवरण</h3>
                </Card.Header>
                <Card.Body>
                    <Table
                        className="table table-hover"
                        columns={columnsWard}
                        data={dataWard}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

export default PopulationTable;
