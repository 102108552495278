import React from "react";
import { Card } from "react-bootstrap";
import { roofType, houseUsedLivingOption } from "../../data/houseSurvey";
import { selectedLanguage } from "../../data/institute";

const HouseRelatedDetails = ({ houseland }) => {
    return (
        <>
            <Card>
                <Card.Body className="house-detail">
                    <Card.Title>
                        <h3> घर सम्बन्धि विवरण</h3>
                    </Card.Title>
                    <h6>
                        घर संख्या :{" "}
                        {houseland.houseCount && houseland.houseCount}
                    </h6>
                    <h6>
                        घरको तला संख्या :{" "}
                        {houseland.totalStorey && houseland.totalStorey}
                    </h6>
                    <h6>
                        घरको कोठा संख्या :{" "}
                        {houseland.totalRoom && houseland.totalRoom}
                    </h6>
                    <h6>
                        हाल बसीरहेको घरमा सटर कोठा छ/छैन :{" "}
                        {houseland.hasShutterInHouse &&
                        houseland.hasShutterInHouse == true
                            ? "छ"
                            : "छैन"}
                    </h6>
                    <h6>
                        घर भाडामा दिनु भएको छ कि छैन? :{" "}
                        {houseland.houseRent && houseland.houseRent == true
                            ? "छ"
                            : "छैन"}
                    </h6>
                    <h6>
                        {" "}
                        घरको बनावटको प्रकार :{" "}
                        {roofType.find((i) => i.value === houseland.houseType)
                            ? roofType.find(
                                  (i) => i.value === houseland.houseType
                              )[selectedLanguage]
                            : houseland.houseType}
                    </h6>
                    <h6>
                        घरको नक्शा पास भएको छ कि छैन? :{" "}
                        {houseland.hasHouseMapPass == true ? "छ" : "छैन"}
                    </h6>
                    {/* <h6>7. नुनको किसिम :  {salttypeLists.find(i => i.value === secondaryHouseDetails.saltUsed) ? salttypeLists.find(i => i.value === secondaryHouseDetails.saltUsed)[selectedLanguage] : ""}</h6> */}
                    <h6>
                        घरको स्वामित्वको प्रकार :{" "}
                        {houseUsedLivingOption.find(
                            (i) => i.value === houseland.houseUsedLiving
                        )
                            ? houseUsedLivingOption.find(
                                  (i) => i.value === houseland.houseUsedLiving
                              )[selectedLanguage]
                            : houseland.houseUsedLiving}
                    </h6>
                    {/* <h6>8. परिवारको बीमा विवरण :  { }</h6> */}
                </Card.Body>
            </Card>
        </>
    );
};

export default HouseRelatedDetails;
