import React, { useState } from "react";
import { useEffect } from "react";
import CustomSelectMulti from "../components/CustomSelectMulti";
import SingleSelectInput from "../components/SingleSelectInput";
import {
    agriExpenseQn,
    agriIncomeQn,
    childLabourQuestion,
    covidEffect,
    covidEffectQn,
    ifOtherValueQuestion,
    loan,
    loanQuestion,
    loanTakenInFamily,
    loanTakenInFamilyQuestion,
    loanTakingReason,
    loanTakingReasonQuestion,
    mainBusiness,
    mainBusinessQn,
    mainIncomeSource,
    mainIncomeSourceQuestion,
    mainInvestment,
    mainInvestmentQn,
    newsMedium,
    newsMediumQn,
    nonAgriExpenseQn,
    nonAgriIncomeQn,
    pregnanctCheckQuestion,
    pregnantCheckup,
    secondaryQuestion,
    suggestion,
    suggestionQn,
    unhealthyTreatment,
    unhealthyTreatmentQn,
} from "../data/houseSurvey";
import {
    basicSchoolQuestion,
    secondarySchoolQuestion,
    wayToHospitalQuestion,
} from "../data/samajhikSurvey";
import { selectedLanguage } from "../data/institute";
import {
    KhopQue,
    babeDeathQuestion,
    bornAliveQuestion,
    bornPlace,
    bornPlaceQue,
    helpBornBabe,
    helpBornBabeQue,
    pregnantNoCheckUp,
    pregnantNoCheckUpQue,
    pregnantQuestion,
} from "../data/AarthikSurvey";

const SamajikForm = (props) => {
    useEffect(() => {}, []);
    console.log("form props", props);
    const { formik } = props;
    const { socialWelfare } = formik.values;
    return (
        <>
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                <div class="col-xl-12 col-xxl-8">
                    <div
                        class="pb-5"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                    >
                        <h3 class="mb-10 font-weight-bold text-dark">
                            सामाजिक विवरण
                        </h3>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>
                                        {
                                            wayToHospitalQuestion[
                                                selectedLanguage
                                            ]
                                        }
                                    </label>
                                    <br />
                                    <div className="d-flex flex-row">
                                        <label>
                                            पैदल <br />
                                            <span style={{ fontSize: "12px" }}>
                                                (मिनेटमा)
                                            </span>
                                        </label>
                                        <input
                                            type="number"
                                            class="form-control form-control-solid form-control-lg"
                                            onChange={formik.handleChange}
                                            name="socialWelfare.houseToHealthPostWalk"
                                            value={
                                                socialWelfare.houseToHealthPostWalk
                                            }
                                        />
                                        <label style={{ marginLeft: "10px" }}>
                                            सवारीसाधन
                                            <br />
                                            <span style={{ fontSize: "12px" }}>
                                                (मिनेटमा)
                                            </span>
                                        </label>
                                        <input
                                            type="number"
                                            class="form-control form-control-solid form-control-lg"
                                            onChange={formik.handleChange}
                                            name="socialWelfare.houseToHealthPostVehicle"
                                            value={
                                                socialWelfare.houseToHealthPostVehicle
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label className="column">
                                        {basicSchoolQuestion[selectedLanguage]}
                                    </label>
                                    <br />
                                    <div className="d-flex flex-row">
                                        <label>
                                            पैदल <br />
                                            <span style={{ fontSize: "12px" }}>
                                                (मिनेटमा)
                                            </span>
                                        </label>
                                        <input
                                            type="number"
                                            class="form-control form-control-solid form-control-lg"
                                            onChange={formik.handleChange}
                                            name="socialWelfare.houseToSchoolWalk"
                                            value={
                                                socialWelfare.houseToSchoolWalk
                                            }
                                        />

                                        <label style={{ marginLeft: "10px" }}>
                                            सवारीसाधन
                                            <br />
                                            <span style={{ fontSize: "12px" }}>
                                                (मिनेटमा)
                                            </span>
                                        </label>
                                        <input
                                            type="number"
                                            class="form-control form-control-solid form-control-lg"
                                            onChange={formik.handleChange}
                                            name="socialWelfare.houseToSchoolVehicle"
                                            value={
                                                socialWelfare.houseToSchoolVehicle
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label className="column">
                                        {
                                            secondarySchoolQuestion[
                                                selectedLanguage
                                            ]
                                        }
                                    </label>
                                    <br />
                                    <div className="d-flex flex-row mb-1">
                                        <label>
                                            पैदल <br />
                                            <span style={{ fontSize: "12px" }}>
                                                (मिनेटमा)
                                            </span>
                                        </label>
                                        <input
                                            type="number"
                                            class="form-control form-control-solid form-control-lg"
                                            onChange={formik.handleChange}
                                            name="socialWelfare.houseToSecondarySchoolWalk"
                                            value={
                                                socialWelfare.houseToSecondarySchoolWalk
                                            }
                                        />

                                        <label style={{ marginLeft: "10px" }}>
                                            सवारीसाधन <br />
                                            <span style={{ fontSize: "12px" }}>
                                                (मिनेटमा)
                                            </span>
                                        </label>
                                        <input
                                            type="number"
                                            class="form-control form-control-solid form-control-lg"
                                            onChange={formik.handleChange}
                                            name="socialWelfare.houseToSecondarySchoolVehicle"
                                            value={
                                                socialWelfare.houseToSecondarySchoolVehicle
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {unhealthyTreatmentQn[selectedLanguage]}
                                    </label>
                                    <SingleSelectInput
                                        id="unhealthyTreatment"
                                        name="socialWelfare.unhealthyTreatment"
                                        value={socialWelfare.unhealthyTreatment}
                                        formik={formik}
                                        selectList={unhealthyTreatment}
                                    />
                                </div>
                            </div> */}

                            {/* {socialWelfare[`unhealthyTreatment`] ===
                                "others_ill" && (
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {
                                                ifOtherValueQuestion[
                                                    selectedLanguage
                                                ]
                                            }
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            name={`socialWelfare.unhealthyTreatmentOther`}
                                            onChange={formik.handleChange}
                                            value={
                                                socialWelfare[
                                                    `unhealthyTreatmentOther`
                                                ]
                                            }
                                        />
                                    </div>
                                </div>
                            )} */}
                            {/* <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {familyInOrgQn[selectedLanguage]}
                                    </label>
                                    <CustomSelectMulti
                                        formik={formik}
                                        value={socialWelfare[`familyInOrg`]}
                                        isMulti={true}
                                        list={familyInOrg}
                                        field={`socialWelfare.familyInOrg`}
                                    />
                                </div>
                            </div>
                            {socialWelfare[`familyInOrg`]?.includes(
                                "others_club"
                            ) && (
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {
                                                ifOtherValueQuestion[
                                                    selectedLanguage
                                                ]
                                            }
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            name={`socialWelfare.familyInOrgOther`}
                                            onChange={formik.handleChange}
                                            value={
                                                socialWelfare[
                                                    `familyInOrgOther`
                                                ]
                                            }
                                        />
                                    </div>
                                </div>
                            )} */}
                            {/* <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {disputeResolveQn[selectedLanguage]}
                                    </label>
                                    <SingleSelectInput
                                        id="disputeResolve"
                                        name="socialWelfare.disputeResolve"
                                        value={socialWelfare.disputeResolve}
                                        formik={formik}
                                        selectList={disputeResolve}
                                    />
                                </div>
                            </div>
                            {socialWelfare[`disputeResolve`] ===
                                "other_complain" && (
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {
                                                ifOtherValueQuestion[
                                                    selectedLanguage
                                                ]
                                            }
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            name={`socialWelfare.disputeResolveOther`}
                                            onChange={formik.handleChange}
                                            value={
                                                socialWelfare[
                                                    `disputeResolveOther`
                                                ]
                                            }
                                        />
                                    </div>
                                </div>
                            )} */}
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {covidEffectQn[selectedLanguage]}
                                    </label>
                                    <SingleSelectInput
                                        id="covidEffect"
                                        name="socialWelfare.pandemicEffect"
                                        value={socialWelfare.pandemicEffect}
                                        formik={formik}
                                        selectList={covidEffect}
                                    />
                                </div>
                            </div>

                            {socialWelfare[`pandemicEffect`] === "others" && (
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {
                                                ifOtherValueQuestion[
                                                    selectedLanguage
                                                ]
                                            }
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            name={`socialWelfare.pandemicEffectOther`}
                                            onChange={formik.handleChange}
                                            value={
                                                socialWelfare[
                                                    `pandemicEffectOther`
                                                ]
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        तपाईलाई यो गाउँपालिका बाट प्रवाह हुने
                                        सेवा कस्तो लाग्छ ? (एक वाक्यमा
                                        लेख्नुहोस)
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control form-control-solid form-control-lg"
                                        onChange={formik.handleChange}
                                        name="socialWelfare.suggestion"
                                        value={socialWelfare.suggestion}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {newsMediumQn[selectedLanguage]}
                                    </label>
                                    <CustomSelectMulti
                                        formik={formik}
                                        value={
                                            socialWelfare[
                                                `meansOfMunicipalInformation`
                                            ]
                                        }
                                        isMulti={true}
                                        list={newsMedium}
                                        field={`socialWelfare.meansOfMunicipalInformation`}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {unhealthyTreatmentQn[selectedLanguage]}
                                    </label>
                                    <SingleSelectInput
                                        id="unhealthyTreatment"
                                        name="socialWelfare.illTreament"
                                        value={socialWelfare.illTreament}
                                        formik={formik}
                                        selectList={unhealthyTreatment}
                                    />
                                </div>
                            </div>
                            {socialWelfare.illTreament === "others_ill" && (
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {
                                                ifOtherValueQuestion[
                                                    selectedLanguage
                                                ]
                                            }
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            name={`socialWelfare.unhealthyTreatmentOther`}
                                            onChange={formik.handleChange}
                                            value={
                                                socialWelfare[
                                                    `unhealthyTreatmentOther`
                                                ]
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>
                                        {childLabourQuestion[selectedLanguage]}
                                    </label>
                                    <div className="radio-inline">
                                        <label className="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`socialWelfare.childLabour`}
                                                value="yes"
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    socialWelfare.childLabour ===
                                                    "yes"
                                                }
                                            />
                                            <span></span>छ
                                        </label>
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`socialWelfare.childLabour`}
                                                value="no"
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    socialWelfare.childLabour ===
                                                    "no"
                                                }
                                            />
                                            <span></span>
                                            छैन
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>
                                            {pregnantQuestion[selectedLanguage]}
                                        </label>
                                        <div className="radio-inline">
                                            <label className="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name={`socialWelfare.anyPregnant`}
                                                    value="yes"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    defaultChecked={
                                                        socialWelfare.anyPregnant ===
                                                        "yes"
                                                    }
                                                />
                                                <span></span>गर्भवती महिला भएको
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name={`socialWelfare.anyPregnant`}
                                                    value="no"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    defaultChecked={
                                                        socialWelfare.anyPregnant ===
                                                        "no"
                                                    }
                                                />
                                                <span></span>
                                                गर्भवती महिला नभएको
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </>
                            {socialWelfare.anyPregnant == "yes" && (
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {
                                                pregnanctCheckQuestion[
                                                    selectedLanguage
                                                ]
                                            }
                                        </label>
                                        <SingleSelectInput
                                            id="pregnantCheckup"
                                            name="socialWelfare.pregnantCheckup"
                                            value={
                                                socialWelfare.pregnantCheckup
                                            }
                                            formik={formik}
                                            selectList={pregnantCheckup}
                                        />
                                    </div>
                                </div>
                            )}
                            {socialWelfare.pregnantCheckup?.includes(
                                "sometimes"
                            ) && (
                                <>
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>
                                                {
                                                    pregnantNoCheckUpQue[
                                                        selectedLanguage
                                                    ]
                                                }
                                            </label>
                                            <SingleSelectInput
                                                id="pregnantNoCheckUp"
                                                name="socialWelfare.ifNotRegularCheckup"
                                                value={
                                                    socialWelfare.ifNotRegularCheckup
                                                }
                                                formik={formik}
                                                selectList={pregnantNoCheckUp}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {socialWelfare.pregnantCheckup?.includes(
                                "no_check_up"
                            ) && (
                                <>
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>
                                                {
                                                    pregnantNoCheckUpQue[
                                                        selectedLanguage
                                                    ]
                                                }
                                            </label>
                                            <SingleSelectInput
                                                id="pregnantNoCheckUp"
                                                name="socialWelfare.ifNotRegularCheckup"
                                                value={
                                                    socialWelfare.ifNotRegularCheckup
                                                }
                                                formik={formik}
                                                selectList={pregnantNoCheckUp}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {socialWelfare.anyPregnant == "yes" && (
                                <>
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>
                                                {
                                                    bornAliveQuestion[
                                                        selectedLanguage
                                                    ]
                                                }
                                            </label>
                                            <div className="radio-inline">
                                                <label className="radio radio-rounded">
                                                    <input
                                                        type="radio"
                                                        name={`socialWelfare.ifChildBorn`}
                                                        value="yes"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        defaultChecked={
                                                            socialWelfare.ifChildBorn ===
                                                            "yes"
                                                        }
                                                    />
                                                    <span></span>छ
                                                </label>
                                                <label class="radio radio-rounded">
                                                    <input
                                                        type="radio"
                                                        name={`socialWelfare.ifChildBorn`}
                                                        value="no"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        defaultChecked={
                                                            socialWelfare.ifChildBorn ===
                                                            "no"
                                                        }
                                                    />
                                                    <span></span>
                                                    छैन
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {socialWelfare.ifChildBorn == "yes" && (
                                        <>
                                            <div className="col-lg-6">
                                                <div class="form-group">
                                                    <label>
                                                        {
                                                            bornPlaceQue[
                                                                selectedLanguage
                                                            ]
                                                        }
                                                    </label>
                                                    <SingleSelectInput
                                                        id="bornPlace"
                                                        name="socialWelfare.bornWhere"
                                                        value={
                                                            socialWelfare.bornWhere
                                                        }
                                                        formik={formik}
                                                        selectList={bornPlace}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {socialWelfare.bornWhere?.includes(
                                        "others"
                                    ) && (
                                        <div className="col-lg-6">
                                            <div class="form-group">
                                                <label>
                                                    {
                                                        ifOtherValueQuestion[
                                                            selectedLanguage
                                                        ]
                                                    }
                                                </label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    name={`socialWelfare.bornPlaceOthers`}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    value={
                                                        socialWelfare[
                                                            `bornPlaceOthers`
                                                        ]
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {socialWelfare.ifChildBorn == "yes" && (
                                        <>
                                            <div className="col-lg-6">
                                                <div class="form-group">
                                                    <label>
                                                        {
                                                            helpBornBabeQue[
                                                                selectedLanguage
                                                            ]
                                                        }
                                                    </label>
                                                    <SingleSelectInput
                                                        id="helpBornBabe"
                                                        name="socialWelfare.helpWhileGivingBirth"
                                                        value={
                                                            socialWelfare.helpWhileGivingBirth
                                                        }
                                                        formik={formik}
                                                        selectList={
                                                            helpBornBabe
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {socialWelfare.helpWhileGivingBirth?.includes(
                                        "others"
                                    ) && (
                                        <div className="col-lg-6">
                                            <div class="form-group">
                                                <label>
                                                    {
                                                        ifOtherValueQuestion[
                                                            selectedLanguage
                                                        ]
                                                    }
                                                </label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    name={`socialWelfare.givingBirthOthers`}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    value={
                                                        socialWelfare[
                                                            `givingBirthOthers`
                                                        ]
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            {socialWelfare.anyPregnant == "yes" && (
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {
                                                babeDeathQuestion[
                                                    selectedLanguage
                                                ]
                                            }
                                        </label>
                                        <div className="radio-inline">
                                            <label className="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name={`socialWelfare.isChildDeathWithinSixMonths`}
                                                    value="thiyio"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    defaultChecked={
                                                        socialWelfare.isChildDeathWithinSixMonths ===
                                                        "thiyio"
                                                    }
                                                />
                                                <span></span>थियो
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name={`socialWelfare.isChildDeathWithinSixMonths`}
                                                    value="thiyena"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    defaultChecked={
                                                        socialWelfare.isChildDeathWithinSixMonths ===
                                                        "thiyena"
                                                    }
                                                />
                                                <span></span>
                                                थिएन
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>{KhopQue[selectedLanguage]}</label>
                                    <div class="radio-inline">
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`socialWelfare.hasVaccineForBelowFive`}
                                                value="yes"
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    socialWelfare.hasVaccineForBelowFive ===
                                                    "yes"
                                                }
                                            />
                                            <span></span>छ
                                        </label>
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`socialWelfare.hasVaccineForBelowFive`}
                                                value="no"
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    socialWelfare.hasVaccineForBelowFive ===
                                                    "no"
                                                }
                                            />
                                            <span></span>
                                            छैन
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SamajikForm;
