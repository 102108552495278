const BuffalloCaste = [
    { nepali: "स्थानीय", english: "Local", value: "local" },
    { nepali: "उनन्त", english: "Advanced", value: "advanced" },
];

const CowCaste = [
    { nepali: "स्थानीय", english: "Local", value: "local" },
    { nepali: "उनन्त", english: "Advanced", value: "advanced" },
];
const pigMeatProduction = {
    nepali: " सुँगुर /बंगुर  मासु उत्पादन  (के.जी ) (बार्षिक ",
    english: "Yearly production of pig meat",
    value: "",
};

const horseIncome = {
    nepali: "घोडा बाट बार्षिक आम्दानी (रु मा )",
    english: "Income from Horse",
    value: "",
};
const KhasiPathaNumber = {
    nepali: "खसी / पाठाको संख्या",
    english: "No. of Khasi and Patha number",
    value: "",
};

const ChickenCaste = [
    { nepali: "लेयर्स कुखुरा", english: "Layers", value: "layers" },
    { nepali: "ब्रोइलर कुखुरा", english: "Boiler", value: "boiler" },
    { nepali: "लोकल कुखुरा", english: "Local", value: "local" },
    { nepali: "अन्य ", english: "others", value: "others" },
];
// const otherAnimal = [
//     {
//         nepali: "अन्य एउटा मात्र",
//         english: "other one",
//         value: "one_animal_oth",
//     },
//     {
//         nepali: "अन्य दुई वा त्यो भन्दा बढी",
//         english: "more nimal",
//         value: "more_animal_ot",
//     },
// ];

module.exports = {
    //new fields from kobo
    BuffalloCaste,
    CowCaste,
    pigMeatProduction,
    horseIncome,
    KhasiPathaNumber,
    ChickenCaste,
    // otherAnimal,
};
