import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import {
    add,
    bridgeType,
    riverName,
    from,
    to,
    heightUnit,
    type,
    statusBridge,
    address,
    establishDate,
    height,
    name,
    photo,
    wardQuestion,
    areaList,
    ward,
    selectedLanguage,
    cancel,
} from "../data/institute";
import {
    wardList,
    toleWardOne,
    toleWardTwo,
    toleWardThree,
    toleWardFour,
    toleWardFive,
    toleWardSix,
    toleWardSeven,
    toleWardEight,
    toleWardTen,
    toleWardNine,
    toleWardEleven,
    toleWardTwelve,
} from "../data/ToleList";
import {
    toleFiveQn,
    toleFourQn,
    toleOneQn,
    toleThreeQn,
    toleTwoQn,
    toleSixQn,
    toleSevenQn,
    toleEightQn,
    toleNineQn,
    toleTenQn,
    toleElevenQn,
    toleTwelveQn,
} from "../data/houseSurvey";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
    wardNumber: "",
    name: "",
    toleName: "",
    riverName: "",
    streetName: "",
    bridgeType: "",
    status: "",
    address: "",
    establishedAt: "",
    bridgeHeight: "",
    from: "",
    to: "",
    uniqueIdentifier: GetUUID(),
};

const Bridge = (props) => {
    const [selectedWard, setSelectedWard] = useState(0);

    const wardData = [
        {
            question: toleOneQn,
            toleList: toleWardOne,
        },
        {
            question: toleTwoQn,
            toleList: toleWardTwo,
        },
        {
            question: toleThreeQn,
            toleList: toleWardThree,
        },
        {
            question: toleFourQn,
            toleList: toleWardFour,
        },
        {
            question: toleFiveQn,
            toleList: toleWardFive,
        },
        {
            question: toleSixQn,
            toleList: toleWardSix,
        },
    ];
    const getToleList = (ward) => {
        if (ward == 1) {
            return toleWardOne;
        } else if (ward == 2) {
            return toleWardTwo;
        } else if (ward == 3) {
            return toleWardThree;
        } else if (ward == 4) {
            return toleWardFour;
        } else if (ward == 5) {
            return toleWardFive;
        } else {
            return toleWardSix;
        }
    };
    const handleWardChange = (ward) => {
        setSelectedWard(ward);
    };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={props.data ? props.data : initData}
                validate={(values) => {
                    const errors = {};
                    // if (!values.ward) {
                    //     errors.ward = 'Required';
                    // }
                    // if (!values.riskArea) {
                    //     errors.riskArea = 'Required';
                    // }
                    // if (!values.address) {
                    //     errors.address = 'Required';
                    // }
                    // if (!values.effectFromDisasters) {
                    //     errors.effectFromDisasters = 'Required';
                    // }
                    // else
                    // if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    // 	errors.email = "Invalid email address";
                    // }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                    setTimeout(() => {
                        props.submit(values);
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                }) => (
                    <>
                        <form
                            className="form survey-form"
                            onSubmit={handleSubmit}
                        >
                            <Card>
                                <Card.Body>
                                    <div className="form-group row">
                                        {/* <div className="col-md-6">
                                            <label className="form-control-label">
                                                समूह, सँस्थाको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="समूह, सँस्थाको नाम"
                                                name="name"
                                                onChange={handleChange}
                                                value={values.name}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="name"
                                                component="div"
                                            />
                                        </div> */}
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                {wardQuestion[selectedLanguage]}
                                            </label>
                                            <select
                                                className="form-control"
                                                id="wardNumber"
                                                name="wardNumber"
                                                onChange={handleChange}
                                                handleWardChange={handleWardChange(
                                                    values.wardNumber
                                                )}
                                                value={
                                                    values.wardNumber
                                                        ? values.wardNumber
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                {wardList.map((woda) => (
                                                    <option value={woda.value}>
                                                        {woda[selectedLanguage]}
                                                    </option>
                                                ))}
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="wardNumber"
                                                component="div"
                                            />
                                        </div>

                                        {values.wardNumber && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    {
                                                        wardData[
                                                            values.wardNumber -
                                                                1
                                                        ].question[
                                                            selectedLanguage
                                                        ]
                                                    }
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="toleName"
                                                    name="toleName"
                                                    onChange={handleChange}
                                                    value={
                                                        values.toleName
                                                            ? values.toleName
                                                            : "selectOne"
                                                    }
                                                >
                                                    <option
                                                        value="selectOne"
                                                        disabled
                                                    >
                                                        कुनै एक छान्नुहोस्
                                                    </option>
                                                    {getToleList(
                                                        values.wardNumber
                                                    ).map((woda) => (
                                                        <option
                                                            value={woda.value}
                                                        >
                                                            {
                                                                woda[
                                                                    selectedLanguage
                                                                ]
                                                            }
                                                        </option>
                                                    ))}
                                                </select>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="ward"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                मार्गको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="मार्गको नाम"
                                                name="streetName"
                                                onChange={handleChange}
                                                value={values.streetName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="streetName"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                पुलको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="पुलको नाम"
                                                name="name"
                                                onChange={handleChange}
                                                value={values.name}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="name"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                {riverName[selectedLanguage]}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={
                                                    riverName[selectedLanguage]
                                                }
                                                name="riverName"
                                                onChange={handleChange}
                                                value={values.riverName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="riverName"
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-control-label">
                                                {from[selectedLanguage]}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={
                                                    from[selectedLanguage]
                                                }
                                                name="from"
                                                onChange={handleChange}
                                                value={values.community?.from}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="from"
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label className="form-control-label">
                                                {to[selectedLanguage]}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={
                                                    to[selectedLanguage]
                                                }
                                                name="to"
                                                onChange={handleChange}
                                                value={values.community?.to}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="to"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                {type[selectedLanguage]}
                                            </label>
                                            <select
                                                className="form-control"
                                                id="bridgeType"
                                                name="bridgeType"
                                                onChange={handleChange}
                                                value={
                                                    values.bridgeType
                                                        ? values.bridgeType
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                {bridgeType.map((type) => (
                                                    <option value={type.value}>
                                                        {type[selectedLanguage]}
                                                    </option>
                                                ))}
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="bridgeType"
                                                component="div"
                                            />
                                        </div>

                                        {values.bridgeType == "other" && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    अन्य भए उल्लेख गर्नुहोस्
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="अन्य भए उल्लेख गर्नुहोस्"
                                                    name="bridgeTypeOther"
                                                    onChange={handleChange}
                                                    value={
                                                        values.bridgeTypeOther
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="bridgeTypeOther"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                {statusBridge[selectedLanguage]}
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="best"
                                                    name="status"
                                                    onChange={handleChange}
                                                />
                                                <span></span>राम्रो
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="good"
                                                    name="status"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                ठिकै
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="jirna"
                                                    name="status"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                जिर्ण
                                            </label>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="status"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                पुल स्थापना भएको साल (निर्माण
                                                भएको साल )
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="पुल स्थापना भएको साल (निर्माण भएको साल )"
                                                name="establishedAt"
                                                onChange={handleChange}
                                                value={values.establishedAt}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="establishedAt"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                पुलको लम्बाई (मिटर)
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="पुलको लम्बाई (मिटर)"
                                                name="bridgeHeight"
                                                onChange={handleChange}
                                                value={values.bridgeHeight}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="bridgeHeight"
                                                component="div"
                                            />
                                        </div>
                                        {/* <div className="col-md-3">
										<label className="form-control-label">
											{heightUnit[selectedLanguage]}
										</label>
										<select
											className="form-control"
											id="bridgeHeightType"
											name="bridgeHeightType"
											onChange={handleChange}
											value={values.bridgeHeightType}
										>
											{areaList.map((type) => (
												<option value={type.value}>
													{type[selectedLanguage]}
												</option>
											))}
										</select>
										<ErrorMessage
											className="invalid-feedback"
											name="bridgeHeightType"
											component="div"
										/>
									</div> */}
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                अक्षांश
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lat"
                                                onChange={handleChange}
                                                value={values.geo?.lat}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="lat"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                देशान्तर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lng"
                                                onChange={handleChange}
                                                value={values.geo?.lng}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="lng"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label>
                                                {photo[selectedLanguage]}
                                            </label>
                                            <ImageUploader
                                                fileListContent={
                                                    props.fileListContent
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="card-footer text-right">
                                        <FormFooterButtons />
                                    </div>
                                </Card.Body>
                            </Card>
                        </form>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default Bridge;
