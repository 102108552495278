export const answererName = {
    nepali: "उत्तरदाताको नाम ",
    english: "Answerer name",
};

export const answererRelationQuestion = {
    nepali: "उत्तरदाताको घरमुली संगको नाता के हो ?",
    english: "What is the answerer relation with house owner?",
};

// use both in primary detail and fmaily details
export const answererRelation = [
    {
        nepali: "घरमुली आफै",
        english: "Self house owner",
        value: "self_house_own",
    },
    {
        nepali: "श्रीमान",
        english: "Husband",
        value: "husband",
    },
    {
        nepali: "श्रीमती",
        english: "Wife",
        value: "wife",
    },
    {
        nepali: "आमा",
        english: "Mother",
        value: "mother",
    },
    {
        nepali: "बुवा",
        english: "Father",
        value: "father",
    },
    {
        nepali: "छोरा",
        english: "Son",
        value: "son",
    },
    {
        nepali: "छोरी",
        english: "Daughter",
        value: "daughter",
    },
    {
        nepali: "बुहारी",
        english: "Buhari",
        value: "buhari",
    },
    {
        nepali: "ज्वाई",
        english: "Jwai",
        value: "jwai",
    },
    {
        nepali: "सासु",
        english: "Sasu",
        value: "sashu",
    },
    {
        nepali: "ससुरा",
        english: "Sasura",
        value: "sasura",
    },
    {
        nepali: "काका",
        english: "Kaka",
        value: "kaka",
    },
    {
        nepali: "काकी",
        english: "Kaki",
        value: "kaki",
    },
    {
        nepali: "फुपू",
        english: "Fupu",
        value: "fupu",
    },
    {
        nepali: "फुपाजु",
        english: "Fupaju",
        value: "fupaju",
    },
    {
        nepali: "मामा",
        english: "Mama",
        value: "mama",
    },
    {
        nepali: "माइजु",
        english: "Maiju",
        value: "maiju",
    },
    {
        nepali: "नाती",
        english: "Nati",
        value: "nati",
    },
    {
        nepali: "नातिनी",
        english: "Natini",
        value: "natini",
    },
    {
        nepali: "दाजु",
        english: "Daju",
        value: "daju",
    },
    {
        nepali: "भाई",
        english: "Bhai",
        value: "bhai",
    },
    {
        nepali: "दिदि",
        english: "Didi",
        value: "didi",
    },
    {
        nepali: "बहिनी",
        english: "Bahini",
        value: "bahini",
    },
    {
        nepali: "भाउजु",
        english: "Bhauju",
        value: "bhauju",
    },
    {
        nepali: "भाई बुहारी",
        english: "Bhai Buhari",
        value: "bhai_buhari",
    },
    {
        nepali: "जेठान",
        english: "Jethan",
        value: "jethan",
    },
    {
        nepali: "ठुलो बुवा",
        english: "Thulo Buwa",
        value: "thulo_bbuwa",
    },
    {
        nepali: "ठुलो आमा",
        english: "Thulo aama",
        value: "thulo_aama",
    },
    {
        nepali: "हजुर बुवा",
        english: "",
        value: "grand_father",
    },
    {
        nepali: "हजुर आमा",
        english: "Grand mother",
        value: "grand_mother",
    },
    {
        nepali: "देवर",
        english: "Dewar",
        value: "dewar",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];
export const memberName = { nepali: "नाम/थर", english: "Full name" };

export const contactNo = { nepali: "को सम्पर्क न", english: "Contact no" };

export const genderQuestion = { nepali: "को लिङ्ग ?", english: "Gender" };

export const dateQuestion = { nepali: "जन्म मिती", english: "Date of Birth" };

export const ageQuestion = { nepali: "को उमेर", english: "Age" };

const totalYear = 140;
const dateYear = [...Array(totalYear).keys()];
export const yearList = dateYear.map((eachD) => {
    var obj = {};
    obj.nepali = eachD + 1;
    obj.english = eachD + 1;
    obj.value = eachD + 1;
});

export const monthList = [
    { nepali: "बैशाख", english: "baishakh", value: "baishakh" },
    { nepali: "जेठ", english: "jestha", value: "jestha" },
    { nepali: "असार", english: "asar", value: "asar" },
    { nepali: "श्रावण", english: "shrawan", value: "shrawan" },
    { nepali: "भदौ", english: "bhadra", value: "bhadra" },
    { nepali: "आश्विन", english: "aswin", value: "aswin" },
    { nepali: "कार्तिक", english: "kartik", value: "kartik" },
    { nepali: "मंसिर", english: "mangsir", value: "mangsir" },
    { nepali: "पुष", english: "poush", value: "poush" },
    { nepali: "माघ", english: "magh", value: "magh" },
    { nepali: "फाल्गुन", english: "falgun", value: "falgun" },
    { nepali: "चैत्र", english: "chaitra", value: "chaitra" },
];

export const dateList = [
    { nepali: "१", english: "1", value: "1" },
    { nepali: "२", english: "2", value: "2" },
    { nepali: "३", english: "3", value: "3" },
    { nepali: "४", english: "4", value: "4" },
    { nepali: "५", english: "5", value: "5" },
    { nepali: "६", english: "6", value: "6" },
    { nepali: "७", english: "7", value: "7" },
    { nepali: "८", english: "8", value: "8" },
    { nepali: "९", english: "9", value: "9" },
    { nepali: "१०", english: "10", value: "10" },
    { nepali: "११", english: "11", value: "11" },
    { nepali: "१२", english: "12", value: "12" },
    { nepali: "१३", english: "13", value: "13" },
    { nepali: "१४", english: "14", value: "14" },
    { nepali: "१५", english: "15", value: "15" },
    { nepali: "१६", english: "16", value: "16" },
    { nepali: "१७", english: "17", value: "17" },
    { nepali: "१८", english: "18", value: "18" },
    { nepali: "१९", english: "19", value: "19" },
    { nepali: "२०", english: "20", value: "20" },
    { nepali: "२१", english: "21", value: "21" },
    { nepali: "२२", english: "22", value: "22" },
    { nepali: "२३", english: "23", value: "23" },
    { nepali: "२४", english: "24", value: "24" },
    { nepali: "२५", english: "25", value: "25" },
    { nepali: "२६", english: "26", value: "26" },
    { nepali: "२७", english: "27", value: "27" },
    { nepali: "२८", english: "28", value: "28" },
    { nepali: "२९", english: "29", value: "29" },
    { nepali: "३०", english: "30", value: "30" },
    { nepali: "३१", english: "31", value: "31" },
    { nepali: "३२", english: "32", value: "32" },
];

export const gender = [
    {
        nepali: "पुरुष",
        english: "Male",
        value: "male",
    },
    {
        nepali: "महिला",
        english: "Female",
        value: "female",
    },
    {
        nepali: "अन्य",
        english: "other sex",
        value: "other_sex",
    },
];

export const livingStatusQuestion = {
    nepali: "को वसोवासको स्थिति",
    english: "Living situation",
};

export const livingStatus = [
    {
        nepali: "गणना गरेको ठाउँमा बस्ने  (घरमै )",
        english: "Same place of data collection",
        value: "same_place",
    },
    {
        nepali: "स्वदेशमा  अन्यत्र बसेको",
        english: "Other place",
        value: "other_place",
    },
    {
        nepali: "विदेशमा बसेको",
        english: "Foreign",
        value: "foreign",
    },
];

export const livingStatusOtherPlace = [
    {
        nepali: "यहि छिमेकी गाउँ/नगरपालिका",
        english: "",
        value: "inside_sindhuli",
    },
    {
        nepali: "नगरपालिका भित्र अर्को वडा",
        english: "",
        value: "another_ward",
    },
    {
        nepali: "काठमाडौँ",
        english: "",
        value: "kathmandu",
    },
    {
        nepali: "प्रदेश १.बाहिर",
        english: "",
        value: "outside_pradesh_gandaki",
    },
    {
        nepali: "अन्यत्र",
        english: "",
        value: "others",
    },
];

export const livingStatusOtherReason = [
    {
        nepali: "अन्यत्र घर भएकाले",
        english: "",
        value: "house_there",
    },
    {
        nepali: "रोजगारका  लागी",
        english: "",
        value: "for_employee",
    },
    {
        nepali: "शिक्षा (पढाईका लागी )",
        english: "",
        value: "to_study",
    },
    {
        nepali: "व्यापार",
        english: "",
        value: "for_business",
    },
    {
        nepali: "अन्य",
        english: "",
        value: "others_work",
    },
];

//if विदेश is clicked

export const abroadReasonQuestion = {
    nepali: "विदेश गएको कारण ?",
    english: "Reason to go abroad",
};

export const abroadReason = [
    {
        nepali: "अध्ययन / तालिम",
        english: "Education",
        value: "education",
    },
    {
        nepali: "तलब/ ज्याला / नोकरी",
        english: "Employment",
        value: "nokari",
    },
    {
        nepali: "व्यापार /व्यावसाय",
        english: "business",
        value: "Business",
    },
    {
        nepali: "डी.भी. चिठ्ठा",
        english: "DV Lottery",
        value: "dv_lottery",
    },
    {
        nepali: "आश्रित",
        english: "aashreet",
        value: "aashreet",
    },
    {
        nepali: "अन्य",
        english: "other reason",
        value: "other_reason",
    },
];

export const abroadYearQuestion = {
    nepali: "कती समय अवधिको लागी जानु भएको हो ? (६ महिना भयो भने 0.6 लेख्ने )",
    english: "How many year you have been in foreign?",
};

export const abroadCountryQuestion = {
    nepali: "विदेश गएको देश?",
    english: "Which country?",
};

export const abroadCountry = [
    {
        nepali: "भारत",
        english: "India",
        value: "india",
    },
    {
        nepali: "कतार",
        english: "Quatar",
        value: "quatar",
    },
    {
        nepali: "भारत बाहेक अन्य सार्क राष्ट्र",
        english: "SAARC",
        value: "saarc",
    },
    {
        nepali: "साउदी अरब",
        english: "Saudi Arab",
        value: "saudi_arab",
    },
    {
        nepali: "मलेसिया",
        english: "Malayasia",
        value: "malaysia",
    },
    {
        nepali: "जापान",
        english: "Japan",
        value: "japan",
    },
    {
        nepali: "यु.ए.ई. (दुबई)",
        english: "UAE",
        value: "uae",
    },
    {
        nepali: "दक्षिण कोरिया",
        english: "South Korea",
        value: "south_korea",
    },
    {
        nepali: "अष्ट्रेलिया",
        english: "Australia",
        value: "australia",
    },
    {
        nepali: "अमेरिका",
        english: "USA",
        value: "usa",
    },
    {
        nepali: "इराक",
        english: "Iraq",
        value: "iraak",
    },
    {
        nepali: "कुवेत",
        english: "Kuwait",
        value: "kuwait",
    },
    {
        nepali: "क्यानाडा",
        english: "Canada",
        value: "canada",
    },

    {
        nepali: "बहराइन",
        english: "Bahrain",
        value: "bahrain",
    },
    {
        nepali: "ओमान",
        english: "Oman",
        value: "oman",
    },
    {
        nepali: "साइप्रस",
        english: "Cyprus",
        value: "cyprus",
    },
    {
        nepali: "फ्रान्स",
        english: "France",
        value: "france",
    },
    {
        nepali: "चीन",
        english: "China",
        value: "china",
    },
    {
        nepali: "UK",
        english: "UK",
        value: "uk",
    },
    {
        nepali: "स्पेन",
        english: "Spain",
        value: "germany",
    },
    {
        nepali: "इटाली",
        english: "Italy",
        value: "italy",
    },
    {
        nepali: "बेल्जियम",
        english: "Belgium",
        value: "belgium",
    },
    {
        nepali: "पोल्याण्ड",
        english: "Poland",
        value: "poland",
    },
    {
        nepali: "पोर्चुगल",
        english: "Portugal",
        value: "portugal",
    },
    {
        nepali: "अन्य",
        english: "other country",
        value: "other",
    },
];

export const casteQuestion = { nepali: "को जात/जाति", english: "caste" };

export const casteList = [
    {
        english: "sherpa",
        nepali: "शेर्पा",
        value: "sherpa",
    },
    {
        english: "tamang",
        nepali: "तामांङ",
        value: "tamang",
    },
    {
        english: "chhetri",
        nepali: "क्षेत्री",
        value: "chhetri",
    },
    {
        english: "thaami",
        nepali: "थामी",
        value: "thaami",
    },
    {
        english: "brahmin",
        nepali: "ब्राहमण",
        value: "brahmin",
    },
    {
        english: "rai",
        nepali: "राई",
        value: "rai",
    },
    {
        english: "magar",
        nepali: "मगर",
        value: "magar",
    },
    {
        english: "newar",
        nepali: "नेवार",
        value: "newar",
    },
    {
        english: "gurung",
        nepali: "गुरुङ",
        value: "gurung",
    },
    {
        english: "sanyasi",
        nepali: "सन्यासी /दशनामी",
        value: "sanyasi",
    },
    {
        english: "dhami",
        nepali: "धामी",
        value: "dhami",
    },
    {
        english: "kumal pahadi",
        nepali: "कुमाल पहाडी",
        value: "kumal_pahadi",
    },
    {
        english: "limbu",
        nepali: "लिम्बु",
        value: "limbu",
    },
    {
        english: "biswokarma",
        nepali: "विश्वकर्मा",
        value: "biswokarma",
    },
    {
        english: "saarki",
        nepali: "सार्की",
        value: "saarki",
    },
    {
        english: "Damai",
        nepali: "दमाई",
        value: "damai",
    },
    {
        english: "bhote",
        nepali: "भोटे",
        value: "bhote",
    },
    {
        english: "pariyaar",
        nepali: "परियार",
        value: "pariyaar",
    },
    {
        english: "muslim",
        nepali: "मुस्लिम",
        value: "muslim",
    },
    {
        english: "raaute",
        nepali: "राउटे",
        value: "raaute",
    },
    {
        english: "pahari",
        nepali: "पहरी",
        value: "pahari",
    },
    {
        english: "maajhi",
        nepali: "माझी",
        value: "maajhi",
    },
    {
        english: "madhesi",
        nepali: "मधेसी",
        value: "madhesi",
    },
    {
        english: "chamaar",
        nepali: "चमार",
        value: "chamaar",
    },
    {
        english: "dhimal",
        nepali: "धिमाल",
        value: "dhimal",
    },
    {
        english: "chaudhari",
        nepali: "चौधरी",
        value: "chaudhari",
    },
    {
        english: "satar",
        nepali: "सतार",
        value: "satar",
    },
    {
        english: "danuwar",
        nepali: "दनुवार",
        value: "danuwar",
    },
    {
        english: "Rajbanshi",
        nepali: "राजवंशी",
        value: "Rajbanshi",
    },
    {
        english: "khawaas",
        nepali: "खवास",
        value: "khawaas",
    },
    {
        english: "pattharkatta",
        nepali: "पत्थरकट्टा",
        value: "pattharkatta",
    },
    {
        english: "gangaai",
        nepali: "गनगाई",
        value: "gangaai",
    },
    {
        english: "tharu",
        nepali: "थारु",
        value: "tharu",
    },
    {
        english: "musahar",
        nepali: "मुसहर",
        value: "musahar",
    },
    {
        english: "yaadaw",
        nepali: "यादव",
        value: "yaadaw",
    },
    {
        english: "kalwaar",
        nepali: "कलवार",
        value: "kalwaar",
    },
    {
        english: "teli",
        nepali: "तेली",
        value: "teli",
    },
    {
        english: "koiri",
        nepali: "कोइरी",
        value: "koiri",
    },
    {
        english: "kurmi",
        nepali: "कुर्मी",
        value: "kurmi",
    },
    {
        english: "thakur",
        nepali: "हजाम /ठाकुर",
        value: "thakur",
    },
    {
        english: "dusaad",
        nepali: "दुसाध",
        value: "dusaad",
    },
    {
        english: "bhujel",
        nepali: "भुजेल",
        value: "bhujel",
    },
    {
        english: "baantar",
        nepali: "बाँतर",
        value: "baantar",
    },
    {
        english: "jhaagad",
        nepali: "झाँगड",
        value: "jhaagad",
    },
    {
        english: "other caste",
        nepali: "अन्य",
        value: "other_caste",
    },
];

// if जनजाती आदिवासी is clicked
export const aadibasiQuestion = {
    nepali: "कुन जनजाती हो ?",
    english: "Which caste",
};

export const aadibasi = [
    {
        nepali: "राई",
        english: "Rai",
        value: "rai",
    },
    {
        nepali: "मगर",
        english: "Magar",
        value: "magar",
    },
    {
        nepali: "शेर्पा",
        english: "Sherpa",
        value: "sherpa",
    },
    {
        nepali: "गुरुङ्ग",
        english: "Gurung",
        value: "gurung",
    },
    {
        nepali: "सुनुवार",
        english: "Sunuwar",
        value: "sunuwar",
    },
    {
        nepali: "तामाङ्ग",
        english: "Tamang",
        value: "tamang",
    },
    {
        nepali: "नेवार",
        english: "Newar",
        value: "newar",
    },
    {
        nepali: "थकाली",
        english: "Thakali",
        value: "thakali",
    },
    {
        nepali: "लिम्बु",
        english: "Lilbu",
        value: "limbu",
    },
    {
        nepali: "भुजेल",
        english: "Bhujel",
        value: "bhujel",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

export const religionQuestion = { nepali: "को धर्म", english: "religion" };

export const religionList = [
    { english: "Hindu", nepali: "हिन्दु", value: "hindu" },
    { english: "Buddhist", nepali: "बौद्ध", value: "buddhist" },
    { english: "Muslim", nepali: "इस्लाम", value: "muslim" },
    { english: "Christian", nepali: "क्रिश्चियन", value: "christian" },
    { english: "Kirat", nepali: "किरात", value: "kirat" },
    { english: "Boon", nepali: "वोन", value: "boon" },
    { english: "Prakriti", nepali: "प्रकृति", value: "prakriti" },
    { english: "Jain", nepali: "जैन", value: "jain" },
    { english: "Bahai", nepali: "बहाई", value: "bahai" },
    { english: "yuma", nepali: "युमा", value: "yuma" },
    { english: "Other", nepali: "अन्य", value: "other_religion" },
];

//marriage

export const marriageQuestion = {
    nepali: "को बैवाहिक स्थिति",
    english: "Marital status",
};

export const marriage = [
    {
        nepali: "अविवाहित",
        english: "Unmarried",
        value: "unmarried",
    },
    {
        nepali: "विवाहित",
        english: "Married",
        value: "married",
    },
    {
        nepali: "विदुर / एकल पुरुष",
        english: "widower",
        value: "widower",
    },
    {
        nepali: "एकल महिला (विधुवा)",
        english: "alone_women",
        value: "alone_women",
    },
    {
        nepali: "सम्बन्ध बिच्छेद",
        english: "Divorce",
        value: "divorce",
    },
    {
        nepali: "विवाहित तर अलग बसेको",
        english: "Married but separated",
        value: "marriage_but_l",
    },
];

//education

//  age >= 4 and age <16
export const isGoingToSchoolQn = {
    nepali: "हाल विद्यालय जानु हुन्छ कि हुन्न ?",
    english: "",
};

export const isGoingToSchool = [
    {
        english: "Yes",
        nepali: "जानु हुन्छ",
        value: "yes_going",
    },
    {
        english: "No",
        nepali: "जानु हुन्न",
        value: "not_going",
    },
];

export const notGoingSchoolQn = {
    nepali: "जानु हुन्न भने किन जानु हुन्न ?",
    english: "",
};

export const notGoingSchool = [
    {
        nepali: "पढने खर्च नभएर",
        english: "",
        value: "unknown_about_importance_of_education",
    },
    {
        nepali: "अभिभावकले नपठाएर",
        english: "",
        value: "no_guidance_of_parents",
    },
    {
        nepali: "बिद्यालय टाडा भएर",
        english: "",
        value: "far_school",
    },
    {
        nepali: "विद्यालयमा कमजोर विद्यार्थीलाई ध्यान नदिएर",
        english: "",
        value: "weak_students",
    },
    {
        nepali: "नराम्रो साथीको संगतमा वा साथीको लहैलहैमा लागेर",
        english: "",
        value: "bad_friend",
    },
    {
        nepali: "घरको काम गर्नुपर्ने भएर",
        english: "",
        value: "house_work",
    },
    {
        nepali: "अन्य",
        english: "",
        value: "others",
    },
];

export const schooLeftInMiddleReason = [
    {
        nepali: "पढने खर्च नभएर",
        english: "",
        value: "no_money_to_read",
    },
    {
        nepali: "विवाह भएर",
        english: "",
        value: "marriage_bhayera",
    },
    {
        nepali: "पढने ईछ्या नभएर",
        english: "",
        value: "padhane_ichhya_nabhera",
    },
    {
        nepali: "शैक्षिक सँस्था टाढा भएर",
        english: "",
        value: "long_distance",
    },
    {
        nepali: "बसाईसराई भएकोले",
        english: "",
        value: "basaaisarai",
    },
    {
        nepali: "अन्य",
        english: "",
        value: "others",
    },
];
// close

export const educationQuestion = { nepali: "को शिक्षा", english: "Education" };

export const education = [
    {
        nepali: "वाल विकास कक्षा (१ कक्षा भन्दा कम)",
        english: "Child development class",
        value: "less_than_one",
    },
    {
        nepali: "आधारभूत तह  (कक्षा  १ देखि ८ कक्षा सम्म )",
        english: "Primary",
        value: "one_to_eight",
    },
    {
        nepali: "माध्यमिक तह (कक्षा ९ देखि १२ कक्षा सम्म )",
        english: "Secondary",
        value: "nine_to_twelve",
    },
    {
        nepali: "स्नातक तह",
        english: "bachelors",
        value: "bachelors",
    },
    {
        nepali: "स्नातकोत्तर  तह",
        english: "masters",
        value: "masters",
    },
    {
        nepali: "एमफिल",
        english: "mphil",
        value: "mphil",
    },
    {
        nepali: "विद्यावारिधी (पिएचडि)",
        english: "Phd",
        value: "phd",
    },
    {
        nepali: "प्राविधिक एस.एल.सी",
        english: "slc",
        value: "slc",
    },
    {
        nepali: "प्राविधिक डिप्लोमा",
        english: "Technical Diploma",
        value: "technical_diploma",
    },
    {
        nepali: "साधारण लेख पढ",
        english: "literate",
        value: "simple_litrate",
    },
];

export const studyFieldQn = { nepali: "अध्ययनको क्षेत्रकुन हो ?", english: "" };

export const studyField = [
    {
        nepali: "व्यवस्थापन",
        english: "",
        value: "management",
    },
    {
        nepali: "विज्ञान",
        english: "",
        value: "science",
    },
    {
        nepali: "शिक्षा",
        english: "",
        value: "education",
    },
    {
        nepali: "इन्जिनियरिंग",
        english: "",
        value: "engineer",
    },
    {
        nepali: "डाक्टर",
        english: "",
        value: "doctor",
    },
    {
        nepali: "नर्स",
        english: "",
        value: "nurse",
    },
    {
        nepali: "कानुन",
        english: "",
        value: "wakil",
    },
    {
        nepali: "मानविकी",
        english: "",
        value: "manawiki",
    },
    {
        nepali: "कृषि",
        english: "",
        value: "agi",
    },
    {
        nepali: "सामाजिकशास्त्र",
        english: "",
        value: "social",
    },
    {
        nepali: "राजनीतिशास्त्र",
        english: "",
        value: "political_science",
    },
    {
        nepali: "सूचना प्रविधि",
        english: "",
        value: "computer",
    },
    {
        nepali: "होटल म्यानेजमेन्ट",
        english: "",
        value: "hotal_management",
    },
    {
        nepali: "वन",
        english: "",
        value: "forest",
    },
    {
        nepali: "पशुविज्ञान",
        english: "",
        value: "animal_science",
    },
    {
        nepali: "मत्स्यपालन",
        english: "",
        value: "fisheries",
    },
    {
        nepali: "पत्रकारिता",
        english: "",
        value: "newsrepoter",
    },
    {
        nepali: "अन्य",
        english: "",
        value: "other",
    },
];

//if पूर्व प्राथमिक is clicked

export const preprimaryQuestion = {
    nepali: "पूर्व प्राथमिक कुन कक्षा?",
    english: "Pre primary education",
};

export const preprimary = [
    {
        nepali: "१",
        english: "1",
        value: "class_1",
    },
    {
        nepali: "२",
        english: "2",
        value: "class_2",
    },
    {
        nepali: "३",
        english: "3",
        value: "class_3",
    },
    {
        nepali: "४",
        english: "4",
        value: "class_4",
    },
    {
        nepali: "५",
        english: "5",
        value: "class_5",
    },
];

//if आधारभूत तह is clicked

export const primaryQuestion = {
    nepali: "आधारभूत तह कुन कक्षा?",
    english: "Primary",
};

export const primary = [
    {
        nepali: "१",
        english: "1",
        value: "one",
    },
    {
        nepali: "२",
        english: "2",
        value: "two",
    },
    {
        nepali: "३",
        english: "3",
        value: "three_1",
    },
    {
        nepali: "४",
        english: "4",
        value: "four_1",
    },
    {
        nepali: "५",
        english: "5",
        value: "five_1",
    },
    {
        nepali: "६",
        english: "6",
        value: "six_1",
    },
    {
        nepali: "७",
        english: "7",
        value: "seven",
    },
    {
        nepali: "८",
        english: "8",
        value: "eight",
    },
];

//if माध्यमिक तह is clicked

export const secondaryQuestion = {
    nepali: "माध्यमिक तह कुन कक्षा?",
    english: "Secondary education",
};

export const secondary = [
    {
        nepali: "९",
        english: "9",
        value: "9",
    },
    {
        nepali: "१०",
        english: "10",
        value: "10",
    },
    {
        nepali: "११",
        english: "11",
        value: "11",
    },
    {
        nepali: "१२",
        english: "12",
        value: "12",
    },
];

//occupation

export const occupationQuestion = { nepali: "को पेशा", english: "Ocupation" };

export const occupation = [
    {
        nepali: "कृषि तथा पशुपालन",
        english: "Agriculture",
        value: "agriculture",
    },
    // {
    //     nepali: "कृषि तथा पशुपालन (व्यावसाहिक )",
    //     english: "Agriculture",
    //     value: "agriculture_animal_business",
    // },
    {
        nepali: "नोकरी /जागिर",
        english: "Job",
        value: "job",
    },
    {
        nepali: "उद्योग  तथा व्यापार",
        english: "Industry",
        value: "industry",
    },
    {
        nepali: "वेरोजगार",
        english: "Unemployment",
        value: "unemployment",
    },
    {
        nepali: "ज्याला /मजदुरी",
        english: "Lbor",
        value: "labor",
    },
    {
        nepali: "विद्यार्थी (अध्यनरत)",
        english: "Student",
        value: "student",
    },
    {
        nepali: "गृहणी",
        english: "House wife",
        value: "house_wife",
    },
    {
        nepali: "वैदेशिक रोजगारी",
        english: "Foreign job",
        value: "foreign_job",
    },
    {
        nepali: "अन्य",
        english: "Other job",
        value: "other_job",
    },
];

export const agriWorkField = [
    {
        nepali: "कृषि/बाली",
        english: "",
        value: "krishi_bali",
    },
    {
        nepali: "पशुपन्छी",
        english: "",
        value: "pashupanchi",
    },
    {
        nepali: "मिश्रीत",
        english: "",
        value: "mishrit",
    },
];

export const timeSpanForAgri = [
    {
        nepali: "३ महिना",
        english: "",
        value: "3_months",
    },
    {
        nepali: "४-६ महिना",
        english: "",
        value: "4to6_months",
    },
    {
        nepali: "७-९ महिना",
        english: "",
        value: "7to9_months",
    },
    {
        nepali: "बर्षै भरि",
        english: "",
        value: "yearly",
    },
];

//if नोकरी \ जागिर is clicked

export const jobQuestion = { nepali: "को नोकरी  जागिर", english: "Job" };

export const job = [
    {
        nepali: "गीटि कुट्ने",
        english: "stone beating",
        value: "stone_beating",
    },
    {
        nepali: "भारी बोक्ने",
        english: "carry load",
        value: "carry_load",
    },
    {
        nepali: "कृषि मजदुर",
        english: "agri_labour",
        value: "agri_labour",
    },
    {
        nepali: "सिकर्मी /डकर्मी",
        english: "sikarmi",
        value: "sikarmi",
    },
    {
        nepali: "खोलामा माछामार्ने",
        english: "fishing",
        value: "fishing",
    },
    {
        nepali: "पर्यटक घुमाउने",
        english: "tourist ghumaaune",
        value: "tourist_ghumaaune",
    },
    {
        nepali: "बालुवा चाल्ने",
        english: "sand refining",
        value: "sand_refining",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other_labor",
    },
];

//if सुरक्षा निकाय is clicked

export const securityQuestion = {
    nepali: "कुन सुरक्षा निकाय?",
    english: "Which security depart?",
};

export const security = [
    {
        nepali: "नेपाल प्रहरी",
        english: "Nepal police",
        value: "nepal_police",
    },
    {
        nepali: "सशस्त्र प्रहरी",
        english: "APF",
        value: "a_p_f",
    },
    {
        nepali: "नेपाली सेना",
        english: "Nepal army",
        value: "nepal_army",
    },
];

//if शिक्षक is clicked

export const teacherQuestion = {
    nepali: "कुन शिक्षक?",
    english: "Which teacher?",
};

export const teacher = [
    {
        nepali: "स्थानीय श्रोत",
        english: "Local source",
        value: "local",
    },
    {
        nepali: "सामुदायिक",
        english: "Government",
        value: "government",
    },
    {
        nepali: "राहत",
        english: "Rahat",
        value: "rahat",
    },
];

//if व्यवसायिक कार्य is clicked

export const industryQuestion = {
    nepali: "को व्यवसायिक कार्य",
    english: "Business work",
};

export const industry = [
    {
        nepali: "पत्रकार",
        english: "Journalist",
        value: "journalist",
    },
    {
        nepali: "वकिल",
        english: "Advocate",
        value: "lawer",
    },
    {
        nepali: "परामर्श",
        english: "Counsulting",
        value: "counselling",
    },
    {
        nepali: "ठेक्का  पट्टा",
        english: "Contract",
        value: "contract",
    },
    {
        nepali: "पुजारी",
        english: "Priest",
        value: "priest",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

//healthcondition

export const healthconditionQuesiton = {
    nepali: "को स्वास्थ्य स्थिति",
    english: "Health condition",
};

export const healthcondition = [
    {
        nepali: "स्वास्थ्य",
        english: "Health",
        value: "helathy",
    },
    {
        nepali: "सामान्य रोगी",
        english: "Normal diseases patient",
        value: "normal_patient",
    },
    {
        nepali: "दिर्घ रोग लागेको",
        english: "Unhealthy",
        value: "unhelathy",
    },
    // {
    // 	nepali: "मानसिक समस्या भएको",
    // 	english: "Mental",
    // 	value: "mental"
    // },
    // {
    // 	nepali: "क्यान्सर रोग",
    // 	english: "Cancer",
    // 	value: "cancer"
    // },
    // {
    // 	nepali: "कुष्ठ रोग",
    // 	english: "Tuberclosis",
    // 	value: "tuberclosis"
    // },
    // {
    // 	nepali: "HIV AIDS",
    // 	english: "HIV AIDS",
    // 	value: "hiv"
    // }
];

//if दिर्घ रोग लागेको clicked

export const unhealthyQuestion = {
    nepali: "दिर्घ रोग लागेको छ भने ? कुन किसिम को ?",
    english: "",
};

export const unhealthy = [
    {
        nepali: "उच्च रक्तचाप / ब्लड प्रेसर",
        english: "High blood pressure",
        value: "high_blood_pre",
    },
    {
        nepali: "मुटु  सम्बन्धी रोग",
        english: "Heart diseases",
        value: "heart",
    },
    {
        nepali: "मधुमेह",
        english: "Diabetes",
        value: "diabetes",
    },
    {
        nepali: "आस्थमा/दम",
        english: "Aasthama",
        value: "aasthama",
    },
    {
        nepali: "मृगौला सम्बन्धी  रोग",
        english: "Liver related",
        value: "liver",
    },
    {
        nepali: "HIV AIDS",
        english: "HIV AIDS",
        value: "hiv",
    },

    {
        nepali: "हाडजोर्नी तथा नसा सम्बन्धि रोग (बाथ रोग )",
        english: "Bornjoint",
        value: "bornjoint",
    },
    {
        nepali: "क्षारे रोग",
        english: "Ipilepsi",
        value: "ipilepsi",
    },
    {
        nepali: "कडा खालको मानसिक रोग (मस्तिस्क)",
        english: "Manasik",
        value: "manasik",
    },

    {
        nepali: "क्यान्सर",
        english: "Cancer",
        value: "cancer",
    },
    {
        nepali: "थाइराईड रोग",
        english: "Thaairoid",
        value: "thaairoid",
    },
    {
        nepali: "पाठेघर झर्ने खस्ने रोग",
        english: "patheghar khasne",
        value: "patheghar_khasne",
    },
    {
        nepali: "अल्सर /आन्द्राको रोग",
        english: "alsar",
        value: "alsar",
    },
    {
        nepali: "अल्जाईमर्स",
        english: "aljaaimars",
        value: "aljaaimars",
    },
    {
        nepali: "हात्तीपाइले",
        english: "hattipaile",
        value: "hattipaile",
    },
    {
        nepali: "निम्न रक्तचाप",
        english: "low pressure",
        value: "low_pressure",
    },
    {
        nepali: "कुष्ठरोग",
        english: "leprosy",
        value: "leprosy",
    },
    {
        nepali: "पार्किन्सस",
        english: "paarkinsas",
        value: "paarkinsas",
    },
    {
        nepali: "अन्य",
        english: "Other_disease",
        value: "other_disease",
    },
];

export const ifSchoolGoing = [
    {
        nepali: "सुन्दरहरैचा नगरपालिका भित्र",
        english: "",
        value: "inside_palika",
    },
    {
        nepali: "सुन्दरहरैचा नगरपालिका बाहिर",
        english: "",
        value: "inside_ditrict",
    },
    {
        nepali: "जिल्ला बाहिर",
        english: "",
        value: "outside_district",
    },
];

export const outsideDistrictSchooling = [
    {
        nepali: "सामुदायिक",
        english: "",
        value: "government",
    },
    {
        nepali: "सँस्थागत",
        english: "",
        value: "private",
    },
    {
        nepali: "गुम्बा /लामा घरमा पढने",
        english: "",
        value: "religious_education",
    },
    {
        nepali: "मदरसा",
        english: "",
        value: "madarasa",
    },
];

export const mentalYesNoQuestion = {
    nepali: "को अपांगता",
    english: "Disabled",
};

export const mentalyesno = [
    {
        nepali: "छ",
        english: "yes",
        value: "disability",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no_disability",
    },
];

export const mentaltypeQuestion = {
    nepali: "अपांगताको किसिम?",
    english: "Types of disablility",
};

//if mental छ is clicked
export const mentaltype = [
    {
        nepali: "जन्मजात",
        english: "By birth",
        value: "by_birth",
    },
    {
        nepali: "रोग लागेर",
        english: "Diseases",
        value: "disease",
    },
    {
        nepali: "दुर्घटना",
        english: "Accident",
        value: "accident",
    },
    {
        nepali: "अन्य",
        english: "Other Reason",
        value: "other_disable",
    },
];

export const disabilitytypeQuestion = {
    nepali: "अपांगता हुनुको कारण?",
    english: "Reason of disablility",
};

export const firstMarriageAgeQn = {
    nepali: "प्रथम पटक विवहा गरेको उमेर",
    english: "",
};
export const ifOtherValueQuestion = {
    nepali: "अन्य भए उल्लेख गर्नुहोस |",
    english: "",
};
//if mental छ is clicked
export const disabilitytype = [
    { value: "disability", nepali: "शारिरिक अपाङ्गता", english: "" },
    { value: "aged_mental", nepali: "बौद्धिक अपाङ्गता", english: "" },
    {
        value: "vision",
        nepali: "पूर्ण ष्टिविहीन  (आखाँ नदेख्ने)",
        english: "",
    },
    { value: "less_power", nepali: "न्यून दृष्टिबिहिन", english: "" },
    { value: "wahira", nepali: "बहिरापन", english: "" },
    {
        value: "bhakbhake",
        nepali: "स्वर वोलाई सम्बन्धी  अपाङ्गता (भकभके)",
        english: "",
    },
    { value: "hear_vision", nepali: "श्रवण दृष्टिविहिन अपाङ्गता", english: "" },
    { value: "mental", nepali: "मानसिक वा मनोसामाजिक अपाङ्गता", english: "" },
    { value: "autism", nepali: "अटिज्म सम्बन्धी", english: "" },
    {
        value: "hereditary_hemorrhagic",
        nepali: "अनुवंशीय रक्तश्राव सम्बन्धी अपाङ्गता",
        english: "",
    },
    { value: "many_disable", nepali: "बहु अपांगता", english: "" },
    { value: "others", nepali: "अन्य", english: "" },
];

export const birthCertificateQn = {
    nepali: "पाइले जन्म दर्ता गर्नु भएको छ कि छैन ?",
    english: "",
};

export const lessThanSixBirthCertificate = [
    {
        nepali: "छ",
        english: "",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "",
        value: "no",
    },
];

//if mental छ is clicked
export const mentalconditionQuestion = {
    nepali: "को अपांगताको स्थिति",
    english: "Condition of disability",
};

export const mentalcondition = [
    {
        nepali: "शारिरिक अपाङ्गता",
        english: "Physical Disability",
        value: "disability",
    },
    {
        nepali: "बौद्धिक अपाङ्गता",
        english: "Intellectual disability",
        value: "aged_mental",
    },
    {
        nepali: "पूर्ण  ष्टिविहीन  (आखाँ नदेख्ने)",
        english: "Blind Disability",
        value: "vision",
    },
    {
        nepali: "न्यून दृष्टिबिहिन",
        english: "less power",
        value: "less_power",
    },
    {
        nepali: "बहिरापन",
        english: "wahira",
        value: "wahira",
    },
    {
        nepali: "स्वर र  वोलाई (भकभके)",
        english: "Vocal Disability",
        value: "bhakbhake",
    },
    {
        nepali: "श्रवण दृष्टिविहिन अपाङ्गता",
        english: "Hearing impaired blindness",
        value: "hear_vision",
    },
    {
        nepali: "मानसिक वा मनोसामाजिक अपाङ्गता",
        english: "Mental or psychosocial disability",
        value: "mental",
    },
    {
        nepali: "अटिज्म सम्बन्धी",
        english: "Autism",
        value: "autism",
    },
    {
        nepali: "अनुवंशीय रक्तश्राव सम्बन्धी अपाङ्गता",
        english: "Genetic bleeding related disability",
        value: "hereditary_hemorrhagic",
    },
    {
        nepali: "बहु अपांगता",
        english: "Multiple disabilities",
        value: "many_disable",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

//को अपांगताको कार्ड दिएको छ कि छैन?
export const mentalCardQuestion = {
    nepali: "को अपांगता परिचय पत्र  छ कि छैन?",
    english: "Do you have disability card?",
};

export const mentalCardYesNo = [
    {
        nepali: "छ",
        english: "yes",
        value: "card_taken",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "card_not_taken",
    },
];
//if xa vaney KUN CARD XA ?
export const mentalCardTypeQuestion = {
    english: "If yes",
    nepali: "अपांगता परिचय पत्र छ भने कस्तो प्रकारको छ",
};
export const mentalCardtype = [
    {
        nepali: "रातो  रंगको परिचय पत्र (क वर्ग)",
        english: "Red card",
        value: "red_card",
    },
    {
        nepali: "निलो रंगको परिचय पत्र (ख वर्ग)",
        english: "Blue card",
        value: "blue_card",
    },
    {
        nepali: "पहेलो  रंगको परिचय पत्र (ग वर्ग)",
        english: "Yellow card",
        value: "yellow_card",
    },
    {
        nepali: "सेतो रंगको परिचय पत्र (घ वर्ग)",
        english: "White card",
        value: "white_card",
    },
];
//mother tongue

export const mothertongueQuestion = {
    nepali: "को मातृभाषा / बोलिने भाषा ?",
    english: "Mother tongue",
};

export const mothertongue = [
    {
        nepali: "नेपाली",
        english: "Nepali",
        value: "nepali",
    },
    {
        nepali: "तामाङ",
        english: "tamang",
        value: "tamang",
    },
    {
        nepali: "राई",
        english: "lhowa",
        value: "lhowa",
    },
    {
        nepali: "मगर",
        english: "magar",
        value: "magar",
    },
    {
        nepali: "मैथिली",
        english: "maithili",
        value: "maithili",
    },
    {
        nepali: "लिम्बु",
        english: "limbu",
        value: "limbu",
    },
    {
        nepali: "शेर्पा",
        english: "sherpa",
        value: "sherpa",
    },
    {
        nepali: "नेवारी",
        english: "newari",
        value: "newari",
    },
    {
        nepali: "गुरुङ",
        english: "gurung",
        value: "gurung",
    },
    {
        nepali: "पहरी",
        english: "pahari",
        value: "pahari",
    },
    {
        nepali: "सांकेतिक भाषा",
        english: "sanketik",
        value: "sanketik",
    },
    {
        nepali: "चौधरी",
        english: "chaudhari",
        value: "chaudhari",
    },
    {
        nepali: "थारु",
        english: "tharu",
        value: "tharu",
    },
    {
        nepali: "राजवंशी",
        english: "rajbanshi",
        value: "rajbanshi",
    },
    {
        nepali: "उर्दु",
        english: "urdu",
        value: "urdu",
    },
    {
        nepali: "भोजपुरी",
        english: "bhojpuri",
        value: "bhojpuri",
    },
    {
        nepali: "हिन्दी",
        english: "hindi",
        value: "hindi",
    },
    {
        nepali: "सन्थली",
        english: "santhali",
        value: "santhali",
    },
    {
        nepali: "चेपाङ",
        english: "chepaang",
        value: "chepaang",
    },
    {
        nepali: "दनुवार",
        english: "danuwaar",
        value: "danuwaar",
    },
    {
        nepali: "सुनुवार",
        english: "sunuwaar",
        value: "sunuwaar",
    },
    {
        nepali: "मुसलमान",
        english: "musalmaan",
        value: "musalmaan",
    },
    {
        nepali: "कोइ",
        english: "koi",
        value: "koi",
    },
    {
        nepali: "किसान",
        english: "kisan",
        value: "kisan",
    },
    {
        nepali: "बनकरिया",
        english: "bankariya",
        value: "bankariya",
    },
    {
        nepali: "गनगाइ",
        english: "gangaai",
        value: "gangaai",
    },
    {
        nepali: "ताजपुरिया",
        english: "taajpuriya",
        value: "taajpuriya",
    },
    {
        nepali: "अवरी",
        english: "awari",
        value: "awari",
    },
    {
        nepali: "वान्तवा",
        english: "waantwa",
        value: "waantwa",
    },
    {
        nepali: "उरन्वा",
        english: "uranwaa",
        value: "uranwaa",
    },
    {
        nepali: "भुजेल",
        english: "bhujel",
        value: "bhujel",
    },
    {
        nepali: "चाम्लीङ",
        english: "chamling",
        value: "chamling",
    },
    {
        nepali: "धिमाल",
        english: "dhimaal",
        value: "dhimaal",
    },
    {
        nepali: "बङग्ला",
        english: "baanglaa",
        value: "baanglaa",
    },
    {
        nepali: "अन्य",
        english: "Other language",
        value: "other_language",
    },
];

export const reasonForNoAllowance = [
    {
        nepali: "परिचयपत्र वडामा पेस नगरेर",
        english: "",
        value: "parichaypatra_wadaa",
    },
    {
        nepali: "भत्ता बारे जानकारी नभएर",
        english: "",
        value: "unknown_about_bhatta",
    },
    {
        nepali: "प्रक्रिया थाह नभएर",
        english: "",
        value: "prakriya_no_knowing",
    },
    {
        nepali: "अन्य",
        english: "",
        value: "others",
    },
];

export const raiQuestion = { nepali: "राई भाषा", english: "Rai language" };

export const raiType = [
    {
        nepali: "बान्तवा",
        english: "Bantawa",
        value: "bantawa",
    },
    {
        nepali: "खालिंङ",
        english: "Khaling",
        value: "khaling",
    },
    {
        nepali: "थुलुंग",
        english: "Thulung",
        value: "thulung",
    },
    {
        nepali: "कुलुंग",
        english: "Kulung",
        value: "kulung",
    },
    {
        nepali: "याक्खा",
        english: "Yakha",
        value: "yakha",
    },
    {
        nepali: "चामलिंङ",
        english: "Chamling",
        value: "chamling",
    },
    {
        nepali: "वादीङ",
        english: "Wading",
        value: "wading",
    },
    {
        nepali: "तिलुंङ",
        english: "Tilung",
        value: "tilung",
    },
    {
        nepali: "वाम्वुले",
        english: "Bambule",
        value: "bambule",
    },
];

//bloodgroup

export const bloodgroupQuestion = {
    nepali: "को रगत समुह",
    english: "Blood group",
};

export const bloodgroup = [
    {
        nepali: "O+",
        english: "O+",
        value: "o_positive",
    },
    {
        nepali: "O-",
        english: "O-",
        value: "o_negative",
    },
    {
        nepali: "A+",
        english: "A+",
        value: "a_positive",
    },
    {
        nepali: "A-",
        english: "A-",
        value: "a_neagtive",
    },
    {
        nepali: "B+",
        english: "B+",
        value: "b_positive",
    },
    {
        nepali: "B-",
        english: "B-",
        value: "b_negative",
    },
    {
        nepali: "AB+",
        english: "AB+",
        value: "ab_positive",
    },
    {
        nepali: "AB-",
        english: "AB-",
        value: "ab_neagtive",
    },
    {
        nepali: "थाहा छैन",
        english: "Donot know",
        value: "no_blood_group",
    },
];

// voter card

export const votercardQuestion = {
    nepali: "को मतदाता परिचय पत्र छ /छैन ?",
    english: "Voter card yes / no",
};

export const votercard = [
    {
        nepali: "छ",
        english: "yes",
        value: "voter_id_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "voter_id_no",
    },
];

export const insuranceQuestion = {
    english: "Do you have insurance?",
    nepali: "तपाइको विमा गरेको छ ?",
};
export const insuranceYesNo = [
    {
        nepali: "छ",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];
export const insuranceListQuestion = {
    english: "Detail about insurance",
    nepali: "छ भने कुन -कुन विमा गर्नु भाको छ ?",
};
export const insuranceLists = [
    {
        nepali: "जीवन विमा",
        english: "Life Insurance",
        value: "life_insurance",
    },
    {
        nepali: "स्वास्थ्य विमा",
        english: "Health Insurance",
        value: "health_insurance",
    },
];
export const ifNoNationalCard = [
    {
        nepali: "आवश्यकता नभएर",
        english: "",
        value: "aawaskta"
    },
    {
        nepali: "जानकारी नभएर",
        english: "",
        value: "jaankari"
    },
    {
        nepali: "प्रक्रिया झन्झटिलो लागेर",
        english: "",
        value: "prakriya_jhanjatilo"
    },
    {
        nepali: "कार्यालय टाढाँ भएर",
        english: "",
        value: "kaaryalaya_thandha_bahera"
    },
    {
        nepali: "अन्य",
        english: "",
        value: "others"
    },
]
export const lifeInsurance = {
    nepali: "जीवन विमा (संख्यामा)",
    english: "Number of Life Insurance",
    value: "",
};

export const healthInsurance = {
    nepali: "स्वास्थ विमा (संख्यामा)",
    english: "Number of Health Insurance",
    value: "",
};

export const nonlifeInsurance = {
    nepali: "नीर जीवन विमा (संख्यामा)",
    english: "Number of Non Life Insurance",
    value: "",
};
export const animalInsurance = {
    nepali: "पशु पन्छी विमा (संख्यामा)",
    english: "Number of Animal Insurance",
    value: "",
};

export const insuranceOther = {
    nepali: "अन्य विमा (संख्यामा)",
    english: "Number of Other Insurance",
    value: "",
};

export const other = {
    nepali: "अन्य भए उल्लेख गर्नु हाेस ।",
    english: "IF any then mention",
    value: "",
};
export const otherCount = {
    nepali: "अन्य (संख्या)",
    english: "Other(no)",
    value: "",
};

export const totalMember = {
    nepali: "जम्मा सदस्य संख्या",
    english: "Total no of family member",
};

export const doseName = [
    {
        nepali: "पहिलो",
        english: "",
        value: "first",
    },
    {
        nepali: "दोस्रो",
        english: "",
        value: "second",
    },
    {
        nepali: "बुस्टर डोज पनि लगाएको",
        english: "",
        value: "buster",
    },
];

export const noBirthCertificate = [
    {
        nepali: "थाहा नभएर",
        english: "",
        value: "being_unknown",
    },
    {
        nepali: "बुवा आमाको नागरिता नभएर",
        english: "",
        value: "no_parents_citizenship",
    },
    {
        nepali: "जन्म दर्तालाई महत्व नदिएर",
        english: "",
        value: "not_importance",
    },
    {
        nepali: "अन्य",
        english: "",
        value: "others",
    },
];

export const covidVaccineName = [
    {
        nepali: "Pfizer",
        english: "Pfizer",
        value: "Pfizer",
    },
    {
        nepali: "Moderna",
        english: "Moderna",
        value: "Moderna",
    },
    {
        nepali: "Covishied",
        english: "Covishied",
        value: "Covishied",
    },
    {
        nepali: "Jonssen",
        english: "Jonssen",
        value: "Jonssen",
    },
    {
        nepali: "Verocell",
        english: "Verocell",
        value: "Verocell",
    },
    {
        nepali: "Astrazeneca",
        english: "Astrazeneca",
        value: "Astrazeneca",
    },
    {
        nepali: "थाहा नभएको",
        english: "",
        value: "not_known_injection",
    },
];

//skillls

export const skillTrainingQuestion = {
    nepali: "तपाईले शिपमुलक तालिम वा कुनै शिप जान्नु हुन्छ ?",
    english: "Do you have any skill?",
};

export const skillTraining = [
    {
        nepali: "छ",
        english: "Yes",
        value: "skill_yes",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "skill_no",
    },
];

//if skilltraining छ is clicked

export const skillDetailQuestion = {
    nepali: "शिपमुलक तालिम छ भने",
    english: "If yes",
};

export const skillDetail = [
    {
        nepali: "कृषि प्राविधिक तालिम तथा अध्ययन  (जेटिए , खाध्य प्रसोधन आदि )",
        english: "Agriculture",
        value: "agri",
    },
    {
        nepali: "निर्माण सम्बन्धी  सिकर्मी / डकर्मी",
        english: "",
        value: "construction",
    },
    {
        nepali: "प्लम्बर",
        english: "",
        value: "plumber",
    },
    {
        nepali: "सिलाई,बुनाई,बुटिक,सृंगर,पार्लर आदि",
        english: "knitting",
        value: "sewing",
    },
    {
        nepali:
            "सूचना तथा प्रविधि (इलेक्ट्रोनिक कम्प्युटर/मोबाइल/रेडियो घडी आदि मर्मत)",
        english: "IT",
        value: "it",
    },
    {
        nepali: "पर्यटन टुर गाइड , ट्राभल र सत्कार",
        english: "Tourism",
        value: "tourism_guide",
    },
    {
        nepali: "होटल सम्बन्धि",
        english: "",
        value: "hotel",
    },
    {
        nepali: "इलेक्ट्रिकसियन",
        english: "Electrician",
        value: "electrician",
    },
    {
        nepali: "पेंटिंङ",
        english: "",
        value: "painting",
    },
    {
        nepali: "इन्जीनियरिङ्,अटो मोबाइल  र मेकानिक्स",
        english: "",
        value: "engineering",
    },
    {
        nepali: "जनस्वास्थ्य सम्बन्धी",
        english: "",
        value: "public_health",
    },
    {
        nepali: "पशु स्वास्थ्य सम्बन्धी",
        english: "",
        value: "animal_health",
    },
    {
        nepali: "वन सम्बन्धी",
        english: "Forest",
        value: "forest",
    },
    {
        nepali: "कला सम्बन्धी",
        english: "Art",
        value: "art",
    },
    {
        nepali: "कानुनी साक्षरता",
        english: "law",
        value: "law",
    },
    {
        nepali: "विपदब्यबस्थापन सम्बन्धि",
        english: "disaster",
        value: "disaster",
    },
    {
        nepali: "अन्य",
        english: "other",
        value: "other",
    },
];

//if निर्माण सम्बन्धी(मिस्त्री/कार्पेन्टर ) is checked
export const constructionMale = {
    nepali: "निर्माण सम्बन्धी (मिस्त्री/कार्पेन्टर ) पुरुष संख्या",
    english: "No of male in construction",
    value: "",
};

export const constructionFemale = {
    nepali: "निर्माण सम्बन्धी(मिस्त्री/कार्पेन्टर ) महिला संख्या",
    english: "No of female in construction ",
    value: "",
};

// if कृषि सम्बन्धी(जे.टि, जे.टि.ए र खद प्रसोधन ) is checked
export const agricultureMale = {
    nepali: "कृषि सम्बन्धी(जे.टि, जे.टि.ए र खद प्रसोधन ) पुरुष संख्या",
    english: "No of male in agriculture",
    value: "",
};

export const agricultureFemale = {
    nepali: "कृषि सम्बन्धी(जे.टि, जे.टि.ए र खद प्रसोधन ) महिला संख्या",
    english: "No of female in Agriculture",
    value: "",
};

//if बास तथा छाला बाट निर्मित हस्त सामग्री is chekced
export const handmadeMale = {
    nepali: "बास तथा छाला बाट निर्मित हस्त सामग्री सम्बन्धीपुरुष संख्या",
    english: "No of male in handicraft work",
    value: "",
};

export const handmadeFemale = {
    nepali: "बास तथा छाला बाट निर्मित हस्त सामग्री सम्बन्धीमहिला संख्या",
    english: "No of female in handicraft work",
    value: "",
};

// if सूचना प्रविधि ,इलेक्ट्रोनिकस,इलेक्त्रोनिकल(कम्पुटर /मोबाइल/रेडियो/घडी मर्मत) is checked
export const electricMale = {
    nepali:
        "सूचना प्रविधि ,इलेक्ट्रोनिकस,इलेक्त्रोनिकल(कम्पुटर /मोबाइल/रेडियो/घडी मर्मत) पुरुष संख्या",
    english: "No of male in Electronic works",
    value: "",
};

export const electricFemale = {
    nepali:
        "सूचना प्रविधि ,इलेक्ट्रोनिकस,इलेक्त्रोनिकल(कम्पुटर /मोबाइल/रेडियो/घडी मर्मत) महिला संख्या",
    english: "No of female in Electronic works",
    value: "",
};

// if सिलाई ,बुनाइ ,बुटिक ,पर्लोर, सृंगार is checked
export const fashionMale = {
    nepali: "सिलाई ,बुनाइ ,बुटिक ,पर्लोर, सृंगार पुरुष संख्या",
    english: "No of male in sewing , beautiparlour and botique",
    value: "",
};

export const fashionFemale = {
    nepali: "सिलाई ,बुनाइ ,बुटिक ,पर्लोर, सृंगार महिला संख्या",
    english: "No of female in sewing , beautiparlour and botique",
    value: "",
};

//if जनस्वास्थ सम्बन्धीis checked
export const healthMale = {
    nepali: "जनस्वास्थ सम्बन्धीपुरुष संख्या",
    english: "",
    value: "",
};

export const healthFemale = {
    nepali: "जनस्वास्थ सम्बन्धी महिला संख्या",
    english: "No of female in public health ",
    value: "",
};

// if पशु स्वास्थ्य  सम्बन्धीis checked
export const animalhealthMale = {
    nepali: "पशु स्वास्थ्य  सम्बन्धीपुरुष संख्या",
    english: "No of male in animal health",
    value: "",
};

export const animalhealthFemale = {
    nepali: "पशु स्वास्थ्य  सम्बन्धीमहिला संख्या",
    english: "No of female in animal health",
    value: "",
};

//if पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धीis checked
export const tourismMale = {
    nepali: "पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धीपुरुष संख्या",
    english: "No of male in tourism, tour guide work",
    value: "",
};

export const tourismFemale = {
    nepali: "पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धीमहिला संख्या",
    english: "No of female in tourism, tour guide work",
    value: "",
};

// if कला सम्बन्धीis checked
export const skillMale = {
    nepali: "कला सम्बन्धीपुरुष संख्या",
    english: "No of male in art work",
    value: "",
};

export const skillFemale = {
    nepali: "कला सम्बन्धीमहिला संख्या",
    english: "No of female in art work",
    value: "",
};

export const skillTrainingDurationQn = {
    nepali: "छ भने कति अवधि को लागि ?",
    english: "",
};
export const skillJobQn = {
    nepali: "तपाइले तालिम लिए  पश्चात सोही क्षेत्रम  काम गर्दै हुनु हुन्छ ?",
    english: "",
};
export const skillJobYesNo = [
    { value: "yes_same_job", nepali: "छ", english: "" },
    { value: "not_same_job", nepali: "छैन", english: "" },
];
export const skillJobNoQn = {
    nepali: "काम गर्नुभएको छैन भने किन ?",
    english: "",
};
export const skillJobNoList = [
    { value: "no_job", nepali: "काम नपाएर", english: "" },
    { value: "job_other", nepali: "अर्कै काम गरिराखेको भएर", english: "" },
    { value: "no_money", nepali: "व्यावसाय गर्ने रकम नभएर", english: "" },
    {
        value: "lack_trained",
        nepali: "तालिमले पर्याप्त  ज्ञान नदिएर",
        english: "",
    },
    { value: "family_support_no", nepali: "परिवारले सहयोग नगरेर", english: "" },
    { value: "other_no_job_reason", nepali: "अन्य कारण", english: "" },
];

export const socialSecurityAllowanceQn = {
    nepali: "तपाईले कुनै सामाजिक सुरक्षा भत्ता लिनुभएको छ",
    english: "",
};
export const socialSecurityAllowanceDetail = {
    nepali: "सामाजिक सुरक्षा भत्ता ",
    english: "",
};
export const socialSecurityAllowanceList = [
    { value: "not_taken", nepali: "छैन", english: "" },
    { value: "pension", nepali: "पेन्सन नेपाल सरकार", english: "" },
    { value: "pension_other", nepali: "पेन्सन अन्य देश", english: "" },
    { value: "old", nepali: "बृद्ध भत्ता", english: "" },
    { value: "bidhuwa", nepali: "विधुवा भत्ता", english: "" },
    { value: "dalit", nepali: "दलित बृद्ध भत्ता", english: "" },
    { value: "handicap", nepali: "अपाङ्गता भत्ता", english: "" },
    { value: "scholarship", nepali: "सरकारी छात्रवृत्ति", english: "" },
];
