const aandolanHeader = { nepali: "द्वन्द वा जनआन्दोलन प्रवाभित", english: "" };
const aandolanYesNo = {
    nepali: "परिवारमा द्वन्द वा जनआन्दोलन प्रवाभित सदस्य हुनुहुन्छ ?",
    english: "",
};
const aandolanEffect = {
    nepali: "कुन किसिमको प्रभावित",
    english: "",
};
const combatSide = {
    nepali: "ससत्र जनयुद्द हो भने कुन पक्षबाट",
    english: "",
};
const painFromAandolan = {
    nepali: "कस्तो किसिमको पिडित",
    english: "",
};
const effectFromPandemic = {
    nepali: "कोरोना महामारीले तपाइको परिवारमा पारेको प्रमुख असर ?",
    english: "",
};
const illTreatment = {
    english: "",
    nepali: "बिरामी हुदा स्वस्थ उपचार सेवाको लागी प्रथम पटक कहाँ जानु हुन्छ?",
};
const agricultureTitle = {
    english: "Agricultural production",
    nepali: "कृषि उत्पादन",
};
const irrigation = { english: "Irrigation", nepali: "खेती सिचाइ" };
const mal = { english: "Use of fertilizer", nepali: "मलको प्रयोग" };
const malType = { english: "Types of fertilizer", nepali: "मलको किसिम" };
const waliType = { english: "Types of crops", nepali: "बाली प्रकार" };
const wali = { english: "Crops", nepali: "बाली" };
const area = { english: "Area", nepali: "क्षेत्रफल" };
const productionShow = {
    english: "Production quantity",
    nepali: "उत्पादन परिमाण(के.जि.मा)",
};
const sales = { english: "Sales quantity", nepali: "बिक्री परिमाण(के.जि.मा)" };
const production = { english: "Profit", nepali: "बार्षिक आम्दानी" };
const expense = { english: "Profit", nepali: "बार्षिक खर्च" };
const agriInfo = { english: "Agriculture information", nepali: "कृषि जानकारी" };
const yearlyInfo = { english: "Yearly income", nepali: "वार्षिक आम्दानी" };
const beefishTitle = {
    nepali: "माछा मौरी र रेशम पालन",
    english: "Fish,bee and resham rearing",
};
const beefishType = { english: "Types", nepali: "किसिम" };
const beefishTotal = { english: "Total", nepali: "कुल" };
const beefishProduction = { english: "Production", nepali: "उत्पादन" };
const fish = { nepali: "माछा", english: "Fish" };
const silk = { nepali: "रेशम", english: "Resham" };
const bee = { nepali: "मौरी", english: "Bee" };
const beefishYesNo = {
    nepali: "माछा मौरी र रेशम पालन गरिएको",
    english: "Rearing of fish , bee and resham",
};
const businessQ = { nepali: "व्यवसाय कार्य", english: "Business work" };
const businessType = { nepali: "किसिम", english: "Types" };
const businessTypeName = { nepali: "नाम", english: "Name" };
const businessProfit = { nepali: "आम्दानी", english: "Profit" };
const businessName = {
    nepali: "घरेलु व्यवसाय कार्य",
    english: "Domestic business work",
};
const herbName = { nepali: "जडिबुटी ", english: "Medical herbs" };
const gharTitle = { english: "Introduction of house", nepali: "घरको परिचय" };
const genderMale = { nepali: "महिला", english: "Female", value: "" };
const genderFemale = { nepali: "पुरुष", english: "Male", value: "" };
const genderThird = {
    nepali: "तेस्रो लिङ्गी",
    english: "Third gender",
    value: "",
};
const genderTotal = { nepali: "जम्मा", english: "total", value: "" };
const economic = {
    nepali: "आर्थिक क्षेत्र जानकारी",
    english: "Economic area information",
};
const mainincome = {
    nepali: "वार्षिक आम्दानीको मुख्य स्रोत",
    english: "Main source of yearly income",
};
const yearlyIncome = { nepali: "वार्षिक आम्दानी ", english: "Yearly income" };
const yearlyExpenditure = {
    nepali: "वार्षिक खर्च",
    english: "Yearly expenditure",
};
const loanGiven = {
    nepali: "नगद ऋण दिनु भएको छ",
    english: "Given loan in cash",
};
const loanGivenInterest = { nepali: "व्याज दर", english: "Interest rate" };
const loanTaken = { nepali: "ऋण लिनु भएको छ", english: "Do you take loan" };
const loanType = { nepali: "rऋणको प्रकर ", english: "Types of loan" };
const loanInterest = { nepali: "व्याज दर", english: "Interest rate" };
const houseFacilityTitle = {
    nepali: "घरको सुविधाहरु",
    english: "Facilities of house",
};
const facilityType = { nepali: "किसिम", english: "Types" };
const facilityCount = { nepali: "संख्या", english: "Number" };
const childMarriageHeader = {
    nepali: "३ बर्ष भित्र छोरीको विवाह",
    english: "",
};
const familyTitle = {
    english: "Use by family",
    nepali: "परिवारले प्रयोग गर्ने बारेमा",
};
const waterSource = {
    english: "Main sources of drinking water",
    nepali: "खानेपानीको मुख्य श्रोत",
};
const distanceToWaterSource = {
    english: "",
    nepali: "घरको खानेपानी लिन जान ,भर्न र आउन कति समय लाग्छ?",
};
const cookingSource = {
    english: "Main sources of cooking",
    nepali: "खाना पकाउने मुख्य श्रोत",
};
const electricSource = {
    nepali: "वत्तिको मुख्य श्रोत",
    english: "Main source of electricity",
    value: "",
};
const isElectricityMeterQn = {
    nepali: "घरमा बिधुत मिटर जडान गरिएको छ कि छैन ?",
    english: "Main source of electricity",
    value: "",
};
const toiletQuestion = {
    nepali: "घरमा शौचालय छ/छैन",
    english: "Is toilet available in house",
    value: "",
};
const toiletType = {
    nepali: "शौचालयको किसिम",
    english: "Types of toilet",
    value: "",
};
const toiletTypeNo = {
    english: "",
    nepali: "शौचालय नभएको कारण",
    value: "",
};
const toiletTypeNoUse = {
    english: "",
    nepali: "शौचालय किन संचालन नगरेको ?",
    value: "",
};
const anyBankAccount = {
    nepali: "बैंक तथा वित्तीय सस्थामा खाता",
    english: "Account in bank and finance",
    value: "",
};
const numberofBankAccount = { nepali: "खाता", english: "account", value: "" };
const homeAge = { nepali: "घरको उमेर", english: "House age", value: "" };
const saltUsed = { nepali: "नुनको किसिम", english: "Types of salt", value: "" };
const hasinsur = { nepali: "परिवारको विमा विवरण", english: "" };
const insuranceDetail = {
    nepali: "परिवारको विमा विवरण",
    english: "Family insurance detail",
    value: "",
};
const totalHouseCount = { english: "No of house", nepali: "घर संख्या" };
const totalMatanCount = { english: "No of matan", nepali: "मतान संख्या" };
const gharJaggaTitle = {
    english: "House land information",
    nepali: "घरजग्गा जानकारी",
};
const totalHouseRoom = { english: "Total room", nepali: "जम्मा कोठा" };
const totalHouseStorey = { english: "Total floor", nepali: "जम्मा तल्ला" };
const houseMapPass = { english: "Map pass", nepali: "नक्सा पास " };
const houseCriteriaFullfill = { english: "Criteria", nepali: "मापदण्ड " };
const houseLandUsedLiving = { english: "Ownership", nepali: "स्वामित्व" };
const houseroofType = { english: "Types of roof", nepali: "छानाको प्रकार" };
const hoousesType = { english: "Types of house", nepali: "घर को प्रकार" };
const wasteDisposal = {
    english: "Disposal of waste",
    nepali: "फोहोर मैलो  व्यवस्थापन ",
};
const road = { english: "Road facility", nepali: "सडकको सुबिधा " };
const houseRent = { english: "Room rent", nepali: "घर भाडामा" };
const houseConstructionCertificate = {
    english: "",
    nepali: "घर निर्माण प्रमाण पत्र लिनु भएको छ ?",
};
const totalArea = {
    english: "Total land area",
    nepali: "जम्मा जग्गा क्षेत्रफल",
};
const ropani = { english: "Ropani", nepali: "रोपनी" };
const aana = { english: "Aana", nepali: "आना" };
const paisa = { english: "Paisa", nepali: "पैसा" };
const bigha = { english: "", nepali: "बिघा" };
const kattha = { english: "", nepali: "कठा" };
const dhur = { english: "", nepali: "धुर" };
const liveStockInfo = {
    english: "Animal, birds information",
    nepali: "पशु पन्छी जानकारी",
};
const livestockName = { english: "Animal,birds", nepali: "पशु पन्छी" };
const livestockDetail = {
    nepali: "पशुपालन गरेको छ कि छैन",
    english: "Rear animal or not",
};
const animalCount = {
    english: "No of animal and birds",
    nepali: "पशु पन्छी संख्या",
};
const annualIncome = { english: "Total income", nepali: "जम्मा आम्दानी" };
const skills = { nepali: "व्यवसायिक सिप तालिम", english: "Skill and training" };
const skillType = {
    nepali: "सिप तालिम क्षेत्र",
    english: "Skill and training center",
};
const skillMaleNo = { nepali: "जम्मा पुरुष", english: "Total male" };
const skillFemaleNo = { nepali: "जम्मा महिला", english: "Total female" };
const skillYesNo = {
    nepali: "व्यवसायिक सिप तालिम प्राप्त गरेका",
    english: "Achive skill and training",
};
const welfare = {
    nepali: "सामाजिक सुरक्षा भत्ता",
    english: "Social security Allowance",
};
const welfareType = { nepali: "प्रकार", english: "Types" };
const welfareMale = { nepali: "पुरुष", english: "Male" };
const welfareFemale = { nepali: "महिला", english: "Female" };
const bhattaYesNo = {
    nepali: "सामाजिक सुरक्षा भत्ता प्राप्त गर्ने",
    english: "Getting social security Allowance",
};
const dirtyWaterQuestion = {
    nepali: "घरबाट निस्कने फोहरपानीको ब्यबस्थापन",
    english: "",
};

const dirtyWaterManagement = [
    {
        nepali: "करेसाबारीमा",
        english: "gardenfiel",
        value: "gardenfiel",
    },
    {
        nepali: "ढलमा मिसाएको",
        english: "drain",
        value: "drain",
    },
    {
        nepali: "खाडलमा जम्मा गर्ने",
        english: "ponding",
        value: "ponding",
    },
    {
        nepali: "अब्यबस्थित छाड्ने",
        english: "unmanaged",
        value: "unmanaged",
    },
];

const riskAreaAroundHouseQn = {
    nepali: "घर कस्तो प्रकारको जिखिममा छ?",
    english: "",
};

const riskAreaAroundHouse = [
    {
        nepali: "बाढी",
        english: "Flood",
        value: "flood",
    },
    {
        nepali: "पहिरो",
        english: "Landslide",
        value: "landslide",
    },
    {
        nepali: "हावाहुरी",
        english: "Hurricane",
        value: "hurricane",
    },
    {
        nepali: "ढुवान वा कटान",
        english: "Kataan",
        value: "kataan",
    },
    {
        nepali: "आगालागी",
        english: "Fire",
        value: "fire",
    },
    {
        nepali: "असिना",
        english: "Hailstone",
        value: "hailstone",
    },
    {
        nepali: "खडेरी",
        english: "Drouhgt",
        value: "drought",
    },
    {
        nepali: "जोखिम नभएको",
        english: "no risk",
        value: "no_risk",
    },
];

const meansOfMunicipalInformationQn = {
    nepali: "गाउँपालिकाको सूचना के माध्यम बाट प्राप्त गर्नुहुन्छ ?",
    english: "",
};

const meansOfMunicipalInformation = [
    {
        nepali: "मोबाइल एप",
        english: "Mobile App",
        value: "mobile_app",
    },
    {
        nepali: "रेडियो",
        english: "Radio",
        value: "radio",
    },
    {
        nepali: "स्थानीय जनप्रतिनिधि  बाट",
        english: "Local leader",
        value: "local_leader",
    },
    {
        nepali: "पत्रपत्रिका / समाचारपत्र",
        english: "newspaper",
        value: "newspaper_arti",
    },
    {
        nepali: "सरकारी कर्मचारी",
        english: "government officer",
        value: "government_off",
    },
    {
        nepali: "गैर सरकारी कर्मचारीबाट",
        english: "non-government officer",
        value: "nongov_officer",
    },
    {
        nepali:
            "लिखित निवेदन दिएर सूचना प्राप्त गर्ने   (सुचनाको हक प्रयोग गर्ने)",
        english: "Information",
        value: "information_ri",
    },
    {
        nepali: "एस.एम.एस",
        english: "Sms",
        value: "sms",
    },
    {
        nepali: "वेबसाइट",
        english: "Website",
        value: "website",
    },
    {
        nepali: "माईकिङ",
        english: "Maaiking",
        value: "maaiking",
    },
    {
        nepali: "सूचना पाटी",
        english: "suchana paati",
        value: "suchana_paati",
    },
    {
        nepali: "सामाजिक सन्जाल",
        english: "Social",
        value: "social",
    },
    {
        nepali: "थाह छैन",
        english: "Dont know",
        value: "dont_know",
    },
];
const waterPurificationQn = {
    nepali: "तपाईले पिउने पानीको शुद्धीकरण कसरी गर्नुहुन्छ ?",
    english: "",
};

const waterPurification = [
    {
        nepali: "उमालेर",
        english: "Boil",
        value: "boil",
    },
    {
        nepali: "फिल्टर गरेर",
        english: "Filter",
        value: "filter",
    },
    {
        nepali: "क्लोरिन झोल वा पियुस हालेर",
        english: "Chlorine",
        value: "chlorine",
    },
    {
        nepali: "सोडीस",
        english: "Sodis",
        value: "sodis",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

const womenchild = {
    nepali: "महिला तथा बालबालिका",
    english: "Female and chindren",
};
const childLabor = {
    nepali: "१६ वर्ष मुनिका कामदार",
    english: "Worker below age of 16",
};

//मकैखेति गरिएको विवरण
const agriMakaiInfo = {
    english: "Miaze farming detail",
    nepali: "मकैखेति गरिएको विवरण",
};
const agriMakaiAreaQuestion = {
    english: "Maize farming area",
    nepali: "मकैखेति गरिएको क्षेत्रफ़ल",
};
const agriMakaiArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//मकैखेति उत्पादन (मुरी पाथीमा)
const agriMakaiProduction = {
    english: "Production of maize ( in muri and pathi)",
    nepali: "मकैखेति उत्पादन (मुरी पाथीमा)",
};
const agriMakaiProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//मकैखेति विक्री परिमाण (मुरी पाथीमा)
const agriMakaiSale = {
    english: "Sale maize",
    nepali: "मकैखेति विक्री परिमाण (मुरी पाथीमा)",
};

//मकैखेति END

//कोदोखेति गरिएको विवरण
const agriKodoInfo = {
    english: "Kodo farming detail",
    nepali: "कोदोखेति गरिएको विवरण",
};
const agriKodoAreaQuestion = {
    english: "Kodo farming area",
    nepali: "कोदोखेति गरिएको क्षेत्रफ़ल",
};
const agriKodoArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//कोदोखेति उत्पादन (मुरी पाथीमा)
const agriKodoProduction = {
    english: "Production of kodo in pathi",
    nepali: "कोदोखेति उत्पादन (मुरी पाथीमा)",
};
const agriKodoProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//कोदोखेति  विक्री परिमाण (मुरी पाथीमा)
const agriKodoSale = {
    english: "Quantity of kodo sales",
    nepali: "कोदोखेति  विक्री परिमाण (मुरी पाथीमा)",
};

//कोदोखेति END

//फापरखेति गरिएको विवरण
const agriFaparInfo = {
    english: "Fapar farming detail",
    nepali: "फापरखेति गरिएको विवरण",
};
const agriFaparAreaQuestion = {
    english: "Area of faper farming",
    nepali: "फापरखेति गरिएको क्षेत्रफ़ल",
};
const agriFaparArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//फापरखेति उत्पादन (मुरी पाथीमा)
const agriFaparProduction = {
    english: "Production of faper",
    nepali: "फापरखेति उत्पादन (मुरी पाथीमा)",
};
const agriFaparProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//फापरखेति विक्री परिमाण (मुरी पाथीमा)
const agriFaparSale = {
    english: "Quantity of faper sales in muri and pathi",
    nepali: "फापरखेति विक्री परिमाण (मुरी पाथीमा)",
};

//फापरखेति END
//माथि भन्दा अन्यखेति कति प्रकार को अन्नवाली गरिएको छ ?
const agriAnyKheti = {
    english: "Other crops detail",
    nepali: "माथि भन्दा अन्य खेति कति प्रकारको अन्नवाली गरिएको छ ?",
    value: "",
};
const agriAnyInfo = {
    english: "Other crops detail",
    nepali: "अन्य अन्नखेति गरिएको विवरण",
};
const agriAnyInfoTitle = {
    engish: "Mention if any",
    nepali: "अन्य भए उल्लेख गर्नु हाेस।",
    value: "",
};
const agriAnyKhetiInfo = {
    english: "Farming detail",
    nepali: "खेति गरिएको विवरण",
    value: "",
};
const agriAnyKhetiAreaQuestion = {
    english: "Other crop farming detail",
    nepali: "अन्यखेति गरिएको क्षेत्रफ़ल",
};
const agriAnyKhetiArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//अन्यखेति उत्पादन (मुरी पाथीमा)
const agriAnyKhetiProduction = {
    english: "Other crops production in muri",
    nepali: "अन्यखेति उत्पादन (मुरी पाथीमा)",
};
const agriAnyKhetiProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//अन्यखेति विक्री परिमाण (मुरी पाथीमा)
const agriAnyKhetiSale = {
    english: "Other crops sales quantity in muri and pathi",
    nepali: "अन्यखेति विक्री परिमाण (मुरी पाथीमा)",
};
const agriAnyKhetiSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "अन्यखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of other crops?",
        value: "",
    },
];
//अन्यखेति END

//दालवालिको उत्पादन तथा बिक्रि विवरण
//If xa vaney   कुन कुन दालवालिको उत्पादन तथा बिक्रि विवरण छन् ?
const agriDaalTitle = {
    english: "Which daal has production and sales detail?",
    nepali: " कुन कुन दालवालीको उत्पादन तथा बिक्रि विवरण छन् ?",
};
const agriDaalType = [
    {
        nepali: "मासको दाल / कालो दाल",
        english: "kalo daal",
        value: "kalo_daal",
    },
    {
        nepali: "सिमि",
        english: "Simi",
        value: "simi",
    },
    {
        nepali: "मटर / केराउ",
        english: "Matar/Kerau",
        value: "black_daal",
    },
    {
        nepali: "भट्मास",
        english: "Bhatmas",
        value: "masuro",
    },
    {
        nepali: "रहर",
        english: "Rahar",
        value: "rahar",
    },
    {
        nepali: "गहत",
        english: "gahat",
        value: "gahat",
    },
    {
        nepali: "बोडी / मस्यांग",
        english: "khesari",
        value: "khesari",
    },
    {
        nepali: "चना /मुसुरो",
        english: "chana musuro",
        value: "chana_musuro",
    },
    {
        nepali: "खेसरी",
        english: "khesari 1",
        value: "khesari_1",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other_daal",
    },
];
// कालो दालखेति गरिएको विवरण
const kalodaalInfo = { english: "", nepali: " कालो दालखेति गरिएको विवरण" };
const kalodaalAreaQuestion = {
    english: "Area of kalo daal farming",
    nepali: " कालो दालखेति गरिएको क्षेत्रफ़ल्",
};
const kalodaalArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//कालो दालखेति उत्पादन (मुरी पाथीमा)
const kalodaalProduction = {
    english: "Production of kalo daal in muri and pathi",
    nepali: "कालो दालखेति उत्पादन (मुरी पाथीमा)",
};
const kalodaalProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// कालो दालखेति विक्री परिमाण (मुरी पाथीमा)
const agrikalodaalSale = {
    english: "kalo daal sales quantity (muri and pathi)",
    nepali: " कालो दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const kalodaalSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// कालो दालखेति END

// रहर दालखेति गरिएको विवरण
const raharInfo = {
    english: "Rahar daal farming detail",
    nepali: " रहर दालखेति गरिएको विवरण",
};
const raharAreaQuestion = {
    english: "Rahar daal farming area",
    nepali: " रहर दालखेति गरिएको क्षेत्रफ़ल",
};
const raharArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//रहर दालखेति उत्पादन (मुरी पाथीमा)
const raharProduction = {
    english: "Production of Rahar daal(muri and pathi)",
    nepali: "रहर दालखेति उत्पादन (मुरी पाथीमा)",
};
const raharProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// रहर दालखेति विक्री परिमाण (मुरी पाथीमा)
const raharSale = {
    english: "Rahar daal sales quantity(muri and pathi)",
    nepali: " रहर दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const raharSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// रहर दालखेति END

// चना दालखेति गरिएको विवरण
const chanaInfo = {
    english: "Chana daal farming detail",
    nepali: " चना दालखेति गरिएको विवरण",
};
const chanaAreaQuestion = {
    english: "Chana daal farming area",
    nepali: " चना दालखेति गरिएको क्षेत्रफ़ल",
};
const chanaArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//चना दालखेति उत्पादन (मुरी पाथीमा)
const chanaProduction = {
    english: "Production of chana daal ?",
    nepali: "चना दालखेति उत्पादन (मुरी पाथीमा)",
};
const chanaProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// चना दालखेति विक्री परिमाण (मुरी पाथीमा)
const chanaSale = {
    english: "Chana daal sales quantity(muri and pathi)",
    nepali: " चना दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const chanaSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// चना दालखेति END
// भट्मास दालखेति गरिएको विवरण
const bhatmasInfo = { english: "", nepali: " भट्मास दालखेति गरिएको विवरण" };
const bhatmasAreaQuestion = {
    english: "Bhatmas daal farming area",
    nepali: " भट्मास दालखेति गरिएको क्षेत्रफ़ल",
};
const bhatmasArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//भट्मास दालखेति उत्पादन (मुरी पाथीमा)
const bhatmasProduction = {
    english: "Bhatmas daal production(muri pathi)",
    nepali: "भट्मास दालखेति उत्पादन (मुरी पाथीमा)",
};
const bhatmasProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// भट्मास दालखेति विक्री परिमाण (मुरी पाथीमा)
const bhatmasSale = {
    english: "Bhatmas daal sale quantity(muri and pathi)",
    nepali: " भट्मास दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const bhatmasSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// भट्मास दालखेति END
// गहत दालखेति गरिएको विवरण
const gahatInfo = { english: "Gahat ", nepali: " गहत दालखेति गरिएको विवरण" };
const gahatAreaQuestion = {
    english: "Gahat daal farming area",
    nepali: " गहत दालखेति गरिएको क्षेत्रफ़ल",
};
const gahatArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//गहत दालखेति उत्पादन (मुरी पाथीमा)
const gahatProduction = {
    english: "Gahat daal production(muri and pathi)",
    nepali: "गहत दालखेति उत्पादन (मुरी पाथीमा)",
};
const gahatProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// गहत दालखेति विक्री परिमाण (मुरी पाथीमा)
const gahatSale = {
    english: "Gahat daal sales quantity(muri and pathi)",
    nepali: " गहत दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const gahatSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// गहत दालखेति END

// मस्याम दालखेति गरिएको विवरण
const mashyamInfo = {
    english: "Mashyang daal farming detail",
    nepali: " मस्याम दालखेति गरिएको विवरण",
};
const mashyamAreaQuestion = {
    english: "Mashyang daal farming area ",
    nepali: " मस्याम दालखेति गरिएको क्षेत्रफ़ल",
};
const mashyamArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//मस्याम दालखेति उत्पादन (मुरी पाथीमा)
const mashyamProduction = {
    english: "Mashyang daal production(muri and pathi)",
    nepali: "मस्याम दालखेति उत्पादन (मुरी पाथीमा)",
};
const mashyamProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// मस्याम दालखेति विक्री परिमाण (मुरी पाथीमा)
const mashyamSale = {
    english: "Mashyang daal sale quantity(muri and pathi)",
    nepali: " मस्याम दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const mashyamSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// मस्याम दालखेति END

// खेसरी दालखेति गरिएको विवरण
const khesariInfo = {
    english: "Mashyang daal farming detail",
    nepali: " खेसरी दालखेति गरिएको विवरण",
};
const khesariAreaQuestion = {
    english: "Mashyang daal farming area ",
    nepali: " खेसरी दालखेति गरिएको क्षेत्रफ़ल",
};
const khesariArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//खेसरी दालखेति उत्पादन (मुरी पाथीमा)
const khesariProduction = {
    english: "Mashyang daal production(muri and pathi)",
    nepali: "खेसरी दालखेति उत्पादन (मुरी पाथीमा)",
};
const khesariProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// खेसरी दालखेति विक्री परिमाण (मुरी पाथीमा)
const khesariSale = {
    english: "Mashyang daal sale quantity(muri and pathi)",
    nepali: " खेसरी दालखेति विक्री परिमाण (मुरी पाथीमा)",
};
const khesariSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// खेसरी दालखेति END

//अन्य दालखेति  START
//माथि भन्दा अन्य दालखेति  कति प्रकार को अन्नवाली गरिएको छ ?
const anyDaalKheti = {
    english: "",
    nepali: "माथि भन्दा अन्य दालखेति  कति प्रकार को अन्नवाली गरिएको छ ?",
    value: "",
};
const anyDaalInfo = { english: "", nepali: "अन्य दालखेति गरिएको विवरण" };
const anyDaalInfoTitle = {
    engish: "Mention if any",
    nepali: "अन्य भए उल्लेख गर्नु होस्।",
    value: "",
};
const anyDaalKhetiInfo = {
    english: "Farming detail",
    nepali: "खेति गरिएको विवरण",
    value: "",
};
const anyDaalKhetiAreaQuestion = {
    english: "Other daal farming area ",
    nepali: "अन्य दालखेति  गरिएको क्षेत्रफ़ल्",
};
const anyDaalKhetiArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//अन्य दालखेति  उत्पादन (मुरी पाथीमा)
const anyDaalKhetiProduction = {
    english: "Other daal production(muri and pathi)",
    nepali: "अन्य दालखेति  उत्पादन (मुरी पाथीमा)",
};
const anyDaalKhetiProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//अन्य दालखेति  विक्री परिमाण (मुरी पाथीमा)
const anyDaalKhetiSale = {
    english: "Other daal sale quantity(muri and pathi)",
    nepali: "अन्य दालखेति  विक्री परिमाण (मुरी पाथीमा)",
};
const anyDaalKhetiSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//अन्य दालखेति END
//दाल वालि END

//तेलहन उत्पादन START
//तेलहन उत्पादन तथा बिक्रि विवरण
//If xa vaney   कुन कुन तेलहन उत्पादन तथा बिक्रि विवरण छन् ?
const agriOilTitle = {
    english: "Which oil crop are produced and sales? ",
    nepali: "कुन कुन तेलहन उत्पादन तथा बिक्रि विवरण छन् ?",
};
const agriOilType = [
    {
        nepali: "तोरी / सर्स्यु",
        english: "Tori",
        value: "tori",
    },
    {
        nepali: "आलस/तिल",
        english: "Aalash",
        value: "aalash",
    },
    {
        nepali: "फिलुंगे",
        english: "Filunge",
        value: "philunge",
    },
    {
        nepali: "सूर्यमुखी",
        english: "Sunflower",
        value: "sunflower",
    },
    {
        nepali: "चिउरी",
        english: "Chiuri",
        value: "chiuri",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other_oilseed",
    },
];
// तोरीखेति गरिएको विवरण
const tooriInfo = {
    english: "Detail of tori farming",
    nepali: " तोरीखेति गरिएको विवरण",
};
const tooriAreaQuestion = {
    english: "Area of tori farming",
    nepali: " तोरीखेति गरिएको क्षेत्रफ़ल्",
};
const tooriArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//तोरी उत्पादन (मुरी पाथीमा)
const tooriProduction = {
    english: "Tori production (muri and pathi)",
    nepali: "तोरीखेति उत्पादन (मुरी पाथीमा)",
};
const tooriProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// तोरीखेति विक्री परिमाण (मुरी पाथीमा)
const tooriSale = {
    english: "Tori sales production",
    nepali: " तोरीखेति विक्री परिमाण (मुरी पाथीमा)",
};
const tooriSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "तोरीखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income from tori?",
        value: "",
    },
];
//तोरीखेति END

//सर्स्युखेति START
// सर्स्युखेति गरिएको विवरण
const suryaInfo = {
    english: "Detail of sarsu farming",
    nepali: " सर्स्युखेति गरिएको विवरण",
};
const suryaAreaQuestion = {
    english: "Area of sarsu farming",
    nepali: " सर्स्युखेति गरिएको क्षेत्रफ़ल्",
};
const suryaArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//सर्स्युखेति उत्पादन (मुरी पाथीमा)
const suryaProduction = {
    english: "Production of sarsu (muri and pathi)",
    nepali: "सर्स्युखेति उत्पादन (मुरी पाथीमा)",
};
const suryaProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// सर्स्युखेति विक्री परिमाण (मुरी पाथीमा)
const suryaSale = {
    english: "Sales quantity of sarsu",
    nepali: " सर्स्युखेति विक्री परिमाण (मुरी पाथीमा)",
};
const suryaSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "सर्स्युखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income from sarsu production?",
        value: "",
    },
];
//सर्स्युखेति END

//आलसखेति START
// आलसखेति गरिएको विवरण
const aalashInfo = {
    english: "Detail of aalash farming",
    nepali: " आलसखेति गरिएको विवरण",
};
const aalashAreaQuestion = {
    english: "Area of aalash farming",
    nepali: " आलसखेति गरिएको क्षेत्रफ़ल्",
};
const aalashArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//आलसखेति उत्पादन (मुरी पाथीमा)
const aalashProduction = {
    english: "Aalash production (muri and pathi)",
    nepali: "आलसखेति उत्पादन (मुरी पाथीमा)",
};
const aalashProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// आलसखेति विक्री परिमाण (मुरी पाथीमा)
const aalashSale = {
    english: "Aalash sales quantity(muri and pathi)",
    nepali: " आलसखेति विक्री परिमाण (मुरी पाथीमा)",
};
const aalashSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "आलसखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income from aalash production?",
        value: "",
    },
];
//आलसखेति END

//फिलुंगेखेति START
// फिलुंगेखेति गरिएको विवरण
const filungeInfo = { english: "", nepali: " फिलुंगेखेति गरिएको विवरण" };
const filungeAreaQuestion = {
    english: "Area of Filunge farming",
    nepali: " फिलुंगेखेति गरिएको क्षेत्रफ़ल्",
};
const filungeArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//फिलुंगेखेति उत्पादन (मुरी पाथीमा)
const filungeProduction = {
    english: "Production of filunge (muri pathi)",
    nepali: "फिलुंगेखेति उत्पादन (मुरी पाथीमा)",
};
const filungeProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// फिलुंगेखेति विक्री परिमाण (मुरी पाथीमा)
const filungeSale = {
    english: "Sales quantity of filunge(muri and pathi)",
    nepali: " फिलुंगेखेति विक्री परिमाण (मुरी पाथीमा)",
};
const filungeSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "फिलुंगेखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of filunge production?",
        value: "",
    },
];
//फिलुंगेखेति END

//तिल खेति START
// तिल खेति गरिएको विवरण
const tilInfo = {
    english: "Detail of teel farming?",
    nepali: " तिल खेति गरिएको विवरण",
};
const tilAreaQuestion = { english: "", nepali: " तिल खेति गरिएको क्षेत्रफ़ल्" };
const tilArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//तिल खेति उत्पादन (मुरी पाथीमा)
const tilProduction = {
    english: "Teel production(muri pathi)",
    nepali: "तिल खेति उत्पादन (मुरी पाथीमा)",
};
const tilProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// तिल खेति विक्री परिमाण (मुरी पाथीमा)
const tilSale = {
    english: "Sales quantity of teel(muri pathi)",
    nepali: " तिल खेति विक्री परिमाण (मुरी पाथीमा)",
};
const tilSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "तिल खेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of teel production?",
        value: "",
    },
];
//तिल खेति END

//सूर्यमुखी खेति START
// सूर्यमुखी खेति गरिएको विवरण
const sunflowerInfo = {
    english: "sunflower farming detail",
    nepali: " सूर्यमुखी खेति गरिएको विवरण",
};
const sunflowerAreaQuestion = {
    english: "Area of sunflower farming",
    nepali: " सूर्यमुखी खेति गरिएको क्षेत्रफ़ल",
};
const sunflowerArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//सूर्यमुखी खेति उत्पादन (मुरी पाथीमा)
const sunflowerProduction = {
    english: "sunflower production(muri pathi)",
    nepali: "सूर्यमुखी खेति उत्पादन (मुरी पाथीमा)",
};
const sunflowerProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// सूर्यमुखी खेति विक्री परिमाण (मुरी पाथीमा)
const sunflowerSale = {
    english: "Sales quantity of sunflower(muri pathi)",
    nepali: " सूर्यमुखी खेति विक्री परिमाण (मुरी पाथीमा)",
};
const sunflowerSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "सूर्यमुखी खेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income from sunflower?",
        value: "",
    },
];
//सूर्यमुखीखेति END

//चिउरी खेति START
// चिउरी खेति गरिएको विवरण
const chiuriInfo = {
    english: "chiuri farming detail",
    nepali: " चिउरी खेति गरिएको विवरण",
};
const chiuriAreaQuestion = {
    english: "Area of chiuri farming",
    nepali: " चिउरी खेति गरिएको क्षेत्रफ़ल",
};
const chiuriArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//चिउरी खेति उत्पादन (मुरी पाथीमा)
const chiuriProduction = {
    english: "chiuri production(muri pathi)",
    nepali: "चिउरी खेति उत्पादन (मुरी पाथीमा)",
};
const chiuriProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// चिउरी खेति विक्री परिमाण (मुरी पाथीमा)
const chiuriSale = {
    english: "Sales quantity of chiuri(muri pathi)",
    nepali: " चिउरी खेति विक्री परिमाण (मुरी पाथीमा)",
};
const chiuriSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "चिउरी खेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income from chiuri?",
        value: "",
    },
];
//चिउरीखेति END

//अन्य तेलहनखेति  START
//माथि भन्दा अन्य तेलहनखेति  कति प्रकार को अन्नवाली गरिएको छ ?
const anyOilKheti = {
    english: "Types of oilseed",
    nepali: "माथि भन्दा अन्य तेलहनखेति  कति प्रकार को अन्नवाली गरिएको छ ?",
    value: "",
};
const anyOilInfo = {
    english: "Detail of other oilseed",
    nepali: "अन्य तेलहनखेति गरिएको विवरण",
};
const anyOilInfoTitle = {
    engish: "Mention if any",
    nepali: "अन्य भए उल्लेख गर्नु हाेस।",
    value: "",
};
const anyOilKhetiInfo = {
    english: "Detail of farming",
    nepali: "खेति गरिएको विवरण",
    value: "",
};
const anyOilKhetiAreaQuestion = {
    english: "Area of other oilseed farming",
    nepali: "अन्य तेलहनखेति  गरिएको क्षेत्रफ़ल्",
};
const anyOilKhetiArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//अन्य तेलहनखेति  उत्पादन (मुरी पाथीमा)
const anyOilKhetiProduction = {
    english: "Production of other oilseed (muri pathi)",
    nepali: "अन्य तेलहनखेति  उत्पादन (मुरी पाथीमा)",
};
const anyOilKhetiProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//अन्य तेलहनखेति  विक्री परिमाण (मुरी पाथीमा)
const anyOilKhetiSale = {
    english: "Sales quantity of other oilseed",
    nepali: "अन्य तेलहनखेति  विक्री परिमाण (मुरी पाथीमा)",
};
const anyOilKhetiSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//अन्य तेलहनखेति END
//तेलहन END

//तरकारी उत्पादन START
//तरकारी उत्पादन तथा बिक्रि विवरण
//If xa vaney कुन कुन तरकारी उत्पादन तथा बिक्रि विवरण छन् ?
const agriVegetableTitle = {
    english: "Detail of vegetables production and sales",
    nepali: "कुन कुन तरकारी उत्पादन तथा बिक्रि विवरण छन् ?",
};
const agriVegetableType = [
    {
        nepali: "आलु",
        english: "Potato",
        value: "potato",
    },
    {
        nepali: "बन्दा/काउली /बोडी",
        english: "Cabbage",
        value: "cabbage",
    },
    {
        nepali: "गोलभेडा (टमाटर )",
        english: "Tomato",
        value: "tomato",
    },
    {
        nepali: "फर्सी /लौका",
        english: "Cucumber",
        value: "cucumber",
    },
    {
        nepali: "रायो (साग सब्जी )",
        english: "Rayo Saag",
        value: "vejetable_raay",
    },
    {
        nepali: "मुला/ काक्रो /गाजर ",
        english: "bittergroud",
        value: "bittergroud",
    },
    {
        nepali: "पर्वल",
        english: "parwal",
        value: "parwal",
    },
    {
        nepali: "करेला /भेन्टा /भिंडी",
        english: "karela venta vindi",
        value: "karelaa_bhenta_bhindi",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other_vegetabl",
    },
];

//आलु खेति START
// आलु खेति गरिएको विवरण
const potatoInfo = {
    english: "Detail of potato farming",
    nepali: " आलु खेति गरिएको विवरण",
};
const potatoAreaQuestion = {
    english: "Area of potato farming",
    nepali: " आलु खेति गरिएको क्षेत्रफ़ल्",
};
const potatoArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//आलुखेति उत्पादन (के.जि.मा )
const potatoProductionQuantity = {
    nepali: "आलुखेति उत्पादन (के.जि.मा )",
    english: "Production of potato (kg)",
    value: "",
};

// आलुखेति विक्री परिमाण (के.जि.मा )
const potatoSaleQuantity = {
    nepali: "आलुखेति विक्री परिमाण (के.जि.मा )",
    english: "Sales quantity of potato(kg)",
    value: "",
};
const potatoSale = {
    nepali: "आलुखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly income from potato?",
    value: "",
};

//आलुखेति END

//बन्दा खेति START
// बन्दा खेति गरिएको विवरण
const cabbageInfo = { english: "", nepali: " बन्दा खेति गरिएको विवरण" };
const cabbageAreaQuestion = {
    english: "Area of cabbage farming",
    nepali: " बन्दा खेति गरिएको क्षेत्रफ़ल्",
};
const cabbageArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//बन्दा उत्पादन (के.जि.मा )
const cabbageProductionQuantity = {
    nepali: "बन्दा उत्पादन (के.जि.मा )",
    english: "Cabbage production(kg)",
    value: "",
};

// बन्दा विक्री परिमाण (के.जि.मा )
const cabbageSaleQuantity = {
    nepali: "बन्दा विक्री परिमाण (के.जि.मा )",
    english: "Sales quantity of cabbage(kg)",
    value: "",
};
const cabbageSale = {
    nepali: "बन्दा उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly income from cabbage?",
    value: "",
};

//बन्दा END

//काउली खेति START
// काउली खेति गरिएको विवरण
const cauliInfo = { english: "", nepali: " काउली खेति गरिएको विवरण" };
const cauliAreaQuestion = {
    english: "",
    nepali: " काउली खेति गरिएको क्षेत्रफ़ल्",
};
const cauliArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//काउली उत्पादन (के.जि.मा )
const cauliProductionQuantity = {
    nepali: "काउली उत्पादन (के.जि.मा )",
    english: "Cauliflower (kg)",
    value: "",
};

// काउली विक्री परिमाण (के.जि.मा )
const cauliSaleQuantity = {
    nepali: "काउली विक्री परिमाण (के.जि.मा )",
    english: "Sales quantity of cauliflower",
    value: "",
};
const cauliSale = {
    nepali: "काउली उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly income from cauliflower?",
    value: "",
};

//काउली END

//बोडी खेति START
// बोडी खेति गरिएको विवरण
const matarkoshaInfo = { english: "", nepali: " मटरकोशा खेति गरिएको विवरण" };
const matarkoshaAreaQuestion = {
    english: "Area of matarkosha farming",
    nepali: " मटरकोशा खेति गरिएको क्षेत्रफ़ल",
};
const matarkoshaArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//बोडी उत्पादन (के.जि.मा )
const matarkoshaProductionQuantity = {
    nepali: "मटरकोशा उत्पादन (के.जि.मा )",
    english: "Production of matarkosha(kg)",
    value: "",
};

// मटरकोशा विक्री परिमाण (के.जि.मा )
const matarkoshaSaleQuantity = {
    nepali: "मटरकोशा विक्री परिमाण (के.जि.मा )",
    english: "Matarkosha sales quantity(kg)",
    value: "",
};
const matarkoshaSale = {
    nepali: "मटरकोशा उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly income from matarkosha?",
    value: "",
};

//मटरकोशा END

// बोडी खेति गरिएको विवरण
const dhanyaInfo = { english: "", nepali: " धन्या खेति गरिएको विवरण" };
const dhanyaAreaQuestion = {
    english: "Area of dhaniya farming",
    nepali: " धन्या खेति गरिएको क्षेत्रफ़ल्",
};
const dhanyaArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//बोडी उत्पादन (के.जि.मा )
const dhanyaProductionQuantity = {
    nepali: "धनिया  (के.जि.मा )",
    english: "Production of dhaniya(kg)",
    value: "",
};

// मटरकोशा विक्री परिमाण (के.जि.मा )
const dhanyaSaleQuantity = {
    nepali: "धनिया बिक्रि परिमाण (के.जी मा)",
    english: "Sales quantity of dhaniya(kg)",
    value: "",
};
const dhanyaSale = {
    nepali: "धन्या उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly income of dhaniya ?",
    value: "",
};

//मटरकोशा END

//सिमि खेति START
// सिमि खेति गरिएको विवरण
const simiInfo = {
    english: "Detail of simi production",
    nepali: "सिमि खेति गरिएको विवरण",
};
const simiAreaQuestion = {
    english: "Area of simi farming",
    nepali: " सिमि खेति गरिएको क्षेत्रफ़ल्",
};
const simiArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//सिमि उत्पादन (के.जि.मा )
const simiProductionQuantity = {
    nepali: "सिमि उत्पादन (के.जि.मा )",
    english: "Production of simi(kg)",
    value: "",
};

// सिमि विक्री परिमाण (के.जि.मा )
const simiSaleQuantity = {
    nepali: "सिमि विक्री परिमाण (के.जि.मा )",
    english: "Sales quantity of simi(kg)",
    value: "",
};
const simiSale = {
    nepali: "सिमि उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly production from simi?",
    value: "",
};

//सिमि END

//गोलभेडा खेति START
// गोलभेडा खेति गरिएको विवरण
const tomatoInfo = { english: "", nepali: " गोलभेडा खेति गरिएको विवरण" };
const tomatoAreaQuestion = {
    english: "Area of tomato farming",
    nepali: " गोलभेडा खेति गरिएको क्षेत्रफ़ल्",
};
const tomatoArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//गोलभेडा उत्पादन (के.जि.मा )
const tomatoProductionQuantity = {
    nepali: "गोलभेडा उत्पादन (के.जि.मा )",
    english: "Tomato production(kg)",
    value: "",
};

// गोलभेडा विक्री परिमाण (के.जि.मा )
const tomatoSaleQuantity = {
    nepali: "गोलभेडा विक्री परिमाण (के.जि.मा )",
    english: "Sales quantity of tomato(kg)",
    value: "",
};
const tomatoSale = {
    nepali: "गोलभेडा उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly imcome from tomato?",
    value: "",
};

//गोलभेडा END

//काक्रो खेति START
// काक्रो खेति गरिएको विवरण
const cucumberInfo = { english: "", nepali: " काक्रो खेति गरिएको विवरण" };
const cucumberAreaQuestion = {
    english: "Area of cucumber farming",
    nepali: " काक्रो खेति गरिएको क्षेत्रफ़ल्",
};
const cucumberArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//काक्रो उत्पादन (के.जि.मा )
const cucumberProductionQuantity = {
    nepali: "काक्रो उत्पादन (के.जि.मा )",
    english: "Production of cucumber",
    value: "",
};

// काक्रो विक्री परिमाण (के.जि.मा )
const cucumberSaleQuantity = {
    nepali: "काक्रो विक्री परिमाण (के.जि.मा )",
    english: "Sales quantity of cucumber",
    value: "",
};
const cucumberSale = {
    nepali: "काक्रो उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly income from cucumber?",
    value: "",
};

//काक्रो END

//लौका खेति START
// लौका खेति गरिएको विवरण
const laukaInfo = { english: "", nepali: " लौका खेति गरिएको विवरण" };
const laukaAreaQuestion = {
    english: "Area of lauka farming",
    nepali: " लौका खेति गरिएको क्षेत्रफ़ल्",
};
const laukaArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//लौका उत्पादन (के.जि.मा )
const laukaProductionQuantity = {
    nepali: "लौका उत्पादन (के.जि.मा )",
    english: "Production of lauka(kg)",
    value: "",
};

// लौका विक्री परिमाण (के.जि.मा )
const laukaSaleQuantity = {
    nepali: "लौका विक्री परिमाण (के.जि.मा )",
    english: "Sales quantity of lauka(kg)",
    value: "",
};
const laukaSale = {
    nepali: "लौका उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly income of lauka?",
    value: "",
};

//लौका END

//फर्सी खेति START
// फर्सी खेति गरिएको विवरण
const saagInfo = { english: "", nepali: " साँग खेति गरिएको विवरण" };
const saagAreaQuestion = {
    english: "Area of saag farming",
    nepali: " साग खेति गरिएको क्षेत्रफ़ल्",
};
const saagArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//साँग उत्पादन (के.जि.मा )
const saagProductionQuantity = {
    nepali: "साँग उत्पादन (के.जि.मा )",
    english: "Saag production(kg)",
    value: "",
};

// साँग विक्री परिमाण (के.जि.मा )
const saagSaleQuantity = {
    nepali: "साँग विक्री परिमाण (के.जि.मा )",
    english: "Sales quantity of saag",
    value: "",
};
const saagSale = {
    nepali: "साँग उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly income of saag?",
    value: "",
};

//साँग END

//पर्वल खेति START
// पर्वल खेति गरिएको विवरण
const parwalInfo = { english: "", nepali: " पर्वल खेति गरिएको विवरण" };
const parwalAreaQuestion = {
    english: "Area of parwal farming",
    nepali: " पर्वल खेति गरिएको क्षेत्रफ़ल्",
};
const parwalArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//पर्वल उत्पादन (के.जि.मा )
const parwalProductionQuantity = {
    nepali: "पर्वल उत्पादन (के.जि.मा )",
    english: "Production of parwal(kg)",
    value: "",
};

// पर्वल विक्री परिमाण (के.जि.मा )
const parwalSaleQuantity = {
    nepali: "पर्वल विक्री परिमाण (के.जि.मा )",
    english: "Sales quantity of parwal(kg)",
    value: "",
};
const parwalSale = {
    nepali: "पर्वल उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly income of parwal?",
    value: "",
};

//पर्वल END

//करेला खेति START
// करेला खेति गरिएको विवरण
const karelaInfo = { english: "", nepali: " करेला खेति गरिएको विवरण" };
const karelaAreaQuestion = {
    english: "Area of karela farming",
    nepali: " करेला खेति गरिएको क्षेत्रफ़ल्",
};
const karelaArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//करेला उत्पादन (के.जि.मा )
const karelaProductionQuantity = {
    nepali: "करेला उत्पादन (के.जि.मा )",
    english: "Production of karela(kg)",
    value: "",
};

// करेला विक्री परिमाण (के.जि.मा )
const karelaSaleQuantity = {
    nepali: "करेला विक्री परिमाण (के.जि.मा )",
    english: "Sales quantity of karela(kg)",
    value: "",
};
const karelaSale = {
    nepali: "करेला उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly income of karela?",
    value: "",
};

//करेला END

//घिरौला खेति START
// घिरौला खेति गरिएको विवरण
const ghiraulaInfo = { english: "", nepali: " घिरौला खेति गरिएको विवरण" };
const ghiraulaAreaQuestion = {
    english: "Area of gharaula farming",
    nepali: " घिरौला खेति गरिएको क्षेत्रफ़ल्",
};
const ghiraulaArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//घिरौला उत्पादन (के.जि.मा )
const ghiraulaProductionQuantity = {
    nepali: "घिरौला उत्पादन (के.जि.मा )",
    english: "Production of ghiraula(kg)",
    value: "",
};

// घिरौला विक्री परिमाण (के.जि.मा )
const ghiraulaSaleQuantity = {
    nepali: "घिरौला विक्री परिमाण (के.जि.मा )",
    english: "Sales quantity of ghiraula(kg)",
    value: "",
};
const ghiraulaSale = {
    nepali: "घिरौला उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly income of ghiraula?",
    value: "",
};

//घिरौला END

//चिचिन्दो खेति START
// चिचिन्दो खेति गरिएको विवरण
const chichindoInfo = { english: "", nepali: " चिचिन्दो खेति गरिएको विवरण" };
const chichindoAreaQuestion = {
    english: "Area of chichindo farming",
    nepali: " चिचिन्दो खेति गरिएको क्षेत्रफ़ल्",
};
const chichindoArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//चिचिन्दो उत्पादन (के.जि.मा )
const chichindoProductionQuantity = {
    nepali: "चिचिन्दो उत्पादन (के.जि.मा )",
    english: "Production of chichindo(kg)",
    value: "",
};

// चिचिन्दो विक्री परिमाण (के.जि.मा )
const chichindoSaleQuantity = {
    nepali: "चिचिन्दो विक्री परिमाण (के.जि.मा )",
    english: "Sales quantity of chichindo(kg) ",
    value: "",
};
const chichindoSale = {
    nepali: "चिचिन्दो उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly income of chichindo?",
    value: "",
};

//चिचिन्दो END

//अन्य तरकारी  START
//माथि भन्दा अन्यखेति कति प्रकार को तरकारी वाली गरिएको छ ?
const anyTarkariKheti = {
    english: "Types of crops",
    nepali: "माथि भन्दा अन्यखेति कति प्रकार को तरकारी वाली गरिएको छ ?",
    value: "",
};
const anyTarkariInfo = {
    english: "Detail of other crops farming",
    nepali: "अन्य तरकारी खेति गरिएको विवरण",
};
const anyTarkariInfoTitle = {
    engish: "Mention if any",
    nepali: "अन्य भए उल्लेख गर्नु हाेस।",
    value: "",
};
const anyTarkariKhetiInfo = {
    english: "Farming detail",
    nepali: "खेति गरिएको विवरण",
    value: "",
};
const anyTarkariKhetiAreaQuestion = {
    english: "Other vegetable farming area",
    nepali: "अन्य तरकारीखेति गरिएको क्षेत्रफ़ल्",
};
const anyTarkariKhetiArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//अन्य उत्पादन (के.जि.मा )
const anyaProductionQuantity = {
    nepali: " चिचीन्ड़ो खेति उत्पादन (के.जि.मा)",
    english: "production farming (kg)",
    value: "",
};

// चिचिन्दो विक्री परिमाण (के.जि.मा )
const anyaSaleQuantity = {
    nepali: "खेति विक्री परिमाण (के.जि.मा)",
    english: "",
    value: "",
};
const anyaSale = {
    nepali: "खेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "",
    value: "",
};
//अन्य तरकारी END
//तरकारी END

//फलफुल START
//फलफुल उत्पादन तथा बिक्रि विवरण
//If xa vaney   कुन कुन फलफुल उत्पादन तथा बिक्रि विवरण छन् ?
const agriFruitTitle = {
    english: "Detail of fruits production",
    nepali: "फलफुल उत्पादन विवरण सुची ?",
};
const agriFruitType = [
    {
        nepali: "आप",
        english: "Mango",
        value: "mango",
    },
    {
        nepali: "केरा",
        english: "Banana",
        value: "Banana",
    },
    {
        nepali: "सुन्तला/जुनार /कागती (सुन्तला जात )",
        english: "Orange",
        value: "orange",
    },
    {
        nepali: "स्याउ",
        english: "Apple",
        value: "apple",
    },
    {
        nepali: "किवी",
        english: "Kiwi",
        value: "jack_fruit",
    },
    {
        nepali: "अभोगाड़ो",
        english: "pulam",
        value: "pulam",
    },
    {
        nepali: "आरु",
        english: "aaru",
        value: "aaru",
    },
    {
        nepali: "काफल",
        english: "kafal",
        value: "kafal",
    },
    {
        nepali: "मेवा",
        english: "papaiya",
        value: "papaiya",
    },
    {
        nepali: "लिची",
        english: "lichi",
        value: "lichi",
    },
    {
        nepali: "भुइँकटर",
        english: "pineappale",
        value: "pineappale",
    },
    {
        nepali: "अनार",
        english: "pomegranate",
        value: "pomegranate",
    },
    {
        nepali: "ड्रागन फ्रुट",
        english: "Dragan_Fruit",
        value: "Dragan_Fruit",
    },
    {
        nepali: "अम्बा",
        english: "Gauva",
        value: "ambaa",
    },
    {
        nepali: "हलुवाबेद",
        english: "haluwaabed",
        value: "haluwabed",
    },
    {
        nepali: "नासपाती",
        english: "pear",
        value: "nasspaati",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other_fruit",
    },
];
// आपखेति गरिएको विवरण
const mangoInfo = {
    english: "Detail of mango farming",
    nepali: "आपखेति गरिएको विवरण",
};
const mangoAreaQuestion = { english: "", nepali: "आपखेति गरिएको क्षेत्रफ़ल्" };
const mangoArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//आपखेतिि उत्पादन (मुरी पाथीमा)
const mangoProduction = {
    english: "Mango production",
    nepali: "आपखेति उत्पादन (मुरी पाथीमा)",
};
const mangoProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// आपखेतिि विक्री परिमाण (मुरी पाथीमा)
const mangoSale = {
    english: "Sales quantity of mango",
    nepali: "आपखेति विक्री परिमाण (मुरी पाथीमा)",
};
const mangoSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "आपखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of mango?",
        value: "",
    },
];
//आपखेति END

//लिची START
// आरुबखडाखेति गरिएको विवरण
const aarubakhadaInfo = { english: "", nepali: " आरुबखडाखेति गरिएको विवरण" };
const aarubakhadaAreaQuestion = {
    english: "Area of arubakhada farming",
    nepali: "आरुबखडाखेति गरिएको क्षेत्रफ़ल्",
};
const aarubakhadaArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//आरुबखडाखेति उत्पादन (मुरी पाथीमा)
const aarubakhadaProduction = {
    english: "Production of arubakhada(muri pathi)",
    nepali: "आरुबखडाखेति उत्पादन (मुरी पाथीमा)",
};
const aarubakhadaProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// विक्री परिमाण (मुरी पाथीमा)
const aarubakhadaSale = {
    english: "Sales quantity of arubakhada(muri pathi)",
    nepali: "आरुबखडाखेति विक्री परिमाण (मुरी पाथीमा)",
};
const aarubakhadaSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "आरुबखडाखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of arubhakada?",
        value: "",
    },
];
//आरुबखडाखेति END

//केरा START
// केराखेति गरिएको विवरण
const bananaInfo = {
    english: "Detail of banana farming",
    nepali: " केराखेति गरिएको विवरण",
};
const bananaAreaQuestion = {
    english: "Area of banana farming",
    nepali: " केराखेति गरिएको क्षेत्रफ़ल",
};
const bananaArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//केरा उत्पादन (मुरी पाथीमा)
const bananaProduction = {
    english: "Production of banana(muri pathi)",
    nepali: "केरा उत्पादन (मुरी पाथीमा)",
};
const bananaProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// विक्री परिमाण (मुरी पाथीमा)
const bananaSale = {
    english: "Sales quantity of banana(muri pathi)",
    nepali: "केरा विक्री परिमाण (मुरी पाथीमा)",
};
const bananaSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "केराखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of banana?",
        value: "",
    },
];
//केरा END

//सुन्तलाखेति START
// सुन्तलाखेति गरिएको विवरण
const orangeInfo = { english: "", nepali: " सुन्तलाखेति गरिएको विवरण" };
const orangeAreaQuestion = {
    english: "Area of orange farming",
    nepali: " सुन्तलाखेति गरिएको क्षेत्रफ़ल्",
};
const orangeArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//सुन्तला उत्पादन (मुरी पाथीमा)
const orangeProduction = {
    english: "Production of orange (muri pathi)",
    nepali: "सुन्तला उत्पादन (मुरी पाथीमा)",
};
const orangeProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// विक्री परिमाण (मुरी पाथीमा)
const orangeSale = {
    english: "Sales quantity of orange(muri pathi)",
    nepali: "सुन्तला विक्री परिमाण (मुरी पाथीमा)",
};
const orangeSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "सुन्तलाखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of orange?",
        value: "",
    },
];
//सुन्तलाखेति END

//अम्बकखेति START
// अम्बकखेति गरिएको विवरण
const guvaInfo = {
    english: "Detail of guava farming",
    nepali: "अम्बकखेति गरिएको विवरण",
};
const guvaAreaQuestion = {
    english: "Area of guava farming",
    nepali: " अम्बकखेति गरिएको क्षेत्रफ़ल",
};
const guvaArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//जुनार उत्पादन (मुरी पाथीमा)
const guvaProduction = {
    english: "Production of guava ",
    nepali: "अम्बकखेति उत्पादन (मुरी पाथीमा)",
};
const guvaProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//जुनार विक्री परिमाण (मुरी पाथीमा)
const guvaSale = {
    english: "Sales quantity of guava(muri pathi)",
    nepali: "अम्बकखेति विक्री परिमाण (मुरी पाथीमा)",
};
const guvaSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "अम्बकखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of guava?",
        value: "",
    },
];
//अम्बकखेति खेति END

//कागतीखेति START
// कागतीखेति गरिएको विवरण
const lemonInfo = {
    english: "Detail of lemon farming",
    nepali: " कागतीखेति गरिएको विवरण",
};
const lemonAreaQuestion = {
    english: "Area of lemon farming",
    nepali: " कागतीखेति गरिएको क्षेत्रफ़ल",
};
const lemonArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//कागती उत्पादन (मुरी पाथीमा)
const lemonProduction = {
    english: "Production of lemon(muri pathi)",
    nepali: "कागती उत्पादन (मुरी पाथीमा)",
};
const lemonProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//कागती विक्री परिमाण (मुरी पाथीमा)
const lemonSale = {
    english: "Sales quantity of lemon(muri pathi)",
    nepali: "कागती विक्री परिमाण (मुरी पाथीमा)",
};
const lemonSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "कागती उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of lemon?",
        value: "",
    },
];
//कागती खेति END

//स्याउखेति START
// स्याउखेति गरिएको विवरण
const appleInfo = {
    english: "Detail of apple farming",
    nepali: " स्याउखेति गरिएको विवरण",
};
const appleAreaQuestion = {
    english: "Area of apple farming",
    nepali: " स्याउखेति गरिएको क्षेत्रफ़ल",
};
const appleArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//स्याउ उत्पादन (मुरी पाथीमा)
const appleProduction = {
    english: "Production of apple(muri pathi)",
    nepali: "स्याउ उत्पादन (मुरी पाथीमा)",
};
const appleProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//स्याउ विक्री परिमाण (मुरी पाथीमा)
const appleSale = {
    english: "Sales quantity of apple(muri pathi) ",
    nepali: "स्याउ विक्री परिमाण (मुरी पाथीमा)",
};
const appleSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "स्याउ उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of apple?",
        value: "",
    },
];
//स्याउ खेति END

//किवीखेति START
// किवीखेति गरिएको विवरण
const kiwiInfo = {
    english: "Detail of kiwi farming",
    nepali: " किवीखेति गरिएको विवरण",
};
const kiwiAreaQuestion = {
    english: "Area of kiwi farming",
    nepali: " किवीखेति गरिएको क्षेत्रफ़ल",
};
const kiwiArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//किवी उत्पादन (मुरी पाथीमा)
const kiwiProduction = {
    english: "Production of kiwi(muri pathi)",
    nepali: "किवी उत्पादन (मुरी पाथीमा)",
};
const kiwiProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//किवी विक्री परिमाण (मुरी पाथीमा)
const kiwiSale = {
    english: "Sales quantity of kiwi(muri pathi)",
    nepali: "किवी विक्री परिमाण (मुरी पाथीमा)",
};
const kiwiSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "किवी उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of kiwi?",
        value: "",
    },
];
//किवी खेति END

// नासपातीखेति START
// नासपातीखेति गरिएको विवरण
const naspatiInfo = {
    english: "Detail of pear farming",
    nepali: " नासपातीखेति गरिएको विवरण",
};
const naspatiAreaQuestion = {
    english: "Area of pear farming",
    nepali: " नासपातीखेति गरिएको क्षेत्रफ़ल",
};
const naspatiArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//नासपाती उत्पादन (मुरी पाथीमा)
const naspatiProduction = {
    english: "Production of pear(muri pathi)",
    nepali: "नासपाती उत्पादन (मुरी पाथीमा)",
};
const naspatiProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//नासपाती विक्री परिमाण (मुरी पाथीमा)
const naspatiSale = {
    english: "Sales quantity of pear(muri pathi)",
    nepali: "नासपाती विक्री परिमाण (मुरी पाथीमा)",
};
const naspatiSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "नासपाती उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of pear?",
        value: "",
    },
];
//नासपाती खेति END

// अभोगाड़ो START
// अभोगाड़ो गरिएको विवरण
const pulamInfo = {
    english: "Detail of pulam farming",
    nepali: " अभोगाड़ो गरिएको विवरण",
};
const pulamAreaQuestion = {
    english: "Area of pulam farming",
    nepali: " अभोगाड़ो गरिएको क्षेत्रफ़ल",
};
const pulamArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//अभोगाड़ो उत्पादन (मुरी पाथीमा)
const pulamProduction = {
    english: "Production of pulam(muri pathi)",
    nepali: "अभोगाड़ो उत्पादन (मुरी पाथीमा)",
};
const pulamProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//अभोगाड़ो विक्री परिमाण (मुरी पाथीमा)
const pulamSale = {
    english: "Sales quantity of pulam(muri pathi) ",
    nepali: "अभोगाड़ो विक्री परिमाण (मुरी पाथीमा)",
};
const pulamSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "अभोगाड़ो उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of pulam?",
        value: "",
    },
];
//अभोगाड़ो खेति END

// ड्रागन फ्रुट START
// ड्रागन फ्रुट गरिएको विवरण
const DraganfruitInfo = {
    english: "Detail of Draganfruit farming",
    nepali: " ड्रागन फ्रुट गरिएको विवरण",
};
const DraganfruitAreaQuestion = {
    english: "Area of Draganfruit farming",
    nepali: " ड्रागन फ्रुट गरिएको क्षेत्रफ़ल",
};
const DraganfruitArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//ड्रागन फ्रुट उत्पादन (मुरी पाथीमा)
const DraganfruitProduction = {
    english: "Production of Draganfruit(muri pathi)",
    nepali: "ड्रागन फ्रुट उत्पादन (मुरी पाथीमा)",
};
const DraganfruitProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//ड्रागन फ्रुट विक्री परिमाण (मुरी पाथीमा)
const DraganfruitSale = {
    english: "Sales quantity of Draganfruit(muri pathi) ",
    nepali: "ड्रागन फ्रुट विक्री परिमाण (मुरी पाथीमा)",
};
const DraganfruitSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "ड्रागन फ्रुट उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of Draganfruit?",
        value: "",
    },
];
//ड्रागन फ्रुट खेति END

// अनार START
// अनार गरिएको विवरण
const pomegranateInfo = {
    english: "Detail of pomegranate farming",
    nepali: " अनार गरिएको विवरण",
};
const pomegranateAreaQuestion = {
    english: "Area of pomegranate farming",
    nepali: " अनार गरिएको क्षेत्रफ़ल",
};
const pomegranateArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//अनार उत्पादन (मुरी पाथीमा)
const pomegranateProduction = {
    english: "Production of pomegranate(muri pathi)",
    nepali: "अनार उत्पादन (मुरी पाथीमा)",
};
const pomegranateProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//अनार विक्री परिमाण (मुरी पाथीमा)
const pomegranateSale = {
    english: "Sales quantity of pomegranate(muri pathi) ",
    nepali: "अनार विक्री परिमाण (मुरी पाथीमा)",
};
const pomegranateSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "अनार उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of pomegranate?",
        value: "",
    },
];
//अनार खेति END

// भुइँकटर START
// भुइँकटर गरिएको विवरण
const pineappaleInfo = {
    english: "Detail of pineappale farming",
    nepali: " भुइँकटर गरिएको विवरण",
};
const pineappaleAreaQuestion = {
    english: "Area of pineappale farming",
    nepali: " भुइँकटर गरिएको क्षेत्रफ़ल",
};
const pineappaleArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//भुइँकटर उत्पादन (मुरी पाथीमा)
const pineappaleProduction = {
    english: "Production of pineappale(muri pathi)",
    nepali: "भुइँकटर उत्पादन (मुरी पाथीमा)",
};
const pineappaleProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//भुइँकटर विक्री परिमाण (मुरी पाथीमा)
const pineappaleSale = {
    english: "Sales quantity of pineappale(muri pathi) ",
    nepali: "भुइँकटर विक्री परिमाण (मुरी पाथीमा)",
};
const pineappaleSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "भुइँकटर उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of pineappale?",
        value: "",
    },
];
//भुइँकटर खेति END

// काफल START
// काफल गरिएको विवरण
const kafalInfo = {
    english: "Detail of kafal farming",
    nepali: " काफल गरिएको विवरण",
};
const kafalAreaQuestion = {
    english: "Area of kafal farming",
    nepali: " काफल गरिएको क्षेत्रफ़ल",
};
const kafalArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//काफल उत्पादन (मुरी पाथीमा)
const kafalProduction = {
    english: "Production of kafal(muri pathi)",
    nepali: "काफल उत्पादन (मुरी पाथीमा)",
};
const kafalProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//काफल विक्री परिमाण (मुरी पाथीमा)
const kafalSale = {
    english: "Sales quantity of kafal(muri pathi) ",
    nepali: "काफल विक्री परिमाण (मुरी पाथीमा)",
};
const kafalSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "काफल उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of kafal?",
        value: "",
    },
];
//काफल खेति END

//अन्यखेति START
//माथि भन्दा अन्यखेति कति प्रकार को फलफुल खेति गरिएको छ ?
const agriAnyFruit = {
    english: "Different crops farm",
    nepali: "माथि भन्दा अन्यखेति कति प्रकार को फलफुल खेति गरिएको छ ?",
    value: "",
};
const agriAnyFruitInfo = {
    english: "Detail of other fruits farming",
    nepali: "अन्य फलफुल खेति गरिएको विवरण",
};
const agriAnyInfoFruitTitle = {
    engish: "Mention if any",
    nepali: "अन्य भए उल्लेख गर्नु हाेस।",
    value: "",
};
const agriAnyFruitKhetiInfo = {
    english: "Detail about farming",
    nepali: "खेति गरिएको विवरण",
    value: "",
};
const agriAnyFruitKhetiAreaQuestion = {
    english: "Area of farming",
    nepali: "अन्यखेति गरिएको क्षेत्रफ़ल्",
};
const agriAnyFruitKhetiArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//अन्यखेति उत्पादन (मुरी पाथीमा)
const agriAnyFruitKhetiProduction = {
    english: "Different crops production",
    nepali: "अन्यखेति उत्पादन (मुरी पाथीमा)",
};
const agriAnyFruitKhetiProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//अन्यखेति विक्री परिमाण (मुरी पाथीमा)
const agriAnyFruitKhetiSale = {
    english: "Other farming detail",
    nepali: "अन्यखेति विक्री परिमाण (मुरी पाथीमा)",
};
const agriAnyFruitKhetiSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "अन्यखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "What is the yearly income of other crops?",
        value: "",
    },
];
//अन्यखेति END
//फलफुल END

//नगदेवाली START

//नगदेवाली उत्पादन तथा बिक्रि विवरण
//If xa vaney   कुन कुन नगदेवाली उत्पादन तथा बिक्रि विवरण छन् ?
const cashCropTitle = {
    english: "Production of cash crop detail",
    nepali: "नगदेवाली उत्पादन विवरण सुची",
};
const cashCropType = [
    {
        nepali: "अलैची",
        english: "alaichi",
        value: "chilly",
    },
    {
        nepali: "अम्लिसो",
        english: "Amliso",
        value: "amliso",
    },
    {
        nepali: "कफी",
        english: "Coffee",
        value: "coffee",
    },
    {
        nepali: "चिया",
        english: "Tea",
        value: "tea",
    },
    {
        nepali: "अदुवा/बेसार",
        english: "Ginger",
        value: "ginger",
    },
    {
        nepali: "लसुन/प्याज",
        english: "Garlic",
        value: "garlic",
    },
    {
        nepali: "च्याउ",
        english: "Mushroom",
        value: "mushroom",
    },
    {
        nepali: "उखु",
        english: "Suagrcane",
        value: "sugarcane",
    },
    {
        nepali: "सुपारी",
        english: "nut",
        value: "supari",
    },
    {
        nepali: "ओखर",
        english: "okhar",
        value: "okhar",
    },
    {
        nepali: "नरिवल",
        english: "coconut",
        value: "coconut",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other_cash_cro",
    },
];
// धसिंगरेखेति गरिएको विवरण
const dhasingareInfo = {
    english: "Detailof dhasingre ",
    nepali: "धसिंगरेखेति गरिएको विवरण",
};
const dhasingareAreaQuestion = {
    english: "Area of dhisingare farm",
    nepali: "धसिंगरेखेति गरिएको क्षेत्रफ़ल",
};
const dhasingareArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//धसिंगरेखेति उत्पादन (मुरी पाथीमा)
const dhasingareProduction = {
    english: "Production of dhasingare(muri pathi)",
    nepali: "धसिंगरेखेति उत्पादन (मुरी पाथीमा)",
};
const dhasingareProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// धसिंगरेखेति विक्री परिमाण (मुरी पाथीमा)
const dhasingareSale = {
    english: "Sales quantity of dhasingare(muri pathi)",
    nepali: "धसिंगरेखेति विक्री परिमाण (मुरी पाथीमा)",
};
const dhasingareSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//धसिंगरेखेति END

//अलैचीखेति START
// अलैचीखेति गरिएको विवरण
const chillyInfo = {
    english: "Detail of chilly farming",
    nepali: "अलैचीखेति गरिएको विवरण",
};
const chillyAreaQuestion = {
    english: "Area of chilly faraming",
    nepali: "अलैचीखेति गरिएको क्षेत्रफ़ल",
};
const chillyArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//अलैचीखेति उत्पादन (मुरी पाथीमा)
const chillyProduction = {
    english: "Production of chilly(muri pathi)",
    nepali: "अलैचीखेति उत्पादन (मुरी पाथीमा)",
};
const chillyProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
// अलैचीखेति विक्री परिमाण (मुरी पाथीमा)
const chillySale = {
    english: "Sales quantity of chilly(muri pathi) ",
    nepali: "अलैचीखेति विक्री परिमाण (मुरी पाथीमा)",
};
const chillySaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//अलैचीखेति END

//सुपारी START
// सुपारीखेति गरिएको विवरण
const rudrakshInfo = {
    english: "Detail of supari farming",
    nepali: "सुपारीखेति गरिएको विवरण",
};
const rudrakshAreaQuestion = {
    english: "Area of supari farming",
    nepali: "सुपारीखेति गरिएको क्षेत्रफ़ल्",
};
const rudrakshArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//सुपारीको बोट संख्या
const rudrakshProduction = {
    english: "No of supari plant",
    nepali: "सुपारीको बोट संख्या",
    value: "",
};

// सुपारीखेति विक्री परिमाण (के.जि.मा)
const rudrakshSale = {
    english: "What is the yearly income of supari?",
    nepali: "सुपारी(दाना) खेति उत्पादन वाट बार्षिक बिक्रि कति हो ? (के.जि. मा)",
    value: "",
};
//सुपारीखेति END

//अम्लिसोखेति START
// अम्लिसोखेति गरिएको विवरण
const amlisoInfo = {
    english: "Detail of amliso farming",
    nepali: "अम्लिसोखेति गरिएको विवरण",
};
const amlisoAreaQuestion = {
    english: "Area of amliso farming",
    nepali: "अम्लिसोखेति गरिएको क्षेत्रफ़ल",
};
const amlisoArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//अम्लिसोखेति उत्पादन (मुरी पाथीमा)
const amlisoProduction = {
    english: "Production of amliso(muri pathi)",
    nepali: "अम्लिसोखेति उत्पादन (मुरी पाथीमा)",
};
const amlisoProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];

// अम्लिसोखेति विक्री परिमाण (मुरी पाथीमा)
const amlisoSale = {
    english: "Sales quantity of amliso(muri pathi)",
    nepali: "अम्लिसोखेति विक्री परिमाण (मुरी पाथीमा)",
};
const amlisoSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//अम्लिसोखेति END

//कफीखेति START
// कफीखेति गरिएको विवरण
const coffeeInfo = {
    english: "Detail of coffee farming",
    nepali: "कफीखेति गरिएको विवरण",
};
//कफीखेति उत्पादन (मुरी पाथीमा)
const coffeeTreeNumber = {
    nepali: "कफीखेति गरिएको बोट संख्या",
    english: "No of coffee plant",
    value: "",
};
const coffeeProduction = {
    nepali: "कफीखेति उत्पादन (के.जि.मा)",
    english: "Production of coffee (kg)",
    value: "",
};
// कफीखेति विक्री परिमाण (मुरी पाथीमा)
const coffeeSale = {
    nepali: "कफीखेति विक्री परिमाण (के.जि.मा)",
    english: "Sales quantity of coffee(kg)",
    value: "",
};
//कफीखेति END

//चियाखेति START
// चियाखेति गरिएको विवरण
const teaInfo = {
    english: "Detail of tea farming",
    nepali: "चियाखेति गरिएको विवरण",
};
const teaAreaQuestion = { english: "", nepali: "चियाखेति गरिएको क्षेत्रफ़ल्" };
const teaArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//चियाखेति बोट संख्या
const teaProduction = {
    nepali: "चियाखेति उत्पादन (के.जि.मा)",
    english: "Production of tea(kg)",
    value: "",
};

// चियाखेति विक्री परिमाण (के.जि.मा)
const teaSale = {
    english: "Sales quantity of tea(kg)",
    nepali: "चियाखेति विक्री परिमाण (के.जि.मा)",
    value: "",
};
//चियाखेति END

//निबुवाखेति START
// निबुवाखेति गरिएको विवरण
const nibuwaInfo = { english: "", nepali: "निबुवाखेति गरिएको विवरण" };
const nibuwaAreaQuestion = {
    english: "Area of nibuwa farming",
    nepali: "निबुवाखेति गरिएको क्षेत्रफ़ल",
};
const nibuwaArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//निबुवाखेति उत्पादन (मुरी पाथीमा)
const nibuwaProduction = {
    english: "Production of nibuwa(muri pathi)",
    nepali: "निबुवाखेति उत्पादन (मुरी पाथीमा)",
};
const nibuwaProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];

// निबुवाखेति विक्री परिमाण (मुरी पाथीमा)
const nibuwaSale = {
    english: "Sales quantity of nibuwa ",
    nepali: "निबुवाखेति विक्री परिमाण (मुरी पाथीमा)",
};
const nibuwaSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//निबुवाखेति END

//अदुवाखेति START
// अदुवाखेति गरिएको विवरण
const gingerInfo = { english: "", nepali: "अदुवाखेति गरिएको विवरण" };
const gingerAreaQuestion = {
    english: "Area of ginger farming",
    nepali: "अदुवाखेति गरिएको क्षेत्रफ़ल",
};
const gingerArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//अदुवाखेति उत्पादन (मुरी पाथीमा)
const gingerProduction = {
    english: "Production of ginger(muri pathi)",
    nepali: "अदुवाखेति उत्पादन (मुरी पाथीमा)",
};
const gingerProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];

// अदुवाखेति विक्री परिमाण (मुरी पाथीमा)
const gingerSale = {
    english: "Sales quantity of ginger ",
    nepali: "अदुवाखेति विक्री परिमाण (मुरी पाथीमा)",
};
const gingerSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//अदुवाखेति END

//बेसारखेति START
// बेसारखेति गरिएको विवरण
const besaarInfo = {
    english: "Detail of beshar farming",
    nepali: "बेसारखेति गरिएको विवरण",
};
const besaarAreaQuestion = {
    english: "Area of beshar farming",
    nepali: "बेसारखेति गरिएको क्षेत्रफ़ल",
};
const besaarArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//बेसारखेति उत्पादन (मुरी पाथीमा)
const besaarProduction = {
    english: "Production of beshar(muri pathi)",
    nepali: "बेसारखेति उत्पादन (मुरी पाथीमा)",
};
const besaarProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];

// बेसारखेति विक्री परिमाण (मुरी पाथीमा)
const besaarSale = {
    english: "Sales quantity of beshar(muri pathi)",
    nepali: "बेसारखेति विक्री परिमाण (मुरी पाथीमा)",
};
const besaarSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//बेसारखेति END

//लसुनखेति START
// लसुनखेति गरिएको विवरण
const garlicInfo = {
    english: "Detail of fgarlic farming",
    nepali: "लसुनखेति गरिएको विवरण",
};
const garlicAreaQuestion = {
    english: "Area of garlic farming",
    nepali: "लसुनखेति गरिएको क्षेत्रफ़ल",
};
const garlicArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//लसुनखेति उत्पादन (मुरी पाथीमा)
const garlicProduction = {
    english: "Production of garlic(muri pathi)",
    nepali: "लसुनखेति उत्पादन (मुरी पाथीमा)",
};
const garlicProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];

// लसुनखेति विक्री परिमाण (मुरी पाथीमा)
const garlicSale = {
    english: "Sales quantity of garlic (muri pathi)",
    nepali: "लसुनखेति विक्री परिमाण (मुरी पाथीमा)",
};
const garlicSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//लसुनखेति END

//प्याजखेति START
// प्याजखेति गरिएको विवरण
const onionInfo = {
    english: "Detail of onion farming",
    nepali: "प्याजखेति गरिएको विवरण",
};
const onionAreaQuestion = {
    english: "Area of onion farming",
    nepali: "प्याजखेति गरिएको क्षेत्रफ़ल",
};
const onionArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//प्याजखेति उत्पादन (मुरी पाथीमा)
const onionProduction = {
    english: "Production of onion (muri pathi)",
    nepali: "प्याजखेति उत्पादन (मुरी पाथीमा)",
};
const onionProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];

// प्याजखेति विक्री परिमाण (मुरी पाथीमा)
const onionSale = {
    english: "Sales quantity of onion (muri pathi)",
    nepali: "प्याजखेति विक्री परिमाण (मुरी पाथीमा)",
};
const onionSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//प्याजखेति END

//च्याउखेति START
// च्याउखेति गरिएको विवरण
const mushroomInfo = {
    english: "Detail of mushroom farming",
    nepali: "च्याउखेति गरिएको विवरण",
};
const mushroomAreaQuestion = {
    english: "Area of mushroom farming",
    nepali: "च्याउखेति गरिएको क्षेत्रफ़ल",
};
const mushroomArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//च्याउखेति उत्पादन (मुरी पाथीमा)
const mushroomProduction = {
    english: "Production of mushroom(muri pathi)",
    nepali: "च्याउखेति उत्पादन (मुरी पाथीमा)",
};
const mushroomProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];

// च्याउखेति विक्री परिमाण (मुरी पाथीमा)
const mushroomSale = {
    english: "Sales quantity of mushroom(muri pathi)",
    nepali: "च्याउखेति विक्री परिमाण (मुरी पाथीमा)",
};
const mushroomSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//च्याउखेति END

//उखुखेति START
// उखुखेति गरिएको विवरण
const sugarcaneInfo = {
    english: "Detail of sugarcane production",
    nepali: "उखुखेति गरिएको विवरण",
};
const sugarcaneAreaQuestion = {
    english: "Area of sugarcane farming",
    nepali: "उखुखेति गरिएको क्षेत्रफ़ल",
};
const sugarcaneArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//उखुखेति उत्पादन (मुरी पाथीमा)
const sugarcaneProduction = {
    english: "Production of sugarcane(muri pathi)",
    nepali: "उखुखेति उत्पादन (मुरी पाथीमा)",
};
const sugarcaneProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];

// उखुखेति विक्री परिमाण (मुरी पाथीमा)
const sugarcaneSale = {
    english: "Sales quantity of sugarcane (muri pathi)",
    nepali: "उखुखेति विक्री परिमाण (मुरी पाथीमा)",
};
const sugarcaneSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//उखुखेति END

//ओखरखेति START
// ओखरखेति गरिएको विवरण
const okharInfo = {
    english: "Detail of okhar farming",
    nepali: "ओखरखेति गरिएको विवरण",
};
const okharAreaQuestion = {
    english: "Area of okhar farming",
    nepali: "ओखरखेति गरिएको क्षेत्रफ़ल",
};
const okharArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//ओखरखेति उत्पादन (मुरी पाथीमा)
const okharProduction = {
    english: "Production of okhar (muri pathi)",
    nepali: "ओखरखेति उत्पादन (मुरी पाथीमा)",
};
const okharProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];

// ओखरखेति विक्री परिमाण (मुरी पाथीमा)
const okharSale = {
    english: "Sales quantity of okhar (muri pathi)",
    nepali: "ओखरखेति विक्री परिमाण (मुरी पाथीमा)",
};
const okharSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//ओखरखेति END

//नरिवलखेति START
// नरिवलखेति गरिएको विवरण
const coconutInfo = {
    english: "Detail of coconut farming",
    nepali: "नरिवलखेति गरिएको विवरण",
};
const coconutAreaQuestion = {
    english: "Area of coconut production",
    nepali: "नरिवलखेति गरिएको क्षेत्रफ़ल",
};
const coconutArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//नरिवलखेति उत्पादन (मुरी पाथीमा)
const coconutProduction = {
    english: "Production of coconut (muri pathi)",
    nepali: "नरिवलखेति उत्पादन (मुरी पाथीमा)",
};
const coconutProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];

// नरिवलखेति विक्री परिमाण (मुरी पाथीमा)
const coconutSale = {
    english: "Sales quantity of coconut(muri pathi)",
    nepali: "नरिवलखेति विक्री परिमाण (मुरी पाथीमा)",
};
const coconutSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//नरिवलखेति END
//अन्य नगदेबाली  START
//माथि भन्दा अन्य नगदेबाली कति प्रकार को अन्नवाली गरिएको छ ?
const anyCashCropKheti = {
    english: "Different kind of crops farming",
    nepali: "माथि भन्दा अन्य नगदेबाली कति प्रकार को अन्नवाली गरिएको छ ?",
    value: "",
};
const anyCashCropKhetiInfo = {
    english: "Different cash crop farming detail",
    nepali: "अन्य नगदेबाली गरिएको विवरण",
};
const anyCashCropInfoTitle = {
    engish: "Mention if any",
    nepali: "अन्य भए उल्लेख गर्नु हाेस।",
    value: "",
};
const anyCashCropInfo = {
    english: "Detail of farming",
    nepali: "खेति गरिएको विवरण",
    value: "",
};
const anyCashCropAreaQuestion = {
    english: "Area of other cashcrop",
    nepali: "अन्य नगदेबाली  गरिएको क्षेत्रफ़ल",
};
const anyCashCropArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//अन्य नगदेबाली  उत्पादन (मुरी पाथीमा)
const anyCashCropProduction = {
    english: "Other cashsrop production(muri pathi)",
    nepali: "अन्य नगदेबाली  उत्पादन (मुरी पाथीमा)",
};
const anyCashCropProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//अन्य नगदेबाली  विक्री परिमाण (मुरी पाथीमा)
const anyCashCropSale = {
    english: "Sales quantity of other cashcrop(muri pathi)",
    nepali: "अन्य नगदेबाली विक्री परिमाण (मुरी पाथीमा)",
};
const anyCashCropSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//अन्य नगदेबाली END
//नगदेवाली END

// फुलखेती गरिएको विवरण
const flowerTitle = {
    english: "Flower farming",
    nepali: "फुलखेती",
};
const flowerAreaQuestion = {
    english: "Area of flower farming",
    nepali: "फुलखेती गरिएको क्षेत्रफ़ल्",
};
const flowerArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//फुलखेती उत्पादन (मुरी पाथीमा)
const flowerProduction = {
    english: "Production of flower",
    nepali: "फुलखेतीको उत्पादन",
    value: "",
};

// फुलखेती विक्री परिमाण (मुरी पाथीमा)
const flowerSale = {
    english: "Sales quantity of flower (kg)",
    nepali: "विक्री परिमाण (के.जी. मा)",
    value: "",
};
const flowerSaleQuantity = {
    nepali: " फुलखेती उत्पादन वाट बार्षिक आम्दानी कति हो ?",
    english: "What is the yearly income of flower?",
    value: "",
};

//फुलखेती END

//किट नाशक औषधि/मल को प्रयोग  START
const insecticideQuestion = {
    nepali: "कृषि बाली अनुसार किट नाशक औषधि/मल को प्रयोग गर्नु भएको छ ?",
    english: "Do you use insecticide and pesticide in crops?",
};
const insecticideYesNo = [
    {
        nepali: "छ",
        english: "yes",
        value: "chemical_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "chemical_no",
    },
];
//if xa vanye
const insecticideYes = {
    nepali: "वाली अनुसार मलको प्रयोग गर्नु भएको छ भने ?",
    english: "Do you use fertilizer according to crops?",
};
const insecticideType = [
    {
        nepali: "स्थानीय अर्गानिक",
        english: "Organic",
        value: "organic",
    },
    {
        nepali: "रासायनिक किट नासक औषधि",
        english: "Chemical fertilizer",
        value: "chemical",
    },
    {
        nepali: "दुवै",
        english: "Both",
        value: "both_org_chem",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "no",
    },
];
//किट नाशक औषधि/मल को प्रयोग  END

//पशुपन्छी START
const livestockQuestion = {
    nepali: "तपाइको घरमा कुनै पशुपन्छी पाल्नु भएको छ ?",
    english: "Do u rear animals in your home?",
};
const livestockYesNo = [
    {
        nepali: "छ",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];
//if xa vanye
const livestockYes = {
    nepali: "के के पाल्नु भएको छ ?",
    english: "Which animal you rear?",
};
const livestockType = [
    {
        nepali: "पशुपालन",
        english: "Animal",
        value: "animal",
    },
    {
        nepali: "पन्छीपालन",
        english: "Bird",
        value: "bird",
    },
    {
        nepali: "पशु र पन्छी दुवै पाल्ने",
        english: "Bird and animal",
        value: "both",
    },
    {
        nepali: "कुनै पनि नपाल्ने",
        english: "not any",
        value: "not_any",
    },
];
//if पशु xa vaney
//If xa vaney कुन कुन पशु पाल्नु भएको छ ?
const livestockTitle = {
    english: "Which animal you rear?",
    nepali: "कुन कुन पशु पाल्नु भएको छ ?",
};

const allLivestock = [
    {
        nepali: "गाई",
        english: "Cow",
        value: "cow",
    },
    {
        nepali: "भैसी",
        english: "Bufffalo",
        value: "buffello",
    },
    {
        nepali: "खसी/ बाख्रा /बोका",
        english: "Goat",
        value: "khasi",
    },
    {
        nepali: "राँगा/पाडा/पाडी",
        english: "Raanga/paada/padi",
        value: "paada_rangaa",
    },
    {
        nepali: "सुँगुर/बंगुर",
        english: "Pig",
        value: "pig",
    },
    {
        nepali: "भेडा",
        english: "Sheep",
        value: "sheep",
    },
    {
        nepali: "गोरु",
        english: "ox",
        value: "goru",
    },
    {
        nepali: "घोडा",
        english: "Horse",
        value: "horse",
    },
    {
        nepali: "चौरी गाई",
        english: "chauri cow",
        value: "chauri_gaai",
    },
    {
        nepali: "बाछा /बाछी",
        english: "calf",
        value: "baachhabaachhi",
    },
    {
        nepali: "च्यांग्रा",
        english: "shrimp",
        value: "chhyangra",
    },
    {
        nepali: "कुखुरा",
        english: "Chicken",
        value: "chicken",
    },
    {
        nepali: "परेवा",
        english: "pegion",
        value: "pegion",
    },
    {
        nepali: "हाँस",
        english: "Duck",
        value: "duck",
    },
];
const livestockDiffType = [
    {
        nepali: "गाई",
        english: "Cow",
        value: "cow",
    },
    {
        nepali: "भैसी",
        english: "Bufffalo",
        value: "buffello",
    },
    {
        nepali: "खसी/ बाख्रा /बोका",
        english: "Goat",
        value: "khasi",
    },
    {
        nepali: "राँगा/पाडा/पाडी",
        english: "Raanga/paada/padi",
        value: "paada_rangaa",
    },
    {
        nepali: "सुँगुर/बंगुर",
        english: "Pig",
        value: "pig",
    },
    {
        nepali: "भेडा",
        english: "Sheep",
        value: "sheep",
    },
    {
        nepali: "गोरु",
        english: "ox",
        value: "goru",
    },
    {
        nepali: "घोडा",
        english: "Horse",
        value: "horse",
    },
    {
        nepali: "चौरी गाई",
        english: "chauri cow",
        value: "chauri_gaai",
    },
    {
        nepali: "बाछा /बाछी",
        english: "calf",
        value: "baachhabaachhi",
    },
    {
        nepali: "च्यांग्रा",
        english: "shrimp",
        value: "chhyangra",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];
//गाई START
// गाई  विवरण
const cowInfo = {
    english: "Detail of cow rearing",
    nepali: "गाई सम्बन्धी विवरण",
};
const cowNumber = {
    nepali: "गाईको संख्या",
    english: "No of cow",
    value: "",
};
const cowMilkProduction = {
    nepali: "गाईको दैनिक दुध उत्पादन (लिटरमा )",
    english: "Daily production of cow milk (litre)",
    value: "",
};
const cowMilkProductionSale = {
    nepali: "दुध बिक्रि परिमाण कति होला ? (औसत बार्षिक )",
    english: "Daily production of cow milk (litre)",
    value: "",
};
const cowMilkSale = {
    nepali: "दुध बिक्रिबाट हुने बार्षिक आम्दानी (रु हजारमा )",
    english: "Income from sales of cow milk(in 1000)",
    value: "",
};

//गाई END

//भैसी START
// भैसी  विवरण
const buffalloInfo = {
    english: "Detail related buffalo",
    nepali: "भैसी सम्बन्धी विवरण",
};
const buffalloNumber = {
    nepali: "भैसीको  संख्या",
    english: "No of buffalo",
    value: "",
};
const buffalloMilkProduction = {
    nepali: "भैसीको दैनिक दुध उत्पादन (लिटरमा )",
    english: "Daily production of buffalo milk",
    value: "",
};
const buffalloMilkProductionSale = {
    nepali: "दुध बिक्रि परिमाण (बार्षिक )",
    english: "Daily production of buffalo milk",
    value: "",
};
const buffalloMilkSale = {
    nepali: "दुध बिक्रिबाट हुने बार्षिक आम्दानी (रु हजारमा )",
    english: "Yearly income from sales of buffalo milk",
    value: "",
};

//भैसी END

//खसी/बोका  START
// खसी/बोका   विवरण
const khasiInfo = {
    english: "Detail related to khasi",
    nepali: "खसी/बोका सम्बन्धी विवरण",
};

const khasiNumber = {
    nepali: "खसी/बोकाको संख्या",
    english: "	No of khasi/boka",
    value: "",
};
const khasiMeatProduction = {
    nepali: "खसी/बोका मासुबिक्रि (के जिमा )",
    english: "Sales of khasi /boka meat",
    value: "",
};
const khasiMeatIncome = {
    nepali: "मासु वा खसी/बोका बिक्रिबाट बार्षिक आम्दानी (रु हजारमा )",
    english: "Yearly income from the sales of khasi boka meat",
    value: "",
};

//खसी/बोक END

//बाख्राा  START
// बाख्रा विवरण
const goatInfo = {
    english: "Detail related to bakhra",
    nepali: "बाख्रा सम्बन्धी विवरण",
};

const goatNumber = {
    nepali: "बाख्राको संख्या",
    english: "No of goat",
    value: "",
};

const goatMeatIncome = {
    nepali: "बाख्राको मासु वा बाख्रा बिक्रीबाट बार्षिक आम्दानी",
    english: "Yearly income from the sales of goat meat",
    value: "",
};
//बाख्रा END

//राँगा/पाडा/पाडी  START
// राँगा/पाडा/पाडीा   विवरण
const rangaInfo = {
    english: "Detail related to ranga/paada/padi",
    nepali: "राँगा/पाडा/पाडी सम्बन्धी विवरण",
};
const rangaNumber = {
    nepali: "राँगा/पाडा/पाडीो संख्या",
    english: "No of ranga / pada/paadi",
    value: "",
};
const rangaMeatProduction = {
    nepali:
        "राँगा /पाडा /पाडी मासु / छाला वा पशुबाट बार्षिक बिक्रि (के .जीमा )",
    english: "Yearly income from the sales of ranga /pada/padi skin",
    value: "",
};
const rangaMeatIncome = {
    nepali: "राँगा/पाडा/पाडी मासु वा पशु बिक्रिबाट बार्षिक आम्दानी (रु हजार )",
    english: "yearly income from the sales of ranga /pada/padi meat",
    value: "",
};
//राँगा/पाडा/पाडी END
//सुँगुर/बंगुर START
// सुँगुर/बंगुर  विवरण
const pigInfo = {
    english: "Detail related to pigbunger",
    nepali: "सुँगुर/बंगुर सम्बन्धी विवरण",
};
const pigNumber = {
    nepali: "सुँगुर/बंगुरको संख्या",
    english: "No of pig and bungur",
    value: "",
};
const pigMeatProduction = {
    nepali: "सुँगुर /बंगुरको मासु बिक्रि बार्षिक (के .जीमा )",
    english: "Yearly sales of pig meat",
    value: "",
};
const pigMeatIncome = {
    nepali:
        "सुँगुर / बंगुरको मासु वा पशु बिक्रिबाट बार्षिक आम्दानी (रु हजारमा)",
    english: "Yearly income from the sales of pig meat",
    value: "",
};
//सुँगुर/बंगुर END

//भेडा START
//भेडा  विवरण
const sheepInfo = {
    english: "Detail related to sheep",
    nepali: "भेडा सम्बन्धी विवरण",
};
const sheepNumber = {
    nepali: "भेडाको संख्या",
    english: "No of sheep",
    value: "",
};
const sheepMeatProduction = {
    nepali: "भेडाको मासु बिक्रि दैनिक (के जीमा )",
    english: "Daily sales of sheep meat(kg)",
    value: "",
};
const sheepMeatIncome = {
    nepali: "भेडाको उन उत्पादन तथा मासु बिक्रि बार्षिकरुपमा (रु हजारमा )",
    english:
        "What is the yearly income from the sales of wool and meat of sheep?",
    value: "",
};
//भेडा END

//चौरी गाई START
//चौरी गाई  विवरण
const ChaurigaaiInfo = {
    english: "Detail related to Chaurigaai",
    nepali: "चौरी गाई सम्बन्धी विवरण",
};
const ChaurigaaiNumber = {
    nepali: "बँदेलको संख्या",
    english: "No of Chaurigaai",
    value: "",
};
const ChaurigaaiMeatProduction = {
    nepali: "बँदेलको मासु दैनिक रुपमा उत्पादन (के जीमा )",
    english: "Daily production of Chaurigaai meat(kg)",
    value: "",
};
const ChaurigaaiMeatIncome = {
    nepali: "मासु वा पशु बिक्रीबाट बार्षिक आम्दानी (रु हजारमा )",
    english: "Income from sales of meat and Chaurigaai",
    value: "",
};
//चौरी गाई END

//गोरु START
//गोरु  विवरण
const oxInfo = { english: "Ox related detail", nepali: "गोरु सम्बन्धी विवरण" };
const oxNumber = {
    nepali: "गोरुको संख्याा",
    english: "No of ox",
    value: "",
};
const oxProduction = {
    nepali: "गोरुको मासु वा छाला उत्पादन (के जिमा )",
    english: "Production of ox meat and skin",
    value: "",
};
const oxMeatIncome = {
    nepali: "पशुबिक्रिबाट बार्षिक आम्दानी (रु हजारमा )",
    english: "Yearly income from animal sales",
    value: "",
};
//गोरु END

//गधा START
//गधा  विवरण
const gadhaInfo = {
    english: "Donkey realted detail",
    nepali: "गधा सम्बन्धी विवरण",
};
const gadhaNumber = {
    nepali: "गधाको संख्याा",
    english: "No of donkey",
    value: "",
};
const gadhaProduction = {
    nepali: "गधाको मासु वा छाला उत्पादन (केजीमा )",
    english: "Production of donkey meat and skin(kg)",
    value: "",
};
const gadhaMeatIncome = {
    nepali: "गधाको मासु वा छालाबाट बार्षिक आम्दानी (रु हजारमा )",
    english: "Yearly income from the sales of meat and animal",
    value: "",
};
//गधा END

//घोडा START
//घोडा  विवरण
const horseInfo = {
    english: "Horse related horse",
    nepali: "घोडा सम्बन्धी विवरण",
};
const horseNumber = {
    nepali: "घोडाको संख्याा",
    english: "No of horse",
    value: "",
};
//घोडा END

//बाछा /बाछी START
//बाछा /बाछी  विवरण
const calfInfo = {
    english: "calf related detail",
    nepali: "बाछा /बाछी सम्बन्धी विवरण",
};
const calfNumber = {
    nepali: "बाछा /बाछीको संख्या",
    english: "No of calf",
    value: "",
};
const calfMilkProduction = {
    nepali: "दुध उत्पादन दैनिकरुपमा (लिटरमा )",
    english: "Daily production of milk(litre)",
    value: "",
};
const calfProduction = {
    nepali: "छुर्पी उत्पादन गर्छ भने बार्षिक रुपमा कति केजी ?",
    english: "If produce churpi then mention in kg?",
    value: "",
};
const calfMeatIncome = {
    nepali:
        "बाछा /बाछीको दुध वा छुर्पीबाट बार्षिक रुपमा कति आम्दानी हुन्छ? ( रु हजारमा )",
    english: "What is the yearly income from calf milk and churpi?",
    value: "",
};
//बाछा /बाछी END

//च्यांग्रा START
//च्यांग्रा  विवरण
const chyangraInfo = {
    english: "Chyangra related detail",
    nepali: "च्यांग्रा सम्बन्धी विवरण",
};
const chyangraNumber = {
    nepali: "च्यांग्राको संख्या",
    english: "No of chyangra",
    value: "",
};
const chyangraProduction = {
    nepali: "च्यांग्राको मासु वा छाला उत्पादन (के जीमा )",
    english: "Chyangra meat and skin produce",
    value: "",
};
const chyangraMeatIncome = {
    nepali:
        "च्यांग्राको मासु / छाला वा पशु बिक्रीबाट बार्षिक आम्दानी (रु हजारमा )",
    english: "Yearly income from the sales of meat and animal",
    value: "",
};
//च्यांग्रा END
//अन्य पशु START
//माथि भन्दा अन्य कति किसिमको पशु पाल्नु भएको छ ?
const anyAnimal = {
    english: "How many kinds of animal you rear?",
    nepali: "माथि भन्दा अन्य कति किसिमको पशु पाल्नु भएको छ ?",
    value: "",
};
const anyAnimalInfo = { english: "", nepali: "अन्य पशु पालेको विवरण" };
const anyAnimalTitle = {
    engish: "Mention if any",
    nepali: "अन्य भए उल्लेख गर्नु हाेस।",
    value: "",
};
const anyAnimalInfoTitle = {
    english: "Other detail",
    nepali: "अन्य विवरण",
};
const animalNumber = {
    nepali: "को संख्या",
    english: "No.",
    value: "",
};
const animalProduction = {
    nepali: "को छाला वा पशुबाट बार्षिक बिक्रि (के .जीमा )",
    english: "Yearly sales of skin and animal",
    value: "",
};
const animalMeatIncome = {
    nepali: "को मासु / छाला वा पशु बिक्रीबाट बार्षिक आम्दानी (रु हजारमा )",
    english: "Yearly income from sales of meat and animal",
    value: "",
};

//अन्य END
//पशु END

//पन्छी START
//if पन्छी xa vaney
//If xa vaney  कुन कुन पन्छी पाल्नु भएको छ ?
const birdTitle = {
    english: "Which animal you rear ?",
    nepali: "कुन कुन पन्छी पाल्नु भएको छ ?",
};
const birdDiffType = [
    {
        nepali: "कुखुरा",
        english: "Chicken",
        value: "chicken",
    },
    {
        nepali: "परेवा",
        english: "pegion",
        value: "pegion",
    },
    {
        nepali: "हाँस",
        english: "Duck",
        value: "duck",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];
//कुखुरा START
// कुखुरा  विवरण
const henInfo = {
    english: "Hen related detail",
    nepali: "कुखुरा सम्बन्धी विवरण",
};
const henNumber = {
    nepali:
        "कुखुराको संख्या (कुखुरा फारम भए फारममा कति संख्या कुखुरा छ उल्लेख गर्नुस )",
    english: "No of hen",
    value: "",
};
const henMeatProduction = {
    nepali: "कुखुराको मासुबिक्रि दैनिक रुपमा (के जीमा )",
    english: "Daily sales of hen meat(kg)",
    value: "",
};
const henEggSale = {
    nepali: "कुखुराको अन्डा बिक्रि दैनिक रुपमा (गोटा )",
    english: "Sales of hen egg and egg",
    value: "",
};
const henMeatIncome = {
    nepali:
        "कुखुराको मासु,अन्डा वा कुखुरा बिक्रीबाट बार्षिक रुपमा आम्दानी (रु हजारमा)",
    english: "Yearly income from sales of hen,egg and meat?",
    value: "",
};

//कुखुरा END

//हाँस START
// हाँस  विवरण
const duckInfo = {
    english: "Duck related detail",
    nepali: "हाँस सम्बन्धी विवरण",
};
const duckNumber = {
    nepali: "हाँसको संख्या",
    english: "No of duck",
    value: "",
};
const duckMeatProduction = {
    nepali: "हाइब्रिड कुखुरा मासु बिक्रि बार्षिक रुपमा",
    english: "Daily sales of duck meat(kg)",
    value: "",
};
const duckEggSale = {
    nepali: "हाँसको अन्डा बार्षिक उत्पादन",
    english: "Daily sales of egg ",
    value: "",
};
const duckMeatIncome = {
    nepali: "हाँसको मासु वा हाँस बिक्रि बाट बार्षिक आम्दानी (रु हजारमा )",
    english: "Yearly income from the sales of duck and meat",
    value: "",
};

//हाँस END

//अस्ट्रिच START
// अस्ट्रिच  विवरण
const ostrichInfo = {
    english: "Ostrich related detail",
    nepali: "अस्ट्रिच सम्बन्धी विवरण",
};
const ostrichNumber = {
    nepali: "अस्ट्रिचको संख्या",
    english: " No of ostrich ",
    value: "",
};
const ostrichMeatProduction = {
    nepali: "अस्ट्रिचको मासु उत्पादन वा बिक्रि दैनिक (के जीमा )",
    english: "Daily sales of ostrich meat ",
    value: "",
};
const ostrichMeatIncome = {
    nepali:
        "अस्ट्रिचको मासु वा अस्ट्रिच बिक्रीबाट बार्षिक रुपमा आम्दानी (रु हजारमा )",
    english: "Yearly income from sales of ostrich and meat",
    value: "",
};
//अस्ट्रिच END

//परेवा START
// परेवा  विवरण
const pegionInfo = {
    english: "Pegion related detail",
    nepali: "परेवा सम्बन्धी विवरण",
};
const pegion = {
    nepali: "परेवाको संख्या",
    english: "No of pegion",
    value: "",
};
const pegionMeatIncome = {
    nepali: "परेवा वा मासु बिक्रिबाट बार्षिक आम्दानी",
    english: "Yearly income from sales of pegion and meat",
    value: "",
};
//परेवा END

//अन्य पन्छी START
//माथि भन्दा अन्य कति किसिमको पन्छी पाल्नु भएको छ ?
const anyBird = {
    english: "Different kind of bird",
    nepali: "माथि भन्दा अन्य कति किसिमको पन्छी पाल्नु भएको छ ?",
    value: "",
};
const anyBirdInfo = {
    english: "Detail of other bird rearing",
    nepali: "अन्य पन्छी पालेको विवरण",
};
const anyBirdTitle = {
    engish: "Mention if any",
    nepali: "अन्य भए उल्लेख गर्नु हाेस।",
    value: "",
};
const anyBirdInfoTitle = {
    english: "Other detail",
    nepali: "अन्य विवरण",
};
const birdNumber = {
    nepali: "को संख्या",
    english: "No ",
    value: "",
};
const birdProduction = {
    nepali: "को अन्डा,मासु पन्छीबाट बार्षिक बिक्रि (के .जीमा )",
    english: "Yearly sales of egg,meat and bird ",
    value: "",
};
const birdMeatIncome = {
    nepali: "को अन्डा,मासु वा पन्छी बिक्रीबाट बार्षिक आम्दानी (रु हजारमा )",
    english: "Yearly income from the sale from egg , meat and bird (kg)",
    value: "",
};

//अन्य END
//पशुपन्छी END

//तपाइको परिवारको जम्मा जग्गा क्षेत्रफ़ल्
const totallandAreaTitle = {
    nepali: "तपाइको परिवारको जम्मा जग्गा क्षेत्रफ़ल् कति हो?",
    english: "How much land do u have?",
};
const totalLandArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//कृषि कार्यका लागी जग्गा प्रयोग गर्नु भएको छ?

const totalFamilyLandQn = {
    nepali: "आफ्नो परिवारको जम्मा जग्गा भोग चलनको आधारमा विवरण दिनुहोस्",
    english: "",
};

const totalFamilyLand = [
    {
        nepali: "एलानी (बिघा -कठा -धुर )",
        english: "",
        value: "elaani",
    },
    {
        nepali: "नम्बरी (विघा- कठा -धुर )",
        english: "",
        value: "nambari",
    },
];

const elaaniQn = { nepali: "ऐलानी (बिघा -कट्ठा -धुर )", english: "" };

const nambariQn = { nepali: "नम्बरी (बिघा -कट्ठा -धुर)", english: "" };

const landforAgri = {
    nepali: "कृषि कार्यका लागी जग्गा प्रयोग गर्नु भएको छ?",
    english: "Do you use land for agriculture purpose?",
};
const landforAgriYesNo = [
    {
        nepali: "छ",
        english: "yes",
        value: "land_used_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "land_used_no",
    },
];
//if xa vaney
const landforAgriYes = {
    nepali:
        "परिवारले कृषि कार्यका लागि जग्गा प्रयोग गरेको छ भने जग्गाको विवरण दिनुस ?",
    english: "",
};
//if xa vaney
const landforAgriList = [
    {
        nepali: "आफ्नो परिवारको नाममा रहेको जग्गा",
        english: "Land in your name",
        value: "own_land",
    },
    {
        nepali: "आफ्नो परिवार बाहेक अरुको नाममा रहेको जग्गा",
        english: "Land belongs to other",
        value: "other_land",
    },
];
//if xa vaney
const irrigationForLand = {
    english: "Which  way do you use for agriculture? ",
    nepali: "कृषि कार्यका लागि सिंचाईको माध्यम के हो ?",
};
const irrigationForLandList = [
    {
        nepali: "थोपा सिंचाई",
        english: "thopa sichai",
        value: "thopaa_sinchaai",
    },
    {
        nepali: "कुलो",
        english: "Kulo",
        value: "kulo",
    },
    {
        nepali: "नहर",
        english: "Nahar",
        value: "nahar",
    },
    {
        nepali: "डिप बोरिङ्ग",
        english: "Dipboring",
        value: "dipboring",
    },
    {
        nepali: "स्यालो ट्युबवेल",
        english: "Tubewell",
        value: "sallow_tyubwell",
    },
    {
        nepali: "पैनी",
        english: "Paini",
        value: "paini",
    },
    {
        nepali: "पोखरी / रिजरभायर",
        english: "Pokhari",
        value: "pond_reservoir",
    },
    {
        nepali: "स्प्रिंङ कल",
        english: "spring call",
        value: "spring_call",
    },
    {
        nepali: "आकासे पानी",
        english: "Rain water",
        value: "aakase_paani",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

const seedNeedQuestion = {
    nepali: "कृषी वालीका लागि विउ विजन प्राप्तिको श्रोत के छ ?",
    english: "",
};

const seedNeed = [
    {
        nepali: "सरकारी निकाय",
        english: "",
        value: "government_off",
    },
    {
        nepali: "एग्रोभेट (स्थानीय )",
        english: "",
        value: "agrovet",
    },
    {
        nepali: "स्थानीय श्रोत",
        english: "",
        value: "local",
    },
    {
        nepali: "अन्य ब्यापारी",
        english: "",
        value: "others_busines",
    },
];
//आफ्नो परिवारको नाममा रहेको जग्गा

const ownLand = {
    english: "Total land area belongs to own?",
    nepali: "आफ्नो परिवारको नाममा रहेको जग्गा कति छ?",
};
const ownLandArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//ifआफ्नो बाहेक अरुको जग्गा पनि कमाउनु भएको कति छ?
const otherLand = {
    english: "Do you use other's land for agriculture?",
    nepali: "आफ्नो बाहेक अरुको जग्गा पनि कमाउनु भएको कति छ?",
};
const otherLandArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
const otherLandAreaBighaKattha = [
    { english: "Bigha", nepali: "बिघा" },
    { english: "Kattha", nepali: "कठा" },
    { english: "Dhur", nepali: "धुर" },
];
//माछा मौरी र रेशम पालन START
const beefishsilkQuestion = {
    nepali: " तपाइको घरमा माछा मौरी र रेशम पालन गरिएको छ भने विवरण दिनुहोला",
    english: "If you  rear fish ,bee and resham in your home then mention?",
};

const beefishsilkYesNo = [
    {
        nepali: "छ",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];
//if xa vanye
const beefishsilkYes = {
    nepali: " छ भने विवरण दिनुहोला :",
    english: "If yes plz mention",
};
const beefishsilkType = [
    {
        nepali: "माछा पालन",
        english: "Fish farming",
        value: "fish",
    },
    {
        nepali: "मौरी पालन",
        english: "Bee farming",
        value: "bee",
    },
    {
        nepali: "रेशम पालन",
        english: "Resham farming",
        value: "resham",
    },
];
//माछा पालन START
const fishTitle = {
    nepali: "माछापालनको विवरण ",
    english: "Detail of fish farming",
};
const totalPond = {
    nepali: "पोखरी  संख्या",
    engish: "No of pond",
    value: "",
};
const pondArea = {
    nepali: "पोखरी क्षेत्रफ़ल",
    engish: "Area of pond",
    value: "",
};
const fishProduction = {
    nepali: "बार्षिक माछा उत्पादन (के.जी)",
    engish: "Yearly fish produciton (kg)",
    value: "",
};
//माछा पालन END

//मौरीपालन START
const beeTitle = {
    nepali: "मौरीपालनको विवरण ",
    english: "Bee farming detail",
};
const totalGhaar = {
    nepali: "घार संख्या",
    engish: "No of ghaar",
    value: "",
};
const beeProduction = {
    nepali: "वार्षिक मह उत्पादन(के.जी)",
    engish: "Yearly production of honey(kg)",
    value: "",
};
//मौरीपालन END

//रेशमपालन START
const reshamTitle = {
    nepali: "रेशमपालनको विवरण",
    english: "Resham farming detail",
};
const reshamGhaar = {
    nepali: "कोयो संख्या",
    engish: "No of koyo",
    value: "",
};
const reshamProduction = {
    nepali: "उत्पादन (के.जि)",
    engish: "Production(kg)",
    value: "",
};
const totalBeefidhsilkIncome = {
    nepali: "माछा, मौरी र रेशम पालन बिक्रीबाट वार्षिक आम्दानी (रु)",
    english: "Yearly income from the sales of fish bee and resham(Rs)",
    value: "",
};
//रेशमपालन END
//माछा मौरी र रेशम पालन END

const businessListQuestion = {
    english: "Do your family have traditional business?",
    nepali: "तपाइको परिवारले कुनै घरेलु व्यवसाय कार्य गर्नु भएको छ ?",
};

const herbListQuestion = {
    english:
        "Do your family enagaged in sales and collection of medicinal herbs?",
    nepali:
        "तपाईको परिवारले कुनै जडिबुटी व्यापार तथा जडिबुटी सङ्कलनको काम गर्नु हुन्छ ?",
};

const businessLists = [
    {
        nepali: "भाडाकुडाका सामान बनाउने बेच्ने",
        english: "Karuwa",
        value: "karuwa",
    },
    {
        nepali: "खोलामा जालबाट माछा मार्ने तथा बिक्रि वितरण गर्ने",
        english: "Fishing",
        value: "river_fish_col",
    },
    {
        nepali: "होमस्टे ब्यबसाय संचालन",
        english: "Home Stay",
        value: "home_stay",
    },
    {
        nepali: "हातेकागज",
        english: "Hand paper",
        value: "hand_paper",
    },
    {
        nepali: "खुकुरी",
        english: "Khuhuri",
        value: "khukuri",
    },
    {
        nepali: "राडीपाखी",
        english: "Raadi pakhi",
        value: "raadi_pakhi",
    },
    {
        nepali: "कुराउनी",
        english: "Kuarauni",
        value: "kurauni",
    },
    {
        nepali: "फर्निचर",
        english: "Furniture",
        value: "furniture",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

const herbLists = [
    {
        nepali: "अदामचल",
        english: "Adaamchal",
        value: "adaamchal",
    },
    {
        nepali: "आखनबेद",
        english: "Ankhanbed",
        value: "ankhanbed",
    },
    {
        nepali: "कुट्की",
        english: "Kutki",
        value: "kutki",
    },
    {
        nepali: "ठुलो ओखती",
        english: "Thulo okhati",
        value: "thulo_okhati",
    },
    {
        nepali: "हर्रो",
        english: "Harro",
        value: "harro",
    },
    {
        nepali: "अमला",
        english: "Aamala",
        value: "aamla",
    },
    {
        nepali: "टिमुर",
        english: "Timur",
        value: "timur",
    },
    {
        nepali: "चिरैतो",
        english: "Chiraito",
        value: "chirauto",
    },
    {
        nepali: "चानो",
        english: "Chano",
        value: "chano",
    },
    {
        nepali: "बर्रो",
        english: "Barro",
        value: "barro",
    },
    {
        nepali: "बोझो",
        english: "Bojho",
        value: "bojho",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

const businesssQn = {
    nepali: "तपाइको परिवारले कुनै घरेलु व्यवसाय कार्य गर्नु भएको छ ?",
    english: "Do your family engaged in any traditional business?",
};

const herbQn = {
    nepali:
        "तपाईको परिवारले कुनै जडिबुटी व्यापार तथा जडिबुटी सङ्कलनको काम गर्नु हुन्छ ?",
    english:
        "Do your family enagaged in sales and collection of medicinal herbs?",
};
const businessRadio = [
    {
        nepali: "छ",
        english: "yes",
        value: "home_busi_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "home_busi_no",
    },
];

const herbRadio = [
    {
        nepali: "छ",
        english: "yes",
        value: "herbs_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "herbs_no",
    },
];

const birthCertificateQuestion = {
    nepali: "पाँच (५) बर्ष भन्दा मुनिको बालबालिकाको जन्म दर्ता गर्नु भएको छ ?",
    english: "Do you registar birth certificate of child below the age of 5?",
};
const birthCertificate = [
    {
        nepali: "छ",
        english: "yes",
        value: "birth_cert_yes",
    },
    { nepali: "छैन", english: "no", value: "birth_cert_no" },
];

const contraceptiveUseQuestion = {
    nepali:
        "परिवारमा विवाहित सदस्यहरुले परिवार निवोजनको साधन प्रयोग गर्नु हुन्छ कि हुन्न ?",
    english: "",
};
const contraceptiveUse = [
    {
        nepali: "छ",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];

const deliveryFromDoctorQuestion = {
    nepali: "गर्भवती आमाको दक्ष स्वास्थ्य ाकर्मी बाट सुत्केरी गराउनु भएको छ ?",
    english: "Do prenant women delivary baby from qualified healthworker?",
};
const deliveryFromDoctor = [
    {
        nepali: "छ",
        english: "yes",
        value: "delivery_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "delivery_no",
    },
];

const childDeathQuestion = {
    nepali:
        "विगत १ वर्ष भित्र हैजा, झाडापखालाबाट मृतु हुनु हुने बालबालिका छन ?",
    english: "",
};

const childDeath = [
    {
        nepali: "छ",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];

const childDeathCount = {
    nepali: "छ भने कति जना",
    english: "If yes how many?",
};

const pregnanctCheckQuestion = {
    nepali: "गर्भवती महिलाको सम्पूर्ण जाँच गराउनु भएको छ कि छैन ?",
    english: "Regular check up for pregnent women? ",
};

const pregnanctCheck = [
    {
        nepali: "छ",
        english: "yes",
        value: "check_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "check_no",
    },
];

const childNutritionQuestion = {
    nepali: "बालबालिकामा पोषण सम्बन्धी समस्या छ कि छैन ?",
    english: "Do there any problem related to child malnutrition?  ",
};

const childNutrition = [
    {
        nepali: "छ",
        english: "yes",
        value: "mal_nutri_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "mal_nutri_no",
    },
];
const childLabourQuestion = {
    nepali: "तपाइको परिवारको कुनै १६ वर्ष मुनिका वालवालिका श्रम गर्न गएको छ ?",
    english: "Do your any family member beleow age of 16 are working?",
};
const childLaborQuestion = {
    nepali: "तपाईको घरमा १६ वर्ष मुनिका कामदार छन् ?",
    english: "Do you have worker below the age of 16 in your home?",
};
const isStillChildMarriage = {
    nepali: "के यस समुदायमा वालविवाहको चलन अझै छ ?",
    english: "",
};
const reasonForChildMarriage = {
    nepali: "समुदायमा बालविवाहको चलन चलिरहनुको कारण",
    english: "",
};
const controlChildMarriage = {
    nepali: "समुदायमा बालविवाहको चलनलाई नियन्त्रण गर्न",
    english: "",
};
const childLabour = [
    {
        nepali: "छ",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];

const earlyMarriageQuestion = {
    nepali: "अठार (१८) वर्ष मुनिका वालवालिकाको बिवाह भएको छ ?",
    english: "Marriage below the age of 18?",
};
const earlyMarriage = [
    {
        nepali: "छ",
        english: "yes",
        value: "e_marry_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "e_marry_no",
    },
];

const pregnantTetanusQuestion = {
    nepali: "गर्भवती महिलाले टिटानस विरुद्धको २ वोटा खोप लगाउनु भएको छ?",
    english: "Do pregnant women get 2  vaccine  against titanus? ",
};
const pregnantTetanus = [
    {
        nepali: "छ",
        english: "yes",
        value: "tetanus_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "tetanus_no",
    },
];

const illTreamentQuestion = {
    nepali: "बिरामी हुदा स्वास्थ्य  उपचार सेवाको लागि कहाँ जानु हुन्छ?",
    english: "Where do you go for treatment?",
};

const illTreament = [
    {
        nepali: "धामी झाक्री",
        english: "Dhami jhakri",
        value: "dhaami_jhakri",
    },
    {
        nepali: "औषधि पसल   / क्लिनिक",
        english: "Pharmecy",
        value: "medical",
    },
    {
        nepali: "प्राकृतिक चिकित्सा",
        english: "prakritik",
        value: "prakritik",
    },
    {
        nepali: "स्वास्थ्य  चौकी",
        english: "Health post",
        value: "health_post",
    },
    {
        nepali: "निजि अस्पताल",
        english: "District private hospital",
        value: "private_hospit",
    },
    {
        nepali: "आयुर्वेदिक औषधालय",
        english: "aayurbedik",
        value: "aayurbedik",
    },
    {
        nepali: "स्थानीय स्वास्थ्यकर्मी",
        english: "Local health worker",
        value: "healthworker",
    },
    {
        nepali: "सरकारी अस्पताल",
        english: "Local health worker",
        value: "district_govt_",
    },
    {
        nepali: "अन्य",
        english: "other",
        value: "other_ill",
    },
];

//जिल्ला बाहिर सरकारी अस्पताल click pacchi aaune array
const govHospitalNameQuestion = {
    english: "District government hospital ",
    nepali: "जिल्ला बाहिर सरकारी अस्पताल",
};

const govHospitalName = [
    {
        nepali: "धरान",
        english: "Dharan",
        value: "dharan",
    },
    {
        nepali: "बिराटनगर",
        english: "Biratnagar",
        value: "biratnagar",
    },
    {
        nepali: "काठमाडौँ",
        english: "Kathmandu",
        value: "kathmandu",
    },
    {
        nepali: "गाईघाट",
        english: "Gaighat",
        value: "gaighat",
    },
    {
        nepali: "लाहान",
        english: "Lahan",
        value: "lahan",
    },
    {
        nepali: "जनकपुर",
        english: "Janakpur",
        value: "janakpur",
    },
    {
        nepali: "भरतपुर",
        english: "Bharatpur",
        value: "bharatpur",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

const isChildMarriageExistsQn = {
    nepali: "के यस समुदायमा वालविवाहको चलन अझै छ ?",
    english: "",
};

const isChildMarriageExists = [
    {
        nepali: "छ",
        english: "",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "",
        value: "no",
    },
    {
        nepali: "थाहा छैन",
        english: "",
        value: "unknown",
    },
];

// if isChildMarriageExist "yes"
const childMarriageExistsQn = {
    nepali:
        "तपाइको समुदायमा बालविवाहको चलन चलिरहनुको कारण के के हो जस्तो लाग्छ ?",
    english: "",
};

const childMarriageExists = [
    {
        nepali: "पहिला देखि चलेको परम्परा भएर",
        english: "",
        value: "tradition",
    },
    {
        nepali: "गरीबि",
        english: "",
        value: "poverty",
    },
    {
        nepali: "उमेर बडदै जादा दाइजो पनि बढ्ने भएर",
        english: "",
        value: "increment",
    },
    {
        nepali: "आफै प्रेम सम्बन्ध भएर",
        english: "",
        value: "love_rel",
    },
    {
        nepali: "साथीको लायालायामा लागेर /देखासिखी गरेर",
        english: "",
        value: "friend_circle",
    },
    {
        nepali: "राम्रो घरको केटा /केटी सजिलै नपाउने भएर",
        english: "",
        value: "no_easy_access_of_good_family",
    },
    {
        nepali: "अपांगताको कारण",
        english: "",
        value: "disable",
    },
    {
        nepali: "ज्ञान नभएर वा अशिक्षित भएकाले",
        english: "",
        value: "uneducate",
    },
    {
        nepali: "थाहा छैन",
        english: "",
        value: "unknown",
    },
    {
        nepali: "अन्य (खुलाउनु होस )",
        english: "",
        value: "others",
    },
];

const controlChildMarriageQn = {
    nepali:
        "तपाइको समुदायमा बालविवाहको चलनलाई नियन्त्रण गर्नको लागि के के गर्नु पर्छ ?",
    english: "",
};

const controlChildMarriageList = [
    {
        nepali: "वालवालिकाहरुमा जनचेतना",
        english: "",
        value: "child_awarness",
    },
    {
        nepali: "आमा बुवा /अभिभावकमा जनचेतना",
        english: "",
        value: "parent_awarness",
    },
    {
        nepali: "कानुन लागु गरेर",
        english: "",
        value: "law",
    },
    {
        nepali: "अनिवार्य शिक्षा",
        english: "",
        value: "compulsary_education",
    },
    {
        nepali: "वाल क्लबहरु सक्रिय र परिचालित",
        english: "",
        value: "regulation_of_active_child_club",
    },
    {
        nepali: "सुरक्षा समितिहरु सक्रिय र परिचालित",
        english: "",
        value: "active_regulation",
    },
    {
        nepali: "आम्दानिमुलक गतिविधि बढाउने",
        english: "",
        value: "diverse_income",
    },
    {
        nepali: "रोजगारीको अवसर",
        english: "",
        value: "job_oppurtunities",
    },
    {
        nepali: "ब्यब्सायिक तालिमहरुको अवसरहरु उपलब्ध",
        english: "",
        value: "trainning_business",
    },
    {
        nepali: "उच्च शिक्षाको अवसर",
        english: "",
        value: "higher_education",
    },
    {
        nepali: "प्रभाबकारी सडक नाटक गाउँ गाउँमा देखाउने",
        english: "",
        value: "road_drama",
    },
    {
        nepali: "थाहा छैन",
        english: "",
        value: "unknown",
    },
    {
        nepali: "अन्य (खुलाउने)",
        english: "",
        value: "others",
    },
];

const isRevolutionCaseQn = {
    nepali: "तपाइको परिवारमा द्वन्द वा जनआन्दोलन प्रवाभित सदस्य हुनुहुन्छ ?",
    english: "",
};

const isRevolutionCase = [
    {
        nepali: "हुनुहुन्छ",
        english: "",
        value: "yes",
    },
    {
        nepali: "हुनुहुन्न",
        english: "",
        value: "no",
    },
];

const protestTypeQn = {
    english: "",
    nepali: "यदि हुनुहुन्छ भने कुन किसिमको प्रभावित",
};

const protestType = [
    {
        nepali: "जनआन्दोलन",
        english: "",
        value: "janaandolan",
    },
    {
        nepali: "ससत्र जनयुद्द",
        english: "",
        value: "combaties",
    },
    {
        nepali: "दुवै",
        english: "",
        value: "both",
    },
];

const whichSideForProtestQn = {
    nepali: "ससत्र जनयुद्द हो भने कुन पक्षबाट",
    english: "",
};

const whichSideForProtest = [
    {
        nepali: "राज्य",
        english: "",
        value: "government",
    },
    {
        nepali: "तत्कालिन बिद्रोही पक्ष",
        english: "",
        value: "dissident",
    },
    {
        nepali: "दुवै",
        english: "",
        value: "both",
    },
];

const effectFromProtestQn = {
    nepali: "कस्तो किसिमको पिडित",
    english: "",
};

const effectFromProtest = [
    {
        nepali: "मृत्यु",
        english: "",
        value: "death",
    },
    {
        nepali: "बेपत्ता",
        english: "",
        value: "bepatta",
    },
    {
        nepali: "घाइते",
        english: "",
        value: "injured",
    },
];

const effectFromCoronaQn = {
    nepali: "कोरोना महामारीले तपाइको परिवारमा पारेको प्रमुख असर ?",
    english: "",
};

const effectFromCorona = [
    {
        nepali: "कुनै पनि असर नगरेको",
        english: "NO ANY EFFECT",
        value: "NOANY_EFFECT",
    },
    {
        nepali: "परिवारको सदश्यको रोजगार गुमेको",
        english: "Unemployment",
        value: "unemployment",
    },
    {
        nepali: "परिवार सदश्यमा मानसिक असर पारेको",
        english: "Psychological",
        value: "psychological",
    },
    {
        nepali: "आर्थिक अभाबले गर्दा परिवारमा कलह /झैँझगडा बढेको",
        english: "Jhaijhagada",
        value: "jhaijhagadaa",
    },
    {
        nepali: "ब्यापार - व्यवासय बन्द गर्नु परेको",
        english: "Business",
        value: "business",
    },
    {
        nepali: "कृषि उत्पादन बिक्रि नभएको",
        english: "Agriculture",
        value: "agriculture",
    },
    {
        nepali: "घर परिवार सदस्यमा खानलाई समस्या",
        english: "khana problem",
        value: "khana_problem",
    },
    {
        nepali: "अन्य (खुलाउनु होस् )",
        english: "others",
        value: "others",
    },
];
//ends

//DEATH INFO
//४.१. तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ कि छैन?
const deathQuestion = {
    english: "Do any child died within one year?",
    nepali: " तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ कि छैन?",
};
const anyDeath = [
    {
        nepali: "छ",
        english: "Yes",
        value: "death",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "no_death",
    },
];

//IF तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ bhaney?
const deathInYear = {
    english: "If yes how many died?",
    nepali: "छ भने कति जना को मृत्यु भएको छ ?",
    value: "",
};

//IF तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ bhaney tesko name tala lekhney
const deathName = {
    nepali: "मृत्यु हुनेको नाम",
    english: "Name of died person",
    value: "dead_person_name",
};

//IF तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ bhaney tesko लिंग ko bibaran tala lekhney
const deathInYeargenderQuestion = { english: "Gender", nepali: "लिंग" };
const deathGender = [
    {
        nepali: "पुरुष",
        english: "Male",
        value: "dearh_male",
    },
    {
        nepali: "महिला",
        english: "Female",
        value: "death_female",
    },
    {
        nepali: "अन्य",
        english: "other",
        value: "death_thrid",
    },
];
//IF तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ bhaney मृत्यु हुनेको उमेर
const deathAge = {
    nepali: "मृत्यु हुनेको उमेर",
    english: "Age of death ",
    value: "death_age",
};

//IF तपाईको परिवारमा गत १ बर्ष भित्र कसैको मृत्यु भएको छ bhaney मृत्यु को कारण
const deathInYearReasonQuestion = {
    english: "Reason of death",
    nepali: "मृत्यु को कारण",
};
const deathReason = [
    {
        nepali: "माहामारी",
        english: "Epidimics",
        value: "epidimics",
    },
    {
        nepali: "रोग लागेर",
        english: "Transferable diseases",
        value: "tranferable",
    },
    {
        nepali: "कालगति",
        english: "Aged",
        value: "aged",
    },
    {
        nepali: "हत्या",
        english: "Murder",
        value: "murder",
    },
    {
        nepali: "यातायात दुर्घटना",
        english: "Accident",
        value: "accident",
    },
    {
        nepali: "आत्महत्या",
        english: "Suicide",
        value: "suicide",
    },
    {
        nepali: "प्राकृतिक प्रकोप",
        english: "natural disaster",
        value: "natural_disaster",
    },
    {
        nepali: "प्रजनन  तथा प्रसुती जटिलता",
        english: "complication",
        value: "complication",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other_death",
    },
];

const deathCertificateQn = {
    nepali: "मृत्यु दर्ता गर्नु भएको छ ?",
    english: "",
};
const deathCertificate = [
    {
        nepali: "छ",
        english: "yes",
        value: "death_cert_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "death_cert_no",
    },
];

// INSURANCE
const insuranceQuestion = {
    english: "Do you have insurance?",
    nepali: "तपाइको विमा गरेको छ ?",
};
const insuranceYesNo = [
    {
        nepali: "छ",
        english: "yes",
        value: "छ",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "छैन",
    },
];
const insuranceListQuestion = {
    english: "Detail about insurance",
    nepali: "छ भने कुन -कुन विमा गर्नु भाको छ ?",
};
const insuranceLists = [
    {
        nepali: "जीवन विमा",
        english: "Life Insurance",
        value: "life_insurance",
    },
    {
        nepali: "स्वास्थ्य विमा",
        english: "Health Insurance",
        value: "health_insurance",
    },
];
const lifeInsurance = {
    nepali: "जीवन विमा (संख्यामा)",
    english: "Number of Life Insurance",
    value: "",
};

const healthInsurance = {
    nepali: "स्वास्थ विमा (संख्यामा)",
    english: "Number of Health Insurance",
    value: "",
};

const nonlifeInsurance = {
    nepali: "नीर जीवन विमा (संख्यामा)",
    english: "Number of Non Life Insurance",
    value: "",
};
const animalInsurance = {
    nepali: "पशु पन्छी विमा (संख्यामा)",
    english: "Number of Animal Insurance",
    value: "",
};

const insuranceOther = {
    nepali: "अन्य विमा (संख्यामा)",
    english: "Number of Other Insurance",
    value: "",
};
//६.परिवारले प्रयोग गर्ने बारेमा
// ६.१. परिवारको खानेपानीको मुख्य श्रोत के हो ?
const drinkingwaterQuestion = {
    english: "What is the main sources of drinking water?",
    nepali: "परिवारको खानेपानीको मुख्य श्रोत के हो ?",
};
const drinkingwaterLists = [
    {
        nepali: "पाइपधारा घर कम्पाउण्डमा",
        english: "Water pipe in home",
        value: "tap_home",
    },
    {
        nepali: "पाइपधारा घर कम्पाउण्ड बाहिर",
        english: "Public pipe",
        value: "tap_outside",
    },
    {
        nepali: "ट्युबवेल / हेन्डपम्पा",
        english: "Tubewell / handpump",
        value: "handpump",
    },
    {
        nepali: "ढाकिएको इनार /कुवा",
        english: "Well",
        value: "well_cover",
    },
    {
        nepali: "नढाकिएको इनार /कुवा",
        english: "Well uncover",
        value: "well_uncover",
    },
    {
        nepali: "मूलको पानी",
        english: "Mulko Pani",
        value: "main_water",
    },
    {
        nepali: "नदी खोला",
        english: "River water",
        value: "river",
    },
    {
        nepali: "डिप वोरिङ्ग",
        english: "Dip boring",
        value: "dip_boring",
    },
    {
        nepali: "जार /बोतल",
        english: "jar_bottal",
        value: "jar_bottal",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];
//६.२. तपाइको घरको खानेपानीको उपलब्ध स्थान कहाँ छ?
const drinkingwaterplaceQuestion = {
    english: "Where do you to get drinking water?",
    nepali: "घरको खानेपानी लिन जान ,भर्न र आउन कति समय लाग्छ?",
};
const drinkingwaterplaceLists = [
    {
        nepali: "घर आगनमै छ",
        english: "Home courtyard",
        value: "in_home",
    },
    {
        nepali: "१० मिनेट सम्म पैदल",
        english: "10 Min walk ",
        value: "10_min",
    },
    {
        nepali: "आधा घण्टा सम्म दुरी",
        english: "Half hour walk",
        value: "30_min",
    },
    {
        nepali: "१ घण्टा वा सो भन्दा माथि",
        english: "1 hour or more than",
        value: "1hour_more",
    },
];
//COOKINGRESOURCES
//७. तपाईको परिवारमा खाना पकाउन कुन प्रमुख इन्धन/चुलो प्रयोग गर्नु हुन्छ ?
const cookingresourceQuestion = {
    english: "Which source of gas you use for cooking ?",
    nepali:
        "तपाईको परिवारमा खाना पकाउन कुन प्रमुख इन्धन/चुलो प्रयोग गर्नु हुन्छ ?",
};
const cookingresourceLists = [
    {
        nepali: "दाउरा/ काठ",
        english: "Wood",
        value: "wood_general",
    },
    {
        nepali: "मट्टीतेल",
        english: "Kerosene",
        value: "kerosene",
    },
    {
        nepali: "एल पि  ग्यास",
        english: "LGP Gas",
        value: "lpg_gas",
    },
    {
        nepali: "वायोग्यास",
        english: "Bio gas",
        value: "bio_gas",
    },
    {
        nepali: "विधुत",
        english: "Electricity",
        value: "electricity",
    },
    {
        nepali: "सौर्य उर्जा",
        english: "Solar energy",
        value: "solar",
    },
    {
        nepali: "गुइठा/गोरहा",
        english: "Dung",
        value: "guithaa",
    },
    {
        nepali: "अन्य",
        english: "Others",
        value: "others",
    },
];
//LIGHTRESOURCES
//८. परिवारले प्रयोग गर्ने वत्तिको मुख्य स्रोत के हो?
const lightresourceQuestion = {
    english: "What is the main source of electricity?",
    nepali: "परिवारले प्रयोग गर्ने वत्तिको मुख्य स्रोत के हो?",
};
const lightresourceLists = [
    {
        nepali: "विधुत /बिजुली",
        english: "Electric line",
        value: "electric_national",
    },
    {
        nepali: "मट्टीतेल",
        english: "Kerosene",
        value: "kerosene",
    },
    {
        nepali: "सोलार",
        english: "Solar",
        value: "solar",
    },
    {
        nepali: "वायो ग्यास /गोबर ग्यास",
        english: "Bio Gas",
        value: "bio_gas",
    },
    {
        nepali: "अन्य",
        english: "Other Source of light",
        value: "other_light",
    },
];

const isElectricMeterQn = {
    nepali: "तपाईको घरमा बिधुत मिटर जडान गरिएको छ कि छैन ?",
    english: "",
};

const isElectricMeter = [
    {
        nepali: "छ",
        english: "",
        value: "meter_yes",
    },
    {
        nepali: "छैन",
        english: "",
        value: "meter_no",
    },
];
//TOILET TYPE
//९.१. परिवारले प्रयोग गर्ने शौचालयको किसिम कस्तो छ?
const toiletYesNo = [
    {
        nepali: "छ",
        english: "Yes",
        value: "yes",
    },
    {
        nepali: "छैन (शौचालय नभएको )",
        english: "No Toilet",
        value: "no",
    },
    {
        nepali: "छ तर प्रयोग गरेको छैन",
        english: "No USE",
        value: "yes_but_not_us",
    },
];
const toiletNoQuestion = {
    english: "",
    nepali: "शौचालयको किसिम कस्तो छ?",
};

const noToiletLists = [
    {
        nepali: "जग्गा नभएर",
        english: "No land",
        value: "no_land",
    },
    {
        nepali: "बनाउने पैसा नभएर",
        english: "No money",
        value: "no_money",
    },
    {
        nepali: "छिमेकीको प्रयोग गरेर",
        english: "Neighbour use",
        value: "neighbour_use",
    },
    {
        nepali: "नजिकै खुला ठाँउ वा जंगल उपलब्ध भएर",
        english: "Open Space",
        value: "open_space",
    },
    {
        nepali: "अन्य",
        english: "other",
        value: "others_toilet",
    },
];

const toiletYesNoUseQn = { nepali: "शौचालय किन संचालन नगरेको ?", english: "" };

const toiletYesNoUse = [
    {
        nepali: "प्रयोग गर्न नमिल्ने भएर",
        english: "",
        value: "not_usable",
    },
    {
        nepali: "पुरानो तर अर्को बनाउन नसकेर",
        english: "",
        value: "old_cannot_afford",
    },
    {
        nepali: "अरुको प्रयोग गरिन्छ",
        english: "",
        value: "using_others",
    },
    {
        nepali: "ढोका नभएर",
        english: "",
        value: "no_door",
    },
    {
        nepali: "अन्य",
        english: "",
        value: "other",
    },
];

const toilettypeQuestion = {
    english: "Which types of toilet you use?",
    nepali: "परिवारले प्रयोग गर्ने शौचालयको किसिम कस्तो छ?",
};
const toiletresourceLists = [
    {
        nepali: "फ्लस भएको सार्बजनिक ढलमा मिसिने",
        english: "Public sewer",
        value: "public_sewer",
    },
    {
        nepali: "फ्लस भएको सेफ्टी ट्यांकीमा मिसिने",
        english: "Safety tank",
        value: "safety_tank",
    },
    {
        nepali: "कच्ची चर्पी",
        english: "Kachhi",
        value: "kachhi",
    },
    {
        nepali: "साधारण चर्पी",
        english: "normal",
        value: "normal_toilet",
    },
    {
        nepali: "सामुदायिक/ सार्वजनिक सार्वजनिक",
        english: "saamudayik",
        value: "saamudayik",
    },
    {
        nepali: "शौचालय नभएको",
        english: "no toilet",
        value: "no_toilet",
    },
];
// WASTE MANAGEMENT
//९.२. तपाईको परिवारले घरबाट निस्कने ठोस फोहरमहिला कहाँ फाल्नु हुन्छ ?
const wasteQuestion = {
    english: "Where do you dispose waste mayterial?",
    nepali: "तपाईको परिवारले घरबाट निस्कने ठोस फोहरमहिला कहाँ फाल्नु हुन्छ ?",
};
const wastemanagementLists = [
    {
        nepali: "फोहर व्यवस्थापनका लागि केहि पनि गरेको छैन",
        english: "no waste management",
        value: "no_waste_management",
    },
    {
        nepali: "खाल्डोमा फ्याल्ने  गरेको",
        english: "Disposed unmanaged Ground",
        value: "gound_management",
    },
    {
        nepali: "वर्गीकरण नगरी डस्टविनमा राख्ने गरेको",
        english: "dastbin",
        value: "dastbin",
    },
    {
        nepali: "जलाउने गरेको",
        english: "Burned",
        value: "burning",
    },
    {
        nepali: "नगरपालिकाको गाडीले उठाउने गरेको",
        english: "nagarpalika bus",
        value: "nagarpalika_bus",
    },
    {
        nepali: "वर्गीकरण गरी  बेग्ला बेग्लै  डस्टविनमा राख्ने गरेको",
        english: "Managed on dustbin",
        value: "Dustbin_use",
    },
];

//ROAD CONDITION
//९.३. घर सम्म पुग्ने सडक/बाटोको अवास्था कस्तो छ?
const roadQuestion = {
    english: "What is the condition of road?",
    nepali: "घर सम्म पुग्ने सडक/बाटोको अवस्था कस्तो छ?",
};
const roadLists = [
    {
        nepali: "पक्कि/कालोपत्रो सडक",
        english: "black pitched road",
        value: "blacktop",
    },
    {
        nepali: "ग्रावेल सडक",
        english: "Gravel road",
        value: "gravel",
    },
    {
        nepali: "कच्ची सडक",
        english: "Dusty road",
        value: "kaccchi",
    },
    {
        nepali: "गोरेटो बाटो जोडिएको",
        english: "Goreto",
        value: "goreto",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];
//BANK DETAILS
//९.४. परिवारको सदस्यको बैंक तथा वित्तीय सस्थामा खाता छ?

const bankaccountQuestion = {
    english: "Do any member of your family have bank account?",
    nepali: " परिवारको सदस्यको बैंक तथा वित्तीय सस्थामा खाता छ?",
};
const bankaccountYesNo = [
    {
        nepali: "छ",
        english: "Yes",
        value: "account_yes",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "account_no",
    },
];

const numberOfBankAccount = {
    english: "If yes how many do you have?",
    nepali: "छ भने कति जना को खाता छ ?",
    value: "",
};

const bankAccountMale = {
    english: "Female",
    nepali: "महिला",
};

const bankAccountFemale = {
    english: "Male",
    nepali: "पुरुष",
};

//SALT
//१०. तपाईको घरमा परिवारले कस्तो नून प्रयोग गर्नु हुन्छ ?
const salttypeQuestion = {
    english: "Which salt do you use?",
    nepali: "तपाईको घरमा परिवारले कस्तो नून प्रयोग गर्नु हुन्छ ?",
};
const salttypeLists = [
    {
        nepali: "आयोडिन  युक्त नून",
        english: "Iodine Salt",
        value: "iodine_salt",
    },
    {
        nepali: "ढिको नून",
        english: "Dhiko salt",
        value: "dhiko_salt",
    },
    {
        nepali: "खुल्ला नून",
        english: "open salt",
        value: "open_salt",
    },
];
//AGRICULTURE AND LIVESTOCKS
//१३.४. कृषि उत्पादन तथा बिक्रि विवरण
const agriProduction = {
    english: "Detail of agricultural production and sales",
    nepali: "कृषि उत्पादन तथा बिक्रि विवरण के के छन् ?",
};
const agriYesNo = [
    {
        nepali: "छ",
        english: "Yes",
        value: "agri_sales_yes",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "agri_sales_no",
    },
];

//If xa vaney कृषि उत्पादन तथा बिक्रि विवरण के के छन् ?
const agriProductionTypeQuestion = {
    english: "Detail of agricultural production and sales",
    nepali: "कृषि उत्पादन तथा बिक्रि विवरण के के छन् ?",
};
const agriProductionType = [
    {
        nepali: "अन्न वालि",
        english: "Crops",
        value: "crops",
    },
    {
        nepali: "दलहन",
        english: "Daal",
        value: "daal",
    },
    {
        nepali: "तेलहन",
        english: "Oil seed",
        value: "oilseed",
    },
    {
        nepali: "तरकारी बाली",
        english: "Vegetable production",
        value: "vegetable",
    },
    {
        nepali: "फलफुल खेती",
        english: "Fruit",
        value: "fruit",
    },
    {
        nepali: "नगदेवाली",
        english: "Cash crops",
        value: "cash_crops",
    },
    {
        nepali: "फुलखेती",
        english: "Flower farm",
        value: "flower",
    },
];
//If xa vaney  कुन कुन अन्नवालिको उत्पादन तथा बिक्रि विवरण छन् ?
const agriTypeProduction = {
    english: "Which crops are produced and sales?",
    nepali: "कुन कुन अन्नवालिको उत्पादन तथा बिक्रि विवरण छन् ?",
};

const allAgriType = [
    {
        nepali: "धान",
        english: "Paddy",
        value: "dhaan",
    },
    {
        nepali: "मकै",
        english: "Maize",
        value: "maize",
    },
    {
        nepali: "कोदो",
        english: "Kodo",
        value: "kodo",
    },
    {
        nepali: "फापर",
        english: "fapar",
        value: "fapar",
    },
    {
        nepali: "गहू/ जौ",
        english: "Gahu",
        value: "gahu",
    },
    {
        nepali: "आलु",
        english: "Potato",
        value: "potato",
    },
    {
        nepali: "बन्दा/काउली /बोडी",
        english: "Cabbage",
        value: "cabbage",
    },
    {
        nepali: "गोलभेडा (टमाटर )",
        english: "Tomato",
        value: "tomato",
    },
    {
        nepali: "फर्सी /लौका",
        english: "Cucumber",
        value: "cucumber",
    },
    {
        nepali: "रायो (साग सब्जी )",
        english: "Rayo Saag",
        value: "vejetable_raay",
    },
    {
        nepali: "मुला/ काक्रो /गाजर ",
        english: "bittergroud",
        value: "bittergroud",
    },
    {
        nepali: "पर्वल",
        english: "parwal",
        value: "parwal",
    },
    {
        nepali: "करेला /भेन्टा /भिंडी",
        english: "karela venta vindi",
        value: "karelaa_bhenta_bhindi",
    },
    {
        nepali: "आप",
        english: "Mango",
        value: "mango",
    },
    {
        nepali: "केरा",
        english: "Banana",
        value: "Banana",
    },
    {
        nepali: "सुन्तला/जुनार /कागती (सुन्तला जात )",
        english: "Orange",
        value: "orange",
    },
    {
        nepali: "स्याउ",
        english: "Apple",
        value: "apple",
    },
    {
        nepali: "किवी",
        english: "Kiwi",
        value: "jack_fruit",
    },
    {
        nepali: "अभोगाड़ो",
        english: "pulam",
        value: "pulam",
    },
    {
        nepali: "आरु",
        english: "aaru",
        value: "aaru",
    },
    {
        nepali: "काफल",
        english: "kafal",
        value: "kafal",
    },
    {
        nepali: "मेवा",
        english: "papaiya",
        value: "papaiya",
    },
    {
        nepali: "लिची",
        english: "lichi",
        value: "lichi",
    },
    {
        nepali: "भुइँकटर",
        english: "pineappale",
        value: "pineappale",
    },
    {
        nepali: "अनार",
        english: "pomegranate",
        value: "pomegranate",
    },
    {
        nepali: "ड्रागन फ्रुट",
        english: "Dragan_Fruit",
        value: "Dragan_Fruit",
    },
    {
        nepali: "अम्बा",
        english: "Gauva",
        value: "ambaa",
    },
    {
        nepali: "हलुवाबेद",
        english: "haluwaabed",
        value: "haluwabed",
    },
    {
        nepali: "नासपाती",
        english: "pear",
        value: "nasspaati",
    },
    {
        nepali: "तोरी / सर्स्यु",
        english: "Tori",
        value: "tori",
    },
    {
        nepali: "आलस/तिल",
        english: "Aalash",
        value: "aalash",
    },
    {
        nepali: "फिलुंगे",
        english: "Filunge",
        value: "philunge",
    },
    {
        nepali: "सूर्यमुखी",
        english: "Sunflower",
        value: "sunflower",
    },
    {
        nepali: "चिउरी",
        english: "Chiuri",
        value: "chiuri",
    },
    {
        nepali: "सिमी",
        english: "simi",
        value: "simi",
    },
    {
        nepali: "मटर / केराउ",
        english: "black_daal",
        value: "black_daal",
    },
    {
        nepali: "रहर",
        english: "rahar",
        value: "rahar",
    },
    {
        nepali: "भटमास",
        english: "musuro",
        value: "musuro",
    },
    {
        nepali: "गहत",
        english: "gahat",
        value: "gahat",
    },
    {
        nepali: "बोडी / मस्यांग",
        english: "khesari",
        value: "khesari",
    },
    {
        nepali: "चना /मुसुरो",
        english: "chana_musuro",
        value: "chana_musuro",
    },
    {
        nepali: "मासको दाल / कालो दाल",
        english: "kalo_daal",
        value: "kalo_daal",
    },
    {
        nepali: "खेसरी",
        english: "khesari_1",
        value: "khesari_1",
    },
    {
        nepali: "अलैची",
        english: "alaichi",
        value: "chilly",
    },
    {
        nepali: "अम्लिसो",
        english: "Amliso",
        value: "amliso",
    },
    {
        nepali: "कफी",
        english: "Coffee",
        value: "coffee",
    },
    {
        nepali: "चिया",
        english: "Tea",
        value: "tea",
    },
    {
        nepali: "अदुवा/बेसार",
        english: "Ginger",
        value: "ginger",
    },
    {
        nepali: "लसुन/प्याज",
        english: "Garlic",
        value: "garlic",
    },
    {
        nepali: "च्याउ",
        english: "Mushroom",
        value: "mushroom",
    },
    {
        nepali: "उखु",
        english: "Suagrcane",
        value: "sugarcane",
    },
    {
        nepali: "सुपारी",
        english: "nut",
        value: "supari",
    },
    {
        nepali: "ओखर",
        english: "okhar",
        value: "okhar",
    },
    {
        nepali: "नरिवल",
        english: "coconut",
        value: "coconut",
    },
];
const agriCropType = [
    {
        nepali: "धान",
        english: "Paddy",
        value: "dhaan",
    },
    {
        nepali: "मकै",
        english: "Maize",
        value: "maize",
    },
    {
        nepali: "कोदो",
        english: "Kodo",
        value: "kodo",
    },
    {
        nepali: "फापर",
        english: "fapar",
        value: "fapar",
    },
    {
        nepali: "गहू/ जौ",
        english: "Gahu",
        value: "gahu",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "crop_other",
    },
];
//धानखेति गरिएको विवरण
const agriDhanInfo = {
    english: "Detail of paddy Farming ",
    nepali: "धानखेति गरिएको विवरण",
};
const agriDhanAreaQuestion = {
    english: "Area of paddy farming",
    nepali: "धानखेति गरिएको क्षेत्रफ़ल",
};
const agriCropArea = [
    {
        nepali: "रोपनी",
        english: "Ropani",
        value: "",
    },
    {
        nepali: "आना",
        english: "Aana",
        value: "",
    },
    {
        nepali: "पैसा",
        english: "Paisa",
        value: "",
    },
];
//धानखेति उत्पादन (मुरी पाथीमा)
const agriDhanProduction = {
    english: "Production of paddy(muri pathi)",
    nepali: "धानखेति उत्पादन (मुरी पाथीमा)",
};
const agriDhanProductionQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
];
//धानखेति विक्री परिमाण (मुरी पाथीमा)
const agriDhanSale = {
    english: "Sales quantity of paddy(muri pathi)",
    nepali: "धानखेति विक्री परिमाण (मुरी पाथीमा)",
};
const agriDhanSaleQuantity = [
    {
        nepali: "मुरी",
        english: "Muri",
        value: "",
    },
    {
        nepali: "पाथी",
        english: "Paathi",
        value: "",
    },
    {
        nepali: "धानखेति उत्पादन वाट बार्षिक आम्दानी कति हो ?",
        english: "Total Income From Crops",
        value: "",
    },
];

const welfareYesNoQuestion = {
    nepali:
        "तपाईको परिवारमा सामाजिक सुरक्षा भत्ता प्राप्त गर्ने कोहि हुनुहुन्छ ?",
    english:
        "Is  there anyone in your family who are getting social security allowances?",
};

const welfareYesNo = [
    {
        nepali: "छ",
        english: "yes",
        value: "social_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "social_no",
    },
];

//welfare if yes
const bhattaQuestion = {
    nepali: "परिवारमा सामाजिक सुरक्षा भत्ता प्राप्त गर्नेको संख्या?",
    english: "No of member who gets social security allowances",
};

const bhattaLists = [
    {
        nepali: "छैन",
        english: "Not Taken",
        value: "not_taken",
    },
    {
        nepali: "पेन्सन नेपाल सरकार",
        english: "Pension Nepal Gov",
        value: "pension",
    },
    {
        nepali: "पेन्सन अन्य देश",
        english: "Pension Other Country",
        value: "pension_other",
    },
    {
        nepali: "बृद्ध भत्ता",
        english: "Old age allowance",
        value: "old",
    },
    {
        nepali: "विधुवा भत्ता",
        english: "Widow Allowance",
        value: "bidhuwa",
    },
    {
        nepali: "दलित बृद्ध भत्ता",
        english: "Dalit Allowance",
        value: "dalit",
    },
    {
        nepali: "अपाङ्गता भत्ता",
        english: "Disablity Allowance",
        value: "handicap",
    },
    {
        nepali: "सरकारी छात्रवृत्ति",
        english: "Scholarship",
        value: "scholarship",
    },
];
const nottakenbhattamale = {
    nepali: "छैन पुरुष संख्या ",
    english: "No of male pension not taken",
    value: "",
};

const nottakenbhattafemale = {
    nepali: "छैन महिला संख्या",
    english: "No of female pension not taken",
    value: "",
};

const govpension = {
    nepali: "पेन्सन नेपाल सरकार",
    english: "No of pension nepal gov",
    value: "",
};

const othercountrypension = {
    nepali: "पेन्सन अन्य देश संख्या",
    english: "No of pension other country",
    value: "",
};

const oldagemen = {
    nepali: "बृद्ध भत्ता पुरुष संख्या",
    english: "No of old age allowance male",
    value: "",
};

const oldagewomen = {
    nepali: "बृद्ध भत्ता महिला संख्या",
    english: "No of old age allowance female",
    value: "",
};

const widow = {
    nepali: "विधुवा भत्ता संख्या",
    english: "No of widow allowance",
    value: "",
};
const handicapmale = {
    nepali: "अपाङ्गता भत्ता पुरुष संख्या",
    english: "No of disability allowance male ",
    value: "",
};
const handicapfemale = {
    nepali: "अपाङ्गता भत्ता महिला संख्या",
    english: "No of female disability allowance ",
    value: "",
};
const dalitMale = {
    nepali: "दलित वर्ग पुरुष संख्या",
    english: "No of male dalit ",
    value: "",
};
const dalitFemale = {
    nepali: "दलित वर्ग महिला संख्या",
    english: "No of female dalit",
    value: "",
};

const scholarshipmale = {
    nepali: "सरकारी छात्रवृत्ति पुरुष संख्या",
    english: "No of male scholarship  ",
    value: "",
};
const scholarshipfemale = {
    nepali: "सरकारी छात्रवृत्ति महिला संख्या",
    english: "No of female scholarship ",
    value: "",
};
//end bhatta
const houseproblem = {
    nepali: "घर सम्बन्धीकुनै समस्या भए उल्लेख गर्नुहोस",
    english: "If you have any house problem then mention",
    value: "",
};
const suggestion = {
    nepali: "गाउँपालिकालाई तपाइले आफ्नो स्थानको लागी केहि सुझाव/सल्लाह छ?",
    english: "Do you have any suggestation for rural municipality?",
    value: "",
};

//houseprimary detail
const wardQuestion = { nepali: "वडा नं", english: "Ward no" };

const wardNumber = [
    {
        nepali: "१",
        english: "1",
        value: "w1",
    },
    {
        nepali: "२",
        english: "2",
        value: "w2",
    },
    {
        nepali: "३",
        english: "3",
        value: "w3",
    },
    {
        nepali: "४",
        english: "4",
        value: "w4",
    },
    {
        nepali: "५",
        english: "5",
        value: "w5",
    },
    {
        nepali: "६",
        english: "6",
        value: "w6",
    },
];

const toleOneQn = { nepali: "टोल वा वस्तीको वार्ड १", english: "" };
const toleTwoQn = { nepali: "टोल वा वस्तीको वार्ड २", english: "" };
const toleThreeQn = { nepali: "टोल वा वस्तीको वार्ड ३", english: "" };
const toleFourQn = { nepali: "टोल वा वस्तीको वार्ड ४", english: "" };
const toleFiveQn = { nepali: "टोल वा वस्तीको वार्ड ५", english: "" };
const toleSixQn = { nepali: "टोल वा वस्तीको वार्ड ६", english: "" };

const allToleName = [
    {
        nepali: "मौराडी ",
        english: "Mauraadi",
        value: "mauraadi",
    },
    {
        nepali: "पैयाखाली",
        english: "paiyaakhaali",
        value: "paiyaakhaali",
    },
    {
        nepali: "सुगरखाल",
        english: "Sugarkhaal",
        value: "sugarkhaal",
    },
    {
        nepali: "खडीना",
        english: "Khadinaa",
        value: "khadinaa",
    },
    {
        nepali: "किलौडा",
        english: "Kilauda",
        value: "kilauda",
    },
    {
        nepali: "साकिन डाँडा",
        english: "Saakinadada",
        value: "saakinadada",
    },
    { nepali: "बाजिलेक", english: "Baajileka", value: "baajileka" },
    { nepali: "उल्लजे", english: "Ullaje", value: "ullaje" },
    { nepali: "कमलानदी", english: "Kamalaanadi", value: "kamalaanadi" },
    { nepali: "चौड", english: "Chaudh", value: "chaudh" },
    { nepali: "छतिवन", english: "Chatiwan", value: "chatiwan" },
    { nepali: "पत्तडा", english: "Patada", value: "patada" },
    { nepali: "राजोपूर", english: "Rajopur", value: "rajopur" },
    { nepali: "चौकी", english: "Chouki", value: "chouki" },
    { nepali: "बोकडा", english: "Bokadaa", value: "bokadaa" },
    { nepali: "अमौन", english: "Amaun", value: "amaun" },
    { nepali: "हरडानी", english: "Hardaani", value: "hardaani" },
    { nepali: "नकुरडा", english: "Nakurdaa", value: "nakurdaa" },
    { nepali: "खहडा", english: "Khahadaa", value: "khahadaa" },
    { nepali: "धामी गाउँ", english: "Dhamigaau", value: "dhamigaau" },
    { nepali: "चोखाल गाउँ", english: "Chokhaalgaau", value: "chokhaalgaau" },
    { nepali: "बामन गाउँ", english: "Baamangaau", value: "baamangaau" },
    { nepali: "भुल खेत", english: "Bhulkhet", value: "bhulkhet" },
    { nepali: "वल्खेत", english: "Walkhet", value: "walkhet" },
    { nepali: "पातली गाउँ", english: "Paataligaau", value: "paataligaau" },
    { nepali: "मनोहर बजार ", english: "Manoharbajaar", value: "manoharbajaar" },
    { nepali: "लेकाली", english: "Lekaali", value: "lekaali" },
    { nepali: "अन्य", english: "Others", value: "others" },
];

const toleWardOne = [
    {
        nepali: "मौराडी ",
        english: "Mauraadi",
        value: "mauraadi",
    },
    {
        nepali: "पैयाखाली",
        english: "paiyaakhaali",
        value: "paiyaakhaali",
    },
    {
        nepali: "सुगरखाल",
        english: "Sugarkhaal",
        value: "sugarkhaal",
    },
    {
        nepali: "खडीना",
        english: "Khadinaa",
        value: "khadinaa",
    },
    {
        nepali: "किलौडा",
        english: "Kilauda",
        value: "kilauda",
    },
    {
        nepali: "साकिन डाँडा",
        english: "Saakinadada",
        value: "saakinadada",
    },
    { nepali: "गोगन पानी", english: "Goganpani", value: "goganpani" },
    { nepali: "झुर डाँडा", english: "Jhudada", value: "jhudada" },
    { nepali: "खार", english: "Khaar", value: "khaar" },
    { nepali: "टोलि", english: "Toli", value: "toli" },
    { nepali: "तुर्से", english: "Turse", value: "turse" },
    { nepali: "गोल्डेनर", english: "Goldenar", value: "goldenar" },
    { nepali: "काफल डाँडा", english: "Kafaldad", value: "kafaldad" },
    { nepali: "घोराड़ी", english: "Ghoradi", value: "ghoradi" },
    { nepali: "पत्थरखानी", english: "Patharkhani", value: "patharkhani" },
    { nepali: "धर्मपुर", english: "Dharmapur", value: "dharmapur" },
    { nepali: "गौरा", english: "Gaura", value: "gaura" },
    { nepali: "हिस्सा", english: "Hissaa", value: "hissaa" },
    { nepali: "भानाकोट", english: "Bhanakot", value: "bhanakot" },
    { nepali: "चपरचारीनी", english: "Chaparchaarini", value: "chaparchaarini" },
    { nepali: "पोखरी डाँडा", english: "Pokaridad", value: "pokaridad" },
    { nepali: "मणिपुर", english: "Mandipur", value: "mandipur" },
    { nepali: "पत्थर गाडा", english: "Pathargaada", value: "pathargaada" },
    { nepali: "वडाखेत", english: "Wadaakhet", value: "wadaakhet" },
    {
        nepali: "तल्लो वडाखेत",
        english: "Tallowadakhet",
        value: "tallowadakhet",
    },
    { nepali: "सुन्लेक", english: "Sunlek", value: "sunlek" },
    { nepali: "वेझपाता", english: "Wejhpaataa", value: "wejhpaataa" },
    { nepali: "खैगाड", english: "Khaigaad", value: "khaigaad" },
    { nepali: "गैरीधारा", english: "Gauridhara", value: "gauridhara" },
    { nepali: "पैरेता", english: "Pairetaa", value: "pairetaa" },
    { nepali: "मुसलेक", english: "Muslek", value: "muslek" },
    { nepali: "अन्य", english: "Others", value: "others" },
];

const toleWardTwo = [
    { nepali: "विछल", english: "Wichal", value: "wichal" },
    { nepali: "रुवासकाडा", english: "Ruwaaskada", value: "ruwaaskada" },
    { nepali: "धारापानी", english: "Dharapani", value: "dharapani" },
    { nepali: "डाँडा खालि", english: "Dhadakhali", value: "dhadakhali" },
    { nepali: "कालाबन", english: "Kalabana", value: "kalabana" },
    { nepali: "दह", english: "Daha", value: "daha" },
    { nepali: "भट्टी", english: "Bhatit", value: "bhatit" },
    { nepali: "मील चौराही", english: "Milchauraahi", value: "milchauraahi" },
    { nepali: "धीमडी", english: "Dhamadi", value: "dhamadi" },
    { nepali: "तल्लो रिण", english: "Talorind", value: "talorind" },
    { nepali: "मल्लो रिण", english: "Mallorid", value: "mallorid" },
    { nepali: "पनाली गैरा", english: "Panaali_gaira", value: "panaali_gaira" },
    { nepali: "खसरे", english: "Khasare", value: "khasare" },
    { nepali: "कटपटे", english: "Katapate", value: "katapate" },
    { nepali: "जजना", english: "Jajanaa", value: "jajanaa" },
    { nepali: "बाजिलेक", english: "Baajileka", value: "baajileka" },
    { nepali: "उल्लजे", english: "Ullaje", value: "ullaje" },
    { nepali: "कमलानदी", english: "Kamalaanadi", value: "kamalaanadi" },
    { nepali: "चौड", english: "Chaudh", value: "chaudh" },
    { nepali: "छतिवन", english: "Chatiwan", value: "chatiwan" },
    { nepali: "पत्तडा", english: "Patada", value: "patada" },
    { nepali: "राजोपूर", english: "Rajopur", value: "rajopur" },
    { nepali: "बस्यनी", english: "Basyani", value: "basyani" },
    { nepali: "डोडरी", english: "dodari", value: "dodari" },
    { nepali: "इगडा", english: "Igadaa", value: "igadaa" },
    { nepali: "मेलखोला", english: "Malekhola", value: "malekhola" },
    { nepali: "केराबारी", english: "Keraabari", value: "keraabari" },
    { nepali: "सेला", english: "Selaa", value: "selaa" },
    { nepali: "रंगिनी", english: "Rangini", value: "rangini" },
    { nepali: "बस्ती", english: "Basti", value: "basti" },
    { nepali: "गल्लेक", english: "Gallek", value: "gallek" },
    { nepali: "कक्नाली", english: "Kaknaali", value: "kaknaali" },
    { nepali: "छहरा", english: "Chaharaa", value: "chaharaa" },
    { nepali: "अन्य", english: "Others", value: "others" },
];

const toleWardThree = [
    { nepali: "अल्याड़ी", english: "Alyaadi", value: "alyaadi" },
    { nepali: "सेगुनी", english: "seguni", value: "seguni" },
    { nepali: "ठुल्ढुंगा", english: "Thuldhunga", value: "thuldhunga" },
    { nepali: "टाटर", english: "Taatr", value: "taatr" },
    { nepali: "उपरकोट", english: "Uparkot", value: "uparkot" },
    { nepali: "निगालतदा", english: "Nigaaltadaa", value: "nigaaltadaa" },
    { nepali: "कोला", english: "Kolaa", value: "kolaa" },
    { nepali: "सेला", english: "Selaa", value: "selaa" },
    { nepali: "अवस्थी गाउँ", english: "Awasthigaau", value: "awasthigaau" },
    { nepali: "साहानी गाउँ", english: "Saahaanigaau", value: "saahaanigaau" },
    { nepali: "गाडा खर्के ", english: "Gaadakharke", value: "gaadakharke" },
    { nepali: "आरा ", english: "Aaraa", value: "aaraa" },
    { nepali: "रिठारा", english: "Rithaara", value: "rithaara" },
    { nepali: "तडा", english: "Tadaa", value: "tadaa" },
    { nepali: "वेल्तुक्री", english: "weltukri", value: "weltukri" },
    { nepali: "सुगाल", english: "sugaal", value: "sugaal" },
    { nepali: "नौकुडा", english: "Naukudaa", value: "naukudaa" },
    { nepali: "दुदेखालि", english: "dudekhali", value: "dudekhali" },
    { nepali: "बर्मे", english: "Barme", value: "barme" },
    { nepali: "रावतकट्टे", english: "Raawatkate", value: "raawatkate" },
    { nepali: "बेलालपानी", english: "Belaalpaani", value: "belaalpaani" },
    { nepali: "भाड़ी", english: "Bhadhi", value: "bhadhi" },
    { nepali: "सुनिखोला", english: "Sunikholaa", value: "sunikholaa" },
    { nepali: "लस्कर", english: "Laskar", value: "laskar" },
    { nepali: "कोटेली", english: "Kateli", value: "kateli" },
    { nepali: "पातल", english: "Paatal", value: "paatal" },
    { nepali: "कनेडी", english: "Kadeni", value: "kadeni" },
    { nepali: "फिरिंगताल", english: "Firigataal", value: "firigataal" },
    { nepali: "नवलपुर", english: "Nawalpur", value: "nawalpur" },
    { nepali: "थाली", english: "Thali", value: "thali" },
    { nepali: "शान्ति टोल", english: "Santitol", value: "santitol" },
    { nepali: "कफलानी", english: "Kafalaani", value: "kafalaani" },
    { nepali: "मुडकी", english: "Mudaki", value: "mudaki" },
    { nepali: "अन्य", english: "Others 1", value: "others_1" },
];

const toleWardFour = [
    { nepali: "कलमटा", english: "Kalamataa", value: "kalamataa" },
    { nepali: "भाजना", english: "Bhaajanaa", value: "bhaajanaa" },
    { nepali: "पातल", english: "Paatal", value: "paatal" },
    { nepali: "घरेल", english: "Gharel", value: "gharel" },
    { nepali: "नक्कु", english: "Nakku", value: "nakku" },
    { nepali: "सिराड", english: "Shirada", value: "shirada" },
    { nepali: "खैगाड", english: "Khaigaad", value: "khaigaad" },
    { nepali: "पिपलकोट", english: "Pipalkot", value: "pipalkot" },
    { nepali: "तलाल", english: "Talaal", value: "talaal" },
    { nepali: "फलेडी", english: "Faledi", value: "faledi" },
    { nepali: "दूबगुनड", english: "Dubagunada", value: "dubagunada" },
    { nepali: "खिरमद", english: "Khirmad", value: "khirmad" },
    { nepali: "कुमरा", english: "Kumara", value: "kumara" },
    { nepali: "कुडेल गाउँ", english: "Kudelagaau", value: "kudelagaau" },
    { nepali: "गुयाली", english: "Guyaali", value: "guyaali" },
    { nepali: "हाट", english: "Haat", value: "haat" },
    { nepali: "सेला", english: "Selaa", value: "selaa" },
    { nepali: "तिज डाँडा", english: "Tijdhada", value: "tijdhada" },
    { nepali: "गोल्ला", english: "Golla", value: "golla" },
    { nepali: "काफली", english: "Kaafali", value: "kaafali" },
    { nepali: "खैरखोला", english: "Khairkholaa", value: "khairkholaa" },
    { nepali: "पिपलकन्या", english: "Pipalkanyaa", value: "pipalkanyaa" },
    { nepali: "बाजीलेक", english: "Baajilek", value: "baajilek" },
    { nepali: "धनरास ", english: "Dhanraash", value: "dhanraash" },
    { nepali: "विनोडा", english: "Winoda", value: "winoda" },
    { nepali: "गौजारी", english: "Gaujaari", value: "gaujaari" },
    { nepali: "एतोड़ा", english: "Etoda", value: "etoda" },
    { nepali: "देउथला", english: "Deuthalaa", value: "deuthalaa" },
    { nepali: "जदेपानी", english: "Jadepaani", value: "jadepaani" },
    { nepali: "चुचुनढुंगा", english: "Chuchundhunga", value: "chuchundhunga" },
    { nepali: "सीलम्बाडा", english: "Silmbada", value: "silmbada" },
    { nepali: "विनरकट्टे", english: "Winarkatte", value: "winarkatte" },
    { nepali: "चोरकोट", english: "Chorkot", value: "chorkot" },
    { nepali: "ढरकट्टे", english: "Dharkatte", value: "dharkatte" },
    { nepali: "दियाकोट", english: "Diyakot", value: "diyakot" },
    { nepali: "खलंगा", english: "Khalanga", value: "khalanga" },
    { nepali: "अन्य", english: "Other", value: "others" },
];

const toleWardFive = [
    { nepali: "चौकी", english: "Chouki", value: "chouki" },
    { nepali: "बोकडा", english: "Bokadaa", value: "bokadaa" },
    { nepali: "अमौन", english: "Amaun", value: "amaun" },
    { nepali: "हरडानी", english: "Hardaani", value: "hardaani" },
    { nepali: "नकुरडा", english: "Nakurdaa", value: "nakurdaa" },
    { nepali: "खहडा", english: "Khahadaa", value: "khahadaa" },
    { nepali: "धामी गाउँ", english: "Dhamigaau", value: "dhamigaau" },
    { nepali: "चोखाल गाउँ", english: "Chokhaalgaau", value: "chokhaalgaau" },
    { nepali: "बामन गाउँ", english: "Baamangaau", value: "baamangaau" },
    { nepali: "भुल खेत", english: "Bhulkhet", value: "bhulkhet" },
    { nepali: "वल्खेत", english: "Walkhet", value: "walkhet" },
    { nepali: "पातली गाउँ", english: "Paataligaau", value: "paataligaau" },
    { nepali: "मनोहर बजार ", english: "Manoharbajaar", value: "manoharbajaar" },
    { nepali: "लेकाली", english: "Lekaali", value: "lekaali" },
    { nepali: "बडीगाड", english: "Badigaad", value: "badigaad" },
    { nepali: "बन्द्वार", english: "Bandur", value: "bandur" },
    { nepali: "ओझा गाउँ", english: "Ojhagaau", value: "ojhagaau" },
    { nepali: "खतेडा", english: "Khaledaa", value: "khaledaa" },
    { nepali: "अलिबाटा", english: "Alibaataa", value: "alibaataa" },
    { nepali: "कुरै", english: "Kurai", value: "kurai" },
    { nepali: "बमन्कट्टे", english: "Bamankate", value: "bamankate" },
    { nepali: "ओल्कत्ते", english: "Olkate", value: "olkate" },
    { nepali: "टोलि", english: "Toli", value: "toli" },
    { nepali: "सिस्मडा", english: "Sismada", value: "sismada" },
    { nepali: "कुनेगडा", english: "Kunegadaa", value: "kunegadaa" },
    { nepali: "ग्वान", english: "Gwan", value: "gwan" },
    { nepali: "बोहरा गाउँ", english: "Bohoragaau", value: "bohoragaau" },
    { nepali: "फाल्दा", english: "Faaldaa", value: "faaldaa" },
    {
        nepali: "चोखाल गाउँ",
        english: "Chokhaalgaau_1",
        value: "chokhaalgaau_1",
    },
    { nepali: "सेल्खोल्टा", english: "Selkholtaa", value: "selkholtaa" },
    { nepali: "नौकोट", english: "Naukot", value: "naukot" },
    { nepali: "मनोहर बजार", english: "Manoharbajar", value: "manoharbajar" },
    { nepali: "खतेडा", english: "Khatedha", value: "khatedha" },
    { nepali: "चिबटा", english: "Chibataa", value: "chibataa" },
    { nepali: "थला", english: "Thalaa", value: "thalaa" },
    { nepali: "नेन्ला", english: "Nellaa", value: "nellaa" },
    { nepali: "चुकिला पानी", english: "Chukilaapani", value: "chukilaapani" },
    { nepali: "पिपल तोला", english: "Pipaltola", value: "pipaltola" },
    { nepali: "गुम", english: "Gum", value: "gum" },
    { nepali: "अन्य", english: "Other", value: "others" },
];

const toleWardSix = [
    { nepali: "गावर", english: "Gaawar", value: "gaawar" },
    { nepali: "चिमेतडा", english: "Chimetada", value: "chimetada" },
    { nepali: "तेलपरा", english: "Telapara", value: "telapara" },
    { nepali: "तानतड", english: "Taalapara", value: "taalapara" },
    { nepali: "चोट्टे", english: "Chotte", value: "chotte" },
    { nepali: "लामौत्य", english: "Laamautya", value: "laamautya" },
    { nepali: "उमरा", english: "Uamara", value: "uamara" },
    { nepali: "स्यामारे", english: "Iyamaare", value: "iyamaare" },
    { nepali: "दाङड", english: "Daangda", value: "daangda" },
    { nepali: "थाकाना", english: "Thaakaana", value: "thaakaana" },
    { nepali: "कस्ताडी", english: "Kastaadi", value: "kastaadi" },
    { nepali: "जडेपानी", english: "Jadepaani", value: "jadepaani" },
    { nepali: "शिर्शी", english: "Shishri", value: "shishri" },
    { nepali: "शिर्शिखेत", english: "Shishrikhet", value: "shishrikhet" },
    { nepali: "गुनड", english: "Gunda", value: "gunda" },
    { nepali: "सिरोडा", english: "Shirodaa", value: "shirodaa" },
    { nepali: "डांडा गाउँ", english: "Dadagaau", value: "dadagaau" },
    { nepali: "खसरे", english: "Khasare", value: "khasare" },
    { nepali: "ग्वानी", english: "Gwani", value: "gwani" },
    { nepali: "बसौटी", english: "Basauti", value: "basauti" },
    { nepali: "निरौली", english: "Nirauli", value: "nirauli" },
    { nepali: "वागीडा", english: "Wagidaa", value: "wagidaa" },
    { nepali: "दह", english: "Daha", value: "daha" },
    { nepali: "खतेना", english: "Khetan", value: "khetan" },
    { nepali: "सित्तोली", english: "Shitol", value: "shitol" },
    { nepali: "कफलतडी", english: "Khafalatadi", value: "khafalatadi" },
    { nepali: "तितौले", english: "Tilaule", value: "tilaule" },
    { nepali: "अनरकट्टे", english: "Anarkatte", value: "anarkatte" },
    { nepali: "होलेसैन", english: "Holesaini", value: "holesaini" },
    { nepali: "सुनारी", english: "Sunari", value: "sunari" },
    { nepali: "उच्कला", english: "Uchkalaa", value: "uchkalaa" },
    { nepali: "नवलपुर", english: "Nawalpur", value: "nawalpur" },
    { nepali: "आरुबाटा", english: "Aarubaat", value: "aarubaat" },
    { nepali: "अन्य", english: "Other", value: "others" },
];

const gharNumber = {
    nepali: "घर नं",
    english: "House No.",
    value: "",
};

const sabikGabisaQuestion = {
    nepali: "साविक गा.वि.स",
    english: "Sabik gaa bi sa",
};

const sabikGabisa = [
    {
        nepali: "बाग्लुङपानी",
        english: "Baaglungpani",
        value: "baaglungpaani",
    },
    {
        nepali: "मालिङ",
        english: "Maaling",
        value: "maaling",
    },
    {
        nepali: "उत्तरकन्या",
        english: "Uttarkanya",
        value: "uttarkanya",
    },
    {
        nepali: "भुजुङ",
        english: "Bhujung",
        value: "bhujung",
    },
    {
        nepali: "पसगाउँ",
        english: "Pasgaau",
        value: "pasgaau",
    },
    {
        nepali: "भोजे",
        english: "Bhoje",
        value: "bhoje",
    },
    {
        nepali: "गिलुङ",
        english: "Gilung",
        value: "gilung",
    },
];

const sabikWard = { nepali: "साविक वडा नं ", english: "Sabik ward no." };

const tole = { nepali: "टोल वा वस्तीको नाम", english: "Name of tole " };

const toleNumber = { nepali: "टोल संकेत नं ", english: "Tole no." };

const streetName = {
    nepali: "मार्गको नाम (घर छेउको बाटो )",
    english: "Street name",
};

const answererName = { nepali: "उत्तरदाताको नाम ", english: "Answerer name" };

const answererRelationQuestion = {
    nepali: "उत्तरदाताको घरमुली संगको नाता के हो ?",
    english: "What is the answerer relation with house owner?",
};

// use both in primary detail and fmaily details
const answererRelation = [
    {
        nepali: "घरमुली आफै",
        english: "Self house owner",
        value: "self_house_own",
    },
    {
        nepali: "श्रीमान",
        english: "Husband",
        value: "husband",
    },
    {
        nepali: "श्रीमती",
        english: "Wife",
        value: "wife",
    },
    {
        nepali: "आमा",
        english: "Mother",
        value: "mother",
    },
    {
        nepali: "बुवा",
        english: "Father",
        value: "father",
    },
    {
        nepali: "छोरा",
        english: "Son",
        value: "son",
    },
    {
        nepali: "छोरी",
        english: "Daughter",
        value: "daughter",
    },
    {
        nepali: "बुहारी",
        english: "Buhari",
        value: "buhari",
    },
    {
        nepali: "ज्वाई",
        english: "Jwai",
        value: "jwai",
    },
    {
        nepali: "सासु",
        english: "Sasu",
        value: "sashu",
    },
    {
        nepali: "ससुरा",
        english: "Sasura",
        value: "sasura",
    },
    {
        nepali: "काका",
        english: "Kaka",
        value: "kaka",
    },
    {
        nepali: "काकी",
        english: "Kaki",
        value: "kaki",
    },
    {
        nepali: "फुपू",
        english: "Fupu",
        value: "fupu",
    },
    {
        nepali: "फुपाजु",
        english: "Fupaju",
        value: "fupaju",
    },
    {
        nepali: "मामा",
        english: "Mama",
        value: "mama",
    },
    {
        nepali: "माइजु",
        english: "Maiju",
        value: "maiju",
    },
    {
        nepali: "नाती",
        english: "Nati",
        value: "nati",
    },
    {
        nepali: "नातिनी",
        english: "Natini",
        value: "natini",
    },
    {
        nepali: "दाजु",
        english: "Daju",
        value: "daju",
    },
    {
        nepali: "भाई",
        english: "Bhai",
        value: "bhai",
    },
    {
        nepali: "दिदि",
        english: "Didi",
        value: "didi",
    },
    {
        nepali: "बहिनी",
        english: "Bahini",
        value: "bahini",
    },
    {
        nepali: "भाउजु",
        english: "Bhauju",
        value: "bhauju",
    },
    {
        nepali: "भाई बुहारी",
        english: "Bhai Buhari",
        value: "bhai_buhari",
    },
    {
        nepali: "जेठान",
        english: "Jethan",
        value: "jethan",
    },
    {
        nepali: "ठुलो बुवा",
        english: "Thulo Buwa",
        value: "thulo_bbuwa",
    },
    {
        nepali: "ठुलो आमा",
        english: "Thulo aama",
        value: "thulo_aama",
    },
    {
        nepali: "हजुर बुवा",
        english: "",
        value: "grand_father",
    },
    {
        nepali: "हजुर आमा",
        english: "Grand mother",
        value: "grand_mother",
    },
    {
        nepali: "देवर",
        english: "Dewar",
        value: "dewar",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

const familyMale = {
    nepali: "परिवारको पुरुष संख्या",
    english: "No of male in family",
};

const familyFemale = {
    nepali: "परिवारको महिला संख्या",
    english: "No of female in family",
};

const familyThirdGender = {
    nepali: "तेस्रो लिंगी सदस्य संख्या",
    english: "No of third gender",
};

const totalMember = {
    nepali: "जम्मा सदस्य संख्या",
    english: "Total no of family member",
};

const geoLocation = { nepali: "जियो कोड", english: "Geo code" };

const latitude = { nepali: "अक्षांश", english: "Latitude" };

const longitude = { nepali: "देशान्तर", english: "Longitude" };

const housePhoto = { nepali: "घर को फोटो", english: "House photo" };
const citizenshipFrontPhoto = {
    nepali: "नागरिकताको अगाडिको फोटो",
    english: "Front photo of citizenship",
};
const citizenshipBackPhoto = {
    nepali: "नागरिकताको पछाडिको  फोटो",
    english: "Back photo of citizenship",
};

const livingtypeQuestion = {
    nepali: "तपाईको परिवारको यहाको बसोबास कस्तो छ ?",
    english: "How is your living style here?",
};
const geoCode = { nepali: "जियो कोड", english: "Geo code" };
const livingType = [
    {
        nepali: "अस्थाई",
        english: "temporary",
        value: "temporary",
    },
    {
        nepali: "स्थाई",
        english: "Permanent",
        value: "permanant",
    },
    {
        nepali: "सुकुम्बासी",
        english: "sukumbaasi",
        value: "sukumbasi",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

//if अस्थाई clicked
const permanentQuestion = {
    nepali: "स्थाई वसोवास कहाँ छ ?",
    english: "Where are you living now?",
};

const permanent = [
    {
        nepali: "अर्को वार्ड ( पालिका भित्रै )",
        english: "other ward",
        value: "other_ward",
    },
    {
        nepali: "यसै वार्डमा",
        english: "Same district",
        value: "same_district",
    },
    {
        nepali: "मोरङ  जिल्ला बाहिर",
        english: "outside district morang",
        value: "outside_district_morang",
    },
    {
        nepali: "मोरङ  जिल्ला भित्र",
        english: "inside district morang",
        value: "inside_district_morang",
    },
];

//Family details

const memberName = { nepali: "नाम/थर", english: "Full name" };

const contactNo = { nepali: "को सम्पर्क न", english: "Contact no" };

const genderQuestion = { nepali: "को लिङ्ग ?", english: "Gender" };

const dateQuestion = { nepali: "जन्म मिती", english: "Date of Birth" };

const ageQuestion = { nepali: "को उमेर", english: "Age" };

const gender = [
    {
        nepali: "पुरुष",
        english: "Male",
        value: "male",
    },
    {
        nepali: "महिला",
        english: "Female",
        value: "female",
    },
    {
        nepali: "अन्य",
        english: "other sex",
        value: "other_sex",
    },
];

const livingStatusQuestion = {
    nepali: "को वसोवासको स्थिति",
    english: "Living situation",
};

const livingStatus = [
    {
        nepali: "गणना गरेको ठाउँमा बस्ने  (घरमै )",
        english: "Same place of data collection",
        value: "same_place",
    },
    {
        nepali: "स्वदेशमा  अन्यत्र बसेको",
        english: "Other place",
        value: "other_place",
    },
    {
        nepali: "विदेशमा बसेको",
        english: "Foreign",
        value: "foreign",
    },
];

//if विदेश is clicked

const abroadReasonQuestion = {
    nepali: "विदेश गएको कारण ?",
    english: "Reason to go abroad",
};

const abroadReason = [
    {
        nepali: "अध्ययन / तालिम",
        english: "Education",
        value: "education",
    },
    {
        nepali: "तलब/ ज्याला / नोकरी",
        english: "Employment",
        value: "nokari",
    },
    {
        nepali: "व्यापार /व्यावसाय",
        english: "business",
        value: "Business",
    },
    {
        nepali: "डी.भी. चिठ्ठा",
        english: "DV Lottery",
        value: "dv_lottery",
    },
    {
        nepali: "आश्रित",
        english: "aashreet",
        value: "aashreet",
    },
    {
        nepali: "अन्य",
        english: "other reason",
        value: "other_reason",
    },
];

const abroadYearQuestion = {
    nepali:
        "विदेश गएको कति बर्ष भयो ? (६ महिना भयो भने 0.6 लेख्ने २ वर्ष ११ महिना भयो भने 2.11 लेख्ने )",
    english: "How many year you have been in foreign?",
};

const abroadCountryQuestion = {
    nepali: "विदेश गएको देश?",
    english: "Which country?",
};

const abroadCountry = [
    {
        nepali: "भारत",
        english: "India",
        value: "india",
    },
    {
        nepali: "कतार",
        english: "Quatar",
        value: "quatar",
    },
    {
        nepali: "भारत बाहेक अन्य सार्क राष्ट्र",
        english: "SAARC",
        value: "saarc",
    },
    {
        nepali: "साउदी अरब",
        english: "Saudi Arab",
        value: "saudi_arab",
    },
    {
        nepali: "मलेसिया",
        english: "Malayasia",
        value: "malaysia",
    },
    {
        nepali: "जापान",
        english: "Japan",
        value: "japan",
    },
    {
        nepali: "यु.ए.ई. (दुबई)",
        english: "UAE",
        value: "uae",
    },
    {
        nepali: "दक्षिण कोरिया",
        english: "South Korea",
        value: "south_korea",
    },
    {
        nepali: "अष्ट्रेलिया",
        english: "Australia",
        value: "australia",
    },
    {
        nepali: "अमेरिका",
        english: "USA",
        value: "usa",
    },
    {
        nepali: "इराक",
        english: "Iraq",
        value: "iraak",
    },
    {
        nepali: "कुवेत",
        english: "Kuwait",
        value: "kuwait",
    },
    {
        nepali: "क्यानाडा",
        english: "Canada",
        value: "canada",
    },

    {
        nepali: "बहराइन",
        english: "Bahrain",
        value: "bahrain",
    },
    {
        nepali: "ओमान",
        english: "Oman",
        value: "oman",
    },
    {
        nepali: "साइप्रस",
        english: "Cyprus",
        value: "cyprus",
    },
    {
        nepali: "फ्रान्स",
        english: "France",
        value: "france",
    },
    {
        nepali: "चीन",
        english: "China",
        value: "china",
    },
    {
        nepali: "UK",
        english: "UK",
        value: "uk",
    },
    {
        nepali: "स्पेन",
        english: "Spain",
        value: "germany",
    },
    {
        nepali: "इटाली",
        english: "Italy",
        value: "italy",
    },
    {
        nepali: "बेल्जियम",
        english: "Belgium",
        value: "belgium",
    },
    {
        nepali: "पोल्याण्ड",
        english: "Poland",
        value: "poland",
    },
    {
        nepali: "पोर्चुगल",
        english: "Portugal",
        value: "portugal",
    },
    {
        nepali: "अन्य",
        english: "other country",
        value: "other",
    },
];

const casteQuestion = { nepali: "को जात/जाति", english: "caste" };

const casteList = [
    {
        english: "sherpa",
        nepali: "शेर्पा",
        value: "sherpa",
    },
    {
        english: "tamang",
        nepali: "तामांङ",
        value: "tamang",
    },
    {
        english: "chhetri",
        nepali: "क्षेत्री",
        value: "chhetri",
    },
    {
        english: "thaami",
        nepali: "थामी",
        value: "thaami",
    },
    {
        english: "brahmin",
        nepali: "ब्राहमण",
        value: "brahmin",
    },
    {
        english: "rai",
        nepali: "राई",
        value: "rai",
    },
    {
        english: "magar",
        nepali: "मगर",
        value: "magar",
    },
    {
        english: "newar",
        nepali: "नेवार",
        value: "newar",
    },
    {
        english: "gurung",
        nepali: "गुरुङ",
        value: "gurung",
    },
    {
        english: "sanyasi",
        nepali: "सन्यासी /दशनामी",
        value: "sanyasi",
    },
    {
        english: "dhami",
        nepali: "धामी",
        value: "dhami",
    },
    {
        english: "kumal pahadi",
        nepali: "कुमाल पहाडी",
        value: "kumal_pahadi",
    },
    {
        english: "limbu",
        nepali: "लिम्बु",
        value: "limbu",
    },
    {
        english: "biswokarma",
        nepali: "विश्वकर्मा",
        value: "biswokarma",
    },
    {
        english: "saarki",
        nepali: "सार्की",
        value: "saarki",
    },
    {
        english: "Damai",
        nepali: "दमाई",
        value: "damai",
    },
    {
        english: "bhote",
        nepali: "भोटे",
        value: "bhote",
    },
    {
        english: "pariyaar",
        nepali: "परियार",
        value: "pariyaar",
    },
    {
        english: "muslim",
        nepali: "मुस्लिम",
        value: "muslim",
    },
    {
        english: "raaute",
        nepali: "राउटे",
        value: "raaute",
    },
    {
        english: "pahari",
        nepali: "पहरी",
        value: "pahari",
    },
    {
        english: "maajhi",
        nepali: "माझी",
        value: "maajhi",
    },
    {
        english: "madhesi",
        nepali: "मधेसी",
        value: "madhesi",
    },
    {
        english: "chamaar",
        nepali: "चमार",
        value: "chamaar",
    },
    {
        english: "dhimal",
        nepali: "धिमाल",
        value: "dhimal",
    },
    {
        english: "chaudhari",
        nepali: "चौधरी",
        value: "chaudhari",
    },
    {
        english: "satar",
        nepali: "सतार",
        value: "satar",
    },
    {
        english: "danuwar",
        nepali: "दनुवार",
        value: "danuwar",
    },
    {
        english: "Rajbanshi",
        nepali: "राजवंशी",
        value: "Rajbanshi",
    },
    {
        english: "khawaas",
        nepali: "खवास",
        value: "khawaas",
    },
    {
        english: "pattharkatta",
        nepali: "पत्थरकट्टा",
        value: "pattharkatta",
    },
    {
        english: "gangaai",
        nepali: "गनगाई",
        value: "gangaai",
    },
    {
        english: "tharu",
        nepali: "थारु",
        value: "tharu",
    },
    {
        english: "musahar",
        nepali: "मुसहर",
        value: "musahar",
    },
    {
        english: "yaadaw",
        nepali: "यादव",
        value: "yaadaw",
    },
    {
        english: "kalwaar",
        nepali: "कलवार",
        value: "kalwaar",
    },
    {
        english: "teli",
        nepali: "तेली",
        value: "teli",
    },
    {
        english: "koiri",
        nepali: "कोइरी",
        value: "koiri",
    },
    {
        english: "kurmi",
        nepali: "कुर्मी",
        value: "kurmi",
    },
    {
        english: "thakur",
        nepali: "हजाम /ठाकुर",
        value: "thakur",
    },
    {
        english: "dusaad",
        nepali: "दुसाध",
        value: "dusaad",
    },
    {
        english: "bhujel",
        nepali: "भुजेल",
        value: "bhujel",
    },
    {
        english: "baantar",
        nepali: "बाँतर",
        value: "baantar",
    },
    {
        english: "jhaagad",
        nepali: "झाँगड",
        value: "jhaagad",
    },
    {
        english: "other caste",
        nepali: "अन्य",
        value: "other_caste",
    },
];

// if जनजाती आदिवासी is clicked
const aadibasiQuestion = { nepali: "कुन जनजाती हो ?", english: "Which caste" };

const aadibasi = [
    {
        nepali: "राई",
        english: "Rai",
        value: "rai",
    },
    {
        nepali: "मगर",
        english: "Magar",
        value: "magar",
    },
    {
        nepali: "शेर्पा",
        english: "Sherpa",
        value: "sherpa",
    },
    {
        nepali: "गुरुङ्ग",
        english: "Gurung",
        value: "gurung",
    },
    {
        nepali: "सुनुवार",
        english: "Sunuwar",
        value: "sunuwar",
    },
    {
        nepali: "तामाङ्ग",
        english: "Tamang",
        value: "tamang",
    },
    {
        nepali: "नेवार",
        english: "Newar",
        value: "newar",
    },
    {
        nepali: "थकाली",
        english: "Thakali",
        value: "thakali",
    },
    {
        nepali: "लिम्बु",
        english: "Lilbu",
        value: "limbu",
    },
    {
        nepali: "भुजेल",
        english: "Bhujel",
        value: "bhujel",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

//if  पहाडी अन्य जाति is clkicked

// const pahadiQuestion = { nepali: "कुन पहाडी अन्य जाती हो ?", english: "" };
//
// const pahadi = [
// 	{
// 		nepali: "गिरि",
// 		english: "Giri",
// 		value: "giri"
// 	},
// 	{
// 		nepali: "पुरी",
// 		english: "Puri",
// 		value: "puri"
// 	},
// 	{
// 		nepali: "भारती",
// 		english: "Bharati",
// 		value: "bharati"
// 	},
// 	{
// 		nepali: "सन्यासी/दशनामी",
// 		english: "Sanyashi",
// 		value: "sansayi"
// 	},
// 	{
// 		nepali: "योगी",
// 		english: "Yogi",
// 		value: "yogi"
// 	},
// 	{
// 		nepali: "कुँवर",
// 		english: "Kunwar",
// 		value: "kunwar"
// 	}
// ];

//religion
const religionQuestion = { nepali: "को धर्म", english: "religion" };

const religionList = [
    { english: "Hindu", nepali: "हिन्दु", value: "hindu" },
    { english: "Buddhist", nepali: "बौद्ध", value: "buddhist" },
    { english: "Muslim", nepali: "इस्लाम", value: "muslim" },
    { english: "Christian", nepali: "क्रिश्चियन", value: "christian" },
    { english: "Kirat", nepali: "किरात", value: "kirat" },
    { english: "Buddhist", nepali: "बौद्ध", value: "buddhist" },
    { english: "Muslim", nepali: "इस्लाम", value: "muslim" },
    { english: "Christian", nepali: "क्रिश्चियन", value: "christian" },
    { english: "Kirat", nepali: "किरात", value: "kirat" },
    { english: "Buddhist", nepali: "बुद्ध", value: "buddhist" },
    { english: "Muslim", nepali: "इस्लाम (मुस्लिम)", value: "muslim" },
    { english: "Christian", nepali: "इसाई (क्रिश्चियन)", value: "christian" },
    { english: "Kirat", nepali: "किंरात", value: "kirat" },
    { english: "Buddhist", nepali: "बौद्ध", value: "buddhist" },
    { english: "Muslim", nepali: "इस्लाम", value: "muslim" },
    { english: "Christian", nepali: "क्रिश्चियन", value: "christian" },
    { english: "Kirat", nepali: "किरात", value: "kirat" },
    { english: "Buddhist", nepali: "बौद्ध", value: "buddhist" },
    { english: "Muslim", nepali: "इस्लाम", value: "muslim" },
    { english: "Christian", nepali: "क्रिश्चियन", value: "christian" },
    { english: "Kirat", nepali: "किरात", value: "kirat" },
    { english: "Boon", nepali: "वोन", value: "boon" },
    { english: "Prakriti", nepali: "प्रकृति", value: "prakriti" },
    { english: "Jain", nepali: "जैन", value: "jain" },
    { english: "Bahai", nepali: "बहाई", value: "bahai" },
    { english: "yuma", nepali: "युमा", value: "yuma" },
    { english: "Other", nepali: "अन्य", value: "other_religion" },
];

//marriage

const marriageQuestion = {
    nepali: "को बैवाहिक स्थिति",
    english: "Marital status",
};

const marriage = [
    {
        nepali: "अविवाहित",
        english: "Unmarried",
        value: "unmarried",
    },
    {
        nepali: "विवाहित",
        english: "Married",
        value: "married",
    },
    {
        nepali: "विदुर / एकल पुरुष",
        english: "widower",
        value: "widower",
    },
    {
        nepali: "एकल महिला (विधुवा)",
        english: "alone_women",
        value: "alone_women",
    },
    {
        nepali: "सम्बन्ध बिच्छेद",
        english: "Divorce",
        value: "divorce",
    },
    {
        nepali: "विवाहित तर अलग बसेको",
        english: "Married but separated",
        value: "marriage_but_l",
    },
];

//education

//  age >= 4 and age <16
const isGoingToSchoolQn = {
    nepali: "हाल विद्यालय जानु हुन्छ कि हुन्न ?",
    english: "",
};

const isGoingToSchool = [
    {
        english: "Yes",
        nepali: "जानु हुन्छ",
        value: "yes_going",
    },
    {
        english: "No",
        nepali: "जानु हुन्न",
        value: "not_going",
    },
];

const notGoingSchoolQn = {
    nepali: "जानु हुन्न भने किन जानु हुन्न ?",
    english: "",
};

const notGoingSchool = [
    {
        nepali: "पढने खर्च नभएर",
        english: "",
        value: "unknown_about_importance_of_education",
    },
    {
        nepali: "अभिभावकले नपठाएर",
        english: "",
        value: "no_guidance_of_parents",
    },
    {
        nepali: "बिद्यालय टाडा भएर",
        english: "",
        value: "far_school",
    },
    {
        nepali: "विद्यालयमा कमजोर विद्यार्थीलाई ध्यान नदिएर",
        english: "",
        value: "weak_students",
    },
    {
        nepali: "नराम्रो साथीको संगतमा वा साथीको लहैलहैमा लागेर",
        english: "",
        value: "bad_friend",
    },
    {
        nepali: "घरको काम गर्नुपर्ने भएर",
        english: "",
        value: "house_work",
    },
    {
        nepali: "अन्य",
        english: "",
        value: "others",
    },
];
// close

const educationQuestion = { nepali: "को शिक्षा", english: "Education" };

const education = [
    {
        nepali: "वाल विकास कक्षा (१ कक्षा भन्दा कम)",
        english: "Child development class",
        value: "less_than_one",
    },
    {
        nepali: "आधारभूत तह  (कक्षा  १ देखि ८ कक्षा सम्म )",
        english: "Primary",
        value: "one_to_eight",
    },
    {
        nepali: "माध्यमिक तह (कक्षा ९ देखि १२ कक्षा सम्म )",
        english: "Secondary",
        value: "nine_to_twelve",
    },
    {
        nepali: "स्नातक तह",
        english: "bachelors",
        value: "bachelors",
    },
    {
        nepali: "स्नातकोत्तर  तह",
        english: "masters",
        value: "masters",
    },
    {
        nepali: "एमफिल",
        english: "mphil",
        value: "mphil",
    },
    {
        nepali: "विद्यावारिधी (पिएचडि)",
        english: "Phd",
        value: "phd",
    },
    {
        nepali: "प्राविधिक एस.एल.सी",
        english: "slc",
        value: "slc",
    },
    {
        nepali: "प्राविधिक डिप्लोमा",
        english: "Technical Diploma",
        value: "technical_diploma",
    },
    {
        nepali: "साधारण लेख पढ",
        english: "literate",
        value: "simple_litrate",
    },
];

const studyFieldQn = { nepali: "अध्ययनको क्षेत्रकुन हो ?", english: "" };

const studyField = [
    {
        nepali: "व्यवस्थापन",
        english: "",
        value: "management",
    },
    {
        nepali: "विज्ञान",
        english: "",
        value: "science	",
    },
    {
        nepali: "शिक्षा",
        english: "",
        value: "education",
    },
    {
        nepali: "इन्जिनियरिंग",
        english: "",
        value: "engineer",
    },
    {
        nepali: "डाक्टर",
        english: "",
        value: "doctor",
    },
    {
        nepali: "नर्स",
        english: "",
        value: "nurse",
    },
    {
        nepali: "कानुन",
        english: "",
        value: "wakil",
    },
    {
        nepali: "मानविकी",
        english: "",
        value: "manawiki",
    },
    {
        nepali: "कृषि",
        english: "",
        value: "agi",
    },
    {
        nepali: "सामाजिकशास्त्र",
        english: "",
        value: "social",
    },
    {
        nepali: "राजनीतिशास्त्र",
        english: "",
        value: "political_science",
    },
    {
        nepali: "सूचना प्रविधि",
        english: "",
        value: "computer",
    },
    {
        nepali: "होटल म्यानेजमेन्ट",
        english: "",
        value: "hotal_management",
    },
    {
        nepali: "वन",
        english: "",
        value: "forest",
    },
    {
        nepali: "पशुविज्ञान",
        english: "",
        value: "animal_science",
    },
    {
        nepali: "मत्स्यपालन",
        english: "",
        value: "fisheries",
    },
    {
        nepali: "पत्रकारिता",
        english: "",
        value: "newsrepoter",
    },
    {
        nepali: "अन्य",
        english: "",
        value: "other",
    },
];

//if पूर्व प्राथमिक is clicked

const preprimaryQuestion = {
    nepali: "पूर्व प्राथमिक कुन कक्षा?",
    english: "Pre primary education",
};

const preprimary = [
    {
        nepali: "१",
        english: "1",
        value: "class_1",
    },
    {
        nepali: "२",
        english: "2",
        value: "class_2",
    },
    {
        nepali: "३",
        english: "3",
        value: "class_3",
    },
    {
        nepali: "४",
        english: "4",
        value: "class_4",
    },
    {
        nepali: "५",
        english: "5",
        value: "class_5",
    },
];

//if आधारभूत तह is clicked

const primaryQuestion = { nepali: "आधारभूत तह कुन कक्षा?", english: "Primary" };

const primary = [
    {
        nepali: "१",
        english: "1",
        value: "one",
    },
    {
        nepali: "२",
        english: "2",
        value: "two",
    },
    {
        nepali: "३",
        english: "3",
        value: "three_1",
    },
    {
        nepali: "४",
        english: "4",
        value: "four_1",
    },
    {
        nepali: "५",
        english: "5",
        value: "five_1",
    },
    {
        nepali: "६",
        english: "6",
        value: "six_1",
    },
    {
        nepali: "७",
        english: "7",
        value: "seven",
    },
    {
        nepali: "८",
        english: "8",
        value: "eight",
    },
];

//if माध्यमिक तह is clicked

const secondaryQuestion = {
    nepali: "माध्यमिक तह कुन कक्षा?",
    english: "Secondary education",
};

const secondary = [
    {
        nepali: "९",
        english: "9",
        value: "9",
    },
    {
        nepali: "१०",
        english: "10",
        value: "10",
    },
    {
        nepali: "११",
        english: "11",
        value: "11",
    },
    {
        nepali: "१२",
        english: "12",
        value: "12",
    },
];

//occupation

const occupationQuestion = { nepali: "को पेशा", english: "Ocupation" };

const occupation = [
    {
        nepali: "कृषि तथा पशुपालन",
        english: "Agriculture",
        value: "agriculture",
    },
    // {
    //     nepali: "कृषि तथा पशुपालन (व्यावसाहिक )",
    //     english: "Agriculture",
    //     value: "agriculture_animal_business",
    // },
    {
        nepali: "नोकरी /जागिर",
        english: "Job",
        value: "job",
    },
    {
        nepali: "उद्योग  तथा व्यापार",
        english: "Industry",
        value: "industry",
    },
    {
        nepali: "वेरोजगार",
        english: "Unemployment",
        value: "unemployment",
    },
    {
        nepali: "ज्याला /मजदुरी",
        english: "Lbor",
        value: "labor",
    },
    {
        nepali: "विद्यार्थी (अध्यनरत)",
        english: "Student",
        value: "student",
    },
    {
        nepali: "गृहणी",
        english: "House wife",
        value: "house_wife",
    },
    {
        nepali: "वैदेशिक रोजगारी",
        english: "Foreign job",
        value: "foreign_job",
    },
    {
        nepali: "अन्य",
        english: "Other job",
        value: "other_job",
    },
];

//if नोकरी \ जागिर is clicked

const jobQuestion = { nepali: "को नोकरी  जागिर", english: "Job" };

const job = [
    {
        nepali: "गीटि कुट्ने",
        english: "stone beating",
        value: "stone_beating",
    },
    {
        nepali: "भारी बोक्ने",
        english: "carry load",
        value: "carry_load",
    },
    {
        nepali: "कृषि मजदुर",
        english: "agri_labour",
        value: "agri_labour",
    },
    {
        nepali: "सिकर्मी /डकर्मी",
        english: "sikarmi",
        value: "sikarmi",
    },
    {
        nepali: "खोलामा माछामार्ने",
        english: "fishing",
        value: "fishing",
    },
    {
        nepali: "पर्यटक घुमाउने",
        english: "tourist ghumaaune",
        value: "tourist_ghumaaune",
    },
    {
        nepali: "बालुवा चाल्ने",
        english: "sand refining",
        value: "sand_refining",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other_labor",
    },
];

//if सुरक्षा निकाय is clicked

const securityQuestion = {
    nepali: "कुन सुरक्षा निकाय?",
    english: "Which security depart?",
};

const security = [
    {
        nepali: "नेपाल प्रहरी",
        english: "Nepal police",
        value: "nepal_police",
    },
    {
        nepali: "सशस्त्र प्रहरी",
        english: "APF",
        value: "a_p_f",
    },
    {
        nepali: "नेपाली सेना",
        english: "Nepal army",
        value: "nepal_army",
    },
];

//if शिक्षक is clicked

const teacherQuestion = { nepali: "कुन शिक्षक?", english: "Which teacher?" };

const teacher = [
    {
        nepali: "स्थानीय श्रोत",
        english: "Local source",
        value: "local",
    },
    {
        nepali: "सामुदायिक",
        english: "Government",
        value: "government",
    },
    {
        nepali: "राहत",
        english: "Rahat",
        value: "rahat",
    },
];

//if व्यवसायिक कार्य is clicked

const industryQuestion = {
    nepali: "को व्यवसायिक कार्य",
    english: "Business work",
};

const industry = [
    {
        nepali: "पत्रकार",
        english: "Journalist",
        value: "journalist",
    },
    {
        nepali: "वकिल",
        english: "Advocate",
        value: "lawer",
    },
    {
        nepali: "परामर्श",
        english: "Counsulting",
        value: "counselling",
    },
    {
        nepali: "ठेक्का  पट्टा",
        english: "Contract",
        value: "contract",
    },
    {
        nepali: "पुजारी",
        english: "Priest",
        value: "priest",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

//healthcondition

const healthconditionQuesiton = {
    nepali: "को स्वास्थ्य स्थिति",
    english: "Health condition",
};

const healthcondition = [
    {
        nepali: "स्वास्थ्य",
        english: "Health",
        value: "helathy",
    },
    {
        nepali: "सामान्य रोगी",
        english: "Normal diseases patient",
        value: "normal_patient",
    },
    {
        nepali: "दिर्घ रोग लागेको",
        english: "Unhealthy",
        value: "unhelathy",
    },
    // {
    // 	nepali: "मानसिक समस्या भएको",
    // 	english: "Mental",
    // 	value: "mental"
    // },
    // {
    // 	nepali: "क्यान्सर रोग",
    // 	english: "Cancer",
    // 	value: "cancer"
    // },
    // {
    // 	nepali: "कुष्ठ रोग",
    // 	english: "Tuberclosis",
    // 	value: "tuberclosis"
    // },
    // {
    // 	nepali: "HIV AIDS",
    // 	english: "HIV AIDS",
    // 	value: "hiv"
    // }
];

//if दिर्घ रोग लागेको clicked

const unhealthyQuestion = {
    nepali: "दिर्घ रोग लागेको छ भने ? कुन किसिम को ?",
    english: "",
};

const unhealthy = [
    {
        nepali: "उच्च रक्तचाप / ब्लड प्रेसर",
        english: "High blood pressure",
        value: "high_blood_pre",
    },
    {
        nepali: "मुटु  सम्बन्धी रोग",
        english: "Heart diseases",
        value: "heart",
    },
    {
        nepali: "मधुमेह",
        english: "Diabetes",
        value: "diabetes",
    },
    {
        nepali: "आस्थमा/दम",
        english: "Aasthama",
        value: "aasthama",
    },
    {
        nepali: "मृगौला सम्बन्धी  रोग",
        english: "Liver related",
        value: "liver",
    },
    {
        nepali: "HIV AIDS",
        english: "HIV AIDS",
        value: "hiv",
    },

    {
        nepali: "हाडजोर्नी तथा नसा सम्बन्धि रोग (बाथ रोग )",
        english: "Bornjoint",
        value: "bornjoint",
    },
    {
        nepali: "क्षारे रोग",
        english: "Ipilepsi",
        value: "ipilepsi",
    },
    {
        nepali: "कडा खालको मानसिक रोग (मस्तिस्क)",
        english: "Manasik",
        value: "manasik",
    },

    {
        nepali: "क्यान्सर",
        english: "Cancer",
        value: "cancer",
    },
    {
        nepali: "थाइराईड रोग",
        english: "Thaairoid",
        value: "thaairoid",
    },
    {
        nepali: "पाठेघर झर्ने खस्ने रोग",
        english: "patheghar khasne",
        value: "patheghar_khasne",
    },
    {
        nepali: "अल्सर /आन्द्राको रोग",
        english: "alsar",
        value: "alsar",
    },
    {
        nepali: "अल्जाईमर्स",
        english: "aljaaimars",
        value: "aljaaimars",
    },
    {
        nepali: "हात्तीपाइले",
        english: "hattipaile",
        value: "hattipaile",
    },
    {
        nepali: "निम्न रक्तचाप",
        english: "low pressure",
        value: "low_pressure",
    },
    {
        nepali: "कुष्ठरोग",
        english: "leprosy",
        value: "leprosy",
    },
    {
        nepali: "पार्किन्सस",
        english: "paarkinsas",
        value: "paarkinsas",
    },
    {
        nepali: "अन्य",
        english: "Other_disease",
        value: "other_disease",
    },
];

const mentalYesNoQuestion = { nepali: "को अपांगता", english: "Disabled" };

const mentalyesno = [
    {
        nepali: "छ",
        english: "yes",
        value: "disability",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no_disability",
    },
];

const mentaltypeQuestion = {
    nepali: "अपांगताको किसिम?",
    english: "Types of disablility",
};

//if mental छ is clicked
const mentaltype = [
    {
        nepali: "जन्मजात",
        english: "By birth",
        value: "by_birth",
    },
    {
        nepali: "रोग लागेर",
        english: "Diseases",
        value: "disease",
    },
    {
        nepali: "दुर्घटना",
        english: "Accident",
        value: "accident",
    },
    {
        nepali: "अन्य",
        english: "Other Reason",
        value: "other_disable",
    },
];

const disabilitytypeQuestion = {
    nepali: "अपांगता हुनुको कारण?",
    english: "Reason of disablility",
};

//if mental छ is clicked
const disabilitytype = [
    { value: "disability", nepali: "शारिरिक अपाङ्गता", english: "" },
    { value: "aged_mental", nepali: "बौद्धिक अपाङ्गता", english: "" },
    {
        value: "vision",
        nepali: "दृष्टिविहीन अपाङ्गता (आखाँ नदेख्ने)",
        english: "",
    },
    { value: "speak", nepali: "बोल्न तथा सुन्न नसक्ने  (बहिरा)", english: "" },
    {
        value: "bhakbhake",
        nepali: "स्वर वोलाई सम्बन्धी  अपाङ्गता (भकभके)",
        english: "",
    },
    { value: "hear_vision", nepali: "श्रवण दृष्टिविहिन अपाङ्गता", english: "" },
    { value: "mental", nepali: "मानसिक वा मनोसामाजिक अपाङ्गता", english: "" },
    { value: "autism", nepali: "अटिज्म सम्बन्धी", english: "" },
    {
        value: "hereditary_hemorrhagic",
        nepali: "अनुवंशीय रक्तश्राव सम्बन्धी अपाङ्गता",
        english: "",
    },
    { value: "many_disable", nepali: "बहु अपांगता", english: "" },
];

const birthCertificateQn = {
    nepali: "पाइले जन्म दर्ता गर्नु भएको छ कि छैन ?",
    english: "",
};

const lessThanSixBirthCertificate = [
    {
        nepali: "छ",
        english: "",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "",
        value: "no",
    },
];

//if mental छ is clicked
const mentalconditionQuestion = {
    nepali: "को अपांगताको स्थिति",
    english: "Condition of disability",
};

const mentalcondition = [
    {
        nepali: "शारिरिक अपाङ्गता",
        english: "Physical Disability",
        value: "disability",
    },
    {
        nepali: "बौद्धिक अपाङ्गता",
        english: "Intellectual disability",
        value: "aged_mental",
    },
    {
        nepali: "पूर्ण  ष्टिविहीन  (आखाँ नदेख्ने)",
        english: "Blind Disability",
        value: "vision",
    },
    {
        nepali: "न्यून दृष्टिबिहिन",
        english: "less power",
        value: "less_power",
    },
    {
        nepali: "बहिरापन",
        english: "wahira",
        value: "wahira",
    },
    {
        nepali: "स्वर र  वोलाई (भकभके)",
        english: "Vocal Disability",
        value: "bhakbhake",
    },
    {
        nepali: "श्रवण दृष्टिविहिन अपाङ्गता",
        english: "Hearing impaired blindness",
        value: "hear_vision",
    },
    {
        nepali: "मानसिक वा मनोसामाजिक अपाङ्गता",
        english: "Mental or psychosocial disability",
        value: "mental",
    },
    {
        nepali: "अटिज्म सम्बन्धी",
        english: "Autism",
        value: "autism",
    },
    {
        nepali: "अनुवंशीय रक्तश्राव सम्बन्धी अपाङ्गता",
        english: "Genetic bleeding related disability",
        value: "hereditary_hemorrhagic",
    },
    {
        nepali: "बहु अपांगता",
        english: "Multiple disabilities",
        value: "many_disable",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

//को अपांगताको कार्ड दिएको छ कि छैन?
const mentalCardQuestion = {
    nepali: "को अपांगता परिचय पत्र  छ कि छैन?",
    english: "Do you have disability card?",
};

const mentalCardYesNo = [
    {
        nepali: "छ",
        english: "yes",
        value: "card_taken",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "card_not_taken",
    },
];
//if xa vaney KUN CARD XA ?
const mentalCardTypeQuestion = {
    english: "If yes",
    nepali: "अपांगता परिचय पत्र छ भने कस्तो प्रकारको छ",
};
const mentalCardtype = [
    {
        nepali: "रातो  रंगको परिचय पत्र (क वर्ग)",
        english: "Red card",
        value: "red_card",
    },
    {
        nepali: "निलो रंगको परिचय पत्र (ख वर्ग)",
        english: "Blue card",
        value: "blue_card",
    },
    {
        nepali: "पहेलो  रंगको परिचय पत्र (ग वर्ग)",
        english: "Yellow card",
        value: "yellow_card",
    },
    {
        nepali: "सेतो रंगको परिचय पत्र (घ वर्ग)",
        english: "White card",
        value: "white_card",
    },
];
//mother tongue

const mothertongueQuestion = {
    nepali: "को मातृभाषा / बोलिने भाषा ?",
    english: "Mother tongue",
};

const mothertongue = [
    {
        nepali: "नेपाली",
        english: "Nepali",
        value: "nepali",
    },
    {
        nepali: "तामाङ",
        english: "tamang",
        value: "tamang",
    },
    {
        nepali: "राई",
        english: "lhowa",
        value: "lhowa",
    },
    {
        nepali: "मगर",
        english: "magar",
        value: "magar",
    },
    {
        nepali: "मैथिली",
        english: "maithili",
        value: "maithili",
    },
    {
        nepali: "लिम्बु",
        english: "limbu",
        value: "limbu",
    },
    {
        nepali: "शेर्पा",
        english: "sherpa",
        value: "sherpa",
    },
    {
        nepali: "नेवारी",
        english: "newari",
        value: "newari",
    },
    {
        nepali: "गुरुङ",
        english: "gurung",
        value: "gurung",
    },
    {
        nepali: "पहरी",
        english: "pahari",
        value: "pahari",
    },
    {
        nepali: "सांकेतिक भाषा",
        english: "sanketik",
        value: "sanketik",
    },
    {
        nepali: "चौधरी",
        english: "chaudhari",
        value: "chaudhari",
    },
    {
        nepali: "थारु",
        english: "tharu",
        value: "tharu",
    },
    {
        nepali: "राजवंशी",
        english: "rajbanshi",
        value: "rajbanshi",
    },
    {
        nepali: "डोटेली",
        english: "doteli",
        value: "doteli",
    },
    {
        nepali: "भोजपुरी",
        english: "bhojpuri",
        value: "bhojpuri",
    },
    {
        nepali: "हिन्दी",
        english: "hindi",
        value: "hindi",
    },
    {
        nepali: "सन्थली",
        english: "santhali",
        value: "santhali",
    },
    {
        nepali: "चेपाङ",
        english: "chepaang",
        value: "chepaang",
    },
    {
        nepali: "दनुवार",
        english: "danuwaar",
        value: "danuwaar",
    },
    {
        nepali: "सुनुवार",
        english: "sunuwaar",
        value: "sunuwaar",
    },
    {
        nepali: "मुसलमान",
        english: "musalmaan",
        value: "musalmaan",
    },
    {
        nepali: "कोइ",
        english: "koi",
        value: "koi",
    },
    {
        nepali: "किसान",
        english: "kisan",
        value: "kisan",
    },
    {
        nepali: "बनकरिया",
        english: "bankariya",
        value: "bankariya",
    },
    {
        nepali: "गनगाइ",
        english: "gangaai",
        value: "gangaai",
    },
    {
        nepali: "ताजपुरिया",
        english: "taajpuriya",
        value: "taajpuriya",
    },
    {
        nepali: "अवरी",
        english: "awari",
        value: "awari",
    },
    {
        nepali: "वान्तवा",
        english: "waantwa",
        value: "waantwa",
    },
    {
        nepali: "उरन्वा",
        english: "uranwaa",
        value: "uranwaa",
    },
    {
        nepali: "भुजेल",
        english: "bhujel",
        value: "bhujel",
    },
    {
        nepali: "चाम्लीङ",
        english: "chamling",
        value: "chamling",
    },
    {
        nepali: "धिमाल",
        english: "dhimaal",
        value: "dhimaal",
    },
    {
        nepali: "बङग्ला",
        english: "baanglaa",
        value: "baanglaa",
    },
    {
        nepali: "अन्य",
        english: "Other language",
        value: "other_language",
    },
];

// if राई is clicked

const raiQuestion = { nepali: "राई भाषा", english: "Rai language" };

const raiType = [
    {
        nepali: "बान्तवा",
        english: "Bantawa",
        value: "bantawa",
    },
    {
        nepali: "खालिंङ",
        english: "Khaling",
        value: "khaling",
    },
    {
        nepali: "थुलुंग",
        english: "Thulung",
        value: "thulung",
    },
    {
        nepali: "कुलुंग",
        english: "Kulung",
        value: "kulung",
    },
    {
        nepali: "याक्खा",
        english: "Yakha",
        value: "yakha",
    },
    {
        nepali: "चामलिंङ",
        english: "Chamling",
        value: "chamling",
    },
    {
        nepali: "वादीङ",
        english: "Wading",
        value: "wading",
    },
    {
        nepali: "तिलुंङ",
        english: "Tilung",
        value: "tilung",
    },
    {
        nepali: "वाम्वुले",
        english: "Bambule",
        value: "bambule",
    },
];

//bloodgroup

const bloodgroupQuestion = { nepali: "को रगत समुह", english: "Blood group" };

const bloodgroup = [
    {
        nepali: "O+",
        english: "O+",
        value: "o_positive",
    },
    {
        nepali: "O-",
        english: "O-",
        value: "o_negative",
    },
    {
        nepali: "A+",
        english: "A+",
        value: "a_positive",
    },
    {
        nepali: "A-",
        english: "A-",
        value: "a_neagtive",
    },
    {
        nepali: "B+",
        english: "B+",
        value: "b_positive",
    },
    {
        nepali: "B-",
        english: "B-",
        value: "b_negative",
    },
    {
        nepali: "AB+",
        english: "AB+",
        value: "ab_positive",
    },
    {
        nepali: "AB-",
        english: "AB-",
        value: "ab_neagtive",
    },
    {
        nepali: "थाहा छैन",
        english: "Donot know",
        value: "no_blood_group",
    },
];

// voter card

const votercardQuestion = {
    nepali: "को मतदाता परिचय पत्र छ /छैन ?",
    english: "Voter card yes / no",
};

const votercard = [
    {
        nepali: "छ",
        english: "yes",
        value: "voter_id_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "voter_id_no",
    },
];
const stepperStep = [
    {
        english: "House Hold",
        nepali: "घरधुरी विवरण",
    },
    {
        english: "Family detail",
        nepali: "परिवार विवरण",
    },
    {
        english: "Death",
        nepali: "मृत्यु",
    },
    {
        english: "Secondary house detail",
        nepali: "माध्यमिक घर विवरण",
    },
    {
        english: "Primary house detail",
        nepali: "प्रथामिक घर विवरण",
    },
    {
        english: "Agriculture",
        nepali: "कृषी तथा पशु",
    },
    {
        english: "Economic",
        nepali: "आर्थिक",
    },
    {
        english: "Child Marriage Related",
        nepali: "बाल बिवाह सम्बन्धि",
    },
    {
        english: "Women and children",
        nepali: "महिला तथा बालबालिका",
    },
];

const detectCurrentLocation = {
    nepali: "हालको स्थान पत्ता लगाउनुहोस्",
    english: "Detect current location",
};

// child Marriage starts
const isChildMarriageInFamilyQn = {
    nepali:
        "तपाइको परिवारमा बिगत ३ बर्ष भित्र छोरीको विवाह भएको छ? (२५ बर्ष भन्दा तलको उल्लेख गर्ने )",
    english: "",
};
const isChildMarriageInFamily = [
    {
        nepali: "छ",
        english: "",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "",
        value: "no",
    },
];

const childMarriageCount = {
    nepali: "कति जनाको विवाह भएको छ ?",
    english: "",
};

const childMartialStatusQn = {
    nepali: "बैबाहिक अवस्था कस्तो छ ?",
    english: "",
};

const childMartialStatus = [
    {
        nepali: "विवाहित",
        english: "",
        value: "Marriage",
    },
    {
        nepali: "छुट्टिएको",
        english: "",
        value: "seperate",
    },
    {
        nepali: "विधुर /विधुवा",
        english: "",
        value: "widow",
    },
    {
        nepali: "एकल",
        english: "",
        value: "single",
    },
    {
        nepali: "पारपाचुके भएको",
        english: "",
        value: "devorce",
    },
];

const childMarriageAge = {
    nepali: "बिवाहा हुदाको उमेर",
    english: "",
};

const childMarriageTypeQn = {
    nepali: "बिवाहाको प्रकार",
    english: "",
};

const childMarriageType = [
    {
        nepali: "मागी बिवाह",
        english: "",
        value: "arrange",
    },
    {
        nepali: "भागि बिवाह",
        english: "",
        value: "elloped",
    },
    {
        nepali: "प्रेम बिवाह",
        english: "",
        value: "love_marriage",
    },
    {
        nepali: "अन्य",
        english: "",
        value: "other",
    },
];

const childMarriageEducationQn = {
    nepali: "शैक्षिकस्तर",
    english: "",
};

const childMarriageEducation = [
    {
        nepali: "साक्षर",
        english: "",
        value: "litrate",
    },
    {
        nepali: "निरक्षर",
        english: "",
        value: "uneducate",
    },
    {
        nepali: "वाल विकास",
        english: "",
        value: "primary_education",
    },
    {
        nepali: "आधारभूत",
        english: "",
        value: "basic",
    },
    {
        nepali: "माध्यमिक",
        english: "",
        value: "secondary",
    },
    {
        nepali: "स्नातक",
        english: "",
        value: "graduate",
    },
    {
        nepali: "स्नातक माथी",
        english: "",
        value: "above_graduate",
    },
];

const childMarriageOccupationQn = {
    nepali: "पेशा",
    english: "",
};

const childMarriageOccupation = [
    {
        nepali: "कृषि तथा पशुपालन",
        english: "",
        value: "agriculture",
    },
    {
        nepali: "गृहणी",
        english: "",
        value: "housewife",
    },
    {
        nepali: "ब्यापार व्यावसाय",
        english: "",
        value: "business",
    },
    {
        nepali: "नकारी जागिर",
        english: "",
        value: "employee",
    },
    {
        nepali: "बेरोजगार",
        english: "",
        value: "unemployee",
    },
    {
        nepali: "अन्य",
        english: "",
        value: "other",
    },
];

const childMarriageKidQn = {
    nepali: "के उंहाको बच्चा छ ?",
    english: "",
};

const childMarriageKid = [
    {
        nepali: "छ",
        english: "",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "",
        value: "no",
    },
];

// ends

// Economics

const mainIncomeSourceQuestion = {
    nepali: "तपाइको परिवारको सरदर वार्षिक आम्दानीको मुख्य स्रोत के हो ?",
    english: "What is the income source of your family?",
};

const mainIncomeSource = [
    {
        nepali: "कृषि",
        english: "Agriculture activities",
        value: "agriculture_1",
    },
    {
        nepali: "ब्यापार ब्यावसय",
        english: "Trade",
        value: "trade",
    },
    {
        nepali: "नोकरी जागिर",
        english: "Job",
        value: "job",
    },
    {
        nepali: "पेन्सन",
        english: "Pension",
        value: "pension",
    },
    {
        nepali: "पशु पन्छी पालन",
        english: "livestock",
        value: "livestock_1",
    },
    {
        nepali: "ठेक्का पट्टा ",
        english: "Contract",
        value: "contract",
    },
    {
        nepali: "पर्यटन व्यवसाय",
        english: "Tourism",
        value: "tourism",
    },

    {
        nepali: "बैदेशिक विप्रेषण",
        english: "Remitance",
        value: "foreign_remittances",
    },
    {
        nepali: "आन्तरिक विप्रेषण",
        english: "Remittances",
        value: "remittances_1",
    },
    {
        nepali: "ज्याला / मजदुरी",
        english: "Daily labor",
        value: "wages",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

const totalIncome = {
    nepali: "तपाई को परिवारको सरदर वार्षिक आम्दानी कति छ?",
    english: "How much is the yearly income of your family? ",
    value: "",
};

const totalExpenditure = {
    nepali: "तपाई को परिवारको सरदर वार्षिक खर्च कति छ?",
    english: "How much is the yearly expenditure?",
    value: "",
};

const foodForMonthQuestion = {
    nepali: "आफ्नो उत्पादनले तपाई को परिवारलाई कति महिना खान पुग्छ ?",
    english: "How ",
};

const foodForMonth = [
    {
        nepali: "३ महिनासम्म",
        english: "3 month",
        value: "3_month",
    },
    {
        nepali: "४ देखि ६ महिनासम्म",
        english: "4-6 month",
        value: "4_6_month",
    },
    {
        nepali: "७ देखि ९ महिनासम्म",
        english: "7-9 month",
        value: "7_9_month",
    },
    {
        nepali: "९ महिना भन्दा बढी",
        english: "9 month above",
        value: "9_month_above",
    },
];

//if ३ महिना && ४ देखि ६ महिना && ७ देखि ९ महिना सम्म is clicked

const foodAlternateQuestion = {
    nepali:
        "नपुग आवादीको लागि परिवार धान्न कसरी व्वस्था म्लाव्नु भयो? (विगत १ वर्षमा)",
    english: "",
};

const foodAlternate = [
    {
        nepali: "संचित घनको प्रयोग गरेको",
        english: "Saving amount",
        value: "saving_amount",
    },
    {
        nepali: "पैचो सापटी लिएको",
        english: "Borrow amount",
        value: "borrow_amount",
    },
    {
        nepali: "ऋण लिएको",
        english: "Loan amount",
        value: "loan_amount",
    },
    {
        nepali: "चल-अचल सम्पति विक्री गरेको",
        english: "Movable imovable property",
        value: "im_imovable_pr",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

const loanTakenInFamilyQuestion = {
    nepali: "तपाईको परिवारले ऋण लिनु भएको छ?",
    english: "Do you have taken loan?",
};

const loanTakenInFamily = [
    {
        nepali: "छ",
        english: "yes",
        value: "loan_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "loan_no",
    },
];

const loanQuestion = {
    nepali: "ऋण लुनु भएको छ भने",
    english: "If you have taken loan",
};

// if loantakeninfamily छ is clicked
const loan = [
    {
        nepali: "बैंक बाट",
        english: "Loan from bank",
        value: "loan_bank",
    },
    {
        nepali: "व्यक्ति बाट",
        english: "Loan from person",
        value: "loan_person",
    },
    {
        nepali: "सहकारी बाट",
        english: "Loan from finance",
        value: "loan_sahakari",
    },
    {
        nepali: "लघुबित्त बाट",
        english: "Loan from lagubitta",
        value: "loan_laghubitt",
    },
    {
        nepali: "कृषक समुह",
        english: "Loan from farmer group",
        value: "loan_farmer",
    },
    {
        nepali: "महिला समुह",
        english: "Loan from women group",
        value: "loan_women",
    },
    {
        nepali: "अन्य",
        english: "loan Other",
        value: "loan_other",
    },
];

//if बैंक बाट is checked
const rate = [
    {
        nepali: "बैंकको व्याज दर कति प्रतिशत (%) छ ?",
        english: "What is the interest rate of bank?",
        value: "",
    },
    {
        nepali: "व्यक्तिको व्याज दर कति प्रतिशत (%) छ ?",
        english: "What is the interest rate of loan from person?",
        value: "",
    },
    {
        nepali: "सहकारीको व्याज दर कति प्रतिशत (%) छ ?",
        english: "What is the interest rate of loan from sahakari?",
        value: "",
    },
    {
        nepali: "लघुबित्तको व्याज दर कति प्रतिशत (%) छ ?",
        english: "What is the interest rate of loan from laghubitta",
        value: "",
    },
    {
        nepali: "कृषकको व्याज दर कति प्रतिशत (%) छ ?",
        english: "What is the interest rate of loan from farmer group?",
        value: "",
    },
    {
        nepali: "महिला समुहको व्याज दर कति प्रतिशत (%) छ ?",
        english: "What is the interest rate of loan from women group?",
        value: "",
    },
    {
        nepali: "अन्य व्याज दर कति प्रतिशत (%) छ ?",
        english: "What is the interest rate of loan from other?",
        value: "",
    },
];
const bankrate = {
    nepali: "बैंकको व्याज दर कति प्रतिशत (%) छ ?",
    english: "What is the interest rate of bank?",
    value: "",
};

//if व्यक्ति बाट is checked
const personrate = {
    nepali: "व्यक्तिको व्याज दर कति प्रतिशत (%) छ ?",
    english: "What is the interest rate of loan from person?",
    value: "",
};

//if सहकारी बाट is checked
const sahakarirate = {
    nepali: "सहकारीको व्याज दर कति प्रतिशत (%) छ ?",
    english: "What is the interest rate of loan from sahakari?",
    value: "",
};

//if लघुबित्त बाट is checked
const laghubittrate = {
    nepali: "लघुबित्तको व्याज दर कति प्रतिशत (%) छ ?",
    english: "What is the interest rate of loan from lagubitta?",
    value: "",
};

// if कृषक समुह is checked
const farmerrate = {
    nepali: "कृषकको व्याज दर कति प्रतिशत (%) छ ?",
    english: "What is the interest rate for farmer?",
    value: "",
};

//if महिला समुह is checked
const femalerate = {
    nepali: "महिला समुहको व्याज दर कति प्रतिशत (%) छ ?",
    english: "What is the interest of women group?",
    value: "",
};

//if अन्य is checeked
const otherrate = {
    nepali: "अन्य व्याज दर कति प्रतिशत (%) छ ?",
    english: "What is the interest rate of other?",
    value: "",
};

const loanTakingReasonQuestion = {
    nepali: "ऋण लिएर के मा खर्च वा लगानी गर्नु भएको छ",
    english: "What is the reason for taking loan?",
    value: "",
};

const loanTakingReason = [
    {
        nepali: "व्यापार व्यावसय  गर्न",
        english: "Business",
        value: "loan_business",
    },
    {
        nepali: "छोरा /छोरी पढाउन",
        english: "Education",
        value: "loan_education",
    },
    {
        nepali: "स्वस्थ उपचार गर्न",
        english: "Health",
        value: "loan_health",
    },
    {
        nepali: "कृषि तथा पशुपालन गर्न",
        english: "Agriculture",
        value: "loan_agricultu",
    },
    {
        nepali: "दैनिक घर खर्च",
        english: "For house hold purpose",
        value: "loan_house",
    },
    {
        nepali: "बिबाह / ब्रतबन्धका लागी",
        english: "Birth / death / ceremony",
        value: "loan_rituals",
    },
    {
        nepali: "घर निर्माणका लागी",
        english: "For building house",
        value: "loan_building",
    },
    {
        nepali: "जग्गा किन्न का लागि",
        english: "To buy land",
        value: "loan_land",
    },
    {
        nepali: "विदेश जानको लागि",
        english: "For ABroad Study",
        value: "outside_countr",
    },
    {
        nepali: "अन्य",
        english: "Loan Other",
        value: "loan_other",
    },
];

const dhukutiKarobar = {
    nepali: "तपाइको परिवारको ढुकुटी संग कारोवार छ कि छैन ?",
    english: "",
};

const dhukutiKarobarYesNo = [
    {
        nepali: "छ",
        english: "Yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "no",
    },
];

const dhukutiKarobarCount = {
    nepali: "यदि छ भने कति ?",
    english: "",
};

const cashLoanGivenQuestion = {
    nepali: "तपाईको परिवारबाट वा तपाई बाट कसैलाइ नगद ऋण दिनु भएको छ?",
    english: "Do anyone in your family give loan to other?",
};

const cashLoanGiven = [
    {
        nepali: "छ",
        english: "yes",
        value: "cash_loan_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "cash_loan_no",
    },
];

const cashLoanRate = {
    nepali: "छ भने कति प्रतिशत (%) व्याज दरमा दिनु भएको छ ?",
    english: "If yes mention the interest rate?",
    value: "",
};
const mainInvestmentQn = {
    nepali: "आम्दानीको मुख्य अंश कुन क्षेत्रमा लगानी गर्नुहुन्छ? ",
    english: "",
};
const mainInvestment = [
    { value: "loan", nepali: "ऋण दिएको", english: "" },
    { value: "house_invest", nepali: "घर खर्च ", english: "" },
    { value: "share", nepali: "शेयर", english: "" },
    { value: "business", nepali: "व्यापार", english: "" },
    { value: "bank", nepali: "बैंकमा राखिएको", english: "" },
    { value: "education", nepali: "शिक्षा", english: "" },
    { value: "health", nepali: "स्वास्थ्य", english: "" },
    { value: "land", nepali: "जग्गा खरिद", english: "" },
    { value: "entertainment", nepali: "मनोरंजन", english: "" },

    { value: "other_invest", nepali: "अन्य", english: "" },
];

const mainBusinessQn = {
    nepali:
        "तपाईको परिवारले कृषिबाहेक कुनै मुख्य साना घरेलु व्यावसाय चलाएको छ ? (दर्ता नगरिएका र तलवी कामदार नभएका समेत)",
    english: "",
};
const mainBusiness = [
    { value: "other_businesss", nepali: "छ", english: "" },
    { value: "no_business", nepali: "छैन", english: "" },
];
const electricityUsageQn = {
    nepali:
        "तपाईले विधुतीय माध्यमको प्रयोग  गरेर कुन कुन सेवा सुबिधाहरुको भुक्तानी गरि रहनु भएको छ ? ",
    english: "",
};
const electricityUsageYesNo = [
    { value: "esewa_yes", nepali: "छ", engish: "" },
    { value: "esewa_no", nepali: "छैन", engish: "" },
];
const electricityUsageYesQn = {
    nepali:
        "छ भने तपाईले विधुतीय माध्यमको प्रयोग गरेर कुन कुन सेवा सुबिधाहरुको भुक्तानी गरि रहनु भएको छ ?  ",
    english: "",
};

const electricityUsage = [
    { value: "water", nepali: "खानेपानी महशुल", english: "" },
    { value: "electric", nepali: "बिधुत महशुल", english: "" },
    { value: "internet", nepali: "इन्टरनेट महशुल", english: "" },
    { value: "telecom", nepali: "टेलिकम महशुल", english: "" },
    { value: "cable", nepali: "केवल महशुल", english: "" },
    { value: "other_esewa", nepali: "अन्य", english: "" },
];

//house detail discrimination
const discriminationQuestion = {
    nepali:
        "तपाईको बिचारमा यस स्थानमा जनजाती लिंग वा समुदाय विशेष भएको आधारमा भेदभावको अनुभव गर्नु भएको छ?",
    english:
        "In your view is there any discrimination on the base of gender,caste in your society?",
};

const discrimination = [
    {
        nepali: "छ",
        english: "Yes",
        value: "inequality_yes",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "inequality_no",
    },
    {
        nepali: "थाहा छैन",
        english: "Donot know",
        value: "not_know_inequ",
    },
];

//skillls

const skillTrainingQuestion = {
    nepali: "तपाईले शिपमुलक तालिम वा कुनै शिप जान्नु हुन्छ ?",
    english: "Do you have any skill?",
};

const skillTraining = [
    {
        nepali: "छ",
        english: "Yes",
        value: "skill_yes",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "skill_no",
    },
];

//if skilltraining छ is clicked

const skillDetailQuestion = {
    nepali: "शिपमुलक तालिम छ भने",
    english: "If yes",
};

const skillDetail = [
    {
        nepali: "कृषि प्राविधिक तालिम तथा अध्ययन  (जेटिए , खाध्य प्रसोधन आदि )",
        english: "Agriculture",
        value: "agri",
    },
    {
        nepali: "निर्माण सम्बन्धी  सिकर्मी / डकर्मी",
        english: "",
        value: "construction",
    },
    {
        nepali: "प्लम्बर",
        english: "",
        value: "plumber",
    },
    {
        nepali: "सिलाई,बुनाई,बुटिक,सृंगर,पार्लर आदि",
        english: "knitting",
        value: "sewing",
    },
    {
        nepali:
            "सूचना तथा प्रविधि (इलेक्ट्रोनिक कम्प्युटर/मोबाइल/रेडियो घडी आदि मर्मत)",
        english: "IT",
        value: "it",
    },
    {
        nepali: "पर्यटन टुर गाइड , ट्राभल र सत्कार",
        english: "Tourism",
        value: "tourism_guide",
    },
    {
        nepali: "होटल सम्बन्धि",
        english: "",
        value: "hotel",
    },
    {
        nepali: "इलेक्ट्रिकसियन",
        english: "Electrician",
        value: "electrician",
    },
    {
        nepali: "पेंटिंङ",
        english: "",
        value: "painting",
    },
    {
        nepali: "इन्जीनियरिङ्,अटो मोबाइल  र मेकानिक्स",
        english: "",
        value: "engineering",
    },
    {
        nepali: "जनस्वास्थ्य सम्बन्धी",
        english: "",
        value: "public_health",
    },
    {
        nepali: "पशु स्वास्थ्य सम्बन्धी",
        english: "",
        value: "animal_health",
    },
    {
        nepali: "वन सम्बन्धी",
        english: "Forest",
        value: "forest",
    },
    {
        nepali: "कला सम्बन्धी",
        english: "Art",
        value: "art",
    },
    {
        nepali: "कानुनी साक्षरता",
        english: "law",
        value: "law",
    },
    {
        nepali: "विपदब्यबस्थापन सम्बन्धि",
        english: "disaster",
        value: "disaster",
    },
    {
        nepali: "अन्य",
        english: "other",
        value: "other",
    },
];

//if निर्माण सम्बन्धी(मिस्त्री/कार्पेन्टर ) is checked
const constructionMale = {
    nepali: "निर्माण सम्बन्धी (मिस्त्री/कार्पेन्टर ) पुरुष संख्या",
    english: "No of male in construction",
    value: "",
};

const constructionFemale = {
    nepali: "निर्माण सम्बन्धी(मिस्त्री/कार्पेन्टर ) महिला संख्या",
    english: "No of female in construction ",
    value: "",
};

// if कृषि सम्बन्धी(जे.टि, जे.टि.ए र खद प्रसोधन ) is checked
const agricultureMale = {
    nepali: "कृषि सम्बन्धी(जे.टि, जे.टि.ए र खद प्रसोधन ) पुरुष संख्या",
    english: "No of male in agriculture",
    value: "",
};

const agricultureFemale = {
    nepali: "कृषि सम्बन्धी(जे.टि, जे.टि.ए र खद प्रसोधन ) महिला संख्या",
    english: "No of female in Agriculture",
    value: "",
};

//if बास तथा छाला बाट निर्मित हस्त सामग्री is chekced
const handmadeMale = {
    nepali: "बास तथा छाला बाट निर्मित हस्त सामग्री सम्बन्धीपुरुष संख्या",
    english: "No of male in handicraft work",
    value: "",
};

const handmadeFemale = {
    nepali: "बास तथा छाला बाट निर्मित हस्त सामग्री सम्बन्धीमहिला संख्या",
    english: "No of female in handicraft work",
    value: "",
};

// if सूचना प्रविधि ,इलेक्ट्रोनिकस,इलेक्त्रोनिकल(कम्पुटर /मोबाइल/रेडियो/घडी मर्मत) is checked
const electricMale = {
    nepali:
        "सूचना प्रविधि ,इलेक्ट्रोनिकस,इलेक्त्रोनिकल(कम्पुटर /मोबाइल/रेडियो/घडी मर्मत) पुरुष संख्या",
    english: "No of male in Electronic works",
    value: "",
};

const electricFemale = {
    nepali:
        "सूचना प्रविधि ,इलेक्ट्रोनिकस,इलेक्त्रोनिकल(कम्पुटर /मोबाइल/रेडियो/घडी मर्मत) महिला संख्या",
    english: "No of female in Electronic works",
    value: "",
};

// if सिलाई ,बुनाइ ,बुटिक ,पर्लोर, सृंगार is checked
const fashionMale = {
    nepali: "सिलाई ,बुनाइ ,बुटिक ,पर्लोर, सृंगार पुरुष संख्या",
    english: "No of male in sewing , beautiparlour and botique",
    value: "",
};

const fashionFemale = {
    nepali: "सिलाई ,बुनाइ ,बुटिक ,पर्लोर, सृंगार महिला संख्या",
    english: "No of female in sewing , beautiparlour and botique",
    value: "",
};

//if जनस्वास्थ सम्बन्धीis checked
const healthMale = {
    nepali: "जनस्वास्थ सम्बन्धीपुरुष संख्या",
    english: "",
    value: "",
};

const healthFemale = {
    nepali: "जनस्वास्थ सम्बन्धी महिला संख्या",
    english: "No of female in public health ",
    value: "",
};

// if पशु स्वास्थ्य  सम्बन्धीis checked
const animalhealthMale = {
    nepali: "पशु स्वास्थ्य  सम्बन्धीपुरुष संख्या",
    english: "No of male in animal health",
    value: "",
};

const animalhealthFemale = {
    nepali: "पशु स्वास्थ्य  सम्बन्धीमहिला संख्या",
    english: "No of female in animal health",
    value: "",
};

//if पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धीis checked
const tourismMale = {
    nepali: "पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धीपुरुष संख्या",
    english: "No of male in tourism, tour guide work",
    value: "",
};

const tourismFemale = {
    nepali: "पर्यटन , टुर गाइड, ट्राभेल र सत्कार सम्बन्धीमहिला संख्या",
    english: "No of female in tourism, tour guide work",
    value: "",
};

// if कला सम्बन्धीis checked
const skillMale = {
    nepali: "कला सम्बन्धीपुरुष संख्या",
    english: "No of male in art work",
    value: "",
};

const skillFemale = {
    nepali: "कला सम्बन्धीमहिला संख्या",
    english: "No of female in art work",
    value: "",
};

const familyInLocalOrganQuestion = {
    nepali:
        "परिवारको सदस्यहरु स्थानीय तह वा कुनै संघ संस्थामा संग्लग्न भएका छन् ?",
    english:
        "Do any member of your family are engaged in local level organization?",
};

const familyInvolvementLocalOrgan = [
    {
        nepali: "छ",
        english: "yes",
        value: "local_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "local_no",
    },
];

//if familyInvolvementLocalOrgan छ is clicked

const familyLocalGovInvolveQuestion = {
    nepali:
        "संग्लग्न हुनुन्छ भने के कस्ता संघ संस्था वा स्थानीय तह तथा राजनैतिक क्रिबाछा /बाछीालाप मा हुनुन्छ?",
    english: "If yes then which department?",
};

const familyLocalGovInvolve = [
    {
        nepali: "स्थानीय तह",
        english: "Local government",
        value: "local_gov",
    },
    {
        nepali: "स्थानीय विद्यालय व्यस्थापन समिति",
        english: "Local school management group",
        value: "local_school",
    },
    {
        nepali: "कृषक समूह",
        english: "Agriculture group ",
        value: "agri_group",
    },
    {
        nepali: "वन उपभोक्ता समुह",
        english: "Jungle consumer group",
        value: "forest",
    },
    {
        nepali: "सहकारी संस्था",
        english: "Sahakari",
        value: "sahakari",
    },
    {
        nepali: "आमा समूह",
        english: "Mother group",
        value: "mother_grp",
    },
    {
        nepali: "महिला समूह",
        english: "women group",
        value: "women_grp",
    },
    {
        nepali: "खानेपानी उपभोक्ता समुह",
        english: "Drinking water group",
        value: "khanepani",
    },
    {
        nepali: "सिंचाई /जल उपभोक्ता समुह",
        english: "Irrigation",
        value: "irrigation",
    },
    {
        nepali: "स्थानीय बाल समुह (बाल क्लब)",
        english: "Local child group",
        value: "child_club",
    },
    {
        nepali: "युवा समूह",
        english: "Youth club",
        value: "youth_club",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

// house detail rent detail
const buildingQuestion = {
    nepali: "तपाइको कति वटा घर/मतान छ ?",
    english: "How many house do you have?",
};

const building = [
    {
        nepali: "घर",
        english: "House",
        value: "ghar",
    },
    {
        nepali: "मतान",
        english: "Matan",
        value: "matan",
    },
];

//if मतान is checked

const matanCount = {
    nepali: "तपाइको कति वटा मतान छ ?",
    english: "How many matan do you have?",
    value: "",
};

// if घर is checked
const houseCount = { nepali: "तपाइको कति वटा घर ?", english: "", value: "" };

const familyAnotherHouseQuestion = {
    nepali:
        "तपाइको परिवारका सदस्यको नाममा यस गाउँपालिका भित्र वा अन्य स्थानमा घर छ?",
    english: "Do you have land in this reral municipality?",
};

const familyMemberAnotherHouse = [
    {
        nepali: "छ",
        english: "yes",
        value: "house_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "house_no",
    },
];

//if familyMemberAnotherHouse ko छ is clicked
const familyMemberAnotherHouseLocation = {
    nepali: "छ भने कहाँ",
    english: "If yes then where?",
    value: "",
};

const totalRoom = {
    nepali: "तपाइको घरमा कति वटा कोठा छ?",
    english: "How many room do you have?",
    value: "",
};

const totalStorey = {
    nepali: "तपाइको घर कति तल्लाको छ?",
    english: "How many floor do you have ?",
    value: "",
};

const houseAge = {
    nepali: "तपाइँले प्रयोग गरेको घर कहिले बनेको हो ? (वर्ष उल्लेख गर्नुहोस् )",
    english: "What is the age of the house you are living now?",
    value: "",
};

const landUsedLivingQuestion = {
    nepali: " परिवारले प्रयोग गरेको घर रहेको जग्गाको स्वामित्व कस्तो हो ?",
    english: "Who is the owner of the house you are living now?",
};

const landUsedLiving = [
    {
        nepali: "नीजि /आफ्नै",
        english: "Self",
        value: "owner",
    },
    {
        nepali: "भाडामा",
        english: "Rent",
        value: "rent",
    },
    {
        nepali: "संस्थागत",
        english: "Institution",
        value: "institution",
    },
    {
        nepali: "भोग चलनमा रहेको तर स्थायी पुर्जा प्राप्त नभएको",
        english: "not sthaai lalpurja",
        value: "not_sthaai_lalpurja",
    },
    {
        nepali: "सार्वजनिक जग्गा /सुकुम्बासी",
        english: "sukumbasi",
        value: "sukumbasi",
    },
    {
        nepali: "संयुक्त आवास",
        english: "joint resident",
        value: "joint_resident",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

const houseTypeQuestion = {
    nepali: "तपाइको घरको प्रकार कस्तो हो?",
    english: "Types of house",
};

const houseType = [
    {
        nepali: "माटो र ढुङ्गा",
        english: "mud",
        value: "mud",
    },
    {
        nepali: "सिमेन्ट र ढुँगा",
        english: "stone",
        value: "stone",
    },
    {
        nepali: "फ्रेम स्ट्रकचर",
        english: "frame",
        value: "frame",
    },
    {
        nepali: "लोड वेयरिङ्ग",
        english: "load wiring",
        value: "load_wiring",
    },
    {
        nepali: "काठको खम्बा",
        english: "wood",
        value: "wood",
    },
    {
        nepali: "अन्य",
        english: "Other House",
        value: "other_house	",
    },
];

const roofTypeQuestion = {
    nepali: "तपाइको घरको छानाको बनौट कस्तो छ?",
    english: "Types of roof",
};

const pregnantCheckup = [
    {
        nepali: "नियमित रुपमा",
        english: "daily",
        value: "daily",
    },
    {
        nepali: "कहिले काहिँ वा समस्या पर्दा",
        english: "sometimes",
        value: "sometimes",
    },
    {
        nepali: "स्वास्थ्य जाँच नगराएको",
        english: "no_check_up",
        value: "no_check_up",
    },
];

const roofType = [
    {
        nepali: "स्टिल फ्रेम स्ट्रक्चर",
        english: "still structure",
        value: "still_structure",
    },
    {
        nepali: "आर.सी.सी. फ्रेम स्ट्रक्चर र आर.सी.सी. ढलानको छाना",
        english: "Rcc frame",
        value: "rcc_frame",
    },
    {
        nepali: "आर.सी.सी. फ्रेम स्ट्रक्चर र आर.सी.सी. जस्ताको छाना",
        english: "Rcc still",
        value: "rcc_still",
    },
    {
        nepali: "सिमेन्टको जोडाइमा ढुंगा/इटाको गाह्रो र आर.सी.सी. ढलानको छाना",
        english: "RCC",
        value: "rcc",
    },
    {
        nepali: "माटोको जोडाइमा ढुंगा/इटाको गाह्रो र आर.सी.सी. ढलानको छाना",
        english: "mud",
        value: "mud_rcc",
    },
    {
        nepali:
            "सिमेन्टको जोडाइमा ढुंगा/इटा/ब्लकको गाह्रो र जस्ता/स्लेटको छाना",
        english: "Break slate",
        value: "break_slate",
    },
    {
        nepali: "सेड वा कच्ची घर",
        english: "Slade",
        value: "slade",
    },
    {
        nepali: "माटोको जोडाइमा ढुंगा/इटा/ब्लकको गाह्रो र जस्ता/स्लेटको छाना",
        english: "Mud Slade tin",
        value: "mud_slade_tin",
    },
    {
        nepali: "अन्य भए खुलाउनुहोस",
        english: "other roof type",
        value: "other_roof",
    },
];

const hasLandDocumentQuestion = {
    nepali: "घर निर्माण प्रमाण पत्र लिनु भएको छ ?",
    english: "Do you have hoouse paper of your house?",
};

const hasLandDocument = [
    {
        nepali: "छ",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];

const doHouseCriteriaFullfillQuestion = {
    nepali: "तपाइको घर भवन मापदण्ड अनुसार बनेको छ?",
    english: "Do your house made under the criteria?",
};

const doHouseCriteriaFullfill = [
    {
        nepali: "छ",
        english: "Yes",
        value: "criteria_yes",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "criteria_no",
    },
    {
        nepali: "थाहा छैन",
        english: "Dont know",
        value: "donot_know_cri",
    },
];

const isHouseEarthquakeResistanceQuestion = {
    nepali: "तपाइको घर परिवारको जोखिम पारिवारिक योजना बनेको छ ?",
    english: "Do your house earthquake resistance?",
};

const isHouseEarthquakeResistance = [
    {
        nepali: "छ",
        english: "Yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "no",
    },
];

const hasHouseMapPassQuestion = {
    nepali: "तपाइको घरको नक्शा पास भएको छ कि छैन?",
    english: "Do your house map passed or not?",
};

const hasHouseMapPass = [
    {
        nepali: "छ",
        english: "Yes",
        value: "map_yes",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "map_know",
    },
];

const isHouseOnRentQuestion = {
    nepali: "तपाइले घर भाडामा दिनु भएको छ कि छैन?",
    english: "Do u give your house in rent?",
};

const isHouseOnRent = [
    {
        nepali: "छ",
        english: "Yes",
        value: "rent",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "no_rent",
    },
];

//if isHouseOnRent छ is cliked

const rentGivenToQuestion = {
    nepali: "यदि छ भने कुन प्रयोजनको लागि भाडामा दिनुभएको छ ?",
    english: "If yes then for ehat purpose?",
};

const rentGivenTo = [
    {
        nepali: "अन्य परिवारलाई",
        english: "Other family",
        value: "other_family",
    },
    {
        nepali: "होटल /रेस्टुरेन्ट /पसल",
        english: "Hotel",
        value: "hotel_restaura_1",
    },
    {
        nepali: "सेवामुलक  संस्थालाई",
        english: " For Non government organization",
        value: "semi_governmen",
    },
    {
        nepali: "बिधालय संचालनको लागी",
        english: "To open school",
        value: "school",
    },

    {
        nepali: "स्वास्थ्य केन्द्रलाई",
        english: "For health post",
        value: "helath_post",
    },
    {
        nepali: "बैंक तथा वित्तीय संस्थालाई",
        english: "For bank and finance",
        value: "bank",
    },
    {
        nepali: "सरकारी कार्यालयलाई",
        english: "Government office",
        value: "government",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

const rentTypeQuestion = {
    nepali: "प्रयोजनको लागि भाडामा दिएको छ भने:",
    english: "For what purpose given room in rent",
};

// radio button not checkbox
const rentType = [
    {
        nepali: "तल्ला",
        english: "Storey",
        value: "storey",
    },
    {
        nepali: "कोठा",
        english: "Room",
        value: "room",
    },
    {
        nepali: "दुबै",
        english: "Both",
        value: "both",
    },
];

//if तल्ला is checked
const storey = {
    nepali: "कति तल्ला ?",
    english: "How many storey?",
    value: "",
};

//if कोठा is checked
const room = { nepali: "कति कोठा ?", english: "How many room?", value: "" };

//house Facility
const houseFacilityQuestion = {
    nepali: "तपाइको घरमा के के सुविधाहरु छन्?",
    english: "What are the facilities do you have in your room?",
};

const houseFacility = [
    {
        nepali: "टि.भी",
        english: "T.V",
        value: "tv",
    },
    {
        nepali: "रेडियो",
        english: "Radio",
        value: "radio",
    },
    {
        nepali: "ल्याण्ड लाईन टेलिफोन",
        english: "Telephone",
        value: "telephone",
    },
    {
        nepali: "मोबाइल",
        english: "Mobile",
        value: "mobile",
    },
    {
        nepali: "ल्यापटप  / कम्पुटर",
        english: "Laptop",
        value: "laptop",
    },
    {
        nepali: "इन्टरनेट",
        english: "Internet",
        value: "internet",
    },
    {
        nepali: "मोटरसाइकल",
        english: "Motercycle",
        value: "motorcycle",
    },
    {
        nepali: "स्कुटर",
        english: "Scooter",
        value: "scooter",
    },
    {
        nepali: "कार",
        english: "Car",
        value: "car",
    },
    {
        nepali: "जिप /अटो",
        english: "Jeep",
        value: "jeep",
    },
    {
        nepali: "ट्राकटर",
        english: "Tractor",
        value: "tractor",
    },
    {
        nepali: "ट्रक",
        english: "Truck",
        value: "truck",
    },
    {
        nepali: "टेम्पो",
        english: "Tempo",
        value: "tempo",
    },
    {
        nepali: "कुनै पनि सुविधा नभएको",
        english: "nofacilities",
        value: "no_any_facilities",
    },
    {
        nepali: "सिटि सफारी",
        english: "safari",
        value: "siti_safari",
    },
    {
        nepali: "डोज़र",
        english: "Dozar",
        value: "dozar",
    },
    {
        nepali: "रेफ्रेजिरेटर",
        english: "Refrigerator",
        value: "refrigerator",
    },
    {
        nepali: "ट्रीपर",
        english: "Tripar",
        value: "tripar",
    },
    {
        nepali: "विधुतीय सवारीसाधन",
        english: "ELECTRIC VEHICLE",
        value: "ELECTRIC_VEHICLE",
    },
    {
        nepali: "अन्य",
        english: "other vehcile",
        value: "other_vehcileT",
    },
];

// if motorcycle is cliked
const motorcycle = {
    nepali: "मोटरसाइकल (संख्या)",
    english: "No of motercycle",
    value: "",
};
const scooter = {
    nepali: "स्कुटर (संख्या)",
    english: "No of Scooter",
    value: "",
};
const car = { nepali: "कार (संख्या)", english: "No of car", value: "" };
const jeep = { nepali: "जिप (संख्या)", english: "Jeep(no)", value: "" };
const tractor = {
    nepali: "ट्राकटर (संख्या)",
    english: "Tractor(no)",
    value: "",
};
const truck = { nepali: "ट्रक (संख्या)", english: "Truck(no)", value: "" };
const auto = { nepali: "अटो (संख्या)", english: "Auto(no)", value: "" };
const tempo = { nepali: "टेम्पो (संख्या)", english: "Tempo(no)", value: "" };
const dozar = { nepali: "डोज़र (संख्या)", english: "Dojar(no)", value: "" };
const Tipper = { nepali: "टिप्पर (संख्या)", english: "Tipper(no)", value: "" };
const ELECTRICVEHICLE = {
    nepali: "विधुतीय सवारीसाधन (संख्या)",
    english: "ELECTRICVEHICLE(no)",
    value: "",
};
const safari = {
    nepali: "सिटि सफारी (संख्या)",
    english: "safari(no)",
    value: "",
};
const other = {
    nepali: "अन्य भए उल्लेख गर्नु हाेस ।",
    english: "IF any then mention",
    value: "",
};
const otherCount = { nepali: "अन्य (संख्या)", english: "Other(no)", value: "" };

const handWash = {
    nepali: "तपाईको परिवारमा साबुनपानीले हात धुने गर्नु हुन्छ कि हुन्न ?",
    english: "",
};
const handWashOption = [
    {
        nepali: "धुन्छौ",
        english: "Yes",
        value: "yes",
    },
    {
        nepali: "धुन्नौ",
        english: "No",
        value: "no",
    },
];
const alcoholTitle = { nepali: "मदिरा सेवन", english: "Alcohol Usages" };
const alcoholUsage = {
    nepali: "तपाईको परिवारमा कोही सदस्य मदिरा सेवन गर्ने हुनु हुन्छ ?",
    english: "",
};
const alcoholUsageOption = [
    {
        nepali: "छन्",
        english: "Yes",
        value: "alcohol_yes",
    },
    {
        nepali: "छैनन्",
        english: "No",
        value: "alcohol_no",
    },
];

// if alcohol usage in family yes then
const alcoholUsageCount = {
    nepali: "छन् भने कति जना छन् ?",
    english: "If yes how many ?",
};
const alcoholCount = { nepali: "कति जना छन् ?", english: "If yes how many ?" };

const alcoholUsageCard = {
    nepali: "मदिरा सेवन कार्डको प्रयोग गर्ने गरेको छ ?",
    english: "",
};

const alcoholUsageCardOption = [
    {
        nepali: "छ",
        english: "Yes",
        value: "alcohol_card_y",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "alcohol_card_n",
    },
];

// if alcoholUsageCard yes then
const effectiveFromCard = {
    nepali:
        "मदिरा सेवन कार्डको प्रयोगले तपाईको परिवारमा कस्तो प्रभाव पारेको छ ?",
    english: "",
};
const effectiveFromCardOption = [
    {
        english: "",
        nepali: "राम्रो प्रभाव पारेको छ",
        value: "best",
    },
    {
        english: "",
        nepali: "ठिकै प्रभाव पारेको छ",
        value: "good",
    },
    {
        english: "",
        nepali: "खासै राम्रो प्रभाव पारेको छैन",
        value: "bad",
    },
];

const alcoholUsageExpense = {
    nepali: "मदिरा सेवनमा मासिक अनुमानित कति खर्च हुन्छ ?",
    english: "",
};

const problemFromAlcohol = {
    nepali: "मदिरा सेवनले तपाईको परिवारमा कस्तो समस्या पारेको छ ?",
    english: "",
};

const problemFromAlcoholOption = [
    {
        nepali: "पारिवारिक कलह",
        english: "",
        value: "family_conflit",
    },
    {
        nepali: "सामाजिक असर",
        english: "",
        value: "social_effect",
    },
    {
        nepali: "स्वास्थ्यमा प्रभाव",
        english: "",
        value: "health_problem",
    },
    {
        nepali: "आर्थिक प्रभाव",
        english: "",
        value: "economic_issue",
    },
    {
        nepali: "हत्या हिंशा",
        english: "",
        value: "crime",
    },
    {
        nepali: "कुटपिट",
        english: "",
        value: "fight",
    },
];

const deathFromAlcohol = {
    nepali: "मदिरा सेवनले परिवारमा कसैको मृत्यू भएको छ ?",
    english: "",
};

const deathFromAlcoholOption = [
    {
        nepali: "छ",
        english: "Yes",
        value: "death_yes",
    },
    {
        nepali: "छैन",
        english: "Yes",
        value: "death_no",
    },
];

const deathCount = {
    nepali: "मदिरा सेवनले परिवारमा कति जनाको मृत्यू भएको छ ?",
    english: "",
};
// if alcohol usage in family yes enddd

const houseUsedLiving = {
    nepali: "तपाई बसेको घरको स्वामित्व कस्तो हो ?",
    english: "",
};
const houseUsedLivingOption = [
    {
        english: "owner",
        nepali: "नीजि /आफ्नै",
        value: "owner",
    },
    {
        english: "",
        nepali: "भाडामा",
        value: "rent",
    },
    {
        english: "",
        nepali: "संस्थागत",
        value: "institution",
    },
    {
        english: "",
        nepali: "भोग चलनमा रहेको तर स्थायी पुर्जा प्राप्त नभएको",
        value: "not_sthaai_lalpurja",
    },
    {
        english: "",
        nepali: "सार्वजनिक जग्गा /सुकुम्बासी",
        value: "sukumbasi",
    },
    {
        english: "",
        nepali: "संयुक्त आवास",
        value: "joint_resident",
    },
    {
        english: "",
        nepali: "अन्य",
        value: "others",
    },
];

const ownLandOrFamilyLand = [
    {
        nepali: "क. लालपुर्जा सहितको जग्गाजमिन भएको",
        english: "having_ownland",
        value: "having_ownland",
    },
    {
        nepali: "ख. जग्गा जमिन भएको, लालपुर्जा नभएको",
        english: "land_but_not_certificate",
        value: "land_but_not_certificate",
    },
    {
        nepali: "ग. जग्गा जमिन वा लालपुर्जा केहि नभएको (भुमिहिन)",
        english: "no_owned_land",
        value: "no_owned_land",
    },
    {
        nepali: "क र ख दुवै भएको",
        english: "both",
        value: "both",
    },
];

const returnFromAbroad = {
    nepali:
        "तपाईको परिवारमा बिगत तीन बर्षमा विदेश बसेर स्थाई रुपमा यहाँ बसोबास गर्नु हुने कोहि हुनु हुन्छ?",
    english: "",
};
const returnFromAbroadOption = [
    {
        nepali: "छ",
        english: "",
        value: "foreign_3_yes",
    },
    {
        nepali: "छैन",
        english: "",
        value: "foreign_3_no",
    },
];

const stayedYearAbroad = {
    nepali: "छ भने कति बर्ष बसेर फर्किनु भएको हो ?",
    english: "",
};

const suggestionTitle = {
    nepali: "घर बाट सुझाव/सल्लाह",
    english: "Suggestion By house",
};
const ifOtherValueQuestion = {
    nepali: "अन्य भए उल्लेख गर्नुहोस |",
    english: "",
};
const houseOwnerNameQn = {
    nepali: "घरमुली को नाम",
    english: "",
};
const houseOwnerContactQn = {
    nepali: "घरमुलीको सम्पर्क नम्बर",
    english: "",
};
const houseOwnerEmailQn = {
    nepali: "घरमुलीको इमेल",
    english: "",
};
const firstMarriageAgeQn = {
    nepali: "प्रथम पटक विवहा गरेको उमेर",
    english: "",
};
const socialSecurityAllowanceQn = {
    nepali: "तपाईले कुनै सामाजिक सुरक्षा भत्ता लिनुभएको छ",
    english: "",
};
const socialSecurityAllowanceDetail = {
    nepali: "सामाजिक सुरक्षा भत्ता ",
    english: "",
};
const socialSecurityAllowanceList = [
    { value: "not_taken", nepali: "छैन", english: "" },
    { value: "pension", nepali: "पेन्सन नेपाल सरकार", english: "" },
    { value: "pension_other", nepali: "पेन्सन अन्य देश", english: "" },
    { value: "old", nepali: "बृद्ध भत्ता", english: "" },
    { value: "bidhuwa", nepali: "विधुवा भत्ता", english: "" },
    { value: "dalit", nepali: "दलित बृद्ध भत्ता", english: "" },
    { value: "handicap", nepali: "अपाङ्गता भत्ता", english: "" },
    { value: "scholarship", nepali: "सरकारी छात्रवृत्ति", english: "" },
];
const landUsageQn = {
    nepali: "तपाइले  आफ्नो जग्गा कसरी  उपभोग गर्नु भएको छ ?",
    english: "",
};
const landUsageList = [
    { value: "self", nepali: "आफै", english: "" },
    { value: "chapter", nepali: "अधिया  मात्र", english: "" },
    { value: "contract", nepali: "ठेक्का मात्र", english: "" },
    { value: "self_chapter", nepali: "आफै  र अधिया (दुवै)", english: "" },
    { value: "self_contract", nepali: "आफै  र ठेक्का (दुवै)", english: "" },
    {
        value: "chapter_contract",
        nepali: "अधिया  र ठेक्का  (दुवै)",
        english: "",
    },
    { value: "all", nepali: "आफै, अधिया  र ठेक्का", english: "" },
];
const adhiyaThekkaYesQn = {
    nepali: "अधिया वा ठेक्का  कसलाई दिनुभएको छ ?",
    english: "",
};
const adhiyaThekkaList = [
    { value: "nepali", nepali: "नेपाली", english: "" },
    { value: "indian", nepali: "भारतीय", english: "" },
    { value: "both", nepali: "दुवै", english: "" },
];
const forestUseYesNoQn = {
    nepali: "वन प्रयोजनको लागी जग्गा प्रयोग गर्नु भएको छ कि छैन ?",
    english: "",
};
const forestUseYesNo = [
    { value: "forest_yes", nepali: "छ", english: "" },
    { value: "forest_no", nepali: "छैन", english: "" },
];
const forestUseYesQn = {
    nepali: "वन प्रयोजनको लागी जग्गा प्रयोग प्रयोग भएको छ भने",
    english: "",
};
const forestRegistered = [
    { value: "registered_yes", nepali: "निजी वन दर्ता भएको", english: "" },
    { value: "registered_no", nepali: "निजी वन दर्ता नभएको", english: "" },
];
const privateForestQn = {
    nepali: "निजी वन दर्ता भएको भए  क्षेत्रफल",
    english: "",
};
const skillTrainingDurationQn = {
    nepali: "छ भने कति अवधि को लागि ?",
    english: "",
};
const skillJobQn = {
    nepali: "तपाइले तालिम लिए  पश्चात सोही क्षेत्रम  काम गर्दै हुनु हुन्छ ?",
    english: "",
};
const skillJobYesNo = [
    { value: "yes_same_job", nepali: "छ", english: "" },
    { value: "not_same_job", nepali: "छैन", english: "" },
];
const skillJobNoQn = {
    nepali: "काम गर्नुभएको छैन भने किन ?",
    english: "",
};
const skillJobNoList = [
    { value: "no_job", nepali: "काम नपाएर", english: "" },
    { value: "job_other", nepali: "अर्कै काम गरिराखेको भएर", english: "" },
    { value: "no_money", nepali: "व्यावसाय गर्ने रकम नभएर", english: "" },
    {
        value: "lack_trained",
        nepali: "तालिमले पर्याप्त  ज्ञान नदिएर",
        english: "",
    },
    { value: "family_support_no", nepali: "परिवारले सहयोग नगरेर", english: "" },
    { value: "other_no_job_reason", nepali: "अन्य कारण", english: "" },
];
const agriIncomeQn = {
    nepali: "तपाईको परिवारको सरदर वार्षिक आम्दानी कति छ ?",
    english: "",
};
const nonAgriIncomeQn = {
    nepali: "तपाईको परिवारको सरदर वार्षिक आम्दानी कति छ ?",
    english: "",
};
const agriExpenseQn = {
    nepali: "तपाईको परिवारको सरदर वार्षिक  खर्च कति छ ",
    english: "",
};
const nonAgriExpenseQn = {
    nepali: "तपाईको परिवारको सरदर वार्षिक खर्च  कति छ ",
    english: "",
};
const ifDiseaseDeathQn = {
    nepali: "यदी  रोग लागेर हो भने कुन रोग ?",
    english: "",
};
const agricultureProductionOrNot = [
    {
        nepali: "कृषी बाली उत्पादन गर्ने र आफै मात्र उपभोग गर्ने",
        english: "only_production",
        value: "only_production",
    },
    {
        nepali: "कृषी बाली उत्पादन सहित विक्रि वितरण गर्ने",
        english: "sales",
        value: "sales",
    },
    {
        nepali: "कृषी बाली उत्पादन नगर्ने",
        english: "no_production",
        value: "no_production",
    },
];
const deathDiseaseList = [
    {
        value: "high_blood_pre",
        nepali: "उच्च रक्तचाप / ब्लड प्रेसर",
        english: "high blood pre",
    },
    { value: "heart", nepali: "मुटु  सम्बन्धी रोग", english: "Heart" },
    {
        value: "diabetes",
        nepali: "मधुमेह (SUGAR) (डाइबेटिज)",
        english: "Diabeties",
    },
    { value: "aasthama", nepali: "आस्थमा/दम", english: "Aasthama" },
    { value: "liver", nepali: "मृगौला सम्बन्धी  रोग", english: "Liver" },
    { value: "hiv", nepali: "HIV AIDS", english: "" },

    {
        value: "bornjoint",
        nepali: "हाडजोर्नी तथा नसा सम्बन्धि रोग (बाथ  रोग )",
        english: "Bornjoint",
    },
    { value: "ipilepsy", nepali: "क्षारे रोग (epilepsy)", english: "Ipilepsy" },
    {
        value: "manasik",
        nepali: "कडा खालको मानसिक रोग (मस्तिस्क)",
        english: "Manashik",
    },
    { value: "hattipaile", nepali: "हात्तीपाइले", english: "Hattipaile" },
    { value: "leprosy", nepali: "कुष्ठरोग", english: "Leprosy" },

    { value: "cancer", nepali: "क्यान्सर", english: "Cancer" },
    { value: "thaairoid", nepali: "थाइराईड रोग", english: "Thairoid" },
    {
        value: "paatheghar_khasne",
        nepali: "पाठेघर झर्ने खस्ने",
        english: "Patheghaar",
    },
    { value: "alsar", nepali: "अल्सर /आन्द्राको रोग", english: "Alsar" },
    { value: "aljaaimars", nepali: "अल्जाइमर्स", english: "Aljaaimars" },
    { value: "low_pressure", nepali: "निम्न रक्तचाप", english: "low pressure" },
    { value: "paarkinsas", nepali: "पार्किन्सस", english: "" },
    { value: "other_disease", nepali: "अन्य", english: "" },
];
const houseMeterQn = {
    nepali: "तपाईको घरमा विधुतीय मिटर जडान गरिएको छ वा छैन?  ",
    english: "",
};
const houseMeter = [
    { value: "meter_yes", nepali: "छ" },
    { value: "meter_no", nepali: "छैन" },
];
const safetyTankCleaningIntervalTimeQn = {
    nepali: "सेफ्टी ट्यांकी कति बर्षमा सफाई गर्नु हुन्छ ? ",
    english: "",
};
const wasteProducedPerWeekQn = {
    nepali: "तपाईको घरमा हप्तामा  कति फोहरमैला उत्पादन हुन्छ ? (के . जी. ) ",
    english: "",
};
const roadConditionHouseQn = {
    nepali: "घरसम्म पुग्ने सडक/बाटोको अवस्था कस्तो छ ?  ",
    english: "",
};
const roadConditionHouse = [
    { value: "blacktop", nepali: "पक्कि/कालो पत्रे", english: "Blacktop" },
    { value: "gravel", nepali: "ग्राभेल सडक", english: "Gravel" },
    { value: "kaccchi", nepali: "कच्ची सडक", english: "Kacchi" },
    { value: "goreto", nepali: "गोरेटो बाटो जोडीएको", english: "Goreto" },
];
const otherHouseLocationQn = {
    nepali: "एक भन्दा बेसी घर भए अर्को घर कहाँ छ ?",
    english: "",
};
const otherHouseLocation = [
    { value: "same_ward", nepali: "यसै वार्डमा", english: "Same ward" },
    {
        value: "other_ward",
        nepali: "अर्को वार्ड ( पालिका भित्रै )",
        english: "Other ward",
    },
    {
        value: "inside_district_morang",
        nepali: "मोरङ  जिल्ला भित्र",
        english: "inside morang",
    },
    {
        value: "outside_district_morang",
        nepali: "मोरङ  जिल्ला बाहिर",
        english: "outside morang",
    },
];
const houseFoundationQn = {
    nepali: "तपाई बसेको घरको जग कस्तो प्रकारको छ ? ",
    english: "",
};
const houseFoundation = [
    { value: "mud", nepali: "माटो र ढुङ्गा", english: "Mud" },
    { value: "stone", nepali: "सिमेन्ट र ढुँगा", english: "Stone" },
    { value: "frame", nepali: "फ्रेम स्ट्रकचर", english: "Frame" },
    { value: "load_wiring", nepali: "लोड वेयरिङ्ग ", english: "load wiring" },
    { value: "wood", nepali: "काठको खम्बा ", english: "Wood" },
    { value: "other_house", nepali: "अन्य", english: "Other house" },
];
const familyRiskPlanQn = {
    nepali: "तपाइको घर परिवारको जोखिम पारिवारिक योजना बनेको छ ? ",
    english: "",
};
const familyRiskPlan = [
    { value: "plan_yes", nepali: "छ" },
    { value: "plan_no", nepali: "छैन" },
];
const familyRiskPlanYesQn = {
    nepali: "छ भने के कस्तो योजना बनेको छ ",
    english: "",
};
const womanOwnedHomeQn = {
    nepali: "तपाइको परिवारमा महिलाको नाममा  घर, जग्गा  छ? ",
    english: "",
};
const womanOwnedHome = [
    { value: "house", nepali: "घर मात्र भएको", english: "House" },
    { value: "land", nepali: "जागा  मात्र भएको", english: "Land" },
    { value: "both", nepali: "घर जग्गा दुवै भएको", english: "Both" },
    { value: "none", nepali: "घर र जग्गा दुवै नभएको", english: "None" },
];

const aanabaliLandUsed = [
    {
        nepali: "आफ्नो जग्गा आफै कमाएको",
        english: "self_use",
        value: "self_use",
    },
    {
        nepali: "आफ्नो जग्गा अरुलाई कमाउन/भाडामा दिएको",
        english: "given_other_to_use",
        value: "given_other_to_use",
    },
    {
        nepali: "अरुको जग्गा कमाएको",
        english: "other_use_land",
        value: "other_use_land",
    },
    {
        nepali: "आफ्नो र अरुको जग्गा समेत कमाएको",
        english: "self_and_other_owned",
        value: "self_and_other_owned",
    },
    {
        nepali: "आफ्नो जागा आफै कमाएको तथा अरुलाई कमाउन दिएको",
        english: "self_and_given_other",
        value: "self_and_given_other",
    },
];

const vehicleCountQn = {
    nepali: "मोटरसाइकल/स्कुटर /कार  (संख्या)",
    english: "",
};

const unhealthyTreatmentQn = {
    nepali:
        "बिरामी हुदा स्वस्थ उपचार सेवाको लागी  प्रथम पटक कहाँ जानु हुन्छ ? ",
    english: "",
};
const unhealthyTreatment = [
    { value: "dhaami_jhakri", nepali: "धामी झाक्री", english: "" },
    { value: "medical", nepali: "औषधि पसल   / क्लिनिक", english: "" },
    { value: "aayurbedik", nepali: "आयुर्वेदिक औषधालय", english: "" },
    { value: "health_post", nepali: "स्वास्थ्य चौकी", english: "" },
    { value: "private_hospit", nepali: "निजि अस्पताल", english: "" },
    {
        value: "prakritik",
        nepali: "प्राकृतिक चिकित्सा",
        english: "",
    },
    { value: "healthworker", nepali: "स्थानीय स्वास्थ्यकर्मी", english: "" },
    { value: "district_govt_", nepali: "सरकारी अस्पताल", english: "" },
    { value: "others_ill", nepali: "अन्य", english: "" },
];
const familyInOrgQn = {
    nepali:
        "तपाईको परिवारको सदश्यहरु कुनै संघ संस्थामा आवद्ध हुनुहुनछ कि हुनुहुन्न ?  ",
    english: "",
};
const familyInOrg = [
    { value: "no", nepali: "छैन", english: "" },
    { value: "women_club", nepali: "महिला समुह", english: "" },
    { value: "mother_club", nepali: "आमा समुह", english: "" },
    { value: "child_club", nepali: "बाल समुह", english: "" },
    { value: "school_committee", nepali: "विद्यालय व्यवस्थापन", english: "" },
    { value: "youth_club", nepali: "युवा समुह", english: "" },
    { value: "agriculture_club", nepali: "कृषक समुह", english: "" },
    { value: "others_club", nepali: "अन्य (भए खुलाउनुहोस् )", english: "" },
];
const disputeResolveQn = {
    nepali:
        "परिवार वा टोल छिमेकमा हुने विवाद वा झैझगडाको उजुरी कहाँ गर्नुहुन्छ?  ",
    english: "",
};
const disputeResolve = [
    { value: "no", nepali: "विवाद झै झगडा भएकै छैन", english: "" },
    { value: "gentlemen", nepali: "भद्र भलादमीकहाँ", english: "" },
    { value: "house", nepali: "बडघर", english: "" },
    { value: "wadghar", nepali: "भलभान्छा", english: "" },
    { value: "tole", nepali: "टोल विकास सँस्था", english: "" },
    { value: "ward", nepali: "वडा कार्यालय", english: "" },
    { value: "judicial", nepali: "पालिकाको न्यायिक समिति", english: "" },
    { value: "police", nepali: "प्रहरी", english: "" },
    { value: "court", nepali: "अदालत", english: "" },
    { value: "other_complain", nepali: "अन्य", english: "" },
];
const covidEffectQn = {
    nepali: "कोरोना महामारीले  तपाइको परिवारमा  पारेको  प्रमुख असर के छ  ? ",
    english: "",
};
const covidEffect = [
    { value: "NOANY_EFFECT", nepali: "कुनै पनि असर नगरेको", english: "" },
    {
        value: "unemployment",
        nepali: "परिवारको सदश्यको रोजगार गुमेको",
        english: "Unemployment",
    },
    {
        value: "psychological",
        nepali: "परिवार सदश्यमा मानसिक असर पारेको ",
        english: "Psychological",
    },
    {
        value: "jhaijhagadaa",
        nepali: "आर्थिक अभाबले गर्दा परिवारमा कलह /झैँझगडा बढेको ",
        english: "fight",
    },
    {
        value: "business",
        nepali: "ब्यापार - व्यवासय बन्द गर्नु परेको ",
        english: "Business",
    },
    {
        value: "agriculture",
        nepali: "कृषि उत्पादन बिक्रि नभएको",
        english: "Agriculture",
    },
    {
        value: "khana_problem",
        nepali: "घर परिवार सदस्यमा  खानलाई समस्या",
        english: "Khana problem",
    },
    { value: "others", nepali: "अन्य (खुलाउनु होस् )", english: "Other" },
];
const suggestionQn = {
    nepali:
        "तपाइले आफ्नो स्थानको लागी केहि सुझाव/सल्लाह छ?  (एक वाक्यमा लेख्नुहोस) ",
    english: "",
};
const newsMediumQn = {
    nepali: "नगरपालिकाको  सूचना के माध्यम बाट प्राप्त गर्नुहुन्छ ?  ",
    english: "",
};
const newsMedium = [
    { value: "dont_know", nepali: "थाह छैन", english: "dont know" },
    { value: "mobile_app", nepali: "मोबाइल एप", english: "mobile app" },
    { value: "radio", nepali: "रेडियो", english: "Radio" },
    {
        value: "local_leader",
        nepali: "स्थानीय जनप्रतिनिधि  बाट",
        english: "Local leader",
    },
    {
        value: "newspaper_arti",
        nepali: "पत्रपत्रिका / समाचारपत्र",
        english: "Newspaper",
    },
    {
        value: "government_off",
        nepali: "सरकारी कर्मचारी",
        english: "Government officer",
    },
    {
        value: "nongov_officer",
        nepali: "गैर सरकारी कर्मचारीबाट",
        english: "non government officer",
    },
    {
        value: "information_ri",
        nepali:
            "लिखित निवेदन दिएर सूचना प्राप्त गर्ने   (सुचनाको हक प्रयोग गर्ने)",
        english: "information ri",
    },
    { value: "sms", nepali: "एस.एम.एस ", english: "SMS" },
    { value: "website", nepali: "वेबसाइट ", english: "Website" },
    { value: "maaiking", nepali: "माईकिङ ", english: "Maaiking" },
    { value: "suchana_paati", nepali: "सूचना पाटी ", english: "Suchana paati" },
    { value: "social", nepali: "सामाजिक सन्जाल ", english: "Social" },
];

module.exports = {
    //new fields from kobo
    isStillChildMarriage,
    reasonForChildMarriage,
    controlChildMarriage,
    aandolanHeader,
    aandolanYesNo,
    aandolanEffect,
    combatSide,
    painFromAandolan,
    effectFromPandemic,
    illTreatment,
    bigha,
    kattha,
    dhur,
    suggestionTitle,
    handWash,
    handWashOption,
    alcoholUsage,
    alcoholUsageOption,
    alcoholUsageCount,
    alcoholUsageCard,
    alcoholUsageCardOption,
    effectiveFromCard,
    effectiveFromCardOption,
    alcoholUsageExpense,
    problemFromAlcohol,
    problemFromAlcoholOption,
    deathFromAlcohol,
    deathFromAlcoholOption,
    deathCount,
    alcoholCount,
    alcoholTitle,
    houseUsedLiving,
    houseUsedLivingOption,
    returnFromAbroad,
    ownLandOrFamilyLand,
    returnFromAbroadOption,
    stayedYearAbroad,
    //end
    //child marriage starts
    isChildMarriageInFamilyQn,
    isChildMarriageInFamily,
    childMarriageCount,
    childMartialStatusQn,
    childMartialStatus,
    childMarriageAge,
    childMarriageTypeQn,
    childMarriageType,
    childMarriageEducationQn,
    childMarriageEducation,
    childMarriageOccupationQn,
    childMarriageOccupation,
    childMarriageKidQn,
    childMarriageKid,
    //ends
    // economics
    mainIncomeSourceQuestion,
    mainIncomeSource,
    foodForMonthQuestion,
    foodForMonth,
    foodAlternateQuestion,
    foodAlternate,
    totalIncome,
    totalExpenditure,
    loanQuestion,
    loan,
    rate,
    loanTakenInFamilyQuestion,
    loanTakenInFamily,
    bankrate,
    farmerrate,
    laghubittrate,
    femalerate,
    personrate,
    sahakarirate,
    otherrate,
    loanTakingReasonQuestion,
    loanTakingReason,
    dhukutiKarobar,
    dhukutiKarobarCount,
    dhukutiKarobarYesNo,
    cashLoanGivenQuestion,
    cashLoanGiven,
    cashLoanRate,
    //end economics

    //house detail discrimination
    discriminationQuestion,
    discrimination,
    //ends

    // skills
    skillTrainingQuestion,
    skillTraining,
    skillDetailQuestion,
    skillDetail,
    constructionMale,
    constructionFemale,
    handmadeMale,
    handmadeFemale,
    electricMale,
    electricFemale,
    agricultureMale,
    agricultureFemale,
    tourismMale,
    tourismFemale,
    healthMale,
    healthFemale,
    fashionMale,
    fashionFemale,
    animalhealthMale,
    animalhealthFemale,
    skillMale,
    skillFemale,
    familyInLocalOrganQuestion,
    familyInvolvementLocalOrgan,
    familyLocalGovInvolveQuestion,
    familyLocalGovInvolve,
    //skills ends

    // about house rent etc
    buildingQuestion,
    building,
    houseCount,
    familyMemberAnotherHouseLocation,
    familyAnotherHouseQuestion,
    familyMemberAnotherHouse,
    totalRoom,
    totalStorey,
    houseAge,
    landUsedLivingQuestion,
    landUsedLiving,
    houseTypeQuestion,
    houseType,
    roofTypeQuestion,
    roofType,
    pregnantCheckup,
    hasLandDocumentQuestion,
    hasLandDocument,
    doHouseCriteriaFullfillQuestion,
    doHouseCriteriaFullfill,
    isHouseEarthquakeResistanceQuestion,
    isHouseEarthquakeResistance,
    hasHouseMapPassQuestion,
    hasHouseMapPass,
    isHouseOnRentQuestion,
    isHouseOnRent,
    matanCount,
    rentGivenToQuestion,
    rentGivenTo,
    rentTypeQuestion,
    rentType,
    storey,
    room,
    //ends about house rent etc
    detectCurrentLocation,
    geoCode,
    // death
    deathQuestion,
    deathInYear,
    deathInYeargenderQuestion,
    deathInYearReasonQuestion,
    anyDeath,
    deathName,
    deathGender,
    deathAge,
    deathReason,
    deathCertificateQn,
    deathCertificate,
    //agrijaustart
    agriYesNo,
    agriVegetableTitle,
    agriDhanAreaQuestion,
    agriTypeProduction,
    agriCropType,
    allAgriType,

    //agrijauwend
    //agrimakaistart
    agriMakaiInfo,
    agriProduction,
    agriProductionTypeQuestion,
    agriProductionType,
    agriMakaiAreaQuestion,
    agriMakaiArea,
    agriMakaiProduction,
    agriMakaiProductionQuantity,
    agriMakaiSale,

    //agrimakaiend
    //agrikodostart
    agriKodoInfo,
    agriKodoAreaQuestion,
    agriKodoArea,
    agriKodoProduction,
    agriKodoProductionQuantity,
    agriKodoSale,

    //agrikodoend
    //agrifaparstart
    agriFaparInfo,
    agriFaparAreaQuestion,
    agriFaparArea,
    agriFaparProduction,
    agriFaparProductionQuantity,
    agriFaparSale,

    //agrifaparend
    //agrigauend
    //agrigaustart
    //agrianyekhetistart
    agriAnyKheti,
    agriAnyInfo,
    agriAnyInfoTitle,
    agriAnyKhetiInfo,
    agriAnyKhetiAreaQuestion,
    agriAnyKhetiArea,
    agriAnyKhetiProduction,
    agriAnyKhetiSale,
    agriAnyKhetiProductionQuantity,
    agriAnyKhetiSaleQuantity,
    //agrianyekhetiend
    // family details
    //DAAL START
    agriDaalTitle,
    agriDaalType,
    //agrikalodaalstart
    kalodaalInfo,
    kalodaalAreaQuestion,
    kalodaalArea,
    kalodaalProduction,
    kalodaalProductionQuantity,
    agrikalodaalSale,
    kalodaalSaleQuantity,
    //agrikalodaalend
    //agrirahardaalstart
    raharInfo,
    raharAreaQuestion,
    raharArea,
    raharProduction,
    raharProductionQuantity,
    raharSale,
    raharSaleQuantity,
    //agrirahardaalstart

    //chanadaalstart
    chanaInfo,
    chanaAreaQuestion,
    chanaArea,
    chanaProduction,
    chanaProductionQuantity,
    chanaSale,
    chanaSaleQuantity,
    //chanadaalend
    //bhatmasdaalstart
    bhatmasInfo,
    bhatmasAreaQuestion,
    bhatmasArea,
    bhatmasProduction,
    bhatmasProductionQuantity,
    bhatmasSale,
    bhatmasSaleQuantity,
    //bhatmasdaalend
    //gahatdaalstart
    gahatInfo,
    gahatAreaQuestion,
    gahatArea,
    gahatProduction,
    gahatProductionQuantity,
    gahatSale,
    gahatSaleQuantity,
    //gahatdaalend
    //mashyamdaalstart
    mashyamInfo,
    mashyamAreaQuestion,
    mashyamArea,
    mashyamProduction,
    mashyamProductionQuantity,
    mashyamSale,
    mashyamSaleQuantity,
    //mashyamdaalend
    //khesaridaalstart
    khesariInfo,
    khesariAreaQuestion,
    khesariArea,
    khesariProduction,
    khesariProductionQuantity,
    khesariSale,
    khesariSaleQuantity,
    //khesaridaalend
    //anyadaalstart
    anyDaalKheti,
    anyDaalInfo,
    anyDaalInfoTitle,
    anyDaalKhetiInfo,
    anyDaalKhetiAreaQuestion,
    anyDaalKhetiArea,
    anyDaalKhetiProduction,
    anyDaalKhetiProductionQuantity,
    anyDaalKhetiSale,
    anyDaalKhetiSaleQuantity,
    //anyadaalstart
    //DAAL END
    //OILSEED START
    agriOilTitle,
    agriOilType,
    //OILSEED TOORI START
    tooriInfo,
    tooriAreaQuestion,
    tooriArea,
    tooriProduction,
    tooriProductionQuantity,
    tooriSale,
    tooriSaleQuantity,
    //OILSEED TOORI END
    //OILSEED SURYA START
    suryaInfo,
    suryaAreaQuestion,
    suryaArea,
    suryaProduction,
    suryaProductionQuantity,
    suryaSale,
    suryaSaleQuantity,
    //OILSEED SURYA END
    //OILSEED AALASH START
    aalashInfo,
    aalashAreaQuestion,
    aalashArea,
    aalashProduction,
    aalashProductionQuantity,
    aalashSale,
    aalashSaleQuantity,
    //OILSEED AALASH END
    //OILSEED FILUNGE START
    filungeInfo,
    filungeAreaQuestion,
    filungeArea,
    filungeProduction,
    filungeProductionQuantity,
    filungeSale,
    filungeSaleQuantity,
    //OILSEED FILUNGE END
    //OILSEED TIL START
    tilInfo,
    tilAreaQuestion,
    tilArea,
    tilProduction,
    tilProductionQuantity,
    tilSale,
    tilSaleQuantity,
    //OILSEED TIL END
    //OILSEED sunflower START
    sunflowerInfo,
    sunflowerAreaQuestion,
    sunflowerArea,
    sunflowerProduction,
    sunflowerProductionQuantity,
    sunflowerSale,
    sunflowerSaleQuantity,
    //OILSEED SUNFLOWER END
    //OILSEED chiuri START
    chiuriInfo,
    chiuriAreaQuestion,
    chiuriArea,
    chiuriProduction,
    chiuriProductionQuantity,
    chiuriSale,
    chiuriSaleQuantity,
    //OILSEED chiuri END
    //OILSEED ANYOILSEED START
    anyOilKheti,
    anyOilInfo,
    anyOilInfoTitle,
    anyOilKhetiInfo,
    anyOilKhetiAreaQuestion,
    anyOilKhetiArea,
    anyOilKhetiProduction,
    anyOilKhetiProductionQuantity,
    anyOilKhetiSale,
    anyOilKhetiSaleQuantity,
    //OILSEED ANYOILSEED END
    //OILSEED END

    //VEGETABLE START
    //POTATO START
    potatoInfo,
    potatoAreaQuestion,
    potatoArea,
    potatoProductionQuantity,
    potatoSaleQuantity,
    potatoSale,
    //POTATO END
    //CABBAGE START
    cabbageInfo,
    cabbageAreaQuestion,
    cabbageArea,
    cabbageProductionQuantity,
    cabbageSaleQuantity,
    cabbageSale,
    //CABBAGE END
    //CAULIFLOWER START
    cauliInfo,
    cauliAreaQuestion,
    cauliArea,
    cauliProductionQuantity,
    cauliSaleQuantity,
    cauliSale,
    //CAULIFLOWER END
    //BODI START
    matarkoshaInfo,
    matarkoshaAreaQuestion,
    matarkoshaArea,
    matarkoshaProductionQuantity,
    matarkoshaSaleQuantity,
    matarkoshaSale,
    //BODI END
    //SIMI START
    simiInfo,
    simiAreaQuestion,
    simiArea,
    simiProductionQuantity,
    simiSaleQuantity,
    simiSale,
    //SIMI END
    //TOMATO START
    tomatoInfo,
    tomatoAreaQuestion,
    tomatoArea,
    tomatoProductionQuantity,
    tomatoSaleQuantity,
    tomatoSale,
    //TOMATO END
    //CUCUMBER START
    cucumberInfo,
    cucumberAreaQuestion,
    cucumberArea,
    cucumberProductionQuantity,
    cucumberSaleQuantity,
    cucumberSale,
    //CUCUMBER END
    //LAUKA START
    laukaInfo,
    laukaAreaQuestion,
    laukaArea,
    laukaProductionQuantity,
    laukaSaleQuantity,
    laukaSale,
    //LAUKA END
    dhanyaInfo,
    dhanyaAreaQuestion,
    dhanyaArea,
    dhanyaProductionQuantity,
    dhanyaSaleQuantity,
    dhanyaSale,
    //FARSI START
    saagInfo,
    saagAreaQuestion,
    saagArea,
    saagProductionQuantity,
    saagSaleQuantity,
    saagSale,
    //FARSI END
    //PARWAL START
    parwalInfo,
    parwalAreaQuestion,
    parwalArea,
    parwalProductionQuantity,
    parwalSaleQuantity,
    parwalSale,
    //PARWAL END
    //KARELA START
    karelaInfo,
    karelaAreaQuestion,
    karelaArea,
    karelaProductionQuantity,
    karelaSaleQuantity,
    karelaSale,
    //KARELA END
    //GHIRAULA START
    ghiraulaInfo,
    ghiraulaAreaQuestion,
    ghiraulaArea,
    ghiraulaProductionQuantity,
    ghiraulaSaleQuantity,
    ghiraulaSale,
    //GHIRAULA END
    //CHICHINDO START
    chichindoInfo,
    chichindoAreaQuestion,
    chichindoArea,
    chichindoProductionQuantity,
    chichindoSaleQuantity,
    chichindoSale,
    //CHICHINDO END
    //ANYATARKARI START
    anyTarkariKheti,
    agriVegetableType,
    anyTarkariInfo,
    anyTarkariInfoTitle,
    anyTarkariKhetiInfo,
    anyTarkariKhetiAreaQuestion,
    anyTarkariKhetiArea,
    anyaProductionQuantity,
    anyaSaleQuantity,
    anyaSale,
    //ANYATARKARI END
    //VEGETABLE END
    //FRUIT START
    agriFruitTitle,
    agriFruitType,
    mangoInfo,
    mangoAreaQuestion,
    mangoArea,
    mangoProduction,
    mangoProductionQuantity,
    mangoSale,
    mangoSaleQuantity,
    aarubakhadaInfo,
    aarubakhadaAreaQuestion,
    aarubakhadaArea,
    aarubakhadaProduction,
    aarubakhadaProductionQuantity,
    aarubakhadaSale,
    aarubakhadaSaleQuantity,
    bananaInfo,
    bananaAreaQuestion,
    bananaArea,
    bananaProduction,
    bananaProductionQuantity,
    bananaSale,
    bananaSaleQuantity,
    orangeInfo,
    orangeAreaQuestion,
    orangeArea,
    orangeProduction,
    orangeProductionQuantity,
    orangeSale,
    orangeSaleQuantity,
    guvaInfo,
    guvaAreaQuestion,
    guvaArea,
    guvaProduction,
    guvaProductionQuantity,
    guvaSale,
    guvaSaleQuantity,
    lemonInfo,
    lemonAreaQuestion,
    lemonArea,
    lemonProduction,
    lemonProductionQuantity,
    lemonSale,
    lemonSaleQuantity,
    appleInfo,
    appleAreaQuestion,
    appleArea,
    appleProduction,
    appleProductionQuantity,
    appleSale,
    appleSaleQuantity,
    kiwiInfo,
    kiwiAreaQuestion,
    kiwiArea,
    kiwiProduction,
    kiwiProductionQuantity,
    kiwiSale,
    kiwiSaleQuantity,
    naspatiInfo,
    naspatiAreaQuestion,
    naspatiArea,
    naspatiProduction,
    naspatiProductionQuantity,
    naspatiSale,
    naspatiSaleQuantity,
    pulamInfo,
    pulamAreaQuestion,
    pulamArea,
    pulamProduction,
    pulamProductionQuantity,
    pulamSale,
    pulamSaleQuantity,
    DraganfruitInfo,
    DraganfruitAreaQuestion,
    DraganfruitArea,
    DraganfruitProduction,
    DraganfruitProductionQuantity,
    DraganfruitSale,
    DraganfruitSaleQuantity,
    pomegranateInfo,
    pomegranateAreaQuestion,
    pomegranateArea,
    pomegranateProduction,
    pomegranateProductionQuantity,
    pomegranateSale,
    pomegranateSaleQuantity,
    pineappaleInfo,
    pineappaleAreaQuestion,
    pineappaleArea,
    pineappaleProduction,
    pineappaleProductionQuantity,
    pineappaleSale,
    pineappaleSaleQuantity,
    kafalInfo,
    kafalAreaQuestion,
    kafalArea,
    kafalProduction,
    kafalProductionQuantity,
    kafalSale,
    kafalSaleQuantity,
    agriAnyFruit,
    agriAnyFruitInfo,
    agriAnyInfoFruitTitle,
    agriAnyFruitKhetiInfo,
    agriAnyFruitKhetiAreaQuestion,
    agriAnyFruitKhetiArea,
    agriAnyFruitKhetiProduction,
    agriAnyFruitKhetiProductionQuantity,
    agriAnyFruitKhetiSale,
    agriAnyFruitKhetiSaleQuantity,

    //FRUIT END

    //CASHCROP START
    cashCropType,
    dhasingareInfo,
    dhasingareAreaQuestion,
    dhasingareArea,
    dhasingareProduction,
    dhasingareProductionQuantity,
    dhasingareSale,
    dhasingareSaleQuantity,
    chillyInfo,
    chillyAreaQuestion,
    chillyArea,
    chillyProduction,
    chillyProductionQuantity,
    chillySale,
    chillySaleQuantity,
    rudrakshInfo,
    rudrakshAreaQuestion,
    rudrakshArea,
    rudrakshProduction,
    rudrakshSale,
    amlisoInfo,
    amlisoAreaQuestion,
    amlisoArea,
    amlisoProduction,
    amlisoProductionQuantity,
    amlisoSale,
    amlisoSaleQuantity,
    coffeeInfo,
    coffeeTreeNumber,
    coffeeProduction,
    coffeeSale,
    teaInfo,
    teaAreaQuestion,
    teaArea,
    teaProduction,
    teaSale,
    nibuwaInfo,
    nibuwaAreaQuestion,
    nibuwaArea,
    nibuwaProduction,
    nibuwaProductionQuantity,
    nibuwaSale,
    nibuwaSaleQuantity,
    gingerInfo,
    gingerAreaQuestion,
    gingerArea,
    gingerProduction,
    gingerProductionQuantity,
    gingerSale,
    gingerSaleQuantity,
    besaarInfo,
    besaarAreaQuestion,
    besaarArea,
    besaarProduction,
    besaarProductionQuantity,
    besaarSale,
    besaarSaleQuantity,
    garlicInfo,
    garlicAreaQuestion,
    garlicArea,
    garlicProduction,
    garlicProductionQuantity,
    garlicSale,
    garlicSaleQuantity,
    onionInfo,
    onionAreaQuestion,
    onionArea,
    onionProduction,
    onionProductionQuantity,
    onionSale,
    onionSaleQuantity,
    mushroomInfo,
    mushroomAreaQuestion,
    mushroomArea,
    mushroomProduction,
    mushroomProductionQuantity,
    mushroomSale,
    mushroomSaleQuantity,
    sugarcaneInfo,
    sugarcaneAreaQuestion,
    sugarcaneArea,
    sugarcaneProduction,
    sugarcaneProductionQuantity,
    sugarcaneSale,
    sugarcaneSaleQuantity,
    okharInfo,
    okharAreaQuestion,
    okharArea,
    okharProduction,
    okharProductionQuantity,
    okharSale,
    okharSaleQuantity,
    coconutInfo,
    coconutAreaQuestion,
    coconutArea,
    coconutProduction,
    coconutProductionQuantity,
    coconutSale,
    coconutSaleQuantity,
    anyCashCropKheti,
    anyCashCropKhetiInfo,
    anyCashCropInfoTitle,
    anyCashCropInfo,
    anyCashCropAreaQuestion,
    anyCashCropArea,
    anyCashCropProduction,
    anyCashCropProductionQuantity,
    anyCashCropSale,
    anyCashCropSaleQuantity,
    //CASHCROP END
    //FLOWER START
    flowerTitle,
    flowerAreaQuestion,
    flowerArea,
    flowerProduction,
    flowerSale,
    flowerSaleQuantity,
    //FLOWER END
    //INSECTICIDES START
    insecticideQuestion,
    insecticideYesNo,
    insecticideYes,
    insecticideType,
    //INSECTICIDES END

    //LIVESTOCKS START
    //ANIMAL START
    livestockQuestion,
    livestockYesNo,
    livestockYes,
    livestockType,
    livestockTitle,
    livestockDiffType,
    allLivestock,
    cowInfo,
    cowNumber,
    cowMilkProduction,
    cowMilkProductionSale,
    cowMilkSale,
    buffalloInfo,
    buffalloNumber,
    buffalloMilkProduction,
    buffalloMilkProductionSale,
    buffalloMilkSale,
    khasiInfo,
    khasiNumber,
    khasiMeatProduction,
    khasiMeatIncome,
    goatInfo,
    goatNumber,
    goatMeatIncome,
    rangaInfo,
    rangaNumber,
    rangaMeatProduction,
    rangaMeatIncome,
    pigInfo,
    pigNumber,
    pigMeatProduction,
    pigMeatIncome,
    sheepInfo,
    sheepNumber,
    sheepMeatProduction,
    sheepMeatIncome,
    ChaurigaaiInfo,
    ChaurigaaiNumber,
    ChaurigaaiMeatProduction,
    ChaurigaaiMeatIncome,
    oxInfo,
    oxNumber,
    oxProduction,
    oxMeatIncome,
    gadhaInfo,
    gadhaNumber,
    gadhaProduction,
    gadhaMeatIncome,
    horseInfo,
    horseNumber,
    calfInfo,
    calfNumber,
    calfMilkProduction,
    calfProduction,
    calfMeatIncome,
    chyangraInfo,
    chyangraNumber,
    chyangraProduction,
    chyangraMeatIncome,
    anyAnimal,
    anyAnimalInfo,
    anyAnimalTitle,
    anyAnimalInfoTitle,
    animalNumber,
    animalProduction,
    animalMeatIncome,
    //ANIMAL END
    //BIRD START
    birdTitle,
    birdDiffType,
    henInfo,
    henNumber,
    henMeatProduction,
    henEggSale,
    henMeatIncome,
    duckInfo,
    duckNumber,
    duckMeatProduction,
    duckEggSale,
    duckMeatIncome,
    ostrichInfo,
    ostrichNumber,
    ostrichMeatProduction,
    ostrichMeatIncome,
    pegionInfo,
    pegion,
    pegionMeatIncome,
    anyBird,
    anyBirdInfo,
    anyBirdTitle,
    anyBirdInfoTitle,
    birdNumber,
    birdProduction,
    birdMeatIncome,
    //BIRD END
    //LIVESTOCKS END
    //BEEFISHSILK START
    beefishsilkQuestion,
    beefishsilkYesNo,
    beefishsilkYes,
    beefishsilkType,
    fishTitle,
    totalPond,
    pondArea,
    fishProduction,
    beeTitle,
    totalGhaar,
    beeProduction,
    reshamTitle,
    reshamGhaar,
    reshamProduction,
    totalBeefidhsilkIncome,
    //BEEFISHSILK END

    // family details
    ageQuestion,
    dateQuestion,
    memberName,
    contactNo,
    genderQuestion,
    gender,
    livingStatusQuestion,
    livingStatus,
    abroadReasonQuestion,
    abroadReason,
    abroadYearQuestion,
    abroadCountryQuestion,
    abroadCountry,
    casteQuestion,
    casteList,
    aadibasiQuestion,
    aadibasi,
    // pahadiQuestion,
    // pahadi,
    religionQuestion,
    religionList,
    marriageQuestion,
    marriage,
    isGoingToSchool,
    isGoingToSchoolQn,
    notGoingSchoolQn,
    notGoingSchool,
    educationQuestion,
    education,
    studyFieldQn,
    studyField,
    preprimaryQuestion,
    preprimary,
    primaryQuestion,
    primary,
    secondaryQuestion,
    secondary,
    occupationQuestion,
    occupation,
    jobQuestion,
    job,
    securityQuestion,
    security,
    teacherQuestion,
    teacher,
    industryQuestion,
    industry,
    healthconditionQuesiton,
    healthcondition,
    lessThanSixBirthCertificate,
    birthCertificateQn,
    unhealthyQuestion,
    unhealthy,
    mentalYesNoQuestion,
    mentaltype,
    mentalconditionQuestion,
    mentalcondition,
    mentalyesno,
    mentalCardYesNo,
    mentalCardtype,
    mentalCardQuestion,
    mentalCardTypeQuestion,
    mentaltypeQuestion,
    mothertongueQuestion,
    mothertongue,
    raiQuestion,
    raiType,
    bloodgroupQuestion,
    bloodgroup,
    votercardQuestion,
    votercard,
    //family details ends

    businessLists,
    herbLists,
    insuranceLists,
    insuranceQuestion,
    insuranceYesNo,

    // secondaryHouse
    drinkingwaterQuestion,
    drinkingwaterLists,
    drinkingwaterplaceQuestion,
    drinkingwaterplaceLists,
    cookingresourceQuestion,
    lightresourceQuestion,
    lightresourceLists,
    isElectricMeterQn,
    isElectricMeter,
    cookingresourceLists,
    toilettypeQuestion,
    toiletYesNo,
    toiletNoQuestion,
    noToiletLists,
    toiletYesNoUseQn,
    toiletYesNoUse,
    toiletresourceLists,
    roadQuestion,
    roadLists,
    bankaccountYesNo,
    bankaccountQuestion,
    // Secondary house detail ends

    // women child

    numberOfBankAccount,
    bankAccountMale,
    bankAccountFemale,
    salttypeQuestion,
    salttypeLists,
    lifeInsurance,
    healthInsurance,
    nonlifeInsurance,
    animalInsurance,
    insuranceOther,
    insuranceListQuestion,
    wasteQuestion,
    wastemanagementLists,
    birthCertificateQuestion,
    birthCertificate,
    contraceptiveUseQuestion,
    contraceptiveUse,
    deliveryFromDoctorQuestion,
    deliveryFromDoctor,
    childDeathQuestion,
    childDeath,
    childDeathCount,
    pregnanctCheckQuestion,
    pregnanctCheck,
    childNutritionQuestion,
    childNutrition,
    childLabourQuestion,
    childLabour,
    childLaborQuestion,
    earlyMarriageQuestion,
    earlyMarriage,
    pregnantTetanusQuestion,
    pregnantTetanus,
    illTreamentQuestion,
    illTreament,
    govHospitalNameQuestion,
    govHospitalName,
    isChildMarriageExistsQn,
    isChildMarriageExists,
    childMarriageExistsQn,
    childMarriageExists,
    controlChildMarriageQn,
    controlChildMarriageList,
    isRevolutionCaseQn,
    isRevolutionCase,
    protestTypeQn,
    protestType,
    whichSideForProtestQn,
    whichSideForProtest,
    effectFromProtestQn,
    effectFromProtest,
    effectFromCoronaQn,
    effectFromCorona,
    // women child ends

    // social welfare
    bhattaQuestion,
    bhattaLists,
    nottakenbhattamale,
    nottakenbhattafemale,
    govpension,
    othercountrypension,
    oldagemen,
    oldagewomen,
    widow,
    handicapmale,
    handicapfemale,
    dalitMale,
    dalitFemale,
    scholarshipmale,
    scholarshipfemale,
    welfareYesNoQuestion,
    welfareYesNo,
    // social welfare ends

    // house
    houseproblem,
    suggestion,

    // primaryHouse
    wardQuestion,
    wardNumber,
    allToleName,
    gharNumber,
    toleOneQn,
    toleTwoQn,
    toleThreeQn,
    toleFourQn,
    toleFiveQn,
    toleSixQn,

    toleWardOne,
    toleWardTwo,
    toleWardThree,
    toleWardFour,
    toleWardFive,
    toleWardSix,

    sabikGabisaQuestion,
    sabikGabisa,
    sabikWard,
    tole,
    toleNumber,
    streetName,
    answererName,
    answererRelationQuestion,
    answererRelation,
    familyMale,
    familyFemale,
    familyThirdGender,
    totalMember,
    geoLocation,
    latitude,
    longitude,
    housePhoto,
    livingtypeQuestion,
    livingType,
    permanentQuestion,
    permanent,
    cashCropTitle,
    // primaryHouse ends

    // Business start
    businesssQn,
    businessRadio,
    businessListQuestion,
    herbRadio,
    herbListQuestion,
    herbQn,
    //business ends

    //houseFacility
    houseFacilityQuestion,
    houseFacility,
    motorcycle,
    car,
    jeep,
    scooter,
    tractor,
    truck,
    auto,
    tempo,
    dozar,
    Tipper,
    ELECTRICVEHICLE,
    safari,
    other,
    otherCount,
    //ends
    stepperStep,
    agriDhanProduction,
    agriDhanProductionQuantity,
    agriDhanSale,
    agriDhanSaleQuantity,
    totallandAreaTitle,
    totalLandArea,
    totalFamilyLandQn,
    totalFamilyLand,
    elaaniQn,
    nambariQn,
    landforAgriYesNo,
    landforAgriYes,
    landforAgri,
    landforAgriList,
    irrigationForLand,
    irrigationForLandList,
    seedNeed,
    seedNeedQuestion,
    otherLand,
    otherLandArea,
    ownLand,
    ownLandArea,
    agricultureTitle,
    irrigation,
    mal,
    malType,
    waliType,
    wali,
    area,
    productionShow,
    sales,
    production,
    expense,
    agriInfo,
    yearlyInfo,
    beefishTitle,
    beefishType,
    beefishTotal,
    beefishProduction,
    fish,
    silk,
    bee,
    beefishYesNo,
    businessQ,
    businessType,
    businessTypeName,
    businessProfit,
    businessName,
    herbName,
    gharTitle,
    genderMale,
    genderFemale,
    genderThird,
    genderTotal,
    economic,
    mainincome,
    yearlyIncome,
    yearlyExpenditure,
    loanGiven,
    loanGivenInterest,
    loanTaken,
    loanType,
    loanInterest,
    houseFacilityTitle,
    facilityType,
    facilityCount,
    childMarriageHeader,
    familyTitle,
    waterSource,
    cookingSource,
    electricSource,
    isElectricityMeterQn,
    toiletType,
    toiletQuestion,
    toiletTypeNo,
    toiletTypeNoUse,
    anyBankAccount,
    numberofBankAccount,
    homeAge,
    saltUsed,
    hasinsur,
    insuranceDetail,
    totalHouseCount,
    totalMatanCount,
    gharJaggaTitle,
    totalHouseRoom,
    totalHouseStorey,
    houseMapPass,
    houseCriteriaFullfill,
    houseLandUsedLiving,
    houseroofType,
    hoousesType,
    wasteDisposal,
    road,
    houseRent,
    houseConstructionCertificate,
    totalArea,
    ropani,
    aana,
    paisa,
    liveStockInfo,
    livestockName,
    livestockDetail,
    animalCount,
    annualIncome,
    skills,
    skillType,
    skillMaleNo,
    skillFemaleNo,
    skillYesNo,
    welfare,
    welfareType,
    welfareMale,
    welfareFemale,
    bhattaYesNo,
    dirtyWaterQuestion,
    dirtyWaterManagement,
    riskAreaAroundHouseQn,
    riskAreaAroundHouse,
    meansOfMunicipalInformationQn,
    meansOfMunicipalInformation,
    waterPurificationQn,
    waterPurification,
    womenchild,
    childLabor,
    citizenshipFrontPhoto,
    citizenshipBackPhoto,
    ifOtherValueQuestion,
    houseOwnerNameQn,
    houseOwnerContactQn,
    houseOwnerEmailQn,
    firstMarriageAgeQn,
    disabilitytypeQuestion,
    disabilitytype,
    socialSecurityAllowanceList,
    socialSecurityAllowanceQn,
    landUsageQn,
    landUsageList,
    adhiyaThekkaYesQn,
    adhiyaThekkaList,
    forestUseYesNoQn,
    forestUseYesNo,
    forestUseYesQn,
    forestRegistered,
    privateForestQn,
    skillTrainingDurationQn,
    skillJobQn,
    skillJobYesNo,
    skillJobNoQn,
    skillJobNoList,
    agriIncomeQn,
    nonAgriIncomeQn,
    agriExpenseQn,
    nonAgriExpenseQn,
    mainInvestmentQn,
    mainInvestment,
    mainBusinessQn,
    mainBusiness,
    electricityUsageQn,
    electricityUsageYesNo,
    electricityUsageYesQn,
    electricityUsage,
    ifDiseaseDeathQn,
    deathDiseaseList,
    agricultureProductionOrNot,
    houseMeterQn,
    houseMeter,
    safetyTankCleaningIntervalTimeQn,
    wasteProducedPerWeekQn,
    roadConditionHouseQn,
    roadConditionHouse,
    vehicleCountQn,
    otherHouseLocationQn,
    otherHouseLocation,
    houseFoundationQn,
    houseFoundation,
    familyRiskPlanQn,
    familyRiskPlan,
    familyRiskPlanYesQn,
    womanOwnedHomeQn,
    womanOwnedHome,
    aanabaliLandUsed,
    unhealthyTreatmentQn,
    unhealthyTreatment,
    familyInOrgQn,
    familyInOrg,
    disputeResolveQn,
    disputeResolve,
    covidEffectQn,
    covidEffect,
    suggestionQn,
    newsMediumQn,
    newsMedium,
    socialSecurityAllowanceDetail,
    otherLandAreaBighaKattha,
};
