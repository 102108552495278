import React from "react";
import { Card } from "react-bootstrap";
import {
    mainInvestment,
    economic,
    loanTakingReason,
    mainIncomeSource,
} from "../../data/houseSurvey";
import { selectedLanguage } from "../../data/institute";

const AarthikKshetraJankari = ({ economics }) => {
    return (
        <Card>
            <Card.Body className="house-detail">
                <Card.Title>
                    <h3>आर्थिक क्षेत्र जानकारी</h3>
                </Card.Title>
                <h6>
                    वार्षिक आम्दानीको मुख्य स्रोत :{" "}
                    {mainIncomeSource.find(
                        (i) => i.value === economics.mainIncomeSource
                    )
                        ? mainIncomeSource.find(
                              (i) => i.value === economics.mainIncomeSource
                          )[selectedLanguage]
                        : ""}
                </h6>
                <h6>
                    आम्दानीको मुख्य अंश कुन क्षेत्रमा लगानी गर्नुहुन्छ? :{" "}
                    {mainInvestment.find(
                        (i) => i.value === economics.investment
                    )
                        ? mainInvestment.find(
                              (i) => i.value === economics.investment
                          )[selectedLanguage]
                        : ""}
                </h6>
                <h6>सरदर वार्षिक खर्च (कृषि) : {economics.agriExpense}</h6>
                <h6> सरदर वार्षिक आम्दानी (कृषि) : {economics.agriIncome}</h6>
                <h6>
                    {" "}
                    सरदर वार्षिक खर्च (गैर कृषि) : {economics.totalExpenditure}
                </h6>
                <h6>
                    {" "}
                    सरदर वार्षिक आम्दानी (गैर कृषि) : {economics.totalIncome}
                </h6>
                <h6>
                    ऋण लिनु भएको छ : {economics.loanTakenInFamily ? "छ" : "छैन"}
                </h6>
                {economics.loanTakenInFamily && (
                    <h6>
                        ऋणको उदेश्यके हो ? :{" "}
                        {economics.loanTakingReason.map((reason) => {
                            return loanTakingReason.find(
                                (i) => i.value === reason
                            )
                                ? loanTakingReason.find(
                                      (i) => i.value === reason
                                  )[selectedLanguage] + ", "
                                : "";
                        })}
                    </h6>
                )}
                {/* <h6>8. ऋणको प्रकर : {drinkingwaterLists.find(i => i.value === economics.waterSource) ? drinkingwaterLists.find(i => i.value === economics.waterSource)[selectedLanguage] : ""}</h6> */}
            </Card.Body>
        </Card>
    );
};

export default AarthikKshetraJankari;
