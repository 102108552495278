import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { Card } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import FormFooterButtons from "../components/FormFooterButtons";
import {
    wardList,
    toleWardOne,
    toleWardTwo,
    toleWardThree,
    toleWardFour,
    toleWardFive,
    toleWardSix,
    toleWardSeven,
    toleWardEight,
    toleWardTen,
    toleWardNine,
    toleWardEleven,
    toleWardTwelve,
} from "../data/ToleList";
import { wardQuestion, selectedLanguage } from "../data/institute";
import {
    toleFiveQn,
    toleFourQn,
    toleOneQn,
    toleThreeQn,
    toleTwoQn,
    toleSixQn,
    toleSevenQn,
    toleEightQn,
    toleNineQn,
    toleTenQn,
    toleElevenQn,
    toleTwelveQn,
} from "../data/houseSurvey";

const School = (props) => {
    const { formik } = props;
    const [gharWardNumber, setWardNumber] = useState(true);
    const [selectedWard, setSelectedWard] = useState(0);
    const wardData = [
        {
            question: toleOneQn,
            toleList: toleWardOne,
        },
        {
            question: toleTwoQn,
            toleList: toleWardTwo,
        },
        {
            question: toleThreeQn,
            toleList: toleWardThree,
        },
        {
            question: toleFourQn,
            toleList: toleWardFour,
        },
        {
            question: toleFiveQn,
            toleList: toleWardFive,
        },
        {
            question: toleSixQn,
            toleList: toleWardSix,
        },
        {
            question: toleSevenQn,
            toleList: toleWardSeven,
        },
        {
            question: toleEightQn,
            toleList: toleWardEight,
        },
        {
            question: toleNineQn,
            toleList: toleWardNine,
        },
        {
            question: toleTenQn,
            toleList: toleWardTen,
        },
        {
            question: toleElevenQn,
            toleList: toleWardEleven,
        },
        {
            question: toleTwelveQn,
            toleList: toleWardTwelve,
        },
    ];
    const getToleList = (ward) => {
        if (ward == 1) {
            return toleWardOne;
        } else if (ward == 2) {
            return toleWardTwo;
        } else if (ward == 3) {
            return toleWardThree;
        } else if (ward == 4) {
            return toleWardFour;
        } else if (ward == 5) {
            return toleWardFive;
        } else if (ward == 6) {
            return toleWardSix;
        } else if (ward == 7) {
            return toleWardSeven;
        } else if (ward == 8) {
            return toleWardEight;
        } else if (ward == 9) {
            return toleWardNine;
        } else if (ward == 10) {
            return toleWardTen;
        } else if (ward == 11) {
            return toleWardEleven;
        } else {
            return toleWardTwelve;
        }
    };
    const handleWardChange = (ward) => {
        setSelectedWard(ward);
    };
    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={
                    props.data
                        ? props.data
                        : { name: "", ward: "", address: "" }
                }
                validate={(values) => {
                    const errors = {};
                    // if (!values.name) {
                    //     errors.name = 'Required';
                    // }
                    // if (!values.ward) {
                    //     errors.ward = 'Required';
                    // }
                    // if (!values.address) {
                    //     errors.address = 'Required';
                    // }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        props.submit(values);
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                }) => (
                    <>
                        <form
                            className="form survey-form"
                            onSubmit={handleSubmit}
                        >
                            <Card>
                                <Card.Body>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शैक्षिक संस्थाको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="शैक्षिक संस्थाको नाम"
                                                name="name"
                                                onChange={handleChange}
                                                value={values.name}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="name"
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                {wardQuestion[selectedLanguage]}
                                            </label>
                                            <select
                                                className="form-control"
                                                id="wardNumber"
                                                name="wardNumber"
                                                onChange={handleChange}
                                                handleWardChange={handleWardChange(
                                                    values.wardNumber
                                                )}
                                                value={
                                                    values.wardNumber
                                                        ? values.wardNumber
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                {wardList.map((woda) => (
                                                    <option value={woda.value}>
                                                        {woda[selectedLanguage]}
                                                    </option>
                                                ))}
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="wardNumber"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        {values.wardNumber && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    {
                                                        wardData[
                                                            values.wardNumber -
                                                                1
                                                        ].question[
                                                            selectedLanguage
                                                        ]
                                                    }
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="toleName"
                                                    name="toleName"
                                                    onChange={handleChange}
                                                    value={
                                                        values.toleName
                                                            ? values.toleName
                                                            : "selectOne"
                                                    }
                                                >
                                                    <option
                                                        value="selectOne"
                                                        disabled
                                                    >
                                                        कुनै एक छान्नुहोस्
                                                    </option>
                                                    {getToleList(
                                                        values.wardNumber
                                                    ).map((woda) => (
                                                        <option
                                                            value={woda.value}
                                                        >
                                                            {
                                                                woda[
                                                                    selectedLanguage
                                                                ]
                                                            }
                                                        </option>
                                                    ))}
                                                </select>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="ward"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                मार्गको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="मार्गको नाम"
                                                name="streetName"
                                                onChange={handleChange}
                                                value={values.streetName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="streetName"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शैक्षिक संस्थाको प्रकार
                                            </label>
                                            <select
                                                className="form-control"
                                                id="instituteType"
                                                name="instituteType"
                                                onChange={handleChange}
                                                handleWardChange={handleWardChange(
                                                    values.instituteType
                                                )}
                                                value={
                                                    values.instituteType
                                                        ? values.instituteType
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                <option>
                                                    सँस्थागत (निजि )
                                                </option>
                                                <option>सामुदायिक</option>
                                                <option>गुम्बा</option>
                                                <option>वेद विद्याश्रम</option>
                                                <option>मदरसा</option>
                                                <option>विश्वविद्यालय</option>
                                                <option>क्याम्पस</option>
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="instituteType"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शैक्षिक सँस्थाको तह
                                            </label>
                                            <select
                                                className="form-control"
                                                id="instituteLevel"
                                                name="instituteLevel"
                                                onChange={handleChange}
                                                handleWardChange={handleWardChange(
                                                    values.instituteLevel
                                                )}
                                                value={
                                                    values.instituteLevel
                                                        ? values.instituteLevel
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                <option value="child">
                                                    बाल विकास केन्द्र
                                                </option>
                                                <option value="primary_up_to_8">
                                                    आधारभूत तह
                                                </option>
                                                <option value="maadhyamik">
                                                    माध्यमिक तह
                                                </option>
                                                <option value="bachlors">
                                                    स्नातक तह
                                                </option>
                                                <option value="masters">
                                                    स्नातकोत्तर तह
                                                </option>
                                                <option value="other">
                                                    अन्य
                                                </option>
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="instituteLevel"
                                                component="div"
                                            />
                                        </div>
                                        {values.instituteLevel == "other" && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    अन्य भए उल्लेख गर्नुहोस |
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="अन्य भए उल्लेख गर्नुहोस |"
                                                    name="instituteLevelOther"
                                                    onChange={handleChange}
                                                    value={
                                                        values.instituteLevelOther
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="instituteLevelOther"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शैक्षिक संस्थामा पढाईहुने कक्षा
                                                / संकाय
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="शैक्षिक संस्थामा पढाईहुने कक्षा / संकाय"
                                                name="classCount"
                                                onChange={handleChange}
                                                value={values.classCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="classCount"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शैक्षिक संस्थाको प्रध्यानध्यापक
                                                /प्रमुख को नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="शैक्षिक संस्थाको प्रध्यानध्यापक /प्रमुख को नाम"
                                                name="principalName"
                                                onChange={handleChange}
                                                value={values.principalName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="principalName"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शैक्षिक संस्थाको सम्पर्क
                                                व्यक्तिको सम्पर्क नम्बर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="शैक्षिक संस्थाको सम्पर्क व्यक्तिको सम्पर्क नम्बर"
                                                name="contactNumber"
                                                onChange={handleChange}
                                                value={values.contactNumber}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="contactNumber"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शैक्षिक संस्थाको भवन सङ्ख्या
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="शैक्षिक संस्थाको भवन सङ्ख्या"
                                                name="buildingCount"
                                                onChange={handleChange}
                                                value={values.buildingCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="buildingCount"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                जम्मा पक्की भवन सङ्ख्या ?
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="जम्मा पक्की भवन सङ्ख्या ?"
                                                name="pakkiCount"
                                                onChange={handleChange}
                                                value={values.pakkiCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="pakkiCount"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                जम्मा कची भवन सङ्ख्या ?
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="जम्मा कची भवन सङ्ख्या ?"
                                                name="kachhiCount"
                                                onChange={handleChange}
                                                value={values.kachhiCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="kachhiCount"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शैक्षिक संस्थाको कोठा सङ्ख्या
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="शैक्षिक संस्थाको कोठा सङ्ख्या"
                                                name="roomCount"
                                                onChange={handleChange}
                                                value={values.roomCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="roomCount"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                जम्मा पक्की कोठा सङ्ख्या ?
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="जम्मा पक्की कोठा सङ्ख्या ?"
                                                name="pakkiRoomCount"
                                                onChange={handleChange}
                                                value={values.pakkiRoomCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="pakkiRoomCount"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                जम्मा कची कोठा सङ्ख्या ?
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="जम्मा कची कोठा सङ्ख्या ?"
                                                name="kachhiRoomCount"
                                                onChange={handleChange}
                                                value={values.kachhiRoomCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="kachhiRoomCount"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शैक्षिक संस्थाको भोग चलन गरेको
                                                जम्मा जग्गा क्षेत्रफल (कट्ठामा
                                                उल्लेख गर्नुहोस् ):
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="शैक्षिक संस्थाको भोग चलन गरेको जम्मा जग्गा क्षेत्रफल (कट्ठामा उल्लेख गर्नुहोस् ):"
                                                name="totalLandUsed"
                                                onChange={handleChange}
                                                value={values.totalLandUsed}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="totalLandUsed"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शैक्षिक संस्थाकोनाममा रहेको
                                                जग्गा निजि हो वा भाडामा हो ?
                                            </label>
                                            {/* <input
                                                type="checkbox"
                                                className="form-control"
                                                name="isLandPrivateOrRent"
                                                onChange={handleChange}
                                                value="निजि"
                                            /> */}
                                            <div
                                                style={{
                                                    float: "left",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                <Checkbox
                                                    value="own"
                                                    name="isLandPrivateOrRent"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    inputProps={{
                                                        "aria-label": "निजि",
                                                    }}
                                                />
                                                <span>भाडामा</span>

                                                <Checkbox
                                                    value="rent"
                                                    name="isLandPrivateOrRent"
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    inputProps={{
                                                        "aria-label": "भाडामा",
                                                    }}
                                                />
                                                <span>भाडामा</span>
                                            </div>

                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="isLandPrivateOrRent"
                                                component="div"
                                            />
                                        </div>

                                        {values.isLandPrivateOrRent ==
                                            "own" && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    शैक्षिक संस्थाको नाममा रहेको
                                                    जग्गा क्षेत्रफल: (कट्ठामा
                                                    उल्लेख गर्नुहोस् )
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="शैक्षिक संस्थाको भोग चलन गरेको जम्मा जग्गा क्षेत्रफल (कट्ठामा उल्लेख गर्नुहोस् ):"
                                                    name="totalLandUsed"
                                                    onChange={handleChange}
                                                    value={values.totalLandUsed}
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="totalLandUsed"
                                                    component="div"
                                                />
                                            </div>
                                        )}

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शैक्षिक संस्थाको प्रयोग गरेको
                                                जग्गाको लालपुर्जा छ /छैन ?
                                            </label>
                                            <select
                                                className="form-control"
                                                id="hasLandDocument"
                                                name="hasLandDocument"
                                                onChange={handleChange}
                                                value={values?.hasLandDocument}
                                            >
                                                <option value="yes">छ</option>
                                                <option value="no">छैन</option>
                                                <option value="unknow">
                                                    थाहा नभएको
                                                </option>
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="hasLandDocument"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                रेट्रोफिटिङ्ग गरेको छ /छैन ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasRetrofitting"
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasRetrofitting"
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                अपाङ्गता तथा विशेष शिक्षा स्रोत
                                                कक्षा संचालन :
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasDisabilityResourceClass"
                                                />
                                                <span></span>भएको
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasDisabilityResourceClass"
                                                />
                                                <span></span>
                                                नभएको
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शौचालय छ कि छैन ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasToilet"
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasToilet"
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                पुरुष /महिलाका (लैङ्गिकमैत्री )
                                                लागि छुट्टा छुटै शौचालय छ कि छैन
                                                ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasDiffToilet"
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasDiffToilet"
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                अपाङ्गमत्रैी शौचालय छ कि छैन ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasDisableToilet"
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasDisableToilet"
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                हात धनुेठाउँको व्यवस्था ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasHandWashing"
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasHandWashing"
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                स्वच्छ खानेपानीको व्यवस्था ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasDrinkingWater"
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasDrinkingWater"
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                फोहर व्यस्थापन गर्न डस्टविन छ कि
                                                छैन ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasDustbin"
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasDustbin"
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                फोहरमैला कसरी व्यवस्थापन गर्नु
                                                हुन्छ ?
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="फोहरमैला कसरी व्यवस्थापन गर्नु
                                                हुन्छ ?"
                                                name="garbageMgmt"
                                                onChange={handleChange}
                                                value={values.garbageMgmt}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="garbageMgmt"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                विज्ञान प्रयोगशाला छ कि छैन ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasScienceLab"
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasScienceLab"
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                पुस्तकालय र अध्ययन केन्द्र ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasLibrary"
                                                    value="yes"
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasLibrary"
                                                    value="no"
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                कमपाउण्ड वाल छ कि छैन ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasCompoundWall"
                                                    value="compound_wall_yes"
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name="hasCompoundWall"
                                                    value="compound_wall_no"
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                खेलमैदान क्षेत्रफल (वर्ग. मी) ?
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="खेलमैदान क्षेत्रफल (वर्ग. मी) ?"
                                                name="playgroundArea"
                                                onChange={handleChange}
                                                value={values.playgroundArea}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="playgroundArea"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                कम्प्युटर कति सङ्ख्यामा छन् ?
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="कम्प्युटर कति सङ्ख्यामा छन् ?"
                                                name="computerCount"
                                                onChange={handleChange}
                                                value={values.computerCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="computerCount"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शैक्षिक संस्थाको निर्माण/
                                                स्थापना भएको वर्ष ?(साल)
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="establishedYear"
                                                onChange={handleChange}
                                                value={values.establishedYear}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="establishedYear"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    {[
                                        "child",
                                        "primary_up_to_8",
                                        "maadhyamik",
                                    ].includes(values.instituteLevel) && (
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    छात्र संख्या (बाल विकास
                                                    केन्द्र)
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="छात्र संख्या (बाल विकास केन्द्र)"
                                                    name="childMale"
                                                    onChange={handleChange}
                                                    value={
                                                        values.studentCount
                                                            ?.childMale
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="childMale"
                                                    component="div"
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    छात्रा संख्या (बाल विकास
                                                    केन्द्र)
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="छात्रा संख्या (बाल विकास केन्द्र)"
                                                    name="childFemale"
                                                    onChange={handleChange}
                                                    value={
                                                        values.studentCount
                                                            ?.childFemale
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="childFemale"
                                                    component="div"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {["primary_up_to_8", "maadhyamik"].includes(
                                        values.instituteLevel
                                    ) && (
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    छात्र संख्या (आधारभूत )
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="छात्र संख्या (आधारभूत )"
                                                    name="primaryMale"
                                                    onChange={handleChange}
                                                    value={
                                                        values.studentCount
                                                            ?.primaryMale
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="primaryMale"
                                                    component="div"
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    छात्रा संख्या (आधारभूत )
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="छात्रा संख्या (आधारभूत )"
                                                    name="primaryFemale"
                                                    onChange={handleChange}
                                                    value={
                                                        values.studentCount
                                                            ?.primaryFemale
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="primaryFemale"
                                                    component="div"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {["maadhyamik"].includes(
                                        values.instituteLevel
                                    ) && (
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    छात्र संख्या (माध्यमिक )
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="छात्र संख्या (माध्यमिक )"
                                                    name="secondaryMale"
                                                    onChange={handleChange}
                                                    value={
                                                        values.studentCount
                                                            ?.secondaryMale
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="secondaryMale"
                                                    component="div"
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    छात्रा संख्या (माध्यमिक )
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="छात्रा संख्या (माध्यमिक )"
                                                    name="secondaryFemale"
                                                    onChange={handleChange}
                                                    value={
                                                        values.studentCount
                                                            ?.secondaryFemale
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="secondaryFemale"
                                                    component="div"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                महिला शिक्षक संख्या (आधारभूत)
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="महिला शिक्षक संख्या (आधारभूत)"
                                                name="primaryTeachFemale"
                                                onChange={handleChange}
                                                value={
                                                    values.teachersCount
                                                        ?.primaryTeachFemale
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="primaryTeachFemale"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                महिला शिक्षक संख्या (माध्यमिक)
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="महिला शिक्षक संख्या (माध्यमिक)"
                                                name="secondaryTeachFemale"
                                                onChange={handleChange}
                                                value={
                                                    values.teachersCount
                                                        ?.secondaryTeachFemale
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="secondaryTeachFemale"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                गणित शिक्षक संख्या
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="गणित शिक्षक संख्या"
                                                name="math"
                                                onChange={handleChange}
                                                value={
                                                    values.teachersCount?.math
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="math"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                विज्ञान शिक्षक संख्या
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="विज्ञान शिक्षक संख्या"
                                                name="science"
                                                onChange={handleChange}
                                                value={
                                                    values.teachersCount
                                                        ?.science
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="science"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                अंग्रेजी शिक्षक संख्या
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="अंग्रेजी शिक्षक संख्या"
                                                name="english"
                                                onChange={handleChange}
                                                value={
                                                    values.teachersCount
                                                        ?.english
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="english"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                जम्मा पुरुष शिक्षक संख्या
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="जम्मा पुरुष शिक्षक संख्या"
                                                name="totalMale"
                                                onChange={handleChange}
                                                value={
                                                    values.teachersCount
                                                        ?.totalMale
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="totalMale"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                जम्मा महिला शिक्षक संख्या
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="जम्मा महिला शिक्षक संख्या"
                                                name="totalFemale"
                                                onChange={handleChange}
                                                value={
                                                    values.teachersCount
                                                        ?.totalFemale
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="totalFemale"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                अक्षांश
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lat"
                                                onChange={handleChange}
                                                value={values.geo?.lat}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="lat"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                देशान्तर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lng"
                                                onChange={handleChange}
                                                value={values.geo?.lng}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="lng"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="card-footer text-right">
                                        <FormFooterButtons />
                                    </div>
                                </Card.Body>
                            </Card>
                        </form>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default School;
