import React, { useState } from "react";
import { useEffect } from "react";
import CustomSelectMulti from "../components/CustomSelectMulti";
import SingleSelectInput from "../components/SingleSelectInput";
import {
    agriExpenseQn,
    agriIncomeQn,
    unhealthyTreatmentQn,
    unhealthyTreatment,
    childLabourQuestion,
    pregnantCheckup,
    dhukutiKarobar,
    dhukutiKarobarYesNo,
    electricityUsage,
    electricityUsageQn,
    electricityUsageYesNo,
    electricityUsageYesQn,
    familyInLocalOrganQuestion,
    familyInvolvementLocalOrgan,
    familyLocalGovInvolve,
    familyLocalGovInvolveQuestion,
    foodForMonth,
    foodForMonthQuestion,
    ifOtherValueQuestion,
    loan,
    loanQuestion,
    loanTakenInFamily,
    loanTakenInFamilyQuestion,
    loanTakingReason,
    loanTakingReasonQuestion,
    mainBusiness,
    mainBusinessQn,
    mainIncomeSource,
    mainIncomeSourceQuestion,
    mainInvestment,
    mainInvestmentQn,
    nonAgriExpenseQn,
    nonAgriIncomeQn,
    pregnanctCheckQuestion,
} from "../data/houseSurvey";
import { no, selectedLanguage } from "../data/institute";
import {
    bornAliveQuestion,
    pregnantQuestion,
    babeDeathQuestion,
    pregnantNoCheckUp,
    pregnantNoCheckUpQue,
    bornPlaceQue,
    bornPlace,
    helpBornBabeQue,
    helpBornBabe,
    KhopQue,
    bornAliveYesNO,
    chhaupadiQuestion,
    chhaupadiIfYes,
    chhaupadiWhere,
} from "../data/AarthikSurvey";
import { dropWhile } from "lodash";

const ArthikBibaran = (props) => {
    useEffect(() => {}, []);
    console.log("form props", props);
    const { formik } = props;

    // agriIncome: Number,
    // nonAgriIncome: Number,
    // agriExpense: Number,
    // nonAgriExpense: Number,
    // mainIncomeSource: String,
    // investment: String,
    // smallHomeBusiness: String,
    // electronicTransaction: Boolean,
    // electronicTransactionType: [String],
    // totalIncome: Number, //वार्षिक आम्दानी
    // totalExpenditure: Number, //वार्षिक खर्च
    // foodForMonth: String, //उत्पादन वा कमाइ ले कति महिना खान पुग्छ
    // loanTakenInFamily: Boolean, //परिवारले ऋण
    // loan: [
    // 	{
    // 		name: String, // ऋण
    // 		rate: Number, //व्याज दर
    // 	},
    // ],
    // loanTakingReason: [String], //ऋणको उदेश्यके
    // cashLoanGiven: Boolean, // नगद ऋण दिनु भएको छ
    // cashLoanRate: Number, // व्याज दर
    //     dhukutiKarobar: "yes"
    // economics: "1"
    // familyInvolvementLocalOrgan: "local_yes"
    // familyLocalGovInvolve: ["local_gov", "local_school", "agri_group"]
    // foodForMonth: "4_6_month"
    // loan: ["loan_bank", "loan_person", "loan_sahakari", "loan_laghubitt"]
    // loanTakenInFamily: "loan_yes"
    // loanTakingReason:
    const { economics } = formik.values;
    return (
        <>
            <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                <div class="col-xl-12 col-xxl-8">
                    <div
                        class="pb-5"
                        data-wizard-type="step-content"
                        data-wizard-state="current"
                    >
                        <h3 class="mb-10 font-weight-bold text-dark">
                            आर्थिक विवरण
                        </h3>

                        <div className="row">
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {agriExpenseQn[selectedLanguage]}
                                    </label>
                                    <input
                                        type="number"
                                        class="form-control form-control-solid form-control-lg"
                                        onChange={formik.handleChange}
                                        name="economics.agriExpense"
                                        value={economics.agriExpense}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {nonAgriExpenseQn[selectedLanguage]}
                                    </label>
                                    <input
                                        type="number"
                                        class="form-control form-control-solid form-control-lg"
                                        onChange={formik.handleChange}
                                        name="economics.totalExpenditure"
                                        value={economics.totalExpenditure}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {agriIncomeQn[selectedLanguage]}
                                    </label>
                                    <input
                                        type="number"
                                        class="form-control form-control-solid form-control-lg"
                                        onChange={formik.handleChange}
                                        name="economics.agriIncome"
                                        value={economics.agriIncome}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {nonAgriIncomeQn[selectedLanguage]}
                                    </label>
                                    <input
                                        type="number"
                                        class="form-control form-control-solid form-control-lg"
                                        onChange={formik.handleChange}
                                        name="economics.totalIncome"
                                        value={economics.totalIncome}
                                    />
                                </div>
                            </div>

                            {/* <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {
                                            mainIncomeSourceQuestion[
                                                selectedLanguage
                                            ]
                                        }
                                    </label>
                                    <SingleSelectInput
                                        id="mainIncomeSource"
                                        name="economics.mainIncomeSource"
                                        value={economics.mainIncomeSource}
                                        formik={formik}
                                        selectList={mainIncomeSource}
                                    />
                                </div>
                            </div> */}

                            {/* <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {
                                            familyInLocalOrganQuestion[
                                                selectedLanguage
                                            ]
                                        }
                                    </label>
                                    <div class="radio-inline">
                                        {familyInvolvementLocalOrgan.map(
                                            (value, i) => (
                                                <label class="radio radio-rounded">
                                                    <input
                                                        type="radio"
                                                        name={`economics.familyInvolvementLocalOrgan`}
                                                        value={value.value}
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        defaultChecked={
                                                            value.value ===
                                                            economics.familyInvolvementLocalOrgan
                                                        }
                                                    />
                                                    <span></span>
                                                    {value[selectedLanguage]}
                                                </label>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            {economics.familyInvolvementLocalOrgan ==
                                "local_yes" && (
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {
                                                familyLocalGovInvolveQuestion[
                                                    selectedLanguage
                                                ]
                                            }
                                        </label>
                                        <CustomSelectMulti
                                            formik={formik}
                                            value={
                                                economics[
                                                    `familyLocalGovInvolve`
                                                ]
                                            }
                                            isMulti={true}
                                            list={familyLocalGovInvolve}
                                            field={`economics.familyLocalGovInvolve`}
                                        />
                                    </div>
                                </div>
                            )} */}

                            {/* <div className="col-lg-6">
                                <div class="form-group">
                                    <label>{dhukutiKarobar[selectedLanguage]}</label>
                                    <div class="radio-inline">
                                        {
                                            dhukutiKarobarYesNo.map((value, i) => (
                                                <label class="radio radio-rounded">

                                                    <input type="radio" name={`economics.dhukutiKarobarYesNo`} value={value.value} onChange={formik.handleChange} defaultChecked={value.value === economics.dhukutiKarobarYesNo} />
                                                    <span></span>
                                                    {value[selectedLanguage]}
                                                </label>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {
                                            mainIncomeSourceQuestion[
                                                selectedLanguage
                                            ]
                                        }
                                    </label>
                                    <SingleSelectInput
                                        id="mainIncomeSource"
                                        name="economics.mainIncomeSource"
                                        value={economics.mainIncomeSource}
                                        formik={formik}
                                        selectList={mainIncomeSource}
                                    />
                                </div>
                            </div>
                            {economics[`mainIncomeSource`] === "other" && (
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {
                                                ifOtherValueQuestion[
                                                    selectedLanguage
                                                ]
                                            }
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            name={`economics.mainIncomeSourceOther`}
                                            onChange={formik.handleChange}
                                            value={
                                                economics[
                                                    `mainIncomeSourceOther`
                                                ]
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {mainInvestmentQn[selectedLanguage]}
                                    </label>
                                    <SingleSelectInput
                                        id="mainInvestment"
                                        name="economics.investment"
                                        value={economics.investment}
                                        formik={formik}
                                        selectList={mainInvestment}
                                    />
                                </div>
                            </div>
                            {economics.investment === "other_invest" && (
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {
                                                ifOtherValueQuestion[
                                                    selectedLanguage
                                                ]
                                            }
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            name={`economics.mainInvestmentOther`}
                                            onChange={formik.handleChange}
                                            value={
                                                economics[`mainInvestmentOther`]
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {mainBusinessQn[selectedLanguage]}
                                    </label>
                                    <div class="radio-inline">
                                        {mainBusiness.map((value, i) => (
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name={`economics.smallHomeBusiness`}
                                                    value={value.value}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    defaultChecked={
                                                        value.value ===
                                                        economics.smallHomeBusiness
                                                    }
                                                />
                                                <span></span>
                                                {value[selectedLanguage]}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {electricityUsageQn[selectedLanguage]}
                                    </label>
                                    <div class="radio-inline">
                                        {electricityUsageYesNo.map(
                                            (value, i) => (
                                                <label class="radio radio-rounded">
                                                    <input
                                                        type="radio"
                                                        name={`economics.electricityUsage`}
                                                        value={value.value}
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        defaultChecked={
                                                            value.value ===
                                                            economics.electricityUsage
                                                        }
                                                    />
                                                    <span></span>
                                                    {value[selectedLanguage]}
                                                </label>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            {economics.electricityUsage == "esewa_yes" && (
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {
                                                electricityUsageYesQn[
                                                    selectedLanguage
                                                ]
                                            }
                                        </label>
                                        <CustomSelectMulti
                                            formik={formik}
                                            value={
                                                economics[
                                                    `electricityUsageList`
                                                ]
                                            }
                                            isMulti={true}
                                            list={electricityUsage}
                                            field={`economics.electricityUsageList`}
                                        />
                                    </div>
                                </div>
                            )} */}
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {
                                            loanTakenInFamilyQuestion[
                                                selectedLanguage
                                            ]
                                        }
                                    </label>
                                    <div class="radio-inline">
                                        {loanTakenInFamily.map((value, i) => (
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name={`economics.loanTakenInFamily`}
                                                    value={value.value}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    defaultChecked={
                                                        value.value ===
                                                        economics.loanTakenInFamily
                                                    }
                                                />
                                                <span></span>
                                                {value[selectedLanguage]}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {economics.loanTakenInFamily === "loan_yes" && (
                                <>
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>
                                                {loanQuestion[selectedLanguage]}
                                            </label>
                                            <CustomSelectMulti
                                                formik={formik}
                                                value={economics[`loan`]}
                                                isMulti={true}
                                                list={loan}
                                                field={`economics.loan`}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>
                                                {
                                                    loanTakingReasonQuestion[
                                                        selectedLanguage
                                                    ]
                                                }
                                            </label>
                                            <CustomSelectMulti
                                                formik={formik}
                                                value={
                                                    economics[
                                                        `loanTakingReason`
                                                    ]
                                                }
                                                isMulti={true}
                                                list={loanTakingReason}
                                                field={`economics.loanTakingReason`}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {chhaupadiQuestion[selectedLanguage]}
                                    </label>
                                    <div class="radio-inline">
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`economics.isChaupadiPratha`}
                                                value="yes"
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    economics[
                                                        `isChaupadiPratha`
                                                    ] == "yes"
                                                }
                                            />
                                            <span></span>छ
                                        </label>
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`economics.isChaupadiPratha`}
                                                value="no"
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    economics[
                                                        `isChaupadiPratha`
                                                    ] == "no"
                                                }
                                            />
                                            <span></span>
                                            छैन
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {economics[`isChaupadiPratha`] == "yes" && (
                                <>
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>
                                                {
                                                    chhaupadiIfYes[
                                                        selectedLanguage
                                                    ]
                                                }
                                            </label>
                                            <CustomSelectMulti
                                                value={
                                                    economics[
                                                        "chaupadiLocation"
                                                    ]
                                                }
                                                isMulti={true}
                                                list={chhaupadiWhere}
                                                formik={formik}
                                                field={
                                                    "economics.chaupadiLocation"
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ArthikBibaran;
