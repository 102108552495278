const landSwamita = [
    { nepali: "करार", english: "karaar", value: "karaar" },
    { nepali: "अधियाँ", english: "Adhiyan", value: "adhiyan" },
];

const landTypes = [
    { nepali: "खेत", english: "khet", value: "khet" },
    { nepali: "पाखो/बारी", english: "paako_baari", value: "paako_baari" },
    { nepali: "दुवै", english: "both", value: "both" },
];

const ownLandEarn = [
    { nepali: "करर", english: "karaar", value: "karaar" },
    { nepali: "बिघा", english: "Adhiyan", value: "adhiyan" },
];

const ownLandType = [
    { nepali: "खेत", english: "khet", value: "khet" },
    { nepali: "पाखो/बारी", english: "paako_baari", value: "paako_baari" },
    { nepali: "दुवै", english: "both", value: "both" },
];
const pregnantQuestion = {
    nepali: "तपाइँको परिवारमा यस १२ महिना भित्र कोहि गर्भवती महिला हुनुहुन्छ ?",
    english: "Do pregnant women get 2  vaccine  against titanus? ",
};

const pregnantYesNo = [
    {
        nepali: "गर्भवती महिला भएको",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "गर्भवती महिला नभएको",
        english: "no",
        value: "no",
    },
];

const chhaupadiQuestion = {
    nepali: "तपाइको परिवारमा छाउपडी प्रथाको प्रचलन रहेको छ ?",
    english: "",
};

const chhaupadiYesNo = [
    {
        nepali: "छ",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];
const chhaupadiIfYes = {
    nepali: "यदि छ भनेको कहाँ बस्नुहुन्छ ?",
    english: "",
};
const chhaupadiWhere = [
    {
        nepali: "घरभित्रै",
        english: "inside house",
        value: "insidehouse",
    },
    {
        nepali: "छाउगोठ",
        english: "chaugoth",
        value: "chaugoth_1",
    },
];
const pregnantCheckupQuestion = {
    nepali: "तपाइँको परिवारमा यस १२ महिना भित्र कोहि गर्भवती महिला हुनुहुन्छ ?",
    english: "Do pregnant women get 2  vaccine  against titanus? ",
};

const bimaQuestion = {
    nepali: "कृषि बिमा गर्नु भएको छ कि छैन ?",
    english: "",
};

const bimaYesNo = [
    {
        nepali: "छ",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];
const ifBimaYes = {
    nepali: "छ भने कुन ?",
    english: "",
};

const ifBimaYesNo = [
    {
        nepali: "कृषि",
        english: "agri",
        value: "agri_bimaa",
    },
    {
        nepali: "पशु",
        english: "animal",
        value: "animal_bimaa",
    },
    {
        nepali: "दुवै",
        english: "Both",
        value: "both",
    },
];
const agriProductionQuestion = {
    english: "",
    nepali: "छ भने बार्षिक बाली उत्पादन तथा बिक्रि के कति गर्नुहुन्छ ?",
};
// const agriProductType = [
//     {
//         nepali: "अन्न वालि",
//         english: "Crops",
//         value: "crops",
//     },
//     {
//         nepali: "दलहन",
//         english: "Daal",
//         value: "daal",
//     },
//     {
//         nepali: "तेलहन",
//         english: "Oil seed",
//         value: "oilseed",
//     },
//     {
//         nepali: "तरकारी बाली",
//         english: "Vegetable production",
//         value: "vegetable",
//     },
//     {
//         nepali: "फलफुल खेती",
//         english: "Fruit",
//         value: "fruit",
//     },
//     {
//         nepali: "नगदेवाली",
//         english: "Cash crops",
//         value: "cash_crops",
//     },
//     {
//         nepali: "फुलखेती",
//         english: "Flower farm",
//         value: "flower",
//     },
// ];

const bornAliveQuestion = {
    nepali:
        "गत १२ महिनामा तपाईँको परिवारबाट कसैले जिवित शिशुलाई जन्म दिनु भएको छ ?",
    english: "",
};

const bornAliveYesNO = [
    { nepali: "छ", english: "yes", value: "yes" },
    { nepali: "छैन", english: "no", value: "no" },
];

const babeDeathQuestion = {
    nepali:
        "गत १२ महिनामा तपाईँको परिवारको कुनै महिला सदस्यको गर्भवती अवस्थामा वा सुत्केरी हुँदा वा सुत्केरी भएको ६ हप्ता भित्र मृत्यु भएको थियो ?",
    english: "",
};

const babeDeathYesNO = [
    { nepali: "थियो", english: "yes", value: "thiyio" },
    { nepali: "थिएन", english: "no", value: "thiyena" },
];

const pregnantNoCheckUpQue = {
    nepali:
        "यदि गर्भवती महिलाले नियमित रूपमा तालिम प्राप्त स्वास्थ्यकर्मीबाट स्वास्थ्य जाँच नगराएको भए, कारण के हो ?",
    english: "",
};
const pregnantNoCheckUp = [
    {
        nepali: "कहाँ जाने भन्ने थाहा नभएर",
        english: "no_whre",
        value: "no_whre",
    },
    {
        nepali: "सेवा शुल्क महंगो भएर",
        english: "costly checkup",
        value: "costly_checkup",
    },
    {
        nepali: "स्वास्थ्य संस्था टाढा भएर",
        english: "long",
        value: "long",
    },
    {
        nepali: "परिवारको अनुमति नपाएर",
        english: "not_given",
        value: "not_given",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "other",
    },
];

const bornPlaceQue = {
    nepali:
        "गत १२ महिनामा तपाईँको परिवारबाट कसैले जिवित शिशुलाई जन्म दिनु भएको भए कहाँ जन्म दिनु भयो ?",
    english: "",
};

const bornPlace = [
    { nepali: "घर", english: "yes", value: "yes" },
    {
        nepali: "स्वास्थ्य सँस्था",
        english: "health post",
        value: "health_post",
    },
    { nepali: "अन्य", english: "other", value: "others" },
];

const helpBornBabeQue = {
    nepali:
        "गत १२ महिनामा तपाईँको परिवारबाट कसैले जिवित शिशुलाई जन्म दिनु भएको भए बच्चा जन्माउँदा कसले सहयोग गरेको थियो?",
    english: "",
};

const helpBornBabe = [
    { nepali: "स्वास्थ्यकर्मी", english: "doctors", value: "doctors" },
    {
        nepali: "महिला स्वास्थ्य स्वामसेविका",
        english: "maatrisishu_kaaryakarta",
        value: "maatrisishu_kaaryakarta",
    },
    {
        nepali: "परिवारका सदस्य /छिमेकी /साथी",
        english: "near_friend",
        value: "near_friend",
    },
    { nepali: "कसैको उपस्थिति नभएको", english: "no_any", value: "no_any" },
    { nepali: "अन्य", english: "others", value: "others" },
];

const KhopQue = {
    nepali:
        "तपाईँको परिवारमा ५ वर्ष मुनिका केटाकेटीलाई सबै खोपको मात्रा (वि.सि.जी., डि.पि.टी. र दादुरा) दिनु भएको छ ?",
    english: "",
};

const KhopYesNo = [
    { nepali: "छ", english: "yes", value: "yes" },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];
// yak start
const yakInfo = {
    english: "Detail of yak rearing",
    nepali: "चौरी गाई सम्बन्धी विवरण",
};
const yakNumber = {
    nepali: "चौरी गाईको संख्या",
    english: "No of yak",
    value: "",
};
const yakMilkProduction = {
    nepali: "चौरी गाईको दैनिक दुध उत्पादन (लिटरमा )(दैनिक )",
    english: "Daily production of yak milk (litre)",
    value: "",
};
const yakMilkProductionSale = {
    nepali: "दुध बिक्रि परिमाण (दैनिक)",
    english: "Daily production of yak milk (litre)",
    value: "",
};
const yakMilkSale = {
    nepali: "दुध बिक्रिबाट हुने बार्षिक आम्दानी (रु मा )",
    english: "Income from sales of yak milk(in 1000)",
    value: "",
};
// yak end

module.exports = {
    //new fields from kobo
    landTypes,
    landSwamita,
    agriProductionQuestion,
    pregnantYesNo,
    pregnantQuestion,
    pregnantCheckupQuestion,
    bimaQuestion,
    bimaYesNo,
    ifBimaYes,
    ifBimaYesNo,
    bornAliveQuestion,
    bornAliveYesNO,
    babeDeathQuestion,
    babeDeathYesNO,
    pregnantNoCheckUpQue,
    pregnantNoCheckUp,
    bornPlaceQue,
    bornPlace,
    helpBornBabe,
    helpBornBabeQue,
    KhopQue,
    KhopYesNo,
    ownLandEarn,
    ownLandType,
    yakInfo,
    yakMilkProduction,
    yakMilkProductionSale,
    yakMilkSale,
    yakNumber,
    chhaupadiQuestion,
    chhaupadiYesNo,
    chhaupadiIfYes,
    chhaupadiWhere,
};
