export const dhaalStatus = [
    { value: "no_drain", nepali: "ढल छैन", english: "Blacktop" },
    {
        value: "yes_drain_enclosed",
        nepali: "पक्की (स्ल्याबले ढाकेको)",
        english: "Gravel",
    },
    {
        value: "yes_drain_open",
        nepali: "पक्की (खुल्ला/ स्ल्याबले नढाकेको)",
        english: "Kacchi",
    },
    { value: "kachhi", nepali: "कच्ची", english: "Goreto" },
];

export const stoveDetail = [
    {
        nepali: "ग्यास चुल्हो",
        english: "",
        value: "gas_stove",
    },
    {
        nepali: "अगेना वा माटोको चुल्हो",
        english: "",
        value: "mud_chulo",
    },
    {
        nepali: "सुधारिएको धुवारहित चुल्हो",
        english: "",
        value: "customized_chulo",
    },
    {
        nepali: "भुसे चुल्हो",
        english: "",
        value: "bhuse_chulo",
    },
    {
        nepali: "स्टोभ",
        english: "",
        value: "stove",
    },
    {
        nepali: "विधुतिय चुल्हो (इन्डक्सन)",
        english: "",
        value: "electric_stove",
    },
    {
        nepali: "अन्य",
        english: "",
        value: "others",
    },
];

export const disasterEffectList = [
    {
        nepali: "बाढी",
        english: "Flood",
        value: "flood",
    },
    {
        nepali: "पहिरो",
        english: "Landslide",
        value: "pahiro",
    },
    {
        nepali: "हावाहुरी",
        english: "Hurricane",
        value: "tornado",
    },
    {
        nepali: "चट्टाङ",
        english: "chataang",
        value: "chataang",
    },
    {
        nepali: "आगालागी",
        english: "Fire",
        value: "fire",
    },
    {
        nepali: "खडेरी",
        english: "Drouhgt",
        value: "khaderi",
    },
    {
        nepali: "अन्य",
        english: "no risk",
        value: "others",
    },
];
