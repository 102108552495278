import React from "react";
import { livestockType, allLivestock } from "../../data/houseSurvey";
import { selectedLanguage } from "../../data/institute";
import { Card } from "react-bootstrap";

const AnimalDetail = ({ livestock }) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>पशु पन्छी जानकारी</Card.Title>
                <h6>
                    चौपाया तथा पशुपन्छी पाल्नु भएको छ ? :{" "}
                    {livestockType.find(
                        (i) => i.value === livestock.animalBirdInHouse
                    )
                        ? livestockType.find(
                              (i) => i.value === livestock.animalBirdInHouse
                          )[selectedLanguage]
                        : ""}
                </h6>
                {livestock.animals?.map((animal) => (
                    <>
                        <h6>
                            पशु पन्छी:{" "}
                            {allLivestock.find((i) => i.value === animal.name)
                                ? allLivestock.find(
                                      (i) => i.value === animal.name
                                  )[selectedLanguage]
                                : ""}
                        </h6>
                        {animal.race && (
                            <h6>
                                नश्ल/जात :{" "}
                                {animal.race == "local" ? "स्थानीय" : "उनन्त"}
                            </h6>
                        )}
                        <h6>
                            सङ्ख्या :{" "}
                            {animal.animalCount ? animal.animalCount : 0}
                        </h6>
                        <h6>
                            वार्षिक आम्दानी:{" "}
                            {animal.annualIncome ? animal.annualIncome : 0}
                        </h6>
                        <h6>वार्षिक खर्च: {animal.sale ? animal.sale : 0}</h6>
                        {animal.specification &&
                            animal.specification.map((spec) => (
                                <>
                                    <h6>पशु पन्छी प्रकार: {spec.title}</h6>
                                    <h6>पशु पन्छी मात्रा: {spec.quantity}</h6>
                                    <h6>पशु पन्छी एकाई: {spec.unit}</h6>
                                </>
                            ))}
                    </>
                ))}
            </Card.Body>
        </Card>
    );
};

export default AnimalDetail;
