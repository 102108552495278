import { totalWard } from "./config.js";
// import getNepaliNumber from "../utils/getNepaliNumber.js";
export const placeholderName = {
    nepali: "को विस्तृत  जानकारी खोज्नुहोस् जस्तै: लिङ्ग =पुरुष ",
    english: "'s advanced search, gender: male",
};
// export const languages = ["नेपाली", "English"];
// export const selectedLanguage = "nepali";
export const houseNeutralSearch = ["wardNumber"];
export const HouseSearchIndex = [
    "illTreament",
    "birthCertificate",
    // "livingType",
    "childVaccine",
    "permanentLivingAddress",
    "waterSource",
    "distanceToWaterSource",
    "cookingSource",
    "stoveDetail",
    // "dirtyWaterManagement",
    "waterPurification",
    "electricSource",
    // "toilet_isToilet",
    // "toilet_toiletNo",
    "toilet_toiletYes",
    "wasteDisposal",
    "road",
    "bankAccount",
    "saltUsed",
    "yearSettled",
    "houseCount",
    "houseAge",
    "houseFoundation",
    "roofType",
    "hasLandDocument",
    "dhukutiKarobar",
    "doHouseCriteriaFullfill",
    "isHouseEarthquakeResisitance",
    "hasHouseMapPass",
    "nonAgriIncome",
    "nonAgriExpense",
    "agriIncome",
    "agriExpense",
    "home_homeBusinessName",
    "item_title",
    "agricultureProductSalesType",
    "agricultureProductionSalesType_crops",
    "agricultureProductionSalesType_daal",
    "agricultureProductionSalesType_oilseed",
    "agricultureProductionSalesType_vegetable",
    "agricultureProductionSalesType_fruit",
    "agricultureProductionSalesType_cash_crops",
    "detail_name",
    // "foreignCountry",
    // "abroadReason"
];

export const mapData = {
    house: [
        "wardNumber",
        "houseNumber",
        "oldVDC",
        "oldWardNumber",
        "maleNumber",
        "femaleNumber",
        "totalFamilyNumber",
        // "livingType",
        "waterSource",
        "distanceToWaterSource",
        "cookingSource",
        "stoveDetail",
        // "dirtyWaterManagement",
        "waterPurification",
        "electricSource",
        // "toilet_isToilet",
        // "toilet_toiletNo",
        "toilet_toiletYes",
        "wasteDisposal",
        "road",
        "bankAccount",
        "saltUsed",
        "yearSettled",
        "houseAge",
        "landUsedLiving",
        "houseFoundation",
        "roofType",
        "hasLandDocument",
        "doHouseCriteriaFullfill",
        "isHouseEarthquakeResisitance",
        "hasHouseMapPass",
        "illTreament",
    ],
    family: [
        // "house",
        // "fullName",
        "gender",
        // "dateOfBirth",
        "age",
        "answererRelation",
        // "citizenshipNumber",
        // "citizenshipfront",
        // "citizenshipBack",
        // "contactNumber",
        "livingStatus",
        "caste",
        "caste",
        "religion",
        "motherTongue",
        "martialStatus",
        "healthCondition",
        "unhealthyDisease",
        "insurance_hasInsurance",
        "insurance_insuranceType",
        "bloodGroup_bloodType",
        // "socialAllowance",
        "voterId",
    ],
    education: [
        "educationLevel",
        // "prePrimaryDetails",
        "nineToTwelve",
        "oneToEightClass",
    ],
    occupation: ["levelOneJob", "levelTwoJob", "levelThreeJob"],
    disable: [
        "isDisable",
        "type",
        "condition",
        // "hasCard", "cardType"
    ],
    abroad: ["abroadReason", "bideshiyekoYear", "foreignCountry"],
    death: [],
    houseFacility: ["item_title"],
    rent: [],
    land: [],
    agriculture: [
        "agricultureProductSalesType",
        "agricultureProductionSalesType_crops",
        "agricultureProductionSalesType_daal",
        "agricultureProductionSalesType_oilseed",
        "agragricultureProductionSalesType_vegetable",
        "agricultureProductionSalesType_fruit",
        "agricultureProductionSalesType_cash_crops",
    ],
    agricultureDetail: [],
    liveStock: ["detail_name"],
    beeFishSilk: [], //contains nested fields to be searched
    economics: [
        "nonAgriIncome",
        "nonAgriExpense",
        "agriIncome",
        "agriExpense",
        "dhukutiKarobar",
    ],
    skill: ["title"],
    business: ["home_homeBusinessName"],

    womenChild: [
        "birthCertificate",
        "contraceptiveUse",
        "deliveryFromDoctor",
        "childDeath",
        "pregnanctCheck",
        // "childNutrition",
        "childLabour",
        "childLabor",
        "earlyMarriage",
        "pregnantTetanus",
    ],
    welfare: [],
};

export const isBoolean = [
    "hasCard",
    "voterId",
    "birthCertificate",
    "insurance_hasInsurance",
    "contraceptiveUse",
    "deliveryFromDoctor",
    "childDeath",
    "pregnanctCheck",
    // "childNutrition",
    "childLabour",
    "childLabor",
    "earlyMarriage",
    "pregnantTetanus",
    // "dhukutiKarobar",
    "bankAccount",
];
export const booleanAttribute = {
    hasCard: "card_taken",
    voterId: "voter_id_yes",
    birthCertificate: "birth_cert_yes",
    insurance_hasInsurance: "yes",
    contraceptiveUse: "yes",
    deliveryFromDoctor: "delivery_yes",
    childDeath: "yes",
    pregnanctCheck: "check_yes",
    // childNutrition: "mal_nutri_yes",
    childLabour: "yes",
    childLabor: "yes",
    earlyMarriage: "e_marry_yes",
    pregnantTetanus: "tetanus_yes",
    // dhukutiKarobar: "yes",
    bankAccount: "account_yes",
};

export const isInteger = ["wardNumber"];
export const nestedArray = [
    // "agricultureProductSalesType",
    "agricultureProductionSalesType_crops",
    "agricultureProductionSalesType_daal",
    "agricultureProductionSalesType_oilseed",
    "agricultureProductionSalesType_vegetable",
    "agricultureProductionSalesType_fruit",
    "agricultureProductionSalesType_cash_crops",
    "home_homeBusinessName",
    "insurance_hasInsurance",
    "insurance_insuranceType",
    "bloodGroup_bloodType",
    // "toilet_isToilet",
    // "toilet_toiletNo",
    "toilet_toiletYes",
    "detail_name",
    "item_title",
];
export const rangeValue = [
    "age",
    "nonAgriIncome",
    "nonAgriExpense",
    "agriIncome",
    "agriExpense",
];

export const searchTableHeader = [
    "क्र.स ",
    "वडा न.",
    "घर न.",
    "मृत्यु हुनेको नाम थर",
    "लिङ्ग",
    "उमेर ",
    "मृत्युको कारण ",
];

export const genderList = [
    { nepali: "पुरुष", english: "Male", value: "male" },
    { nepali: "महिला", english: "Female", value: "female" },
    { nepali: "तेस्रोलिंगी", english: "Third Gender", value: "other_sex" },
];

export const casteList = [
    {
        english: "sherpa",
        nepali: "शेर्पा",
        value: "sherpa",
    },
    {
        english: "tamang",
        nepali: "तामांङ",
        value: "tamang",
    },
    {
        english: "chhetri",
        nepali: "क्षेत्री",
        value: "chhetri",
    },
    {
        english: "thaami",
        nepali: "थामी",
        value: "thaami",
    },
    {
        english: "brahmin",
        nepali: "ब्राहमण",
        value: "brahmin",
    },
    {
        english: "rai",
        nepali: "राई",
        value: "rai",
    },
    {
        english: "magar",
        nepali: "मगर",
        value: "magar",
    },
    {
        english: "newar",
        nepali: "नेवार",
        value: "newar",
    },
    {
        english: "gurung",
        nepali: "गुरुङ",
        value: "gurung",
    },
    {
        english: "sanyasi",
        nepali: "सन्यासी /दशनामी",
        value: "sanyasi",
    },
    {
        english: "dhami",
        nepali: "धामी",
        value: "dhami",
    },
    {
        english: "kumal pahadi",
        nepali: "कुमाल पहाडी",
        value: "kumal_pahadi",
    },
    {
        english: "limbu",
        nepali: "लिम्बु",
        value: "limbu",
    },
    {
        english: "biswokarma",
        nepali: "विश्वकर्मा",
        value: "biswokarma",
    },
    {
        english: "saarki",
        nepali: "सार्की",
        value: "saarki",
    },
    {
        english: "Damai",
        nepali: "दमाई",
        value: "दमाई",
    },
    {
        english: "bhote",
        nepali: "भोटे",
        value: "bhote",
    },
    {
        english: "pariyaar",
        nepali: "परियार",
        value: "pariyaar",
    },
    {
        english: "muslim",
        nepali: "मुस्लिम",
        value: "muslim",
    },
    {
        english: "raaute",
        nepali: "राउटे",
        value: "raaute",
    },
    {
        english: "pahari",
        nepali: "पहरी",
        value: "pahari",
    },
    {
        english: "maajhi",
        nepali: "माझी",
        value: "maajhi",
    },
    {
        english: "madhesi",
        nepali: "मधेसी",
        value: "madhesi",
    },
    {
        english: "chamaar",
        nepali: "चमार",
        value: "chamaar",
    },
    {
        english: "dhimal",
        nepali: "धिमाल",
        value: "dhimal",
    },
    {
        english: "chaudhari",
        nepali: "चौधरी",
        value: "chaudhari",
    },
    {
        english: "satar",
        nepali: "सतार",
        value: "satar",
    },
    {
        english: "danuwar",
        nepali: "दनुवार",
        value: "danuwar",
    },
    {
        english: "Rajbanshi",
        nepali: "राजवंशी",
        value: "Rajbanshi",
    },
    {
        english: "khawaas",
        nepali: "खवास",
        value: "khawaas",
    },
    {
        english: "pattharkatta",
        nepali: "पत्थरकट्टा",
        value: "pattharkatta",
    },
    {
        english: "gangaai",
        nepali: "गनगाई",
        value: "gangaai",
    },
    {
        english: "tharu",
        nepali: "थारु",
        value: "tharu",
    },
    {
        english: "musahar",
        nepali: "मुसहर",
        value: "musahar",
    },
    {
        english: "yaadaw",
        nepali: "यादव",
        value: "yaadaw",
    },
    {
        english: "kalwaar",
        nepali: "कलवार",
        value: "kalwaar",
    },
    {
        english: "teli",
        nepali: "तेली",
        value: "teli",
    },
    {
        english: "koiri",
        nepali: "कोइरी",
        value: "koiri",
    },
    {
        english: "kurmi",
        nepali: "कुर्मी",
        value: "kurmi",
    },
    {
        english: "thakur",
        nepali: "हजाम /ठाकुर",
        value: "thakur",
    },
    {
        english: "dusaad",
        nepali: "दुसाध",
        value: "dusaad",
    },
    {
        english: "bhujel",
        nepali: "भुजेल",
        value: "bhujel",
    },
    {
        english: "baantar",
        nepali: "बाँतर",
        value: "baantar",
    },
    {
        english: "jhaagad",
        nepali: "झाँगड",
        value: "jhaagad",
    },
    {
        english: "other caste",
        nepali: "अन्य",
        value: "other_caste",
    },
];

export const religionList = [
    { english: "Hindu", nepali: "हिन्दु", value: "hindu" },
    { english: "Buddhist", nepali: "बुद्ध", value: "buddhist" },
    { english: "Muslim", nepali: "इस्लाम (मुस्लिम)", value: "muslim" },
    { english: "Christian", nepali: "इसाई (क्रिश्चियन)", value: "christian" },
    { english: "Kirat", nepali: "किंरात", value: "kirat" },
    { english: "Boon", nepali: "वोन", value: "boon" },
    { english: "Prakriti", nepali: "प्रकृति", value: "prakriti" },
    { english: "Jain", nepali: "जैन", value: "jain" },
    { english: "Bahai", nepali: "बहाई", value: "bahai" },
    { english: "yuma", nepali: "युमा", value: "yuma" },
    { english: "Other", nepali: "अन्य", value: "other_religion" },
];

export const educationList = [
    {
        nepali: "वाल विकास कक्षा (१ कक्षा भन्दा कम)",
        english: "Child development class",
        value: "less_than_one",
    },
    {
        nepali: "आधारभूत तह  (कक्षा  १ देखि ८ कक्षा सम्म )",
        english: "Primary",
        value: "one_to_eight",
    },
    {
        nepali: "माध्यमिक तह (कक्षा ९ देखि १२ कक्षा सम्म )",
        english: "Secondary",
        value: "nine_to_twelve",
    },
    {
        nepali: "स्नातक तह",
        english: "bachelors",
        value: "bachelors",
    },
    {
        nepali: "स्नातकोत्तर  तह",
        english: "masters",
        value: "masters",
    },
    {
        nepali: "एमफिल",
        english: "mphil",
        value: "mphil",
    },
    {
        nepali: "विद्यावारिधी (पिएचडि)",
        english: "Phd",
        value: "phd",
    },
    {
        nepali: "प्राविधिक एस.एल.सी",
        english: "slc",
        value: "slc",
    },
    {
        nepali: "प्राविधिक डिप्लोमा",
        english: "Technical Diploma",
        value: "technical_diploma",
    },
    {
        nepali: "साक्षर (साधारण लेखपढ)",
        english: "literate",
        value: "literate",
    },
];

// export const preprima0.ryList = [
// 	{
// 		nepali: "१",
// 		english: "1",
// 		value: "class_1"
// 	},
// 	{
// 		nepali: "२",
// 		english: "2",
// 		value: "class_2"
// 	},
// 	{
// 		nepali: "३",
// 		english: "3",
// 		value: "class_3"
// 	},
// 	{
// 		nepali: "४",
// 		english: "4",
// 		value: "class_4"
// 	},
// 	{
// 		nepali: "५",
// 		english: "5",
// 		value: "class_5"
// 	}
// ];

export const primaryList = [
    {
        nepali: "१",
        english: "1",
        value: "one",
    },
    {
        nepali: "२",
        english: "2",
        value: "two",
    },
    {
        nepali: "३",
        english: "3",
        value: "three_1",
    },
    {
        nepali: "४",
        english: "4",
        value: "four_1",
    },
    {
        nepali: "५",
        english: "5",
        value: "five_1",
    },
    {
        nepali: "६",
        english: "6",
        value: "six_1",
    },
    {
        nepali: "७",
        english: "7",
        value: "seven",
    },
    {
        nepali: "८",
        english: "8",
        value: "eight",
    },
];

export const secondaryList = [
    {
        nepali: "९",
        english: "9",
        value: "9",
    },
    {
        nepali: "१०",
        english: "10",
        value: "10",
    },
    {
        nepali: "११",
        english: "11",
        value: "11",
    },
    {
        nepali: "१२",
        english: "12",
        value: "12",
    },
];

export const occupationList = [
    {
        nepali: "कृषि तथा पशुपालन",
        english: "Agriculture",
        value: "agriculture",
    },
    {
        nepali: "नोकरी /जागिर",
        english: "Job",
        value: "job",
    },
    {
        nepali: "गृहणी",
        english: "House Wife",
        value: "house_wife",
    },
    {
        nepali: "वेरोजगार",
        english: "Unemployment",
        value: "unemployment",
    },
    {
        nepali: "उद्योग  तथा व्यापार",
        english: "Industry",
        value: "industry",
    },
    {
        nepali: "ज्याला /मजदुरी",
        english: "Labor",
        value: "labor",
    },
    {
        nepali: "विद्यार्थी (अध्यनरत)",
        english: "Student",
        value: "student",
    },
    {
        nepali: "वैदेशिक रोजगारी",
        english: "Foreign job",
        value: "foreign_job",
    },
    {
        nepali: "अन्य",
        english: "Other Job",
        value: "other_job",
    },
];

export const jobList = [
    {
        nepali: "गीटि कुट्ने",
        english: "stone beating",
        value: "stone_beating",
    },
    {
        nepali: "भारी बोक्ने",
        english: "carry load",
        value: "carry_load",
    },
    {
        nepali: "कृषि मजदुर",
        english: "agri_labour",
        value: "agri_labour",
    },
    {
        nepali: "सिकर्मी /डकर्मी",
        english: "sikarmi",
        value: "sikarmi",
    },
    {
        nepali: "खोलामा माछामार्ने",
        english: "fishing",
        value: "fishing",
    },
    {
        nepali: "पर्यटक घुमाउने",
        english: "tourist ghumaaune",
        value: "tourist_ghumaaune",
    },
    {
        nepali: "बालुवा चाल्ने",
        english: "sand refining",
        value: "sand_refining",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other_labor",
    },
];

export const healthconditionList = [
    {
        nepali: "स्वास्थ्य",
        english: "Health",
        value: "helathy",
    },
    {
        nepali: "सामान्य रोगी",
        english: "Normal Patient",
        value: "normal_patient",
    },
    {
        nepali: "दिर्घ रोग लागेको",
        english: "Unhealthy",
        value: "unhelathy",
    },
];
export const insuranceType = [
    {
        nepali: "जीवन बीमा",
        english: "Life Insurance",
        value: "life_insurance",
    },
    {
        nepali: "स्वास्थ्य बीमा",
        english: "Health Insurance",
        value: "health_insurance",
    },
];

export const hasInsuranceList = [
    {
        nepali: "छ",
        english: "Yes",
        value: true,
    },
    {
        nepali: "छैन",
        english: "No",
        value: false,
    },
];

export const unhealthy = [
    {
        nepali: "उच्च रक्तचाप / ब्लड प्रेसर",
        english: "High Bllod Pressure",
        value: "high_blood_pre",
    },
    {
        nepali: "मुटु  सम्बन्धी रोग",
        english: "Heart Related Diseases",
        value: "heart",
    },
    {
        nepali: "मधुमेह",
        english: "Sugar",
        value: "diabetes",
    },
    {
        nepali: "आस्थमा/दम",
        english: "Aasthama",
        value: "aasthama",
    },
    {
        nepali: "मृगौला सम्बन्धी  रोग",
        english: "Liver Disease",
        value: "liver",
    },
    {
        nepali: "HIV AIDS",
        english: "HIV AIDS",
        value: "hiv",
    },

    {
        nepali: "हाडजोर्नी तथा नसा सम्बन्धि रोग (बाथ  रोग )",
        english: "Born Joint",
        value: "bornjoint",
    },
    {
        nepali: "क्षारे रोग",
        english: "ipilepsi",
        value: "ipilepsi",
    },
    {
        nepali: "अल्सर /आन्द्राको रोग",
        english: "alsar",
        value: "alsar",
    },
    {
        nepali: "कडा खालको मानसिक रोग (मस्तिस्क)",
        english: "Mental",
        value: "manasik",
    },
    {
        nepali: "अल्जाईमर्स",
        english: "aljaaimars",
        value: "aljaaimars",
    },
    {
        nepali: "हात्तीपाइले",
        english: "hattipaile",
        value: "hattipaile",
    },
    {
        nepali: "निम्न रक्तचाप",
        english: "low pressure",
        value: "low_pressure",
    },
    {
        nepali: "कुष्ठरोग",
        english: "Leprosy",
        value: "leprosy",
    },
    {
        nepali: "क्यान्सर",
        english: "Cancer",
        value: "cancer",
    },
    {
        nepali: "पार्किन्सस",
        english: "paarkinsas",
        value: "paarkinsas",
    },
    {
        nepali: "थाइराईड रोग",
        english: "thaairoid",
        value: "thaairoid",
    },
    {
        nepali: "पाठेघर झर्ने खस्ने",
        english: "patheghar khasne",
        value: "patheghar_khasne",
    },
    {
        nepali: "अन्य",
        english: "other disease",
        value: "other_disease",
    },
];

// export const teacherList = [
// 	{
// 		nepali: "स्थानीय श्रोत",
// 		english: "Local",
// 		value: "local"
// 	},
// 	{
// 		nepali: "सामुदायिक",
// 		english: "Government",
// 		value: "government"
// 	},
// 	{
// 		nepali: "राहत",
// 		english: "Rahat",
// 		value: "rahat"
// 	},
// 	{
// 		nepali: "नेपाल प्रहरी",
// 		english: "Nepal Police",
// 		value: "nepal_police"
// 	},
// 	{
// 		nepali: "सशस्त्र प्रहरी",
// 		english: "A P F",
// 		value: "a_p_f"
// 	},
// 	{
// 		nepali: "नेपाली सेना",
// 		english: "Nepal Army",
// 		value: "nepal_army"
// 	}
// ];
export const mentaltype = [
    {
        nepali: "जन्मजात",
        english: "By birth",
        value: "by_birth",
    },
    {
        nepali: "रोग लागेर",
        english: "Diseases",
        value: "disease",
    },
    {
        nepali: "दुर्घटना",
        english: "Accident",
        value: "accident",
    },
    {
        nepali: "अन्य",
        english: "Other Reason",
        value: "other_disable",
    },
];

export const mentalcondition = [
    {
        nepali: "शारिरिक अपाङ्गता",
        english: "Physical Disability",
        value: "disability",
    },
    {
        nepali: "बौद्धिक अपाङ्गता",
        english: "Intellectual disability",
        value: "aged_mental",
    },
    {
        nepali: "पूर्ण  ष्टिविहीन  (आखाँ नदेख्ने)",
        english: "Blind Disability",
        value: "vision",
    },
    {
        nepali: "न्यून दृष्टिबिहिन",
        english: "less power",
        value: "less_power",
    },
    {
        nepali: "बहिरापन",
        english: "wahira",
        value: "wahira",
    },
    {
        nepali: "स्वर र  वोलाई (भकभके)",
        english: "Vocal Disability",
        value: "bhakbhake",
    },
    {
        nepali: "श्रवण दृष्टिविहिन अपाङ्गता",
        english: "Hearing impaired blindness",
        value: "hear_vision",
    },
    {
        nepali: "मानसिक वा मनोसामाजिक अपाङ्गता",
        english: "Mental or psychosocial disability",
        value: "mental",
    },
    {
        nepali: "अटिज्म सम्बन्धी",
        english: "Autism",
        value: "autism",
    },
    {
        nepali: "अनुवंशीय रक्तश्राव सम्बन्धी अपाङ्गता",
        english: "Genetic bleeding related disability",
        value: "hereditary_hemorrhagic",
    },
    {
        nepali: "बहु अपांगता",
        english: "Multiple disabilities",
        value: "many_disable",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const mentalCardYesNo = [
    {
        nepali: "छ",
        english: "yes",
        value: "card_taken",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "card_not_taken",
    },
];

export const voterIdYesNo = [
    {
        nepali: "छ",
        english: "yes",
        value: "voter_id_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "voter_id_no",
    },
];

export const mentalCardtype = [
    {
        nepali: "रातो  रंगको परिचय पत्र (क वर्ग)",
        english: "Red Card",
        value: "red_card",
    },
    {
        nepali: "निलो रंगको परिचय पत्र (ख वर्ग)",
        english: "Blue card",
        value: "blue_card",
    },
    {
        nepali: "पहेलो  रंगको परिचय पत्र (ग वर्ग)",
        english: "Yellow card",
        value: "yellow_card",
    },
    {
        nepali: "सेतो रंगको परिचय पत्र (घ वर्ग)",
        english: "White card",
        value: "white_card",
    },
];

export const mothertongue = [
    {
        nepali: "नेपाली",
        english: "Nepali",
        value: "nepali",
    },
    {
        nepali: "तामाङ",
        english: "tamang",
        value: "tamang",
    },
    {
        nepali: "राई",
        english: "lhowa",
        value: "lhowa",
    },
    {
        nepali: "मगर",
        english: "magar",
        value: "magar",
    },
    {
        nepali: "मैथिली",
        english: "maithili",
        value: "maithili",
    },
    {
        nepali: "लिम्बु",
        english: "limbu",
        value: "limbu",
    },
    {
        nepali: "शेर्पा",
        english: "sherpa",
        value: "sherpa",
    },
    {
        nepali: "नेवारी",
        english: "newari",
        value: "newari",
    },
    {
        nepali: "गुरुङ",
        english: "gurung",
        value: "gurung",
    },
    {
        nepali: "पहरी",
        english: "pahari",
        value: "pahari",
    },
    {
        nepali: "सांकेतिक भाषा",
        english: "sanketik",
        value: "sanketik",
    },
    {
        nepali: "चौधरी",
        english: "chaudhari",
        value: "chaudhari",
    },
    {
        nepali: "थारु",
        english: "tharu",
        value: "tharu",
    },
    {
        nepali: "राजवंशी",
        english: "rajbanshi",
        value: "rajbanshi",
    },
    {
        nepali: "उर्दु",
        english: "urdu",
        value: "urdu",
    },
    {
        nepali: "भोजपुरी",
        english: "bhojpuri",
        value: "bhojpuri",
    },
    {
        nepali: "हिन्दी",
        english: "hindi",
        value: "hindi",
    },
    {
        nepali: "सन्थली",
        english: "santhali",
        value: "santhali",
    },
    {
        nepali: "चेपाङ",
        english: "chepaang",
        value: "chepaang",
    },
    {
        nepali: "दनुवार",
        english: "danuwaar",
        value: "danuwaar",
    },
    {
        nepali: "सुनुवार",
        english: "sunuwaar",
        value: "sunuwaar",
    },
    {
        nepali: "मुसलमान",
        english: "musalmaan",
        value: "musalmaan",
    },
    {
        nepali: "कोइ",
        english: "koi",
        value: "koi",
    },
    {
        nepali: "किसान",
        english: "kisan",
        value: "kisan",
    },
    {
        nepali: "बनकरिया",
        english: "bankariya",
        value: "bankariya",
    },
    {
        nepali: "गनगाइ",
        english: "gangaai",
        value: "gangaai",
    },
    {
        nepali: "ताजपुरिया",
        english: "taajpuriya",
        value: "taajpuriya",
    },
    {
        nepali: "अवरी",
        english: "awari",
        value: "awari",
    },
    {
        nepali: "वान्तवा",
        english: "waantwa",
        value: "waantwa",
    },
    {
        nepali: "उरन्वा",
        english: "uranwaa",
        value: "uranwaa",
    },
    {
        nepali: "भुजेल",
        english: "bhujel",
        value: "bhujel",
    },
    {
        nepali: "चाम्लीङ",
        english: "chamling",
        value: "chamling",
    },
    {
        nepali: "धिमाल",
        english: "dhimaal",
        value: "dhimaal",
    },
    {
        nepali: "बङग्ला",
        english: "baanglaa",
        value: "baanglaa",
    },
    {
        nepali: "अन्य",
        english: "Other language",
        value: "other_language",
    },
];

export const bloodgroup = [
    {
        nepali: "O+",
        english: "O positive",
        value: "o_positive",
    },
    {
        nepali: "O-",
        english: "O Negative",
        value: "o_negative",
    },
    {
        nepali: "A+",
        english: "A Positive",
        value: "a_positive",
    },
    {
        nepali: "A-",
        english: "A negative",
        value: "a_neagtive",
    },
    {
        nepali: "B+",
        english: "",
        value: "b_positive",
    },
    {
        nepali: "B-",
        english: "",
        value: "b_negative",
    },
    {
        nepali: "AB+",
        english: "",
        value: "ab_positive",
    },
    {
        nepali: "AB-",
        english: "",
        value: "ab_neagtive",
    },
];

export const marriage = [
    {
        nepali: "अविवाहित",
        english: "Unmarried",
        value: "unmarried",
    },
    {
        nepali: "विवाहित",
        english: "Married",
        value: "married",
    },
    {
        nepali: "विदुर / एकल पुरुष",
        english: "widower",
        value: "widower",
    },
    {
        nepali: "एकल महिला (विधुवा)",
        english: "alone_women",
        value: "alone_women",
    },
    {
        nepali: "सम्बन्ध बिच्छेद",
        english: "Divorce",
        value: "divorce",
    },
    {
        nepali: "विवाहित तर अलग बसेको",
        english: "Married but separated",
        value: "marriage_but_l",
    },
];
export const livingTypeList = [
    {
        nepali: "अस्थाई",
        english: "Temporary",
        value: "temporary",
    },
    {
        nepali: "स्थाई",
        english: "permanent",
        value: "permanent",
    },
    {
        nepali: "सुकुम्बासी",
        english: "sukumbaasi",
        value: "sukumbaasi",
    },
];
export const permanent = [
    {
        nepali: "अर्को वार्ड ( पालिका भित्रै )",
        english: "Other ward",
        value: "other_ward",
    },
    {
        nepali: "यसै वार्डमा",
        english: "Same ward",
        value: "same_ward",
    },
    {
        nepali: "मोरङ  जिल्ला बाहिर",
        english: "outside district morang",
        value: "outside_district_morang",
    },
    {
        nepali: "मोरङ  जिल्ला बाहिर",
        english: "inside district morang",
        value: "inside_district_morang",
    },
];

export const drinkingwaterLists = [
    {
        nepali: "पाइपधारा घर कम्पाउण्डमा",
        english: "Home Tap Water",
        value: "tap_home",
    },
    {
        nepali: "पाइपधारा घर कम्पाउण्ड बाहिर",
        english: "Public Tap Water",
        value: "tap_outside",
    },
    {
        nepali: "ट्युववेल / हेन्डपम्प",
        english: "Tubewell",
        value: "handpump",
    },
    {
        nepali: "ढाकिएको  इनार /कुवा",
        english: "Covered Well",
        value: "well_cover",
    },
    {
        nepali: "नढाकिएको इनार /कुवा",
        english: "Uncovered Well",
        value: "well_uncover",
    },
    {
        nepali: "मूलको पानी",
        english: "Water from Origin",
        value: "main_water",
    },
    {
        nepali: "नदी खोला",
        english: "River Water",
        value: "river",
    },
    {
        nepali: "डिप वोरिङ्ग",
        english: "Dip Boring",
        value: "dip_boring",
    },
    {
        nepali: "जार /बोतल",
        english: "jar_bottal",
        value: "jar_bottal",
    },
    {
        nepali: "अन्य",
        english: "Other Water Source",
        value: "other",
    },
];

export const drinkingwaterplaceLists = [
    {
        nepali: "घर आगनमै छ",
        english: "home courtyard",
        value: "in_home",
    },
    {
        nepali: "१० मिनेट सम्म पैदल",
        english: "10 min walk",
        value: "10_min",
    },
    {
        nepali: "आधा घण्टा सम्म दुरी",
        english: "half hour walk",
        value: "30_min",
    },
    {
        nepali: "१ घण्टा वा सो भन्दा माथि",
        english: "1 hour walk",
        value: "1hour_more",
    },
];
export const cookingresourceLists = [
    {
        nepali: "एल पि  ग्यास",
        english: "lpg Gas",
        value: "lpg_gas",
    },
    {
        nepali: "वायोग्यास",
        english: "Bio Gas",
        value: "bio_gas",
    },
    {
        nepali: "विधुत",
        english: "electricity",
        value: "electricity",
    },
    {
        nepali: "सौर्य उर्जा",
        english: "solar",
        value: "solar",
    },
    {
        nepali: "दाउरा/काठ",
        english: "wood",
        value: "wood_general",
    },
    {
        nepali: "मट्टीतेल",
        english: "kerosene",
        value: "kerosene",
    },
    {
        nepali: "गुइठा/गोरहा",
        english: "Dung",
        value: "guithaa",
    },
    {
        nepali: "अन्य",
        english: "Others",
        value: "others",
    },
];

export const waterPurification = [
    {
        nepali: "उमालेर",
        english: "Boiling",
        value: "boil",
    },
    {
        nepali: "फिल्टर गरेर",
        english: "filter",
        value: "filter",
    },
    {
        nepali: "क्लोरिन झोल वा पियुस हालेर",
        english: "chlorine",
        value: "chlorine",
    },
    {
        nepali: "सोडीस",
        english: "sodis",
        value: "sodis",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const dirtyWaterManagement = [
    {
        nepali: "करेसाबारीमा",
        english: "gardenfiel",
        value: "gardenfiel",
    },
    {
        nepali: "ढलमा मिसाएको",
        english: "drain",
        value: "drain",
    },
    {
        nepali: "खाडलमा जम्मा गर्ने",
        english: "ponding",
        value: "ponding",
    },
    {
        nepali: "अब्यबस्थित छाड्ने",
        english: "unmanaged",
        value: "unmanaged",
    },
];

export const stoveLists = [
    {
        nepali: "ग्यास चुल्हो",
        english: "gas stove",
        value: "gas_stove",
    },
    {
        nepali: "अगेना वा माटोको चुल्हो",
        english: "mud hulo",
        value: "mud_chulo",
    },
    {
        nepali: "सुधारिएको धुवारहित चुल्हो",
        english: "customized chulo",
        value: "customized_chulo",
    },
    {
        nepali: "भुसे चुल्हो",
        english: "bhuse chulo",
        value: "bhuse_chulo",
    },
    {
        nepali: "स्टोभ",
        english: "stove",
        value: "stove",
    },
    {
        nepali: "विधुतिय चुल्हो (इन्डक्सन)",
        english: "electric stove",
        value: "electric_stove",
    },
    {
        nepali: "अन्य",
        english: "Others",
        value: "others",
    },
];

export const lightresourceLists = [
    {
        nepali: "विधुत /बिजुली",
        english: "Electricity",
        value: "electric_national",
    },
    {
        nepali: "वायो ग्यास /गोबर ग्यास",
        english: "Bio Gas",
        value: "bio_gas",
    },
    {
        nepali: "सोलार",
        english: "Solar",
        value: "solar",
    },
    {
        nepali: "मट्टीतेल",
        english: "Kerosene",
        value: "kerosene",
    },
    {
        nepali: "अन्य",
        english: "Other Source of Light",
        value: "other_light",
    },
];
export const isToilet = [
    {
        nepali: "छ",
        english: "Yes",
        value: "yes",
    },
    {
        nepali: "छैन (शौचालय नभएको )",
        english: "No toilet",
        value: "no",
    },
    {
        nepali: "छ तर संचालन नभएको",
        english: "No use",
        value: "yes_but_not_us",
    },
];
export const noToilet = [
    {
        nepali: "जग्गा नभएर",
        english: "No Land",
        value: "no_land",
    },
    {
        nepali: "बनाउने पैसा नभएर",
        english: "No Money",
        value: "no_money",
    },
    {
        nepali: "छिमेकीको प्रयोग गरेर",
        english: "Neighbour Use",
        value: "neighbour_use",
    },
    {
        nepali: "नजिकै खुला ठाँउ वा जंगल उपलब्ध भएर",
        english: "Open Space ",
        value: "open_space",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "others_toilet",
    },
];
export const toiletresourceLists = [
    {
        nepali: "फ्लस भएको सार्बजनिक ढलमा मिसिने",
        english: "Public Sewer",
        value: "public_sewer",
    },
    {
        nepali: "फ्लस भएको सेफ्टी ट्यांकीमा मिसिने",
        english: "Safety Tank",
        value: "safety_tank",
    },
    {
        nepali: "कच्ची चर्पी",
        english: "kachhi",
        value: "kachhi",
    },
    {
        nepali: "साधारण चर्पी",
        english: "normal",
        value: "normal_toilet",
    },
    {
        nepali: "सामुदायिक/ सार्वजनिक सार्वजनिक",
        english: "saamudayik",
        value: "saamudayik",
    },
    {
        nepali: "शौचालय नभएको",
        english: "no toilet",
        value: "no_toilet",
    },
];
export const roadLists = [
    {
        nepali: "पक्कि/कालोपत्रो सडक",
        english: "black pitched road",
        value: "blacktop",
    },
    {
        nepali: "ग्रावेल सडक",
        english: "gravel road",
        value: "gravel",
    },
    {
        nepali: "कच्ची सडक)",
        english: "dusty road",
        value: "kaccchi",
    },
    {
        nepali: "गोरेटो बाटो जोडिएको",
        english: "goreto bato",
        value: "goreto",
    },
];
export const wastemanagementLists = [
    {
        nepali: "फोहर व्यवस्थापनका लागि केहि पनि गरेको छैन",
        english: "No waste management",
        value: "no_waste_management	",
    },
    {
        nepali: "खाल्डोमा फ्याल्ने  गरेको",
        english: "Disposed unmanaged Ground",
        value: "gound_management",
    },
    {
        nepali: "वर्गीकरण गरी  बेग्ला बेग्लै  डस्टविनमा राख्ने गरेको",
        english: "Managed on dustbin",
        value: "Dustbin_use",
    },
    {
        nepali: "वर्गीकरण नगरी डस्टविनमा राख्ने गरेको",
        english: "dastbin",
        value: "dastbin",
    },
    {
        nepali: "जलाउने गरेको",
        english: "Burned",
        value: "burning",
    },
    {
        nepali: "नगरपालिकाको गाडीले उठाउने गरेको",
        english: "nagarpalika bus",
        value: "nagarpalika_bus",
    },
];
export const salttypeLists = [
    {
        nepali: "आयोडिन  युक्त नून",
        english: "Iodine Salt",
        value: "iodine_salt",
    },
    {
        nepali: "ढिको नून",
        english: "dhika salt",
        value: "dhiko_salt",
    },
    {
        nepali: "खुल्ला नून",
        english: "open salt",
        value: "open_salt",
    },
];

export const bankAccount = [
    {
        nepali: "छ",
        english: "Yes",
        value: "account_yes",
    },
    {
        nepali: "छैन",
        english: "No",
        value: "account_no",
    },
];
export const trueFalse = [
    {
        nepali: "छ",
        english: "Yes",
        value: true,
    },
    {
        nepali: "छैन",
        english: "No",
        value: false,
    },
];

export const illTreament = [
    {
        nepali: "धामी झाक्री",
        english: "dhami jhakri",
        value: "dhaami_jhakri",
    },
    {
        nepali: "औषधि पसल   / क्लिनिक",
        english: "pharmecy",
        value: "medical",
    },
    {
        nepali: "स्वास्थ्य चौकी",
        english: "health post",
        value: "health_post",
    },
    {
        nepali: "निजि अस्पताल",
        english: "district private hospital",
        value: "private_hospit",
    },
    {
        nepali: "स्थानीय स्वास्थ्य कर्मी",
        english: "health worker",
        value: "healthworker",
    },
    {
        nepali: "सरकारी अस्पताल",
        english: "District Government",
        value: "district_govt_",
    },
    {
        nepali: "प्राकृतिक चिकित्सा",
        english: "prakritik",
        value: "prakritik",
    },
    {
        nepali: "आयुर्वेदिक औषधालय",
        english: "aayurbedik",
        value: "aayurbedik",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "others_ill",
    },
];
export const bhattaLists = [
    {
        nepali: "छैन",
        english: "Not Taken",
        value: "not_taken",
    },
    {
        nepali: "पेन्सन नेपाल सरकार",
        english: "Pension Nepal Gov",
        value: "pension",
    },
    {
        nepali: "पेन्सन अन्य देश",
        english: "Pension Other Country",
        value: "pension_other",
    },
    {
        nepali: "बृद्ध भत्ता",
        english: "Old age allowance",
        value: "old",
    },
    {
        nepali: "विधुवा भत्ता",
        english: "Widow Allowance",
        value: "bidhuwa",
    },
    {
        nepali: "दलित बृद्ध भत्ता",
        english: "Dalit Allowance",
        value: "dalit",
    },
    {
        nepali: "अपाङ्गता भत्ता",
        english: "Disablity Allowance",
        value: "handicap",
    },
    {
        nepali: "सरकारी छात्रवृत्ति",
        english: "Scholarship",
        value: "scholarship",
    },
];
export const businessLists = [
    {
        nepali: "होमस्टे ब्यबसाय संचालन",
        english: "Home Stay",
        value: "home_stay",
    },
    {
        nepali: "भाडाकुडाका सामान बनाउने बेच्ने",
        english: "karuwa",
        value: "karuwa",
    },
    {
        nepali: "खोलामा जालबाट माछा मार्ने तथा बिक्रि वितरण गर्ने",
        english: "fishing",
        value: "river_fish_col",
    },
    {
        nepali: "हातेकागज",
        english: "Hand paper",
        value: "hand_paper",
    },
    {
        nepali: "खुकुरी",
        english: "khukuri",
        value: "khukuri",
    },
    {
        nepali: "राडीपाखी",
        english: "raadi pakhi",
        value: "raadi_pakhi",
    },
    {
        nepali: "कुराउनी",
        english: "kurauni",
        value: "kurauni",
    },
    {
        nepali: "फर्निचर",
        english: "furniture",
        value: "furniture",
    },
    {
        nepali: "अन्य",
        english: "other",
        value: "other",
    },
];
// export const herbLists = [
// 	{
// 		nepali: "अदामचल",
// 		english: "adaamchal",
// 		value: "adaamchal"
// 	},
// 	{
// 		nepali: "आखनबेद",
// 		english: "aakhanbed",
// 		value: "ankhanbed"
// 	},
// 	{
// 		nepali: "कुट्की",
// 		english: "kutki",
// 		value: "kutki"
// 	},
// 	{
// 		nepali: "ठुलो ओखती",
// 		english: "thulo okhati",
// 		value: "thulo_okhati"
// 	},
// 	{
// 		nepali: "हर्रो",
// 		english: "harro",
// 		value: "harro"
// 	},
// 	{
// 		nepali: "अमला",
// 		english: "amala",
// 		value: "aamla"
// 	},
// 	{
// 		nepali: "टिमुर",
// 		english: "timur",
// 		value: "timur"
// 	},
// 	{
// 		nepali: "चिरैतो",
// 		english: "chairato",
// 		value: "chirauto"
// 	},
// 	{
// 		nepali: "चानो",
// 		english: "chano",
// 		value: "chano"
// 	},
// 	{
// 		nepali: "बर्रो",
// 		english: "barro",
// 		value: "barro"
// 	},
// 	{
// 		nepali: "बोझो",
// 		english: "bojho",
// 		value: "bojho"
// 	},
// 	{
// 		nepali: "अन्य",
// 		english: "other",
// 		value: "other"
// 	}
// ];
export const landUsedliving = [
    {
        nepali: "निजि /आफ्नै",
        english: "private/self",
        value: "owner",
    },
    {
        nepali: "भाडामा",
        english: "rent",
        value: "rent",
    },
    {
        nepali: "संस्थागत",
        english: "Institution",
        value: "institution",
    },
    {
        nepali: "भोग चलनमा रहेको तर स्थायी पुर्जा प्राप्त नभएको",
        english: "not sthaai lalpurja",
        value: "not_sthaai_lalpurja",
    },
    {
        nepali: "सार्वजनिक जग्गा /सुकुम्बासी",
        english: "Sukumbasi",
        value: "sukumbasi",
    },
    {
        nepali: "संयुक्त आवास",
        english: "Joint resident",
        value: "Joint_resident",
    },
    {
        nepali: "अन्य",
        english: "other",
        value: "other",
    },
];

export const housetype = [
    {
        nepali: "माटो र ढुङ्गा",
        english: "mud",
        value: "mud",
    },
    {
        nepali: "सिमेन्ट र ढुँगा",
        english: "stone",
        value: "stone",
    },
    {
        nepali: "फ्रेम स्ट्रकचर",
        english: "frame",
        value: "frame",
    },
    {
        nepali: "लोड वेयरिङ्ग",
        english: "load wiring",
        value: "load_wiring",
    },
    {
        nepali: "काठको खम्बा",
        english: "wood",
        value: "wood",
    },
    {
        nepali: "अन्य",
        english: "Other House",
        value: "other_house	",
    },
];
export const rooftype = [
    {
        nepali: "स्टिल फ्रेम स्ट्रक्चर",
        english: "still structure",
        value: "still_structure",
    },
    {
        nepali: "आर.सी.सी. फ्रेम स्ट्रक्चर र आर.सी.सी. ढलानको छाना",
        english: "Rcc frame",
        value: "rcc_frame",
    },
    {
        nepali: "आर.सी.सी. फ्रेम स्ट्रक्चर र आर.सी.सी. जस्ताको छाना",
        english: "Rcc still",
        value: "rcc_still",
    },
    {
        nepali: "सिमेन्टको जोडाइमा ढुंगा/इटाको गाह्रो र आर.सी.सी. ढलानको छाना",
        english: "RCC",
        value: "rcc",
    },
    {
        nepali: "माटोको जोडाइमा ढुंगा/इटाको गाह्रो र आर.सी.सी. ढलानको छाना",
        english: "mud",
        value: "mud_rcc",
    },
    {
        nepali:
            "सिमेन्टको जोडाइमा ढुंगा/इटा/ब्लकको गाह्रो र जस्ता/स्लेटको छाना",
        english: "Break slate",
        value: "break_slate",
    },
    {
        nepali: "सेड वा कच्ची घर",
        english: "Slade",
        value: "slade",
    },
    {
        nepali: "माटोको जोडाइमा ढुंगा/इटा/ब्लकको गाह्रो र जस्ता/स्लेटको छाना",
        english: "Mud Slade tin",
        value: "mud_slade_tin",
    },
    {
        nepali: "अन्य भए खुलाउनुहोस",
        english: "other roof type",
        value: "other_roof",
    },
];
export const hasLanddocument = [
    {
        nepali: "छ",
        english: "yes",
        value: "land_doc_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "land_doc_no",
    },
];
// export const dhukutiKarobar = [
// 	{
// 		nepali: "छ",
// 		english: "Yes",
// 		value: "yes",
// 	},
// 	{
// 		nepali: "छैन",
// 		english: "No",
// 		value: "no",
// 	},
// ];
export const doHouseCriteriafullfill = [
    {
        nepali: "छ",
        english: "yes",
        value: "stadard_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "standard_no",
    },
];
export const hasHouseMappass = [
    {
        nepali: "छ",
        english: "yes",
        value: "map_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "map_know",
    },
];
export const title = [
    {
        nepali:
            "सूचना तथा प्रविधि (इलेक्ट्रोनिक कम्प्युटर/मोबाइल/रेडियो घडी आदि मर्मत)",
        english: "IT",
        value: "it",
    },
    {
        nepali: "इलेक्ट्रिकसियन",
        english: "electrician",
        value: "electrician",
    },
    {
        nepali: "होटल सम्बन्धि",
        english: "hotel",
        value: "hotel",
    },
    {
        nepali: "पेंटिंग",
        english: "painting",
        value: "painting",
    },
    {
        nepali: "प्लम्बर",
        english: "plumber",
        value: "plumber",
    },
    {
        nepali: "सिलाई,बुनाई,बुटिक,सृंगर,पार्लर आदि",
        english: "sewing",
        value: "sewing",
    },
    {
        nepali: "कृषि प्राविधिक तालिम तथा अध्ययन  (जेटिए , खाध्य प्रसोधन आदि )",
        english: "agri",
        value: "agri",
    },
    {
        nepali: "निर्माण सम्बन्धी  सिकर्मी / डकर्मी",
        english: "construction",
        value: "construction",
    },
    {
        nepali: "इन्जीनियरिङ्,अटो मोबाइल  र मेकानिक्स",
        english: "engineering",
        value: "engineering",
    },
    {
        nepali: "जनस्वास्थ्य सम्बन्धी",
        english: "public health",
        value: "public_health",
    },
    {
        nepali: "पशु स्वास्थ्य सम्बन्धी",
        english: "animal health",
        value: "animal_health",
    },
    {
        nepali: "वन सम्बन्धी",
        english: "forest",
        value: "forest",
    },
    {
        nepali: "पर्यटन टुर गाइड , ट्राभल र सत्कार",
        english: "tourism guide",
        value: "tourism_guide",
    },
    {
        nepali: "कला सम्बन्धी",
        english: "art",
        value: "art",
    },
    {
        nepali: "कानुनी साक्षरता",
        english: "law",
        value: "law",
    },
    {
        nepali: "विपदब्यबस्थापन सम्बन्धि",
        english: "disaster",
        value: "disaster",
    },
    {
        nepali: "अन्य (खुलाउनु होस्)",
        english: "other",
        value: "other",
    },
];
export const birthCertificate = [
    {
        nepali: "छ",
        english: "yes",
        value: "birth_cert_yes",
    },
    { nepali: "छैन", english: "no", value: "birth_cert_no" },
];
export const contraceptiveUse = [
    {
        nepali: "छ",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];
export const deliveryFromdoctor = [
    {
        nepali: "छ",
        english: "yes",
        value: "delivery_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "delivery_no",
    },
];
export const childDeath = [
    {
        nepali: "छ",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];
export const pregnanctcheck = [
    {
        nepali: "छ",
        english: "yes",
        value: "check_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "check_no",
    },
];
// export const childnutrition = [
// 	{
// 		nepali: "छ",
// 		english: "yes",
// 		value: "mal_nutri_yes"
// 	},
// 	{
// 		nepali: "छैन",
// 		english: "no",
// 		value: "mal_nutri_no"
// 	}
// ];
export const childlabour = [
    {
        nepali: "छ",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];

export const childlabor = [
    {
        nepali: "छ",
        english: "yes",
        value: "yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "no",
    },
];

export const earlymarriage = [
    {
        nepali: "छ",
        english: "yes",
        value: "e_marry_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "e_marry_no",
    },
];
export const pregnanttetanus = [
    {
        nepali: "छ",
        english: "yes",
        value: "tetanus_yes",
    },
    {
        nepali: "छैन",
        english: "no",
        value: "tetanus_no",
    },
];
export const preprimary = [
    {
        nepali: "१",
        english: "1",
        value: "class_1",
    },
    {
        nepali: "२",
        english: "2",
        value: "class_2",
    },
    {
        nepali: "३",
        english: "3",
        value: "class_3",
    },
    {
        nepali: "४",
        english: "4",
        value: "class_4",
    },
    {
        nepali: "५",
        english: "5",
        value: "class_5",
    },
];

export const answererRelation = [
    {
        nepali: "घरमुली आफै",
        english: "Self house owner",
        value: "self_house_own",
    },
    {
        nepali: "श्रीमान",
        english: "Husband",
        value: "husband",
    },
    {
        nepali: "श्रीमती",
        english: "Wife",
        value: "wife",
    },
    {
        nepali: "आमा",
        english: "Mother",
        value: "mother",
    },
    {
        nepali: "बुवा",
        english: "Father",
        value: "father",
    },
    {
        nepali: "छोरा",
        english: "Son",
        value: "son",
    },
    {
        nepali: "छोरी",
        english: "Daughter",
        value: "daughter",
    },
    {
        nepali: "बुहारी",
        english: "Buhari",
        value: "buhari",
    },
    {
        nepali: "ज्वाई",
        english: "Jwai",
        value: "jwai",
    },
    {
        nepali: "सासु",
        english: "Sasu",
        value: "sashu",
    },
    {
        nepali: "ससुरा",
        english: "Sasura",
        value: "sasura",
    },
    {
        nepali: "काका",
        english: "Kaka",
        value: "kaka",
    },
    {
        nepali: "काकी",
        english: "Kaki",
        value: "kaki",
    },
    {
        nepali: "फुपू",
        english: "Fupu",
        value: "fupu",
    },
    {
        nepali: "फुपाजु",
        english: "Fupaju",
        value: "fupaju",
    },
    {
        nepali: "मामा",
        english: "Mama",
        value: "mama",
    },
    {
        nepali: "माइजु",
        english: "Maiju",
        value: "maiju",
    },
    {
        nepali: "नाती",
        english: "Nati",
        value: "nati",
    },
    {
        nepali: "नातिनी",
        english: "Natini",
        value: "natini",
    },
    {
        nepali: "दाजु",
        english: "Daju",
        value: "daju",
    },
    {
        nepali: "भाई",
        english: "Bhai",
        value: "bhai",
    },
    {
        nepali: "दिदि",
        english: "Didi",
        value: "didi",
    },
    {
        nepali: "बहिनी",
        english: "",
        value: "bahini",
    },
    {
        nepali: "भाउजु",
        english: "Bhauju",
        value: "bhauju",
    },
    {
        nepali: "देवर",
        english: "dewar",
        value: "dewar",
    },
    {
        nepali: "भाई बुहारी",
        english: "Bhai Buhari",
        value: "bhai_buhari",
    },
    {
        nepali: "जेठान",
        english: "Jethan",
        value: "jethan",
    },
    {
        nepali: "ठुलो बुवा",
        english: "Thulo Buwa",
        value: "thulo_bbuwa",
    },
    {
        nepali: "ठुलो आमा",
        english: "Thulo Aama",
        value: "thulo_aama",
    },
    {
        nepali: "हजुर बुवा",
        english: "Hajur Buwa",
        value: "grand_father",
    },
    {
        nepali: "हजुर आमा",
        english: "Hajur Aama",
        value: "grand_mother",
    },
    {
        nepali: "अन्य",
        english: "other",
        value: "other",
    },
];
export const agriCropTypeList = [
    {
        nepali: "धान",
        english: "Paddy",
        value: "dhaan",
    },
    {
        nepali: "मकै",
        english: "Maize",
        value: "maize",
    },
    {
        nepali: "कोदो",
        english: "Kodo",
        value: "kodo",
    },
    {
        nepali: "फापर",
        english: "fapar",
        value: "fapar",
    },
    {
        nepali: "गहू/ जौ",
        english: "Gahu",
        value: "gahu",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "crop_other",
    },
];

export const agriDaalType = [
    {
        nepali: "रहर",
        english: "Rahar",
        value: "rahar",
    },
    {
        nepali: "भटमास",
        english: "Bhatmas",
        value: "musuro",
    },
    {
        nepali: "सिमी",
        english: "Simi",
        value: "simi",
    },
    {
        nepali: "गहत",
        english: "gahat",
        value: "gahat",
    },
    {
        nepali: "मटर / केराउ",
        english: "black daal",
        value: "black_daal",
    },
    {
        nepali: "बोडी / मस्यांग",
        english: "khesari",
        value: "khesari",
    },
    {
        nepali: "चना /मुसुरो",
        english: "chana musuro",
        value: "chana_musuro",
    },
    {
        nepali: "मासको दाल / कालो दाल",
        english: "kalo daal",
        value: "kalo_daal",
    },
    {
        nepali: "खेसरी",
        english: "khesari",
        value: "khesari_1",
    },
    {
        nepali: "अन्य",
        english: "other daal",
        value: "other_daal",
    },
];
export const agriOilType = [
    {
        nepali: "तोरी / सर्स्यु",
        english: "Tori",
        value: "tori",
    },
    {
        nepali: "आलस/तिल",
        english: "Aalash",
        value: "aalash",
    },
    {
        nepali: "सूर्यमुखी",
        english: "Sunflower",
        value: "sunflower",
    },
    {
        nepali: "चिउरी",
        english: "chiuri",
        value: "chiuri",
    },
    {
        nepali: "फिलुंगे",
        english: "philunge",
        value: "philunge",
    },
    {
        nepali: "अन्य",
        english: "other",
        value: "other_oilseed",
    },
];
export const agriVegetableType = [
    {
        nepali: "आलु",
        english: "Potato",
        value: "potato",
    },
    {
        nepali: "बन्दा/काउली",
        english: "Cabbage",
        value: "cabbage",
    },
    {
        nepali: "गोलभेडा(टमाटर )",
        english: "Tomato",
        value: "tomato",
    },
    {
        nepali: "फर्सी /लौका",
        english: "Cucumber",
        value: "cucumber",
    },
    {
        nepali: "मुला/ काक्रो /गाजर ",
        english: "bittergroud",
        value: "bittergroud",
    },
    {
        nepali: "रायो (साग सब्जी )",
        english: "Rayo Saag",
        value: "vejetable_raay",
    },
    {
        nepali: "करेला/भेन्टा/भिंडी",
        english: "karela",
        value: "karelaa_bhenta_bhindi",
    },
    {
        nepali: "पर्वल",
        english: "parwal",
        value: "parwal",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other_vegetabl",
    },
];

export const agriFruitType = [
    {
        nepali: "आरु",
        english: "aaru",
        value: "aaru",
    },
    {
        nepali: "आप",
        english: "mango",
        value: "mango",
    },
    {
        nepali: "स्याउ",
        english: "apple",
        value: "apple",
    },
    {
        nepali: "काफल",
        english: "kafal",
        value: "kafal",
    },
    {
        nepali: "मेवा",
        english: "papaiya",
        value: "papaiya",
    },
    {
        nepali: "लिची",
        english: "lichi",
        value: "lichi",
    },
    {
        nepali: "केरा",
        english: "Bananaa",
        value: "Bananaa",
    },
    {
        nepali: "भुइँकटर",
        english: "pinappale",
        value: "pinappale",
    },
    {
        nepali: "ड्रागन फ्रुट",
        english: "Dragan_Fruit",
        value: "Dragan_Fruit",
    },
    {
        nepali: "अनार",
        english: "pomegranate",
        value: "pomegranate",
    },
    {
        nepali: "एभाकाडो",
        english: "pulam",
        value: "pulam",
    },
    {
        nepali: "अम्बा",
        english: "Guava",
        value: "ambaa",
    },
    {
        nepali: "किवि",
        english: "Kiwi",
        value: "jack_fruit",
    },
    {
        nepali: "हलुवाबेद",
        english: "haluwaabed",
        value: "haluwaabed",
    },
    {
        nepali: "नासपाती",
        english: "Pear",
        value: "nasspaati",
    },
    {
        nepali: "सुन्तला/जुनार /कागती (सुन्तला  जात )",
        english: "orange",
        value: "orange",
    },
    {
        nepali: "अन्य",
        english: "other fruit",
        value: "other_fruit",
    },
];

export const cashCropType = [
    {
        nepali: "लसुन/प्याज",
        english: "garlic",
        value: "garlic",
    },
    {
        nepali: "अदुवा/बेसार",
        english: "ginger",
        value: "ginger",
    },
    {
        nepali: "उखु",
        english: "sugarcane",
        value: "sugarcane",
    },
    {
        nepali: "कफी",
        english: "Coffee",
        value: "coffee",
    },
    {
        nepali: "चिया",
        english: "Tea",
        value: "tea",
    },
    {
        nepali: "च्याउ",
        english: "Mushroom",
        value: "mushroom",
    },
    {
        nepali: "अलैची",
        english: "Alainchi",
        value: "chilly",
    },
    {
        nepali: "ओखर",
        english: "okhar",
        value: "okhar",
    },
    {
        nepali: "अम्लिसो",
        english: "amliso",
        value: "amliso",
    },
    {
        nepali: "सुपारी",
        english: "bitter-nut",
        value: "supari",
    },
    {
        nepali: "नरिवल",
        english: "coconut",
        value: "coconut",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other_cash_cro",
    },
];
export const autoSuggetionString = [
    { nepali: "वडा", english: "Ward", value: "wardNumber" },
    { nepali: "लिङ्ग", english: "Gender", value: "gender" },

    { nepali: "उमेर", english: "Age", value: "age" },
    {
        nepali: "सरदर कृषी बाहेक (अन्य पेशा वा व्यावसायबाट) वार्षिक खर्च",
        english: "Annual Income",
        value: "nonAgriExpense",
    },
    {
        nepali: "सरदर कृषी बाहेक (अन्य पेशा वा व्यावसायबाट) वार्षिक आम्दानी",
        english: "Annual Expenditure",
        value: "nonAgriIncome",
    },
    {
        nepali: "सरदर कृषिमा वार्षिक आम्दानी",
        english: "Annual Expenditure",
        value: "agriIncome",
    },
    {
        nepali: "सरदर कृषिमा वार्षिक खर्च",
        english: "Annual Income",
        value: "agriExpense",
    },
    {
        nepali: "घरमुली संगको नाता",
        english: "AnswererRelation",
        value: "answererRelation",
    },
    { nepali: "जात/जाती", english: "Ethnics", value: "caste" },
    // { nepali: "कुन जात /जाती", english: "Which Ethnics", value: "caste" },
    { nepali: "धर्म", english: "Religion", value: "religion" },
    { nepali: "शिक्षा", english: "Education", value: "educationLevel" },
    // {
    // 	nepali: "पूर्व प्राथमिक",
    // 	english: "Preprimary",
    // 	value: "prePrimaryDetails"
    // },
    { nepali: "आधारभूत तह", english: "Primary", value: "oneToEightClass" },
    { nepali: "माध्यमिक तह", english: "Secondary", value: "nineToTwelve" },
    { nepali: "पेशा", english: "Occupation", value: "levelOneJob" },
    { nepali: "ज्याला/ मजदुरी", english: "Job", value: "levelTwoJob" },
    // {
    // 	nepali: "शिक्षक/सुरक्षा निकाय",
    // 	english: "Teacher/Security",
    // 	value: "levelThreeJob"
    // },
    {
        nepali: "खेतिवाली को किसिम",
        english: "Agriculture Type",
        value: "agricultureProductSalesType",
    },
    {
        nepali: "अन्न वाली को किसिम",
        english: "Agriculture Type",
        value: "agricultureProductionSalesType_crops",
    },
    {
        nepali: "दालको किसिम",
        english: "Agriculture Type",
        value: "agricultureProductionSalesType_daal",
    },
    {
        nepali: "तेलहनको किसिम",
        english: "Agriculture Type",
        value: "agricultureProductionSalesType_oilseed",
    },
    {
        nepali: "तरकारीको किसिम",
        english: "Agriculture Type",
        value: "agricultureProductionSalesType_vegetable",
    },
    {
        nepali: "फलफुलको किसिम",
        english: "Agriculture Type",
        value: "agricultureProductionSalesType_fruit",
    },
    {
        nepali: "नगदेवालीको किसिम",
        english: "Agriculture Type",
        value: "agricultureProductionSalesType_cash_crops",
    },

    {
        nepali: "स्वास्थ्य",
        english: "Health Condition",
        value: "healthCondition",
    },
    {
        nepali: "दिर्घ रोग",
        english: "Unhealthy Disease",
        value: "unhealthyDisease",
    },
    {
        nepali: "बिमा छ/छैन",
        english: "",
        value: "insurance_hasInsurance",
    },
    {
        nepali: "बिमा",
        english: "insuranceType",
        value: "insurance_insuranceType",
    },
    { nepali: "अपांगताको किसिम", english: "Disable", value: "type" },
    {
        nepali: "अपांगताको स्थिति",
        english: "Disable Condition",
        value: "condition",
    },
    // { nepali: "अपांगताको कार्ड", english: "Disable Card", value: "hasCard" },
    // { nepali: "कार्डको किसिम", english: "", value: "cardType" },
    { nepali: "मातृभाषा", english: "MotherTongue", value: "motherTongue" },
    {
        nepali: "रगत समुह",
        english: "Blood Group",
        value: "bloodGroup_bloodType",
    },
    // { nepali: "निर्वाचन परिचय पत्र", english: "Voter Id", value: "voterId" },
    {
        nepali: "बैवाहिक स्थिति",
        english: "MartialStatus",
        value: "martialStatus",
    },
    // { nepali: "बसोबासको अवस्था", english: "Living Type", value: "livingType" },
    // {
    // 	nepali: "इस्थाइ बसोबास",
    // 	english: "Permanent Living Type",
    // 	value: "permanentLivingAddress",
    // },
    {
        nepali: "खानेपानीको श्रोत",
        english: "Drinking Water Resource",
        value: "waterSource",
    },
    {
        // nepali: "खानेपानीको उपलब्ध स्थान",
        nepali: "खाने पानीको आधारमा",
        english: "Drinking Water Place",
        value: "distanceToWaterSource",
    },
    {
        nepali: "खाना पकाउने प्रमुख इन्धन/चुलो",
        english: "Cooking Resource",
        value: "cookingSource",
    },
    {
        nepali: "घरमा कस्तो प्रकारको चुलो प्रयोग हुन्छ",
        english: "Stove Detail",
        value: "stoveDetail",
    },
    {
        nepali: "घरबाट निस्किने ठोस फोहोरको व्यवस्थापन",
        english: "Dirty Water Management",
        value: "wasteDisposal",
    },
    // {
    // 	nepali: "पिउने पानीको शुद्धीकरण",
    // 	english: "Water Purification",
    // 	value: "waterPurification",
    // },
    {
        nepali: "वत्तिको मुख्य स्रोत",
        english: "Light Resource",
        value: "electricSource",
    },
    // { nepali: "घरमा शौचालय छ", english: "Toilet Type", value: "toilet_isToilet" },
    // {
    // 	nepali: "शौचालय छैन भने किन नभएको",
    // 	english: "Toilet Type",
    // 	value: "toilet_toiletNo",
    // },
    {
        nepali: "शौचालयको प्रकार",
        english: "Toilet Type",
        value: "toilet_toiletYes",
    },
    { nepali: "सडक/बाटोको अवस्था", english: "Road Type", value: "road" },
    // {
    // 	nepali: "फोहर मैलाको व्यवस्थापन",
    // 	english: "Waste Management",
    // 	value: "wasteDisposal",
    // },
    // { nepali: "नून प्रयोग", english: "Salt Type", value: "saltUsed" },
    { nepali: "घरको सुबिधा", english: "House Facility", value: "item_title" },
    { nepali: "पशुपंक्षी", english: "live Stock", value: "detail_name" },

    // {
    // 	nepali: "घरेलु व्यवसाय",
    // 	english: "Home Business",
    // 	value: "home_homeBusinessName",
    // },
    // {
    // 	nepali: "घरेलु जडिबुटी व्यवसाय",
    // 	english: "Home Herb Business",
    // 	value: "herb_herbBusinessName"
    // },

    {
        nepali: "बैंक तथा वित्तीय संस्थामा खाता",
        english: "Bank Account",
        value: "bankAccount",
    },
    { nepali: "बिरामी जाँच ", english: "illTreament", value: "illTreament" },
    // {
    // 	nepali: "भत्ता को किसिम",
    // 	english: "Bhatta list",
    // 	value: "socialAllowance",
    // },
    // {
    // 	nepali: "जग्गाको किसिम",
    // 	english: "Land Used Living",
    // 	value: "landUsedLiving",
    // },
    {
        nepali: "घरको जगको प्रकार",
        english: "House Type",
        value: "houseFoundation",
    },
    { nepali: "घरको छानाको किसिम", english: "Roof Type", value: "roofType" },
    // {
    // 	nepali: "जग्गाको कागजपत्र",
    // 	english: "Has Land Document",
    // 	value: " ",
    // },
    // {
    // 	nepali: "परिवारको ढुकुटी संग कारोवार छ कि छैन",
    // 	english: "",
    // 	value: "dhukutiKarobar",
    // },
    {
        nepali: "घर मापदण्ड",
        english: "House Criteria",
        value: "doHouseCriteriaFullfill",
    },
    {
        nepali: "घरको नक्सा पास",
        english: "House Map Pass",
        value: "hasHouseMapPass",
    },
    { nepali: "शिप र तालिम", english: "Skill Detail", value: "title" },
    // {
    // 	nepali: "जन्म दर्ता",
    // 	english: "Birth Certificate Detail",
    // 	value: "birthCertificate",
    // },
    // {
    // 	nepali: "परिवार निवोजनको साधन प्रयोग गर्नु हुन्छ कि हुन्न ",
    // 	english: "",
    // 	value: "contraceptiveUse",
    // },
    // {
    // 	nepali: "स्वास्थ्य कर्मीबाट सुत्केरी",
    // 	english: "Delivery From Doctor",
    // 	value: "deliveryFromDoctor",
    // },
    // {
    // 	nepali: "हैजा, झाडापखालाबाट मृतु हुनु हुने बालबालिका",
    // 	english: "",
    // 	value: "childDeath",
    // },
    // {
    // 	nepali: "गर्भवती महिलाको जाँच ",
    // 	english: "Check-Up of Pregnant",
    // 	value: "pregnanctCheck",
    // },
    // {
    // 	nepali: "पोषण सम्बन्धी समस्या",
    // 	english: "Child Nutrition",
    // 	value: "childNutrition"
    // },
    // {
    // 	nepali: "परिवारको कुनै १६ वर्ष मुनिका वालवालिका श्रम",
    // 	english: "Child Labour",
    // 	value: "childLabour",
    // },
    // {
    // 	nepali: "घरमा १६ वर्ष मुनिका बालबालिका कामदार राख्नु भएको छ ",
    // 	english: "Child Labor",
    // 	value: "childLabor",
    // },
    // { nepali: "वाल विवाह", english: "Early Marriage", value: "earlyMarriage" },
    // {
    // 	nepali: "टिटानस खोप",
    // 	english: "Pregnant Tetanus",
    // 	value: "pregnantTetanus",
    // },
    {
        nepali: "विदेसिएको देश",
        english: "Abroad Country",
        value: "foreignCountry",
    },
    {
        nepali: "विदेसिएको कारण",
        english: "Abroad Reason",
        value: "abroadReason",
    },
];

export const abroadCountryList = [
    {
        nepali: "भारत",
        english: "India",
        value: "india",
    },
    {
        nepali: "कतार",
        english: "Quatar",
        value: "quatar",
    },
    {
        nepali: "भारत बाहेक अन्य सार्क राष्ट्र",
        english: "SAARC",
        value: "saarc",
    },
    {
        nepali: "साउदी अरब",
        english: "Saudi Arab",
        value: "saudi_arab",
    },
    {
        nepali: "मलेसिया",
        english: "Malayasia",
        value: "malaysia",
    },
    {
        nepali: "जापान",
        english: "Japan",
        value: "japan",
    },
    {
        nepali: "यु.ए.ई. (दुबई)",
        english: "UAE",
        value: "uae",
    },
    {
        nepali: "दक्षिण कोरिया",
        english: "South Korea",
        value: "south_korea",
    },
    {
        nepali: "अष्ट्रेलिया",
        english: "Australia",
        value: "australia",
    },
    {
        nepali: "अमेरिका",
        english: "USA",
        value: "usa",
    },
    {
        nepali: "इराक",
        english: "Iraq",
        value: "iraak",
    },
    {
        nepali: "कुवेत",
        english: "Kuwait",
        value: "kuwait",
    },
    {
        nepali: "क्यानाडा",
        english: "Canada",
        value: "canada",
    },
    {
        nepali: "जर्मनी",
        english: "Germany",
        value: "germany",
    },
    {
        nepali: "बहराइन",
        english: "Bahrain",
        value: "bahrain",
    },
    {
        nepali: "ओमान",
        english: "Oman",
        value: "oman",
    },
    {
        nepali: "साइप्रस",
        english: "Cyprus",
        value: "cyprus",
    },
    {
        nepali: "फ्रान्स",
        english: "France",
        value: "france",
    },
    {
        nepali: "चीन",
        english: "China",
        value: "china",
    },
    {
        nepali: "UK",
        english: "UK",
        value: "uk",
    },
    {
        nepali: "स्पेन",
        english: "Spain",
        value: "germany",
    },
    {
        nepali: "इटाली",
        english: "Italy",
        value: "italy",
    },
    {
        nepali: "बेल्जियम",
        english: "Belgium",
        value: "belgium",
    },
    {
        nepali: "पोल्याण्ड",
        english: "Poland",
        value: "poland",
    },
    {
        nepali: "पोर्चुगल",
        english: "Portugal",
        value: "portugal",
    },
    {
        nepali: "अन्य",
        english: "other country",
        value: "other",
    },
];
export const abroadReasonList = [
    {
        nepali: "अध्ययन / तालिम",
        english: "Education",
        value: "education",
    },
    {
        nepali: "तलब/ ज्याला / नोकरी",
        english: "employement",
        value: "nokari",
    },
    {
        nepali: "डी.भी. चिठ्ठा",
        english: "dv lottery",
        value: "dv_lottery",
    },
    {
        nepali: "व्यापार /व्यावसाय",
        english: "business",
        value: "Business",
    },
    {
        nepali: "आश्रित",
        english: "aashreet",
        value: "aashreet",
    },
    {
        nepali: "अन्य",
        english: "other reason",
        value: "other_reason",
    },
];

export const livestockDiffType = [
    {
        nepali: "गाई",
        english: "Cow",
        value: "cow",
    },
    {
        nepali: "भैसी",
        english: "Buffalo",
        value: "buffello",
    },
    {
        nepali: "खसी/ बाख्रा /बोका",
        english: "Goat",
        value: "khasi",
    },
    {
        nepali: "राँगा/पाडा/पाडी",
        english: "Ranga",
        value: "paada_ranga",
    },
    {
        nepali: "सुँगुर/बंगुर",
        english: "Pig",
        value: "pig",
    },
    {
        nepali: "भेडा",
        english: "Sheep",
        value: "sheep",
    },
    {
        nepali: "गोरु",
        english: "OX",
        value: "goru",
    },
    {
        nepali: "घोडा",
        english: "Horse",
        value: "horse",
    },
    {
        nepali: "चौरी गाई",
        english: "chauri cow",
        value: "chauri_gaai",
    },
    {
        nepali: "बाछा /बाछी",
        english: "calf",
        value: "baachhabachhi",
    },
    {
        nepali: "च्यांग्रा",
        english: "shrimp",
        value: "chhyangra",
    },
    {
        nepali: "अन्य",
        english: "Other",
        value: "other",
    },
];

export const livestockType = [
    {
        nepali: "पशुपालन",
        english: "Animal",
        value: "animal",
    },
    {
        nepali: "पन्छीपालन",
        english: "Bird",
        value: "bird",
    },
    {
        nepali: "पशु र पन्छी दुवै पाल्ने",
        english: "Bird and animal",
        value: "both",
    },
    {
        nepali: "कुनै पनि नपाल्ने",
        english: "not any",
        value: "not_any",
    },
];
export const wardSelect = [...Array(totalWard).keys()].map((each) => ({
    nepali: `वडा न ${each + 1}`,
    english: `Ward Number ${each + 1}`,
    value: `${each + 1}`,
}));
export const searchOption = {
    gender: [
        {
            nepali: "पुरुष",
            english: "Male",
            value: "male",
        },
        {
            nepali: "महिला",
            english: "Female",
            value: "female",
        },
        {
            nepali: "अन्य",
            english: "other sex",
            value: "other_sex",
        },
    ],
    wardNumber: [...Array(totalWard).keys()].map((each) => ({
        nepali: `वडा न ${each + 1}`,
        english: `Ward Number ${each + 1}`,
        value: each + 1,
    })),
    agricultureProductSalesType: [
        { nepali: "अन्न वाली", english: "", value: "crops" },
        { nepali: "दलहन", english: "", value: "daal" },
        { nepali: "तेलहन", english: "", value: "oilseed" },
        { nepali: "तरकारी बाली", english: "", value: "vegetable" },
        { nepali: "फलफुल खेती", english: "", value: "fruit" },
        { nepali: "नगदेवाली", english: "", value: "cash_crops" },
        { nepali: "फुलखेती", english: "", value: "flower" },
    ],

    // detail_name: [
    // 	{ nepali: "पशुि", english: "Animal", value: "animal" },
    // 	{ nepali: "पन्छी", english: "Bird", value: "bird" },
    //
    // ],
    // agricultureProductionSalesType_crops: [
    // 	{ nepali: "अन्न वालि", english: "", value: "crops" },
    // 	{ nepali: "दाल वालि", english: "", value: "daal" },
    // 	{ nepali: "तेलहन उत्पादन", english: "", value: "oilseed" },
    // 	{ nepali: "तरकारी उत्पादन", english: "", value: "vegetable" },
    // 	{ nepali: "फलफुल", english: "", value: "fruit" },
    // 	{ nepali: "नगदेवाली", english: "", value: "cash_crops" },
    // 	{ nepali: "फुलखेती", english: "", value: "flower" }
    // ],
    item_title: [
        {
            nepali: "कुनै पनि सुविधा नभएको",
            english: "NoFacility",
            value: "no_any_facilities",
        },
        {
            nepali: "सिटि सफारी",
            english: "safari",
            value: "siti_safari",
        },
        {
            nepali: "टि.भी",
            english: "T.V",
            value: "tv",
        },
        {
            nepali: "रेडियो",
            english: "Radio",
            value: "radio",
        },
        {
            nepali: "मोबाइल",
            english: "Mobile",
            value: "mobile",
        },
        {
            nepali: "इन्टरनेट",
            english: "Internet",
            value: "internet",
        },
        {
            nepali: "ल्यापटप  / कम्पुटर",
            english: "Laptop",
            value: "laptop",
        },
        {
            nepali: "मोटरसाइकल",
            english: "Motor Cycle",
            value: "Motorcycle",
        },
        {
            nepali: "साइकल",
            english: "Cycle",
            value: "cycle",
        },
        {
            nepali: "स्कुटर",
            english: "Scooter",
            value: "scooter",
        },
        {
            nepali: "कार",
            english: "Car",
            value: "car",
        },
        {
            nepali: "जिप /अटो",
            english: "Jeep",
            value: "jeep",
        },
        {
            nepali: "ट्राकटर",
            english: "Tractor",
            value: "tractor",
        },
        {
            nepali: "ट्रक",
            english: "Truck",
            value: "truck",
        },
        {
            nepali: "टेम्पो",
            english: "Tempo",
            value: "tempo",
        },
        {
            nepali: "डोज़र",
            english: "Dozar",
            value: "dozar",
        },
        {
            nepali: "रेफ्रेजिरेटर",
            english: "Refrigerator",
            value: "refrigerator",
        },
        {
            nepali: "ट्रीपर",
            english: "Tripar",
            value: "tripar",
        },
        {
            nepali: "ल्याण्ड लाईन टेलिफोन",
            english: "Telephone",
            value: "telephone",
        },
        {
            nepali: "विधुतीय सवारीसाधन",
            english: "ELECTRIC VEHICLE",
            value: "ELECTRIC_VEHICLE",
        },
        {
            nepali: "अन्य",
            english: "other vechile",
            value: "other_vehcileT",
        },
    ],

    answererRelation: answererRelation,
    caste: casteList,
    ethnics: [
        {
            nepali: "राई",
            english: "Rai",
            value: "rai",
        },
        {
            nepali: "मगर",
            english: "Magar",
            value: "magar",
        },
        {
            nepali: "शेर्पा",
            english: "Sherpa",
            value: "sherpa",
        },
        {
            nepali: "गुरुङ",
            english: "Gurung",
            value: "gurung",
        },
        {
            nepali: "तामाङ्ग",
            english: "Tamang",
            value: "tamang",
        },
        {
            nepali: "नेवार",
            english: "Newar",
            value: "newar",
        },
        {
            nepali: "थकाली",
            english: "Thakali",
            value: "thakali",
        },
        {
            nepali: "लिम्बु",
            english: "Limbu",
            value: "limbu",
        },
        {
            nepali: "भुजेल",
            english: "Bhujel",
            value: "bhujel",
        },
        {
            nepali: "घले",
            english: "ghale",
            value: "ghale",
        },
        // {
        // 	nepali: "गिरि",
        // 	english: "Giri",
        // 	value: "giri"
        // },
        // {
        // 	nepali: "पुरी",
        // 	english: "Puri",
        // 	value: "puri"
        // },
        // {
        // 	nepali: "भारती",
        // 	english: "Bharati",
        // 	value: "bharati"
        // },
        // {
        // 	nepali: "सन्यासी/दशनामी",
        // 	english: "Sansayi",
        // 	value: "sansayi"
        // },
        // {
        // 	nepali: "योगी",
        // 	english: "Yogi",
        // 	value: "yogi"
        // },
        // {
        // 	nepali: "कुँवर",
        // 	english: "Kunwar",
        // 	value: "kunwar"
        // }
    ],

    religion: religionList,
    educationLevel: educationList,
    // prePrimaryDetails: preprimaryList,
    oneToEightClass: primaryList,
    nineToTwelve: secondaryList,
    levelOneJob: occupationList,
    levelTwoJob: jobList,
    // levelThreeJob: teacherList,
    healthCondition: healthconditionList,
    unhealthyDisease: unhealthy,
    insurance_hasInsurance: hasInsuranceList,
    insurance_insuranceType: insuranceType,
    type: mentaltype,
    condition: mentalcondition,
    hasCard: mentalCardYesNo,
    cardType: mentalCardtype,
    motherTongue: mothertongue,
    bloodGroup_bloodType: bloodgroup,
    voterId: voterIdYesNo,
    martialStatus: marriage,
    livingType: livingTypeList,
    permanentLivingAddress: permanent,
    waterSource: drinkingwaterLists,
    distanceToWaterSource: drinkingwaterplaceLists,
    cookingSource: cookingresourceLists,
    stoveDetail: stoveLists,
    dirtyWaterManagement: dirtyWaterManagement,
    waterPurification: waterPurification,
    electricSource: lightresourceLists,
    // toilet_isToilet: isToilet,
    // toilet_toiletNo: noToilet,
    toilet_toiletYes: toiletresourceLists,
    road: roadLists,
    wasteDisposal: wastemanagementLists,
    saltUsed: salttypeLists,
    bankAccount: bankAccount,
    illTreament: illTreament,
    // socialAllowance: bhattaLists,
    // home_homeBusinessName: businessLists,
    // herb_herbBusinessName: herbLists,
    landUsedLiving: landUsedliving,
    houseFoundation: housetype,
    roofType: rooftype,
    hasLandDocument: hasLanddocument,
    // dhukutiKarobar: dhukutiKarobar,
    doHouseCriteriaFullfill: doHouseCriteriafullfill,
    hasHouseMapPass: hasHouseMappass,
    title: title,
    birthCertificate: birthCertificate,
    contraceptiveUse: contraceptiveUse,
    deliveryFromDoctor: deliveryFromdoctor,
    childDeath: childDeath,
    pregnanctCheck: pregnanctcheck,
    // childNutrition: childnutrition,
    childLabour: childlabour,
    childLabor: childlabor,
    earlyMarriage: earlymarriage,
    pregnantTetanus: pregnanttetanus,
    agricultureProductionSalesType_crops: agriCropTypeList,
    agricultureProductionSalesType_daal: agriDaalType,
    agricultureProductionSalesType_oilseed: agriOilType,
    agricultureProductionSalesType_vegetable: agriVegetableType,
    agricultureProductionSalesType_fruit: agriFruitType,
    agricultureProductionSalesType_cash_crops: cashCropType,
    detail_name: livestockType,
    foreignCountry: abroadCountryList,
    abroadReason: abroadReasonList,
};
export const tableTitles = [
    { english: "S.N", nepali: "क्र.स" },
    { english: "", nepali: "वडा नं", value: "wardNumber" },
    { english: "", nepali: "घर नं", value: "houseNumber" },
    { english: "", nepali: "नाम थर", value: "fullName" },
    { english: "", nepali: "नाम थर", value: "answererName" },
    { english: "", nepali: "लिङ्ग", value: "gender" },
    { english: "", nepali: "उमेर", value: "age" },
    { english: "", nepali: "घरमुलीसंगको नाता", value: "answererRelation" },
    { english: "", nepali: "फोन नम्बर", value: "contactNumber" },
    { nepali: "फोन नम्बर", english: "Living Type", value: "houseOwnerMobile" },
    {
        nepali: "बसोबासको अवस्था",
        english: "Living Type",
        value: "livingStatus",
    },
    {
        nepali: "बार्षिक आम्दानी",
        english: "Annual Income",
        value: "nonAgriIncome",
    },
    {
        nepali: "सडक/बाटोको अवस्था",
        english: "Road Condition",
        value: "road",
    },
];
