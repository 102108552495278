import React from "react";
import {
    doHouseCriteriaFullfill,
    doHouseCriteriaFullfillQuestion,
    familyRiskPlan,
    familyRiskPlanQn,
    familyRiskPlanYesQn,
    hasHouseMapPass,
    hasHouseMapPassQuestion,
    hasLandDocument,
    hasLandDocumentQuestion,
    houseAge,
    houseCount,
    houseFoundation,
    houseFoundationQn,
    houseMapPass,
    houseType,
    houseTypeQuestion,
    houseUsedLiving,
    houseUsedLivingOption,
    ifOtherValueQuestion,
    isHouseOnRent,
    isHouseOnRentQuestion,
    otherHouseLocation,
    otherHouseLocationQn,
    rentGivenTo,
    rentGivenToQuestion,
    riskAreaAroundHouse,
    riskAreaAroundHouseQn,
    roofType,
    roofTypeQuestion,
    totalRoom,
    totalStorey,
    womanOwnedHome,
    womanOwnedHomeQn,
    ownLandOrFamilyLand,
} from "../data/houseSurvey";
import { disasterEffectList } from "../data/madhyamikGharKoboValue";
import { selectedLanguage } from "../data/institute";
import Select from "react-select";
import CustomSelectMulti from "../components/CustomSelectMulti";
import SingleSelectInput from "../components/SingleSelectInput";

const PrathamikGharForm = (props) => {
    const { formik } = props;
    const { houseDetail } = formik.values;
    return (
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
            <div class="col-xl-12 col-xxl-8">
                <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                >
                    <h3 class="mb-10 font-weight-bold text-dark">
                        प्रथामिक घर विवरण
                    </h3>
                    <div className="row">
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>{houseCount[selectedLanguage]}</label>
                                <input
                                    type="number"
                                    class="form-control"
                                    name="houseDetail.houseCount"
                                    onChange={formik.handleChange}
                                    value={houseDetail.houseCount}
                                />
                            </div>
                        </div>
                        {houseDetail.houseCount > 1 && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {otherHouseLocationQn[selectedLanguage]}
                                    </label>
                                    <CustomSelectMulti
                                        formik={formik}
                                        value={
                                            houseDetail[`otherHouseLocation`]
                                        }
                                        isMulti={true}
                                        list={otherHouseLocation}
                                        field={`houseDetail.otherHouseLocation`}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>{totalRoom[selectedLanguage]}</label>
                                <input
                                    type="number"
                                    class="form-control"
                                    name="houseDetail.totalRoom"
                                    onChange={formik.handleChange}
                                    value={houseDetail.totalRoom}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>{totalStorey[selectedLanguage]}</label>
                                <input
                                    type="number"
                                    class="form-control"
                                    name="houseDetail.totalStorey"
                                    onChange={formik.handleChange}
                                    value={houseDetail.totalStorey}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    हाल बसीरहेको घरमा सटर कोठा छ ? (सटर )
                                </label>
                                <div class="radio-inline">
                                    <label class="radio radio-rounded">
                                        <input
                                            type="radio"
                                            name={`houseDetail.hasShutterInHouse`}
                                            value="yes"
                                            onChange={formik.handleChange}
                                            defaultChecked={
                                                houseDetail[
                                                    `hasShutterInHouse`
                                                ] == "yes"
                                            }
                                        />
                                        <span></span>छ
                                    </label>
                                    <label class="radio radio-rounded">
                                        <input
                                            type="radio"
                                            name={`houseDetail.hasShutterInHouse`}
                                            value="no"
                                            onChange={formik.handleChange}
                                            defaultChecked={
                                                houseDetail[
                                                    `hasShutterInHouse`
                                                ] == "no"
                                            }
                                        />
                                        <span></span>
                                        छैन
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {isHouseOnRentQuestion[selectedLanguage]}
                                </label>
                                <div class="radio-inline">
                                    {isHouseOnRent.map((value, i) => (
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`houseDetail.isHouseOnRent`}
                                                value={value.value}
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    houseDetail[
                                                        `isHouseOnRent`
                                                    ] == value.value
                                                }
                                            />
                                            <span></span>
                                            {value[selectedLanguage]}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {houseDetail.isHouseOnRent == "rent" && (
                            <>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            घर भाडामा दिएको भए कति कोठा भाडामा
                                            दिनु भएको छ ?
                                        </label>
                                        <input
                                            type="number"
                                            class="form-control"
                                            name="houseDetail.totalRoomRented"
                                            onChange={formik.handleChange}
                                            value={houseDetail.totalRoomRented}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {
                                                rentGivenToQuestion[
                                                    selectedLanguage
                                                ]
                                            }
                                        </label>
                                        <CustomSelectMulti
                                            value={houseDetail.rentType}
                                            isMulti={true}
                                            list={rentGivenTo}
                                            formik={formik}
                                            field={"houseDetail.rentType"}
                                        />
                                    </div>
                                </div>

                                {houseDetail.rentType == "other_family" && (
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>
                                                परिवारलाई भाडामा दिनु भएको भए
                                                कति जना बस्छन ?
                                            </label>
                                            <input
                                                type="number"
                                                class="form-control"
                                                name="houseDetail.numberOfFamilyInRent"
                                                onChange={formik.handleChange}
                                                value={
                                                    houseDetail.numberOfFamilyInRent
                                                }
                                            />
                                        </div>
                                    </div>
                                )}

                                {houseDetail.rentType == "other" && (
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>
                                                अन्य भए उल्लेख गर्नु हाेस ।
                                            </label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                name="houseDetail.rentTypeOther"
                                                onChange={formik.handleChange}
                                                value={
                                                    houseDetail.rentTypeOther
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {houseUsedLiving[selectedLanguage]}
                                </label>
                                <SingleSelectInput
                                    id="houseType"
                                    name={`houseDetail.houseUsedLiving`}
                                    value={houseDetail[`houseUsedLiving`]}
                                    formik={formik}
                                    selectList={houseUsedLivingOption}
                                />
                            </div>
                        </div>
                        {houseDetail.houseUsedLiving == "others" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {ifOtherValueQuestion[selectedLanguage]}
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="houseDetail.houseUsedLivingOther"
                                        onChange={formik.handleChange}
                                        value={houseDetail.houseUsedLivingOther}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    बसोवास गर्ने घरको बनावट कस्तो प्रकारको छ ?
                                </label>
                                <SingleSelectInput
                                    id="roofType"
                                    name={`houseDetail.roofType`}
                                    value={houseDetail[`roofType`]}
                                    formik={formik}
                                    selectList={roofType}
                                />
                            </div>
                        </div>
                        {houseDetail.houseType == "other_roof" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {ifOtherValueQuestion[selectedLanguage]}
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="houseDetail.houseTypeOther"
                                        onChange={formik.handleChange}
                                        value={houseDetail.houseTypeOther}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {hasLandDocumentQuestion[selectedLanguage]}
                                </label>
                                <div class="radio-inline">
                                    {hasLandDocument.map((value, i) => (
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`houseDetail.houseConstructionCertificate`}
                                                value={value.value}
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    houseDetail[
                                                        `houseConstructionCertificate`
                                                    ] == value.value
                                                }
                                            />
                                            <span></span>
                                            {value[selectedLanguage]}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {
                                        doHouseCriteriaFullfillQuestion[
                                            selectedLanguage
                                        ]
                                    }
                                </label>
                                <div class="radio-inline">
                                    {doHouseCriteriaFullfill.map((value, i) => (
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`houseDetail.doHouseCriteriaFullfill`}
                                                value={value.value}
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    houseDetail[
                                                        `doHouseCriteriaFullfill`
                                                    ] == value.value
                                                }
                                            />
                                            <span></span>
                                            {value[selectedLanguage]}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {riskAreaAroundHouseQn[selectedLanguage]}
                                </label>
                                <CustomSelectMulti
                                    value={houseDetail["riskAreaAroundHouse"]}
                                    isMulti={true}
                                    list={riskAreaAroundHouse}
                                    formik={formik}
                                    field={"houseDetail.riskAreaAroundHouse"}
                                />
                            </div>
                        </div>
                        {houseDetail["riskAreaAroundHouse"] == "other_risk" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>अन्य भए उल्लेख गर्नु हाेस ।</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="houseDetail.riskAreaAroundHouseOther"
                                        onChange={formik.handleChange}
                                        value={
                                            houseDetail.riskAreaAroundHouseOther
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    बितेको एक वर्षभित्र कुनै प्रकोपबाट तपाइँको
                                    परिवार पिडित भयो ?
                                </label>
                                <div class="radio-inline">
                                    <label class="radio radio-rounded">
                                        <input
                                            type="radio"
                                            name={`houseDetail.hasEffect`}
                                            value="plan_yes"
                                            onChange={formik.handleChange}
                                            defaultChecked={
                                                houseDetail[`hasEffect`] ==
                                                "plan_yes"
                                            }
                                        />
                                        <span></span>
                                        पिडित भएको छ
                                    </label>
                                    <label class="radio radio-rounded">
                                        <input
                                            type="radio"
                                            name={`houseDetail.hasEffect`}
                                            value="plan_no"
                                            onChange={formik.handleChange}
                                            defaultChecked={
                                                houseDetail[`hasEffect`] ==
                                                "plan_no"
                                            }
                                        />
                                        <span></span>
                                        पिडित भएको छैन
                                    </label>
                                </div>
                            </div>
                        </div>
                        {houseDetail[`hasEffect`] == "plan_yes" && (
                            <>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            छ भने कुन प्रकोपबाट पिडित हुनुहुन्छ
                                            ?
                                        </label>
                                        <CustomSelectMulti
                                            value={houseDetail["disasterList"]}
                                            isMulti={true}
                                            list={disasterEffectList}
                                            formik={formik}
                                            field={"houseDetail.disasterList"}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            प्रकोपबाट कति आर्थीक क्षति भएको थियो
                                            ? (रु. )
                                        </label>
                                        <input
                                            type="number"
                                            class="form-control"
                                            name="houseDetail.disasterCost"
                                            onChange={formik.handleChange}
                                            value={houseDetail["disasterCost"]}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        {houseDetail["disasterList"] == "others" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>अन्य भए उल्लेख गर्नु हाेस ।</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="houseDetail.disasterListOther"
                                        onChange={formik.handleChange}
                                        value={houseDetail["disasterListOther"]}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {hasHouseMapPassQuestion[selectedLanguage]}
                                </label>
                                <div class="radio-inline">
                                    {hasHouseMapPass.map((value, i) => (
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`houseDetail.hasHouseMapPass`}
                                                value={value.value}
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    houseDetail[
                                                        `hasHouseMapPass`
                                                    ] == value.value
                                                }
                                            />
                                            <span></span>
                                            {value[selectedLanguage]}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>{houseAge[selectedLanguage]}</label>
                                <input
                                    type="number"
                                    class="form-control"
                                    name="houseDetail.houseAge"
                                    onChange={formik.handleChange}
                                    value={houseDetail.houseAge}
                                />
                            </div>
                        </div>

                        {/* <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {familyRiskPlanQn[selectedLanguage]}
                                </label>
                                <SingleSelectInput
                                    t
                                    id="isFamilyRiskPlan"
                                    name={`houseDetail.isFamilyRiskPlan`}
                                    value={houseDetail[`isFamilyRiskPlan`]}
                                    formik={formik}
                                    selectList={familyRiskPlan}
                                />
                            </div>
                        </div>
                        {houseDetail.familyRiskPlan == "plan_yes" && (
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>
                                        {familyRiskPlanYesQn[selectedLanguage]}
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="houseDetail.familyRiskPlan"
                                        onChange={formik.handleChange}
                                        value={houseDetail.familyRiskPlan}
                                    />
                                </div>
                            </div>
                        )} */}
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    आफ्नो घरपरिवार सदस्यको नाममा घडेरी तथा जग्गा
                                    जमिन छ ?
                                </label>
                                <SingleSelectInput
                                    id="ownLandOrFamilyLand"
                                    name={`houseDetail.ownLandOrFamilyLand`}
                                    value={houseDetail[`ownLandOrFamilyLand`]}
                                    formik={formik}
                                    selectList={ownLandOrFamilyLand}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {womanOwnedHomeQn[selectedLanguage]}
                                </label>
                                <SingleSelectInput
                                    id="landInWomansName"
                                    name={`houseDetail.landInWomansName`}
                                    value={houseDetail[`landInWomansName`]}
                                    formik={formik}
                                    selectList={womanOwnedHome}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrathamikGharForm;
