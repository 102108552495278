import React from "react";
import { Card, Table } from "react-bootstrap";
import { educationList } from "../../data/advanceSearch";
import { education, occupation } from "../../data/houseSurvey";
import { selectedLanguage } from "../../data/institute";

const EducationDetails = ({ member }) => {
    const getClassNumber = (level) => {
        switch (level.educationLevel) {
            case "one_to_eight":
                return <>{level.oneToEightClass}</>;

            case "nine_to_twelve":
                return <>{level.nineToTwelve}</>;

            default:
                break;
        }
    };
    return (
        <Card className="mb-5">
            <Card.Body>
                <Card.Title>शैक्षिक र पेशा विवरण</Card.Title>
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>पुरा नाम</th>
                            <th>शिक्षा</th>
                            <th>तहको कक्षा</th>
                            <th>पेशा</th>
                        </tr>
                    </thead>
                    <tbody>
                        {member.map((familyMember, i) => (
                            <>
                                <tr>
                                    <td>{familyMember.detail.fullName}</td>
                                    <td>
                                        {educationList.find(
                                            (i) =>
                                                i.value ===
                                                familyMember.education
                                                    .educationLevel
                                        )
                                            ? educationList.find(
                                                  (i) =>
                                                      i.value ===
                                                      familyMember.education
                                                          .educationLevel
                                              )[selectedLanguage]
                                            : ""}
                                    </td>
                                    <td>
                                        {getClassNumber(familyMember.education)}
                                    </td>
                                    <td>
                                        {occupation.find(
                                            (i) =>
                                                i.value ===
                                                familyMember.occupation
                                                    .levelOneJob
                                        )
                                            ? occupation.find(
                                                  (i) =>
                                                      i.value ===
                                                      familyMember.occupation
                                                          .levelOneJob
                                              )[selectedLanguage]
                                            : ""}
                                    </td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default EducationDetails;
