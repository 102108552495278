import { SearchOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import { Button, Input, Space, Table } from "antd";
import { toast } from "react-toastify";
import { getTableData } from "../modules/InstituteSurvey/_redux/surveyCrud";
import Loader from "./Loader";

const HistoricalReligiousSiteTable = (props) => {
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const { subCatRoute } = props;
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const searchInput = useRef(null);
    useEffect(() => {
        if (subCatRoute) {
            setLoading(true);
            getTableData(subCatRoute)
                .then((res) => {
                    setLoading(false);
                    console.log(res);
                    if (res.data.data) {
                        const { title, data } = res.data;
                        const pages = Math.ceil(data.length / 10);
                        setTotalPages(pages);
                        // const dispData = data.slice(0, 10);
                        setColumns([
                            {
                                title: "वडा नम्बर",
                                dataIndex: "ward",
                                key: "ward",
                                width: "30%",
                                ...getColumnSearchProps("ward"),
                            },
                            {
                                title: "सम्पदाको नाम",
                                dataIndex: "name",
                                key: "name",
                                width: "30%",
                                ...getColumnSearchProps("name"),
                            },
                            {
                                title: "सम्पदा स्थलको प्रकार",
                                dataIndex: "placeType",
                                filters: [
                                    {
                                        text: "मठमन्दिर",
                                        value: "मठमन्दिर",
                                    },
                                    {
                                        text: "मस्जिद",
                                        value: "मस्जिद",
                                    },
                                    {
                                        text: "गुम्बा",
                                        value: "गुम्बा",
                                    },
                                    {
                                        text: "धर्मशाला",
                                        value: "धर्मशाला",
                                    },
                                    {
                                        text: "संग्राहालय",
                                        value: "संग्राहालय",
                                    },
                                    {
                                        text: "दरवार",
                                        value: "दरवार",
                                    },
                                    {
                                        text: "साँस्कृतिक क्लब (नाचघर)",
                                        value: "साँस्कृतिक क्लब (नाचघर)",
                                    },
                                    {
                                        text: "चर्च",
                                        value: "चर्च",
                                    },
                                    {
                                        text: "साकेला थान",
                                        value: "साकेला थान",
                                    },
                                    {
                                        text: "अन्य",
                                        value: "अन्य",
                                    },
                                ],
                                filterMode: "tree",
                                filterSearch: true,
                                onFilter: (value, record) =>
                                    record.placeType.startsWith(value),
                                width: "30%",
                            },
                            {
                                title: "सदस्य सङ्ख्या",
                                dataIndex: "capacity",
                                key: "capacity",
                                width: "30%",
                                ...getColumnSearchProps("capacity"),
                            },
                            {
                                title: "कार्य",
                                dataIndex: "",
                                key: "d",
                                render: function(val) {
                                    return (
                                        <div className="d-flex table-actions">
                                            <i
                                                className="flaticon-eye mr-3 mb-3"
                                                onClick={(e) =>
                                                    props.viewData(val.id)
                                                }
                                            ></i>
                                            <i
                                                className="flaticon-edit mr-3 mb-3"
                                                onClick={(e) =>
                                                    props.editData(val.id)
                                                }
                                            ></i>
                                            <i
                                                className="flaticon-delete"
                                                onClick={(e) =>
                                                    props.deleteData(val.id)
                                                }
                                            ></i>
                                        </div>
                                    );
                                },
                            },
                        ]);
                        setData(data);
                        setDisplayData(data);
                    }
                })
                .catch((err) => {
                    toast.error("An error occured while fetching data");
                    setLoading(false);
                });
        }
    }, [subCatRoute]);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        खोज्नुहोस्
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        रिसेट गर्नुहोस्
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        फिल्टर गर्नुहोस्
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button> */}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text,
    });
    // const columns = [
    //     {
    //         title: "Name",
    //         dataIndex: "name",
    //         key: "name",
    //         width: "30%",
    //         ...getColumnSearchProps("name"),
    //     },
    //     {
    //         title: "Age",
    //         dataIndex: "age",
    //         key: "age",
    //         width: "20%",
    //         ...getColumnSearchProps("age"),
    //     },
    //     {
    //         title: "Address",
    //         dataIndex: "address",
    //         key: "address",
    //         ...getColumnSearchProps("address"),
    //         sorter: (a, b) => a.address.length - b.address.length,
    //         sortDirections: ["descend", "ascend"],
    //     },
    // ];
    return (
        <>
            {loading && <Loader loading={loading} />}
            {!loading && (
                <>
                    <Table
                        columns={columns}
                        dataSource={displayData}
                        pagination={true}
                    />
                </>
            )}
        </>
    );
};
export default HistoricalReligiousSiteTable;
