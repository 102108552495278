import React from "react";
import { Card, Table } from "react-bootstrap";
import {
    answererRelation,
    bloodgroup,
    casteList,
    gender,
    healthcondition,
    healthconditionQuesiton,
    marriage,
    mothertongue,
    religionList,
    socialSecurityAllowanceDetail,
    socialSecurityAllowanceList,
    livingStatus,
    mentalcondition,
    unhealthy,
    votercard,
} from "../../data/houseSurvey";
import { selectedLanguage } from "../../data/institute";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const getMonthNumber = (monthNep) => {
    if (monthNep == "Baisakh") {
        return "०१";
    } else if (monthNep == "Jestha") {
        return "०२";
    } else if (monthNep == "Asar") {
        return "०३";
    } else if (monthNep == "Shrawn") {
        return "०४";
    } else if (monthNep == "Bhadra") {
        return "०५";
    } else if (monthNep == "Aswin") {
        return "०६";
    } else if (monthNep == "Karthik") {
        return "०७";
    } else if (monthNep == "Mangsir") {
        return "०८";
    } else if (monthNep == "Poush") {
        return "०९";
    } else if (monthNep == "Magh") {
        return "१०";
    } else if (monthNep == "Falgun") {
        return "११";
    } else {
        return "१२";
    }
};

const PariwarSadasya = ({ member }) => {
    return (
        <Card className="mb-5">
            <Card.Body>
                <Card.Title>परिवार सदस्य व्यक्तिगत विवरण</Card.Title>
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>पुरा नाम</th>
                            <th>लिंग</th>
                            <th>जात</th>
                            <th>जन्ममिति (B.S.)</th>
                            <th>उमेर</th>
                            <th>बैबाहिक स्तिथि</th>
                            <th>धर्म</th>
                            <th>मातृभाषा</th>
                            <th>रगत समुह</th>
                            <th>घरमुली संगको नाता</th>
                            <th>मतदाता परिचय पत्र</th>
                            {/* <th>सम्पर्क नम्बर</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {member.map((familyMember, i) => (
                            <>
                                <tr
                                    data-toggle="collapse"
                                    data-target={`.multi-collapse${i}`}
                                    aria-controls={`collapsible${i}`}
                                >
                                    <td>
                                        <img
                                            className="mr-2"
                                            src={toAbsoluteUrl(
                                                "/media/svg/hamropalika-icons/caret.svg"
                                            )}
                                            alt=""
                                        />
                                        {familyMember.detail.fullName}
                                    </td>
                                    <td>
                                        {gender.find(
                                            (i) =>
                                                i.value ===
                                                familyMember.detail.gender
                                        )
                                            ? gender.find(
                                                  (i) =>
                                                      i.value ===
                                                      familyMember.detail.gender
                                              )[selectedLanguage]
                                            : ""}
                                    </td>
                                    <td>
                                        {casteList.find(
                                            (i) =>
                                                i.value ===
                                                familyMember.detail.caste
                                        )
                                            ? casteList.find(
                                                  (i) =>
                                                      i.value ===
                                                      familyMember.detail.caste
                                              )[selectedLanguage]
                                            : ""}
                                    </td>
                                    <td>
                                        {familyMember.detail.dateOfBirth &&
                                            familyMember.detail.dateOfBirth
                                                .year +
                                                "/" +
                                                getMonthNumber(
                                                    familyMember.detail
                                                        .dateOfBirth.month
                                                ) +
                                                "/" +
                                                familyMember.detail.dateOfBirth
                                                    .day}
                                    </td>
                                    <td>{familyMember.detail.age}</td>
                                    <td>
                                        {marriage.find(
                                            (i) =>
                                                i.value ===
                                                familyMember.detail
                                                    .martialStatus
                                        )
                                            ? marriage.find(
                                                  (i) =>
                                                      i.value ===
                                                      familyMember.detail
                                                          .martialStatus
                                              )[selectedLanguage]
                                            : ""}
                                    </td>
                                    <td>
                                        {religionList.find(
                                            (i) =>
                                                i.value ===
                                                familyMember.detail.religion
                                        )
                                            ? religionList.find(
                                                  (i) =>
                                                      i.value ===
                                                      familyMember.detail
                                                          .religion
                                              )[selectedLanguage]
                                            : ""}
                                    </td>
                                    <td>
                                        {mothertongue.find(
                                            (i) =>
                                                i.value ===
                                                familyMember.detail.motherTongue
                                        )
                                            ? mothertongue.find(
                                                  (i) =>
                                                      i.value ===
                                                      familyMember.detail
                                                          .motherTongue
                                              )[selectedLanguage]
                                            : ""}
                                    </td>
                                    <td>
                                        {familyMember.detail.bloodGroup
                                            .knowBloodGroup &&
                                        bloodgroup.find(
                                            (i) =>
                                                i.value ===
                                                familyMember.detail.bloodGroup
                                                    ?.bloodType
                                        )
                                            ? bloodgroup.find(
                                                  (i) =>
                                                      i.value ===
                                                      familyMember.detail
                                                          .bloodGroup?.bloodType
                                              )[selectedLanguage]
                                            : ""}
                                    </td>
                                    <td>
                                        {answererRelation.find(
                                            (i) =>
                                                i.value ===
                                                familyMember.detail
                                                    .answererRelation
                                        )
                                            ? answererRelation.find(
                                                  (i) =>
                                                      i.value ===
                                                      familyMember.detail
                                                          .answererRelation
                                              )[selectedLanguage]
                                            : ""}
                                    </td>
                                    <td>
                                        {familyMember.detail.voterId &&
                                        familyMember.detail.voterId == true
                                            ? "छ"
                                            : "छैन"}
                                    </td>
                                    {/* <td>सम्पर्क नम्बर</td> */}
                                </tr>
                                <tr
                                    class={`collapse multi-collapse${i}`}
                                    id={`collapsible${i}`}
                                >
                                    <td
                                        colSpan={4}
                                        style={{ textAlign: "left" }}
                                    >
                                        {familyMember.detail.insurance && (
                                            <h6>
                                                बिमा गर्नु भएको छ कि छैन ? :{" "}
                                                {familyMember.detail.insurance
                                                    .hasInsurance == true
                                                    ? "छ"
                                                    : "छैन"}
                                            </h6>
                                        )}
                                        {familyMember.detail.email && (
                                            <h6>
                                                email :{" "}
                                                {familyMember.detail.email}
                                            </h6>
                                        )}
                                        {familyMember.detail.contactNumber && (
                                            <h6>
                                                फोन नम्बर :{" "}
                                                {
                                                    familyMember.detail
                                                        .contactNumber
                                                }
                                            </h6>
                                        )}
                                        {familyMember.detail.unhealthyDisease
                                            .length === 0 && (
                                            <h6>
                                                {
                                                    healthconditionQuesiton[
                                                        selectedLanguage
                                                    ]
                                                }{" "}
                                                :{" "}
                                                {healthcondition.find(
                                                    (i) =>
                                                        i.value ===
                                                        familyMember.detail
                                                            .healthCondition
                                                )
                                                    ? healthcondition.find(
                                                          (i) =>
                                                              i.value ===
                                                              familyMember
                                                                  .detail
                                                                  .healthCondition
                                                      )[selectedLanguage]
                                                    : ""}
                                            </h6>
                                        )}
                                        {familyMember.detail.unhealthyDisease
                                            .length !== 0 &&
                                            familyMember.detail.unhealthyDisease.map(
                                                (disease) => (
                                                    <>
                                                        {unhealthy.find(
                                                            (i) =>
                                                                i.value ===
                                                                disease
                                                        )
                                                            ? unhealthy.find(
                                                                  (i) =>
                                                                      i.value ===
                                                                      disease
                                                              )[
                                                                  selectedLanguage
                                                              ]
                                                            : ""}
                                                    </>
                                                )
                                            )}
                                        <h6>
                                            {familyMember.detail
                                                .hasSocialSecurityFund && (
                                                <h6>
                                                    {
                                                        socialSecurityAllowanceDetail[
                                                            selectedLanguage
                                                        ]
                                                    }{" "}
                                                    :{" "}
                                                    {familyMember.detail
                                                        .hasSocialSecurityFund ==
                                                    true
                                                        ? "छ"
                                                        : "छैन"}
                                                </h6>
                                            )}
                                        </h6>
                                    </td>
                                    <td
                                        colSpan={5}
                                        style={{ textAlign: "left" }}
                                    >
                                        {familyMember.detail.hasCitizenship && (
                                            <h6>
                                                नागरिकता छ वा छैन ? :{" "}
                                                {familyMember.detail
                                                    .hasCitizenship == true
                                                    ? "छ"
                                                    : "छैन"}
                                            </h6>
                                        )}
                                        {familyMember.detail.nationalIdCard && (
                                            <h6>
                                                राष्ट्रिय परिचय पत्र छ कि छैन ?
                                                :{" "}
                                                {familyMember.detail
                                                    .nationalIdCard == true
                                                    ? "छ"
                                                    : "छैन"}
                                            </h6>
                                        )}
                                        {familyMember.detail.disable && (
                                            <h6>
                                                अपांगता छ कि छैन ? :{" "}
                                                {familyMember.detail.disable &&
                                                familyMember.detail.disable
                                                    .isDisable == true
                                                    ? "छ"
                                                    : "छैन"}
                                            </h6>
                                        )}
                                        {familyMember.detail.disable &&
                                            familyMember.detail.disable
                                                .isDisable == true && (
                                                <h6>
                                                    अपांगताको किसिम :{" "}
                                                    {mentalcondition.find(
                                                        (i) =>
                                                            i.value ===
                                                            familyMember.detail
                                                                .disable
                                                                .condition
                                                    )
                                                        ? mentalcondition.find(
                                                              (i) =>
                                                                  i.value ===
                                                                  familyMember
                                                                      .detail
                                                                      .disable
                                                                      .condition
                                                          )[selectedLanguage]
                                                        : ""}
                                                </h6>
                                            )}

                                        {familyMember.detail.livingStatus && (
                                            <h6>
                                                बसोबास स्थिती :{" "}
                                                {livingStatus.find(
                                                    (i) =>
                                                        i.value ===
                                                        familyMember.detail
                                                            .livingStatus
                                                )
                                                    ? livingStatus.find(
                                                          (i) =>
                                                              i.value ===
                                                              familyMember
                                                                  .detail
                                                                  .livingStatus
                                                      )[selectedLanguage]
                                                    : ""}
                                            </h6>
                                        )}
                                    </td>
                                    <td
                                        colSpan={3}
                                        style={{ textAlign: "left" }}
                                    >
                                        {familyMember.detail
                                            .isBirthCertificate && (
                                            <h6>
                                                जन्म दर्ता छ कि छैन ? :{" "}
                                                {familyMember.detail
                                                    .isBirthCertificate == true
                                                    ? "छ"
                                                    : "छैन"}
                                            </h6>
                                        )}
                                        <h6>
                                            प्यान नम्बर छ कि छैन ? :{" "}
                                            {familyMember.detail.hasPanNumber ==
                                            true
                                                ? "छ"
                                                : "छैन"}
                                        </h6>
                                    </td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    );
};

export default PariwarSadasya;
