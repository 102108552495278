import React, { Component } from "react";
import DetailTable from "../components/DetailTable";
import SchoolTable from "../components/SchoolTable";
import HealthTable from "../components/HealthTable";
import BankTable from "../components/BankTable";
import SecurityBodiesTable from "../components/SecurityBodiesTable";
import CommunityOrganizationTable from "../components/CommunityOrganizationTable";
import IndustryTable from "../components/IndustryTable";
import BridgeTable from "../components/BridgeTable";
import CommunityBuildingTable from "../components/CommunityBuildingTable";
import ElectricityTable from "../components/ElectricityTable";
import BusinessTable from "../components/BusinessTable";
import HistoricalReligiousSiteTable from "../components/HistoricalReligiousSiteTable";

const SurveyList = (props) => {
    return (
        <>
            {(() => {
                if (props.subCatRoute == "school") {
                    return (
                        <SchoolTable
                            subCatRoute={props.subCatRoute}
                            editData={props.editData}
                            deleteData={props.deleteData}
                            viewData={props.viewData}
                        />
                    );
                } else if (props.subCatRoute == "Health") {
                    return (
                        <HealthTable
                            subCatRoute={props.subCatRoute}
                            editData={props.editData}
                            deleteData={props.deleteData}
                            viewData={props.viewData}
                        />
                    );
                } else if (props.subCatRoute == "Business") {
                    return (
                        <BusinessTable
                            subCatRoute={props.subCatRoute}
                            editData={props.editData}
                            deleteData={props.deleteData}
                            viewData={props.viewData}
                        />
                    );
                } else if (props.subCatRoute == "Bank") {
                    return (
                        <BankTable
                            subCatRoute={props.subCatRoute}
                            editData={props.editData}
                            deleteData={props.deleteData}
                            viewData={props.viewData}
                        />
                    );
                } else if (props.subCatRoute == "SecurityBodies") {
                    return (
                        <SecurityBodiesTable
                            subCatRoute={props.subCatRoute}
                            editData={props.editData}
                            deleteData={props.deleteData}
                            viewData={props.viewData}
                        />
                    );
                } else if (props.subCatRoute == "CommunityOrganization") {
                    return (
                        <CommunityOrganizationTable
                            subCatRoute={props.subCatRoute}
                            editData={props.editData}
                            deleteData={props.deleteData}
                            viewData={props.viewData}
                        />
                    );
                } else if (props.subCatRoute == "Industry") {
                    return (
                        <IndustryTable
                            subCatRoute={props.subCatRoute}
                            editData={props.editData}
                            deleteData={props.deleteData}
                            viewData={props.viewData}
                        />
                    );
                } else if (props.subCatRoute == "Bridge") {
                    return (
                        <BridgeTable
                            subCatRoute={props.subCatRoute}
                            editData={props.editData}
                            deleteData={props.deleteData}
                            viewData={props.viewData}
                        />
                    );
                } else if (props.subCatRoute == "CommunityBuilding") {
                    return (
                        <CommunityBuildingTable
                            subCatRoute={props.subCatRoute}
                            editData={props.editData}
                            deleteData={props.deleteData}
                            viewData={props.viewData}
                        />
                    );
                } else if (props.subCatRoute == "Electricity") {
                    return (
                        <ElectricityTable
                            subCatRoute={props.subCatRoute}
                            editData={props.editData}
                            deleteData={props.deleteData}
                            viewData={props.viewData}
                        />
                    );
                } else if (props.subCatRoute == "HistoricalReligiousSite") {
                    return (
                        <HistoricalReligiousSiteTable
                            subCatRoute={props.subCatRoute}
                            editData={props.editData}
                            deleteData={props.deleteData}
                            viewData={props.viewData}
                        />
                    );
                } else {
                    return (
                        <DetailTable
                            subCatRoute={props.subCatRoute}
                            editData={props.editData}
                            deleteData={props.deleteData}
                            viewData={props.viewData}
                        />
                    );
                }
            })()}
        </>
    );
};

// export default SurveyList;
// class SurveyList extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {

//         }
//     }

//     render() {
//         return (

//         );
//     }
// }

export default SurveyList;
