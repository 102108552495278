import React from "react";
import { Form } from "react-bootstrap";
import { CheckBox } from "react-bootstrap";
import {
    adhiyaThekkaList,
    adhiyaThekkaYesQn,
    agriCropKeys,
    fishTitle,
    agriCropType,
    totalPond,
    agriDaalType,
    beeTitle,
    totalGhaar,
    beeProduction,
    irrigationForLandques,
    reshamGhaar,
    agriFruitType,
    fishProduction,
    agriOilType,
    self_and_given_other,
    aanabaliLandUsed,
    agriProduction,
    foodForMonth,
    foodForMonthQuestion,
    agriProductionType,
    agriProductionTypeQuestion,
    agriVegetableType,
    agriYesNo,
    beefishsilkType,
    beefishsilkYes,
    beefishsilkYesNo,
    beefishType,
    allLivestock,
    beefishYesNo,
    bigha,
    birdDiffType,
    cashCropType,
    dhur,
    elaaniQn,
    forestRegistered,
    forestUseList,
    forestUseYesNo,
    forestUseYesNoQn,
    forestUseYesQn,
    hasHouseMapPass,
    hasHouseMapPassQuestion,
    agricultureProductionOrNot,
    ifOtherValueQuestion,
    insecticideQuestion,
    insecticideType,
    insecticideYes,
    insecticideYesNo,
    irrigationForLand,
    irrigationForLandList,
    kattha,
    landforAgri,
    landforAgriYesNo,
    landUsageList,
    landUsageQn,
    livestockDiffType,
    livestockQuestion,
    livestockType,
    livestockYes,
    livestockYesNo,
    nambariQn,
    otherLand,
    otherLandArea,
    otherLandAreaBighaKattha,
    ownLand,
    privateForestQn,
    seedNeed,
    pondArea,
    seedNeedQuestion,
    totalBeefidhsilkIncome,
    totalFamilyLand,
    totalFamilyLandQn,
    reshamTitle,
    reshamProduction,
    livestockTitle,
    birdTitle,
    anyAnimal,
    cashCropTitle,
    anyAnimalInfoTitle,
    animalNumber,
    animalProduction,
    animalMeatIncome,
} from "../data/houseSurvey";
import { selectedLanguage } from "../data/institute";
import Select from "react-select";
import CustomSelectMulti from "../components/CustomSelectMulti";
import {
    BeeSection,
    BuffalloSection,
    CalfSection,
    ChickenSection,
    ChyangraSection,
    CowSection,
    DuckSection,
    FishSection,
    GadhaSection,
    GaruSection,
    GoatSection,
    HorseSection,
    HybridChickenSection,
    PigSection,
    RangaSection,
    SheepSection,
    SilkSection,
    YakSection,
} from "../components/LivestockDetail";
import SingleSelectInput from "../components/SingleSelectInput";
import {
    agriProductionQuestion,
    bimaQuestion,
    ifBimaYes,
    ifBimaYesNo,
    landSwamita,
    landType,
    ownLandEarn,
    ownLandType,
    landTypes,
} from "../data/AarthikSurvey";
import { otherAnimal } from "../data/krishiSurvey";

const KrishiTathaPashu = (props) => {
    const { formik } = props;
    const { agriLivestock } = formik.values;
    const getLandArea = (section) => (
        <>
            {otherLandAreaBighaKattha.map((units, index) => {
                return (
                    <div className="col-lg-6" key={`${section}${index}`}>
                        <div class="form-group">
                            <label>{units[selectedLanguage]}</label>
                            <input
                                type="number"
                                class="form-control"
                                name={`agriLivestock.${section}${units.english}`}
                                onChange={formik.handleChange}
                                value={
                                    agriLivestock[`${section}${units.english}`]
                                }
                            />
                        </div>
                    </div>
                );
            })}
            <div className="col-lg-6 d-none d-lg-block"></div>
        </>
    );
    const getSelectFor = (section, list, question) => {
        return (
            <div className="col-lg-6">
                <div class="form-group">
                    <label>{question[selectedLanguage]}</label>
                    <CustomSelectMulti
                        formik={formik}
                        value={agriLivestock[`${section}`]}
                        isMulti={true}
                        list={list}
                        field={`agriLivestock.${section}`}
                    />
                </div>
            </div>
        );
    };
    const getIncomeExpense = (nepaliQuestion, key) => {
        return (
            <>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{`${nepaliQuestion} उत्पादन वाट बार्षिक आम्दानी कति हो ?`}</label>
                        <input
                            type="number"
                            class="form-control"
                            name={`agriLivestock.${key}Income`}
                            onChange={formik.handleChange}
                            value={agriLivestock[`${key}Income`]}
                        />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>{`${nepaliQuestion} खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)`}</label>
                        <input
                            type="number"
                            class="form-control"
                            name={`agriLivestock.${key}Expense`}
                            onChange={formik.handleChange}
                            value={agriLivestock[`${key}Expense`]}
                        />
                    </div>
                </div>
            </>
        );
    };
    const getAgriProductionSection = (
        list,
        subListKey,
        landKey,
        nepaliSection
    ) => {
        const question = {
            nepali: `कुन कुन ${nepaliSection} उत्पादन तथा बिक्रि विवरण छन् ?`,
            english: "",
        };
        return (
            <>
                {list.length &&
                    getSelectFor(`${subListKey}Type`, list, question)}

                {agriLivestock[`${subListKey}Type`] == "other" && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>
                                {ifOtherValueQuestion[selectedLanguage]}
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                name={`agriLivestock.${subListKey}Other`}
                                onChange={formik.handleChange}
                                value={agriLivestock[`${subListKey}Other`]}
                            />
                        </div>
                    </div>
                )}

                {agriLivestock[`${subListKey}Type`]?.map((value) => {
                    const key = list.find((i) => i.value === value);
                    const apiKey = key.english.replace(/\s+/g, "");
                    return (
                        <>
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>{`${key[selectedLanguage]} खेति उत्पादन के. जि`}</label>
                                    <input
                                        type="number"
                                        class="form-control"
                                        name={`agriLivestock.agri${apiKey}ProductionKg`}
                                        onChange={formik.handleChange}
                                        value={
                                            agriLivestock[
                                                `agri${apiKey}ProductionKg`
                                            ]
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div class="form-group">
                                    <label>{`${key[selectedLanguage]} खेतीमा हुने बार्षिक लागत खर्च कति हुन्छ? (रु.)`}</label>
                                    <input
                                        type="number"
                                        class="form-control"
                                        name={`agriLivestock.agri${apiKey}SaleKg`}
                                        onChange={formik.handleChange}
                                        value={
                                            agriLivestock[`agri${apiKey}SaleKg`]
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    );
                })}
                {getIncomeExpense(nepaliSection, subListKey)}
                {getLandArea(landKey)}
            </>
        );
    };
    const getAgriProductionDetails = () => {
        return (
            <>
                {agriLivestock.agriProductionType?.includes("crops") &&
                    getAgriProductionSection(
                        agriCropType,
                        "agriCrop",
                        "crops",
                        agriProductionType.find((i) => i.value == "crops")
                            ?.nepali
                    )}
                {agriLivestock.agriProductionType?.includes("oilseed") &&
                    getAgriProductionSection(
                        agriOilType,
                        "agriOil",
                        "oil",
                        agriProductionType.find((i) => i.value == "oilseed")
                            ?.nepali
                    )}
                {agriLivestock.agriProductionType?.includes("daal") &&
                    getAgriProductionSection(
                        agriDaalType,
                        "agriDaa",
                        "daal",
                        agriProductionType.find((i) => i.value == "daal")
                            ?.nepali
                    )}
                {agriLivestock.agriProductionType?.includes("vegetable") &&
                    getAgriProductionSection(
                        agriVegetableType,
                        "agriVegetable",
                        "vegetable",
                        agriProductionType.find((i) => i.value == "vegetable")
                            ?.nepali
                    )}
                {agriLivestock.agriProductionType?.includes("fruit") &&
                    getAgriProductionSection(
                        agriFruitType,
                        "agriFruit",
                        "fruit",
                        agriProductionType.find((i) => i.value == "crops")
                            ?.nepali
                    )}
                {agriLivestock.agriProductionType?.includes("cash_crops") &&
                    getAgriProductionSection(
                        cashCropType,
                        "agriCashCrop",
                        "cashCrop",
                        agriProductionType.find((i) => i.value == "cash_crops")
                            ?.nepali
                    )}
                {agriLivestock.agriProductionType?.includes("flower") &&
                    getAgriProductionSection(
                        [],
                        "agriFlower",
                        "flower",
                        agriProductionType.find((i) => i.value == "flower")
                            ?.nepali
                    )}
            </>
        );
    };
    const getAnimalSection = () => {
        const question = {
            nepali: `कुन कुन पशु पाल्नु भएको छ ?`,
            english: "",
        };
        const section = "livestockDiffType";
        return (
            <>
                {getSelectFor(section, livestockDiffType, question)}
                {agriLivestock.livestockDiffType?.includes("other") && (
                    <>
                        <div className="col-lg-6">
                            <div class="form-group">
                                {/* <label>{anyAnimal[selectedLanguage]}</label>
                                <SingleSelectInput
                                    id="otherAnimal"
                                    name="agriLivestock.unhealthyTreatment"
                                    value={agriLivestock.unhealthyTreatment}
                                    formik={formik}
                                    selectList={otherAnimal}
                                /> */}
                                <label>
                                    {ifOtherValueQuestion[selectedLanguage]}
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    name={`agriLivestock.livestockOther`}
                                    onChange={formik.handleChange}
                                    value={agriLivestock[`livestockOther`]}
                                />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div class="form-group">
                                <div className="">
                                    <label>
                                        {anyAnimalInfoTitle[selectedLanguage]}
                                    </label>
                                </div>
                                <label>{animalNumber[selectedLanguage]}</label>
                                <input
                                    type="number"
                                    class="form-control"
                                    name={`agriLivestock.animalNumber`}
                                    onChange={formik.handleChange}
                                    value={agriLivestock.animalNumber}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {animalProduction[selectedLanguage]}
                                </label>
                                <input
                                    type="number"
                                    class="form-control"
                                    name={`agriLivestock.animalProduction`}
                                    onChange={formik.handleChange}
                                    value={agriLivestock.animalProduction}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {animalMeatIncome[selectedLanguage]}
                                </label>
                                <input
                                    type="number"
                                    class="form-control"
                                    name={`agriLivestock.animalMeatIncome`}
                                    onChange={formik.handleChange}
                                    value={agriLivestock.animalMeatIncome}
                                />
                            </div>
                        </div>
                    </>
                )}
                {agriLivestock.livestockDiffType?.includes("cow") && (
                    <CowSection formik={formik} agriLivestock={agriLivestock} />
                )}
                {agriLivestock.livestockDiffType?.includes("buffello") && (
                    <BuffalloSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.livestockDiffType?.includes("khasi") && (
                    <GoatSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.livestockDiffType?.includes("paada_rangaa") && (
                    <RangaSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.livestockDiffType?.includes("pig") && (
                    <PigSection formik={formik} agriLivestock={agriLivestock} />
                )}
                {agriLivestock.livestockDiffType?.includes("sheep") && (
                    <SheepSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.livestockDiffType?.includes("goru") && (
                    <GaruSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.livestockDiffType?.includes("gadha") && (
                    <GadhaSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.livestockDiffType?.includes("chauri_gaai") && (
                    <YakSection formik={formik} agriLivestock={agriLivestock} />
                )}
                {agriLivestock.livestockDiffType?.includes(
                    "baachhabaachhi"
                ) && (
                    <CalfSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.livestockDiffType?.includes("horse") && (
                    <HorseSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.livestockDiffType?.includes("chhyangra") && (
                    <ChyangraSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
            </>
        );
    };
    const getAnimalBirdSection = () => {
        const question = {
            nepali: `कुन कुन पशु पाल्नु भएको छ ?`,
            english: "",
        };
        const section = "allLivestock";
        return (
            <>
                {getSelectFor(section, allLivestock, question)}
                {agriLivestock.allLivestock?.includes("other") && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>
                                {ifOtherValueQuestion[selectedLanguage]}
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                name={`agriLivestock.livestockOther`}
                                onChange={formik.handleChange}
                                value={agriLivestock[`livestockOther`]}
                            />
                        </div>
                    </div>
                )}
                {agriLivestock.allLivestock?.includes("chicken") && (
                    <ChickenSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.allLivestock?.includes("pegion") && (
                    <HybridChickenSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.allLivestock?.includes("duck") && (
                    <DuckSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.allLivestock?.includes("cow") && (
                    <CowSection formik={formik} agriLivestock={agriLivestock} />
                )}
                {agriLivestock.allLivestock?.includes("buffello") && (
                    <BuffalloSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.allLivestock?.includes("khasi") && (
                    <GoatSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.allLivestock?.includes("paada_rangaa") && (
                    <RangaSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.allLivestock?.includes("pig") && (
                    <PigSection formik={formik} agriLivestock={agriLivestock} />
                )}
                {agriLivestock.allLivestock?.includes("sheep") && (
                    <SheepSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.allLivestock?.includes("goru") && (
                    <GaruSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.allLivestock?.includes("gadha") && (
                    <GadhaSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.allLivestock?.includes("chauri_gaai") && (
                    <YakSection formik={formik} agriLivestock={agriLivestock} />
                )}
                {agriLivestock.allLivestock?.includes("baachhabaachhi") && (
                    <CalfSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.allLivestock?.includes("horse") && (
                    <HorseSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.allLivestock?.includes("chhyangra") && (
                    <ChyangraSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
            </>
        );
    };
    const getBirdSection = () => {
        const question = {
            nepali: `कुन कुन पन्छी पाल्नु भएको छ ?`,
            english: "",
        };
        const section = "birdDiffType";
        return (
            <>
                {getSelectFor(section, birdDiffType, question)}
                {agriLivestock.birdDiffType?.includes("other") && (
                    <div className="col-lg-6">
                        <div class="form-group">
                            <label>
                                {ifOtherValueQuestion[selectedLanguage]}
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                name={`agriLivestock.birdOther`}
                                onChange={formik.handleChange}
                                value={agriLivestock[`birdOther`]}
                            />
                        </div>
                    </div>
                )}
                {agriLivestock.birdDiffType?.includes("chicken") && (
                    <ChickenSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.birdDiffType?.includes("pegion") && (
                    <HybridChickenSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.birdDiffType?.includes("duck") && (
                    <DuckSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
            </>
        );
    };
    const getLiveStockSection = () => {
        return (
            <>
                {agriLivestock.animalBirdInHouse?.includes("animal") &&
                    getAnimalSection()}
                {agriLivestock.animalBirdInHouse?.includes("bird") &&
                    getBirdSection()}
                {agriLivestock.animalBirdInHouse?.includes("both") &&
                    getBothLivestockSection()}
            </>
        );
    };

    const getBothLivestockSection = () => {
        return (
            <>
                {getAnimalSection()}
                {getBirdSection()}
            </>
        );
    };
    const getBeeFishSilkSection = () => {
        return (
            <>
                {agriLivestock.beefishsilkType?.includes("fish") && (
                    <FishSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
                {agriLivestock.beefishsilkType?.includes("bee") && (
                    <BeeSection formik={formik} agriLivestock={agriLivestock} />
                )}
                {agriLivestock.beefishsilkType?.includes("resham") && (
                    <SilkSection
                        formik={formik}
                        agriLivestock={agriLivestock}
                    />
                )}
            </>
        );
    };
    return (
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
            <div class="col-xl-12 col-xxl-8">
                <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                >
                    <h3 class="mb-10 font-weight-bold text-dark">
                        कृषी तथा पशु
                    </h3>
                    <div className="row align-items-end">
                        {/* <div className="col-12">
							<div class="form-group">
								<label>{totalFamilyLandQn[selectedLanguage]}</label>
								<div class="radio-inline">
									{totalFamilyLand.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="checkbox"
												name={`agriLivestock.totalLandArea`}
												value={value.value}
												onChange={formik.handleChange}
												checked={agriLivestock.totalLandArea?.includes(
													value.value
												)}
											/>
											<span></span> {value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div> */}
                        {/* {agriLivestock.totalLandArea?.includes("elaani") && (
							<>
								<div className="col-12">
									<h3 class="mb-10 font-weight-bold text-dark">
										{elaaniQn[selectedLanguage]}
									</h3>
								</div>

								{getLandArea("yelani")}
							</>
						)} */}
                        {/* {agriLivestock.totalLandArea?.includes("nambari") && (
							<>
								<div className="col-12">
									<h3 class="mb-10 font-weight-bold text-dark">
										{nambariQn[selectedLanguage]}
									</h3>
								</div>
								{getLandArea("nambari")}
							</>
						)} */}
                        {/* <div className="col-lg-6">
							<div class="form-group">
								<label>{landUsageQn[selectedLanguage]}</label>
								<SingleSelectInput
									id="landUsage"
									name={`agriLivestock.landUsage`}
									value={agriLivestock[`landUsage`]}
									formik={formik}
									selectList={landUsageList}
								/>
							</div>
						</div> */}
                        {/* {[
							"chapter",
							"contract",
							"self_chapter",
							"self_contract",
							"chapter_contract",
							"all",
						].includes(agriLivestock.landUsage) && (
								<div className="col-lg-6">
									<div class="form-group">
										<label>{adhiyaThekkaYesQn[selectedLanguage]}</label>
										<SingleSelectInput
											id="adhiyaThekka"
											name={`agriLivestock.adhiyaThekka`}
											value={agriLivestock[`adhiyaThekka`]}
											formik={formik}
											selectList={adhiyaThekkaList}
										/>
									</div>
								</div>
							)} */}
                        {/* <div className="col-lg-6">
							<div class="form-group">
								<label>{forestUseYesNoQn[selectedLanguage]}</label>
								<div class="radio-inline">
									{forestUseYesNo.map((value, i) => (
										<label class="radio radio-rounded">
											<input
												type="radio"
												name={`agriLivestock.forestUseYesNo`}
												value={value.value}
												onChange={formik.handleChange}
												defaultChecked={
													agriLivestock.forestUseYesNo === value.value
												}
											/>
											<span></span>
											{value[selectedLanguage]}
										</label>
									))}
								</div>
							</div>
						</div> */}
                        {/* {agriLivestock.forestUseYesNo == "forest_yes" && (
							<>
								<div className="col-lg-6">
									<div class="form-group">
										<label>{forestUseYesQn[selectedLanguage]}</label>
										<div class="radio-inline">
											{forestRegistered.map((value, i) => (
												<label class="radio radio-rounded">
													<input
														type="radio"
														name={`agriLivestock.forestRegistered`}
														defaultChecked={
															agriLivestock.forestRegistered == value.value
														}
														value={value.value}
														onChange={formik.handleChange}
														defaultChecked={
															agriLivestock.forestRegistered === value.value
														}
													/>
													<span></span>
													{value[selectedLanguage]}
												</label>
											))}
										</div>
									</div>
								</div>
								{agriLivestock.forestRegistered == "registered_yes" && (
									<>
										<div className="col-12">
											<h3 class="mb-10 font-weight-bold text-dark">
												{privateForestQn[selectedLanguage]}
											</h3>
										</div>

										{getLandArea("privateForest")}
									</>
								)}
							</>
						)} */}
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>{landforAgri[selectedLanguage]}</label>
                                <div class="radio-inline">
                                    {landforAgriYesNo.map((value, i) => (
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`agriLivestock.agricultureProductSales`}
                                                value={value.value}
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    agriLivestock.agricultureProductSales ===
                                                    value.value
                                                }
                                            />
                                            <span></span>
                                            {value[selectedLanguage]}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {agriLivestock.agricultureProductSales ==
                            "land_used_yes" && (
                            <>
                                <div className="col-lg-6">
                                    <label>
                                        यदि छ भने कृषि कार्यका लागि तपाईको
                                        परिवारको नाममा रहेको जग्गाको विवरण
                                        दिनुहोस (बिघा-कठ्ठा-धुर) (1-0-2)
                                    </label>
                                </div>
                                {getLandArea("agriOwnTotal")}

                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            कृषि कार्यका लागी परिवार बाहेक अरुको
                                            स्वामित्वमा जग्गा जग्गा प्रयोग गर्नु
                                            भएको छ ?
                                        </label>
                                        <div class="radio-inline">
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name={`agriLivestock.isOtherFamilyLand`}
                                                    value="yes"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    defaultChecked={
                                                        agriLivestock.isOtherFamilyLand ===
                                                        "yes"
                                                    }
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name={`agriLivestock.isOtherFamilyLand`}
                                                    value="no"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    defaultChecked={
                                                        agriLivestock.isOtherFamilyLand ===
                                                        "no"
                                                    }
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {agriLivestock.isOtherFamilyLand === "yes" && (
                                    <>
                                        <div className="col-lg-6">
                                            <div class="form-group">
                                                <label>
                                                    छ भने परिवार बाहेक अरुको
                                                    नाममा रहेको जग्गा
                                                    (बिघा-कठ्ठा-धुर) (1-0-2)
                                                </label>
                                            </div>
                                        </div>
                                        {getLandArea("agriOtherTotal")}
                                    </>
                                )}
                            </>
                        )}
                        {agriLivestock.isOtherFamilyLand == "yes" && (
                            <>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            तपाइले आफ्नो जग्गा कृषि प्रायोजनका
                                            लागि कसरी व्यवस्थापन गर्नु भएको छ ?
                                        </label>
                                        <SingleSelectInput
                                            id="aanabaliLandUsed"
                                            name={`agriLivestock.aanabaliLandUsed`}
                                            value={
                                                agriLivestock[
                                                    `aanabaliLandUsed`
                                                ]
                                            }
                                            formik={formik}
                                            selectList={aanabaliLandUsed}
                                        />
                                    </div>
                                </div>
                                {agriLivestock.aanabaliLandUsed?.includes(
                                    "given_other_to_use"
                                ) && (
                                    <>
                                        <div className="col-lg-6">
                                            <div class="form-group">
                                                <label>
                                                    जग्गाको स्वमित्त्व
                                                </label>
                                                <CustomSelectMulti
                                                    formik={formik}
                                                    value={
                                                        agriLivestock[
                                                            `landSwamita`
                                                        ]
                                                    }
                                                    isMulti={true}
                                                    list={landSwamita}
                                                    field={`agriLivestock.landSwamita`}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {agriLivestock.aanabaliLandUsed?.includes(
                                    "given_other_to_use"
                                ) && (
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>जग्गा जमिनको किसिम</label>
                                            <SingleSelectInput
                                                id="landTypes"
                                                name={`agriLivestock.landType`}
                                                value={
                                                    agriLivestock[`landType`]
                                                }
                                                formik={formik}
                                                selectList={landTypes}
                                            />
                                        </div>
                                    </div>
                                )}
                                {agriLivestock.aanabaliLandUsed?.includes(
                                    "given_other_to_use"
                                ) && <>{getLandArea("landAreaOther")}</>}

                                {agriLivestock.aanabaliLandUsed?.includes(
                                    "self_and_given_other"
                                ) && (
                                    <>
                                        <div className="col-lg-6">
                                            <div class="form-group">
                                                <label>
                                                    जग्गाको स्वमित्त्व
                                                </label>
                                                <CustomSelectMulti
                                                    formik={formik}
                                                    value={
                                                        agriLivestock[
                                                            `landSwamita`
                                                        ]
                                                    }
                                                    isMulti={true}
                                                    list={landSwamita}
                                                    field={`agriLivestock.landSwamita`}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}

                                {agriLivestock.aanabaliLandUsed?.includes(
                                    "self_and_given_other"
                                ) && (
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>जग्गा जमिनको किसिम</label>
                                            <SingleSelectInput
                                                id="ownLandType"
                                                name={`agriLivestock.landType`}
                                                value={
                                                    agriLivestock[`landType`]
                                                }
                                                formik={formik}
                                                selectList={ownLandType}
                                            />
                                        </div>
                                    </div>
                                )}
                                {agriLivestock.aanabaliLandUsed?.includes(
                                    "self_and_given_other"
                                ) && <>{getLandArea("agriTotal")}</>}
                            </>
                        )}
                        {/* <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {agriProduction[selectedLanguage]}
                                </label>
                                <div class="radio-inline">
                                    {agriYesNo.map((value, i) => (
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`agriLivestock.agriYesNo`}
                                                value={value.value}
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    agriLivestock.agriYesNo ===
                                                    value.value
                                                }
                                            />
                                            <span></span>
                                            {value[selectedLanguage]}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {agriLivestock.agriYesNo == "agri_sales_yes" &&
                            getSelectFor(
                                "agriProductionType",
                                agriProductionType,
                                agriProductionTypeQuestion
                            )}
                        {getAgriProductionDetails()} */}
                        {/* <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {insecticideQuestion[selectedLanguage]}
                                </label>
                                <div class="radio-inline">
                                    {insecticideYesNo.map((value, i) => (
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`agriLivestock.insecticideYesNo`}
                                                value={value.value}
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    agriLivestock.insecticideYesNo ===
                                                    value.value
                                                }
                                            />
                                            <span></span>
                                            {value[selectedLanguage]}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {agriLivestock.insecticideYesNo == "chemical_yes" && (
                            <>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {insecticideYes[selectedLanguage]}
                                        </label>
                                        <SingleSelectInput
                                            id="insecticideType"
                                            name={`agriLivestock.insecticideType`}
                                            value={
                                                agriLivestock[`insecticideType`]
                                            }
                                            formik={formik}
                                            selectList={insecticideType}
                                        />
                                    </div>
                                </div>
                            </>
                        )} */}

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    तपाइँको परिवारले कृषि बाली उत्पादन तथा उपभोग
                                    गर्नुहुन्छ कि हुन्न ?
                                </label>
                                <SingleSelectInput
                                    id="agricultureProductionOrNot"
                                    name={`agriLivestock.agricultureProductionOrNot`}
                                    value={
                                        agriLivestock[
                                            `agricultureProductionOrNot`
                                        ]
                                    }
                                    formik={formik}
                                    selectList={agricultureProductionOrNot}
                                />
                            </div>
                        </div>

                        {agriLivestock.agricultureProductionOrNot?.includes(
                            "only_production"
                        ) && (
                            <>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            छ भने बार्षिक बाली उत्पादन तथा
                                            बिक्रि के कति गर्नुहुन्छ ?
                                        </label>
                                        <CustomSelectMulti
                                            formik={formik}
                                            value={
                                                agriLivestock[
                                                    `agriProductionType`
                                                ]
                                            }
                                            isMulti={true}
                                            list={agriProductionType}
                                            field={`agriLivestock.agriProductionType`}
                                        />
                                    </div>
                                </div>
                                {getAgriProductionDetails()}
                            </>
                        )}
                        {agriLivestock.agricultureProductSales?.includes(
                            "sales"
                        ) && (
                            <>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            {
                                                agriProductionQuestion[
                                                    selectedLanguage
                                                ]
                                            }
                                        </label>
                                        <CustomSelectMulti
                                            formik={formik}
                                            value={
                                                agriLivestock[
                                                    `agriProductionType`
                                                ]
                                            }
                                            isMulti={true}
                                            list={agriProductionType}
                                            field={`agriLivestock.agriProductionType`}
                                        />
                                    </div>
                                </div>
                                {getAgriProductionDetails()}
                            </>
                        )}

                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {foodForMonthQuestion[selectedLanguage]}
                                </label>
                                <SingleSelectInput
                                    id="foodForMonth"
                                    name={`agriLivestock.foodForMonth`}
                                    value={agriLivestock[`foodForMonth`]}
                                    formik={formik}
                                    selectList={foodForMonth}
                                />
                            </div>
                        </div>
                        {agriLivestock.agricultureProductSales ===
                            "land_used_yes" && (
                            <>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <label>
                                            तपाइँले खेती गरिरहनु भएको जमिनमा
                                            सिँचाई सुविधा पुगेको छ ? (स्थायी
                                            ठेगानामा रहेको)
                                        </label>
                                        <div class="radio-inline">
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name={`agriLivestock.hasIrrigation`}
                                                    value="yes"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    defaultChecked={
                                                        agriLivestock.hasIrrigation ===
                                                        "yes"
                                                    }
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    name={`agriLivestock.hasIrrigation`}
                                                    value="no"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    defaultChecked={
                                                        agriLivestock.hasIrrigation ===
                                                        "no"
                                                    }
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {agriLivestock.hasIrrigation === "yes" && (
                                    <>
                                        <div className="col-lg-6">
                                            <div class="form-group">
                                                <label>
                                                    {
                                                        irrigationForLandques[
                                                            selectedLanguage
                                                        ]
                                                    }
                                                </label>

                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    name="agriLivestock.facilityThroughYear"
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    value={
                                                        agriLivestock.facilityThroughYear
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}

                                {agriLivestock.hasIrrigation === "yes" && (
                                    <>
                                        <div className="col-lg-6">
                                            <div class="form-group">
                                                <label>
                                                    सिंचाईको श्रोत के हो?
                                                </label>
                                                <CustomSelectMulti
                                                    formik={formik}
                                                    value={
                                                        agriLivestock[
                                                            `throughIrrigation`
                                                        ]
                                                    }
                                                    isMulti={true}
                                                    list={irrigationForLandList}
                                                    field={`agriLivestock.throughIrrigation`}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>
                                    {livestockQuestion[selectedLanguage]}
                                </label>
                                <SingleSelectInput
                                    id="livestockType"
                                    name={`agriLivestock.animalBirdInHouse`}
                                    value={agriLivestock[`animalBirdInHouse`]}
                                    formik={formik}
                                    selectList={livestockType}
                                />
                            </div>
                        </div>

                        {getLiveStockSection()}

                        {/* {agriLivestock.livestockType == "both" && (
                            <>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <label>
                                            कुन कुन पशु पन्छी पाल्नु भएको छ ?
                                        </label>
                                        <CustomSelectMulti
                                            formik={formik}
                                            value={
                                                agriLivestock[`allLivestock`]
                                            }
                                            isMulti={true}
                                            list={allLivestock}
                                            field={`agriLivestock.allLivestock`}
                                        />
                                    </div>
                                </div>
                            </>
                        )} */}
                        <div className="col-lg-6">
                            <div class="form-group">
                                <label>{beefishYesNo[selectedLanguage]}</label>
                                <div class="radio-inline">
                                    {beefishsilkYesNo.map((value, i) => (
                                        <label class="radio radio-rounded">
                                            <input
                                                type="radio"
                                                name={`agriLivestock.fishBeeSilk`}
                                                value={value.value}
                                                onChange={formik.handleChange}
                                                defaultChecked={
                                                    agriLivestock.fishBeeSilk ===
                                                    value.value
                                                }
                                            />
                                            <span></span>
                                            {value[selectedLanguage]}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {agriLivestock.fishBeeSilk === "yes" && (
                            <>
                                <div className="col-6">
                                    <div class="form-group">
                                        <label>
                                            {beefishsilkYes[selectedLanguage]}
                                        </label>
                                        <CustomSelectMulti
                                            formik={formik}
                                            value={
                                                agriLivestock[`beefishsilkType`]
                                            }
                                            isMulti={true}
                                            list={beefishsilkType}
                                            field={`agriLivestock.beefishsilkType`}
                                        />
                                    </div>
                                </div>
                                {getBeeFishSilkSection()}
                            </>
                        )}

                        <>
                            {agriLivestock.agricultureProductSales ===
                                "land_used_yes" && (
                                <>
                                    <div className="col-6">
                                        <div class="form-group">
                                            <label>
                                                {bimaQuestion[selectedLanguage]}
                                            </label>
                                            <div className="radio-inline">
                                                <label className="radio radio-rounded">
                                                    <input
                                                        type="radio"
                                                        name={`agriLivestock.agricultureInsurance`}
                                                        value="yes"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        defaultChecked={
                                                            agriLivestock.agricultureInsurance ===
                                                            "yes"
                                                        }
                                                    />
                                                    <span></span>छ
                                                </label>
                                                <label class="radio radio-rounded">
                                                    <input
                                                        type="radio"
                                                        name={`agriLivestock.agricultureInsurance`}
                                                        value="no"
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        defaultChecked={
                                                            agriLivestock.agricultureInsurance ===
                                                            "no"
                                                        }
                                                    />
                                                    <span></span>
                                                    छैन
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {agriLivestock.agricultureInsurance === "yes" && (
                                <>
                                    <div className="col-lg-6">
                                        <div class="form-group">
                                            <label>
                                                {ifBimaYes[selectedLanguage]}
                                            </label>
                                            <SingleSelectInput
                                                id="ifBimaYesNo"
                                                name={`agriLivestock.whichAgriInsurance`}
                                                value={
                                                    agriLivestock[
                                                        `whichAgriInsurance`
                                                    ]
                                                }
                                                formik={formik}
                                                selectList={ifBimaYesNo}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KrishiTathaPashu;
