const stateQuestion = {
    nepali: "प्रदेश छान्नुहोस्",
    english: "",
};

const province = [
    {
        nepali: "सुदूरपश्चिम  प्रदेश",
        english: "Sudurpachim",
        value: "sudhurpaschim",
    },
];

const districtQuestion = {
    nepali: "जिल्ला छान्नुहोस्",
    english: "",
};

const sudhurpaschimDistrictSelect = [
    {
        nepali: "डोटी",
        english: "Doti",
        value: "DOTI",
    },
];

const localQuestion = {
    nepali: "स्थानीय तह छान्नुहोस्",
    english: "",
};

const localSelect = [
    {
        nepali: "जोरायल गाउँपालिका",
        english: "Joint village",
        value: "Joint_village",
    },
];

module.exports = {
    stateQuestion,
    province,
    districtQuestion,
    sudhurpaschimDistrictSelect,
    localSelect,
    localQuestion,
};
