import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import {
    wardList,
    toleWardOne,
    toleWardTwo,
    toleWardThree,
    toleWardFour,
    toleWardFive,
    toleWardSix,
    toleWardSeven,
    toleWardEight,
    toleWardTen,
    toleWardNine,
    toleWardEleven,
    toleWardTwelve,
} from "../data/ToleList";
import {
    toleFiveQn,
    toleFourQn,
    toleOneQn,
    toleThreeQn,
    toleTwoQn,
    toleSixQn,
    toleSevenQn,
    toleEightQn,
    toleNineQn,
    toleTenQn,
    toleElevenQn,
    toleTwelveQn,
} from "../data/houseSurvey";
import {
    ward,
    communityGroupType,
    wardQuestion,
    selectedLanguage,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";

const AssemblyHall = (props) => {
    const [selectedWard, setSelectedWard] = useState(0);

    const wardData = [
        {
            question: toleOneQn,
            toleList: toleWardOne,
        },
        {
            question: toleTwoQn,
            toleList: toleWardTwo,
        },
        {
            question: toleThreeQn,
            toleList: toleWardThree,
        },
        {
            question: toleFourQn,
            toleList: toleWardFour,
        },
        {
            question: toleFiveQn,
            toleList: toleWardFive,
        },
        {
            question: toleSixQn,
            toleList: toleWardSix,
        },
        {
            question: toleSevenQn,
            toleList: toleWardSeven,
        },
        {
            question: toleEightQn,
            toleList: toleWardEight,
        },
        {
            question: toleNineQn,
            toleList: toleWardNine,
        },
        {
            question: toleTenQn,
            toleList: toleWardTen,
        },
        {
            question: toleElevenQn,
            toleList: toleWardEleven,
        },
        {
            question: toleTwelveQn,
            toleList: toleWardTwelve,
        },
    ];
    const getToleList = (ward) => {
        if (ward == 1) {
            return toleWardOne;
        } else if (ward == 2) {
            return toleWardTwo;
        } else if (ward == 3) {
            return toleWardThree;
        } else if (ward == 4) {
            return toleWardFour;
        } else if (ward == 5) {
            return toleWardFive;
        } else if (ward == 6) {
            return toleWardSix;
        } else if (ward == 7) {
            return toleWardSeven;
        } else if (ward == 8) {
            return toleWardEight;
        } else if (ward == 9) {
            return toleWardNine;
        } else if (ward == 10) {
            return toleWardTen;
        } else if (ward == 11) {
            return toleWardEleven;
        } else {
            return toleWardTwelve;
        }
    };
    const handleWardChange = (ward) => {
        setSelectedWard(ward);
    };
    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={
                    props.data
                        ? props.data
                        : {
                              name: "",
                              wardNumber: "",
                              toleName: "",
                              streetName: "",
                              capacity: "",
                              condition: "",
                              establishedDate: "",
                              presidentInfoName: "",
                              presidentInfoContact: "",
                          }
                }
                validate={(values) => {
                    const errors = {};
                    // if (!values.name) {
                    //     errors.name = 'Required';
                    // }
                    // else
                    // if (
                    //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    // ) {
                    //     errors.email = 'Invalid email address';
                    // }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                    setTimeout(() => {
                        props.submit(values);
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                }) => (
                    <>
                        <form
                            className="form survey-form"
                            onSubmit={handleSubmit}
                        >
                            <Card>
                                <Card.Body>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                सभाहलको नाम
                                            </label>

                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="सभाहलको नाम"
                                                name="name"
                                                onChange={handleChange}
                                                value={values.name}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="name"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                {wardQuestion[selectedLanguage]}
                                            </label>
                                            <select
                                                className="form-control"
                                                id="wardNumber"
                                                name="wardNumber"
                                                onChange={handleChange}
                                                handleWardChange={handleWardChange(
                                                    values.wardNumber
                                                )}
                                                value={
                                                    values.wardNumber
                                                        ? values.wardNumber
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                {wardList.map((woda) => (
                                                    <option value={woda.value}>
                                                        {woda[selectedLanguage]}
                                                    </option>
                                                ))}
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="wardNumber"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        {values.wardNumber && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    {
                                                        wardData[
                                                            values.wardNumber -
                                                                1
                                                        ].question[
                                                            selectedLanguage
                                                        ]
                                                    }
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="toleName"
                                                    name="toleName"
                                                    onChange={handleChange}
                                                    value={
                                                        values.toleName
                                                            ? values.toleName
                                                            : "selectOne"
                                                    }
                                                >
                                                    <option
                                                        value="selectOne"
                                                        disabled
                                                    >
                                                        कुनै एक छान्नुहोस्
                                                    </option>
                                                    {getToleList(
                                                        values.wardNumber
                                                    ).map((woda) => (
                                                        <option
                                                            value={woda.value}
                                                        >
                                                            {
                                                                woda[
                                                                    selectedLanguage
                                                                ]
                                                            }
                                                        </option>
                                                    ))}
                                                </select>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="toleName"
                                                    component="div"
                                                />
                                            </div>
                                        )}

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                मार्गको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="मार्गको नाम"
                                                name="streetName"
                                                onChange={handleChange}
                                                value={values.streetName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="streetName"
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                सभाहलको क्षमता (अटाउन सक्ने
                                                जनसंख्या)
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="सभाहलको क्षमता (अटाउन सक्ने जनसंख्या)"
                                                name="capacity"
                                                onChange={handleChange}
                                                value={values.capacity}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="capacity"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                सभाहलको आवस्था
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="good"
                                                    name="condition"
                                                    onChange={handleChange}
                                                />
                                                <span></span>राम्रो
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="average"
                                                    name="condition"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                ठिकै
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="jirna"
                                                    name="condition"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                जिर्ण
                                            </label>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="condition"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                सभाहाल बनेको साल
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="establishedDate"
                                                onChange={handleChange}
                                                value={values.establishedDate}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="establishedDate"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                सम्पर्क व्यक्तिको नाम थर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="presidentInfoName"
                                                onChange={handleChange}
                                                value={values.presidentInfoName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="presidentInfoName"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                स्रोत व्यक्तिको सम्पर्क नम्बर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="presidentInfoContact"
                                                onChange={handleChange}
                                                value={
                                                    values.presidentInfoContact
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="presidentInfoContact"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                अक्षांश
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lat"
                                                onChange={handleChange}
                                                value={values.lat}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="lat"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                देशान्तर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lng"
                                                onChange={handleChange}
                                                value={values.lng}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="lng"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label>फोटो</label>
                                            <ImageUploader
                                                fileListContent={
                                                    props.fileListContent
                                                }
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="card-footer text-right">
                                        <FormFooterButtons />
                                    </div>
                                </Card.Body>
                            </Card>
                        </form>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default AssemblyHall;
