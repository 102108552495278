import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { Divider } from "antd";
import {
    wardList,
    toleWardOne,
    toleWardTwo,
    toleWardThree,
    toleWardFour,
    toleWardFive,
    toleWardSix,
    toleWardSeven,
    toleWardEight,
    toleWardTen,
    toleWardNine,
    toleWardEleven,
    toleWardTwelve,
} from "../data/ToleList";
import {
    toleFiveQn,
    toleFourQn,
    toleOneQn,
    toleThreeQn,
    toleTwoQn,
    toleSixQn,
    toleSevenQn,
    toleEightQn,
    toleNineQn,
    toleTenQn,
    toleElevenQn,
    toleTwelveQn,
} from "../data/houseSurvey";
import {
    ward,
    healthInstituteList,
    wardQuestion,
    selectedLanguage,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";

const Health = (props) => {
    const [selectedWard, setSelectedWard] = useState(0);

    const wardData = [
        {
            question: toleOneQn,
            toleList: toleWardOne,
        },
        {
            question: toleTwoQn,
            toleList: toleWardTwo,
        },
        {
            question: toleThreeQn,
            toleList: toleWardThree,
        },
        {
            question: toleFourQn,
            toleList: toleWardFour,
        },
        {
            question: toleFiveQn,
            toleList: toleWardFive,
        },
        {
            question: toleSixQn,
            toleList: toleWardSix,
        },
        {
            question: toleSevenQn,
            toleList: toleWardSeven,
        },
        {
            question: toleEightQn,
            toleList: toleWardEight,
        },
        {
            question: toleNineQn,
            toleList: toleWardNine,
        },
        {
            question: toleTenQn,
            toleList: toleWardTen,
        },
        {
            question: toleElevenQn,
            toleList: toleWardEleven,
        },
        {
            question: toleTwelveQn,
            toleList: toleWardTwelve,
        },
    ];
    const getToleList = (ward) => {
        if (ward == 1) {
            return toleWardOne;
        } else if (ward == 2) {
            return toleWardTwo;
        } else if (ward == 3) {
            return toleWardThree;
        } else if (ward == 4) {
            return toleWardFour;
        } else if (ward == 5) {
            return toleWardFive;
        } else if (ward == 6) {
            return toleWardSix;
        } else if (ward == 7) {
            return toleWardSeven;
        } else if (ward == 8) {
            return toleWardEight;
        } else if (ward == 9) {
            return toleWardNine;
        } else if (ward == 10) {
            return toleWardTen;
        } else if (ward == 11) {
            return toleWardEleven;
        } else {
            return toleWardTwelve;
        }
    };
    const handleWardChange = (ward) => {
        setSelectedWard(ward);
    };
    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={
                    props.data
                        ? props.data
                        : {
                              name: "",
                              wardNumber: "",
                              toleName: "",
                              streetName: "",
                              healthOrgType: "",
                              instituteType: "",
                              bedCount: "",
                              buildingType: "",
                              buildingCount: "",
                              roomCount: "",
                          }
                }
                validate={(values) => {
                    const errors = {};
                    // if (!values.name) {
                    //     errors.name = 'Required';
                    // }
                    // else
                    // if (
                    //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    // ) {
                    //     errors.email = 'Invalid email address';
                    // }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                    setTimeout(() => {
                        props.submit(values);
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                }) => (
                    <>
                        <form
                            className="form survey-form"
                            onSubmit={handleSubmit}
                        >
                            <Card>
                                <Card.Body>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                स्वास्थ्य सँस्थाको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="स्वास्थ्य सँस्थाको नाम"
                                                name="name"
                                                onChange={handleChange}
                                                value={values.name}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="name"
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                {wardQuestion[selectedLanguage]}
                                            </label>
                                            <select
                                                className="form-control"
                                                id="wardNumber"
                                                name="wardNumber"
                                                onChange={handleChange}
                                                handleWardChange={handleWardChange(
                                                    values.wardNumber
                                                )}
                                                value={
                                                    values.wardNumber
                                                        ? values.wardNumber
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                {wardList.map((woda) => (
                                                    <option value={woda.value}>
                                                        {woda[selectedLanguage]}
                                                    </option>
                                                ))}
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="wardNumber"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        {values.wardNumber && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    {
                                                        wardData[
                                                            values.wardNumber -
                                                                1
                                                        ].question[
                                                            selectedLanguage
                                                        ]
                                                    }
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="toleName"
                                                    name="toleName"
                                                    onChange={handleChange}
                                                    value={
                                                        values.toleName
                                                            ? values.toleName
                                                            : "selectOne"
                                                    }
                                                >
                                                    <option
                                                        value="selectOne"
                                                        disabled
                                                    >
                                                        कुनै एक छान्नुहोस्
                                                    </option>
                                                    {getToleList(
                                                        values.wardNumber
                                                    ).map((woda) => (
                                                        <option
                                                            value={woda.value}
                                                        >
                                                            {
                                                                woda[
                                                                    selectedLanguage
                                                                ]
                                                            }
                                                        </option>
                                                    ))}
                                                </select>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="ward"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                मार्गको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="मार्गको नाम"
                                                name="streetName"
                                                onChange={handleChange}
                                                value={values.streetName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="streetName"
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                स्वास्थ सँस्था
                                            </label>
                                            <select
                                                className="form-control"
                                                id="healthOrgType"
                                                name="healthOrgType"
                                                onChange={handleChange}
                                                value={
                                                    values.healthOrgType
                                                        ? values.healthOrgType
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                {healthInstituteList.map(
                                                    (health) => (
                                                        <option
                                                            value={health.value}
                                                        >
                                                            {
                                                                health[
                                                                    selectedLanguage
                                                                ]
                                                            }
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="ward"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                स्वास्थ्य सँस्थाको प्रकार
                                            </label>
                                            <select
                                                className="form-control"
                                                id="instituteType"
                                                name="instituteType"
                                                onChange={handleChange}
                                                value={
                                                    values.instituteType
                                                        ? values.instituteType
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                <option value="personal">
                                                    सँस्थागत (निजि )
                                                </option>
                                                <option value="governmental">
                                                    सामुदायिक
                                                </option>
                                                <option value="other">
                                                    अन्य
                                                </option>
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="instituteType"
                                                component="div"
                                            />
                                        </div>
                                        {values.instituteType == "other" && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    अन्य भए खुलाउनुहोस्
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="example-date-input"
                                                    placeholder="अन्य भए खुलाउनुहोस्"
                                                    name="otherInstituteType"
                                                    onChange={handleChange}
                                                    value={
                                                        values.otherInstituteType
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="otherInstituteType"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                शैया संख्या ( उपलब्ध बेड संख्या
                                                )
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="शैया संख्या ( उपलब्ध बेड संख्या )"
                                                name="bedCount"
                                                onChange={handleChange}
                                                value={values.bedCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="bedCount"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                स्वास्थ्य सँस्थाको भवन कस्तो छ ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="kachhi"
                                                    name="buildingType"
                                                    onChange={handleChange}
                                                />
                                                <span></span>कच्ची
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="pakki"
                                                    name="buildingType"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                पक्की
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="other"
                                                    name="buildingType"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                अन्य
                                            </label>
                                        </div>
                                        {values.buildingType == "other" && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    अन्य भए खुलाउनुहोस्
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="example-date-input"
                                                    placeholder="अन्य भए खुलाउनुहोस्"
                                                    name="otherBuildingType"
                                                    onChange={handleChange}
                                                    value={
                                                        values.otherBuildingType
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="otherBuildingType"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                स्वास्थ्य सँस्थाको भवन सङ्ख्या
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="स्वास्थ्य सँस्थाको भवन सङ्ख्या"
                                                name="buildingCount"
                                                onChange={handleChange}
                                                value={values.buildingCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="buildingCount"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                स्वास्थ्य सँस्थाको कोठा सङ्ख्या
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="roomCount"
                                                placeholder="स्वास्थ्य सँस्थाको कोठा सङ्ख्या"
                                                name="roomCount"
                                                onChange={handleChange}
                                                value={values.roomCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="roomCount"
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                स्वास्थ्य सँस्थाको स्रोत
                                                व्यक्तिको नाम थर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="orgSponsorName"
                                                placeholder="स्वास्थ्य सँस्थाको स्रोत व्यक्तिको नाम थर"
                                                name="orgSponsorName"
                                                onChange={handleChange}
                                                value={values.orgSponsorName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="orgSponsorName"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                स्वास्थ्य सँस्थाको सम्पर्क नम्बर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="orgContactNumber"
                                                placeholder="स्वास्थ्य सँस्थाको सम्पर्क नम्बर"
                                                name="orgContactNumber"
                                                onChange={handleChange}
                                                value={values.orgContactNumber}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="orgContactNumber"
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                MBBS डाक्टर वा सो सरहको डाक्टर
                                                उपलब्ध छ कि छैन ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="hasHighDegreeDoctor"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="hasHighDegreeDoctor"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                    </div>
                                    {values.hasHighDegreeDoctor === "yes" && (
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    विशेषज्ञ चिकित्सक
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="specialistPhysician"
                                                    onChange={handleChange}
                                                    value={
                                                        values.staffCount
                                                            ?.specialistPhysician
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="specialistPhysician"
                                                    component="div"
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    सामान्य चिकित्सक
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    name="generalPractitioner"
                                                    onChange={handleChange}
                                                    value={
                                                        values.staffCount
                                                            ?.generalPractitioner
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="generalPractitioner"
                                                    component="div"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                हे.व
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="healthAssistant"
                                                onChange={handleChange}
                                                value={
                                                    values.staffCount
                                                        ?.healthAssistant
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="healthAssistant"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                नर्स
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="nurse"
                                                onChange={handleChange}
                                                value={values.staffCount?.nurse}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="nurse"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                अनमी
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="aanami"
                                                onChange={handleChange}
                                                value={
                                                    values.staffCount?.aanami
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="aanami"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                ल्याव टेक्निसियन
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="labTechnician"
                                                onChange={handleChange}
                                                value={
                                                    values.staffCount
                                                        ?.labTechnician
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="labTechnician"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                रेडियोग्राफी
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="radiography"
                                                onChange={handleChange}
                                                value={
                                                    values.staffCount
                                                        ?.radiography
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="radiography"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    {/* <Divider>
                                        स्वास्थ्य सँस्थाका सेवा तथा सुबिधाहरु
                                    </Divider> */}
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                प्रसुती सेवा
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="maternity"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="maternity"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                ल्याब
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="lab"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="lab"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                एक्सरे सेवा
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="xray"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="xray"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                रक्त सञ्चार सेवा
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="bloodTransfusion"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="bloodTransfusion"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                एम्बुलेन्स सेवा
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="ambulance"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="ambulance"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                इमर्जेन्सी सेवा
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="emergency"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="emergency"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                अक्षांश
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lat"
                                                onChange={handleChange}
                                                value={values.geo?.lat}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="lat"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                देशान्तर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lng"
                                                onChange={handleChange}
                                                value={values.geo?.lng}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="lng"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label>फोटो</label>
                                            <ImageUploader
                                                fileListContent={
                                                    props.fileListContent
                                                }
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="card-footer text-right">
                                        <FormFooterButtons />
                                    </div>
                                </Card.Body>
                            </Card>
                        </form>
                    </>

                    // <Form>
                    //     <Field type="area" name="area" />
                    //     <ErrorMessage className="invalid-feedback" name="area" component="div" />
                    //     <Field type="password" name="password" />
                    //     <ErrorMessage className="invalid-feedback" name="password" component="div" />
                    //     <button type="submit" disabled={isSubmitting}>
                    //         Submit
                    //     </button>
                    // </Form>
                )}
            </Formik>
        </div>
    );
};

export default Health;
