export const allToleName = [
    {
        nepali: "उपल्लो तिरम",
        english: "UpalloTiram",
        value: "UpalloTiram",
    },
    {
        nepali: "तल्लो तिराम",
        english: "Tallotiram",
        value: "Tallotiram",
    },
    {
        nepali: "खरिवांग",
        english: "Khariwagh",
        value: "Khariwagh",
    },
    {
        nepali: "बडाचौर",
        english: "BadhaChour",
        value: "BadhaChour",
    },
    {
        nepali: "उमारे",
        english: "Umarey",
        value: "Umarey",
    },
    {
        nepali: "छाबिसे",
        english: "Chhabeshya",
        value: "Chhabeshya",
    },
    {
        nepali: "कात्तिके",
        english: "Katikey",
        value: "Katikey",
    },
    {
        nepali: "कुमालटार",
        english: "Kumaltar",
        value: "Kumaltar",
    },
    {
        nepali: "गरजिने",
        english: "Garjiney",
        value: "Garjiney",
    },
    {
        nepali: "सिमलचौर",
        english: "Simalchour",
        value: "Simalchour",
    },
    {
        nepali: "जेमुरे",
        english: "jamurey",
        value: "jamurey",
    },
    {
        nepali: "तल्लो तिराम माज टोल",
        english: "Tallo_Tiram_Maaj_Tol",
        value: "Tallo_Tiram_Maaj_Tol",
    },
    //1
    {
        nepali: "ढाडा",
        english: "khungri_kot",
        value: "khungri_kot",
    },
    {
        nepali: "गोल्वांग",
        english: "Golwang",
        value: "Golwang",
    },
    {
        nepali: "धन्चौर",
        english: "Dhanchour",
        value: "Dhanchour",
    },
    {
        nepali: "रातापानी",
        english: "Ratapani",
        value: "Ratapani",
    },
    {
        nepali: "काभ्रे",
        english: "Kavre",
        value: "Kavre",
    },
    {
        nepali: "ढिकावन",
        english: "Dhikawan",
        value: "Dhikawan",
    },
    {
        nepali: "मर्कावंग",
        english: "Markawan",
        value: "Markawan",
    },
    {
        nepali: "पुकरुक",
        english: "pukaruka",
        value: "pukaruka",
    },
    {
        nepali: "खुल्लिचौर",
        english: "Khulichour",
        value: "Khulichour",
    },
    // 2
    {
        nepali: "मार्सिबांग",
        english: "maarsibang",
        value: "maarsibang",
    },
    {
        nepali: "बेसी",
        english: "besi",
        value: "besi",
    },
    {
        nepali: "हिरिंग खोला",
        english: "hiring_khola",
        value: "hiring_khola",
    },
    {
        nepali: "सल्लिकोट",
        english: "sallikot",
        value: "sallikot",
    },
    {
        nepali: "टार",
        english: "tar",
        value: "tar",
    },
    {
        nepali: "रिपा",
        english: "ripa",
        value: "ripa",
    },
    {
        nepali: "जोइ टोल",
        english: "joi_tol",
        value: "joi_tol",
    },
    {
        nepali: "गाइ खोला",
        english: "gaaikhol",
        value: "gaaikhol",
    },
    {
        nepali: "पखरचोर्र",
        english: "pakharchor",
        value: "pakharchor",
    },
    {
        nepali: "धाईचोर",
        english: "dhaichor",
        value: "dhaichor",
    },
    {
        nepali: "सुनार टोल",
        english: "sunar_tol",
        value: "sunar_tol",
    },
    {
        nepali: "आँपचौर",
        english: "aapachor",
        value: "aapachor",
    },
    {
        nepali: "कुसुम डाँडा",
        english: "kusumdadha",
        value: "kusumdadha",
    },
    {
        nepali: "सिमल गैरा",
        english: "simal_gauraa",
        value: "simal_gauraa",
    },
    {
        nepali: "ताप खोला",
        english: "taap_khola",
        value: "taap_khola",
    },
    {
        nepali: "जुहारी",
        english: "juhari",
        value: "juhari",
    },
    {
        nepali: "पोखर डाँडा",
        english: "pokhar_dadha",
        value: "pokhar_dadha",
    },
    {
        nepali: "धरम्पा",
        english: "darampaa",
        value: "darampaa",
    },
    {
        nepali: "पुन टोल",
        english: "puntol",
        value: "puntol",
    },
    {
        nepali: "थापा टोल",
        english: "tahaptol",
        value: "tahaptol",
    },
    {
        nepali: "भट्टराइ टोल",
        english: "bhataraitol",
        value: "bhataraitol",
    },
    {
        nepali: "भलाबाय टोल",
        english: "bhalabay_tol",
        value: "bhalabay_tol",
    },
    {
        nepali: "घर्ती टोल",
        english: "dharti_tol",
        value: "dharti_tol",
    },
    {
        nepali: "दारिमचोर",
        english: "darimchor",
        value: "darimchor",
    },
    {
        nepali: "जाबुने",
        english: "jabune",
        value: "jabune",
    },
    {
        nepali: "बंगैचा",
        english: "bagaichh",
        value: "bagaichh",
    },
    {
        nepali: "जस्पुर",
        english: "jaspur",
        value: "jaspur",
    },
    {
        nepali: "माथिल्लो जस्पुर",
        english: "mathillo",
        value: "mathillo",
    },
    {
        nepali: "देवीस्थान",
        english: "dewisthan",
        value: "dewisthan",
    },
    // 3
    {
        nepali: "सल्लिखर्क",
        english: "Salikharka",
        value: "Salikharka",
    },
    {
        nepali: "सिर्वारी",
        english: "Sirwari",
        value: "Sirwari",
    },
    {
        nepali: "नर्शु",
        english: "Narsu",
        value: "Narsu",
    },
    {
        nepali: "धोवाघाट",
        english: "Dhowaghat",
        value: "Dhowaghat",
    },
    {
        nepali: "भेरावारी",
        english: "Bherawari",
        value: "Bherawari",
    },
    {
        nepali: "धन्चौर",
        english: "Dhanchour",
        value: "Dhanchour",
    },
    {
        nepali: "डाडाकटेरी",
        english: "Dadhakateyri",
        value: "Dadhakateyri",
    },
    {
        nepali: "तिल्केनी",
        english: "Tilkeyni",
        value: "Tilkeyni",
    },
    {
        nepali: "आप्चौर",
        english: "Aapchour",
        value: "Aapchour",
    },
    {
        nepali: "मक्चौर",
        english: "Makaichour",
        value: "Makaichour",
    },
    {
        nepali: "बुर्दे",
        english: "Burdhay",
        value: "Burdhay",
    },
    {
        nepali: "फरिन्दे",
        english: "Farindhey",
        value: "Farindhey",
    },
    {
        nepali: "गरुले",
        english: "Garuley",
        value: "Garuley",
    },
    {
        nepali: "दक्सुनि",
        english: "Daksune",
        value: "Daksune",
    },
    {
        nepali: "भकुण्डे",
        english: "Bhakundey",
        value: "Bhakundey",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const toleWardOne = [
    {
        nepali: "उपल्लो तिरम",
        english: "UpalloTiram",
        value: "UpalloTiram",
    },
    {
        nepali: "तल्लो तिराम",
        english: "Tallotiram",
        value: "Tallotiram",
    },
    {
        nepali: "खरिवांग",
        english: "Khariwagh",
        value: "Khariwagh",
    },
    {
        nepali: "बडाचौर",
        english: "BadhaChour",
        value: "BadhaChour",
    },
    {
        nepali: "उमारे",
        english: "Umarey",
        value: "Umarey",
    },
    {
        nepali: "छाबिसे",
        english: "Chhabeshya",
        value: "Chhabeshya",
    },
    {
        nepali: "कात्तिके",
        english: "Katikey",
        value: "Katikey",
    },
    {
        nepali: "कुमालटार",
        english: "Kumaltar",
        value: "Kumaltar",
    },
    {
        nepali: "गरजिने",
        english: "Garjiney",
        value: "Garjiney",
    },
    {
        nepali: "सिमलचौर",
        english: "Simalchour",
        value: "Simalchour",
    },
    {
        nepali: "जेमुरे",
        english: "jamurey",
        value: "jamurey",
    },
    {
        nepali: "तल्लो तिराम माज टोल",
        english: "Tallo_Tiram_Maaj_Tol",
        value: "Tallo_Tiram_Maaj_Tol",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const toleWardTwo = [
    {
        nepali: "ढाडा",
        english: "khungri_kot",
        value: "khungri_kot",
    },
    {
        nepali: "गोल्वांग",
        english: "Golwang",
        value: "Golwang",
    },
    {
        nepali: "धन्चौर",
        english: "Dhanchour",
        value: "Dhanchour",
    },
    {
        nepali: "रातापानी",
        english: "Ratapani",
        value: "Ratapani",
    },
    {
        nepali: "काभ्रे",
        english: "Kavre",
        value: "Kavre",
    },
    {
        nepali: "ढिकावन",
        english: "Dhikawan",
        value: "Dhikawan",
    },
    {
        nepali: "मर्कावंग",
        english: "Markawan",
        value: "Markawan",
    },
    {
        nepali: "पुकरुक",
        english: "pukaruka",
        value: "pukaruka",
    },
    {
        nepali: "खुल्लिचौर",
        english: "Khulichour",
        value: "Khulichour",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const toleWardThree = [
    {
        nepali: "मार्सिबांग",
        english: "maarsibang",
        value: "maarsibang",
    },
    {
        nepali: "बेसी",
        english: "besi",
        value: "besi",
    },
    {
        nepali: "हिरिंग खोला",
        english: "hiring_khola",
        value: "hiring_khola",
    },
    {
        nepali: "सल्लिकोट",
        english: "sallikot",
        value: "sallikot",
    },
    {
        nepali: "टार",
        english: "tar",
        value: "tar",
    },
    {
        nepali: "रिपा",
        english: "ripa",
        value: "ripa",
    },
    {
        nepali: "जोइ टोल",
        english: "joi_tol",
        value: "joi_tol",
    },
    {
        nepali: "गाइ खोला",
        english: "gaaikhol",
        value: "gaaikhol",
    },
    {
        nepali: "पखरचोर्र",
        english: "pakharchor",
        value: "pakharchor",
    },
    {
        nepali: "धाईचोर",
        english: "dhaichor",
        value: "dhaichor",
    },
    {
        nepali: "सुनार टोल",
        english: "sunar_tol",
        value: "sunar_tol",
    },
    {
        nepali: "आँपचौर",
        english: "aapachor",
        value: "aapachor",
    },
    {
        nepali: "कुसुम डाँडा",
        english: "kusumdadha",
        value: "kusumdadha",
    },
    {
        nepali: "सिमल गैरा",
        english: "simal_gauraa",
        value: "simal_gauraa",
    },
    {
        nepali: "ताप खोला",
        english: "taap_khola",
        value: "taap_khola",
    },
    {
        nepali: "जुहारी",
        english: "juhari",
        value: "juhari",
    },
    {
        nepali: "पोखर डाँडा",
        english: "pokhar_dadha",
        value: "pokhar_dadha",
    },
    {
        nepali: "धरम्पा",
        english: "darampaa",
        value: "darampaa",
    },
    {
        nepali: "पुन टोल",
        english: "puntol",
        value: "puntol",
    },
    {
        nepali: "थापा टोल",
        english: "tahaptol",
        value: "tahaptol",
    },
    {
        nepali: "भट्टराइ टोल",
        english: "bhataraitol",
        value: "bhataraitol",
    },
    {
        nepali: "भलाबाय टोल",
        english: "bhalabay_tol",
        value: "bhalabay_tol",
    },
    {
        nepali: "घर्ती टोल",
        english: "dharti_tol",
        value: "dharti_tol",
    },
    {
        nepali: "दारिमचोर",
        english: "darimchor",
        value: "darimchor",
    },
    {
        nepali: "जाबुने",
        english: "jabune",
        value: "jabune",
    },
    {
        nepali: "बंगैचा",
        english: "bagaichh",
        value: "bagaichh",
    },
    {
        nepali: "जस्पुर",
        english: "jaspur",
        value: "jaspur",
    },
    {
        nepali: "माथिल्लो जस्पुर",
        english: "mathillo",
        value: "mathillo",
    },
    {
        nepali: "देवीस्थान",
        english: "dewisthan",
        value: "dewisthan",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const toleWardFour = [
    {
        nepali: "सल्लिखर्क",
        english: "Salikharka",
        value: "Salikharka",
    },
    {
        nepali: "सिर्वारी",
        english: "Sirwari",
        value: "Sirwari",
    },
    {
        nepali: "नर्शु",
        english: "Narsu",
        value: "Narsu",
    },
    {
        nepali: "धोवाघाट",
        english: "Dhowaghat",
        value: "Dhowaghat",
    },
    {
        nepali: "भेरावारी",
        english: "Bherawari",
        value: "Bherawari",
    },
    {
        nepali: "धन्चौर",
        english: "Dhanchour",
        value: "Dhanchour",
    },
    {
        nepali: "डाडाकटेरी",
        english: "Dadhakateyri",
        value: "Dadhakateyri",
    },
    {
        nepali: "तिल्केनी",
        english: "Tilkeyni",
        value: "Tilkeyni",
    },
    {
        nepali: "आप्चौर",
        english: "Aapchour",
        value: "Aapchour",
    },
    {
        nepali: "मक्चौर",
        english: "Makaichour",
        value: "Makaichour",
    },
    {
        nepali: "बुर्दे",
        english: "Burdhay",
        value: "Burdhay",
    },
    {
        nepali: "फरिन्दे",
        english: "Farindhey",
        value: "Farindhey",
    },
    {
        nepali: "गरुले",
        english: "Garuley",
        value: "Garuley",
    },
    {
        nepali: "दक्सुनि",
        english: "Daksune",
        value: "Daksune",
    },
    {
        nepali: "भकुण्डे",
        english: "Bhakundey",
        value: "Bhakundey",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const toleWardFive = [
    {
        nepali: "चौकी",
        english: "chouki",
        value: "chouki",
    },
    {
        nepali: "बोकडा",
        english: "bokadaa",
        value: "bokadaa",
    },
    {
        nepali: "अमौन",
        english: "amaun",
        value: "amaun",
    },
    {
        nepali: "हरडानी",
        english: "hardaani",
        value: "hardaani",
    },
    {
        nepali: "नकुरडा",
        english: "nakurdaa",
        value: "nakurdaa",
    },
    {
        nepali: "खहडा",
        english: "khahadaa",
        value: "khahadaa",
    },
    {
        nepali: "धामी गाउँ",
        english: "dhamigaau",
        value: "dhamigaau",
    },
    {
        nepali: "चोखाल गाउँ",
        english: "chokhaalgaau",
        value: "chokhaalgaau",
    },
    {
        nepali: "बामन गाउँ",
        english: "baamangaau",
        value: "baamangaau",
    },
    {
        nepali: "भुल खेत",
        english: "bhulkhet",
        value: "bhulkhet",
    },
    {
        nepali: "वल्खेत",
        english: "walkhet",
        value: "walkhet",
    },
    {
        nepali: "पातली गाउँ",
        english: "paataligaau",
        value: "paataligaau",
    },
    {
        nepali: "मनोहर बजार",
        english: "manoharbajaar",
        value: "manoharbajaar",
    },
    {
        nepali: "लेकाली",
        english: "lekaali",
        value: "lekaali",
    },
    {
        nepali: "बडीगाड",
        english: "badigaad",
        value: "badigaad",
    },
    {
        nepali: "बन्द्वार",
        english: "bandur",
        value: "bandur",
    },
    {
        nepali: "ओझा गाउँ",
        english: "ojhagaau",
        value: "ojhagaau",
    },
    {
        nepali: "खतेडा",
        english: "khaledaa",
        value: "khaledaa",
    },
    {
        nepali: "अलिबाटा",
        english: "alibaataa",
        value: "alibaataa",
    },
    {
        nepali: "कुरै",
        english: "kurai",
        value: "kurai",
    },
    {
        nepali: "बमन्कट्टे",
        english: "bamankate",
        value: "bamankate",
    },
    {
        nepali: "ओल्कत्ते",
        english: "olkate",
        value: "olkate",
    },
    {
        nepali: "टोलि",
        english: "toli",
        value: "toli",
    },
    {
        nepali: "सिस्मडा",
        english: "sismada",
        value: "sismada",
    },
    {
        nepali: "कुनेगडा",
        english: "kunegadaa",
        value: "kunegadaa",
    },
    {
        nepali: "ग्वान",
        english: "gwan",
        value: "gwan",
    },
    {
        nepali: "बोहरा गाउँ",
        english: "bohoragaau",
        value: "bohoragaau",
    },
    {
        nepali: "फाल्दा",
        english: "faaldaa",
        value: "faaldaa",
    },
    {
        nepali: "चोखाल गाउँ",
        english: "chokhaalgaau_1",
        value: "chokhaalgaau_1",
    },
    {
        nepali: "सेल्खोल्टा",
        english: "selkholtaa",
        value: "selkholtaa",
    },
    {
        nepali: "नौकोट",
        english: "naukot",
        value: "naukot",
    },
    {
        nepali: "मनोहर बजार",
        english: "manoharbajar",
        value: "manoharbajar",
    },
    {
        nepali: "खतेडा",
        english: "khatedha",
        value: "khatedha",
    },
    {
        nepali: "चिबटा",
        english: "chibataa",
        value: "chibataa",
    },
    {
        nepali: "थला",
        english: "thalaa",
        value: "thalaa",
    },
    {
        nepali: "नेन्ला",
        english: "nellaa",
        value: "nellaa",
    },
    {
        nepali: "चुकिला पानी",
        english: "chukilaapani",
        value: "chukilaapani",
    },
    {
        nepali: "पिपल तोला",
        english: "pipaltola",
        value: "pipaltola",
    },
    {
        nepali: "गुम",
        english: "gum",
        value: "gum",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const toleWardSix = [
    {
        nepali: "गावर",
        english: "gaawar",
        value: "gaawar",
    },
    {
        nepali: "चिमेतडा",
        english: "chimetada",
        value: "chimetada",
    },
    {
        nepali: "तेलपरा",
        english: "telapara",
        value: "telapara",
    },
    {
        nepali: "तानतड",
        english: "taalapara",
        value: "taalapara",
    },
    {
        nepali: "चोट्टे",
        english: "chotte",
        value: "chotte",
    },
    {
        nepali: "लामौत्य",
        english: "laamautya",
        value: "laamautya",
    },
    {
        nepali: "उमरा",
        english: "uamara",
        value: "uamara",
    },
    {
        nepali: "स्यामारे",
        english: "iyamaare",
        value: "iyamaare",
    },
    {
        nepali: "दाङड",
        english: "daangda",
        value: "daangda",
    },
    {
        nepali: "थाकाना",
        english: "thaakaana",
        value: "thaakaana",
    },
    {
        nepali: "कस्ताडी",
        english: "kastaadi",
        value: "kastaadi",
    },
    {
        nepali: "जडेपानी",
        english: "jadepaani",
        value: "jadepaani",
    },
    {
        nepali: "शिर्शी",
        english: "shishri",
        value: "shishri",
    },
    {
        nepali: "शिर्शिखेत",
        english: "shishrikhet",
        value: "shishrikhet",
    },
    {
        nepali: "गुनड",
        english: "gunda",
        value: "gunda",
    },
    {
        nepali: "सिरोडा",
        english: "shirodaa",
        value: "shirodaa",
    },
    {
        nepali: "डांडा गाउँ",
        english: "dadagaau",
        value: "dadagaau",
    },
    {
        nepali: "खसरे",
        english: "khasare",
        value: "khasare",
    },
    {
        nepali: "ग्वानी",
        english: "gwani",
        value: "gwani",
    },
    {
        nepali: "बसौटी",
        english: "basauti",
        value: "basauti",
    },
    {
        nepali: "निरौली",
        english: "nirauli",
        value: "nirauli",
    },
    {
        nepali: "वागीडा",
        english: "wagidaa",
        value: "wagidaa",
    },
    {
        nepali: "दह",
        english: "daha",
        value: "daha",
    },
    {
        nepali: "खतेना",
        english: "khetan",
        value: "khetan",
    },
    {
        nepali: "सित्तोली",
        english: "shitol",
        value: "shitol",
    },
    {
        nepali: "कफलतडी",
        english: "khafalatadi",
        value: "khafalatadi",
    },
    {
        nepali: "तितौले",
        english: "tilaule",
        value: "tilaule",
    },
    {
        nepali: "अनरकट्टे",
        english: "anarkatte",
        value: "anarkatte",
    },
    {
        nepali: "होलेसैन",
        english: "holesaini",
        value: "holesaini",
    },
    {
        nepali: "सुनारी",
        english: "sunari",
        value: "sunari",
    },
    {
        nepali: "उच्कला",
        english: "uchkalaa",
        value: "uchkalaa",
    },
    {
        nepali: "नवलपुर",
        english: "nawalpur",
        value: "nawalpur",
    },
    {
        nepali: "आरुबाटा",
        english: "aarubaat",
        value: "aarubaat",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const toleWardSeven = [
    {
        nepali: "चन्द्र सुर्य टोल बिकास समिति",
        english: "chardra_surya_tol",
        value: "chardra_surya_tol",
    },
    {
        nepali: "गोठगाउँ पु. वि.वि",
        english: "godagau_tol",
        value: "godagau_tol",
    },
    {
        nepali: "सृजना टोल बिकास समिति",
        english: "sirjan_tol",
        value: "sirjan_tol",
    },
    {
        nepali: "७५ विघे टोल बिकास समिति",
        english: "75_biga_tol",
        value: "75_biga_tol",
    },
    {
        nepali: "बेलागछी टोलविकास समिति",
        english: "belgachhi_tol",
        value: "belgachhi_tol",
    },
    {
        nepali: "गणेशपुर टोल विकास समिति",
        english: "ganesh_tol",
        value: "ganesh_tol",
    },
    {
        nepali: "गछिया टोल बिकास समिति",
        english: "gachiya_tol",
        value: "gachiya_tol",
    },
    {
        nepali: "पाथिभरा टोल बिकास समिति",
        english: "pathivara_tol",
        value: "pathivara_tol",
    },
    {
        nepali: "सुन्दर डाडागाउ  टोल बिकास समिति",
        english: "sundar_dada_gau_tol",
        value: "sundar_dada_gau_tol",
    },
    {
        nepali: "ठडीया टोल विकास समिति",
        english: "thadiy_tol",
        value: "thadiy_tol",
    },
    {
        nepali: "कल्याण टोल विकास समिति",
        english: "kalyan_tol",
        value: "kalyan_tol",
    },
    {
        nepali: "मधा एकता टोल विकास समिति",
        english: "madha_tol",
        value: "madha_tol",
    },
    {
        nepali: "परिवर्तन टोल विकास समिति",
        english: "pariwartan_tol",
        value: "pariwartan_tol",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const toleWardEight = [
    {
        nepali: "सलकपुर टोल",
        english: "salakpur_tol",
        value: "salakpur_tol",
    },
    {
        nepali: "भुल्के टोल",
        english: "bhulke_tol",
        value: "bhulke_tol",
    },
    {
        nepali: "पाथिभारा टोल",
        english: "paathivara_tol_8",
        value: "paathivara_tol_8",
    },
    {
        nepali: "न्यौपाने टोल",
        english: "neupane_tol",
        value: "neupane_tol",
    },
    {
        nepali: "देबी बहादुर टोल",
        english: "devi_bahadur_tol",
        value: "devi_bahadur_tol",
    },
    {
        nepali: "पोलाफयाक्टि पुर्ब टोल",
        english: "polafayakti_purb_tol",
        value: "polafayakti_purb_tol",
    },
    {
        nepali: "पोलाफयाक्टि टोल",
        english: "polafayakti_tol",
        value: "polafayakti_tol",
    },
    {
        nepali: "ढकाल टोल",
        english: "dhakal_tol",
        value: "dhakal_tol",
    },
    {
        nepali: "नागदेबी टोल",
        english: "nagdebi_tol",
        value: "nagdebi_tol",
    },
    {
        nepali: "लाहुरे टोल",
        english: "laahure_tol",
        value: "laahure_tol",
    },
    {
        nepali: "मध्याली टोल",
        english: "madhyali_tol",
        value: "madhyali_tol",
    },
    {
        nepali: "चन्द्रनगर टोल",
        english: "chandrnagar_tol",
        value: "chandrnagar_tol",
    },
    {
        nepali: "तालपोखरी टोल",
        english: "taalpokhari_tol",
        value: "taalpokhari_tol",
    },
    {
        nepali: "चिरम टोल",
        english: "chiran_tol",
        value: "chiran_tol",
    },
    {
        nepali: "नमस्ते टोल",
        english: "namaste_tol_8",
        value: "namaste_tol_8",
    },
    {
        nepali: "सुब्बा टोल",
        english: "shuba_tol_8",
        value: "shuba_tol_8",
    },
    {
        nepali: "खानेपानी टोल",
        english: "khanepane_tol8",
        value: "khanepane_tol8",
    },
    {
        nepali: "पौडेल टोल",
        english: "poudel_tol_8",
        value: "poudel_tol_8",
    },
    {
        nepali: "बिस्वबिधलय टोल",
        english: "university_tol",
        value: "university_tol",
    },
    {
        nepali: "भ्रकुटि टोल",
        english: "bhirkuti_tol",
        value: "bhirkuti_tol",
    },
    {
        nepali: "पाथिभारा राज टोल",
        english: "paathivara_raaj_tol",
        value: "paathivara_raaj_tol",
    },
    {
        nepali: "चिरम टोल",
        english: "chiram_tol",
        value: "chiram_tol",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const toleWardNine = [
    {
        nepali: "प्रकृती टोल",
        english: "prakriti_tol",
        value: "prakriti_tol",
    },
    {
        nepali: "मुसलिम टोल",
        english: "muslim_tol",
        value: "muslim_tol",
    },
    {
        nepali: "बगाली टोल",
        english: "bangaali_tol",
        value: "bangaali_tol",
    },
    {
        nepali: "मुसहर टोल",
        english: "musahar_tol",
        value: "musahar_tol",
    },
    {
        nepali: "आदर्श चोक",
        english: "aadarsa_tol",
        value: "aadarsa_tol",
    },
    {
        nepali: "गोबिन्द मार्ग",
        english: "gobinda_marga",
        value: "gobinda_marga",
    },
    {
        nepali: "बुधबारे टोल",
        english: "budabaare_tol",
        value: "budabaare_tol",
    },
    {
        nepali: "आइतबारे टोल",
        english: "aaitabar_tol",
        value: "aaitabar_tol",
    },
    {
        nepali: "शान्ति टोल",
        english: "santi_tol",
        value: "santi_tol",
    },
    {
        nepali: "सिर्जना टोल",
        english: "sirjana_tol",
        value: "sirjana_tol",
    },
    {
        nepali: "मिलिजुली टोल",
        english: "milijuli_tol",
        value: "milijuli_tol",
    },
    {
        nepali: "सन्यनारायण टोल",
        english: "satyanarayan_tol",
        value: "satyanarayan_tol",
    },
    {
        nepali: "चित्रे टोल",
        english: "chitra_tol",
        value: "chitra_tol",
    },
    {
        nepali: "बगदाद टोल",
        english: "bagdaad_tol",
        value: "bagdaad_tol",
    },
    {
        nepali: "नागदेबी टोल",
        english: "nagdebi_tol",
        value: "nagdebi_tol",
    },
    {
        nepali: "गरुंड टोल",
        english: "garud_tol",
        value: "garud_tol",
    },
    {
        nepali: "ज्योति टोल मार्ग",
        english: "jyoti_tol",
        value: "jyoti_tol",
    },
    {
        nepali: "सैनिक मार्ग",
        english: "sainika_marga",
        value: "sainika_marga",
    },
    {
        nepali: "मुनाल मार्ग",
        english: "munal_marga",
        value: "munal_marga",
    },
    {
        nepali: "कालिमन्दिर मार्ग",
        english: "kaalimandir_marg",
        value: "kaalimandir_marg",
    },
    {
        nepali: "जैनगर टोल",
        english: "jainagar_tol",
        value: "jainagar_tol",
    },
    {
        nepali: "बिराटचोक बजार",
        english: "birat_chok",
        value: "birat_chok",
    },
    {
        nepali: "दिपेन्द्र मार्ग",
        english: "dipendra_marga",
        value: "dipendra_marga",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const toleWardTen = [
    {
        nepali: "विराटचौक उत्तर सनराईज मार्ग",
        english: "option_1",
        value: "option_1",
    },
    {
        nepali: "शुभम मार्ग",
        english: "option_2",
        value: "option_2",
    },
    {
        nepali: "काजी मार्ग",
        english: "option_3",
        value: "option_3",
    },
    {
        nepali: "हलेसी मार्ग",
        english: "option_4",
        value: "option_4",
    },
    {
        nepali: "दराईजिङ मार्ग",
        english: "option_5",
        value: "option_5",
    },
    {
        nepali: "हिमाल मार्ग",
        english: "option_6",
        value: "option_6",
    },
    {
        nepali: "नरायण मार्ग",
        english: "option_7",
        value: "option_7",
    },
    {
        nepali: "प्रगति मार्ग",
        english: "option_8",
        value: "option_8",
    },
    {
        nepali: "शान्ति मार्ग",
        english: "option_9",
        value: "option_9",
    },
    {
        nepali: "जागृति मार्ग",
        english: "option_10",
        value: "option_10",
    },
    {
        nepali: "शिद्वाथ मार्ग",
        english: "option_11",
        value: "option_11",
    },
    {
        nepali: "बुद्व मार्ग",
        english: "option_12",
        value: "option_12",
    },
    {
        nepali: "त्रिवेणी मार्ग",
        english: "option_13",
        value: "option_13",
    },
    {
        nepali: "गौरीशंकर मार्ग",
        english: "option_14",
        value: "option_14",
    },
    {
        nepali: "भृकुटी मार्ग",
        english: "option_15",
        value: "option_15",
    },
    {
        nepali: "त्रिवेणी कंचनजंघा मार्ग",
        english: "option_16",
        value: "option_16",
    },
    {
        nepali: "त्रिवेणी पुर्व मितेरी मार्ग",
        english: "option_17",
        value: "option_17",
    },
    {
        nepali: "जनता टोल मार्ग",
        english: "option_18",
        value: "option_18",
    },
    {
        nepali: "हिमालय मार्ग",
        english: "option_19",
        value: "option_19",
    },
    {
        nepali: "गोपाल भण्डारी मार्ग",
        english: "option_20",
        value: "option_20",
    },
    {
        nepali: "न्यु मार्ग",
        english: "option_21",
        value: "option_21",
    },
    {
        nepali: "सात घरे मार्ग",
        english: "option_22",
        value: "option_22",
    },
    {
        nepali: "रुपाटार मार्ग",
        english: "option_23",
        value: "option_23",
    },
    {
        nepali: "लोक बहादुर मार्ग",
        english: "option_24",
        value: "option_24",
    },
    {
        nepali: "भण्डारी मार्ग",
        english: "option_25",
        value: "option_25",
    },
    {
        nepali: "गुरुजी टोल",
        english: "option_26",
        value: "option_26",
    },
    {
        nepali: "हरैचा मोड त्रिवेणी चोक",
        english: "option_27",
        value: "option_27",
    },
    {
        nepali: "गितांजलि मार्ग",
        english: "option_28",
        value: "option_28",
    },
    {
        nepali: "उद्दोग वाणिज्य संघ मार्ग",
        english: "option_29",
        value: "option_29",
    },
    {
        nepali: "प्रदिप मार्ग",
        english: "option_30",
        value: "option_30",
    },
    {
        nepali: "पानी टंकी  मार्ग",
        english: "option_31",
        value: "option_31",
    },
    {
        nepali: "तिन घरे मार्ग",
        english: "option_32",
        value: "option_32",
    },
    {
        nepali: "भोजपुरे मार्ग",
        english: "option_33",
        value: "option_33",
    },
    {
        nepali: "टिकाराम मार्ग",
        english: "option_34",
        value: "option_34",
    },
    {
        nepali: "भट्टराई मार्ग",
        english: "option_35",
        value: "option_35",
    },
    {
        nepali: "मुनाल मार्ग",
        english: "option_36",
        value: "option_36",
    },
    {
        nepali: "मुनाल झोडा",
        english: "option_37",
        value: "option_37",
    },
    {
        nepali: "छ थरे मार्ग",
        english: "option_38",
        value: "option_38",
    },
    {
        nepali: "मिस्रित मार्ग",
        english: "option_39",
        value: "option_39",
    },
    {
        nepali: "शंकर पश्चिम मार्ग",
        english: "option_40",
        value: "option_40",
    },
    {
        nepali: "सट्टभर्ना उत्तर दक्षिण मार्ग",
        english: "option_41",
        value: "option_41",
    },
    {
        nepali: "एकता टोल बाटो नं, १,२,३,४,५,६,७,८",
        english: "option_42",
        value: "option_42",
    },
    {
        nepali: "लाङघारी मार्ग",
        english: "option_43",
        value: "option_43",
    },
    {
        nepali: "मितेरी मार्ग",
        english: "option_44",
        value: "option_44",
    },
    {
        nepali: "वरपिपल टोल पिपल चोक मार्ग",
        english: "option_45",
        value: "option_45",
    },
    {
        nepali: "सुकुना मार्ग",
        english: "option_46",
        value: "option_46",
    },
    {
        nepali: "चारकुने मार्ग",
        english: "option_47",
        value: "option_47",
    },
    {
        nepali: "स्वागत मार्ग",
        english: "option_48",
        value: "option_48",
    },
    {
        nepali: "यात्री मार्ग",
        english: "यात्री मार्ग",
        value: "यात्री मार्ग",
    },
    {
        nepali: "लक्ष्मी नारायण मार्ग",
        english: "option_50",
        value: "option_50",
    },
    {
        nepali: "कन्काई मार्ग खोर्साने",
        english: "option_51",
        value: "option_51",
    },
    {
        nepali: "भानु माग उत्तर",
        english: "option_52",
        value: "option_52",
    },
    {
        nepali: "सयपत्री मार्ग",
        english: "option_53",
        value: "option_53",
    },
    {
        nepali: "सेवारो मार्ग",
        english: "option_54",
        value: "option_54",
    },
    {
        nepali: "लालिगुरास मार्ग",
        english: "option_55",
        value: "option_55",
    },
    {
        nepali: "शितल मार्ग",
        english: "option_56",
        value: "option_56",
    },
    {
        nepali: "वनअफिस बागझोडा मार्ग",
        english: "option_57",
        value: "option_57",
    },
    {
        nepali: "राया टोल मार्ग",
        english: "option_58",
        value: "option_58",
    },
    {
        nepali: "न्यु रोड मार्ग",
        english: "option_59",
        value: "option_59",
    },
    {
        nepali: "अनु मसला मिल मार्ग",
        english: "option_60",
        value: "option_60",
    },
    {
        nepali: "जेष्ठ नागरिक मार्ग",
        english: "option_61",
        value: "option_61",
    },
    {
        nepali: "हाम्रो स्टोर मार्ग",
        english: "option_62",
        value: "option_62",
    },
    {
        nepali: "सिद्वार्थ बोर्डिंग मार्ग",
        english: "option_63",
        value: "option_63",
    },
    {
        nepali: "सिस्ती किरात मार्ग",
        english: "option_64",
        value: "option_64",
    },
    {
        nepali: "शिवालय मन्दिर मार्ग",
        english: "option_65",
        value: "option_65",
    },
    {
        nepali: "नमुना मार्ग",
        english: "option_66",
        value: "option_66",
    },
    {
        nepali: "डि आर मार्ग",
        english: "option_67",
        value: "option_67",
    },
    {
        nepali: "न्यौपाने चोक हुदै बाघझोडा मार्ग",
        english: "option_68",
        value: "option_68",
    },
    {
        nepali: "नटुवा डाँडा मार्ग",
        english: "option_69",
        value: "option_69",
    },
    {
        nepali: "बासुकी मार्ग गहिरी टोल",
        english: "option_70",
        value: "option_70",
    },
    {
        nepali: "विष्णुलाल मार्ग",
        english: "option_71",
        value: "option_71",
    },
    {
        nepali: "सुन्निमा मार्ग",
        english: "option_72",
        value: "option_72",
    },
    {
        nepali: "महेन्द्र मार्ग रुपाटार",
        english: "option_73",
        value: "option_73",
    },
    {
        nepali: "शान्ति मार्ग बाघझोडा",
        english: "option_74",
        value: "option_74",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const toleWardEleven = [
    {
        nepali: "माथिल्लो कर्जुना",
        english: "maathilo_kajurna",
        value: "maathilo_kajurna",
    },
    {
        nepali: "तल्लो कर्जुना",
        english: "talo_kajurna",
        value: "talo_kajurna",
    },
    {
        nepali: "ग्रमथान",
        english: "gramthan",
        value: "gramthan",
    },
    {
        nepali: "बेलेपुर",
        english: "belepur",
        value: "belepur",
    },
    {
        nepali: "सगरमाथा टोल",
        english: "sagarmatha_tol",
        value: "sagarmatha_tol",
    },
    {
        nepali: "पुरानो बेलेपुर टोल",
        english: "purano_bele_tol",
        value: "purano_bele_tol",
    },
    {
        nepali: "तीनपैनी टोल",
        english: "tinpaini_tol",
        value: "tinpaini_tol",
    },
    {
        nepali: "भुटाइ र धाती टोल",
        english: "bhutai_ra_dhati_tol",
        value: "bhutai_ra_dhati_tol",
    },
    {
        nepali: "तिनमोहडी टोल",
        english: "tinmohadi_tol",
        value: "tinmohadi_tol",
    },
    {
        nepali: "शुक्रबारे टोल",
        english: "sukharbare_tol",
        value: "sukharbare_tol",
    },
    {
        nepali: "लालिगुरास तथा पत्थरघट्ट",
        english: "laliguras_tol",
        value: "laliguras_tol",
    },
    {
        nepali: "गोविन्दपुर टोल",
        english: "gobindapur_tol",
        value: "gobindapur_tol",
    },
    {
        nepali: "शिब मन्दिर टोल",
        english: "shiva_mandir_tol",
        value: "shiva_mandir_tol",
    },
    {
        nepali: "शान्ति टोल",
        english: "santi_tol",
        value: "santi_tol",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const toleWardTwelve = [
    {
        nepali: "खोर्साने टोल विकास संस्था",
        english: "khorsane_tol",
        value: "khorsane_tol",
    },
    {
        nepali: "क्याम्पस टोल विकास संस्था",
        english: "campus_tol",
        value: "campus_tol",
    },
    {
        nepali: "सिद्धश्वर टोल विकास संस्था",
        english: "sidrasor_tol",
        value: "sidrasor_tol",
    },
    {
        nepali: "संगम टोल विकास संस्था",
        english: "sangam_tol",
        value: "sangam_tol",
    },
    {
        nepali: "प्रणाम टोल विकास संस्था",
        english: "pranam_tol",
        value: "pranam_tol",
    },
    {
        nepali: "अन्य",
        english: "others",
        value: "others",
    },
];

export const wardList = [
    {
        nepali: "वडा न.१",
        english: "Ward number 1",
        value: "1",
    },
    {
        nepali: "वडा न.२",
        english: "Ward number 2",
        value: "2",
    },
    {
        nepali: "वडा न.३",
        english: "Ward number 3",
        value: "3",
    },
    {
        nepali: "वडा न.४",
        english: "Ward number 4",
        value: "4",
    },
    {
        nepali: "वडा न.५",
        english: "Ward number 5",
        value: "5",
    },
    {
        nepali: "वडा न.६",
        english: "Ward number 6",
        value: "6",
    },
];
