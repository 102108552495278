import React, { useState } from "react";
import { Formik, ErrorMessage } from "formik";
import {
    add,
    bridgeType,
    riverName,
    from,
    to,
    heightUnit,
    type,
    statusBridge,
    address,
    establishDate,
    height,
    name,
    photo,
    wardQuestion,
    areaList,
    ward,
    selectedLanguage,
    cancel,
} from "../data/institute";
import {
    wardList,
    toleWardOne,
    toleWardTwo,
    toleWardThree,
    toleWardFour,
    toleWardFive,
    toleWardSix,
    toleWardSeven,
    toleWardEight,
    toleWardTen,
    toleWardNine,
    toleWardEleven,
    toleWardTwelve,
} from "../data/ToleList";
import {
    toleFiveQn,
    toleFourQn,
    toleOneQn,
    toleThreeQn,
    toleTwoQn,
    toleSixQn,
    toleSevenQn,
    toleEightQn,
    toleNineQn,
    toleTenQn,
    toleElevenQn,
    toleTwelveQn,
} from "../data/houseSurvey";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
    wardNumber: "",
    name: "",
    toleName: "",
    buildingUse: "",
    streetName: "",
    buildingOwnership: "",
    status: "",
    area: "",
    benefittedHouse: "",
    presidentInfoName: "",
    presidentInfoContact: "",
    uniqueIdentifier: GetUUID(),
};

const CommunityBuilding = (props) => {
    const [selectedWard, setSelectedWard] = useState(0);

    const wardData = [
        {
            question: toleOneQn,
            toleList: toleWardOne,
        },
        {
            question: toleTwoQn,
            toleList: toleWardTwo,
        },
        {
            question: toleThreeQn,
            toleList: toleWardThree,
        },
        {
            question: toleFourQn,
            toleList: toleWardFour,
        },
        {
            question: toleFiveQn,
            toleList: toleWardFive,
        },
        {
            question: toleSixQn,
            toleList: toleWardSix,
        },
        {
            question: toleSevenQn,
            toleList: toleWardSeven,
        },
        {
            question: toleEightQn,
            toleList: toleWardEight,
        },
        {
            question: toleNineQn,
            toleList: toleWardNine,
        },
        {
            question: toleTenQn,
            toleList: toleWardTen,
        },
        {
            question: toleElevenQn,
            toleList: toleWardEleven,
        },
        {
            question: toleTwelveQn,
            toleList: toleWardTwelve,
        },
    ];
    const getToleList = (ward) => {
        if (ward == 1) {
            return toleWardOne;
        } else if (ward == 2) {
            return toleWardTwo;
        } else if (ward == 3) {
            return toleWardThree;
        } else if (ward == 4) {
            return toleWardFour;
        } else if (ward == 5) {
            return toleWardFive;
        } else if (ward == 6) {
            return toleWardSix;
        } else if (ward == 7) {
            return toleWardSeven;
        } else if (ward == 8) {
            return toleWardEight;
        } else if (ward == 9) {
            return toleWardNine;
        } else if (ward == 10) {
            return toleWardTen;
        } else if (ward == 11) {
            return toleWardEleven;
        } else {
            return toleWardTwelve;
        }
    };
    const handleWardChange = (ward) => {
        setSelectedWard(ward);
    };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={props.data ? props.data : initData}
                validate={(values) => {
                    const errors = {};
                    // if (!values.ward) {
                    //     errors.ward = 'Required';
                    // }
                    // if (!values.riskArea) {
                    //     errors.riskArea = 'Required';
                    // }
                    // if (!values.address) {
                    //     errors.address = 'Required';
                    // }
                    // if (!values.effectFromDisasters) {
                    //     errors.effectFromDisasters = 'Required';
                    // }
                    // else
                    // if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    // 	errors.email = "Invalid email address";
                    // }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                    setTimeout(() => {
                        props.submit(values);
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                }) => (
                    <>
                        <form
                            className="form survey-form"
                            onSubmit={handleSubmit}
                        >
                            <Card>
                                <Card.Body>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                सामुदायिक भवनको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="सामुदायिक भवनको नाम"
                                                name="name"
                                                onChange={handleChange}
                                                value={values.name}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="name"
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                {wardQuestion[selectedLanguage]}
                                            </label>
                                            <select
                                                className="form-control"
                                                id="wardNumber"
                                                name="wardNumber"
                                                onChange={handleChange}
                                                handleWardChange={handleWardChange(
                                                    values.wardNumber
                                                )}
                                                value={
                                                    values.wardNumber
                                                        ? values.wardNumber
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                {wardList.map((woda) => (
                                                    <option value={woda.value}>
                                                        {woda[selectedLanguage]}
                                                    </option>
                                                ))}
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="wardNumber"
                                                component="div"
                                            />
                                        </div>

                                        {values.wardNumber && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    {
                                                        wardData[
                                                            values.wardNumber -
                                                                1
                                                        ].question[
                                                            selectedLanguage
                                                        ]
                                                    }
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="toleName"
                                                    name="toleName"
                                                    onChange={handleChange}
                                                    value={
                                                        values.toleName
                                                            ? values.toleName
                                                            : "selectOne"
                                                    }
                                                >
                                                    <option
                                                        value="selectOne"
                                                        disabled
                                                    >
                                                        कुनै एक छान्नुहोस्
                                                    </option>
                                                    {getToleList(
                                                        values.wardNumber
                                                    ).map((woda) => (
                                                        <option
                                                            value={woda.value}
                                                        >
                                                            {
                                                                woda[
                                                                    selectedLanguage
                                                                ]
                                                            }
                                                        </option>
                                                    ))}
                                                </select>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="ward"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                मार्गको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="मार्गको नाम"
                                                name="streetName"
                                                onChange={handleChange}
                                                value={values.streetName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="streetName"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                सामुदायिक भवन के मा प्रयोग
                                                भैरहेको छ ?
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="सामुदायिक भवन के मा प्रयोग भैरहेको छ ?"
                                                name="buildingUse"
                                                onChange={handleChange}
                                                value={values.buildingUse}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="buildingUse"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                भवनको स्वामित्व कस्तो हो ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="own"
                                                    name="buildingOwnership"
                                                    onChange={handleChange}
                                                />
                                                <span></span>निजि
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="rent"
                                                    name="buildingOwnership"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                भाडामा
                                            </label>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="buildingOwnership"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                भवनको अवस्था कस्तो छ ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="best"
                                                    name="status"
                                                    onChange={handleChange}
                                                />
                                                <span></span>राम्रो
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="average"
                                                    name="status"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                ठिकै
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="wrost"
                                                    name="status"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                जिर्ण
                                            </label>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="status"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label>
                                                कति क्षेत्रफलमा बनेको छ ?
                                                (जग्गाको क्षेत्रफल )
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="कति क्षेत्रफलमा बनेको छ ?(जग्गाको क्षेत्रफल )"
                                                name="area"
                                                onChange={handleChange}
                                                value={values.area}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="area"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                यो सामुदायिक भवनबाट कति परिवार
                                                लावान्वित भएका छन ?
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="यो सामुदायिक भवनबाट कति परिवार लावान्वित भएका छन ?"
                                                name="benefittedHouse"
                                                onChange={handleChange}
                                                value={values.benefittedHouse}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="benefittedHouse"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                अध्यक्षको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="अध्यक्षको नाम"
                                                name="presidentInfoName"
                                                onChange={handleChange}
                                                value={values.presidentInfoName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="presidentInfoName"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                अध्यक्षको सम्पर्क नम्बर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="अध्यक्षको सम्पर्क नम्बर"
                                                name="presidentInfoContact"
                                                onChange={handleChange}
                                                value={
                                                    values.presidentInfoContact
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="presidentInfoContact"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                अक्षांश
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lat"
                                                onChange={handleChange}
                                                value={values.geo?.lat}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="lat"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                देशान्तर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lng"
                                                onChange={handleChange}
                                                value={values.geo?.lng}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="lng"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label>
                                                {photo[selectedLanguage]}
                                            </label>
                                            <ImageUploader
                                                fileListContent={
                                                    props.fileListContent
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="card-footer text-right">
                                        <FormFooterButtons />
                                    </div>
                                </Card.Body>
                            </Card>
                        </form>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default CommunityBuilding;
