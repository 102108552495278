import { withStyles } from "@material-ui/core/styles";
// import { ReactLeafletSearch } from "react-leaflet-search";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
// import 'leaflet/dist/leaflet.css';
// const SearchComponent = props => (
// 	<ReactLeafletSearch position="topleft" provider="OpenStreetMap" />
// );
function ChangeMapView({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());

    return null;
}
class LeafletMap extends Component {
    render() {
        const { classes, zoom, lat, lng, addMarker } = this.props;
        const position = [lat || 27.088853575440009, lng || 86.642233893776691];
        console.log(position);
        return (
            <div className={classes.component}>
                <MapContainer
                    center={position}
                    zoom={zoom}
                    onClick={addMarker}
                    style={{ height: "50vh" }}
                >
                    {/* <SearchBar /> */}
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {/* <Marker position={position}>
						<Popup>{this.props.children}</Popup>
					</Marker> */}
                    <Marker position={position}>
                        <Popup>{this.props.children}</Popup>
                    </Marker>
                    <ChangeMapView coords={position} />
                </MapContainer>
            </div>
        );
    }
}

const styles = (theme) => ({
    component: {
        height: "100%",
        width: "100%",
    },
});

LeafletMap.propTypes = {
    classes: PropTypes.object.isRequired,
    isVisible: PropTypes.bool,
};

LeafletMap.defaultProps = {
    isVisible: false,
};

export default withStyles(styles)(LeafletMap);
