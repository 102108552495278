import Table from "rc-table";
import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ExcelExport from "../components/ExcelExport";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";
import { toast } from "react-toastify";
import {
    deleteHouseById,
    getTableData,
} from "../modules/HomeSurvey/homeSurveyCrud";
import { allToleName } from "../data/houseSurvey";
import { selectedLanguage } from "../data/institute";
import { ToleNameFetch } from "../data/helpers/ToleNameFetch";
import Axios from "axios";

const HomeSurveyList = (props) => {
    const { subCatRoute } = props;
    const [columns, setColumns] = useState([]);
    const [sessionWard] = useState(parseInt(sessionStorage.getItem("ward")));
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [wardNumberSearch, setWardNumberSearch] = useState("");
    const [houseNumberSearch, setHouseNumberSearch] = useState("");
    const [fullNameSearch, setFullNameSearch] = useState("");
    const [contactNumberSearch, setContactNumberSearch] = useState("");
    const excelHeaders = [
        {
            label: "वडा न.",
            key: "wardNumber",
        },
        {
            label: "घर न.",
            key: "houseNumber",
        },
        {
            label: "घरमुलीको नाम",
            key: "fullName",
        },
        {
            label: "मोबाइल नम्बर",
            key: "contactNumber",
        },
        {
            label: "Collector code",
            key: "dataCollectionCode",
        },
        {
            label: "पुरुष",
            key: "genderMale",
        },
        {
            label: "महिला",
            key: "genderFemale",
        },
        {
            label: "टोलको नाम",
            key: "toleName",
        },
    ];
    const deleteData = (id) => {
        if (window.confirm("के तपाई यो डाटा हटाउन चाहनुहुन्छ?"))
            deleteHouseById(id).then((res) => {
                if (res.data) {
                    const newData = data.filter((i) => i.house._id !== id);
                    const newFilteredData = filteredData.filter(
                        (i) => i.house._id !== id
                    );
                    const newDisplayData = displayData.filter(
                        (i) => i.house._id !== id
                    );
                    setData(newData);
                    setFilteredData(newFilteredData);
                    setDisplayData(newDisplayData);
                    toast.success("Data deleted successfully");
                }
            });
    };
    useEffect(() => {
        fetHouseList();
    }, []);

    useEffect(() => {}, [filteredData]);
    useEffect(() => {
        console.log(
            wardNumberSearch,
            houseNumberSearch,
            fullNameSearch,
            contactNumberSearch
        );
        // const newFilteredData = data.filter(i => {
        //     const wardAction = i.wardNumber === parseInt(wardNumberSearch);
        //     const houseAction = i.houseNumber?.includes(houseNumberSearch);
        //     const fullNameAction = i.fullName?.includes(fullNameSearch);
        //     const contactNumberAction = i.contactNumber?.includes(contactNumberSearch);
        //     console.log()
        //     return (
        //         wardNumberSearch !== '' ? wardAction : true &&
        //             houseNumberSearch !== '' ? houseAction : true &&
        //                 fullNameSearch !== '' ? fullNameAction : true &&
        //                     contactNumberSearch !== '' ? contactNumberAction : true
        //     )
        // })
        let newFilteredData = data;
        if (wardNumberSearch !== "") {
            newFilteredData = newFilteredData.filter(
                (i) =>
                    i.wardNumber && i.wardNumber === parseInt(wardNumberSearch)
            );
        }
        if (houseNumberSearch !== "") {
            newFilteredData = newFilteredData.filter(
                (i) =>
                    i.houseNumber && i.houseNumber.startsWith(houseNumberSearch)
            );
        }
        if (fullNameSearch !== "") {
            newFilteredData = newFilteredData.filter(
                (i) => i.fullName && i.fullName.includes(fullNameSearch)
            );
        }
        if (contactNumberSearch !== "") {
            newFilteredData = newFilteredData.filter((i) => {
                return (
                    i.contactNumber &&
                    i.contactNumber.includes(contactNumberSearch)
                );
            });
        }
        const pages = Math.ceil(newFilteredData.length / 10);
        setTotalPages(pages);
        setFilteredData(newFilteredData);
        const dispData = newFilteredData.slice(0, 10);
        setDisplayData(dispData);
    }, [
        wardNumberSearch,
        houseNumberSearch,
        fullNameSearch,
        contactNumberSearch,
    ]);
    const search = (e) => {
        switch (e.target.name) {
            case "wardNumber": {
                setWardNumberSearch(e.target.value);
                break;
            }
            case "houseNumber": {
                setHouseNumberSearch(e.target.value);
                break;
            }
            case "fullName": {
                setFullNameSearch(e.target.value);
                break;
            }
            case "contactNumber": {
                setContactNumberSearch(e.target.value);
                break;
            }
            default:
                break;
        }
    };
    const fetHouseList = () => {
        setColumns([
            {
                title: "क्र.श",
                dataIndex: "sNo",
                key: "sNo",
                width: null,
            },
            {
                title: (
                    <div class="form-group table-filter">
                        <input
                            type="text"
                            className="form-control"
                            name="wardNumber"
                            onChange={search}
                            placeholder="वडा न."
                        />
                    </div>
                ),
                dataIndex: "wardNumber",
                key: "wardNumber",
                width: null,
            },
            {
                title: (
                    <div className="form-group table-filter">
                        <input
                            type="text"
                            className="form-control"
                            name="houseNumber"
                            onChange={search}
                            placeholder="घर न."
                        />
                    </div>
                ),
                dataIndex: "houseNumber",
                key: "houseNumber",
                width: null,
            },
            {
                title: (
                    <div className="form-group table-filter">
                        <input
                            type="text"
                            className="form-control"
                            name="fullName"
                            onChange={search}
                            placeholder="घरमुलीको नाम"
                        />
                    </div>
                ),
                dataIndex: "fullName",
                key: "fullName",
                width: null,
            },
            {
                title: (
                    <div className="form-group table-filter">
                        <input
                            type="text"
                            className="form-control"
                            name="contactNumber"
                            onChange={search}
                            placeholder="मोबाइल नम्बर"
                        />
                    </div>
                ),
                dataIndex: "contactNumber",
                key: "contactNumber",
                width: null,
            },
            {
                title: "Collector code",
                dataIndex: "dataCollectionCode",
                key: "dataCollectionCode",
                width: null,
            },
            {
                title: "पुरुष",
                dataIndex: "maleNumber",
                key: "maleNumber",
                width: null,
            },
            {
                title: "महिला",
                dataIndex: "femaleNumber",
                key: "femaleNumber",
                width: null,
            },
            {
                title: "टोलको नाम",
                dataIndex: "toleName",
                key: "toleName",
            },
            ,
            {
                title: "",
                dataIndex: "",
                key: "d",
                render: function(val) {
                    return (
                        <div className="d-flex table-actions">
                            <NavLink
                                className="menu-link menu-toggle"
                                target="_blank"
                                to={`/home-survey/detail/${val.house._id}`}
                            >
                                <i className="flaticon-eye mr-3 mb-3"></i>
                            </NavLink>

                            <NavLink
                                className="menu-link menu-toggle"
                                to={`/home-survey/form/${val.house._id}`}
                            >
                                <i className="flaticon-edit mr-3 mb-3"></i>
                            </NavLink>
                            <i
                                className="flaticon-delete"
                                onClick={(e) => deleteData(val._id)}
                            ></i>
                        </div>
                    );
                },
            },
        ]);
        const ward = parseInt(sessionStorage.getItem("ward") || 1);
        getTableData(ward)
            .then((res) => {
                // const csvData = this.getCSVData(res.data);
                if (res.data.data) {
                    setLoading(false);
                    const { title, data } = res.data;
                    const newData = res.data.data.map((datum, i) => {
                        return {
                            ...datum,
                            sNo: i + 1,
                            wardNumber: datum.house.wardNumber,
                            houseNumber: datum.house.houseNumber,
                            fullName: datum.fullName,
                            contactNumber: datum.contactNumber,
                            dataCollectionCode: datum.house.dataCollectionCode,
                            maleNumber: datum.house.genderMale,
                            femaleNumber: datum.house.genderFemale,
                            toleName: ToleNameFetch(datum.house.toleName),
                        };
                    });
                    const pages = Math.ceil(data.length / 10);
                    setTotalPages(pages);
                    setData(newData);
                    setFilteredData(newData);
                    const dispData = newData.slice(0, 10);
                    setDisplayData(dispData);
                }
            })
            .catch((err) => {
                setLoading(false);
            });

        // getTableData()
        // 	.then((res) => {
        // 		console.log(res);
        // 		if (res.data.data) {
        // 			setLoading(false);
        // 			const { title, data } = res.data;
        // 			console.log(res.data);
        // 			const newData = res.data.data.map((datum, i) => {
        // 				return {
        // 					...datum,
        // 					sNo: i + 1,
        // 					wardNumber: datum.house.wardNumber,
        // 					houseNumber: datum.house.houseNumber,
        // 					dataCollectionCode: datum.house.dataCollectionCode,
        // 					genderMale: datum.house.genderMale,
        // 					genderFemale: datum.house.genderFemale,
        // 					toleName: ToleNameFetch(datum.house.toleName),
        // 				};
        // 			});
        // 			const pages = Math.ceil(data.length / 10);
        // 			setTotalPages(pages);
        // 			setData(newData);
        // 			setFilteredData(newData);
        // 			const dispData = newData.slice(0, 10);
        // 			setDisplayData(dispData);
        // 		}
        // 	})
        // .catch((err) => {
        // 	setLoading(false);
        // });
    };
    const handlePageChange = (val) => {
        const start = val * 10;
        const end = start + 10;
        const dispData = filteredData.slice(start, end);
        setDisplayData(dispData);
    };
    const report = () => {
        document.getElementById("exportElement").click();
    };
    return (
        <>
            <Card style={{ width: "100%", minHeight: "80vh" }}>
                <Card.Body>
                    <Card.Title>
                        <h1 className="mb-10">घर सर्वेक्षण</h1>
                    </Card.Title>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end align-items-center">
                            <h5 className="mr-3">
                                कुल घर :
                                <span className="text-primary">
                                    {data.length}
                                </span>
                            </h5>
                            {/* <h5 className="mr-3">
								कुल घर :<span className="text-primary">३१८५२</span>
							</h5> */}
                            {filteredData.length !== 0 && (
                                <>
                                    <Button
                                        variant="light-success"
                                        className="mr-3"
                                        onClick={report}
                                    >
                                        डाउनलोडगर्नुहोस् XLS
                                    </Button>
                                    <ExcelExport
                                        headers={excelHeaders}
                                        data={filteredData}
                                    />
                                </>
                            )}
                            <NavLink
                                className="menu-link menu-toggle"
                                to={`/home-survey/form`}
                            >
                                <Button variant="light-primary">
                                    <i
                                        style={{
                                            position: "relative",
                                            bottom: "1px",
                                        }}
                                        className="flaticon2-add-1 icon-nm"
                                    ></i>
                                    थप्नुहोस
                                </Button>
                            </NavLink>
                        </div>
                    </div>
                    {loading && <Loader loading={loading} />}
                    {!loading && (
                        <>
                            <Table
                                className="table table-hover"
                                columns={columns}
                                data={displayData}
                                data-filter-control="true"
                            />
                            <Pagination
                                pages={totalPages}
                                handlePageChange={handlePageChange}
                            />
                        </>
                    )}
                </Card.Body>
            </Card>
        </>
    );
};

export default HomeSurveyList;
