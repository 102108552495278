import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import {
    wardList,
    toleWardOne,
    toleWardTwo,
    toleWardThree,
    toleWardFour,
    toleWardFive,
    toleWardSix,
    toleWardSeven,
    toleWardEight,
    toleWardTen,
    toleWardNine,
    toleWardEleven,
    toleWardTwelve,
} from "../data/ToleList";
import {
    toleFiveQn,
    toleFourQn,
    toleOneQn,
    toleThreeQn,
    toleTwoQn,
    toleSixQn,
    toleSevenQn,
    toleEightQn,
    toleNineQn,
    toleTenQn,
    toleElevenQn,
    toleTwelveQn,
} from "../data/houseSurvey";
import {
    ward,
    businessTypeList,
    wardQuestion,
    selectedLanguage,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";

const Business = (props) => {
    const [selectedWard, setSelectedWard] = useState(0);

    const wardData = [
        {
            question: toleOneQn,
            toleList: toleWardOne,
        },
        {
            question: toleTwoQn,
            toleList: toleWardTwo,
        },
        {
            question: toleThreeQn,
            toleList: toleWardThree,
        },
        {
            question: toleFourQn,
            toleList: toleWardFour,
        },
        {
            question: toleFiveQn,
            toleList: toleWardFive,
        },
        {
            question: toleSixQn,
            toleList: toleWardSix,
        },
        {
            question: toleSevenQn,
            toleList: toleWardSeven,
        },
        {
            question: toleEightQn,
            toleList: toleWardEight,
        },
        {
            question: toleNineQn,
            toleList: toleWardNine,
        },
        {
            question: toleTenQn,
            toleList: toleWardTen,
        },
        {
            question: toleElevenQn,
            toleList: toleWardEleven,
        },
        {
            question: toleTwelveQn,
            toleList: toleWardTwelve,
        },
    ];
    const getToleList = (ward) => {
        if (ward == 1) {
            return toleWardOne;
        } else if (ward == 2) {
            return toleWardTwo;
        } else if (ward == 3) {
            return toleWardThree;
        } else if (ward == 4) {
            return toleWardFour;
        } else if (ward == 5) {
            return toleWardFive;
        } else if (ward == 6) {
            return toleWardSix;
        } else if (ward == 7) {
            return toleWardSeven;
        } else if (ward == 8) {
            return toleWardEight;
        } else if (ward == 9) {
            return toleWardNine;
        } else if (ward == 10) {
            return toleWardTen;
        } else if (ward == 11) {
            return toleWardEleven;
        } else {
            return toleWardTwelve;
        }
    };
    const handleWardChange = (ward) => {
        setSelectedWard(ward);
    };
    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={
                    props.data
                        ? props.data
                        : {
                              name: "",
                              wardNumber: "",
                              toleName: "",
                              streetName: "",
                              businessType: "",
                              farmType: "",
                              manufacturedGoods: "",
                              yearlyProduction: "",
                              productionAnimals: "",
                              proprietorInfoName: "",
                              proprietorInfoContact: "",
                              email: "",
                              citizenshipNumber: "",
                              fatherName: "",
                              grandFatherName: "",
                              permanentAddress: "",
                              privateOrRent: "",
                              rentOwnerName: "",
                              rentOwnerContact: "",
                              monthlyRent: "",
                              hasRentAgreement: "",
                              businessPlaceArea: "",
                              establishedYear: "",
                              hasRegisteredBusiness: "",
                              businessRegisterInfoType: "",
                              taxOfficeDartaNumber: "",
                              isBusinessInVat: "",
                              vatNumber: "",
                              domesticeDartaNumber: "",
                              companyRegistrarDartaNumber: "",
                              industryDartaNumber: "",
                              municipalityDartaNumber: "",
                              panNumber: "",
                              hasSocialSecurityFund: "",
                              isBusinessRenewed: "",
                              businessCapitalAmount: "",
                              isMulSuchi: "",
                              hasBusinessBoard: "",
                              businessBoardSize: "",
                              hasEmployee: "",
                              employeeCount: "",
                              femaleCount: "",
                              maleCount: "",
                              isEmployeeFromIndia: "",
                              yearlySalaryExpense: "",
                              hasInsurance: "",
                          }
                }
                validate={(values) => {
                    const errors = {};
                    // if (!values.name) {
                    //     errors.name = 'Required';
                    // }
                    // else
                    // if (
                    //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    // ) {
                    //     errors.email = 'Invalid email address';
                    // }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                    setTimeout(() => {
                        props.submit(values);
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                }) => (
                    <>
                        <form
                            className="form survey-form"
                            onSubmit={handleSubmit}
                        >
                            <Card>
                                <Card.Body>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                व्यावसायको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="व्यावसायको नाम"
                                                name="name"
                                                onChange={handleChange}
                                                value={values.name}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="name"
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                {wardQuestion[selectedLanguage]}
                                            </label>
                                            <select
                                                className="form-control"
                                                id="wardNumber"
                                                name="wardNumber"
                                                onChange={handleChange}
                                                handleWardChange={handleWardChange(
                                                    values.wardNumber
                                                )}
                                                value={
                                                    values.wardNumber
                                                        ? values.wardNumber
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                {wardList.map((woda) => (
                                                    <option value={woda.value}>
                                                        {woda[selectedLanguage]}
                                                    </option>
                                                ))}
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="wardNumber"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        {values.wardNumber && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    {
                                                        wardData[
                                                            values.wardNumber -
                                                                1
                                                        ].question[
                                                            selectedLanguage
                                                        ]
                                                    }
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="toleName"
                                                    name="toleName"
                                                    onChange={handleChange}
                                                    value={
                                                        values.toleName
                                                            ? values.toleName
                                                            : "selectOne"
                                                    }
                                                >
                                                    <option
                                                        value="selectOne"
                                                        disabled
                                                    >
                                                        कुनै एक छान्नुहोस्
                                                    </option>
                                                    {getToleList(
                                                        values.wardNumber
                                                    ).map((woda) => (
                                                        <option
                                                            value={woda.value}
                                                        >
                                                            {
                                                                woda[
                                                                    selectedLanguage
                                                                ]
                                                            }
                                                        </option>
                                                    ))}
                                                </select>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="toleName"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                मार्गको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="मार्गको नाम"
                                                name="streetName"
                                                onChange={handleChange}
                                                value={values.streetName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="streetName"
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                व्यावसायको प्रकार
                                            </label>
                                            <select
                                                className="form-control"
                                                id="businessType"
                                                name="businessType"
                                                onChange={handleChange}
                                                multiple={true}
                                                value={
                                                    values.businessType
                                                        ? values.businessType
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                {businessTypeList.map(
                                                    (type) => (
                                                        <option
                                                            value={type.value}
                                                        >
                                                            {
                                                                type[
                                                                    selectedLanguage
                                                                ]
                                                            }
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="businessType"
                                                component="div"
                                            />
                                        </div>

                                        {values.businessType == "others" && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    अन्य भए खुलाउनुहोस
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="अन्य भए खुलाउनुहोस"
                                                    name="businessTypeOther"
                                                    onChange={handleChange}
                                                    value={
                                                        values.businessTypeOther
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="businessTypeOther"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                    </div>

                                    {values.businessType == "arg_fam" && (
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    फर्मको किसिम
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="farmType"
                                                    name="farmType"
                                                    onChange={handleChange}
                                                    value={values?.farmType}
                                                    multiple={true}
                                                >
                                                    <option value="agrilocal_farm">
                                                        कृषि
                                                    </option>
                                                    <option value="animallocal_farm">
                                                        पशु
                                                    </option>
                                                    <option value="fishlocal_farm">
                                                        मत्स्यपालन (माछा पानल )
                                                    </option>
                                                </select>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="farmType"
                                                    component="div"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {values.farmType == "agrilocal_farm" && (
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    वार्षिक उत्पादन कृषि (
                                                    उत्पादित वस्तु )
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="manufacturedGoods"
                                                    name="manufacturedGoods"
                                                    onChange={handleChange}
                                                    value={
                                                        values?.manufacturedGoods
                                                    }
                                                    multiple={true}
                                                >
                                                    <option value="tarkari">
                                                        तरकारी
                                                    </option>
                                                    <option value="falfull">
                                                        फलफुल
                                                    </option>
                                                    <option value="others_agrilocal">
                                                        अन्य
                                                    </option>
                                                </select>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="manufacturedGoods"
                                                    component="div"
                                                />
                                            </div>

                                            {values.manufacturedGoods ==
                                                "others_agrilocal" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        अन्य भए उल्लेख गर्नुहोस्
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="अन्य भए उल्लेख गर्नुहोस्"
                                                        name="manufacturedGoodsOther"
                                                        onChange={handleChange}
                                                        value={
                                                            values.manufacturedGoodsOther
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="manufacturedGoodsOther"
                                                        component="div"
                                                    />
                                                </div>
                                            )}

                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    वार्षिक कति उत्पादन हुन्छ ?
                                                    (के.जी. मा )
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="वार्षिक कति उत्पादन हुन्छ ? (के.जी. मा )"
                                                    name="yearlyProduction"
                                                    onChange={handleChange}
                                                    value={
                                                        values.yearlyProduction
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="yearlyProduction"
                                                    component="div"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {values.farmType == "animallocal_farm" && (
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    वार्षिक उत्पादन पशु (
                                                    उत्पादित वस्तु )
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="productionAnimals"
                                                    name="productionAnimals"
                                                    onChange={handleChange}
                                                    value={
                                                        values?.productionAnimals
                                                    }
                                                    multiple={true}
                                                >
                                                    <option value="cow">
                                                        गाई
                                                    </option>
                                                    <option value="bufallo">
                                                        भैसी
                                                    </option>
                                                    <option value="goat">
                                                        बाख्रा / खसी /बोका
                                                    </option>
                                                    <option value="pig">
                                                        बंगुर /सुँगुर
                                                    </option>
                                                    <option value="hen">
                                                        कुखुरा
                                                    </option>
                                                    <option value="hans">
                                                        हाँस
                                                    </option>
                                                    <option value="other_animal_local">
                                                        अन्य
                                                    </option>
                                                </select>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="productionAnimals"
                                                    component="div"
                                                />
                                            </div>

                                            {values.productionAnimals ==
                                                "other_animal_local" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        अन्य भए उल्लेख गर्नुहोस्
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="अन्य भए उल्लेख गर्नुहोस्"
                                                        name="productionAnimalsOther"
                                                        onChange={handleChange}
                                                        value={
                                                            values.productionAnimalsOther
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="productionAnimalsOther"
                                                        component="div"
                                                    />
                                                </div>
                                            )}

                                            {values.productionAnimals ==
                                                "cow" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        गाईको संख्या
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="गाईको संख्या"
                                                        name="cowCount"
                                                        onChange={handleChange}
                                                        value={values.cowCount}
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="cowCount"
                                                        component="div"
                                                    />
                                                </div>
                                            )}

                                            {values.productionAnimals ==
                                                "bufallo" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        भैसीको संख्या
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="भैसीको संख्या"
                                                        name="buffalloCount"
                                                        onChange={handleChange}
                                                        value={
                                                            values.buffalloCount
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="buffalloCount"
                                                        component="div"
                                                    />
                                                </div>
                                            )}

                                            {values.productionAnimals ==
                                                "goat" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        बाख्रा/खसी/बोकाको संख्या
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="बाख्रा/खसी/बोकाको संख्या"
                                                        name="goatCount"
                                                        onChange={handleChange}
                                                        value={values.goatCount}
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="goatCount"
                                                        component="div"
                                                    />
                                                </div>
                                            )}

                                            {values.productionAnimals ==
                                                "pig" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        बंगुर/सुँगुरको संख्या
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="बंगुर/सुँगुरको संख्या"
                                                        name="pigCount"
                                                        onChange={handleChange}
                                                        value={values.pigCount}
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="pigCount"
                                                        component="div"
                                                    />
                                                </div>
                                            )}

                                            {values.productionAnimals ==
                                                "hen" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        कुखुराको संख्या
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="कुखुराको संख्या"
                                                        name="chickenCount"
                                                        onChange={handleChange}
                                                        value={
                                                            values.chickenCount
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="chickenCount"
                                                        component="div"
                                                    />
                                                </div>
                                            )}

                                            {values.productionAnimals ==
                                                "hans" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        हाँसको संख्या
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="हाँसको संख्या"
                                                        name="duckCount"
                                                        onChange={handleChange}
                                                        value={values.duckCount}
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="duckCount"
                                                        component="div"
                                                    />
                                                </div>
                                            )}

                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    वार्षिक कति उत्पादन हुन्छ ?
                                                    (के.जी. मा )
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="वार्षिक कति उत्पादन हुन्छ ? (के.जी. मा )"
                                                    name="yearlyProduction"
                                                    onChange={handleChange}
                                                    value={
                                                        values.yearlyProduction
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="yearlyProduction"
                                                    component="div"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {values.farmType == "fishlocal_farm" && (
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    वार्षिक कति उत्पादन हुन्छ ?
                                                    (के.जी. मा )
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="वार्षिक कति उत्पादन हुन्छ ? (के.जी. मा )"
                                                    name="yearlyProduction"
                                                    onChange={handleChange}
                                                    value={
                                                        values.yearlyProduction
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="yearlyProduction"
                                                    component="div"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                प्रोपराइटरको नाम थर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="प्रोपराइटरको नाम थर"
                                                name="proprietorInfoName"
                                                onChange={handleChange}
                                                value={
                                                    values.proprietorInfoName
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="proprietorInfoName"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                प्रोपराइटरको सम्पर्क नम्बर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="प्रोपराइटरको सम्पर्क नम्बर"
                                                name="proprietorInfoContact"
                                                onChange={handleChange}
                                                value={
                                                    values.proprietorInfoContact
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="proprietorInfoContact"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                सव्यावसयको email ठेगाना
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="सव्यावसयको email ठेगाना"
                                                name="email"
                                                onChange={handleChange}
                                                value={values.email}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="email"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                प्रोपराइटरको नागरिकता नम्बर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="प्रोपराइटरको नागरिकता नम्बर"
                                                name="citizenshipNumber"
                                                onChange={handleChange}
                                                value={values.citizenshipNumber}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="citizenshipNumber"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                बावुको नाम थर (बुबाको नाम )
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="बावुको नाम थर (बुबाको नाम )"
                                                name="fatherName"
                                                onChange={handleChange}
                                                value={values.fatherName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="fatherName"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                बाजेको नाम थर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="बाजेको नाम थर"
                                                name="grandFatherName"
                                                onChange={handleChange}
                                                value={values.grandFatherName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="grandFatherName"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                स्थाई ठेगाना कहाँ हो ?
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="स्थाई ठेगाना कहाँ हो ?"
                                                name="permanentAddress"
                                                onChange={handleChange}
                                                value={values.permanentAddress}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="permanentAddress"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                व्यावसाय गरेको घर /सटर वा जग्गा
                                                निजि हो वा भाडामा हो ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="own"
                                                    name="privateOrRent"
                                                    onChange={handleChange}
                                                />
                                                <span></span>निजि
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="bhadam"
                                                    name="privateOrRent"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                भाडामा
                                            </label>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="privateOrRent"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    {values.privateOrRent == "bhadam" && (
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    भाडामा भए घरधनीको नाम थर
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="भाडामा भए घरधनीको नाम थर"
                                                    name="rentOwnerName"
                                                    onChange={handleChange}
                                                    value={values.rentOwnerName}
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="rentOwnerName"
                                                    component="div"
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    भाडामा भए घर /जग्गा धनिको
                                                    सम्पर्क नम्बर
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="भाडामा भए घर /जग्गा धनिको सम्पर्क नम्बर"
                                                    name="rentOwnerContact"
                                                    onChange={handleChange}
                                                    value={
                                                        values.rentOwnerContact
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="rentOwnerContact"
                                                    component="div"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {values.privateOrRent == "bhadam" && (
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    मासिक भाडा दर (रु. मा उल्लेख
                                                    गर्नुहोस् )
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="मासिक भाडा दर (रु. मा उल्लेख गर्नुहोस् )"
                                                    name="monthlyRent"
                                                    onChange={handleChange}
                                                    value={values.monthlyRent}
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="monthlyRent"
                                                    component="div"
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    भाडा सम्झौता गर्नु भएको छ कि
                                                    छैन ?
                                                </label>
                                                <label class="radio radio-rounded">
                                                    <input
                                                        type="radio"
                                                        value="yes"
                                                        name="hasRentAgreement"
                                                        onChange={handleChange}
                                                    />
                                                    <span></span>छ
                                                </label>
                                                <label class="radio radio-rounded">
                                                    <input
                                                        type="radio"
                                                        value="no"
                                                        name="hasRentAgreement"
                                                        onChange={handleChange}
                                                    />
                                                    <span></span>
                                                    छैन
                                                </label>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="hasRentAgreement"
                                                    component="div"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                कोठा वा व्यावसाय रहेको ठाउँको
                                                क्षेत्रफल (वर्ग मिटरमा उल्लेख
                                                गर्नुहोस् )
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="कोठा वा व्यावसाय रहेको ठाउँको क्षेत्रफल (वर्ग मिटरमा उल्लेख गर्नुहोस् )"
                                                name="businessPlaceArea"
                                                onChange={handleChange}
                                                value={values.businessPlaceArea}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="businessPlaceArea"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                व्यावसाय सँचालन गरेको वर्ष
                                                (सालमा - २०७५ )
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="व्यावसाय सँचालन गरेको वर्ष (सालमा - २०७५ )"
                                                name="establishedYear"
                                                onChange={handleChange}
                                                value={values.establishedYear}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="establishedYear"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                व्यावसाय दर्ता गर्नुभएको छ कि
                                                छैन ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="hasRegisteredBusiness"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="hasRegisteredBusiness"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="hasRegisteredBusiness"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    {values.hasRegisteredBusiness == "yes" && (
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    प्रकार
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="businessRegisterInfoType"
                                                    name="businessRegisterInfoType"
                                                    onChange={handleChange}
                                                    value={
                                                        values?.businessRegisterInfoType
                                                    }
                                                    multiple={true}
                                                >
                                                    <option value="tax_office">
                                                        कर कार्यालय
                                                    </option>
                                                    <option value="gharelu">
                                                        घरेलु
                                                    </option>
                                                    <option value="company_registar">
                                                        कम्पनी रजिष्‍ट्रारको
                                                        कार्यालय
                                                    </option>
                                                    <option value="udhyog_banijya">
                                                        उधोग वाणिज्य संघ
                                                    </option>
                                                    <option value="nagarpalika">
                                                        नगरपालिका
                                                    </option>
                                                </select>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="businessRegisterInfoType"
                                                    component="div"
                                                />
                                            </div>

                                            {values.businessRegisterInfoType ==
                                                "tax_office" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        कर कार्यालयमा दर्ता
                                                        गरेको भए दर्ता नम्बर
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="कर कार्यालयमा  दर्ता गरेको भए दर्ता नम्बर"
                                                        name="taxOfficeDartaNumber"
                                                        onChange={handleChange}
                                                        value={
                                                            values.taxOfficeDartaNumber
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="taxOfficeDartaNumber"
                                                        component="div"
                                                    />
                                                </div>
                                            )}
                                            {values.businessRegisterInfoType ==
                                                "tax_office" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        तपाइँको व्यावसायको
                                                        भ्याटमा दर्ता भएको छ कि
                                                        छैन ?
                                                    </label>
                                                    <label class="radio radio-rounded">
                                                        <input
                                                            type="radio"
                                                            value="yes"
                                                            name="isBusinessInVat"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span></span>छ
                                                    </label>
                                                    <label class="radio radio-rounded">
                                                        <input
                                                            type="radio"
                                                            value="no"
                                                            name="isBusinessInVat"
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                        <span></span>
                                                        छैन
                                                    </label>
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="isBusinessInVat"
                                                        component="div"
                                                    />
                                                </div>
                                            )}
                                            {values.isBusinessInVat ==
                                                "yes" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        व्यावसायको भ्याट नम्बर
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="घव्यावसायको भ्याट नम्बर"
                                                        name="vatNumber"
                                                        onChange={handleChange}
                                                        value={values.vatNumber}
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="vatNumber"
                                                        component="div"
                                                    />
                                                </div>
                                            )}
                                            {values.businessRegisterInfoType ==
                                                "gharelu" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        घरेलुमा दर्ता गरेको भए
                                                        दर्ता नम्बर
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="घरेलुमा दर्ता गरेको भए दर्ता नम्बर"
                                                        name="domesticeDartaNumber"
                                                        onChange={handleChange}
                                                        value={
                                                            values.domesticeDartaNumber
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="domesticeDartaNumber"
                                                        component="div"
                                                    />
                                                </div>
                                            )}

                                            {values.businessRegisterInfoType ==
                                                "company_registar" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        कम्पनी रजिष्‍ट्रारको
                                                        कार्यालयमा दर्ता भए
                                                        दर्ता नम्बर
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="कम्पनी रजिष्‍ट्रारको कार्यालयमा दर्ता भए दर्ता नम्बर "
                                                        name="companyRegistrarDartaNumber"
                                                        onChange={handleChange}
                                                        value={
                                                            values.companyRegistrarDartaNumber
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="companyRegistrarDartaNumber"
                                                        component="div"
                                                    />
                                                </div>
                                            )}

                                            {values.businessRegisterInfoType ==
                                                "udhyog_banijya" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        उधोग वाणिज्य महासंघमा
                                                        दर्ता भए दर्ता नम्बर
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="उधोग वाणिज्य महासंघमा दर्ता भए दर्ता नम्बर"
                                                        name="industryDartaNumber"
                                                        onChange={handleChange}
                                                        value={
                                                            values.industryDartaNumber
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="industryDartaNumber"
                                                        component="div"
                                                    />
                                                </div>
                                            )}

                                            {values.businessRegisterInfoType ==
                                                "nagarpalika" && (
                                                <div className="col-md-6">
                                                    <label className="form-control-label">
                                                        नगरपालिकामा दर्ता भए
                                                        दर्ता नम्बर
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="नगरपालिकामा दर्ता भए दर्ता नम्बर "
                                                        name="municipalityDartaNumber"
                                                        onChange={handleChange}
                                                        value={
                                                            values.municipalityDartaNumber
                                                        }
                                                    />
                                                    <ErrorMessage
                                                        className="invalid-feedback"
                                                        name="municipalityDartaNumber"
                                                        component="div"
                                                    />
                                                </div>
                                            )}

                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    व्यावसायको प्यान नम्बर
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="व्व्यावसायको प्यान नम्बर"
                                                    name="panNumber"
                                                    onChange={handleChange}
                                                    value={values.panNumber}
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="panNumber"
                                                    component="div"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                सामाजिक सुरक्षा कोषमा दर्ता
                                                हुनुहुन्छा कि हुनुहुन्न ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="hasSocialSecurityFund"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="hasSocialSecurityFund"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="hasSocialSecurityFund"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                व्यावसाय नवीकरण गर्नु भएको छ ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="isBusinessRenewed"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="isBusinessRenewed"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="isBusinessRenewed"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                तपाइँको व्यावसाय कति पुजि रकमको
                                                हो ? (रु. मा उल्लेख गर्नुहोस् )
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="तपाइँको व्यावसाय कति पुजि रकमको हो ? (रु. मा उल्लेख गर्नुहोस् )"
                                                name="businessCapitalAmount"
                                                onChange={handleChange}
                                                value={
                                                    values.businessCapitalAmount
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="businessCapitalAmount"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                मूल्य सुची राखेको छ कि छैन ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="isMulSuchi"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="isMulSuchi"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="isMulSuchi"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                व्यावसायको विज्ञापन वोर्ड छ कि
                                                छैन ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="hasBusinessBoard"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="hasBusinessBoard"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="hasBusinessBoard"
                                                component="div"
                                            />
                                        </div>

                                        {values.hasBusinessBoard == "yes" && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    व्यावसायको विज्ञापन बोर्डको
                                                    साइज कति हो ? (वर्ग फिटमा
                                                    उल्लेख गर्नुहोस् )
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="व्यावसायको विज्ञापन बोर्डको साइज कति हो ? (वर्ग फिटमा उल्लेख गर्नुहोस् )"
                                                    name="businessBoardSize"
                                                    onChange={handleChange}
                                                    value={
                                                        values.businessBoardSize
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="businessBoardSize"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                तपाइँको व्यावसयमा कर्मचारी
                                                राख्नु भएको छ ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="hasEmployee"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="hasEmployee"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="hasEmployee"
                                                component="div"
                                            />
                                        </div>

                                        {values.hasEmployee == "yes" && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    छ भने कति जना कर्मचारी
                                                    राख्नु भएको छ ?
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="छ भने कति जना कर्मचारी राख्नु भएको छ ?"
                                                    name="employeeCount"
                                                    onChange={handleChange}
                                                    value={values.employeeCount}
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="employeeCount"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                        {values.employeeCount && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    महिला सङ्ख्या
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="महिला सङ्ख्या"
                                                    name="femaleCount"
                                                    onChange={handleChange}
                                                    value={values.femaleCount}
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="femaleCount"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                        {values.employeeCount && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    पुरुष सङ्ख्या
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="पुरुष सङ्ख्या"
                                                    name="maleCount"
                                                    onChange={handleChange}
                                                    value={values.maleCount}
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="maleCount"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                        {values.hasEmployee == "yes" && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    तपाइको व्यवसयमा कोहि विदेशी
                                                    (भारतिय ) कामदार छन् ?
                                                </label>
                                                <label class="radio radio-rounded">
                                                    <input
                                                        type="radio"
                                                        value="yes"
                                                        name="isEmployeeFromIndia"
                                                        onChange={handleChange}
                                                    />
                                                    <span></span>छ
                                                </label>
                                                <label class="radio radio-rounded">
                                                    <input
                                                        type="radio"
                                                        value="no"
                                                        name="isEmployeeFromIndia"
                                                        onChange={handleChange}
                                                    />
                                                    <span></span>
                                                    छैन
                                                </label>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="isEmployeeFromIndia"
                                                    component="div"
                                                />
                                            </div>
                                        )}

                                        {values.hasEmployee == "yes" && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    कर्मचारीको औसत तलबमा कति
                                                    खर्च हुन्छ ? (वार्षिक) (रु.
                                                    हजार,मा )
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="कर्मचारीको औसत तलबमा कति खर्च हुन्छ ? (वार्षिक) (रु. हजार,मा )"
                                                    name="yearlySalaryExpense"
                                                    onChange={handleChange}
                                                    value={
                                                        values.yearlySalaryExpense
                                                    }
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="yearlySalaryExpense"
                                                    component="div"
                                                />
                                            </div>
                                        )}

                                        {values.hasEmployee == "yes" && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    कर्मचारीको बीमा गर्नु भएको छ
                                                    कि छैन ?
                                                </label>
                                                <label class="radio radio-rounded">
                                                    <input
                                                        type="radio"
                                                        value="yes_all"
                                                        name="hasInsurance"
                                                        onChange={handleChange}
                                                    />
                                                    <span></span>सबै कर्मचारीको
                                                    वीमा गरेको
                                                </label>
                                                <label class="radio radio-rounded">
                                                    <input
                                                        type="radio"
                                                        value="some_kar"
                                                        name="hasInsurance"
                                                        onChange={handleChange}
                                                    />
                                                    <span></span>
                                                    केहि कर्मचारिको बीमा गरेको
                                                </label>
                                                <label class="radio radio-rounded">
                                                    <input
                                                        type="radio"
                                                        value="no"
                                                        name="hasInsurance"
                                                        onChange={handleChange}
                                                    />
                                                    <span></span>
                                                    कर्मचारिको बीमा नगरेको
                                                </label>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="hasInsurance"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                अक्षांश
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lat"
                                                onChange={handleChange}
                                                value={values.geo?.lat}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="lat"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                देशान्तर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lng"
                                                onChange={handleChange}
                                                value={values.geo?.lng}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="lng"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label>फोटो</label>
                                            <ImageUploader
                                                fileListContent={
                                                    props.fileListContent
                                                }
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="card-footer text-right">
                                        <FormFooterButtons />
                                    </div>
                                </Card.Body>
                            </Card>
                        </form>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default Business;
