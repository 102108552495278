const wayToHospitalQuestion = {
    nepali: "तपाईँको घरबाट नजिकको स्वास्थ्य संस्थामा पुग्न कति समय लाग्छ ?",
    english: "",
};

const wayToHospitalAns = [
    {
        nepali: "पैदल",
        english: "byhealthpost",
        value: "by_foot_healthpost",
    },
    {
        nepali: "सवारीसाधन",
        english: "byvechile",
        value: "by_vehicle_healthpost",
    },
];

const basicSchoolQuestion = {
    nepali: "तपाईँको घरबाट नजिकको आधारभूत विद्यालयसम्म पुग्न कति समय लाग्छ ?",
    english: "",
};

const basicSchoolOption = [
    {
        nepali: "पैदल",
        english: "byfoot",
        value: "by_foot_adharbhut",
    },
    {
        nepali: "सवारीसाधन",
        english: "byvechile",
        value: "by_vehicle_adharbhut",
    },
];

const secondarySchoolQuestion = {
    nepali: "तपाईँको घरबाट नजिकको माध्यमिक विद्यालयसम्म पुग्न कति समय लाग्छ ?",
    english: "",
};

const secondarySchoolOption = [
    {
        nepali: "पैदल",
        english: "byfoot",
        value: "by_foot_madhyamik",
    },
    {
        nepali: "सवारीसाधन",
        english: "byvechile",
        value: "by_vehicle_madhyamik",
    },
];

module.exports = {
    //new fields from kobo
    wayToHospitalAns,
    wayToHospitalQuestion,
    basicSchoolOption,
    basicSchoolQuestion,
    secondarySchoolOption,
    secondarySchoolQuestion,
};
