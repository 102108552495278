import React from "react";
import { Card } from "react-bootstrap";
import { pregnantCheckup } from "../../data/houseSurvey";
import { selectedLanguage } from "../../data/institute";

const BibadDetail = ({ vivad }) => {
    return (
        <>
            <Card>
                <Card.Body className="house-detail">
                    <Card.Title>
                        <h3>विविध</h3>
                    </Card.Title>
                    <h6>
                        परिवारमा यस १२ महिना भित्र कोहि गर्भवती महिला हुनुहुन्छ
                        ? :{" "}
                        {vivad.pregnantInfo &&
                        vivad.pregnantInfo.anyPregnant == true
                            ? "छ"
                            : "छैन"}
                    </h6>
                    {vivad.pregnantInfo &&
                        vivad.pregnantInfo.anyPregnant == true && (
                            <h6>
                                गत १२ महिनामा तपाईँको परिवारमा गर्भवती महिलाले
                                नियमित रूपमा तालिम प्राप्त स्वास्थ्य कर्मीहरुबाट
                                स्वास्थ्य जाँच गराउनु भयो? :{" "}
                                {pregnantCheckup.find(
                                    (i) =>
                                        i.value ===
                                        vivad.pregnantInfo.pregnantCheckup
                                )
                                    ? pregnantCheckup.find(
                                          (i) =>
                                              i.value ===
                                              vivad.pregnantInfo.pregnantCheckup
                                      )[selectedLanguage]
                                    : vivad.pregnantInfo.pregnantCheckup}
                            </h6>
                        )}
                    <h6>
                        गाउँपालिका बाट प्रवाह हुने सेवा कस्तो लाग्छ ? :{" "}
                        {vivad.suggestion && vivad.suggestion}
                    </h6>
                    {/* <h6>8. परिवारको बीमा विवरण :  { }</h6> */}
                </Card.Body>
            </Card>
        </>
    );
};

export default BibadDetail;
