import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import ImageUploader from "../components/ImageUploader";
import FormFooterButtons from "../components/FormFooterButtons";
import {
    add,
    wardQuestion,
    family,
    amount,
    descriptions,
    selectedLanguage,
    industryTypeLists,
    industryCategory,
    cancel,
} from "../data/institute";
import {
    wardList,
    toleWardOne,
    toleWardTwo,
    toleWardThree,
    toleWardFour,
    toleWardFive,
    toleWardSix,
    toleWardSeven,
    toleWardEight,
    toleWardTen,
    toleWardNine,
    toleWardEleven,
    toleWardTwelve,
} from "../data/ToleList";
import {
    toleFiveQn,
    toleFourQn,
    toleOneQn,
    toleThreeQn,
    toleTwoQn,
    toleSixQn,
    toleSevenQn,
    toleEightQn,
    toleNineQn,
    toleTenQn,
    toleElevenQn,
    toleTwelveQn,
} from "../data/houseSurvey";
import { Card } from "react-bootstrap";
import { GetUUID } from "../data/helpers/RandomNumGenerator";

const initData = {
    data: {
        name: "",
        wardNumber: "",
        industryCategory: "",
        industryType: [],
        ownership: [],
        directorName: "",
        productionGoodsName: "",
        hasSocialSecurityFund: "",
        yearlySalaryExpense: "",
        hasRegistration: "",
        dartaNumber: "",
        investmentCapital: "",
        sourcePersonName: "",
        sourcePersonContact: "",
        employeeCountAndInfo: [
            {
                name: "",
                gender: "",
                age: 0,
                permanentAddress: "",
                fullAddress: "",
                hasInsurance: "",
                workTimePeriodOption: "",
                workTimePeriod: "",
            },
        ],
        uniqueIdentifier: GetUUID(),
    },
};

const Industry = (props) => {
    const [apiData, setApiData] = useState({});
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: apiData.data,
        validate: (values) => {
            const errors = {};
            // if (!values.name) {
            //     errors.name = 'Required';
            // }
            return errors;
        },
        onSubmit: (values, { setSubmitting }) => {
            props.submit(values);
        },
    });
    useEffect(() => {
        if (props.data) {
            setApiData((prevState) => {
                return {
                    ...prevState,
                    data: {
                        ...props.data,
                        employeeCountAndInfo: props.data.employeeCountAndInfo
                            ?.length
                            ? props.data.employeeCountAndInfo
                            : [
                                  {
                                      name: "",
                                      gender: "",
                                      age: 0,
                                      permanentAddress: "",
                                      fullAddress: "",
                                      hasInsurance: "",
                                      workTimePeriodOption: "",
                                      workTimePeriod: "",
                                  },
                              ],
                    },
                };
            });
        } else {
            setApiData(initData);
        }
        console.log(props);
    }, [props]);
    const addFormRow = () => {
        const prevdescription = formik.values?.employeeCountAndInfo;
        prevdescription.push({
            name: "",
            gender: "",
            age: 0,
            permanentAddress: "",
            fullAddress: "",
            hasInsurance: "",
            workTimePeriodOption: "",
            workTimePeriod: "",
        });
        formik.setValues({
            ...formik.values,
            employeeCountAndInfo: prevdescription,
        });
    };
    const deleteFormRow = (index) => {
        const prevdescription = formik.values.employeeCountAndInfo;
        prevdescription.splice(index, 1);
        formik.setValues({
            ...formik.values,
            employeeCountAndInfo: prevdescription,
        });
    };
    const getFormRowList = () => {
        return formik.values?.employeeCountAndInfo.map((row, i) => (
            <div className="form-group row" key={i}>
                <div className="col-lg-10">
                    <div className="form-group row align-items-center">
                        <div className="col-md-3">
                            <label>कर्मचारीको नाम थर</label>
                            <input
                                type="text"
                                name={`employeeCountAndInfo[${i}].name`}
                                onChange={formik.handleChange}
                                className="form-control"
                                value={
                                    formik.values?.employeeCountAndInfo[i].name
                                }
                            />
                            <div className="d-md-none mb-2"></div>
                        </div>
                        <div className="col-md-3">
                            <label>लिङ्ग</label>
                            <select
                                className="form-control"
                                id="ward"
                                name={`employeeCountAndInfo[${i}].gender`}
                                onChange={formik.handleChange}
                                value={
                                    formik.values?.employeeCountAndInfo[i]
                                        .gender
                                }
                            >
                                <option>कुनै छान्नुहोस</option>
                                <option value="male">पुरुष</option>
                                <option value="female">महिला</option>
                                <option value="gender_others">अन्य</option>
                            </select>
                            <div className="d-md-none mb-2"></div>
                        </div>
                        <div className="col-md-3">
                            <label>उमेर</label>
                            <input
                                type="number"
                                name={`employeeCountAndInfo[${i}].age`}
                                onChange={formik.handleChange}
                                className="form-control"
                                value={
                                    formik.values?.employeeCountAndInfo[i].age
                                }
                            />
                            <div className="d-md-none mb-2"></div>
                        </div>
                        <div className="col-md-3">
                            <label>स्थायी ठेगाना</label>
                            <select
                                className="form-control"
                                id="ward"
                                name={`employeeCountAndInfo[${i}].permanentAddress`}
                                onChange={formik.handleChange}
                                value={
                                    formik.values?.employeeCountAndInfo[i]
                                        .permanentAddress
                                }
                            >
                                <option>कुनै छान्नुहोस</option>
                                <option value="nepal">नेपाल</option>
                                <option value="other_countries">
                                    अन्य मुलुक
                                </option>
                            </select>
                            <div className="d-md-none mb-2"></div>
                        </div>
                        <div className="col-md-3">
                            <label>को पुरा ठेगाना उल्लेख गर्नुहोस्</label>
                            <input
                                type="text"
                                name={`employeeCountAndInfo[${i}].fullAddress`}
                                onChange={formik.handleChange}
                                className="form-control"
                                value={
                                    formik.values?.employeeCountAndInfo[i]
                                        .fullAddress
                                }
                            />
                            <div className="d-md-none mb-2"></div>
                        </div>
                        <div className="col-md-3">
                            <label>विमा</label>
                            <select
                                className="form-control"
                                id="ward"
                                name={`employeeCountAndInfo[${i}].hasInsurance`}
                                onChange={formik.handleChange}
                                value={
                                    formik.values?.employeeCountAndInfo[i]
                                        .hasInsurance
                                }
                            >
                                <option>कुनै छान्नुहोस</option>
                                <option value="yes">छ</option>
                                <option value="no">छैन</option>
                            </select>
                            <div className="d-md-none mb-2"></div>
                        </div>
                        <div className="col-md-3">
                            <label>कति समय भयो काम गरेको</label>
                            <select
                                className="form-control"
                                id="ward"
                                name={`employeeCountAndInfo[${i}].workTimePeriodOption`}
                                onChange={formik.handleChange}
                                value={
                                    formik.values?.employeeCountAndInfo[i]
                                        .workTimePeriodOption
                                }
                            >
                                <option>कुनै छान्नुहोस</option>
                                <option value="year">वर्ष</option>
                                <option value="month">महिना</option>
                                <option value="day">दिन</option>
                            </select>
                            <div className="d-md-none mb-2"></div>
                        </div>
                        <div className="col-md-3">
                            <label>उल्लेख गर्नुहोस्</label>
                            <input
                                type="number"
                                name={`employeeCountAndInfo[${i}].workTimePeriod`}
                                onChange={formik.handleChange}
                                className="form-control"
                                value={
                                    formik.values?.employeeCountAndInfo[i]
                                        .workTimePeriod
                                }
                            />
                            <div className="d-md-none mb-2"></div>
                        </div>
                        <div className="col-md-3">
                            <a
                                href="javascript:;"
                                onClick={() => deleteFormRow(i)}
                                className="btn btn-sm font-weight-bolder btn-light-danger"
                            >
                                <i className="la la-trash-o"></i>
                                {cancel[selectedLanguage]}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        ));
    };
    const wardData = [
        {
            question: toleOneQn,
            toleList: toleWardOne,
        },
        {
            question: toleTwoQn,
            toleList: toleWardTwo,
        },
        {
            question: toleThreeQn,
            toleList: toleWardThree,
        },
        {
            question: toleFourQn,
            toleList: toleWardFour,
        },
        {
            question: toleFiveQn,
            toleList: toleWardFive,
        },
        {
            question: toleSixQn,
            toleList: toleWardSix,
        },
        {
            question: toleSevenQn,
            toleList: toleWardSeven,
        },
        {
            question: toleEightQn,
            toleList: toleWardEight,
        },
        {
            question: toleNineQn,
            toleList: toleWardNine,
        },
        {
            question: toleTenQn,
            toleList: toleWardTen,
        },
        {
            question: toleElevenQn,
            toleList: toleWardEleven,
        },
        {
            question: toleTwelveQn,
            toleList: toleWardTwelve,
        },
    ];
    const getToleList = (ward) => {
        if (ward == 1) {
            return toleWardOne;
        } else if (ward == 2) {
            return toleWardTwo;
        } else if (ward == 3) {
            return toleWardThree;
        } else if (ward == 4) {
            return toleWardFour;
        } else if (ward == 5) {
            return toleWardFive;
        } else if (ward == 6) {
            return toleWardSix;
        } else if (ward == 7) {
            return toleWardSeven;
        } else if (ward == 8) {
            return toleWardEight;
        } else if (ward == 9) {
            return toleWardNine;
        } else if (ward == 10) {
            return toleWardTen;
        } else if (ward == 11) {
            return toleWardEleven;
        } else {
            return toleWardTwelve;
        }
    };
    return (
        <form className="form survey-form" onSubmit={formik.handleSubmit}>
            <Card>
                <Card.Body>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="form-control-label">
                                उधोगको नाम
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="उधोगको नाम"
                                name="name"
                                onChange={formik.handleChange}
                                value={formik.values?.name}
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="form-control-label">
                                {wardQuestion[selectedLanguage]}
                            </label>
                            <select
                                className="form-control"
                                id="wardNumber"
                                name="wardNumber"
                                onChange={formik.handleChange}
                                value={
                                    formik.values && formik.values.wardNumber
                                        ? formik.values.wardNumber
                                        : "selectOne"
                                }
                            >
                                <option value="selectOne" disabled>
                                    कुनै एक छान्नुहोस्
                                </option>
                                {wardList.map((woda) => (
                                    <option value={woda.value}>
                                        {woda[selectedLanguage]}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {formik.values?.wardNumber && (
                            <div className="col-md-6">
                                <label className="form-control-label">
                                    {
                                        wardData[formik.values?.wardNumber - 1]
                                            .question[selectedLanguage]
                                    }
                                </label>
                                <select
                                    className="form-control"
                                    id="toleName"
                                    name="toleName"
                                    onChange={formik.handleChange}
                                    value={
                                        formik.values && formik.values.toleName
                                            ? formik.values.toleName
                                            : "selectOne"
                                    }
                                >
                                    <option value="selectOne" disabled>
                                        कुनै एक छान्नुहोस्
                                    </option>
                                    {getToleList(formik.values?.wardNumber).map(
                                        (woda) => (
                                            <option value={woda.value}>
                                                {woda[selectedLanguage]}
                                            </option>
                                        )
                                    )}
                                </select>
                            </div>
                        )}
                    </div>

                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="form-control-label">
                                मार्गको नाम
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="मार्गको नाम"
                                name="streetName"
                                onChange={formik.handleChange}
                                value={formik.values?.streetName}
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="form-control-label">उधोग</label>
                            <select
                                className="form-control"
                                id="industryCategory"
                                name="industryCategory"
                                onChange={formik.handleChange}
                                value={
                                    formik.values &&
                                    formik.values.industryCategory
                                        ? formik.values.industryCategory
                                        : "selectOne"
                                }
                            >
                                <option value="selectOne" disabled>
                                    कुनै एक छान्नुहोस्
                                </option>
                                {industryCategory.map((type) => (
                                    <option value={type.value}>
                                        {type[selectedLanguage]}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {formik.values?.industryCategory == "other" && (
                            <div className="col-md-6">
                                <label className="form-control-label">
                                    अन्य भए नाम उल्लेख गर्नु होस् :
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="अन्य भए नाम उल्लेख गर्नु होस् :"
                                    name="industryCategoryOther"
                                    onChange={formik.handleChange}
                                    value={formik.values?.industryCategoryOther}
                                />
                            </div>
                        )}
                    </div>

                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="form-control-label">
                                उधोगको प्रकार
                            </label>
                            <select
                                className="form-control"
                                multiple={true}
                                id="industryType"
                                name="industryType"
                                onChange={formik.handleChange}
                                value={
                                    formik.values && formik.values.industryType
                                        ? formik.values.industryType
                                        : []
                                }
                            >
                                <option value="selectOne" disabled>
                                    कुनै एक छान्नुहोस्
                                </option>
                                {industryTypeLists.map((type) => (
                                    <option value={type.value}>
                                        {type[selectedLanguage]}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {formik.values?.industryType == "other" && (
                            <div className="col-md-6">
                                <label className="form-control-label">
                                    अन्य भए खुलाउनुहोस्
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="अन्य भए खुलाउनुहोस्"
                                    name="industryTypeOther"
                                    onChange={formik.handleChange}
                                    value={formik.values?.industryTypeOther}
                                />
                            </div>
                        )}

                        <div className="col-md-6">
                            <label className="form-control-label">
                                उधोगको स्वामित्व
                            </label>
                            <select
                                className="form-control"
                                multiple={true}
                                id="ownership"
                                name="ownership"
                                onChange={formik.handleChange}
                                value={
                                    formik.values && formik.values.ownership
                                        ? formik.values.ownership
                                        : []
                                }
                            >
                                <option value="selectOne" disabled>
                                    कुनै एक छान्नुहोस्
                                </option>
                                <option value="publice">सरकारी</option>
                                <option value="private">निजि/पब्लिक</option>
                                <option value="sahakari">सहकारी</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="form-control-label">
                                उधोग संचालकको नाम थर
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="उधोग संचालकको नाम थर"
                                name="directorName"
                                onChange={formik.handleChange}
                                value={formik.values?.directorName}
                            />
                        </div>

                        <div className="col-md-6">
                            <label className="form-control-label">
                                उत्पादन हुने बस्तुहरुको नाम
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="उत्पादन हुने बस्तुहरुको नाम"
                                name="productionGoodsName"
                                onChange={formik.handleChange}
                                value={formik.values?.productionGoodsName}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="form-control-label">
                                सामाजिक सुरक्षा कोषमा दर्ता हुनुहुन्छ कि हुन्न ?
                            </label>
                            <label className="radio radio-rounded">
                                <input
                                    type="radio"
                                    value="yes"
                                    name="hasSocialSecurityFund"
                                    onChange={formik.handleChange}
                                />
                                <span></span>छ
                            </label>
                            <label className="radio radio-rounded">
                                <input
                                    type="radio"
                                    value="no"
                                    name="hasSocialSecurityFund"
                                    onChange={formik.handleChange}
                                />
                                <span></span>
                                छैन
                            </label>
                        </div>

                        <div className="col-md-6">
                            <label className="form-control-label">
                                कर्मचारीको तलबमा औसत वार्षिक कति खर्च हुन्छ ?
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="कर्मचारीको तलबमा औसत वार्षिक कति खर्च हुन्छ ?"
                                name="yearlySalaryExpense"
                                onChange={formik.handleChange}
                                value={formik.values?.yearlySalaryExpense}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="form-control-label">
                                उधोग दर्ता गर्नु भएको छ कि छैन ?
                            </label>
                            <label className="radio radio-rounded">
                                <input
                                    type="radio"
                                    value="yes"
                                    name="hasRegistration"
                                    onChange={formik.handleChange}
                                />
                                <span></span>छ
                            </label>
                            <label className="radio radio-rounded">
                                <input
                                    type="radio"
                                    value="no"
                                    name="hasRegistration"
                                    onChange={formik.handleChange}
                                />
                                <span></span>
                                छैन
                            </label>
                        </div>

                        {formik.values?.hasRegistration == "yes" && (
                            <div className="col-md-6">
                                <label className="form-control-label">
                                    दर्ता गरेको भए दर्ता नम्बर
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="दर्ता गरेको भए दर्ता नम्बर"
                                    name="dartaNumber"
                                    onChange={formik.handleChange}
                                    value={formik.values?.dartaNumber}
                                />
                            </div>
                        )}

                        <div className="col-md-6">
                            <label className="form-control-label">
                                उधोगको लगानी पुजी कति को हो ?
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="उधोगको लगानी पुजी कति को हो ? "
                                name="investmentCapital"
                                onChange={formik.handleChange}
                                value={formik.values?.investmentCapital}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="form-control-label">
                                श्रोत व्यक्तिको नाम थर
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="श्रोत व्यक्तिको नाम थर"
                                name="sourcePersonName"
                                onChange={formik.handleChange}
                                value={formik.values?.sourcePersonName}
                            />
                        </div>

                        <div className="col-md-6">
                            <label className="form-control-label">
                                श्रोत व्यक्तिको सम्पर्क नम्बर
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="श्रोत व्यक्तिको सम्पर्क नम्बर"
                                name="sourcePersonContact"
                                onChange={formik.handleChange}
                                value={formik.values?.sourcePersonContact}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="form-control-label">
                                कर्मचारी सङ्ख्या महिला
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="कर्मचारी सङ्ख्या महिला"
                                name="employeeCountAndInfoFemale"
                                onChange={formik.handleChange}
                                value={
                                    formik.values?.employeeCountAndInfoFemale
                                }
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="form-control-label">
                                कर्मचारी सङ्ख्या पुरुष
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="कर्मचारी सङ्ख्या पुरुष"
                                name="employeeCountAndInfoMale"
                                onChange={formik.handleChange}
                                value={formik.values?.employeeCountAndInfoMale}
                            />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="form-control-label">
                                अन्य सङ्ख्या
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="अन्य सङ्ख्या"
                                name="employeeCountAndInfoOther"
                                onChange={formik.handleChange}
                                value={formik.values?.employeeCountAndInfoOther}
                            />
                        </div>
                    </div>

                    <hr />
                    <div>
                        <h3>उधोग संचालक सम्बन्धि विवरण</h3>
                        {getFormRowList()}
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <a
                                    href="javascript:;"
                                    onClick={addFormRow}
                                    className="btn btn-sm font-weight-bolder btn-light-primary"
                                >
                                    <i className="la la-plus"></i>
                                    {add[selectedLanguage]}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-6">
                            <label className="form-control-label">
                                अक्षांश
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="lat"
                                onChange={formik.handleChange}
                                value={formik.values?.lat}
                            />
                        </div>

                        <div className="col-md-6">
                            <label className="form-control-label">
                                देशान्तर
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="lng"
                                onChange={formik.handleChange}
                                value={formik.values?.lng}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <FormFooterButtons />
                    </div>
                </Card.Body>
            </Card>
        </form>
    );
};

export default Industry;
