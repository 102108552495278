import React, { useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import ApexCharts from "apexcharts";
import { Accordion, Button, Card } from "react-bootstrap";
import { KTUtil } from "../../_metronic/_assets/js/components/util.js";
import { useHtmlClassService } from "../../_metronic/layout";
import objectPath from "object-path";
import SchoolGraph from "../detailTypes/overview-sections/SchoolGraph";
import axios from "axios";
import BarGraphCard from "../detailTypes/overview-sections/BarGraphCard.js";
import { reportList } from "../data/report.js";
import { selectedLanguage } from "../data/institute.js";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import getNepalNumber from "get-nepali-number";
import Loader from "../components/Loader.js";
import BibaranDataModal from "../components/BibaranDataModal.js";
import { getAnalysisData } from "../modules/HomeSurvey/homeSurveyCrud.js";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
// import Axios from 'axios';

const DataDetail = (props) => {
    const uiService = useHtmlClassService();
    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [tableData, setTableData] = useState(undefined);
    console.log(data);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
  @page {
    size: 80mm 50mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
	  #detail-table th, #detail-table td{
		  font-size: 8px;
	  }
    .pagebreak {
      page-break-before: always;
    }
  }
`,
    });
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray200: objectPath.get(
                uiService.config,
                "js.colors.gray.gray200"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseDanger: objectPath.get(
                uiService.config,
                "js.colors.theme.base.danger"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
        };
    }, [uiService]);
    const child = reportList[props.match.params.parentIndex]?.child.find(
        (i) => i.value === props.match.params.apiKey
    );
    const detailKey = reportList[
        props.match.params.parentIndex
    ]?.baseApiKey.split("-detail")[0];
    const getTotal = (datum) => {
        let total = 0;
        if (datum.data.total) {
            datum.data.total.map((value, index) => {
                total += value.value;
            });
            if (parseInt(datum.ward) !== 0) {
                return (
                    <td>
                        {selectedLanguage == "nepali"
                            ? getNepalNumber(total)
                            : total}
                    </td>
                );
            } else {
                return (
                    <th>
                        {selectedLanguage == "nepali"
                            ? getNepalNumber(total)
                            : total}
                    </th>
                );
            }
        } else {
            datum.data.map((value, index) => {
                total += value.value;
            });
            if (parseInt(datum.ward) !== 0) {
                return (
                    <td>
                        {selectedLanguage == "nepali"
                            ? getNepalNumber(total)
                            : total}
                    </td>
                );
            } else {
                return (
                    <th>
                        {selectedLanguage == "nepali"
                            ? getNepalNumber(total)
                            : total}
                    </th>
                );
            }
        }
    };
    const clickDiv = (el) => {
        const downloadBtn = document.getElementById("test-table-xls-button");
        if (downloadBtn) {
            downloadBtn.click();
        }
    };
    const parentIndex = props.match.params.parentIndex;
    const genderKeys = [
        { value: "male", label: "पुरुष" },
        { value: "female", label: "महिल" },
        { value: "third", label: "अन्य " },
        { value: "single", label: "जम्मा" },
    ];
    const getCellArgDetail = (args) => {
        setShow(true);
        setTableData(undefined);
        const body = {
            function: data.functions,
            args,
        };
        getAnalysisData(detailKey, body)
            .then((res) => {
                if (res.data) {
                    setTableData(res.data);
                }
            })
            .catch((err) => {
                toast.error("An error occured");
            });
    };
    const getTableCell = (valueObj) => {
        if (valueObj.args?.length > 0) {
            return (
                <a
                    href="javascript:void()"
                    onClick={() => getCellArgDetail(valueObj.args)}
                >
                    {selectedLanguage == "nepali"
                        ? getNepalNumber(valueObj.value)
                        : valueObj.value}
                </a>
            );
        }
        return (
            <>
                {selectedLanguage == "nepali"
                    ? getNepalNumber(valueObj.value)
                    : valueObj.value}
            </>
        );
    };
    return (
        <>
            <BibaranDataModal
                setShow={setShow}
                show={show}
                tableData={tableData}
                isFamily={[0, 1].includes(parseInt(parentIndex))}
            />
            <Card>
                {/* <Card.Header>
                    <h2>
                        शिक्षा सम्बन्धी बिबरण
                    </h2>
                </Card.Header> */}
                <Card.Body>
                    <Accordion defaultActiveKey={0}>
                        <BarGraphCard
                            child={child}
                            detail={true}
                            parentIndex={parentIndex}
                            childIndex={0}
                            setData={setData}
                            setLoading={setLoading}
                        />
                    </Accordion>
                </Card.Body>
            </Card>
            {!loading && data && child.value !== "educationSector" && (
                <Card className="mt-10">
                    <Card.Header className="d-flex justify-content-between">
                        <h2>{child[selectedLanguage]}</h2>
                        <div className="d-flex align-items-start">
                            {/* <div className="d-flex justify-content-end"> */}
                            <button
                                className="btn btn-primary mb-4 mr-3 d-flex"
                                onClick={handlePrint}
                            >
                                <i className="flaticon2-printer"></i> प्रिन्ट
                                गर्नुहोस{" "}
                            </button>
                            {/* </div> */}
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="d-none"
                                table="detail-table"
                                filename={child[selectedLanguage]}
                                sheet={child[selectedLanguage]}
                                buttonText="Download as XLS"
                            />
                            <button
                                className="btn btn-light-success d-flex"
                                onClick={clickDiv}
                            >
                                {selectedLanguage == "nepali"
                                    ? "डाउनलोडगर्नुहोस् XLS"
                                    : "Export XLS"}
                                <i
                                    className="far fa-file-excel ml-2"
                                    style={{ position: "relative" }}
                                ></i>
                            </button>
                            {/* <button className="btn btn-light-danger" onClick={this.exportPdf}>
											Export PDF
										<i className="far fa-file-pdf ml-2" style={{ top: "3px", position: "relative" }}></i>
										</button> */}
                        </div>
                    </Card.Header>

                    {loading && <Loader loading={loading} />}
                    {!loading && (
                        <Card.Body ref={componentRef}>
                            {data?.ward && (
                                <Table responsive id="detail-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                {selectedLanguage == "nepali"
                                                    ? "वडा न "
                                                    : "Ward No. "}
                                            </th>
                                            {data.ward.length &&
                                                data.ward[0].data.male &&
                                                data.ward[0].data.male.map(
                                                    (datum, index) => {
                                                        return (
                                                            <th>
                                                                {datum.label}
                                                            </th>
                                                        );
                                                    }
                                                )}
                                            {data.ward.length &&
                                                !data.ward[0].data.male &&
                                                data.ward[0].data.map(
                                                    (datum, index) => {
                                                        return (
                                                            <th>
                                                                {datum.label}
                                                            </th>
                                                        );
                                                    }
                                                )}
                                            <th>{data.total && "जम्मा"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.ward.length &&
                                            data.ward.map((datum, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th>
                                                            {parseInt(
                                                                datum.ward
                                                            ) !== 0
                                                                ? selectedLanguage ==
                                                                  "nepali"
                                                                    ? getNepalNumber(
                                                                          datum.ward
                                                                      )
                                                                    : datum.ward
                                                                : selectedLanguage ==
                                                                  "nepali"
                                                                ? "डेमो"
                                                                : "Demo"}
                                                        </th>
                                                        {datum.data.total &&
                                                            datum.data.total.map(
                                                                (
                                                                    valueObj,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        parseInt(
                                                                            datum.ward
                                                                        ) !== 0
                                                                    ) {
                                                                        return (
                                                                            <td
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {getTableCell(
                                                                                    valueObj
                                                                                )}
                                                                            </td>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <th
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {getTableCell(
                                                                                    valueObj
                                                                                )}
                                                                            </th>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        {!datum.data.total &&
                                                            datum.data.map(
                                                                (
                                                                    valueObj,
                                                                    index
                                                                ) => {
                                                                    if (
                                                                        parseInt(
                                                                            datum.ward
                                                                        ) !== 0
                                                                    ) {
                                                                        return (
                                                                            <td
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {getTableCell(
                                                                                    valueObj
                                                                                )}
                                                                            </td>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <th
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {getTableCell(
                                                                                    valueObj
                                                                                )}
                                                                            </th>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        {data.total &&
                                                            getTotal(datum)}
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            )}
                            {data?.male && (
                                <Table responsive id="detail-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                {selectedLanguage == "nepali"
                                                    ? "लिङ्ग"
                                                    : "Gender"}
                                            </th>
                                            {data.male &&
                                                data.male.length &&
                                                data.male.map(
                                                    (datum, index) => {
                                                        return (
                                                            <th>
                                                                {datum.label}
                                                            </th>
                                                        );
                                                    }
                                                )}
                                            <th>
                                                {selectedLanguage == "nepali"
                                                    ? "जम्मा"
                                                    : "Total"}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {genderKeys.map((gender, index) => (
                                            <tr key={index}>
                                                <th>{gender.label}</th>
                                                {data[gender.value]?.map(
                                                    (valueObj, index2) => {
                                                        if (
                                                            gender.value !==
                                                            "single"
                                                        ) {
                                                            return (
                                                                <td
                                                                    key={index2}
                                                                >
                                                                    {getTableCell(
                                                                        valueObj
                                                                    )}
                                                                </td>
                                                            );
                                                        } else {
                                                            return (
                                                                <th
                                                                    key={index2}
                                                                >
                                                                    {getTableCell(
                                                                        valueObj
                                                                    )}
                                                                </th>
                                                            );
                                                        }
                                                    }
                                                )}
                                                <td key={index}>
                                                    {data[gender.value].reduce(
                                                        (a, c) =>
                                                            (a += c.value),
                                                        0
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            )}
                        </Card.Body>
                    )}
                </Card>
            )}
        </>
    );
};

export default DataDetail;
