import { SearchOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import { Button, Input, Space, Table } from "antd";
import { toast } from "react-toastify";
import { getTableData } from "../modules/InstituteSurvey/_redux/surveyCrud";
import Loader from "./Loader";
import Pagination from "./Pagination";

const HealthTable = (props) => {
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const { subCatRoute } = props;
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const searchInput = useRef(null);
    useEffect(() => {
        if (subCatRoute) {
            setLoading(true);
            getTableData(subCatRoute)
                .then((res) => {
                    setLoading(false);
                    console.log(res);
                    if (res.data.data) {
                        const { title, data } = res.data;
                        const pages = Math.ceil(data.length / 10);
                        setTotalPages(pages);
                        const dispData = data.slice(0, 10);
                        setColumns([
                            {
                                title: "वडा नम्बर",
                                dataIndex: "ward",
                                key: "ward",
                                width: "30%",
                                ...getColumnSearchProps("ward"),
                            },
                            {
                                title: "स्वास्थ्य सँस्थाको नाम",
                                dataIndex: "name",
                                key: "name",
                                width: "30%",
                                ...getColumnSearchProps("name"),
                            },
                            {
                                title: "सँस्थाको प्रकार",
                                dataIndex: "instituteType",
                                key: "instituteType",
                                width: "30%",
                                ...getColumnSearchProps("instituteType"),
                            },
                            {
                                title: "स्वस्थ सँस्थाको प्रकार",
                                dataIndex: "healthOrgType",
                                filters: [
                                    {
                                        text: "अस्पताल",
                                        value: "अस्पताल",
                                    },
                                    {
                                        text: "स्वास्थ्य चौकी",
                                        value: "स्वास्थ्य चौकी",
                                    },
                                    {
                                        text: "सामुदायिक स्वास्थ्य इकाई",
                                        value: "सामुदायिक स्वास्थ्य इकाई",
                                    },
                                    {
                                        text: "प्राथमिक स्वास्थ्य केन्द्र",
                                        value: "प्राथमिक स्वास्थ्य केन्द्र",
                                    },
                                    {
                                        text: "आधारभूत स्वास्थ्य सेवा केन्द्र",
                                        value: "आधारभूत स्वास्थ्य सेवा केन्द्र",
                                    },
                                    {
                                        text: "शहरी स्वास्थ्य केन्द्र",
                                        value: "शहरी स्वास्थ्य केन्द्र",
                                    },
                                    {
                                        text: "गाउँ घर क्लिनिक",
                                        value: "गाउँ घर क्लिनिक",
                                    },
                                    {
                                        text:
                                            "एचआइभि परीक्षण तथा परामर्श केन्द्र",
                                        value:
                                            "एचआइभि परीक्षण तथा परामर्श केन्द्र",
                                    },
                                    {
                                        text: "प्रसुती केन्द्र",
                                        value: "प्रसुती केन्द्र",
                                    },
                                    {
                                        text: "आयुर्वेद स्वास्थ्य केन्द्र",
                                        value: "आयुर्वेद स्वास्थ्य केन्द्र",
                                    },
                                    {
                                        text: "प्राकृतिक चिकित्सालय",
                                        value: "प्राकृतिक चिकित्सालय",
                                    },
                                    {
                                        text: "आँखा उपचार केन्द्र",
                                        value: "आँखा उपचार केन्द्र",
                                    },
                                    {
                                        text: "बाल अस्पताल",
                                        value: "बाल अस्पताल",
                                    },
                                    {
                                        text: "रक्त संचार केन्द्र",
                                        value: "रक्त संचार केन्द्र",
                                    },
                                    {
                                        text: "शिक्षण अस्पताल",
                                        value: "शिक्षण अस्पताल",
                                    },
                                ],
                                filterMode: "tree",
                                filterSearch: true,
                                onFilter: (value, record) =>
                                    record.healthOrgType.startsWith(value),
                                width: "30%",
                            },
                            {
                                title: "शैया सङ्ख्या",
                                dataIndex: "bedCount",
                                key: "bedCount",
                                width: "30%",
                                ...getColumnSearchProps("bedCount"),
                            },
                            {
                                title: "कार्य",
                                dataIndex: "",
                                key: "d",
                                render: function(val) {
                                    return (
                                        <div className="d-flex table-actions">
                                            <i
                                                className="flaticon-eye mr-3 mb-3"
                                                onClick={(e) =>
                                                    props.viewData(val.id)
                                                }
                                            ></i>
                                            <i
                                                className="flaticon-edit mr-3 mb-3"
                                                onClick={(e) =>
                                                    props.editData(val.id)
                                                }
                                            ></i>
                                            <i
                                                className="flaticon-delete"
                                                onClick={(e) =>
                                                    props.deleteData(val.id)
                                                }
                                            ></i>
                                        </div>
                                    );
                                },
                            },
                        ]);
                        setData(data);
                        setDisplayData(dispData);
                    }
                })
                .catch((err) => {
                    toast.error("An error occured while fetching data");
                    setLoading(false);
                });
        }
    }, [subCatRoute]);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        खोज्नुहोस्
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        रिसेट गर्नुहोस्
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        फिल्टर गर्नुहोस्
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button> */}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text,
    });
    const handlePageChange = (val) => {
        const start = val * 10;
        const end = start + 10;
        const dispData = data.slice(start, end);
        setDisplayData(dispData);
    };
    return (
        <>
            {loading && <Loader loading={loading} />}
            {!loading && (
                <>
                    <Table columns={columns} dataSource={displayData} />
                    <Pagination
                        pages={totalPages}
                        handlePageChange={handlePageChange}
                    />
                </>
            )}
        </>
    );
};
export default HealthTable;
