import Table from "rc-table";
import React, { useEffect } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const SchoolReport = (props) => {
    const { data, mapColumn } = props.data;
    const table1Columns = [
        "ward",
        "toleName",
        "instituteType",
        "name",
        "instituteLevel",
    ];
    const table1MapColumn = mapColumn?.filter((i) =>
        table1Columns.includes(i.dataIndex)
    );
    const table2Columns = [
        "ward",
        "name",
        "buildingPakkiCount",
        "buildingKachhiCount",
        "roomPakki",
        "roomKacchi",
        "toiletYes",
        "toiletNo",
        "disableToiletYes",
        "disableToiletNo",
        "handWashYes",
        "handWashNo",
        "drinkingWaterYes",
        "drinkingWaterNo",
    ];
    const table2MapColumn = mapColumn?.filter((i) =>
        table2Columns.includes(i.dataIndex)
    );

    const table4aColumns = [
        "ward",
        "name",
        "buildingPakkiCount",
        "buildingKachhiCount",
        "roomPakki",
        "roomKacchi",
        "toiletYesNo",
        "disableToiletYesNo",
        "handWashYesNo",
        "drinkingWaterYesNo",
    ];
    const table4aMapColumn = mapColumn?.filter((i) =>
        table4aColumns.includes(i.dataIndex)
    );
    //प्रयोगशाला(विज्ञान/कम्पियूटर/अन्य) key not found
    const table4bColumns = [
        "ward",
        "name",
        "totalLandUsed",
        "isLandPrivateOrRent",
        "hasLandDocument",
        "hasRetrofitting",
        "establishedYear",
        "hasCompoundWall",
    ];

    const table4bMapColumn = mapColumn?.filter((i) =>
        table4bColumns.includes(i.dataIndex)
    );
    const table5Columns = [
        "ward",
        "name",
        "hasDisabilityResourceClass",
        "computerCount",
        "hasScienceLab",
        "hasLibrary",
        "playgroundArea",
    ];
    const table5MapColumn = mapColumn?.filter((i) =>
        table5Columns.includes(i.dataIndex)
    );

    const mapColumnArr = [
        { value: "1", title: "टेवल १", columns: table1MapColumn },
        // { value: "2", title: "टेवल २", columns: table2MapColumn },
        { value: "2", title: "टेवल २", columns: table4aMapColumn },
        { value: "3", title: "टेवल ३", columns: table4bMapColumn },
        { value: "4", title: "टेवल ४", columns: table5MapColumn },
    ];
    useEffect(() => {
        let tables = document.getElementsByTagName("table");
        console.log("tablesData", tables);

        tables.forEach((table, index) => {
            table.setAttribute("id", `schoolTable${index}`);
        });
        // let index = 0;
        // for (let table of tables) {
        //     table.setAttribute("id", `schoolTable${index}`);
        // }
    }, [data]);
    return (
        <>
            <Card>
                <Card.Body>
                    <Tabs
                        size="sm"
                        variant="pills"
                        defaultActiveKey={"1"}
                        id="uncontrolled-tab-example"
                    >
                        {mapColumnArr.map((mapColumnCurr, index) => {
                            return (
                                <Tab
                                    key={mapColumnCurr.value}
                                    eventKey={mapColumnCurr.value}
                                    title={mapColumnCurr.title}
                                >
                                    <div className="d-flex justify-content-between mb-4">
                                        <h3>{mapColumnCurr.title}</h3>
                                        <ReactHTMLTableToExcel
                                            id={`${index}-button`}
                                            className="btn btn-primary float-right mb-4"
                                            table={`schoolTable${index}`}
                                            filename={mapColumnCurr.title}
                                            sheet={mapColumnCurr.title}
                                            buttonText="डाउनलोडगर्नुहोस् XLS"
                                        />
                                    </div>
                                    <Table
                                        columns={mapColumnCurr.columns}
                                        data={data}
                                        className="table table-bordered table-responsive"
                                    />
                                </Tab>
                            );
                        })}
                    </Tabs>
                </Card.Body>
            </Card>
            {/* {
                mapColumnArr.map((mapColumnCurr, index) => (
                    <>
                    </>

                ))
            } */}
        </>
    );
};

export default SchoolReport;
