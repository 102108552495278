import React from "react";
import { Card } from "react-bootstrap";
import {
    aanabaliLandUsed,
    womanOwnedHome,
    ownLandOrFamilyLand,
} from "../../data/houseSurvey";
import { selectedLanguage } from "../../data/institute";

const GharJagga = ({ houseland }) => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>जग्गाको प्रयोग सम्बन्धी</Card.Title>
                <h6>
                    आफ्नो घरपरिवार सदस्यको नाममा घडेरी तथा जग्गा जमिन :{" "}
                    {ownLandOrFamilyLand.find(
                        (i) => i.value === houseland.ownLandOrFamilyLand
                    )
                        ? ownLandOrFamilyLand.find(
                              (i) => i.value === houseland.ownLandOrFamilyLand
                          )[selectedLanguage]
                        : houseland.ownLandOrFamilyLand}
                </h6>
                <h6>
                    परिवारको जम्मा भोग चलनको क्षेत्रफल :
                    {`${houseland.totalLandArea.ropani}-${houseland.totalLandArea.aana}-${houseland.totalLandArea.paisa}`}
                </h6>
                <h6>
                    परिवारमा महिलाको नाममा घर, जग्गा :{" "}
                    {womanOwnedHome.find(
                        (i) => i.value === houseland.landInWomansName
                    )
                        ? womanOwnedHome.find(
                              (i) => i.value === houseland.landInWomansName
                          )[selectedLanguage]
                        : houseland.landInWomansName}
                </h6>
                <h6>
                    परिवारले कृषि कार्यका लागी जग्गा प्रयोग गर्नु भएको छ? :{" "}
                    {houseland.agricultureProductSales &&
                    houseland.agricultureProductSales == true
                        ? "छ"
                        : "छैन"}
                </h6>
                <h6>
                    कृषि प्रायोजनका लागि कसरी व्यवस्थापन गर्नु भएको छ ? :{" "}
                    {aanabaliLandUsed.find(
                        (i) => i.value === houseland.aanabaliLandUsed
                    )
                        ? aanabaliLandUsed.find(
                              (i) => i.value === houseland.aanabaliLandUsed
                          )[selectedLanguage]
                        : houseland.aanabaliLandUsed}
                </h6>
            </Card.Body>
        </Card>
    );
};

export default GharJagga;
