import { SearchOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import { Button, Input, Space, Table } from "antd";
import { toast } from "react-toastify";
import { getTableData } from "../modules/InstituteSurvey/_redux/surveyCrud";
import Loader from "./Loader";
import Pagination from "./Pagination";

const BusinessTable = (props) => {
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const { subCatRoute } = props;
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const searchInput = useRef(null);
    useEffect(() => {
        if (subCatRoute) {
            setLoading(true);
            getTableData(subCatRoute)
                .then((res) => {
                    setLoading(false);
                    console.log(res);
                    if (res.data.data) {
                        const { title, data } = res.data;
                        const pages = Math.ceil(data.length / 10);
                        setTotalPages(pages);
                        const dispData = data.slice(0, 10);
                        setColumns([
                            {
                                title: "वडा नम्बर",
                                dataIndex: "ward",
                                key: "ward",
                                width: "30%",
                                ...getColumnSearchProps("ward"),
                            },
                            {
                                title: "व्यापार तथा व्यसायको नाम",
                                dataIndex: "name",
                                key: "name",
                                width: "30%",
                                ...getColumnSearchProps("name"),
                            },
                            {
                                title: "सँचालक/प्रोपराइटरको नाम",
                                dataIndex: "proprietorInfoName",
                                key: "proprietorInfoName",
                                width: "30%",
                                ...getColumnSearchProps("proprietorInfoName"),
                            },
                            {
                                title: "व्यावसायको प्रकार",
                                dataIndex: "businessType",
                                filters: [
                                    {
                                        text: "किराना /थोक /मार्ट पसल",
                                        value: "किराना /थोक /मार्ट पसल",
                                    },
                                    {
                                        text: "कपडा पसल/फेन्सी स्टोर",
                                        value: "कपडा पसल/फेन्सी स्टोर",
                                    },
                                    {
                                        text:
                                            "हार्डवेयर तथा निर्माण सामग्री व्यवसाय",
                                        value:
                                            "हार्डवेयर तथा निर्माण सामग्री व्यवसाय",
                                    },
                                    {
                                        text:
                                            "चुरोट, मदिराजन्य,सुर्तीजन्य वस्तुहरुको व्यापार व्यवसाय",
                                        value:
                                            "चुरोट, मदिराजन्य,सुर्तीजन्य वस्तुहरुको व्यापार व्यवसाय",
                                    },
                                    {
                                        text:
                                            "इलेक्ट्रोनिक्स सामग्रीहरुको थोक तथा खुद्र व्यवसाय",
                                        value:
                                            "इलेक्ट्रोनिक्स सामग्रीहरुको थोक तथा खुद्र व्यवसाय",
                                    },
                                    {
                                        text: "अटो मोबाइल्स",
                                        value: "अटो मोबाइल्स",
                                    },
                                    {
                                        text: "औषधी पसल वा क्लिनिक",
                                        value: "औषधी पसल वा क्लिनिक",
                                    },
                                    {
                                        text:
                                            "होटेल तथा रेष्टुरेन्ट,रिसोर्ट,तारे होटल,खाजा घर",
                                        value:
                                            "होटेल तथा रेष्टुरेन्ट,रिसोर्ट,तारे होटल,खाजा घर",
                                    },
                                    {
                                        text: "ज्वेलरी ,सुन चादीका गरगहना पसल",
                                        value: "ज्वेलरी ,सुन चादीका गरगहना पसल",
                                    },
                                    {
                                        text:
                                            "इलेक्ट्रिकल, वायारिङका सामग्रीहरुको थोक तथा  खुद्रा व्यावसाय",
                                        value:
                                            "इलेक्ट्रिकल, वायारिङका सामग्रीहरुको थोक तथा  खुद्रा व्यावसाय",
                                    },
                                    {
                                        text:
                                            "परामार्श सेवा, अनुसन्धान तथा परामर्शदाता ,छापाखाना , प्रेस स्क्रिन , वृत्तचित्र निर्माण , सर्भेयर प्रिन्टर आदि व्यवसाय",
                                        value:
                                            "परामार्श सेवा, अनुसन्धान तथा परामर्शदाता ,छापाखाना , प्रेस स्क्रिन , वृत्तचित्र निर्माण , सर्भेयर प्रिन्टर आदि व्यवसाय",
                                    },
                                    {
                                        text: "कुटानी /पिसानी व्यवसाय",
                                        value: "कुटानी /पिसानी व्यवसाय",
                                    },
                                    {
                                        text: "भाडावर्तन",
                                        value: "भाडावर्तन",
                                    },
                                    {
                                        text:
                                            "पार्लर ,कस्मेटिक,बुटिक,सिलाई कटाई गर्ने व्यवसाय",
                                        value:
                                            "पार्लर ,कस्मेटिक,बुटिक,सिलाई कटाई गर्ने व्यवसाय",
                                    },
                                    {
                                        text: "मासुजन्य पसल",
                                        value: "मासुजन्य पसल",
                                    },
                                    {
                                        text: "तरकारी तथा फलफूल व्यवसाया",
                                        value: "तरकारी तथा फलफूल व्यवसाया",
                                    },
                                    {
                                        text: "दुध डेरी तथा चिस्यान केन्द्र",
                                        value: "दुध डेरी तथा चिस्यान केन्द्र",
                                    },
                                    {
                                        text: "भेटरीनरी पसल",
                                        value: "भेटरीनरी पसल",
                                    },
                                    {
                                        text:
                                            "केवल नेटवर्क /इन्टरनेट सेवा प्रदायक किकाय /कम्पनी",
                                        value:
                                            "केवल नेटवर्क /इन्टरनेट सेवा प्रदायक किकाय /कम्पनी",
                                    },
                                    {
                                        text:
                                            "शारीरिक व्यायामशाला (wellness center)",
                                        value:
                                            "शारीरिक व्यायामशाला (wellness center)",
                                    },
                                    {
                                        text: "फुटसल संचालन",
                                        value: "फुटसल संचालन",
                                    },
                                    {
                                        text: "वर्कशप",
                                        value: "वर्कशप",
                                    },
                                    {
                                        text: "पार्टी प्यालेस",
                                        value: "पार्टी प्यालेस",
                                    },
                                    {
                                        text:
                                            "कोल्ड ड्रिंक्स ,सिसा फ्रेमिङ, कार्पेट , सजावटका सामान , जनरल गुड्स आदि",
                                        value:
                                            "कोल्ड ड्रिंक्स ,सिसा फ्रेमिङ, कार्पेट , सजावटका सामान , जनरल गुड्स आदि",
                                    },
                                    {
                                        text: "कवाडी पसल थोक तथा खुद्रा",
                                        value: "कवाडी पसल थोक तथा खुद्रा",
                                    },
                                    {
                                        text: "ग्यास बिक्रि डिलर",
                                        value: "ग्यास बिक्रि डिलर",
                                    },
                                    {
                                        text: "पेट्रोल पम्प",
                                        value: "पेट्रोल पम्प",
                                    },
                                    {
                                        text: "टेन्टहाउस क्याटरिङ सेवा",
                                        value: "टेन्टहाउस क्याटरिङ सेवा",
                                    },
                                    {
                                        text:
                                            "वैदेशिक रोजगारमा पठाउँने सन्स्था , मेनपावर , विदेशको लागि जनशक्ति निर्यात गर्ने कम्पनी",
                                        value:
                                            "वैदेशिक रोजगारमा पठाउँने सन्स्था , मेनपावर , विदेशको लागि जनशक्ति निर्यात गर्ने कम्पनी",
                                    },
                                    {
                                        text: "फर्निचर पसल",
                                        value: "फर्निचर पसल",
                                    },
                                    {
                                        text: "तालिम केन्द्र/सिकाई केन्द्र",
                                        value: "तालिम केन्द्र/सिकाई केन्द्र",
                                    },
                                    {
                                        text:
                                            "घरजग्गा खरिद विक्री , हाउजिङ कम्पनी",
                                        value:
                                            "घरजग्गा खरिद विक्री , हाउजिङ कम्पनी",
                                    },
                                    {
                                        text: "कलर ल्याब /फोटो स्टुडियो",
                                        value: "कलर ल्याब /फोटो स्टुडियो",
                                    },
                                    {
                                        text: "कृषि, पशु तथा माछा फर्म",
                                        value: "कृषि, पशु तथा माछा फर्म",
                                    },
                                    {
                                        text: "कानून व्यवसाय तथा परामर्श",
                                        value: "कानून व्यवसाय तथा परामर्श",
                                    },
                                    {
                                        text: "पेन्ट्स / रङ्ग पसल तथा व्यवसाय",
                                        value: "पेन्ट्स / रङ्ग पसल तथा व्यवसाय",
                                    },
                                    {
                                        text: "मल तथा विषादी व्यवसय",
                                        value: "मल तथा विषादी व्यवसय",
                                    },
                                    {
                                        text: "स्टेसनरी पसल",
                                        value: "स्टेसनरी पसल",
                                    },
                                    {
                                        text: "गोदाम",
                                        value: "गोदाम",
                                    },
                                ],
                                filterMode: "tree",
                                filterSearch: true,
                                onFilter: (value, record) =>
                                    record.businessType.startsWith(value),
                                width: "30%",
                            },
                            {
                                title: "कार्य",
                                dataIndex: "",
                                key: "d",
                                render: function(val) {
                                    return (
                                        <div className="d-flex table-actions">
                                            <i
                                                className="flaticon-eye mr-3 mb-3"
                                                onClick={(e) =>
                                                    props.viewData(val.id)
                                                }
                                            ></i>
                                            <i
                                                className="flaticon-edit mr-3 mb-3"
                                                onClick={(e) =>
                                                    props.editData(val.id)
                                                }
                                            ></i>
                                            <i
                                                className="flaticon-delete"
                                                onClick={(e) =>
                                                    props.deleteData(val.id)
                                                }
                                            ></i>
                                        </div>
                                    );
                                },
                            },
                        ]);
                        setData(data);
                        setDisplayData(dispData);
                    }
                })
                .catch((err) => {
                    toast.error("An error occured while fetching data");
                    setLoading(false);
                });
        }
    }, [subCatRoute]);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        खोज्नुहोस्
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        रिसेट गर्नुहोस्
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        फिल्टर गर्नुहोस्
                    </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button> */}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text,
    });
    const handlePageChange = (val) => {
        const start = val * 10;
        const end = start + 10;
        const dispData = data.slice(start, end);
        setDisplayData(dispData);
    };
    return (
        <>
            {loading && <Loader loading={loading} />}
            {!loading && (
                <>
                    <Table columns={columns} dataSource={displayData} />
                    <Pagination
                        pages={totalPages}
                        handlePageChange={handlePageChange}
                    />
                </>
            )}
        </>
    );
};
export default BusinessTable;
