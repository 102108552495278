import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import {
    wardList,
    toleWardOne,
    toleWardTwo,
    toleWardThree,
    toleWardFour,
    toleWardFive,
    toleWardSix,
    toleWardSeven,
    toleWardEight,
    toleWardTen,
    toleWardNine,
    toleWardEleven,
    toleWardTwelve,
} from "../data/ToleList";
import {
    toleFiveQn,
    toleFourQn,
    toleOneQn,
    toleThreeQn,
    toleTwoQn,
    toleSixQn,
    toleSevenQn,
    toleEightQn,
    toleNineQn,
    toleTenQn,
    toleElevenQn,
    toleTwelveQn,
} from "../data/houseSurvey";
import {
    ward,
    communityGroupType,
    wardQuestion,
    selectedLanguage,
} from "../data/institute";
import ImageUploader from "../components/ImageUploader";

const CommunityOrganization = (props) => {
    const [selectedWard, setSelectedWard] = useState(0);

    const wardData = [
        {
            question: toleOneQn,
            toleList: toleWardOne,
        },
        {
            question: toleTwoQn,
            toleList: toleWardTwo,
        },
        {
            question: toleThreeQn,
            toleList: toleWardThree,
        },
        {
            question: toleFourQn,
            toleList: toleWardFour,
        },
        {
            question: toleFiveQn,
            toleList: toleWardFive,
        },
        {
            question: toleSixQn,
            toleList: toleWardSix,
        },
        {
            question: toleSevenQn,
            toleList: toleWardSeven,
        },
        {
            question: toleEightQn,
            toleList: toleWardEight,
        },
        {
            question: toleNineQn,
            toleList: toleWardNine,
        },
        {
            question: toleTenQn,
            toleList: toleWardTen,
        },
        {
            question: toleElevenQn,
            toleList: toleWardEleven,
        },
        {
            question: toleTwelveQn,
            toleList: toleWardTwelve,
        },
    ];
    const getToleList = (ward) => {
        if (ward == 1) {
            return toleWardOne;
        } else if (ward == 2) {
            return toleWardTwo;
        } else if (ward == 3) {
            return toleWardThree;
        } else if (ward == 4) {
            return toleWardFour;
        } else if (ward == 5) {
            return toleWardFive;
        } else if (ward == 6) {
            return toleWardSix;
        } else if (ward == 7) {
            return toleWardSeven;
        } else if (ward == 8) {
            return toleWardEight;
        } else if (ward == 9) {
            return toleWardNine;
        } else if (ward == 10) {
            return toleWardTen;
        } else if (ward == 11) {
            return toleWardEleven;
        } else {
            return toleWardTwelve;
        }
    };
    const handleWardChange = (ward) => {
        setSelectedWard(ward);
    };
    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={
                    props.data
                        ? props.data
                        : {
                              name: "",
                              wardNumber: "",
                              toleName: "",
                              streetName: "",
                              groupType: "",
                              chairmanInfoName: "",
                              chairmanInfoContact: "",
                              overallCount: "",
                              maleCount: "",
                              femaleCount: "",
                              monthlySavingAmount: "",
                              investmentAmount: "",
                              investmentArea: "",
                              mainWorkInYear: "",
                              establishedDate: "",
                              hasAdyavdhik: "",
                          }
                }
                validate={(values) => {
                    const errors = {};
                    // if (!values.name) {
                    //     errors.name = 'Required';
                    // }
                    // else
                    // if (
                    //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    // ) {
                    //     errors.email = 'Invalid email address';
                    // }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                    setTimeout(() => {
                        props.submit(values);
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                }) => (
                    <>
                        <form
                            className="form survey-form"
                            onSubmit={handleSubmit}
                        >
                            <Card>
                                <Card.Body>
                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                समूह, सँस्थाको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="समूह, सँस्थाको नाम"
                                                name="name"
                                                onChange={handleChange}
                                                value={values.name}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="name"
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                {wardQuestion[selectedLanguage]}
                                            </label>
                                            <select
                                                className="form-control"
                                                id="wardNumber"
                                                name="wardNumber"
                                                onChange={handleChange}
                                                handleWardChange={handleWardChange(
                                                    values.wardNumber
                                                )}
                                                value={
                                                    values.wardNumber
                                                        ? values.wardNumber
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                {wardList.map((woda) => (
                                                    <option value={woda.value}>
                                                        {woda[selectedLanguage]}
                                                    </option>
                                                ))}
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="wardNumber"
                                                component="div"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        {values.wardNumber && (
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    {
                                                        wardData[
                                                            values.wardNumber -
                                                                1
                                                        ].question[
                                                            selectedLanguage
                                                        ]
                                                    }
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="toleName"
                                                    name="toleName"
                                                    onChange={handleChange}
                                                    value={
                                                        values.toleName
                                                            ? values.toleName
                                                            : "selectOne"
                                                    }
                                                >
                                                    <option
                                                        value="selectOne"
                                                        disabled
                                                    >
                                                        कुनै एक छान्नुहोस्
                                                    </option>
                                                    {getToleList(
                                                        values.wardNumber
                                                    ).map((woda) => (
                                                        <option
                                                            value={woda.value}
                                                        >
                                                            {
                                                                woda[
                                                                    selectedLanguage
                                                                ]
                                                            }
                                                        </option>
                                                    ))}
                                                </select>
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="ward"
                                                    component="div"
                                                />
                                            </div>
                                        )}
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                मार्गको नाम
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="मार्गको नाम"
                                                name="streetName"
                                                onChange={handleChange}
                                                value={values.streetName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="streetName"
                                                component="div"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                सँस्था वा समूहको प्रकार
                                            </label>
                                            <select
                                                className="form-control"
                                                id="groupType"
                                                name="groupType"
                                                onChange={handleChange}
                                                value={
                                                    values.groupType
                                                        ? values.groupType
                                                        : "selectOne"
                                                }
                                            >
                                                <option
                                                    value="selectOne"
                                                    disabled
                                                >
                                                    कुनै एक छान्नुहोस्
                                                </option>
                                                {communityGroupType.map(
                                                    (type) => (
                                                        <option
                                                            value={type.value}
                                                        >
                                                            {
                                                                type[
                                                                    selectedLanguage
                                                                ]
                                                            }
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="groupType"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                समूह वा सँस्थाको अध्यक्षको नाम
                                                थर
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="समूह वा सँस्थाको अध्यक्षको नाम
                                                थर"
                                                name="chairmanInfoName"
                                                onChange={handleChange}
                                                value={values.chairmanInfoName}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="chairmanInfoName"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                समूह वा सँस्थाको अध्यक्षको
                                                सम्पर्क नम्बर
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="समूह वा सँस्थाको अध्यक्षको सम्पर्क नम्बर"
                                                name="chairmanInfoContact"
                                                onChange={handleChange}
                                                value={
                                                    values.chairmanInfoContact
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="chairmanInfoContact"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                समूहमा आवद्द सदस्य सङ्ख्या
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="समूहमा आवद्द सदस्य सङ्ख्या"
                                                name="overallCount"
                                                onChange={handleChange}
                                                value={values.overallCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="overallCount"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                पुरुष सदस्य सङ्ख्या
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="पुरुष सदस्य सङ्ख्या"
                                                name="maleCount"
                                                onChange={handleChange}
                                                value={values.maleCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="maleCount"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                महिला सदस्य सङ्ख्या
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="महिला सदस्य सङ्ख्या"
                                                name="femaleCount"
                                                onChange={handleChange}
                                                value={values.femaleCount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="femaleCount"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                मासिक बचत रकम (रु.)
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="मासिक बचत रकम (रु.)"
                                                name="monthlySavingAmount"
                                                onChange={handleChange}
                                                value={
                                                    values.monthlySavingAmount
                                                }
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="monthlySavingAmount"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                लगानी रकम (रु.) सरदर
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="लगानी रकम (रु.) सरदर"
                                                name="investmentAmount"
                                                onChange={handleChange}
                                                value={values.investmentAmount}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="investmentAmount"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                लगानीको मुख्य क्षेत्र (३ वटा
                                                सम्म )
                                            </label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                id="investmentArea"
                                                rows="3"
                                                name="investmentArea"
                                                onChange={handleChange}
                                                value={values?.investmentArea}
                                            ></textarea>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="investmentArea"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                विगत एक वर्ष भित्र गरिएका प्रमुख
                                                कार्यहरु
                                            </label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                id="mainWorkInYear"
                                                rows="3"
                                                name="mainWorkInYear"
                                                onChange={handleChange}
                                                value={values?.mainWorkInYear}
                                            ></textarea>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="mainWorkInYear"
                                                component="div"
                                            />
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                स्थापना मिति (साल -महिना -गते)
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="लगानी रकम (रु.) सरदर"
                                                name="establishedDate"
                                                onChange={handleChange}
                                                value={values.establishedDate}
                                            />
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="establishedDate"
                                                component="div"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-6">
                                            <label className="form-control-label">
                                                अध्यावधिक गरिएको छ कि छैन ?
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="yes"
                                                    name="hasHighDegreeDoctor"
                                                    onChange={handleChange}
                                                />
                                                <span></span>छ
                                            </label>
                                            <label class="radio radio-rounded">
                                                <input
                                                    type="radio"
                                                    value="no"
                                                    name="hasHighDegreeDoctor"
                                                    onChange={handleChange}
                                                />
                                                <span></span>
                                                छैन
                                            </label>
                                            <ErrorMessage
                                                className="invalid-feedback"
                                                name="establishedDate"
                                                component="div"
                                            />
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    अक्षांश
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="lat"
                                                    onChange={handleChange}
                                                    value={values.geo?.lat}
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="lat"
                                                    component="div"
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label className="form-control-label">
                                                    देशान्तर
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="lng"
                                                    onChange={handleChange}
                                                    value={values.geo?.lng}
                                                />
                                                <ErrorMessage
                                                    className="invalid-feedback"
                                                    name="lng"
                                                    component="div"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label>फोटो</label>
                                            <ImageUploader
                                                fileListContent={
                                                    props.fileListContent
                                                }
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="card-footer text-right">
                                        <FormFooterButtons />
                                    </div>
                                </Card.Body>
                            </Card>
                        </form>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default CommunityOrganization;
