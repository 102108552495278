import React from "react";
import { Card } from "react-bootstrap";
import {
    bankaccountYesNo,
    cookingresourceLists,
    drinkingwaterLists,
    lightresourceLists,
    noToiletLists,
    salttypeLists,
    toiletresourceLists,
    toiletYesNo,
    wastemanagementLists,
    waterSource,
} from "../../data/houseSurvey";
import { selectedLanguage } from "../../data/institute";

const FamilyResourceUsage = ({ secondaryHouseDetails }) => {
    const getToilet = (toilet) => {
        switch (toilet.isToilet) {
            case "yes":
                return toiletresourceLists.find(
                    (i) => i.value === toilet.toiletYes
                )
                    ? toiletresourceLists.find(
                          (i) => i.value === toilet.toiletYes
                      )[selectedLanguage]
                    : "";

            case "no":
                return noToiletLists.find((i) => i.value === toilet.toiletNo)
                    ? noToiletLists.find((i) => i.value === toilet.toiletNo)[
                          selectedLanguage
                      ]
                    : "";

            default:
                break;
        }
    };
    return (
        <>
            <Card>
                <Card.Body className="house-detail">
                    <Card.Title>
                        <h3>परिवारले प्रयोग गर्ने बारेमा</h3>
                    </Card.Title>
                    <h6>
                        खानेपानीको मुख्य श्रोतं :{" "}
                        {drinkingwaterLists.find(
                            (i) => i.value === secondaryHouseDetails.waterSource
                        )
                            ? drinkingwaterLists.find(
                                  (i) =>
                                      i.value ===
                                      secondaryHouseDetails.waterSource
                              )[selectedLanguage]
                            : ""}
                    </h6>
                    <h6>
                        वत्तिको मुख्य श्रोत :{" "}
                        {lightresourceLists.find(
                            (i) =>
                                i.value === secondaryHouseDetails.electricSource
                        )
                            ? lightresourceLists.find(
                                  (i) =>
                                      i.value ===
                                      secondaryHouseDetails.electricSource
                              )[selectedLanguage]
                            : ""}
                    </h6>
                    <h6>
                        बिजुलीको मिटर जडान :{" "}
                        {secondaryHouseDetails.isElectricityMeter == true
                            ? "छ"
                            : "छैन"}
                    </h6>
                    <h6>
                        खाना पकाउने मुख्य श्रोत :{" "}
                        {secondaryHouseDetails.cookingSource.map((fuel) => {
                            return cookingresourceLists.find(
                                (i) => i.value === fuel
                            )
                                ? cookingresourceLists.find(
                                      (i) => i.value === fuel
                                  )[selectedLanguage] + ", "
                                : "";
                        })}
                    </h6>
                    {/* <h6>
                        घरमा शौचालय छ/छैन :{" "}
                        {toiletYesNo.find(
                            (i) =>
                                i.value ===
                                secondaryHouseDetails.toilet.isToilet
                        )
                            ? toiletYesNo.find(
                                  (i) =>
                                      i.value ===
                                      secondaryHouseDetails.toilet.isToilet
                              )[selectedLanguage]
                            : ""}
                    </h6> */}
                    <h6>
                        {" "}
                        शौचालयको किसिम :{" "}
                        {toiletresourceLists.find(
                            (i) => i.value === secondaryHouseDetails.toilet
                        )
                            ? toiletresourceLists.find(
                                  (i) =>
                                      i.value === secondaryHouseDetails.toilet
                              )[selectedLanguage]
                            : secondaryHouseDetails.toilet}
                    </h6>
                    <h6>
                        बैंक तथा वित्तीय सस्थामा खाता :{" "}
                        {secondaryHouseDetails.bankAccount == true
                            ? "छ"
                            : "छैन"}
                    </h6>
                    {/* <h6>7. नुनको किसिम :  {salttypeLists.find(i => i.value === secondaryHouseDetails.saltUsed) ? salttypeLists.find(i => i.value === secondaryHouseDetails.saltUsed)[selectedLanguage] : ""}</h6> */}
                    <h6>
                        फोहोर मैलो व्यवस्थापन :{" "}
                        {wastemanagementLists.find(
                            (i) =>
                                i.value === secondaryHouseDetails.wasteDisposal
                        )
                            ? wastemanagementLists.find(
                                  (i) =>
                                      i.value ===
                                      secondaryHouseDetails.wasteDisposal
                              )[selectedLanguage]
                            : ""}
                    </h6>
                    <h6>
                        फोहरमैला उत्पादन (के.जी.) :{" "}
                        {secondaryHouseDetails.wasteProduction &&
                            secondaryHouseDetails.wasteProduction}
                    </h6>
                    {/* <h6>8. परिवारको बीमा विवरण :  { }</h6> */}
                </Card.Body>
            </Card>
        </>
    );
};

export default FamilyResourceUsage;
