import React, { useEffect, useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import ApexCharts from "apexcharts";
import { KTUtil } from "../../../_metronic/_assets/js/components/util.js";
import { NavLink } from "react-router-dom";
import { selectedLanguage } from "../../data/institute";
import { getReportData } from "../../modules/HomeSurvey/homeSurveyCrud";
import { agricultureList } from "../../data/agricultureReport";
import Loader from "../../components/Loader.js";
import Table from "rc-table";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const AgricultureBarGraphCard = (props) => {
    const [data, setData] = useState(undefined);
    const [series, setSeries] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState(undefined);
    const { detail, child, childIndex, parentIndex } = props;
    const clickDiv = (el) => {
        const downloadBtn = document.getElementById("test-table-xls-button");
        if (downloadBtn) {
            downloadBtn.click();
        }
    };
    useEffect(() => {
        if (childIndex == 0) {
            getDataDetails();
        }
        console.log(child);
    }, []);
    useEffect(() => {
        if (series && categories && data) {
            console.log("chart data", series, categories, data);
            const element1 = document.getElementById(
                props.child.value +
                    "_" +
                    props.parentIndex +
                    "_" +
                    props.childIndex
            );
            // const element = barElement;
            if (!element1) {
                return;
            }

            // const height = parseInt(KTUtil.css(element1, 'height'));
            const options = getChartOptions();

            const chart = new ApexCharts(element1, options);
            chart.render();
            return function cleanUp() {
                chart.destroy();
            };
        }
    }, [series, categories, data]);
    function getChartOptions() {
        const longTextGraph = [
            "Waste management detail",
            "शिप तालिम प्राप्त जनसक्तिको विवरण",
            "घरको प्रकारको विवरण",
            "अपाङ्गता सम्बन्धी विवरण र जनसंख्या",
            "फोहरमहिला विवरण",
        ];
        const options = {
            series: series,
            chart: {
                toolbar: {
                    show: false,
                },
                type: "bar",
                height: longTextGraph.includes(props.datum?.title) ? 680 : 430,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: "bottom",
                    },
                },
            },
            dataLabels: {
                enabled: false,
                style: {
                    fontSize: "12px",
                    colors: ["#fff"],
                },
            },
            stroke: {
                show: true,
                width: 1,
                colors: ["#fff"],
            },
            tooltip: {
                shared: true,
                intersect: false,
            },
            xaxis: {
                categories: categories,
                labels: {
                    style: {
                        fontSize: "12",
                    },
                    maxHeight: 270,
                    // minHeight: (props.datum?.title === "शिप तालिम प्राप्त जनसक्तिको विवरण") ? 270 : undefined,
                },
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: "14",
                    },
                },
            },
            colors: ["#2541b2", "#e5d549", "#bf1363", "#39a6a3"],
            legend: {
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: "#fff",
                    fillColors: undefined,
                    radius: 12,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: 0,
                },
                position: "top",
                horizontalAlign: "right",
            },
        };
        return options;
    }
    const getDataDetails = () => {
        if (!data) {
            if (props.setLoading) {
                props.setLoading(false);
            }
            setLoading(false);
            const baseKey = agricultureList[parentIndex];
            getReportData(baseKey.baseApiKey, child.value)
                .then((res) => {
                    console.log(child.value, res.data.data);
                    if (res.data.success) {
                        if (props.setData) {
                            props.setData(res.data.data);
                        }
                        setData(res.data.data);
                        if (res.data.data.hasOwnProperty("male")) {
                            const { data } = res.data;
                            let femaleTitle =
                                selectedLanguage == "nepali"
                                    ? "महिला"
                                    : "Female";
                            let maleTitle =
                                selectedLanguage == "nepali" ? "पुरुष" : "Male";
                            let thirdTitle =
                                selectedLanguage == "nepali"
                                    ? "अन्य"
                                    : "Third Gender";
                            const femaleData = data.female.map(
                                ({ value }) => value
                            );
                            const maleData = data.male.map(
                                ({ value }) => value
                            );
                            const thirdData = data.third.map(
                                ({ value }) => value
                            );

                            const labelData = data.single.map((datum) => {
                                return datum.label ? datum.label : "";
                            });
                            const seriesData = [
                                {
                                    name: femaleTitle,
                                    data: femaleData,
                                },
                                {
                                    name: maleTitle,
                                    data: maleData,
                                },
                                {
                                    name: thirdTitle,
                                    data: thirdData,
                                },
                            ];
                            setSeries(seriesData);
                            setCategories(labelData);
                        } else if (res.data.data.hasOwnProperty("ward")) {
                            const { data } = res.data;
                            const wardData = data.ward[
                                data.ward.length - 1
                            ].data.map(({ value }) => value);

                            const labelData = data.ward[0].data.map((datum) => {
                                return datum.label ? datum.label : "";
                            });
                            const seriesData = [
                                {
                                    name:
                                        data.reportTitle[
                                            data.reportTitle.length - 1
                                        ],
                                    data: wardData,
                                },
                            ];
                            setSeries(seriesData);
                            setCategories(labelData);
                        }
                    }
                })
                .catch((err) => {
                    if (props.setLoading) {
                        props.setLoading(false);
                    }
                    setLoading(false);
                });
        }
    };
    return (
        <Card className="mb-2">
            <Card.Header>
                <Accordion.Toggle
                    className="w-100 d-flex justify-content-between"
                    onClick={getDataDetails}
                    as={Button}
                    variant="primary-light"
                    eventKey={childIndex}
                >
                    <h3 className="p-5">{child[selectedLanguage]}</h3>
                    {!detail && (
                        <>
                            <NavLink
                                to={`/agriculture-survey/report/${parentIndex}/${child.value}`}
                            >
                                <button className="btn btn-light-primary font-weight-bold px-16 font-size-h6 mt-2">
                                    विवरण हेर्नुहोस
                                </button>
                            </NavLink>
                        </>
                    )}
                    {detail && data && !data.chartAvailable && (
                        <div>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="d-none"
                                table="detail-table"
                                filename={child[selectedLanguage]}
                                sheet={child[selectedLanguage]}
                                buttonText="Download as XLS"
                            />
                            <button
                                className="btn btn-light-success"
                                onClick={clickDiv}
                            >
                                {selectedLanguage == "nepali"
                                    ? "डाउनलोडगर्नुहोस् XLS"
                                    : "Export XLS"}
                                <i
                                    className="far fa-file-excel ml-2"
                                    style={{ position: "relative" }}
                                ></i>
                            </button>
                            {/* <button className="btn btn-light-danger" onClick={this.exportPdf}>
                                    Export PDF
                                <i className="far fa-file-pdf ml-2" style={{ top: "3px", position: "relative" }}></i>
                                </button> */}
                        </div>
                    )}
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={childIndex}>
                <Card.Body>
                    {loading && <Loader loading={loading} />}
                    {!loading && data && data.chartAvailable && (
                        <div
                            id={`${props.child.value}_${props.parentIndex}_${props.childIndex}`}
                            className="card-rounded-bottom"
                            style={{ height: "500px" }}
                        ></div>
                    )}
                    {!loading && data && !data.chartAvailable && (
                        <Table
                            id="detail-table"
                            className="table table-hover"
                            columns={data.title}
                            data={data.result}
                        />
                    )}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

export default AgricultureBarGraphCard;
